var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-not-found-content" }, [
    _vm._m(0),
    _vm._m(1),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-6 text-center" },
        [
          _c(
            "router-link",
            { attrs: { to: { name: "Login" } } },
            [
              _c("ButtonForward", {
                attrs: {
                  "label-name": _vm.$t("buttons.returnToLogin"),
                  color: "secondary"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "col-6 text-center" }, [
        _c(
          "a",
          { attrs: { href: _vm.inquiryUrl, target: "_blank" } },
          [
            _c("ButtonForward", {
              attrs: {
                "label-name": _vm.$t("buttons.toInquiry"),
                color: "secondary"
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center" }, [
      _c("h1", [_vm._v("ファイルが見つかりません。")]),
      _c("h3", { staticClass: "text-muted" }, [_vm._v(" 404 Not Found ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("p", [_vm._v("アクセスしたURLが存在しません。")]),
      _c("p", [
        _vm._v(
          " ブックマークに登録して直接開かれている場合は登録しなおしてください。 "
        )
      ]),
      _c("p", [_vm._v("考えられる原因としては以下の通りです。")]),
      _c("p", [_vm._v("・指定されたURLが存在しません。")]),
      _c("p", [
        _vm._v(
          " ・URLが間違っているか、ファイルが移動、または名前が変更されています。 "
        )
      ]),
      _c("p", [
        _vm._v("・ご覧になっていたページからのリンクが無効になっています。")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }