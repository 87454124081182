<template>
  <div class="title-question">
    <ul class="list-group list-group-horizontal">
      <li class="list-group-item border-0 pt-0 ps-0 pe-2 pb-0">
        <IconQuestion
          width="30px"
          height="30px"
        />
      </li>
      <li class="list-group-item border-0 px-0 pb-0 title-text">
        <h5 class="m-0">
          {{ $t("titles.question") }}
        </h5>
      </li>
      <li class="list-group-item border-0 ps-3 pe-1 pb-0 title-help">
        <ButtonOpenHelp :url="$t('url.helps.question')" />
      </li>
    </ul>
  </div>
</template>

<script>
import IconQuestion from "@/components/atoms/icons/IconQuestion.vue"
import ButtonOpenHelp from "@/components/atoms/buttons/ButtonOpenHelp.vue"

export default {
  name: "TitleQuestion",
  components: {
    IconQuestion,
    ButtonOpenHelp,
  },
}
</script>

<style lang="scss" scoped>
.title-question {
  font-size: 10px;
  height: 2.3rem;

  .list-group {
    height: 2.3rem;

    .title-text {
      padding-top: 0.1rem;
    }

    .title-help {
      padding-top: 0.1rem;
    }
  }
}
</style>