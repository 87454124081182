/**
 * アカウント関連 Repository
 */
import { apiResponseCode, sukenServiceId } from "../constant/network"
import i18n from "../i18n"
import accountApi from "../api/account"
import sessionRepository from "@/repositories/session"
import Promise from 'bluebird'
import { funcs } from '@/dbs/indexedDb'

export default {
  /**
   * Create 系
   */

  /**
   * Read 系
   */

  /**
   * セッショントークンを取得する
   * @param {string} accountId ID
   * @param {string} password パスワード
   * @returns セッショントークン
   */
  async getSessionToken(accountId, password) {
    return await accountApi.getSessionToken(accountId, password)
  },

  /**
   * 数研アカウント情報を取得する
   * @param {Number} serviceId サービス ID（LMS は、2:新デジタル教科書システム）
   * @param {String} accountId ID
   * @param {String} sessionToken セッショントークン
   * @returns 数研アカウント情報
   */
  async getSukenAccountInfo(serviceId, accountId, sessionToken) {
    return await accountApi.getSukenAccountInfo(
      serviceId,
      accountId,
      sessionToken
    )
  },
  
  /**
   * 数研アカウントとグループIDに紐づく商品ID一覧を取得する
   * @param {Number} serviceId サービス ID（LMS は、2:新デジタル教科書システム）
   * @param {String} accountId ID
   * @param {String} sessionToken セッショントークン
   * @returns グループ ID 一覧
   */
  async getProductIdsOfSukenAccount(serviceId, groupId, accountId, sessionToken) {
    return await accountApi.getProductIdsOfSukenAccount(
      serviceId,
      groupId,
      accountId,
      sessionToken
    )
  },

  /**
   * 数研アカウント権限を持っているか
   * @param {Number} authorityId 権限 ID
   * @returns Bool 型（true: 持っている, false: 持っていない）
   */
  async hasSukenAccountPrivilege(authorityId) {
    const authorityIds = {
      // 数研アカウント
      sukenAccount: 1,
      // 数研アカウント（生徒）
      sukenAccountStudent: 5,
      // 学校アカウント
      schoolAccount: 6,
    }

    // 数研アカウントの場合
    if (authorityId === authorityIds.sukenAccount) {
      // 権限あり
      return true
      // 数研アカウント以外の場合
    } else {
      // 権限なし
      return false
    }
  },
  /**
   * 教職関係者か
   * @param {Number} jobId 権限 ID
   * @returns Bool 型（true: 教職関係者, false: 教職関係者ではない）
   */
  isTeacherAccount(jobId) {
    const jobIds = [
      {
        // 小学校教職員
        jobId: 1,
      },
      {
        // 中学校教職員
        jobId: 2,
      },
      {
        // 高等学校（高等専門学校含む）教職員
        jobId: 3,
      },
      {
        // 中等教育学校教職員
        jobId: 4,
      },
      {
        // 大学・大学院・短大・専門学校教職員
        jobId: 5,
      },
      {
        // 教育委員会・教育センターなどの関係者
        jobId: 6,
      },
    ]

    return jobIds.some((job) => job.jobId === jobId)
  },

  /**
   * 数研アカウントが管理対象のグループ ID 一覧を取得する
   * @param {Number} serviceId サービス ID（LMS は、2:新デジタル教科書システム）
   * @param {String} accountId ID
   * @param {String} sessionToken セッショントークン
   * @returns グループ ID 一覧
   */
  async getGroupIdsOfSukenAccount(serviceId, accountId, sessionToken) {
    const promise = await accountApi.getGroupIdsOfSukenAccount(
      serviceId,
      accountId,
      sessionToken
    )

    if (promise.status !== apiResponseCode.ok) {
      promise.data.groupList = []
      return Promise.reject(promise)
    }

    if (promise.data && promise.data.status !== apiResponseCode.ok) {
      promise.data.groupList = []
      return Promise.reject(promise.data)
    }
    return promise
  },

  /**
   * グループ ID に紐づくアカウントが所有している教材パッケージの一覧を取得する
   * @param {String} groupId グループ ID
   * @param {String} teacherAccountId 先生アカウント ID
   * @param {Array} curriculums 教科（例 ['ma', 'sa']）
   * @param {String} sessionToken セッショントークン
   * @param {Object} apiSettings API設定
   * @returns 教材パッケージの一覧
   */
  async getSukenAccountWithBooks(
    groupId,
    teacherAccountId,
    curriculums,
    sessionToken,
    apiSettings
  ) {
    // console.log(`repositories/getSukenAccountWithBooks`)

    const apiSettingBulkCount = apiSettings.find(v => v.code == "0400");
    const apiSettingConcurrency = apiSettings.find(v => v.code == "0401");
    const apiSettingTimeout = apiSettings.find(v => v.code == "0402");
    var bulkCount = apiSettingBulkCount ? apiSettingBulkCount.value : 3;
    var concurrency = apiSettingConcurrency ? apiSettingConcurrency.value : 5;
    var timeout = apiSettingTimeout ? apiSettingTimeout.value : 120000;

    try {
      let accountItemsResponses = []

      let url = `/groups/${groupId}/accounts/products/books`;
      let key = `${url}-${teacherAccountId}-${[].concat(curriculums).join(",")}`;
      // 有効期限内のレスポンスキャッシュが存在する場合、レスポンスとして返却する
      let responseCache = await funcs.responseCache.get(key);
      if (responseCache !== null) {
        accountItemsResponses = responseCache.data;
      } else {
        // グループアカウント情報を取得
        const booksAccountsPromise = await accountApi.getSukenAccountWithBooksAccounts(
          groupId,
          teacherAccountId,
          sessionToken
        )
        const { group_accounts, random_key } = booksAccountsPromise.data

        // グループアカウントリストを分割し、分割単位でリクエスト
        const groupAccountsList = this.sliceArray(group_accounts, bulkCount)
        accountItemsResponses = await Promise.map(groupAccountsList, (accounts) => {
          return accountApi.getSukenAccountWithBooks(
            groupId,
            teacherAccountId,
            curriculums,
            sessionToken,
            accounts,
            random_key,
            timeout
          )
        }, {concurrency: concurrency})

        accountItemsResponses.forEach(p => {
          if (!p || p.status !== apiResponseCode.ok) {
            throw new Object({status: 500});
          }
        });
        // レスポンスをキャッシュとして保存する
        await funcs.responseCache.set(key, accountItemsResponses);
      }
      // 教材一覧
      let bookItems = []
  
      const accountItems = accountItemsResponses.reduce((acc, response) => {
        response.data.forEach((x) => {
          if (x.books.length) {
            x.books.forEach((b) => {
              bookItems.push({
                bookId: b.id,
                bookTitle: b.display_title,
                shortCurriculumName: b.curriculum,
                productIds: b.product_ids,
                displayTitle: b.display_title,
                thumbnailUrl: b.thumbnail_url,
                additionalOptionId: b.additional_option_id
              })
            })
          }

          let accountName = `${x.first_name} ${x.last_name}`
          if (this.isEmpty(accountName)) {
            accountName = `${x.account_id}`
          }
  
          // 教材を所有しているアカウント一覧
          acc.push({
            accountId: x.account_id,
            accountName: accountName,
            bookItems: x.books,
          })
        })
        return acc
      }, [])

      // 教材一覧から重複データを取り除く（キーは教材 ID）
      const filterBookItems = Array.from(
        new Map(bookItems.map((x) => [x.bookId, x])).values()
      )
      return { accountItems: accountItems, bookItems: filterBookItems }
    } catch (error) {
      return Promise.reject(error)
    }
  },

  /**
   * 配列の分割
   */
  sliceArray(array, number, maxChars = 3600) {
    var result = []
    var currentArray = []
    result.push(currentArray)
    var charLength = 0
    for (var item of array) {
      // 文字列数の加算
      // join時にカンマ区切りになることを考慮して +1 する。
      var newCharLength = charLength + item.length + 1
      // 分割単位の上限個数、および、文字列数の最大値オーバーの場合は新しい配列に格納
      if (currentArray.length >= number || newCharLength > maxChars) {
        currentArray = []
        newCharLength = 0
        result.push(currentArray)
      }
      charLength = newCharLength
      currentArray.push(item)
    }
    return result
  },

  /**
   * 空文字、スペース、undefinedのみであればtrueを返す
   * @param {string} str
   * @returns
   */
  isEmpty(str) {
    return !str || !str.match(/\S/g)
  },

  /**
   * bookIdに対応する書籍と、その書籍を持っている生徒アカウントを返す
   * @param {Array} accountItems 生徒アカウントリスト（getSukenAccountWithBooksメソッドから取得したもの）
   * @param {Array} bookItems 書籍リスト（getSukenAccountWithBooksメソッドから取得したもの）
   * @param {String} bookId 書籍ID（bookItemsに入っているものの中のどれか）
   * @returns 書籍オブジェクトと生徒アカウントオブジェクトをまとめたオブジェクト
   */
  getBookAndStudentAccount(accountItems, bookItems, bookId) {
    // book、studentは必ず存在するのでnullチェック等は無し
    const book = bookItems.find((b) => b.bookId === bookId)
    // 書籍を持っているアカウントを適当に取ってくる
    const student = accountItems.find((s) =>
      s.bookItems.some((b) => b.id === bookId)
    )
    return {
      book: book,
      student: student,
    }
  },

  /**
   * 追加オプション教材をセレクトボックスに表示するかどうか判定を行った上で教材リストを返す
   * @param {Array} productList 選択したグループ内の生徒が所持している商品IDリスト
   * @param {Array} bookItems 書籍リスト（getSukenAccountWithBooksメソッドから取得したもの）
   * @returns 新しい書籍リスト
   */
  judgeAddOptionBook(productList, bookItems) {
    return bookItems.filter((bookItem) => {
      // 追加オプションがない教材の場合はtrueを返してフィルターしない
      if (!bookItem.additionalOptionId) {
        return true;
      }

      if(productList.includes(parseInt(bookItem.additionalOptionId))){
        return true;
      }else{
        return false;
      }
    });
  },

  /**
   * 教材パッケージを変換し取得する
   * @param {Array} bookItems 教材パッケージの一覧
   * @returns ドロップダウンリスト用教材パッケージの一覧
   */
  getBookList(bookItems) {
    let bookSelectItems = new Array()
    if (bookItems !== null && bookItems.length > 0) {
      bookItems.sort(function (a, b) {
        const aData = a.productIds[0]
        const bData = b.productIds[0]
        return aData < bData ? 1 : -1 //オブジェクトの降順ソート
      })

      bookSelectItems = bookItems.map((x) => {
        return {
          value: x.bookId,
          label: x.bookTitle,
        }
      })
    }
    bookSelectItems.unshift({
      value: "0",
      label: i18n.t("labels.pleaseSelect"),
    })
    return bookSelectItems
  },

  /**
   * グループ ID に紐づくアカウントが所有している教材パッケージの閲覧権限を生成する
   * @param {String} groupId グループ ID
   * @param {String} studentAccountId 生徒アカウント ID
   * @param {String} productId 商品 ID
   * @param {String} bookId 教材 ID
   * @param {String} teacherAccountId 先生アカウント ID
   * @param {String} sessionToken セッショントークン
   * @returns 生成された閲覧権限 Cookie
   */
  async getCookieSukenAccountWithBooks(
    groupId,
    studentAccountId,
    productId,
    bookId,
    teacherAccountId,
    sessionToken
  ) {
    const result = await accountApi.getCookieSukenAccountWithBooks(
      groupId,
      studentAccountId,
      productId,
      bookId,
      teacherAccountId,
      sessionToken
    )

    // バリデーションエラー
    if (result.status === apiResponseCode.badRequest) {
      return Promise.reject(result)
    }

    // 資格情報不足エラー
    if (result.status === apiResponseCode.unauthorized) {
      return Promise.reject(result)
    }

    // 教材非公開エラー
    if (result.status === apiResponseCode.forbidden) {
      return Promise.reject(result)
    }

    // 教材存在エラー
    if (result.status === apiResponseCode.notFound) {
      return Promise.reject(result)
    }

    // サーバー側エラー
    if (result.status === apiResponseCode.internalServerError) {
      return Promise.reject(result)
    }

    // サーバー側エラー（処理する準備ができていない）
    if (result.status === apiResponseCode.serviceUnavailable) {
      return Promise.reject(result)
    }

    return result
  },

  /**
   * Update 系
   */

  /**
   * LMS サーバ API に認証を実行する
   * @param {String} accountId ID
   * @param {String} apiToken LMS API トークン
   * @returns API トークン
   */
  async getLmsApiToken(accountId, apiToken) {
    const promise = await accountApi.getLmsApiToken(accountId, apiToken)

    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }

    // コード値・名称変換設定値
    const nameConversionParams = new URL(promise.data.data.name_conversion_list)
      .searchParams
    const nameConversionParamItems = {
      expires: nameConversionParams.get("Expires"),
      signature: nameConversionParams.get("Signature"),
      keyPairId: nameConversionParams.get("Key-Pair-Id"),
    }

    // パラメータ設定値
    const parameterParams = new URL(promise.data.data.parameter_list)
      .searchParams
    const parameterParamItems = {
      expires: parameterParams.get("Expires"),
      signature: parameterParams.get("Signature"),
      keyPairId: parameterParams.get("Key-Pair-Id"),
    }

    promise.data.data.nameConversionParamItems = nameConversionParamItems
    promise.data.data.parameterParamItems = parameterParamItems

    return promise
  },

  /**
   * ログイン認証をする
   * @param {Object} account アカウント情報（ID、パスワード）
   * @returns Promise オブジェクト
   */
  async authenticateLogin(account) {
    let loginUserInfo = {
      accountId: "",
      userName: "",
      sessionToken: "",
      schoolId: "",
      schoolName: "",
    }

    // (1) 配信サーバ API で認証する
    const sessionTokenPromise = await this.getSessionToken(
      account.id,
      account.password
    )

    // ログイン認証エラー
    if (sessionTokenPromise.status !== apiResponseCode.ok) {
      sessionTokenPromise.data.loginUserInfo = loginUserInfo
      return Promise.reject(sessionTokenPromise)
    }

    // メンテナンス中はstatus200でレスポンスが返ってくるためここでハンドリングする
    if (sessionTokenPromise.headers["content-type"] !== "application/json") {
      sessionTokenPromise.status = apiResponseCode.serviceUnavailable
      return Promise.reject(sessionTokenPromise)
    }

    // (2) アカウント基盤サーバ API より、数研アカウント情報を取得
    const accountInfoPromise = await this.getSukenAccountInfo(
      sukenServiceId.lms,
      account.id,
      sessionTokenPromise.data.account.token
    )

    // メンテナンス中はstatus200でレスポンスが返ってくるためここでハンドリングする
    if (accountInfoPromise.headers["content-type"] !== "application/json;charset=UTF-8") {
      accountInfoPromise.status = apiResponseCode.serviceUnavailable
      return Promise.reject(accountInfoPromise)
    }

    const accountInfoPromiseData = accountInfoPromise.data

    // アカウント情報取得エラー
    if (accountInfoPromise.status !== apiResponseCode.ok) {
      accountInfoPromiseData.loginUserInfo = loginUserInfo
      return Promise.reject(accountInfoPromise)
    }

    // 権限を取得
    const authorityId = accountInfoPromiseData.authorityId

    // (3) 数研アカウントの権限を持っているかチェックする
    const hasPrivilege = await this.hasSukenAccountPrivilege(authorityId)

    // 数研アカウントの権限を持っていない
    if (!hasPrivilege) {
      accountInfoPromiseData.loginUserInfo = loginUserInfo
      accountInfoPromiseData.isForbiddenUser = true
      return Promise.reject(accountInfoPromise)
    }

    const isTeacher = await this.isTeacherAccount(accountInfoPromiseData.job)
    // 教職員関係者ではない
    if (!isTeacher) {
      accountInfoPromiseData.loginUserInfo = loginUserInfo
      accountInfoPromiseData.isForbiddenUser = true
      return Promise.reject(accountInfoPromise)
    }

    // (4) LMS サーバ API で認証する
    const apiToken = ""
    const lmsApiPromise = await this.getLmsApiToken(account.id, apiToken)

    // LMS 認証エラー
    if (lmsApiPromise.status !== apiResponseCode.ok) {
      lmsApiPromise.data.loginUserInfo = loginUserInfo
      return Promise.reject(lmsApiPromise)
    }

    // (5) システム日時を取得する
    const systemDateTimePromise = await sessionRepository.getSystemDateTime(
      account.id,
      lmsApiPromise.data.data.token
    )

    // システム日時取得エラー
    if (systemDateTimePromise.status !== apiResponseCode.ok) {
      systemDateTimePromise.data.loginUserInfo = loginUserInfo
      return Promise.reject(systemDateTimePromise)
    }

    let userName = `${accountInfoPromiseData.firstName} ${accountInfoPromiseData.lastName}`
    if (this.isEmpty(userName)) {
      userName = `${accountInfoPromiseData.accountId}`
    }

    // ログイン情報をセット
    sessionTokenPromise.data.loginUserInfo = {
      accountId: account.id,
      userName: userName,
      sessionToken: sessionTokenPromise.data.account.token,
      lmsApiToken: lmsApiPromise.data.data.token,
      schoolId: accountInfoPromiseData.schoolId,
      schoolName: accountInfoPromiseData.companyName,
      handleCurriculums: accountInfoPromiseData.chargedSubject,
      loginDateTime: systemDateTimePromise.data.data.system_date,
    }

    // パラメータ設定情報の URL パラメータ（クエリストリングの引数）をセット
    sessionTokenPromise.data.settingUrlParameter = {
      nameConversionItems: lmsApiPromise.data.data.nameConversionParamItems,
      parameterItems: lmsApiPromise.data.data.parameterParamItems,
    }

    return sessionTokenPromise
  },
  /**
   * ソーシャルログイン認証をする
   * @param {String} accountId アカウントID
   * @param {String} token トークン
   * @returns Promise オブジェクト
   */
  async authenticateSocialLogin(accountId, token) {
    let loginUserInfo = {
      accountId: "",
      userName: "",
      sessionToken: "",
      schoolId: "",
      schoolName: "",
    }

    // (1) アカウント基盤サーバ API より、数研アカウント情報を取得
    const accountInfoPromise = await this.getSukenAccountInfo(
      sukenServiceId.lms,
      accountId,
      token
    )

    const accountInfoPromiseData = accountInfoPromise.data

    // アカウント情報取得エラー
    if (Number(accountInfoPromise.status) !== apiResponseCode.ok) {
      accountInfoPromiseData.loginUserInfo = loginUserInfo
      return Promise.reject(accountInfoPromise)
    }

    // メンテナンス中はstatus200でレスポンスが返ってくるためここでハンドリングする
    // ※content-typeは何故かバックエンドの都合でこうなっている
    if (
      accountInfoPromise.headers["content-type"] !==
      "application/json;charset=UTF-8"
    ) {
      accountInfoPromise.status = apiResponseCode.serviceUnavailable
      return Promise.reject(accountInfoPromise)
    }

    // 権限を取得
    const authorityId = accountInfoPromiseData.authorityId

    // (3) 数研アカウントの権限を持っているかチェックする
    const hasPrivilege = await this.hasSukenAccountPrivilege(authorityId)

    // 数研アカウントの権限を持っていない
    if (!hasPrivilege) {
      accountInfoPromiseData.loginUserInfo = loginUserInfo
      accountInfoPromise.status = apiResponseCode.forbidden
      return Promise.reject(accountInfoPromise)
    }

    const isTeacher = await this.isTeacherAccount(accountInfoPromiseData.job)
    // 教職員関係者ではない
    if (!isTeacher) {
      accountInfoPromiseData.loginUserInfo = loginUserInfo
      accountInfoPromiseData.isForbiddenUser = true
      return Promise.reject(accountInfoPromise)
    }

    // (4) LMS サーバ API で認証する
    const apiToken = ""
    const lmsApiPromise = await this.getLmsApiToken(accountId, apiToken)

    // LMS 認証エラー
    if (lmsApiPromise.status !== apiResponseCode.ok) {
      lmsApiPromise.data.loginUserInfo = loginUserInfo
      return Promise.reject(lmsApiPromise)
    }

    // (5) システム日時を取得する
    const systemDateTimePromise = await sessionRepository.getSystemDateTime(
      accountId,
      lmsApiPromise.data.data.token
    )

    // システム日時取得エラー
    if (systemDateTimePromise.status !== apiResponseCode.ok) {
      systemDateTimePromise.data.loginUserInfo = loginUserInfo
      return Promise.reject(systemDateTimePromise)
    }

    let userName = `${accountInfoPromiseData.firstName} ${accountInfoPromiseData.lastName}`
    if (this.isEmpty(userName)) {
      userName = `${accountInfoPromiseData.accountId}`
    }

    // ログイン情報をセット
    loginUserInfo = {
      accountId: accountId,
      userName: userName,
      sessionToken: token,
      lmsApiToken: lmsApiPromise.data.data.token,
      schoolId: accountInfoPromiseData.schoolId,
      schoolName: accountInfoPromiseData.companyName,
      handleCurriculums: accountInfoPromiseData.chargedSubject,
      loginDateTime: systemDateTimePromise.data.data.system_date,
    }

    // パラメータ設定情報の URL パラメータ（クエリストリングの引数）をセット
    const settingUrlParameter = {
      nameConversionItems: lmsApiPromise.data.data.nameConversionParamItems,
      parameterItems: lmsApiPromise.data.data.parameterParamItems,
    }

    return {
      loginUserInfo: loginUserInfo,
      settingUrlParameter: settingUrlParameter,
    }
  },

  /**
   * 選択したグループが削除されているかを取得する
   * @param {String} accountId
   * @param {String} sessionToken
   * @param {String} selectedGroupId
   * @returns 削除されている:True / 削除されていない：false
   */
  async getChangeStateGroupIds(accountId, sessionToken, selectedGroupId) {
    // ログインユーザが管理対象のグループ ID 一覧を取得
    const promise = await this.getGroupIdsOfSukenAccount(
      sukenServiceId.lms,
      accountId,
      sessionToken
    )

    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }

    if (promise.data && promise.data.status !== apiResponseCode.ok) {
      return Promise.reject(promise.data)
    }

    const originalGroupIds = promise.data.groupList.map((item) => {
      return item.groupId
    })

    return !originalGroupIds.includes(Number(selectedGroupId))
  },

  /**
   * Delete 系
   */
}
