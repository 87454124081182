import { render, staticRenderFns } from "./HomeworkAddPdfQuestion.vue?vue&type=template&id=0ea60f61&scoped=true&"
import script from "./HomeworkAddPdfQuestion.vue?vue&type=script&lang=js&"
export * from "./HomeworkAddPdfQuestion.vue?vue&type=script&lang=js&"
import style0 from "./HomeworkAddPdfQuestion.vue?vue&type=style&index=0&id=0ea60f61&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ea60f61",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/git/lms-teachers-site/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0ea60f61')) {
      api.createRecord('0ea60f61', component.options)
    } else {
      api.reload('0ea60f61', component.options)
    }
    module.hot.accept("./HomeworkAddPdfQuestion.vue?vue&type=template&id=0ea60f61&scoped=true&", function () {
      api.rerender('0ea60f61', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/HomeworkAddPdfQuestion.vue"
export default component.exports