var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "icon-circle" }, [
    _c(
      "svg",
      {
        staticClass: "bi bi-caret-down-fill",
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: _vm.width,
          height: _vm.height,
          fill: _vm.fill,
          viewBox: "0 0 16 16"
        }
      },
      [
        _c("path", {
          attrs: {
            d:
              "M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }