<template>
  <div class="icon-circle">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      :fill="circleStyle"
      class="bi bi-circle"
      viewBox="0 0 16 16"
    >
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
    </svg>
  </div>
</template>

<script>
/**
 * Circle （丸）アイコン
 * Bootstrap アイコンを使用
 */
export default {
  name: 'IconCircle',
  props: {
    width: {
      type: String,
      default: function () {
        return '25px'
      },
    },
    height: {
      type: String,
      default: function () {
        return '25px'
      },
    },
    colorStyle: {
      type: String,
      default: function () {
        return '#FFFFFF'
      },
    },
  },

  computed: {
    circleStyle: function () {
      return this.colorStyle ? this.colorStyle : 'currentColor'
    },
  },
}
</script>