<template>
  <div class="link-text">
    <p>{{ labelText }}</p>
  </div>
</template>

<script>
export default {
  name: "LinkText",
  props: {
    labelText: {
      type: String,
      default: function () {
        return ""
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.link-text {
  color: #0d6efd;
  cursor: pointer;
  text-decoration: underline;
}
</style>
