var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "homework-add-pdf-question" },
    [
      _c(
        "div",
        { staticClass: "mt-3 mb-4 mx-4" },
        [
          _c("TitleHomework", {
            ref: "titleArea",
            attrs: { "help-link": _vm.$t("url.helps.addQuestion") }
          }),
          _c("div", { staticClass: "d-flex justify-content-center" }, [
            _c("p", { staticClass: "mb-0 fw-bold" }, [
              _vm._v(" " + _vm._s(_vm.$t("labels.addStdbQuestion")) + " ")
            ])
          ])
        ],
        1
      ),
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row mb-3 stream-data-list" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "div",
                { staticClass: "capacity-bar" },
                [
                  _c("p", { staticClass: "mb-m" }, [
                    _vm._v(" " + _vm._s(_vm.$t("labels.uploadList")) + " ")
                  ]),
                  _c("DataCapacityContent", {
                    attrs: {
                      "max-byte": _vm.maximumLimitSize,
                      "now-byte": _vm.nowSaveSize,
                      "stdb-byte": _vm.stdbSaveSize,
                      "pdf-byte": _vm.pdfSaveSize
                    }
                  })
                ],
                1
              ),
              _vm.showPreviewStdbQuestion
                ? _c(
                    "ModalPreviewStdbQuestion",
                    _vm._g(
                      {
                        attrs: {
                          "task-name": _vm.taskName,
                          "send-memo": _vm.sendMemo,
                          "question-type": _vm.questionType,
                          "image-list": _vm.imageList
                        },
                        on: { close: _vm.onCloseInputStdb }
                      },
                      {
                        save: _vm.onInputStdb,
                        "preview-open-flg": _vm.onPreviewOpen
                      }
                    )
                  )
                : _vm._e(),
              _vm.showResetQuesNumStdbQuestion
                ? _c("ModalResetQuesNumStdbQuestion", {
                    attrs: {
                      "task-name": _vm.taskName,
                      "send-memo": _vm.sendMemo,
                      "question-type": _vm.questionType,
                      "image-list": _vm.imageList,
                      "file-path": _vm.tempFilePath
                    },
                    on: {
                      save: _vm.resetQuesNumStdbQuestion,
                      close: _vm.closeResetQuesNumStdbQuestion
                    }
                  })
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "card card-body overflow-auto my-2 p-3 file-select",
                  on: {
                    dragover: function($event) {
                      $event.preventDefault()
                      _vm.drag = true
                    },
                    dragleave: function($event) {
                      $event.preventDefault()
                      _vm.drag = false
                    },
                    drop: function($event) {
                      $event.preventDefault()
                      return _vm.readDecryptZip.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-8 col-xl-10" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("labels.fileDragAndDropOrFileSelect")) +
                          " "
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "col-4 col-xl-2 button-file-select" },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-black btn-sm px-4 py-1",
                            style: _vm.buttonStyle,
                            attrs: {
                              type: "button",
                              color: "table-row-upload"
                            },
                            on: { click: _vm.onClickFileSelect }
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("buttons.fileSelect")) + " "
                            )
                          ]
                        ),
                        _vm.isChrome
                          ? _c("div", [
                              _c("input", {
                                ref: "input",
                                staticClass: "d-none",
                                attrs: { type: "file", accept: ".spo,.spr" },
                                on: { change: _vm.readDecryptZip }
                              })
                            ])
                          : _c("div", [
                              _c("input", {
                                ref: "input",
                                staticClass: "d-none",
                                attrs: { type: "file" },
                                on: { change: _vm.readDecryptZip }
                              })
                            ])
                      ]
                    )
                  ])
                ]
              ),
              _c(
                "div",
                { key: _vm.updateKey },
                [
                  _c(
                    "TableHomeworkUploadDataList",
                    _vm._g(
                      {
                        attrs: {
                          "upload-data-list": _vm.showUploadItems,
                          "display-count": 5
                        }
                      },
                      {
                        "change-checkbox": _vm.checkUploadTableRow,
                        "delete-upload-data": _vm.deleteUploadTableRow
                      }
                    )
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c("p", { staticClass: "explanation" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("labels.selectStreamDataTheUploadListFirst")) +
            " "
        ),
        _c("br"),
        _vm._v(
          " " + _vm._s(_vm.$t("labels.selectStreamDataTheUploadListLate")) + " "
        )
      ]),
      _c("div", { staticClass: "container-fluid" }, [
        _vm.showErrorMessageFlg
          ? _c("div", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("messages.error.canNotPublishSelectedTextbook")
                  ) +
                  " "
              )
            ])
          : _vm._e(),
        _c("div", { staticClass: "row mb-1 stream-data-list" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "TableHomeworkUploadStreamDataList",
                _vm._g(
                  {
                    attrs: {
                      "stream-data-list": _vm.currentHomeworkDetailItems,
                      "display-count": 5,
                      limit: _vm.homeworkMaximumLimitItems[1].items[0].value,
                      "hide-sort-buttons": true
                    }
                  },
                  {
                    delete: _vm.deleteStreamTableRow,
                    "changed-stream-data-list": _vm.changedStreamDataList
                  }
                )
              )
            ],
            1
          )
        ]),
        _vm._m(0),
        _c("div", { staticClass: "row gx-5 justify-content-center" }, [
          _c(
            "div",
            { staticClass: "col-auto" },
            [
              _c("ButtonBorderCircleMedium", {
                attrs: {
                  "label-name": _vm.$t("buttons.cancel"),
                  "label-color": _vm.colorLayoutTheme,
                  "border-color": _vm.colorLayoutTheme
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.onClickCancel.apply(null, arguments)
                  }
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-auto" },
            [
              _c("ButtonBorderCircleMedium", {
                attrs: {
                  "label-name": _vm.$t("buttons.register"),
                  color: "layout-theme-light",
                  "label-color": _vm.colorLayoutTheme,
                  "border-color": _vm.colorLayoutTheme
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.onClickRegister.apply(null, arguments)
                  }
                }
              })
            ],
            1
          )
        ])
      ]),
      _vm.isLoading ? _c("LoadingScreen") : _vm._e(),
      _vm.showConfirmCaution
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.cautionMessage },
            on: {
              "close-confirm-modal-ok-only": function($event) {
                _vm.showConfirmCaution = false
              }
            }
          })
        : _vm._e(),
      _vm.sessionDisconnect
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.$t("messages.error.overLimitSessionToken") },
            on: {
              "close-confirm-modal-ok-only": function($event) {
                return _vm.onSessionDisconnect()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row px-0 mb-4" }, [
      _c("div", {
        staticClass: "col-12",
        staticStyle: { "text-align": "right" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }