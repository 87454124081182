// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/login/eye_open.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/login/eye_close.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.form-label {\r\n  margin: 0;\n}\n.form-login-password-control {\r\n  height: 50px;\n}\n.form-login-password-field {\r\n  width: 94%;\n}\n.form-login-password-field > label {\r\n  margin: 0;\n}\n::-ms-reveal{\r\n  display: none;\n}\n.passwordBox {\r\n  position: relative;\n}\n.eye_open {\r\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n  background-size: contain;\r\n  width: 100%;\r\n  height: 100%;\r\n  position: absolute;\r\n  cursor: pointer;\n}\n.eye_close {\r\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\r\n  background-size: contain;\r\n  width: 100%;\r\n  height: 100%;\r\n  position: absolute;\r\n  cursor: pointer;\n}\n.field-icon {\r\n  position: absolute;\r\n  top:15px;\r\n  right: 10px;\r\n  width: 25px;\r\n  height: 25px;\n}\r\n", ""]);
// Exports
module.exports = exports;
