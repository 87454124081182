<template>
  <div class="button-close">
    <button
      type="button"
      class="btn"
    >
      <IconClose
        width="25px"
        height="25px"
      />
    </button>
  </div>
</template>

<script>
import IconClose from "@/components/atoms/icons/IconClose.vue"

export default {
  name: "ButtonClose",
  components: {
    IconClose,
  },

  data: () => ({}),
}
</script>

