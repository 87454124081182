<template>
  <div class="modal-edit-category">
    <transition
      name="modal"
      appear
    >
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container-outer">
            <ButtonClose
              class="modal-default-button"
              @click.native="$emit('close')"
            />
            <div
              class="modal-container p-4"
              style="max-height: 80vh"
            >
              <div class="row g-0 category-header">
                <div class="col-auto">
                  <p class="text-start fs-5">
                    カテゴリーの編集
                  </p>
                </div>
              </div>
              <div class="row g-0">
                <div class="col mt-2 mb-2">
                  <div class="category-add">
                    <div
                      class="btn-category-add"
                      @click="onClickEdit(true)"
                    >
                      <img src="@/assets/images/btn_add.png">
                      <div>新規作成</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row category-area">
                <div class="col mt-auto h-100">
                  <template
                    v-for="(categoryItem, idx) in categories"
                  >
                    <div
                      :key="`category-${idx}`"
                      class="category-row row g-0"
                    >
                      <div class="category-col col-auto mt-2">
                        <div class="category-item">
                          <div class="category-name">
                            {{ categoryItem }}
                          </div>
                          <div class="category-btn-area">
                            <img
                              class="btn-edit"
                              src="@/assets/images/btn_edit.png"
                              @click="onClickEdit(false, categoryItem)"
                            >
                            <img
                              class="btn-trashbox"
                              src="@/assets/images/btn_trashbox.png"
                              @click="onClickErase(categoryItem)"
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <ModalInputCategory
      v-if="showInputCategory"
      :initial-category-name="selectedCategory"
      :action-row="actionRow"
      v-on="{
        'on-click-cancel': () => { showInputCategory = false },
        'on-click-ok': onInputCategory,
      }"
    />
    <ModalConfirmOkOnly
      v-if="showOKOnlyMessage"
      :message="okOnlyMessage"
      @close-confirm-modal-ok-only="showOKOnlyMessage = false"
    />
    <ModalConfirm
      v-if="showConfirmDeleteCategory"
      :message="`【${selectedCategory}】を削除してもよろしいですか？`"
      :categoryConfirm="true"
      v-on="{
        'ok-confirm-modal': onOkShowConfirmDeleteCategory,
        'close-confirm-modal': onCloseConfirmModalCategory,
      }"
    />
  </div>
</template>

<script>
/**
 * 宿題管理 - カテゴリー編集モーダル
 */
import { mapGetters } from "vuex"
import ButtonClose from "@/components/atoms/buttons/ButtonClose.vue"
import ModalInputCategory from "@/components/organisms/modal/ModalInputCategory.vue"
import ModalConfirmOkOnly from "@/components/organisms/modal/ModalConfirmOkOnly.vue"
import ModalConfirm from "@/components/organisms/modal/ModalConfirm.vue"

export default {
  name: "ModalEditCategory",
  components: {
    ButtonClose,
    ModalInputCategory,
    ModalConfirm,
    ModalConfirmOkOnly,
  },
  props: {
    isPublished: { type: Boolean },
  },
  data: () => {
    return {
      showInputCategory: false,
      showConfirmDeleteCategory: false,
      selectedCategory: "",
      actionRow: 0,
      showOKOnlyMessage: false,
      okOnlyMessage: "",
    }
  },
  computed: {
    ...mapGetters("homework", [
      "categoryItems",
      "inputGroupType",
      "cmnCategoryItems"
    ]),
    categories() {
      if(this.inputGroupType == "share"){
        // 共有グループ用カテゴリー
        return JSON.parse(JSON.stringify(this.cmnCategoryItems))
      }else{
        // 個別グループ用カテゴリー
        return JSON.parse(JSON.stringify(this.categoryItems))
      }
    },
  },
  methods: {
    /**
     * 編集ボタン押下時
     */
    async onClickEdit(isAdd, v = '') {
      let categoryItems = this.categoryItems;
      if(this.inputGroupType == "share"){
        categoryItems = this.cmnCategoryItems
      }
      
      if (isAdd) {
        if (categoryItems.length >= 10) {
          this.showOKOnlyMessage = true
          this.okOnlyMessage = "作成できるカテゴリーの上限を超えています。"
          return
        }
      }
      this.selectedCategory = v
      this.actionRow = v ? categoryItems.indexOf(v) : categoryItems.length
      this.showInputCategory = true
    },
    /**
     * 編集完了時
     */
    onInputCategory(v) {
      this.$emit('on-input-category', v)
      this.showInputCategory = false
    },
    /**
     * ゴミ箱ボタン押下時
     */
    onClickErase(v) {
      this.selectedCategory = v
      if(this.inputGroupType == "share"){
        this.actionRow = v ? this.cmnCategoryItems.indexOf(v) : this.cmnCategoryItems.length
      }else{
        this.actionRow = v ? this.categoryItems.indexOf(v) : this.categoryItems.length
      }
      this.showConfirmDeleteCategory = true
    },
    /**
     * 削除確認ダイアログ「OK」押下時
     */
    onOkShowConfirmDeleteCategory() {
      this.$emit('on-delete-category', {
        action: 'del',
        actionRow: this.actionRow,
        initialCategoryName: this.selectedCategory,
        categoryName: this.selectedCategory
      })
    },
    /**
     * 削除確認ダイアログ「キャンセル」押下時
     */
    onCloseConfirmModalCategory() {
      this.showConfirmDeleteCategory = false
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 90;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;

    .modal-container-outer {
      width: 760px;
      margin-left: auto;
      margin-right: auto;

      .modal-default-button {
        width: 51px;
        margin-left: auto;
      }

      .modal-container {
        display: flex;
        flex-direction: column;
        width: 730px;
        height: 580px;
        margin: 0px auto;
        padding: 20px 30px;
        background-color: #fff;

        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        text-align: center;

        .category-add {
          display: flex;
          justify-content: start;
          align-items: center;
          .btn-category-add {
            display: flex;
            justify-content: start;
            align-items: center;
            cursor: pointer;
            img {
              width: 30px;
            }
          }
        }

        .category-area {
          overflow-y: scroll;

          .category-row {
          width: 100%;
            .category-col {
              width: 100%;

              .category-item {
                display: flex;
                justify-content: start;
                align-items: center;
                width: 100%;

                border: 2px solid #ececed;
                border-radius: 5px;
                padding: 6px;

                .category-btn-area {
                  margin-left: auto;

                  img {
                    width: 50px;
                    margin-left: 6px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// トランジション（アニメーション）設定
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: opacity 0.8s;
}

.category-header{
  justify-content: space-between;
  .caution{
    font-size: 0.9rem;
    color: red;
  }
}
</style>
