<template>
  <div class="sidebar">
    <SideMenu
      :size="size"
      @change-modal-view="changeModalView"
    />
  </div>
</template>

<script>
import SideMenu from "@/components/molecules/common/SideMenu.vue"

export default {
  name: "Sidebar",
  components: {
    SideMenu,
  },
  props: {
    size: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },

  data: () => ({
    //
  }),

  methods: {
    changeModalView(value) {
      this.$emit('change-modal-view', value)
    }
  }
}
</script>
