<template>
  <div
    id="left-menu-layout"
    class="left-menu-layout"
  >
    <div class="container-fluid mainArea">
      <div class="row h-100">
        <div
          ref="leftArea"
          class="d-inline-block p-0 left-area"
          :class="{ modalStyle: isModalView }"
        >
          <div class="sidbar-fixed">
            <Sidebar
              ref="sidebarArea"
              :size="sidebarAreaSize"
              @change-modal-view="changeModalView"
              @on-logout="onLogout"
            />
          </div>
        </div>
        <div ref="contentsArea" class="d-inline-block p-0 contentsArea">
          <div class="contentsArea-flex">
            <router-view style="height: 100%;"/>
          </div>
        </div>
      </div>
    </div>
    <LoadingScreen v-if="isLoading" />
    <ModalConfirm
      v-if="displayLogoutConfirm"
      :message="$t('messages.confirm.logout')"
      v-on="{
        'ok-confirm-modal': () => { this.$router.push({ name: 'Login' }) },
        'close-confirm-modal': () => { this.displayLogoutConfirm = false },
      }"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
import Sidebar from "@/components/organisms/common/Sidebar.vue"
import LoadingScreen from "@/components/atoms/LoadingScreen.vue"
import ModalConfirm from '@/components/organisms/modal/ModalConfirm.vue'
import accountRepository from "@/repositories/account"
import { sukenServiceId, apiResponseCode } from "@/constant/network"
import { groupType } from "@/constant/homework"

export default {
  name: "LeftMenuLayout",
  components: {
    Sidebar,
    LoadingScreen,
    ModalConfirm,
  },

  data: () => ({
    sidebarAreaSize: {
      width: 0,
      height: 0,
    },
    isModalView: false,
    isLoading: false,
    displayLogoutConfirm: false,
  }),

  computed: {
    ...mapGetters(["loginUserInfo"]),
    ...mapGetters("nameConversions", ["curriculumConversionTable"]),
  },

  created: function () {
    // インスタンスを作成した後、イベントリスナに登録
    window.addEventListener("resize", this.onHandleResize, false)
  },
  mounted: function () {
    this.onHandleResize()
  },
  beforeDestroy: function () {
    // インスタンスを破棄する前に、イベントリスナから削除
    window.removeEventListener("resize", this.onHandleResize, false)
  },

  methods: {
    ...mapMutations([
      "setGroupIdsOfLoginUser",
      "setHandleCurriculumsOfLoginUser",
    ]),

    getSidebarAreaWidth: function () {
      return this.$refs.sidebarArea.$el.getBoundingClientRect().width
    },
    getSidebarAreaHeight: function () {
      return this.$refs.leftArea.clientHeight
    },
    onHandleResize: function () {
      this.sidebarAreaSize.width = this.getSidebarAreaWidth()
      this.sidebarAreaSize.height = this.getSidebarAreaHeight()
    },

    changeModalView(value) {
      this.isModalView = value;
    },

    /**
     * ログアウト
     */
    onLogout() {
      this.displayLogoutConfirm = true;
    },

    /**
     * グループリスト再取得
     */
    async refreshGroupList() {
      this.isLoading = true
      try {
        let groupIdPromise = null
        let sukenAccountInfoPromise = null
        try {
          groupIdPromise = await accountRepository.getGroupIdsOfSukenAccount(
            sukenServiceId.lms,
            groupType.all,
            this.loginUserInfo.accountId,
            this.loginUserInfo.sessionToken
          )
          sukenAccountInfoPromise = await accountRepository.getSukenAccountInfo(
            sukenServiceId.lms,
            this.loginUserInfo.accountId,
            this.loginUserInfo.sessionToken
          )
        } catch (error) {
          this.$router.push({
            name: "APIError",
            params: { status: error.status },
          })
        }

        // グループID
        this.setGroupIdsOfLoginUser(groupIdPromise.data.groupList)

        // 担当教科
        const curriculums = sukenAccountInfoPromise.data.chargedSubject
        // 教科IDを変換
        let isError = false
        const handleCurriculums = curriculums.map((code) => {
          let item = this.curriculumConversionTable.find(
            (item) => code == item.code
          )

          if (!item) {
            console.error(`Invalid curriculum code: ${code}`)
            isError = true
          }
          return Number(item.value)
        })

        if (isError) {
          // 変換できなかったものがあった場合は403エラーとする
          this.$router.push({
            name: "APIError",
            params: { status: apiResponseCode.forbidden },
          })
          return
        }
        this.setHandleCurriculumsOfLoginUser(handleCurriculums)
      } finally {
        this.isLoading = false
      }
    },
    resetScroll(){
      this.$refs.contentsArea.scrollTop = 0;
    }
  },
}
</script>

<style lang="scss" scoped>
.left-menu-layout {
  position: relative;
  height: 100%;
  min-height: 100%;
}

.container-fluid {
  position: absolute;
}

.left-area {
  height: 100%;
  width: 180px;
}
.modalStyle {
  z-index: 45;
}

.sidbar-fixed {
  position: sticky;
  top: 0;
  height: 100%;
}

.contentsArea {
  height: 100%;
  width: calc(100% - 180px);
  overflow-y: auto;
}
.right-contents-area {
  position: relative;
}
.mainArea {
  height: 100%;
}
.contentsArea-flex {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
}
</style>
