<template>
  <div class="table-homework-upload-data-list">
    <!--
      行数は最大表示数 + ヘッダ１行分とする。
      １行の高さはCSS部分で設定しているものに対して少し余裕を持たせたものとしている。
      ※丁度に設定すると何故か最大表示数と要素数が一致した時でもスクロールバーが活性化されてしまう為。
    -->
    <div
      class="table-wrap"
      :style="{ 'max-height': `${42 * (displayCount + 1)}px` }"
    >
      <table>
        <thead>
          <tr>
            <th
              v-for="(header, index) in headers"
              :key="index"
            >
              <div>{{ header }}</div>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(item, index) in dataList"
            :key="index"
            class="text-center px-1"
          >
            <template v-if="item">
              <!-- チェックボックス -->
              <td>
                <input
                  ref="checkboxes"
                  v-model="item.checkFlg"
                  type="checkbox"
                  @change="checkTableRow($event, item, index)"
                >
              </td>
              <!-- 課題名 -->
              <td class="text-start px-1">
                <div class="row">
                  <div class="col-12">
                    <div class="show-three-point">
                      {{ item.taskName }}
                    </div>
                  </div>
                </div>
              </td>
              <!-- 最終利用日 -->
              <td class="text-center px-1">
                {{ item.displayEndUseDate }}
              </td>
              <!-- 公開終了日 -->
              <td class="text-center px-1">
                {{ item.displayEndPubDate }}
              </td>
              <!-- 送信メモ -->
              <td class="text-start px-1">
                <div class="row">
                  <div class="col-12">
                    <div class="show-three-point">
                      {{ item.sendMemo }}
                    </div>
                  </div>
                </div>
              </td>
              <!-- プレビューアイコンクリック時にプレビューモーダルを表示する -->
              <td>
                <ButtonPreviewUpload @click.native="onClickPreview(item)" />
              </td>
              <td>
                <ButtonDeleteUpload
                  :disabled="isPublished"
                  @click.native="onClickDelete(item, index)"
                />
              </td>
            </template>
            <template v-else>
              <!-- colspanの値はitemがnullでない場合の表示数と合わせる -->
              <!-- 1選択,2課題名,3アップロード日,4公開終了日,5進捗メモ,6プレビュー,7削除 -->
              <td colspan="7" />
            </template>
          </tr>
        </tbody>
      </table>
    </div>

    <ModalConfirm
      v-if="showConfirmDelete"
      :message="
        $t('messages.confirm.deleteUploadFile', {
          fileName: deleteFileName,
        })
      "
      v-on="{
        'ok-confirm-modal': okConfirmModal,
        'close-confirm-modal': closeConfirmModal,
      }"
    />
    <ModalPreviewPdfQuestion
      v-if="showPreviewPdfQuestion"
      :task-name="taskName"
      :send-memo="sendMemo"
      :image-list="imageList"
      :default-question-count="questionQuantity"
      :is-disabled="true"
      @close="showPreviewPdfQuestion = false"
    />

    <ModalPreviewStdbQuestion
      v-if="showPreviewStdbQuestion"
      :task-name="taskName"
      :send-memo="sendMemo"
      :prm-question-type="itemQuestionType"
      :prm-question-quantity="questionQuantity"
      :image-list="imageList"
      :ans-disp-flg="ansDispFlg"
      :explain-disp-flg="explainDispFlg"
      :is-disabled="true"
      @close="showPreviewStdbQuestion = false"
    />
    <ModalConfirmOkOnly
      v-if="showConfirmCaution"
      :message="cautionMessage"
      @close-confirm-modal-ok-only="showConfirmCaution = false"
    />
    <LoadingScreen v-if="isLoading" />
  </div>
</template>

<script>
import { db } from "@/dbs/indexedDb"
import mixin from "@/mixins/mixin"
import ButtonPreviewUpload from "@/components/atoms/buttons/ButtonPreviewUpload.vue"
import ButtonDeleteUpload from "@/components/atoms/buttons/ButtonDeleteUpload.vue"
import ModalConfirm from "@/components/organisms/modal/ModalConfirm.vue"
import { homeworkTypeCode } from "@/constant/homework"
import ModalPreviewPdfQuestion from "@/components/organisms/modal/ModalPreviewPdfQuestion.vue"
import ModalPreviewStdbQuestion from "@/components/organisms/modal/ModalPreviewStdbQuestion.vue"
import ModalConfirmOkOnly from "@/components/organisms/modal/ModalConfirmOkOnly.vue"
import LoadingScreen from "@/components/atoms/LoadingScreen.vue"
import homeworkApi from "@/api/homework"
import axios from "axios"
import convertValue from "@/repositories/convert-value"

export default {
  name: "TableHomeworkUploadDataList",
  components: {
    ButtonPreviewUpload,
    ButtonDeleteUpload,
    LoadingScreen,
    ModalConfirm,
    ModalPreviewPdfQuestion,
    ModalPreviewStdbQuestion,
    ModalConfirmOkOnly,
  },

  mixins: [mixin],

  props: {
    questionType: {
      type: Number,
      require: true,
      default: function () {
        return 0
      },
    },
    uploadDataList: {
      type: Array,
      default: function () {
        return []
      },
    },
    isPublished: Boolean,
    displayCount: {
      type: Number,
      default: function () {
        return 0
      },
    },
    streamGroupMember: {
      type: Array,
      default: function () {
        return []
      },
    },
  },

  data: () => ({
    headers: [
      "選択",
      "課題名",
      "最終利用日",
      "公開終了日",
      "送信メモ",
      "プレビュー",
      "削除",
    ],
    showConfirmCaution: false, // 警告確認
    cautionMessage: "", // 警告メッセージ
    items: [],
    taskName: "", // 課題名
    sendMemo: "", // 送信メモ
    showConfirmDelete: false, // 削除確認ダイアログ表示フラグ
    colorLayoutTheme: "#f2f2f2", // レイアウトテーマカラー
    checkItem: {},
    checkItemIndex: -1,
    checkHomeworkName: "",
    deleteItem: {},
    deleteItemIndex: -1,
    deleteFileName: "",
    showPreviewPdfQuestion: false, // PDFダイアログ表示フラグ
    showPreviewStdbQuestion: false, // STDBダイアログ表示フラグ
    imageList: null, // 画像情報
    itemQuestionType: 0, // 問題種別
    questionQuantity: 0, // 問題数
    ansDispFlg: false, // 答えチェックフラグ
    explainDispFlg: false, // 解説チェックフラグ
    isLoading: false,
    // isHiddenQuesCount: false,       // 問題欄非表示フラグ
  }),

  computed: {
    dataList: {
      get: function () {
        const length = Math.max(this.uploadDataList.length, this.displayCount)
        const list = []
        // データリストの日付データから表示用データを作成
        for (let i = 0; i < length; i++) {
          const item =
            i < this.uploadDataList.length
              ? JSON.parse(JSON.stringify(this.uploadDataList[i]))
              : null
          if (item) {
            this.$set(item, "index", i)
            // 最終利用日・公開終了日のゼロパディングを取り除く
            const removeZeroPaddingFromDate = (date) => {
              const replaced = date.replace(/\//g, "")
              // 区切り文字を取り除いたものをconvertValueに投げて再構築する
              // ※yyyy/MM/dd形式でなかった場合は元の値を返す
              return replaced.length < 8
                ? date
                : convertValue.toDateStringSeparator(replaced)
            }
            // 元のプロパティに再セットするのではなく、表示用プロパティに値をセットする
            // （再セットすると宿題作成APIのリクエストに影響が出るので）
            this.$set(
              item,
              "displayEndUseDate",
              removeZeroPaddingFromDate(item.endUseDate)
            )
            this.$set(
              item,
              "displayEndPubDate",
              removeZeroPaddingFromDate(item.endPubDate)
            )
          }
          list.push(item)
        }
        return list
      },
      set: function (value) {
        this.$emit("changed-upload-data-list", value)
      },
    },
  },

  methods: {
    // テーブル行チェック処理
    checkTableRow: function (event, item, index) {
      this.checkItem = item
      this.checkItemIndex = index
      // チェック処理
      return this.$emit("change-checkbox", {
        check: event.target.checked,
        item: this.checkItem,
        index: index,
      })
    },

    /**
     * チェックが入った時にメッセージの表示、非表示を切り替える
     */
    onChangeMessageDisplayFlag(item) {
      this.$emit("change-display-message", item)
    },

    /**
     * プレビューボタン押下処理
     */
    onClickPreview: async function (item) {
      this.isLoading = true

      this.taskName = item.taskName // 課題名
      this.sendMemo = item.sendMemo // 送信メモ
      this.itemQuestionType = item.questionType // 問題種別
      if (0 === item.haishinFileNo) {
        // 0：新規追加の場合はIndexedDBから取得
        const promise = await db.idbQuestionImage.get(
          item.subQuestionInfo.filePath
        )

        if (promise !== undefined) {
          this.imageList = promise.uploadFileList
        } else {
          this.imageList = []
        }
      } else {
        // 0以外：APIを実行し取得
        let streamFileInfo = await this.getHomeworkStreamFileInfo(
          item.haishinFileNo
        )
        let previewInfoList = streamFileInfo.data.data.preview_info_list[0]
        item.questionType = previewInfoList.homework_syubetu_kbn
        this.itemQuestionType = previewInfoList.homework_syubetu_kbn
        this.imageList = await this.getApiImageList(streamFileInfo)

        // 画像情報が読み込めなかった場合配信終了エラー表示
        if (this.imageList === null) {
          // メッセージ出力
          this.showModalOkOnly(this.$t("messages.error.uploadPublicEndPreview"))
          this.isLoading = false
          return
        }
      }

      this.isLoading = false
      this.questionQuantity =  this.getQuestionCount(item) // 問題数
      // questionTypeによってプレビューを切り替える
      switch (this.itemQuestionType) {
        // PDF
        case homeworkTypeCode.pdf:
          this.showPreviewPdfQuestion = true
          break
        // Stdb
        case homeworkTypeCode.stdbLayout:
          this.showPreviewStdbQuestion = true
          break
        case homeworkTypeCode.stdb:
          this.ansDispFlg = item.ansDispFlg // 答え表示フラグ
          this.explainDispFlg = item.explainDispFlg // 解説表示フラグ
          this.showPreviewStdbQuestion = true
          break
      }
    },
    
    /**
     * 問題数を取得
     */
    getQuestionCount: function (item) {
      if ((String(typeof item.questionCount) === 'Array' && item.questionCount.length) ||
          item.questionCount > 0) { return item.questionCount }
      
      // 無ければ生成
      if (this.itemQuestionType === homeworkTypeCode.stdb) {
        // １問ずつ
        return this.imageList.filter(img => img.file_name.match(/^Q\d/)).map(() => 1)
      } else {
        // レイアウト通り or PDF
        return 1
      }
    },

    /**
     * 画像リストを取得
     */
    getApiImageList: async function (streamFileInfo) {
      if (streamFileInfo.status !== 200) {
        // ファイル保持期限切れ
        return null
      }
      let fileList = streamFileInfo.data.data.upload_file_list
      if (fileList.length == 0) {
        // ファイル削除済
        return null
      }
      let fileImgList = []
      for (let i = 0; i < fileList.length; i++) {
        let item = fileList[i]
        let url = item.file_path
        let base64 = await this.downloadImageToBase64(url)

        fileImgList.push({
          file_image: base64,
          file_name: item.file_name,
        })
      }
      return fileImgList
    },

    /**
     * アップロード一覧取得
     */
    getHomeworkStreamFileInfo: async function (haishinFileNo) {
      return await homeworkApi.getHomeworkStreamFileInfo(
        this.loginUserInfo.accountId, // アカウントID
        this.loginUserInfo.schoolId, // 学校ID
        0, // モード(0:アップロード一覧、1:配信一覧)
        0, // 宿題キー
        haishinFileNo, // 配信ファイル連番
        this.loginUserInfo.lmsApiToken // APIトークン
      )
    },

    /**
     * 画像ファイルダウンロード
     */
    downloadImageToBase64: async function (externalUrl) {
      let retBase64 = null
      try {
        const response = await axios.get(externalUrl, {
          responseType: "arraybuffer",
        })
        // arrayBuffer -> blob
        const blob = new Blob([response.data], { type: "image/png" })

        // blob -> base64
        await new Promise((res) => {
          let reader = new FileReader()
          reader.onload = () => {
            res(reader.result)
          }
          reader.readAsDataURL(blob)
        }).then((ret) => {
          retBase64 = ret
        })
      } catch (ex) {
        // 空情報を返す（配信期限切れ）
      }

      return retBase64
    },

    /**
     * 削除クリック処理
     */
    onClickDelete: function (item, index) {
      this.deleteItem = item
      this.deleteItemIndex = index
      this.deleteFileName = item.taskName

      // 削除処理を実行する
      this.showConfirmDelete = true
    },

    /**
     * 削除確認後処理
     */
    okConfirmModal: function () {
      // 親コンポーネントの宿題削除処理を実行
      this.$emit("delete-upload-data", this.deleteItem)
      this.showConfirmDelete = false
    },

    /*
     * 閉じる確認モーダル
     */
    closeConfirmModal: function () {
      this.showConfirmDelete = false
    },

    /**
     * OKだけのモーダルを表示する
     */
    showModalOkOnly: function (message) {
      this.cautionMessage = message
      this.showConfirmCaution = true
    },
  },
}
</script>

<style lang="scss" scoped>
.show-three-point {
  max-width: fit-content;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.caution {
  cursor: pointer;
}

.table-wrap {
  overflow-y: scroll;

  table {
    table-layout: fixed;
    width: 100%;
    border: solid 1px #b3b3b3;

    thead {
      position: sticky;
      top: 0;
      // 暫定対応なので別の箇所でz-indexを設定する時のレスポンス対応時は考慮が必要
      z-index: 1;
      tr {
        height: 40px;
        background: var(--bs-table-header-upload); // 背景色
        color: var(--bs-table-font-upload); // 文字色

        // 選択
        th:nth-child(1) {
          text-align: center;
          width: 10%;
          @media (max-width: 991px) {
            width: 12%;
            text-align: center;
          }
        }
        // 課題名
        th:nth-child(2) {
          text-align: center;
        }
        // 最終利用日
        th:nth-child(3) {
          text-align: center;
          width: 10%;
          @media (max-width: 1084px) {
            width: 12%;
            text-align: center;
          }
          @media (max-width: 991px) {
            width: 17%;
            text-align: center;
          }
        }
        // 公開終了日
        th:nth-child(4) {
          text-align: center;
          width: 10%;
          @media (max-width: 1084px) {
            width: 12%;
            text-align: center;
          }
          @media (max-width: 991px) {
            width: 17%;
            text-align: center;
          }
        }
        // 送信メモ
        th:nth-child(5) {
          text-align: center;
          width: 15%;
        }
        // プレビュー
        th:nth-child(6) {
          text-align: center;
          width: 10%;
          @media (max-width: 991px) {
            width: 12%;
            text-align: center;
          }
        }
        // 削除
        th:nth-child(7) {
          text-align: center;
          width: 10%;
        }
      }
    }

    tbody {
      tr {
        height: 40px;

        // 選択
        th:nth-child(1) {
          text-align: center;
          width: 10%;
        }
        // 課題名
        th:nth-child(2) {
          text-align: start;
        }
        // 最終利用日
        th:nth-child(3) {
          text-align: center;
          width: 15%;
        }
        // 公開終了日
        th:nth-child(4) {
          text-align: center;
          width: 15%;
        }
        // 送信メモ
        th:nth-child(5) {
          text-align: start;
          width: 15%;
        }
        // プレビュー
        th:nth-child(6) {
          width: 10%;
          // アイコンクリック範囲設定
          ::v-deep .button-preview {
            display: contents;
            width: 25px;
            height: 25px;
          }
        }
        // 削除
        th:nth-child(7) {
          width: 10%;
          // アイコンクリック範囲設定
          ::v-deep .button-preview {
            display: contents;
            width: 25px;
            height: 25px;
          }
        }
      }
      tr:nth-child(odd) {
        background: var(--bs-table-row-upload);
      }
    }
  }
}
</style>
