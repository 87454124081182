<template>
  <div class="modal-progress-preview-stdb-pdf-question">
    <transition
      name="modal"
      appear
    >
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container-outer">
            <ButtonClose
              class="modal-default-button"
              @click.native="$emit('close')"
            />
            <div class="modal-container px-2 py-4">
              <div class="row mb-4 task-name">
                <div
                  v-if="
                    item.page == 'progress' && selectedHomeworkItemIndex !== 0
                  "
                  class="col-2"
                >
                  <div class="show-three-point">
                    No.{{ selectedHomeworkItemIndex }}
                  </div>
                </div>
                <div class="col-8">
                  <div class="show-three-point">
                    {{ item.taskName }}
                  </div>
                </div>
                <div
                  v-if="filePaths.length"
                  class="col-2"
                >
                  {{ currentIndex + 1 + " / " + filePaths.length }}
                </div>
              </div>

              <div
                class="card w-90"
                style="margin-right: 5%; margin-left: 5%"
              >
                <div class="card-header">
                  {{ $t("labels.preview") }}
                </div>
                <div class="py-3 card-body preview-window window-body">
                  <div class="row mb-2 preview-image">
                    <div class="col-1 page-transition-button">
                      <i
                        v-if="hasPrevPage"
                        class="bi bi-chevron-left"
                        @click="currentIndex--"
                      />
                    </div>
                    <div class="col-10">
                      <div class="card">
                        <img
                          v-if="currentPath"
                          :src="currentPath"
                        >
                      </div>
                    </div>
                    <div class="col-1 page-transition-button">
                      <i
                        v-if="hasNextPage"
                        class="bi bi-chevron-right"
                        @click="currentIndex++"
                      />
                    </div>
                  </div>
                  <LoadingScreen v-if="isLoading" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <ModalConfirmOkOnly
      v-if="showConfirmCaution"
      :message="cautionMessage"
      @close-confirm-modal-ok-only="closeModal"
    />
  </div>
</template>

<script>
import mixin from "@/mixins/mixin"
import ButtonClose from "@/components/atoms/buttons/ButtonClose.vue"
import LoadingScreen from "@/components/atoms/LoadingScreen.vue"
import homeworkRepository from "@/repositories/homework"
import ModalConfirmOkOnly from "@/components/organisms/modal/ModalConfirmOkOnly.vue"

export default {
  name: "ModalProgressPreviewStdbPdfQuestion",
  components: {
    ButtonClose,
    LoadingScreen,
    ModalConfirmOkOnly,
  },
  mixins: [mixin],
  props: {
    item: {
      type: Object,
      require: true,
      default: function () {
        return {}
      },
    },
    selectedHomeworkItemIndex: {
      type: Number,
      default: function () {
        return 0
      },
    },
  },
  data: function () {
    return {
      isLoading: false,
      filePaths: [],
      currentIndex: 0,
      showConfirmCaution: false,
      cautionMessage: "",
    }
  },
  computed: {
    /**
     * 現在のパス
     */
    currentPath: function () {
      if (this.filePaths.length === 0) {
        return ""
      }
      return this.filePaths[this.currentIndex]
    },
    /**
     * 前のページがあるか？
     */
    hasPrevPage: function () {
      return this.currentIndex > 0
    },
    /**
     * 次のページがあるか？
     */
    hasNextPage: function () {
      return this.currentIndex < this.filePaths.length - 1
    },
  },
  async mounted() {
    let filesInfo
    if (this.item.fileList.length === 0) {
      this.isLoading = true
      try {
        filesInfo = await this.fetchFilesList()
      } catch (error) {
        // メッセージ出力
        if (this.item.isPublished) {
          this.showModalOkOnly(
            this.$t("messages.error.uploadPublicEndPreviewForPublish")
          )
        } else {
          this.showModalOkOnly(this.$t("messages.error.uploadPublicEndPreview"))
        }
        this.isLoading = false
        return
      }
    } else {
      filesInfo = this.item.fileList.map((file) => {
        return {
          fileName: file.file_name,
          filePath: file.file_image,
        }
      })
    }

    this.filePaths = filesInfo.map((x) => x.filePath)
    this.isLoading = false
  },
  methods: {
    /**
     * ファイルリストを取得
     */
    fetchFilesList: async function () {
      const result = await homeworkRepository.getHomeworkStreamFileListForPreview(
        this.loginUserInfo.accountId,
        this.loginUserInfo.schoolId,
        this.item.mode,
        this.item.key,
        this.item.keyNo,
        this.loginUserInfo.lmsApiToken
      )
      return result;
    },
    /*
     * 閉じるモーダル
     */
    closeModal: function () {
      this.showConfirmCaution = false
      this.$emit("close")
    },

    /**
     * OKだけのモーダルを表示する
     */
    showModalOkOnly: function (message) {
      this.cautionMessage = message
      this.showConfirmCaution = true
    },
  },
}
</script>

<style lang="scss" scoped>
.show-three-point {
  max-width: fit-content;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.task-name {
  width: 80% !important;
  margin: 0 auto !important;
  @media (max-width: 1020px) {
    width: 100% !important;
  }
}
.page-transition-button {
  margin: auto 0;
  cursor: pointer;

  i {
    font-size: 48px;
    color: #0070c0;
  }
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;

    .modal-container-outer {
      width: 72vw;
      margin-left: auto;
      margin-right: auto;

      .modal-default-button {
        width: 51px;
        margin-left: auto;
      }

      .modal-container {
        overflow: auto;
        max-height: 80vh;
        margin-left: auto;
        margin-right: auto;
        padding: 20px 30px;
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        text-align: center;
        width: 70vw !important;

        .preview-image {
          img {
            width: 90%;
            -webkit-user-drag: none;
            user-select: none;
          }
        }
      }
    }
  }
}
.card-header {
  height: 7%;
  padding: 0.4rem;
  border-bottom: none;
  background: var(--bs-table-header);
  color: var(--bs-layout-theme);
  font-weight: bold;
}
.preview-window {
  padding: 30px 30px;
}
// トランジション（アニメーション）設定
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: opacity 0.8s;
}
</style>
