<template>
  <div class="icon-circle">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      :fill="dashStyle"
      class="bi bi-dash"
      viewBox="0 0 16 16"
    >
      <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
    </svg>
  </div>
</template>

<script>
/**
 * Dash （マイナス）アイコン
 * Bootstrap アイコンを使用
 */
export default {
  name: 'IconDash',
  props: {
    width: {
      type: String,
      default: function () {
        return '25px'
      },
    },
    height: {
      type: String,
      default: function () {
        return '25px'
      },
    },
    colorStyle: {
      type: String,
      default: function () {
        return '25px'
      },
    },
  },

  computed: {
    dashStyle: function () {
      return this.colorStyle ? this.colorStyle : 'currentColor'
    },
  },
}
</script>