<template>
  <div class="button-return">
    <button
      type="button"
      :class="['btn', 'btn-' + color]"
      :style="buttonStyle"
    >
      <div class="d-flex flex-row">
        <IconReturn
          width="15px"
          height="15px"
        />
        &nbsp;
        {{ labelName }}
      </div>
    </button>
  </div>
</template>

<script>
import IconReturn from "@/components/atoms/icons/IconReturn.vue"

export default {
  name: "ButtonReturn",
  components: {
    IconReturn,
  },
  props: {
    labelName: {
      type: String,
      default: function () {
        return ''
      },
    },
    color: {
      type: String,
      default: function () {
        return ''
      },
    },
    labelColor: {
      type: String,
      default: function () {
        return ''
      },
    },
    borderColor: {
      type: String,
      default: function () {
        return ''
      },
    },
  },

  computed: {
    buttonStyle: function () {
      return {
        color: this.labelColor,
        border: `solid 1px ${this.borderColor}`,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  border-radius: 2rem;
  padding: 0.5em 1.2em 0.5em;
  font-weight: bold;
  font-size: 0.9em;
}
</style>