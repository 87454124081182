var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "icon-qestion" }, [
    _c(
      "svg",
      {
        staticStyle: { "enable-background": "new 0 0 70.6 70.6" },
        attrs: {
          id: "レイヤー_1",
          version: "1.1",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          x: "0px",
          y: "0px",
          width: _vm.width,
          height: _vm.height,
          viewBox: "0 0 70.6 70.6",
          "xml:space": "preserve"
        }
      },
      [
        _c("g", [
          _c("path", {
            staticClass: "st0",
            attrs: {
              d:
                "M47.9,25.5H19.7c-1.2,0-2.1-1-2.1-2.1c0-1.2,1-2.1,2.1-2.1h28.2c1.2,0,2.1,1,2.1,2.1\n        C50,24.6,49.1,25.5,47.9,25.5z",
              fill: _vm.fillColor
            }
          }),
          _c("path", {
            staticClass: "st0",
            attrs: {
              d:
                "M47.9,36.9H19.7c-1.2,0-2.1-1-2.1-2.1c0-1.2,1-2.1,2.1-2.1h28.2c1.2,0,2.1,1,2.1,2.1\n        C50,36,49.1,36.9,47.9,36.9z",
              fill: _vm.fillColor
            }
          }),
          _c("path", {
            staticClass: "st0",
            attrs: {
              d:
                "M47.9,48.3H19.7c-1.2,0-2.1-1-2.1-2.1c0-1.2,1-2.1,2.1-2.1h28.2c1.2,0,2.1,1,2.1,2.1\n        C50,47.4,49.1,48.3,47.9,48.3z",
              fill: _vm.fillColor
            }
          }),
          _c("path", {
            staticClass: "st0",
            attrs: {
              d:
                "M67.6,62.8l-3.4,3.4c-0.4,0.4-0.9,0.4-1.3,0L45.3,48.6c-0.4-0.4-0.4-0.9,0-1.3l3.4-3.4c0.4-0.4,0.9-0.4,1.3,0\n        l17.6,17.6C67.9,61.8,67.9,62.4,67.6,62.8z",
              fill: _vm.fillColor
            }
          }),
          _c("path", {
            staticClass: "st0",
            attrs: {
              d:
                "M48,56.3H12.9V13.3h41.9v30.7l4.2,4.2v-37c0-1.2-0.9-2.1-2.1-2.1H10.8c-1.2,0-2.1,0.9-2.1,2.1v47.1\n        c0,1.2,0.9,2.1,2.1,2.1h41.4L48,56.3z",
              fill: _vm.fillColor
            }
          })
        ]),
        _c("rect", {
          staticClass: "st1",
          staticStyle: { fill: "none" },
          attrs: { x: "0", y: "0", width: "70.6", height: "70.6" }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }