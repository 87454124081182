var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "check-box" }, [
    _c("label", { attrs: { for: _vm.id } }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.inputValue,
            expression: "inputValue"
          }
        ],
        attrs: { id: _vm.id, type: "checkbox", disabled: _vm.disabled },
        domProps: {
          checked: Array.isArray(_vm.inputValue)
            ? _vm._i(_vm.inputValue, null) > -1
            : _vm.inputValue
        },
        on: {
          change: [
            function($event) {
              var $$a = _vm.inputValue,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.inputValue = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.inputValue = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.inputValue = $$c
              }
            },
            function($event) {
              return _vm.$emit("input", $event.target.checked)
            }
          ]
        }
      }),
      _vm._v(" " + _vm._s(_vm.label) + " ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }