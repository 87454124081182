var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "topElement",
      staticClass: "note-image_top-element",
      style: { cursor: _vm.topElementCursor }
    },
    [
      _c(
        "div",
        {
          ref: "imgContainer",
          staticClass: "note-image_image-rect",
          style: {
            top: _vm.imageRectPoint.y + "px",
            left: _vm.imageRectPoint.x + "px",
            width: _vm.imageRectWidth + "px",
            height: _vm.imageRectHeight + "px",
            transform: "scale(" + _vm.imageRectScale + ")",
            "transform-origin": _vm.imageRectOrigin
          }
        },
        [
          _c(
            "div",
            {
              ref: "imageScale",
              staticClass: "note-image_image-scale",
              style: {
                transform: "scale(" + _vm.scale + ")",
                left: _vm.scaleRectPoint.x + "px",
                top: _vm.scaleRectPoint.y + "px"
              }
            },
            [
              _vm.isShowImg
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "note-image_q-img-wrap",
                        style: {
                          top: _vm.teacherPaddingTop + "px",
                          left: _vm.teacherPaddingLeft + "px",
                          width: _vm.imgWidth + "px",
                          height:
                            _vm.imgHeight + _vm.imgHeightCalibrationValue + "px"
                        }
                      },
                      [
                        _c("img", {
                          staticClass: "note-image_q-img",
                          class: { "note-image_stdb-custom": _vm.isStdb },
                          attrs: { src: _vm.imgSource }
                        }),
                        _vm.showWriteInfoStudent
                          ? _c(
                              "div",
                              {
                                staticClass: "note-image_student",
                                style: {
                                  top: _vm.teacherPaddingTop,
                                  left: _vm.teacherPaddingLeft
                                }
                              },
                              [
                                _c("HomeworkWriteInfo", {
                                  attrs: {
                                    "write-info": _vm.writeInfoStudent,
                                    "img-width": _vm.imgWidth,
                                    "img-height": _vm.imgHeight,
                                    "page-rate": _vm.pageRateStudent,
                                    "config-page":
                                      _vm.writeInfoStudent.configPage,
                                    cond: _vm.writeInfoStudent.cond,
                                    "homework-syubetu-kbn":
                                      _vm.homeworkSyubetuKbn
                                  },
                                  on: {
                                    "update:configPage": function($event) {
                                      return _vm.$set(
                                        _vm.writeInfoStudent,
                                        "configPage",
                                        $event
                                      )
                                    },
                                    "update:config-page": function($event) {
                                      return _vm.$set(
                                        _vm.writeInfoStudent,
                                        "configPage",
                                        $event
                                      )
                                    },
                                    "update:cond": function($event) {
                                      return _vm.$set(
                                        _vm.writeInfoStudent,
                                        "cond",
                                        $event
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "note-image_teacher",
                        style: {
                          width: _vm.imageRectWidth + "px",
                          height: _vm.imageRectHeight + "px"
                        }
                      },
                      [
                        _c(
                          "HomeworkWriteInfo",
                          _vm._g(
                            {
                              ref: "writeInfoTeacher",
                              attrs: {
                                "pointer-element": _vm.imageScaleElement,
                                "is-teacher-mode": true,
                                "write-info": _vm.writeInfoTeacher,
                                "img-width": _vm.imageRectWidth,
                                "img-height": _vm.imageRectHeight,
                                "page-rate": _vm.pageRateTeacher,
                                "config-page": _vm.configPageTeacher,
                                cond: _vm.condTeacher,
                                "homework-syubetu-kbn": _vm.homeworkSyubetuKbn,
                                "base-scale": _vm.baseScale,
                                editable: _vm.editable,
                                "write-info-object-to-string":
                                  _vm.writeInfoObjectToString
                              },
                              on: {
                                "update:writeInfo": function($event) {
                                  _vm.writeInfoTeacher = $event
                                },
                                "update:write-info": function($event) {
                                  _vm.writeInfoTeacher = $event
                                },
                                "update:configPage": function($event) {
                                  _vm.configPageTeacher = $event
                                },
                                "update:config-page": function($event) {
                                  _vm.configPageTeacher = $event
                                },
                                "update:cond": function($event) {
                                  _vm.condTeacher = $event
                                }
                              }
                            },
                            {
                              showConfirmForOverWriteInfoSize: function() {
                                return _vm.$emit(
                                  "showConfirmForOverWriteInfoSize"
                                )
                              },
                              drawStart: function() {
                                return _vm.$emit("drawStart")
                              },
                              drawEnd: function() {
                                return _vm.$emit("drawEnd")
                              }
                            }
                          )
                        )
                      ],
                      1
                    )
                  ]
                : [_vm._v(" No Imaged ")],
              _c("div", { staticClass: "rect-wrap" }, [
                _vm.zoomRangeRect
                  ? _c("div", {
                      staticClass: "zoom-range-rect",
                      style: {
                        top: _vm.zoomRangeRect.y + "px",
                        left: _vm.zoomRangeRect.x + "px",
                        width: _vm.zoomRangeRect.width + "px",
                        height: _vm.zoomRangeRect.height + "px"
                      }
                    })
                  : _vm._e()
              ])
            ],
            2
          )
        ]
      ),
      !_vm.draggable
        ? _c("div", {
            staticStyle: {
              width: "100%",
              height: "100%",
              position: "absolute",
              top: "0",
              left: "0",
              "background-color": "rgba(0, 0, 0, 0)",
              "pointer-events": "all"
            }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }