var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-reset-ques-num-stdb-question" },
    [
      _c("transition", { attrs: { name: "modal", appear: "" } }, [
        _c("div", { staticClass: "modal-mask" }, [
          _c("div", { staticClass: "modal-wrapper" }, [
            _c(
              "div",
              { staticClass: "modal-container-outer" },
              [
                _c("ButtonClose", {
                  staticClass: "modal-default-button",
                  nativeOn: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                }),
                _c("div", { staticClass: "modal-container mx-3 py-4" }, [
                  _c("div", { staticClass: "row mb-5 question-setting mt-3" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-4 tree-wrap" },
                      [
                        _c("FormTextField", {
                          attrs: {
                            "label-text": _vm.$t("labels.taskName"),
                            "initial-value": _vm.taskName,
                            "max-length": 50,
                            disabled: true
                          },
                          on: {
                            input: _vm.inputTaskName,
                            "on-blur": _vm.blurTaskName
                          }
                        }),
                        _vm.showMsgTaskName
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(" " + _vm._s(_vm.showMsgTaskName) + " ")
                            ])
                          : _vm._e(),
                        _c("br"),
                        _vm._m(0),
                        _c("div", { attrs: { id: "display-method" } }, [
                          _c("label", { staticClass: "px-4 py-1" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.checkDisplayMethod,
                                  expression: "checkDisplayMethod"
                                }
                              ],
                              attrs: {
                                type: "radio",
                                value: "oneQuestion",
                                disabled: true
                              },
                              domProps: {
                                checked: _vm._q(
                                  _vm.checkDisplayMethod,
                                  "oneQuestion"
                                )
                              },
                              on: {
                                change: function($event) {
                                  _vm.checkDisplayMethod = "oneQuestion"
                                }
                              }
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("labels.oneQuestion")) + " "
                            )
                          ]),
                          _c("div", { staticClass: "px-5 py-1" }, [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.answerValue,
                                    expression: "answerValue"
                                  }
                                ],
                                attrs: {
                                  id: "chkAnswer",
                                  type: "checkbox",
                                  disabled: true
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.answerValue)
                                    ? _vm._i(_vm.answerValue, null) > -1
                                    : _vm.answerValue
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$a = _vm.answerValue,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.answerValue = $$a.concat([
                                              $$v
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.answerValue = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.answerValue = $$c
                                      }
                                    },
                                    _vm.onChangedCheckboxValue
                                  ]
                                }
                              }),
                              _c(
                                "label",
                                { attrs: { for: "chkOneQuestion" } },
                                [_vm._v(_vm._s(_vm.$t("labels.answer")))]
                              ),
                              _vm._v("    ")
                            ]),
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.explanationValue,
                                    expression: "explanationValue"
                                  }
                                ],
                                attrs: {
                                  id: "chkExplanation",
                                  type: "checkbox",
                                  disabled: true
                                },
                                domProps: {
                                  checked: Array.isArray(_vm.explanationValue)
                                    ? _vm._i(_vm.explanationValue, null) > -1
                                    : _vm.explanationValue
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      var $$a = _vm.explanationValue,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = null,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.explanationValue = $$a.concat([
                                              $$v
                                            ]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.explanationValue = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.explanationValue = $$c
                                      }
                                    },
                                    _vm.onChangedCheckboxValue
                                  ]
                                }
                              }),
                              _c(
                                "label",
                                { attrs: { for: "chkExplanation" } },
                                [_vm._v(_vm._s(_vm.$t("labels.explanation")))]
                              )
                            ])
                          ]),
                          _c("div", [
                            _c("label", { staticClass: "mx-4 py-1" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.checkDisplayMethod,
                                    expression: "checkDisplayMethod"
                                  }
                                ],
                                attrs: {
                                  type: "radio",
                                  value: "followLayout",
                                  disabled: true
                                },
                                domProps: {
                                  checked: _vm._q(
                                    _vm.checkDisplayMethod,
                                    "followLayout"
                                  )
                                },
                                on: {
                                  change: function($event) {
                                    _vm.checkDisplayMethod = "followLayout"
                                  }
                                }
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("labels.followLayout")) +
                                  " "
                              )
                            ])
                          ])
                        ]),
                        _c("br"),
                        _c("FormTextField", {
                          attrs: {
                            "label-text": _vm.$t("labels.sendMemo"),
                            "initial-value": _vm.sendMemo,
                            "max-length": 50,
                            disabled: true
                          },
                          on: {
                            input: _vm.inputSendMemo,
                            "on-blur": _vm.blurSendMemo
                          }
                        }),
                        _vm.showMsgSendMemo
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(" " + _vm._s(_vm.showMsgSendMemo) + " ")
                            ])
                          : _vm._e(),
                        _c("br"),
                        _c("div", [
                          _vm._m(1),
                          _c(
                            "div",
                            {
                              staticClass: "question-area",
                              staticStyle: { "overflow-y": "auto" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "question-amount mx-3" },
                                _vm._l(_vm.questionQuantityList, function(
                                  item,
                                  index
                                ) {
                                  return _c("div", { key: index }, [
                                    _c("div", { staticClass: "row py-1" }, [
                                      _vm.stdbPreviewNum === 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "col-2 text-center"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    "[" +
                                                      (Number(index) + 1) +
                                                      "]"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass: "col-2 text-center"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    "[" +
                                                      _vm.stdbPreviewNum +
                                                      "]"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-2",
                                          on: {
                                            click: function($event) {
                                              return _vm.onClickQuestionCountMinus(
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "icon-minus bi bi-dash-circle-fill"
                                          })
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "col-4 text-center" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "labels.questionNumber",
                                                  { number: item }
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-2",
                                          on: {
                                            click: function($event) {
                                              return _vm.onClickQuestionCountPlus(
                                                index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "icon-plus bi bi-plus-circle-fill"
                                          })
                                        ]
                                      ),
                                      _c("div", { staticClass: "col-2" })
                                    ])
                                  ])
                                }),
                                0
                              )
                            ]
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-8 preview-image-container" },
                      [
                        _c("div", { staticClass: "card w-100" }, [
                          _c("div", { staticClass: "card-header" }, [
                            _vm._v(" " + _vm._s(_vm.$t("labels.preview")) + " ")
                          ]),
                          _vm.checkDisplayMethod == "oneQuestion"
                            ? _c("div", [
                                _c("div", { staticClass: "window-body" }, [
                                  _vm.stdbPreviewNum === 0
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              "[" +
                                                (_vm.oneQuestionCount + 1) +
                                                "]"
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              "[" + _vm.stdbPreviewNum + "]"
                                            ) +
                                            " "
                                        )
                                      ]),
                                  _c(
                                    "ul",
                                    {
                                      staticClass: "prev-next",
                                      staticStyle: { "list-style": "none" }
                                    },
                                    [
                                      _c("li", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.checkDisplayMethod ==
                                                "oneQuestion" &&
                                              _vm.oneQuestionCount > 0,
                                            expression:
                                              "\n                            checkDisplayMethod == 'oneQuestion' &&\n                              oneQuestionCount > 0\n                          "
                                          }
                                        ],
                                        staticClass: "bi bi-chevron-left",
                                        staticStyle: { top: "45%" },
                                        on: { click: _vm.onClickArrowMinus }
                                      }),
                                      _c("li", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.checkDisplayMethod ==
                                                "oneQuestion" &&
                                              _vm.oneQuestionCount <
                                                _vm.imageQList.length - 1,
                                            expression:
                                              "\n                            checkDisplayMethod == 'oneQuestion' &&\n                              oneQuestionCount < imageQList.length - 1\n                          "
                                          }
                                        ],
                                        staticClass: "bi bi-chevron-right",
                                        staticStyle: { top: "45%" },
                                        on: { click: _vm.onClickArrowPlus }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "px-3 py-3 card-body preview-window"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "card overflow-auto h-100"
                                        },
                                        [
                                          _vm.imageQList.length
                                            ? _c("SettingPreviewImage", {
                                                staticClass: "card-body",
                                                attrs: {
                                                  image:
                                                    _vm.imageQList[
                                                      _vm.oneQuestionCount
                                                    ].file_image
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "card-body" },
                                    [
                                      _c("SettingPreviewArea", {
                                        ref: "previewArea",
                                        attrs: {
                                          list:
                                            _vm.settingList[
                                              _vm.oneQuestionCount
                                            ],
                                          "stdb-flg": true
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ])
                            : _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "window-body-layout" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "h-auto text-end" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.layoutCount +
                                                1 +
                                                " / " +
                                                _vm.imageDocList.length
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "preview-window" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "preview-layout" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "px-3 py-3 card-body"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "card overflow-auto"
                                                  },
                                                  [
                                                    _vm.imageDocList.length
                                                      ? _c("img", {
                                                          staticClass:
                                                            "container-fluid",
                                                          attrs: {
                                                            src:
                                                              _vm.imageDocList[
                                                                _vm.layoutCount
                                                              ].file_image
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "ul",
                                      {
                                        staticClass: "prev-next py-1",
                                        staticStyle: { "list-style": "none" }
                                      },
                                      [
                                        _c("li", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.checkDisplayMethod ==
                                                  "followLayout" &&
                                                _vm.layoutCount > 0,
                                              expression:
                                                "\n                            checkDisplayMethod == 'followLayout' &&\n                              layoutCount > 0\n                          "
                                            }
                                          ],
                                          staticClass: "bi bi-chevron-left",
                                          staticStyle: { top: "45%" },
                                          on: { click: _vm.onClickArrowMinus }
                                        }),
                                        _c("li", {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.checkDisplayMethod ==
                                                  "followLayout" &&
                                                _vm.layoutCount <
                                                  _vm.imageDocList.length - 1,
                                              expression:
                                                "\n                            checkDisplayMethod == 'followLayout' &&\n                              layoutCount < imageDocList.length - 1\n                          "
                                            }
                                          ],
                                          staticClass: "bi bi-chevron-right",
                                          staticStyle: { top: "45%" },
                                          on: { click: _vm.onClickArrowPlus }
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ])
                        ])
                      ]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "row gx-5 justify-content-center" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-auto" },
                        [
                          _c("ButtonBorderCircleMedium", {
                            attrs: {
                              "label-name": _vm.$t("buttons.cancel"),
                              "label-color": _vm.colorLayoutTheme,
                              "border-color": _vm.colorLayoutTheme
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.$emit("close")
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-auto" },
                        [
                          _c("ButtonBorderCircleMedium", {
                            attrs: {
                              "label-name": _vm.$t("buttons.save"),
                              color: "layout-theme-light",
                              "label-color": _vm.colorLayoutTheme,
                              "border-color": _vm.colorLayoutTheme
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.onClickSave.apply(null, arguments)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ])
              ],
              1
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _vm._v(" " + _vm._s(_vm.$t("labels.howToDisplayInViewer")) + " ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" " + _vm._s(_vm.$t("labels.questionQuantity")) + " ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }