<template>
  <div class="icon-progress">
    <svg
      id="_x32_"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      :width="width"
      :height="height"
      viewBox="0 0 512 512"
      style="opacity: 1;"
      xml:space="preserve"
    >
      <g>
        <rect
          x="113.978"
          y="158.237"
          class="st0"
          width="81.435"
          height="309.455"
          style="fill: rgb(250, 113, 11);"
        />
        <path
          class="st0"
          d="M357.688,207.139c-4.319,5.782-6.68,12.788-6.68,20.112c0,9.043,3.499,17.433,9.853,23.779
        c6.354,6.354,14.825,9.853,23.779,9.853c8.963,0,17.432-3.499,23.778-9.766c11.977-11.977,13.035-30.785,3.094-43.978H357.688z"
          style="fill: rgb(250, 113, 11);"
        />
        <path
          class="st0"
          d="M341.966,334.096v133.636h81.435V335.558c-12.294,4.398-25.4,6.681-38.761,6.681
        C369.824,342.239,355.327,339.392,341.966,334.096z"
          style="fill: rgb(250, 113, 11);"
        />
        <path
          class="st0"
          d="M269.74,227.251c0-30.697,11.882-59.525,33.624-81.268c1.956-1.957,3.913-3.834,6.028-5.543V44.268h-81.435
        v423.464h81.435v-153.59c-2.115-1.71-4.072-3.579-6.028-5.535C281.621,286.865,269.74,258.036,269.74,227.251z"
          style="fill: rgb(250, 113, 11);"
        />
        <rect
          x="0"
          y="304.782"
          class="st0"
          width="81.436"
          height="162.871"
          style="fill: rgb(250, 113, 11);"
        />
        <path
          class="st0"
          d="M504.622,319.144l-43.939-42.801c9.372-14.482,14.442-31.366,14.442-49.06
        c0-24.208-9.408-46.937-26.483-64.012c-17.074-17.074-39.803-26.483-64.011-26.483c-24.208,0-46.945,9.408-64.012,26.483
        c-17.082,17.074-26.482,39.803-26.482,64.012c0,24.208,9.4,46.944,26.475,64.019c17.074,17.074,39.81,26.482,64.019,26.482
        c17.698,0,34.582-5.074,49.072-14.458l42.782,43.931c7.976,9.614,19.746,10.187,28.844,1.09
        C514.435,339.257,514.236,327.097,504.622,319.144z M384.632,275.85c-12.971,0-25.17-5.058-34.348-14.228
        c-9.17-9.169-14.219-21.369-14.219-34.339c0-12.971,5.05-25.163,14.219-34.332c9.178-9.169,21.369-14.227,34.348-14.227
        c12.963,0,25.162,5.058,34.332,14.227c18.935,18.935,18.935,49.744,0,68.679C409.794,270.8,397.603,275.85,384.632,275.85z"
          style="fill: rgb(250, 113, 11);"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconProgress',
  props: {
    width: {
      type: String,
      default: function () {
        return '25px'
      },
    },
    height: {
      type: String,
      default: function () {
        return '25px'
      },
    },
  },

  data: () => ({
    // アイコンにタイトルを設定する場合は、iconName を表示するようにする
    // ファイル名：宿題一覧_進捗(先方作成).svg
    iconName: '進捗'
  }),
}
</script>

<style lang="scss" scoped>

</style>