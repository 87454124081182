var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "side-menu d-flex flex-column justify-content-between",
      style: _vm.sizeStyle
    },
    [
      _c(
        "div",
        { staticClass: "nav flex-column" },
        _vm._l(_vm.items, function(item) {
          return _c("div", { key: item.name }, [
            _c(
              "div",
              {
                staticClass: "row py-0",
                class: {
                  active: _vm.activeName === item.name,
                  "over-active":
                    _vm.activeName !== item.name &&
                    _vm.overItemName === item.name
                },
                staticStyle: { "--bs-gutter-x": "0rem" },
                on: {
                  click: function($event) {
                    return _vm.onMenuClick(item)
                  },
                  mouseover: function($event) {
                    _vm.overItemName = item.name
                  },
                  mouseleave: function($event) {
                    _vm.overItemName = ""
                  }
                }
              },
              [
                _c("LinkTextRouter", {
                  staticClass: "col-12",
                  attrs: {
                    name: item.name,
                    "icon-name": item.iconName,
                    "icon-color": item.iconColor,
                    "label-text": item.displayName,
                    "font-color": item.fontColor,
                    "open-url": item.openUrl
                  }
                })
              ],
              1
            )
          ])
        }),
        0
      ),
      _c("div", { staticClass: "nav flex-column" }, [
        _c(
          "ul",
          { staticClass: "nav flex-column mb-2" },
          _vm._l(_vm.bottomItems, function(item) {
            return _c("li", { key: item.name }, [
              _c(
                "ul",
                {
                  staticClass: "list-inline",
                  class: { active: _vm.activeName === item.name }
                },
                [
                  _c(
                    "li",
                    { staticClass: "list-inline-item" },
                    [
                      _c("LinkTextRouter", {
                        attrs: {
                          name: item.name,
                          "label-text": item.displayName,
                          "font-color": item.fontColor,
                          "open-url": item.openUrl
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.onMenuClick(item)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  item.name != "Terms"
                    ? _c(
                        "li",
                        { staticClass: "list-inline-item" },
                        [
                          _c("IconLink", {
                            attrs: { width: "16px", height: "16px" }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ])
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "nav flex-column mb-3 accountArea" },
          [
            _c("div", { staticClass: "mt-3 px-3 login-user" }, [
              _vm._v(" " + _vm._s(_vm.loginUserInfo.userName) + " ")
            ]),
            _c("ButtonMini", {
              staticClass: "px-3",
              attrs: {
                "label-name": _vm.$t("buttons.logout"),
                color: "layout-theme",
                "label-color": "white",
                "border-color": "#ff7f27"
              },
              nativeOn: {
                click: function($event) {
                  return _vm.onLogout.apply(null, arguments)
                }
              }
            })
          ],
          1
        )
      ]),
      _vm.showModal == "terms"
        ? _c("ModalTerms", {
            attrs: { "is-agree-required": false },
            on: {
              "close-terms": function($event) {
                _vm.showModal = ""
                _vm.$emit("change-modal-view", false)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }