<template>
  <div class="tree-check-box">
    <ul :class="{ tree_space: list[0] && !isFolder(list[0])}">
      <li
        v-for="(item, index) in list"
        :key="index"
        class="tree"
      >
        <div
          class="label"
          :class="{
            hidden: !isFolder(item) && isGrayOut(item) && grayoutHiddenMode,
            active: item === selectedItem,
          }"
        >
          <span
            v-if="isFolder(item)"
            :class="{ hidden_icon: isGrayOut(item) && grayoutHiddenMode }"
            @click="toggle(item)"
          >
            <IconCaretRight
              v-if="!item.isOpen"
              width="15px"
              height="15px"
              color-style="#1E90FF"
            />
            <IconCaretDown
              v-if="item.isOpen"
              width="15px"
              height="15px"
              color-style="#ff7f27"
            />
          </span>
          <input
            :id="item.id"
            :ref="'checkboxes' + index"
            v-model="item.checkFlg"
            type="checkbox"
            :value="item.checkFlg"
            :disabled="isGrayOut(item)"
            @change="
              onChangedFlg(item)
              onChangeMessageDisplayFlag(item)
            "
          >
          <span
            :class="{
              grayout: isGrayOut(item),
              select_preview: !isFolder(item) || item.img,
            }"
            @click.self="onClickQuestionTitle(item)"
          >
            {{ item.title }}
          </span>
        </div>
        <TreeCheckBox
          v-if="item.isOpen"
          :list="item.nodes"
          :parent-id="item.id"
          :grayout-hidden-mode="grayoutHiddenMode"
          :selected-item="selectedItem"
          v-on="{
            'changed-child-flg': onChangedChildFlg,
            'clicked-question-title': emitQuestionImage,
            'change-preview-tabs': emitChangePreviewTabs,
            'clicked-content-title': onClickContentTitle,
            'change-display-message': onChangeMessageDisplayFlag,
          }"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import IconCaretDown from "@/components/atoms/icons/IconCaretDown.vue"
import IconCaretRight from "@/components/atoms/icons/IconCaretRight.vue"
import { displayFlagCode } from "@/constant/question.js"

export default {
  name: "TreeCheckBox",
  components: {
    IconCaretDown,
    IconCaretRight,
  },
  props: {
    list: {
      type: Array,
      default: function () {
        return []
      },
    },
    showFlg: Boolean,
    parentId: { type: String, default: "" },
    // グレーアウトしているものを非表示にするか？
    grayoutHiddenMode: { type: Boolean, default: false },
    // 現在選択されている親問題
    selectedItem: { type: Object, default: null },
  },
  data() {
    return {
      isOpen: false,
    }
  },

  watch: {
    list: {
      async handler(){
        await this.updateCheckOrIndeterminate()
      },
      deep: true,
    }
  },

  async mounted() {
    await this.updateCheckOrIndeterminate()
    
  },
  methods: {
    /**
     * 要素内に子要素を含むかを返す処理
     */
    isFolder(item) {
      return "nodes" in item && item.nodes && item.nodes.length > 0
    },
    /**
     * 要素内に子要素を含むかを返す処理
     */
    hasNotValue(item) {
      return !item || !("value" in item)
    },
    /**
     * グレーアウトするか？
     */
    isGrayOut(item) {
      if (
        "displayFlag" in item &&
        item.displayFlag === displayFlagCode.grayout
      ) {
        return true
      }
      if (!this.isFolder(item)) {
        return false
      }
      let hasGrayoutChild = true
      item.nodes.forEach((child) => {
        if (hasGrayoutChild && !this.isGrayOut(child)) {
          hasGrayoutChild = false
        }
      })
      return hasGrayoutChild
    },

    /**
     * チェックフラグ変更時処理
     */
    onChangedFlg(item) {
      // 親要素の選択状態が変更された際に子要素の選択状態を一括して変更する処理
      this.bulkValueChange(item)
      // 親コンポーネントに返す処理
      this.$emit("changed-child-flg")
    },
    /**
     * 値を再帰的に一括変更する処理（要素の値を子要素に伝播する処理）
     */
    bulkValueChange(item) {
      if ("nodes" in item && item.nodes) {
        item.nodes.forEach((node) => {
          if (this.isGrayOut(node)) {
            return
          }
          this.$set(node, "checkFlg", item.checkFlg)
          this.bulkValueChange(node)
        })
      }
    },
    /**
     * 子要素の値変更時処理（親要素のチェックフラグを変更する）
     */
    onChangedChildFlg() {
      this.list.forEach((item) => {
        if ("nodes" in item && item.nodes) {
          let parentChangeFlg = item.nodes.some((node) => {
            if (!node.checkFlg) {
              return false
            }
            return "displayFlag" in node
              ? node.displayFlag === displayFlagCode.display && node.checkFlg
              : node.checkFlg
          })
          this.$set(item, "checkFlg", parentChangeFlg)
        }
      })
      this.$emit("changed-child-flg")
    },
    /**
     * 子要素の表示非表示を管理する処理
     */
    toggle(item) {
      if (this.isFolder(item)) {
        this.$set(item, "isOpen", !item.isOpen)
      }
    },
    /**
     * 親問題のタイトルラベルがクリックされた
     */
    onClickQuestionTitle(item) {
      if (!this.isGrayOut(item) && "img" in item && item.img) {
        // プレビューエリアのタブ切り替え
        this.changePreviewTabs(item)
        if ("displayFilter" in item && item) {
          // 画像の切り替えは後からemit
          this.$emit("clicked-question-title", item)
        }
      }
    },
    /**
     * プレビューエリアのタブ切り替え
     */
    changePreviewTabs(item) {
      // tabsにはコンテンツのタブが入るが、nullだった場合は３タブに戻す
      let tabs = null
      const isContent = "isContent" in item && item.isContent
      // imgプロパティが存在（上のif文で判定済み）し、尚且つ下の階層があるなら「コンテンツの親問題」と見做す
      if (this.isFolder(item)) {
        tabs = []
        let id = 1
        item.nodes.forEach((content) => {
          tabs.push({
            id: id++,
            label: content.title,
            content: content.img,
          })
        })
      } else if (isContent) {
        // コンテンツがクリックされた場合はclicked-content-titleイベントで処理する
        this.$emit("clicked-content-title", this.parentId)
      }
      // そうでないならここで処理する
      if (!isContent) {
        this.emitChangePreviewTabs({
          tabs: tabs,
        })
      }
    },
    /**
     * コンテンツのタイトルがクリックされた
     */
    onClickContentTitle(id) {
      const item = this.list.filter((item) => item.id === id)[0] // 必ず存在するのでnullチェック無し
      // タブ切り替え
      this.changePreviewTabs(item)
      // 親問題の画像表示
      this.$emit("clicked-question-title", item)
    },
    /**
     * 画像パスを親画面に返す処理
     */
    emitQuestionImage(item) {
      this.$emit("clicked-question-title", item)
    },
    /**
     * タブの差し替えを親要素に返す
     */
    emitChangePreviewTabs(item) {
      this.$emit("change-preview-tabs", item)
    },
    /**
     * 子要素が存在するかどうかで表示を分ける
     */
    async updateCheckOrIndeterminate() {
      await this.$nextTick()
      this.list.forEach((item, index) => {
        if (this.isFolder(item) && !this.hasNotValue(this.$refs['checkboxes' + index][0])) {
          if (this.isAllChildrenChecked(item)) {
            this.$set(this.$refs['checkboxes' + index][0], "value", item.checkFlg)
            this.$set(this.$refs['checkboxes' + index][0], "indeterminate", false)
          } else {
            this.$set(this.$refs['checkboxes' + index][0], "value", false)
            this.$set(this.$refs['checkboxes' + index][0], "indeterminate", item.checkFlg)
          }
        }
      })
    },
    /**
     * 全ての子、孫にチェックが入っているかどうかを判定する再帰関数
     */
    isAllChildrenChecked(item) {
      // 子要素が存在する場合true
      if (this.isFolder(item)) {
        let allChecked = true
        item.nodes.forEach((node) => {
          if (!this.isAllChildrenChecked(node) && !this.isGrayOut(node)) {
            allChecked = false
          }
        })
        return allChecked
      }
      return item.checkFlg
    },
    /**
     * チェックが入った時にメッセージの表示、非表示を切り替える
     */
    onChangeMessageDisplayFlag(item) {
      this.$emit("change-display-message", item)
    },
  },
}
</script>

<style lang="scss" scoped>
ul,
li {
  list-style: none;
  padding-left: 0.5rem;
}
.tree {
  display: block;
  .tree_space{
    padding-left: 1.5rem;
  }
  .grayout {
    opacity: 0.3;
  }

  .select_preview {
    cursor: pointer;
  }

  .active {
    color: #000000;
    background: var(--bs-selected-tree);
  }

  .label {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    padding: 0 0.5em;
  }
  .hidden {
    display: none;
  }
  .hidden_icon {
    opacity: 0;
  }
}
</style>
