var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card answer-status" }, [
    _c(
      "div",
      { staticClass: "card-body" },
      _vm._l(_vm.list, function(item, index) {
        return _c(
          "ul",
          { key: index },
          [
            _c("p", { staticClass: "text-start m-0" }, [
              _vm._v(" " + _vm._s(item.homeworkName) + " ")
            ]),
            _vm._l(item.childrenQuestion, function(childItem, childIndex) {
              return _c("li", { key: childIndex }, [
                _c("p", [_vm._v(_vm._s(childIndex + 1) + "問目")]),
                childItem === 0
                  ? _c("div", [_c("IconDash", { attrs: { width: "15px" } })], 1)
                  : _vm._e(),
                childItem === 1
                  ? _c(
                      "div",
                      [
                        _c("IconCircle", {
                          attrs: {
                            width: "15px",
                            "color-style": _vm.correctColor
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                childItem === 2
                  ? _c(
                      "div",
                      [
                        _c("IconClose", {
                          attrs: {
                            width: "15px",
                            "color-style": _vm.incorrectColor
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            })
          ],
          2
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }