<template>
  <div
    ref="topElement"
    class="note-image_top-element"
    :style="{cursor: topElementCursor}"
  >
    <div
      ref="imgContainer"
      class="note-image_image-rect"
      :style="{
        top: imageRectPoint.y+'px',
        left:imageRectPoint.x+'px',
        width: imageRectWidth+'px',
        height: imageRectHeight+'px',
        transform: 'scale('+imageRectScale+')',
        'transform-origin': imageRectOrigin,
      }"
    >
      <div
        ref="imageScale"
        class="note-image_image-scale"
        :style="{
          transform: 'scale('+scale+')',
          left: scaleRectPoint.x+'px',
          top: scaleRectPoint.y+'px',
        }"
      >
        <template v-if="isShowImg">
          <!-- ノート写真、問題画像 -->
          <div
            class="note-image_q-img-wrap"
            :style="{
              top:teacherPaddingTop+'px',
              left:teacherPaddingLeft+'px',
              width:imgWidth+'px',
              height:(imgHeight+imgHeightCalibrationValue)+'px',
            }"
          >
            <img
              class="note-image_q-img"
              :src="imgSource"
              :class="{'note-image_stdb-custom': isStdb}"
            >
            <!-- 書き込み情報(生徒)-->
            <div 
              v-if="showWriteInfoStudent"
              class="note-image_student"
              :style="{
                top: teacherPaddingTop,
                left: teacherPaddingLeft,
              }"
            >
              <HomeworkWriteInfo
                :write-info="writeInfoStudent"
                :img-width="imgWidth"
                :img-height="imgHeight"
                :page-rate="pageRateStudent"
                :config-page.sync="writeInfoStudent.configPage"
                :cond.sync="writeInfoStudent.cond"
                :homework-syubetu-kbn="homeworkSyubetuKbn"
              />
            </div>
          </div>
          <!-- 書き込み情報(先生)-->
          <div
            class="note-image_teacher"
            :style="{
              width: imageRectWidth+'px',
              height: imageRectHeight+'px',
            }"
          >
            <HomeworkWriteInfo
              ref="writeInfoTeacher"
              :pointer-element="imageScaleElement"
              :is-teacher-mode="true"
              :write-info.sync="writeInfoTeacher"
              :img-width="imageRectWidth"
              :img-height="imageRectHeight"
              :page-rate="pageRateTeacher"
              :config-page.sync="configPageTeacher"
              :cond.sync="condTeacher"
              :homework-syubetu-kbn="homeworkSyubetuKbn"
              :base-scale="baseScale"
              :editable="editable"
              :write-info-object-to-string="writeInfoObjectToString"
              v-on="{
                showConfirmForOverWriteInfoSize: ()=>$emit('showConfirmForOverWriteInfoSize'),
                drawStart: ()=>$emit('drawStart'),
                drawEnd: ()=>$emit('drawEnd'),
              }"
            />
          </div>
        </template>
        <template v-else>
          No Imaged
        </template>
        <div class="rect-wrap">
          <!-- 範囲選択拡大の範囲用矩形 -->
          <div
            v-if="zoomRangeRect"
            class="zoom-range-rect"
            :style="{
              top: zoomRangeRect.y+'px',
              left: zoomRangeRect.x+'px',
              width: zoomRangeRect.width+'px',
              height: zoomRangeRect.height+'px',
            }"
          />
        </div>
      </div>
    </div>
    <!-- UIブロック用 -->
    <div
      v-if="!draggable"
      style="
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0);
        pointer-events: all;
      "
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

import HomeworkWriteInfo from "@/components/atoms/HomeworkWriteInfo.vue"
import { homeworkTypeCode, noteType } from "@/constant/homework"

import Hammer from 'hammerjs'

export default {
  name: 'HomeworkNoteImage',
  components: {
    HomeworkWriteInfo,
  },
  props: {
    contentScale: {
      type: Number,
      required: false,
      default: 1,
    },
    img: {
      type: Object,
      required: false,
      default: function(){
        return {};
      },
    },
    teacherPadding: {
      type: Boolean,
      required: true,
    },
    draggable: {
      type: Boolean,
      required: false,
      default: false,
    },
    configPageTeacher: {
      type: Object,
      required: true,
    },
    condTeacher: {
      type: Object,
      required: true,
    },
    scales: {
      type: Array,
      required: false,
      default: function(){
        return [];
      }
    },
    initialScale: {
      type: Number,
      required: false,
      default: NaN,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    writeInfoObjectToString: {
      type: Function,
      required: false,
      default: null,
    }
  },
  data: function () {
    return {
      topElement: null,
      imgContainer: null,
      writeInfoTeacherComponent: null,
      teacherPaddingTop: 512,
      teacherPaddingBottom: 512,
      teacherPaddingLeft: 512,
      teacherPaddingRight: 512,
      imageRectPoint: {
        x: 0,
        y: 0,
      },
      scaleRectPoint: {
        x: 0,
        y: 0,
      },
      dragArea: null,
      dragInfo: null,
      zoomRangeRect: null,
      hammer: null,
      pinchInfo: null,
      imageScaleElement: null,
    };
  },
  computed: {
    ...mapGetters('homework',[
      'toolbarInfoOfwriteInfo',
    ]),
    homeworkSyubetuKbn:{
      get(){
        if(this.img){
          if(Number.isInteger(this.img.homework_syubetu_kbn)){
            return this.img.homework_syubetu_kbn;
          }
        }
        return NaN;
      },
    },
    toolbarInfo:{
      get(){
        if(this.draggable){
          return this.toolbarInfoOfwriteInfo;
        }else{
          return null;
        }
      },
      set(value){
        if(this.draggable){
          this.setToolbarInfoOfWriteInfo(value);
        }
      }
    },
    topElementCursor: {
      get(){
        let cursor = 'default';
        if(this.draggable){
          if (this.isDrawModeZoom) {
            if (this.isSelectingZoomRange) {
              cursor = 'default';
            } else {
              cursor = 'grab';
            }
          } else {
            if (this.isDrawMode) {
              cursor = 'default';
            } else {
              cursor = 'grab';
            }
          }
        }else{
          cursor = 'pointer';
        }
        if(cursor === 'grab' && this.dragInfo){
          // ドラッグ中はグー
          cursor = 'grabbing';
        }
        return cursor;
      }
    },
    baseScale:{
      get(){
        return this.imageRectScale * this.contentScale;
      }
    },
    topElementWidth: {
      get() {
        if (this.topElement) {
          return this.topElement.clientWidth;
        } else {
          return undefined;
        }
      }
    },
    topElementHeight: {
      get() {
        if (this.topElement) {
          return this.topElement.clientHeight;
        } else {
          return undefined;
        }
      }
    },
    imageScale: {
      get() {
        return this.$refs.imageScale;
      }
    },
    imageRectWidth: {
      get() {
        return this.imgWidth + this.teacherPaddingLeft + this.teacherPaddingRight;
      }
    },
    imageRectHeight: {
      get() {
        return this.imgHeight + this.teacherPaddingTop + this.teacherPaddingBottom;
      }
    },
    imageRectScale: {
      get() {
        // 親要素の横幅に合わせて拡縮する
        if (this.teacherPadding) {
          // 先生書き込み用余白も表示する
          return this.topElementWidth / this.imageRectWidth;
        } else {
          // 先生書き込み用余白は表示しない
          return this.topElementWidth / this.imgWidth;
        }
      }
    },
    imageRectOrigin: {
      get() {
        if (this.teacherPadding) {
          return 'left top';
        } else {
          const fact = this.imageRectScale / (1 - this.imageRectScale);
          const x = this.teacherPaddingLeft * -fact;
          const y = this.teacherPaddingTop * -fact;
          return `${x}px ${y}px`;
        }
      }
    },
    isOnline: {
      get() {
        return navigator.onLine;
      }
    },
    isShowImg: {
      get() {
        return this.isOnline;
      }
    },
    noteType: {
      get() {
        return this.img ? this.img.noteType : '';
      }
    },
    isPicture: {
      get() {
        return this.noteType === noteType.picture;
      }
    },
    isWriteInfo: {
      get() {
        return this.noteType === noteType.writeInfo;
      }
    },
    imgSource: {
      get() {
        return this.img ? this.img.noteFilePath : '';
      }
    },
    writeInfoStudent: {
      get() {
        if(this.img && this.img.writeInfoStudentObject){
          return this.img.writeInfoStudentObject.writeInfo;
        }else{
          return {};
        }
      }
    },
    pageRateStudent: {
      get() {
        if (this.writeInfoStudent) {
          if (this.writeInfoStudent.configPage) {
            if (this.writeInfoStudent.configPage.BookConfig) {
              if (this.writeInfoStudent.configPage.BookConfig.vp) {
                return this.writeInfoStudent.configPage.BookConfig.vp.w * 2 / this.imgWidth;
              }
            }
          }
        }
        return 1;
      }
    },
    writeInfoTeacher: {
      get() {
        if(this.img && this.img.writeInfoTeacherObject){
          return this.img.writeInfoTeacherObject.writeInfo;
        }else{
          return {};
        }
      }
    },
    pageRateTeacher: {
      get() {
        if (this.writeInfoTeacher) {
          if (this.writeInfoTeacher.configPage) {
            if (this.writeInfoTeacher.configPage.BookConfig) {
              if (this.writeInfoTeacher.configPage.BookConfig.vp) {
                return this.writeInfoTeacher.configPage.BookConfig.vp.w * 2 / this.imageRectWidth;
              }
            }
          }
        }
        return 1;
      }
    },
    isTextbook: {
      get() {
        return this.isWriteInfo && this.img && this.img.homework_syubetu_kbn === homeworkTypeCode.textbook;
      }
    },
    isPdf: {
      get() {
        return this.isWriteInfo && this.img && this.img.homework_syubetu_kbn === homeworkTypeCode.pdf;
      }
    },
    isStdbLayout: {
      get() {
        return this.isWriteInfo && this.img && this.img.homework_syubetu_kbn === homeworkTypeCode.stdbLayout;
      }
    },
    isStdb: {
      get() {
        return this.isWriteInfo && this.img && this.img.homework_syubetu_kbn === homeworkTypeCode.stdb;
      }
    },
    imgWidth: {
      get() {
        return 1024;
      }
    },
    imgHeight: {
      get() {
        if (!this.img) {
          return 0;
        }

        let w = this.img.imageHeight * this.imgWidth / this.img.imageWidth;
        switch(true){
          case this.isTextbook:   w += 1500;      break;
          case this.pdf:          w += 52;        break;
          case this.isStdbLayout: w += 52;        break;
          case this.isStdb:       w += 1500 + 52; break;
        }
        return w;
      }
    },
    imgHeightCalibrationValue: {
      get() {
        switch(true){
          case this.isTextbook:   return -55;
          case this.pdf:          return -52;
          case this.isStdbLayout: return -52;
          case this.isStdb:       return -52;
        }
        return 0;
      },
    },
    showWriteInfoStudent: {
      get() {
        return this.isWriteInfo && Boolean(this.img && this.img.writeInfoStudent);
      }
    },
    showWriteInfoTeacher: {
      get() {
        return Boolean(this.img && this.img.writeInfoStudent);
      }
    },
    toolType:{
      get(){
        return this.toolbarInfo ? this.toolbarInfo.toolType : '';
      },
    },
    isDrawMode: {
      get() {
        return this.editable && this.toolType !== 'none';
      },
    },
    isDrawModePen: {
      get() {
        return this.editable && this.toolType === 'pen';
      },
    },
    isDrawModeText: {
      get() {
        return this.editable && this.toolType === 'text';
      },
    },
    isDrawModeErase: {
      get() {
        return this.editable && this.toolType === 'erase';
      },
    },
    isDrawModeZoom: {
      get() {
        return this.toolType === 'zoom';
      },
    },
    scale: {
      get() {
        return this.toolbarInfo ? this.toolbarInfo.scale : 1;
      },
      set(value) {
        const toolbarInfo = this.cloneObject(this.toolbarInfo);
        if(toolbarInfo){
          toolbarInfo.scale = value;
          this.toolbarInfo = toolbarInfo;
        }
      }
    },
    isSelectingZoomRange: {
      get(){
        if(this.toolbarInfo){
          return this.toolbarInfo.isSelectingZoomRange;
        }else{
          return false;
        }
      },
      set(value){
        const toolbarInfo = this.cloneObject(this.toolbarInfo);
        if(toolbarInfo){
          toolbarInfo.isSelectingZoomRange = value;
          this.toolbarInfo = toolbarInfo;
        }
      }
    }
  },
  watch: {
    img: function () {
      // ドラッグ中でも強制解除
      this.dragEnd(null);
      if(this.draggable){
        // 画像が変わったら初期倍率に戻す
        this.scale = this.initialScale;
        // 表示座標をリセット
        this.resetPosition();
      }
    },
    baseScale(newValue){
      if(this.editable){
        const toolbarInfo = this.cloneObject(this.toolbarInfo);
        toolbarInfo.baseScale = newValue;
        this.toolbarInfo = toolbarInfo;
      }
    },
    pageRateTeacher(newValue){
      if(this.editable){
        const toolbarInfo = this.cloneObject(this.toolbarInfo);
        toolbarInfo.pageRate = newValue;
        this.toolbarInfo = toolbarInfo;
      }
    },
    scale(newValue, oldValue){
      if (this.pinchInfo) {
        // ピンチ操作中の倍率変更時の位置決めはピンチ操作側で行うためスキップ
        return;
      }
      if(newValue === 0){
        // ツールバーで100%を選択
        this.scale = this.initialScale;
        this.resetPosition();
        return;
      }

      const topRect = this.topElement.getBoundingClientRect();

      const p1 = {
          x: topRect.width * 0.5 / this.contentScale,
          y: topRect.height * 0.5 / this.contentScale,
      }
      const p2 = {
          x: p1.x - this.imageRectPoint.x,
          y: p1.y - this.imageRectPoint.y,
      }

      const scaleRate = newValue / oldValue - 1;
      const newPoint = {
          x: this.imageRectPoint.x - p2.x * scaleRate,
          y: this.imageRectPoint.y - p2.y * scaleRate,
      };
      this.setImageRectPoint(newPoint);
    },
  },
  beforeMount: function () {
    if (this.draggable) {
      if (!this.toolbarInfo || !this.toolbarInfo.toolType) {
        // ツールバー情報がない場合は初期値で生成
        this.toolbarInfo = {
          toolType: 'none',
          penInfo: {
            type: 'path',
            color: 0,
            size: 2,
            opacity: 0,
          },
          textInfo: {
            color: 0,
            backColor: 0,
            size: 3,
            font: 0
          },
          scale: 1,
          isSelectingZoomRange: false,
          isUndoOff: true,
          isRedoOff: true,
          baseScale: 1,
          pageRate: 1,
        };
      }
      // ツール種別と拡大率等はリセット
      const toolbarInfo = this.cloneObject(this.toolbarInfo);
      toolbarInfo.toolType = 'none';
      toolbarInfo.scale = this.initialScale;
      toolbarInfo.isSelectingZoomRange = false;
      toolbarInfo.isUndoOff = true;
      toolbarInfo.isUndoOff = true;
      toolbarInfo.pageRate = this.pageRateTeacher;
      this.toolbarInfo = toolbarInfo;
    }
  },
  mounted: function () {
    this.topElement = this.$refs.topElement;
    this.imgContainer = this.$refs.imgContainer;
    this.writeInfoTeacherComponent = this.$refs.writeInfoTeacher;
    if (this.draggable) {
      // 表示座標をリセット
      this.resetPosition();
      // ドラッグ対応
      this.imgContainer.addEventListener('touchstart', this.dragStart, {passive:true});
      this.imgContainer.addEventListener('mousedown', this.dragStart);
      // ピンチ操作対応
      this.hammer = new Hammer.Manager(this.imgContainer);
      const pinch = new Hammer.Pinch();
      this.hammer.add([pinch]);
      this.hammer.on('pinchstart', this.pinchHandler);
      this.hammer.on('pinchmove', this.pinchHandler);
      this.hammer.on('pinchend', this.pinchHandler);
      this.hammer.on('pinchcancel', this.pinchHandler);
      this.hammer.on('pinchin', this.pinchHandler);
      this.hammer.on('pinchout', this.pinchHandler);
      if(this.editable){
        const thisIns = this;
        this.$nextTick(()=>{
          thisIns.imageScaleElement = thisIns.$refs.imageScale;
        });
      }
    }
  },
  beforeDestroy: function () {
    document.body.removeEventListener('touchend', this.dragEnd);
    document.body.removeEventListener('touchcancel', this.dragEnd);
    document.body.removeEventListener('mouseup', this.dragEnd);
    if (this.imgContainer) {
      this.imgContainer.removeEventListener('touchstart', this.dragStart);
      this.imgContainer.removeEventListener('touchmove', this.dragMove);
      this.imgContainer.removeEventListener('mousedown', this.dragStart);
      this.imgContainer.removeEventListener('mousemove', this.dragMove);
      this.imgContainer.removeEventListener('mouseleave', this.dragEnd);
    }
    if (this.hammer) {
      this.hammer.off();
    }
  },
  methods: {
    ...mapActions('homework',[
      'setToolbarInfoOfWriteInfo',
    ]),
    dragStart(e) {
      const dragTarget = this.getDragTarget(e);
      if(dragTarget instanceof HTMLTextAreaElement){
        // テキストエリア上からドラッグ開始
        if(dragTarget.scrollWidth > dragTarget.clientWidth || dragTarget.scrollHeight > dragTarget.clientHeight){
          // スクロールバーが表示されている場合
          if(e instanceof TouchEvent){
            // タッチイベントではテキストエリアのスクロールを優先するので紙面の移動はしない
            return;
          }
        }
      }
      if (e instanceof TouchEvent) {
        if (e.touches.length > 1) {
          // タッチ点が2点以上は無視
          return;
        }
        this.imgContainer.addEventListener('touchmove', this.dragMove, {passive:true});
        document.body.addEventListener('touchend', this.dragEnd);
        document.body.addEventListener('touchcancel', this.dragEnd);
      } else if (e instanceof MouseEvent) {
        this.imgContainer.addEventListener('mousemove', this.dragMove);
        this.imgContainer.addEventListener('mouseleave', this.dragEnd);
        document.body.addEventListener('mouseup', this.dragEnd);
      } else {
        return;
      }
      const p = this.getPoint(e);
      if (!this.existsTopElement(p)) {
        // 領域外のドラッグはドラッグ終了
        this.dragEnd(e);
        return;
      }
      this.$emit('drag-start');
      if(this.isSelectingZoomRange){
        // 範囲指定拡大の範囲指定中
        ;
      }else if(!['none','zoom'].includes(this.toolType)){
        // 描画中なので移動は行わない
        this.dragEnd(e);
        return;
      }

      this.dragInfo = {
        startPoint: p,
        imageRectPoint: {
          x: this.imageRectPoint.x,
          y: this.imageRectPoint.y,
        },
      }
    },
    dragMove(e) {
      if (e instanceof TouchEvent) {
        if (e.touches.length > 1) {
          // タッチ点が2点以上は無視
          return;
        }
      } else if (e instanceof MouseEvent) {
        ;
      } else {
        return;
      }

      const p = this.getPoint(e);
      if(p.identifier !== this.dragInfo.startPoint.identifier){
        // 最初とタッチ識別子が異なる場合はドラッグ終了
        this.dragEnd(e);
        return;
      }
      if (!this.existsTopElement(p)) {
        // 領域外のドラッグはドラッグ終了
        this.dragEnd(e);
        return;
      }

      if(this.isSelectingZoomRange){
        // 範囲指定拡大の範囲指定中
        this.zoomRangeRect = this.getRect(this.dragInfo.startPoint, p , 'layer', true, 1 / (this.contentScale * this.imageRectScale * this.scale));
      }else{
        // 移動中
        const rect = this.getRect(this.dragInfo.startPoint, p, '', false, 1 / this.contentScale);
        const newPoint = {
          x: this.dragInfo.imageRectPoint.x + rect.width,
          y: this.dragInfo.imageRectPoint.y + rect.height,
        };

        this.setImageRectPoint(newPoint);
      }
    },
    dragEnd() {
      if(this.isSelectingZoomRange){
        // 範囲指定拡大の範囲確定
        this.commitRangeZoom();
        // 範囲選択ズームは解除
        this.isSelectingZoomRange = false;
      }

      this.dragInfo = null;
      this.zoomRangeRect = null;
      document.body.removeEventListener('touchend', this.dragEnd);
      document.body.removeEventListener('touchcancel', this.dragEnd);
      document.body.removeEventListener('mouseup', this.dragEnd);
      if(this.imgContainer){
        this.imgContainer.removeEventListener('touchmove', this.dragMove);
        this.imgContainer.removeEventListener('mousemove', this.dragMove);
        this.imgContainer.removeEventListener('mouseleave', this.dragEnd);
      }
    },
    pinchHandler(e){
      switch(e.type){
        case 'pinchstart':
          // ピンチ操作開始
          this.pinchInfo = {
            startCenter: {
              x: e.center.x,
              y: e.center.y,
            },
            startPoint: {
              x: this.imageRectPoint.x,
              y: this.imageRectPoint.y,
            },
            startScale: this.scale,
          }
          // ドラッグ操作は強制終了
          this.dragEnd();
          break;
        case 'pinchend':
        case 'pinchcancel':
          // ピンチ操作終了
          this.pinchInfo = null;
          return;
        default:
          // ピンチ操作継続中
          break;
      }
      if(!this.pinchInfo){
        return;
      }

      // 移動分
      const newPoint = {
        x: this.pinchInfo.startPoint.x + (e.center.x - this.pinchInfo.startCenter.x) / this.contentScale,
        y: this.pinchInfo.startPoint.y + (e.center.y - this.pinchInfo.startCenter.y) / this.contentScale,
      };

      // ピンチ操作開始時点での倍率とピンチイベントの倍率で新しい倍率を求める
      // ピンチ操作の場合はスムーズに倍率を変えるために定義済み倍率以外でもOKとする
      // 上下限チェックは行う
      let newScale = this.pinchInfo.startScale * e.scale;
      if (newScale < this.scales[0]) {
        newScale = this.scales[0];
      }
      if (newScale > this.scales[this.scales.length - 1]) {
        newScale = this.scales[this.scales.length - 1];
      }

      // 表示枠内でのタップ位置
      const topRect = this.topElement.getBoundingClientRect();
      const p1 = {
        x: (e.center.x - topRect.x) / this.contentScale,
        y: (e.center.y - topRect.y) / this.contentScale,
      }

      // 画像内でのタップ位置
      const p2 = {
        x: p1.x - this.pinchInfo.startPoint.x,
        y: p1.y - this.pinchInfo.startPoint.y,
      }

      // 倍率変更に伴う位置調整
      const scaleRate = newScale / this.pinchInfo.startScale - 1;
      newPoint.x -= p2.x * scaleRate;
      newPoint.y -= p2.y * scaleRate;

      // // 画面反映
      this.scale = newScale;
      this.setImageRectPoint(newPoint);
    },
    /** 範囲指定拡大操作の確定 */
    commitRangeZoom(){
      if (!this.zoomRangeRect || this.zoomRangeRect.width <= 0 || this.zoomRangeRect.height <= 0) {
        // 矩形が存在しない場合は無視
        return;
      }

      const wRate = this.imageRectWidth / this.zoomRangeRect.width;
      const hRate = this.imageRectWidth / this.zoomRangeRect.height;
      // 縦横で倍率の小さいほうを採用
      let newScale = wRate < hRate ? wRate : hRate;
      // 求めた倍率から倍率定義に近い値を求める
      newScale = this.scales.map(scale => { return { scale: scale, diff: Math.abs(scale - newScale) }; }).reduce((a, b) => a.diff < b.diff ? a : b).scale;

      const topRect = this.topElement.getBoundingClientRect();

      // 選択範囲の中心座標
      const zoomRangeCenterP = {
        x: this.zoomRangeRect.x + this.zoomRangeRect.width / 2,
        y: this.zoomRangeRect.y + this.zoomRangeRect.height / 2,
      };

      // 新しい表示座標
      const newPoint = {
        x: (topRect.width * 0.5 / this.contentScale / this.imageRectScale / newScale - zoomRangeCenterP.x) * this.imageRectScale * newScale,
        y: (topRect.height * 0.5 / this.contentScale / this.imageRectScale / newScale - zoomRangeCenterP.y) * this.imageRectScale * newScale,
      };

      // 画面に反映
      this.scale = newScale;
      this.$nextTick(()=>{
        // スケール設定時の位置補正を上書くため$nextTick後に位置を設定
        this.setImageRectPoint(newPoint);
      });
    },
    getPoint(e) {
      let targetInfo;
      if (e instanceof TouchEvent) {
        if(this.dragInfo && this.dragInfo.startPoint){
          targetInfo = Object.values(e.touches).find(t=>t.identifier === this.dragInfo.startPoint.identifier);
        }else{
          targetInfo = e.touches[0];
        }
      } else if (e instanceof MouseEvent) {
        targetInfo = e;
      }else{
        return null;
      }
      const imgContainerRect = this.imgContainer.getBoundingClientRect();
      const p = {
        identifier: targetInfo.identifier === undefined ? -1 : targetInfo.identifier,
        offsetX: targetInfo.offsetX,
        offsetY: targetInfo.offsetY,
        clientX: targetInfo.clientX,
        clientY: targetInfo.clientY,
        pageX: targetInfo.pageX,
        pageY: targetInfo.pageY,
        layerX: targetInfo.clientX - imgContainerRect.x,
        layerY: targetInfo.clientY - imgContainerRect.y,
        x: targetInfo.clientX,
        y: targetInfo.clientY,
        movementX: targetInfo.movementX,
        movementY: targetInfo.movementY,
      };
      return p;
    },
    getRect(p1, p2, prop='', normalize=true, scale=1){
      const propX = prop===''?'x':`${prop}X`;
      const propY = prop===''?'y':`${prop}Y`;

      const pt1 = {
        x: p1[propX] * scale,
        y: p1[propY] * scale,
      };
      const pt2 = {
        x: p2[propX] * scale,
        y: p2[propY] * scale,
      };

      const rect = {
        x: pt1.x,
        y: pt1.y,
        width: pt2.x - pt1.x,
        height: pt2.y - pt1.y,
      };

      if(normalize){
        if(rect.width < 0){
          rect.x += rect.width;
          rect.width = -rect.width;
        }
        if(rect.height < 0){
          rect.y += rect.height;
          rect.height = -rect.height;
        }
      }

      return rect;
    },
    getDragTarget(e){
      let targetInfo;
      if (e instanceof TouchEvent) {
        const touches = e.touches && e.touches.length > 1 ? e.touches : e.changedTouches || e.touches;
        if(this.dragInfo && this.dragInfo.startPoint){
          targetInfo = Object.values(touches).find(t=>t.identifier === this.dragInfo.startPoint.identifier);
        }else{
          targetInfo = touches[0];
        }
      } else if (e instanceof MouseEvent) {
        targetInfo = e;
      }else{
        return null;
      }
      return targetInfo.target;
    },
    resetPosition(){
      this.$nextTick(() => {
        if (!this.draggable) {
          // ドラッグ移動できない場合は 0, 0
          this.imageRectPoint = {
            x: 0,
            y: 0,
          }
          return;
        }

        // ドラッグ移動可能な場合
        // 左右は中央表示
        // トップは実画像が上の端になるように(先生用余白(上)をみせない)
        const p = {
          x: this.imageRectWidth * (1 - this.scale) * 0.5 * this.imageRectScale,
          y: 0,
        };
        if(this.teacherPadding){
          p.y = -this.teacherPaddingTop * this.scale * this.imageRectScale;
        }
        this.imageRectPoint = p;
      });
    },
    setImageRectPoint(newPoint) {
      const topRect = this.topElement.getBoundingClientRect();
      const scale = this.contentScale * this.imageRectScale * this.scale;

      // 横方向の制限チェック
      const topW = Math.round(topRect.width);
      const imgW = Math.round(this.imageRectWidth * scale);
      if (imgW <= topW) {
        // 横方向が見切れていない場合は画像が中央表示になるようにx座標を調整
        newPoint.x = (topW - imgW) / 2 / this.contentScale;
      } else {
        if (newPoint.x > 0) {
          // 正数は許可しない
          newPoint.x = 0;
        } else {
          const diffW = (topW - imgW) / this.contentScale;
          if (newPoint.x < diffW) {
            // トップと横の差のみ移動可能
            newPoint.x = diffW;
          }
        }
      }

      // 縦方向の制限チェック
      const topH = Math.round(topRect.height);
      const imgH = Math.round((this.imageRectHeight + this.imgHeightCalibrationValue) * scale);
      if (imgH <= topH) {
        // 縦方向が見切れていない場合は画像が中央表示になるようにy座標を調整
        newPoint.y = (topH - imgH) / 2 / this.contentScale;
      } else {
        if (newPoint.y > 0) {
          // 正数は許可しない
          newPoint.y = 0;
        } else {
          const diffH = (topH - imgH) / this.contentScale;
          if (newPoint.y < diffH) {
            // トップと縦の差のみ移動可能
            newPoint.y = diffH;
          }
        }
      }

      // 移動後の座標を設定
      this.imageRectPoint = newPoint;
    },
    existsTopElement(p) {
      // 指定座標にtopElementが存在するか確認
      return document.elementsFromPoint(p.pageX, p.pageY).includes(this.topElement);
    },
    /** 全消去 */
    eraseAll() {
      if(this.writeInfoTeacherComponent){
        this.writeInfoTeacherComponent.eraseAll();
      }
    },
    /** アンドゥ */
    undoHistory(){
      if(this.writeInfoTeacherComponent){
        this.writeInfoTeacherComponent.undoHistory();
      }
    },
    /** リドゥ */
    redoHistory(){
      if(this.writeInfoTeacherComponent){
        this.writeInfoTeacherComponent.redoHistory();
      }
    },
    /**
     * 対象オブジェクトのクローン(別インスタンス)を取得する
     * @param {*} value クローン対象
     * @returns クローンインスタンス
     */
     cloneObject(value){
      return JSON.parse(JSON.stringify(value));
    },
  },
}
</script>

<style lang="scss" scoped>
.note-image_top-element {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #b5b5b5;

  .note-image_image-rect {
    position: relative;
    display: block;

    .note-image_image-scale {
      width: 100%;
      height: 100%;
      background-color: #b5b5b5;
      transform-origin: top left;
      position: absolute;

      .note-image_q-img-wrap {
        background-color: white;
        position: relative;
        display: block;
        overflow: hidden;

        .note-image_q-img {
          position: absolute;
          display: block;
          width: 100%;
          -webkit-user-drag: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          &.note-image_stdb-custom {
            scale: 0.98;
            transform-origin: center center;
          }
        }

        .note-image_student {
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
        }
      }
  
      .note-image_teacher {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        pointer-events: none;
      }

      .rect-wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        .zoom-range-rect{
          position: absolute;
          border: solid 1px blue;
          background-color: #99f;
          opacity: 0.3;
          z-index: 350;
        }
      }
    }
  }
}
</style>