<template>
  <div class="badge-status">
    <span
      class="badge"
      :style="statusStyle"
    >{{ labelText }}</span>
  </div>
</template>

<script>
export default {
  name: "BadgeStatus",
  props: {
    labelText: {
      type: String,
      default: function () {
        return ""
      },
    },
    color: {
      type: String,
      default: function () {
        return ""
      },
    },
  },

  computed: {
    statusStyle: function () {
      return {
        background: this.color,
        width: "100%",
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.badge {
  padding: 0.6em 0.9em;
  border-radius: 2rem;
}
</style>
