var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "setting-preview-area" }, [
    _vm.list
      ? _c("div", [
          _c(
            "div",
            { staticClass: "preview-tab" },
            [
              _vm._l(_vm.displayList, function(item) {
                return [
                  _c(
                    "SettingTab",
                    _vm._b(
                      {
                        key: item.id,
                        attrs: { value: _vm.displayId },
                        on: {
                          input: function(id) {
                            return (_vm.currentId = id)
                          }
                        }
                      },
                      "SettingTab",
                      item,
                      false
                    )
                  )
                ]
              })
            ],
            2
          ),
          _c("div", { staticClass: "card preview-display" }, [
            _vm.isDisplayNotShowStudent
              ? _c("div", { staticClass: "not-display text-danger" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("messages.error.notShowStudent")) + " "
                  )
                ])
              : _vm._e(),
            _c("div", [
              _vm.isContent
                ? _c(
                    "div",
                    [
                      _c("SettingPreviewContent", {
                        staticClass: "card-body",
                        attrs: { content: _vm.current.content }
                      })
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("SettingPreviewImage", {
                        staticClass: "card-body",
                        attrs: {
                          image: _vm.current.content,
                          "stdb-flg": _vm.stdbFlg
                        }
                      })
                    ],
                    1
                  )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }