<template>
  <span class="icon-microsoft-login-logo">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384.42 56"
    >
      <g
        id="レイヤー_2"
        data-name="レイヤー 2"
      >
        <g id="microsoft">
          <rect
            class="cls-1"
            x="1.5"
            y="1.5"
            width="381.42"
            height="53"
            rx="26.5"
          />
          <circle
            class="cls-2"
            cx="341"
            cy="28"
            r="11.77"
          />
          <path
            class="cls-3"
            d="M338.69,33.64a.84.84,0,0,1-.55-.19.58.58,0,0,1,0-.92L343.57,28l-5.43-4.53a.58.58,0,0,1,0-.92.9.9,0,0,1,1.1,0l5.85,4.87a.73.73,0,0,1,0,1.16l-5.85,4.87A.84.84,0,0,1,338.69,33.64Zm5.29-5.3h0Z"
          />
          <path
            d="M80.6,20.74,86,34.08l5.34-13.34h2.8V37.09H91.93V30.72l.2-6.87L86.77,37.09H85.11L79.76,23.88,80,30.72v6.37H77.82V20.74Z"
          />
          <path
            d="M97.58,21.71a1.22,1.22,0,0,1,.31-.85,1.17,1.17,0,0,1,.91-.35,1.2,1.2,0,0,1,.93.35,1.22,1.22,0,0,1,.31.85,1.16,1.16,0,0,1-.31.84,1.19,1.19,0,0,1-.93.34,1.12,1.12,0,0,1-1.22-1.18Zm2.25,15.38H97.75V24.94h2.08Z"
          />
          <path
            d="M108,35.62a3,3,0,0,0,2-.68,2.38,2.38,0,0,0,.92-1.68h2a3.74,3.74,0,0,1-.71,2,4.9,4.9,0,0,1-1.77,1.5,5.18,5.18,0,0,1-2.35.56,5,5,0,0,1-3.94-1.65,6.61,6.61,0,0,1-1.47-4.54v-.34a7.37,7.37,0,0,1,.65-3.16,5,5,0,0,1,1.87-2.15,5.43,5.43,0,0,1,2.88-.76,4.89,4.89,0,0,1,3.4,1.22,4.4,4.4,0,0,1,1.44,3.18h-2a2.87,2.87,0,0,0-.89-1.93,2.78,2.78,0,0,0-2-.76,2.91,2.91,0,0,0-2.45,1.14,5.35,5.35,0,0,0-.87,3.29v.4a5.26,5.26,0,0,0,.86,3.23A2.92,2.92,0,0,0,108,35.62Z"
          />
          <path
            d="M121.06,26.8a6.4,6.4,0,0,0-1-.08,2.73,2.73,0,0,0-2.77,1.74v8.63h-2.08V24.94h2l0,1.4a3.22,3.22,0,0,1,2.89-1.63,2,2,0,0,1,.92.16Z"
          />
          <path
            d="M122.42,30.9a7.27,7.27,0,0,1,.7-3.21,5.19,5.19,0,0,1,2-2.2,5.29,5.29,0,0,1,2.86-.78,5.1,5.1,0,0,1,4,1.72A6.63,6.63,0,0,1,133.48,31v.15a7.14,7.14,0,0,1-.68,3.18,5.19,5.19,0,0,1-1.94,2.2,5.4,5.4,0,0,1-2.9.78,5.08,5.08,0,0,1-4-1.72A6.53,6.53,0,0,1,122.42,31Zm2.09.25a5.15,5.15,0,0,0,.94,3.24,3.17,3.17,0,0,0,5,0,5.65,5.65,0,0,0,.94-3.48,5.22,5.22,0,0,0-.95-3.24,3.17,3.17,0,0,0-5,0A5.73,5.73,0,0,0,124.51,31.15Z"
          />
          <path
            d="M143.17,33.86a1.53,1.53,0,0,0-.64-1.3,6.18,6.18,0,0,0-2.21-.81,10.13,10.13,0,0,1-2.51-.81,3.65,3.65,0,0,1-1.37-1.12,2.72,2.72,0,0,1-.44-1.55,3.17,3.17,0,0,1,1.26-2.53,5,5,0,0,1,3.23-1,5.06,5.06,0,0,1,3.35,1.07,3.38,3.38,0,0,1,1.29,2.73H143a1.87,1.87,0,0,0-.72-1.47,2.69,2.69,0,0,0-1.83-.62,2.82,2.82,0,0,0-1.77.49,1.55,1.55,0,0,0-.64,1.29,1.28,1.28,0,0,0,.59,1.14,7.51,7.51,0,0,0,2.15.73,11.07,11.07,0,0,1,2.52.83,3.66,3.66,0,0,1,1.44,1.16,2.87,2.87,0,0,1,.46,1.66,3.11,3.11,0,0,1-1.3,2.61,5.51,5.51,0,0,1-3.38,1,6,6,0,0,1-2.58-.52,4.13,4.13,0,0,1-1.76-1.44,3.49,3.49,0,0,1-.64-2h2.08A2.15,2.15,0,0,0,138.5,35a3.24,3.24,0,0,0,2.06.62,3.31,3.31,0,0,0,1.89-.48A1.46,1.46,0,0,0,143.17,33.86Z"
          />
          <path
            d="M147.4,30.9a7.14,7.14,0,0,1,.7-3.21,5.24,5.24,0,0,1,2-2.2,5.31,5.31,0,0,1,2.86-.78,5.11,5.11,0,0,1,4,1.72A6.63,6.63,0,0,1,158.46,31v.15a7.27,7.27,0,0,1-.68,3.18,5.19,5.19,0,0,1-1.94,2.2,5.4,5.4,0,0,1-2.9.78,5.1,5.1,0,0,1-4-1.72A6.57,6.57,0,0,1,147.4,31Zm2.09.25a5.15,5.15,0,0,0,.94,3.24,3.17,3.17,0,0,0,5,0,5.71,5.71,0,0,0,.93-3.48,5.15,5.15,0,0,0-.95-3.24,3.16,3.16,0,0,0-5,0A5.66,5.66,0,0,0,149.49,31.15Z"
          />
          <path
            d="M162.09,37.09V26.54h-1.92v-1.6h1.92V23.69a4.16,4.16,0,0,1,1-3,4,4,0,0,1,3-1.07,5.28,5.28,0,0,1,1.42.19l-.11,1.68a5.75,5.75,0,0,0-1.12-.11,2,2,0,0,0-1.56.6,2.37,2.37,0,0,0-.55,1.69v1.29h2.59v1.6h-2.59V37.09Z"
          />
          <path
            d="M171.87,22v3h2.27v1.6h-2.27v7.55a1.69,1.69,0,0,0,.3,1.09,1.28,1.28,0,0,0,1,.37,4.94,4.94,0,0,0,1-.14v1.68a5.91,5.91,0,0,1-1.59.22,2.59,2.59,0,0,1-2.1-.84,3.63,3.63,0,0,1-.71-2.39V26.54h-2.21v-1.6h2.21V22Z"
          />
          <path
            d="M197.38,23h-5.63a11,11,0,0,0-4,3.21A7.47,7.47,0,0,0,185.8,31a5.07,5.07,0,0,0,1,3.24,5.87,5.87,0,0,0,2.77,1.9,14.31,14.31,0,0,0,4.58.6l1.36,0-.11,1.66h-.06c-1.39,0-2.54,0-3.45-.07a11.82,11.82,0,0,1-2.89-.55,7.54,7.54,0,0,1-2.67-1.46,6.28,6.28,0,0,1-1.72-2.25,7.25,7.25,0,0,1-.56-2.9,8.4,8.4,0,0,1,1.38-4.48A12.46,12.46,0,0,1,189.09,23H177.83v-1.5h19.55Zm-2.79,4.79-1.29.58a27,27,0,0,0-1.63-3.16l1.22-.55A24.44,24.44,0,0,1,194.59,27.78Zm3.27-.69-1.29.53a22.35,22.35,0,0,0-1.61-3.1l1.2-.59A32.58,32.58,0,0,1,197.86,27.09Z"
          />
          <path
            d="M206.69,31.7h-1.95V26h-4.45V24.38h4.45V19.65h1.95v4.73H216V19.32H218v5.06h4.24V26H218v2.72q0,9.4-13,9.84l-.37-1.67q6.28-.23,8.85-2.4A7.13,7.13,0,0,0,216,28.78V26h-9.31Z"
          />
          <path
            d="M237,38.68h-2V27.5a43.54,43.54,0,0,1-10.52,3.92l-.59-1.54a37.48,37.48,0,0,0,11.5-4.71,23.67,23.67,0,0,0,6.32-5.85l1.84.72A24.25,24.25,0,0,1,237,26.19Z"
          />
          <path
            d="M267.38,27.44a19.89,19.89,0,0,1-4.9,5.69,21.87,21.87,0,0,1-6.26,3.57,33,33,0,0,1-7.65,1.64l-.39-1.73a29.6,29.6,0,0,0,6.55-1.34,19.58,19.58,0,0,0,4.92-2.35A24.66,24.66,0,0,0,263,30.2a18.7,18.7,0,0,0,2.8-3.73Zm-9.79-2.85-1.27,1.47a60.1,60.1,0,0,0-8-4.17l1-1.4A47.68,47.68,0,0,1,257.59,24.59Z"
          />
          <path
            d="M283,38.68h-2V27.5a43.54,43.54,0,0,1-10.52,3.92l-.59-1.54a37.48,37.48,0,0,0,11.5-4.71,23.67,23.67,0,0,0,6.32-5.85l1.84.72A24.25,24.25,0,0,1,283,26.19Z"
          />
          <path
            d="M313.38,27.44a19.89,19.89,0,0,1-4.9,5.69,21.87,21.87,0,0,1-6.26,3.57,33,33,0,0,1-7.65,1.64l-.39-1.73a29.6,29.6,0,0,0,6.55-1.34,19.58,19.58,0,0,0,4.92-2.35A24.66,24.66,0,0,0,309,30.2a18.7,18.7,0,0,0,2.8-3.73Zm-9.79-2.85-1.27,1.47a60.1,60.1,0,0,0-8-4.17l1-1.4A47.68,47.68,0,0,1,303.59,24.59Z"
          />
          <rect
            class="cls-4"
            x="32.3"
            y="14.04"
            width="12.62"
            height="12.62"
          />
          <rect
            class="cls-5"
            x="32.3"
            y="28.06"
            width="12.62"
            height="12.62"
          />
          <rect
            class="cls-6"
            x="46.32"
            y="14.04"
            width="12.62"
            height="12.62"
          />
          <rect
            class="cls-7"
            x="46.32"
            y="28.06"
            width="12.62"
            height="12.62"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: "IconMicrosoftLoginLogo",
};
</script>
<style lang="scss" scoped>
.cls-1,
.cls-3 {
  fill: #fff;
}
.cls-1 {
  stroke: #2f7bb9;
  stroke-miterlimit: 10;
  stroke-width: 3px;
}
.cls-2 {
  fill: #2f7bb9;
}
.cls-4 {
  fill: #f25022;
}
.cls-5 {
  fill: #00a4ef;
}
.cls-6 {
  fill: #7fba00;
}
.cls-7 {
  fill: #ffb900;
}
</style>