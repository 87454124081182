var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-student-handed-data" },
    [
      _c("div", { staticClass: "modal-mask" }, [
        _c("div", { staticClass: "modal-wrapper" }, [
          _c(
            "div",
            { staticClass: "modal-container-outer" },
            [
              _c("ButtonClose", {
                staticClass: "modal-default-button",
                nativeOn: {
                  click: function($event) {
                    return _vm.showCheckCloseModal.apply(null, arguments)
                  }
                }
              }),
              _c(
                "div",
                { ref: "modalContainer", staticClass: "modal-container" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "p-0 container-fluid d-flex align-items-center"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-inline-block align-middle m-auto handed-data-area text-start"
                        },
                        [
                          _c("p", { staticClass: "m-0 small" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("labels.handedDateReplaceWords")
                                ) +
                                " "
                            ),
                            _c(
                              "span",
                              { staticStyle: { "white-space": "nowrap" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      !_vm.studentData[_vm.studentIndex]
                                        ? ""
                                        : _vm.toYMDHMDate(
                                            _vm.studentData[_vm.studentIndex]
                                              .lastSubmitAddDate
                                          )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-inline-block align-middle text-center m-auto show-student-name"
                        },
                        [
                          _vm.showStudentName &&
                          _vm.studentData[_vm.studentIndex]
                            ? _c("h6", { staticClass: "m-0" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.studentData[_vm.studentIndex]
                                        .accountName
                                    ) +
                                    "の提出データ "
                                )
                              ])
                            : _vm._e(),
                          !_vm.showStudentName &&
                          _vm.studentData[_vm.studentIndex]
                            ? _c("h6", { staticClass: "m-0" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.studentData[_vm.studentIndex]
                                        .anonymousAccountName
                                    ) +
                                    "の提出データ "
                                )
                              ])
                            : _vm._e()
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "d-inline-block stamp-eval-wrapper" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "d-flex justify-content-end gap-4",
                              class: {
                                "no-editing-permission": !_vm.editingPermission
                              }
                            },
                            [
                              _vm.studentData[_vm.studentIndex]
                                ? _c(
                                    "StampArea",
                                    _vm._g(
                                      {
                                        attrs: {
                                          stamps: _vm.stamps,
                                          "selected-stamp": _vm.stamp,
                                          "init-stamp-code": _vm.initStampCode,
                                          "student-index": _vm.studentIndex
                                        }
                                      },
                                      {
                                        "on-change-stamp": _vm.onChangeStamp
                                      }
                                    )
                                  )
                                : _vm._e(),
                              _vm.studentData[_vm.studentIndex]
                                ? _c(
                                    "EvalArea",
                                    _vm._g(
                                      {
                                        attrs: {
                                          "init-eval-code": _vm.initEvalCode,
                                          "init-eval-manual-set-flg":
                                            _vm.initEvalManualSetFlg,
                                          "student-index": _vm.studentIndex
                                        }
                                      },
                                      {
                                        "on-change-eval": _vm.onChangeEval
                                      }
                                    )
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  ),
                  _c("div", { staticClass: "w-100 modal-contents-area" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-around modal-contents-area-inner"
                      },
                      [
                        _c("div", { staticClass: "modal-contents-area-left" }, [
                          _c(
                            "div",
                            { staticClass: "icon-wrapper" },
                            [
                              _c("IconCamera", {
                                attrs: { width: "25px", height: "25px" }
                              }),
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("labels.note")))
                              ])
                            ],
                            1
                          ),
                          _c("div", { staticClass: "card photo-area" }, [
                            _c(
                              "div",
                              { staticClass: "p-0 photo-area-wrapper" },
                              [
                                _vm._l(_vm.noteItemDateList, function(d, dIdx) {
                                  return [
                                    _vm.noteItemsByDate[d]
                                      ? _c(
                                          "div",
                                          {
                                            key: "date-index-" + dIdx,
                                            staticClass:
                                              "m-0 p-2 ps-3 text-start photo-area-date"
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.toYMDHMDate(
                                                    _vm.noteItemsByDate[d]
                                                      .lastSubmitAddDate
                                                  )
                                                ) +
                                                " 提出 "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.studentData[_vm.studentIndex] &&
                                    _vm.noteItemsByDate[d]
                                      ? _c(
                                          "ul",
                                          {
                                            key: "notes-index-" + dIdx,
                                            staticClass:
                                              "row row-cols-4 m-0 p-1 photo-area-list"
                                          },
                                          [
                                            _vm._l(
                                              _vm.noteItemsByDate[d].noteItems,
                                              function(note, childIndex) {
                                                return [
                                                  note.showThumbnail
                                                    ? _c(
                                                        "li",
                                                        {
                                                          key: childIndex,
                                                          staticClass:
                                                            "photo-area-list-item"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "justify-content-center notebook"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "notebook-wrapper"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "note-header"
                                                                    },
                                                                    [
                                                                      note.noteType ===
                                                                      1
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "lesson-no"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " No." +
                                                                                  _vm._s(
                                                                                    note.lessonNo
                                                                                  ) +
                                                                                  " "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      _vm.showIconEdit(
                                                                        note
                                                                      )
                                                                        ? _c(
                                                                            "IconEdit",
                                                                            {
                                                                              attrs: {
                                                                                width:
                                                                                  "10",
                                                                                height:
                                                                                  "10"
                                                                              }
                                                                            }
                                                                          )
                                                                        : _vm._e()
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "note-content"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "HomeworkNoteImage",
                                                                        {
                                                                          attrs: {
                                                                            content_scale: 1,
                                                                            img: note,
                                                                            "teacher-padding": false,
                                                                            draggable: false,
                                                                            editable: false,
                                                                            scale: 1,
                                                                            "config-page-teacher":
                                                                              _vm.configPageTeacher,
                                                                            "cond-teacher":
                                                                              _vm.condTeacher
                                                                          },
                                                                          on: {
                                                                            "update:img": function(
                                                                              $event
                                                                            ) {
                                                                              note = $event
                                                                            },
                                                                            "update:configPageTeacher": function(
                                                                              $event
                                                                            ) {
                                                                              _vm.configPageTeacher = $event
                                                                            },
                                                                            "update:config-page-teacher": function(
                                                                              $event
                                                                            ) {
                                                                              _vm.configPageTeacher = $event
                                                                            },
                                                                            "update:condTeacher": function(
                                                                              $event
                                                                            ) {
                                                                              _vm.condTeacher = $event
                                                                            },
                                                                            "update:cond-teacher": function(
                                                                              $event
                                                                            ) {
                                                                              _vm.condTeacher = $event
                                                                            }
                                                                          },
                                                                          nativeOn: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.openImageDetail(
                                                                                d,
                                                                                childIndex
                                                                              )
                                                                            }
                                                                          }
                                                                        }
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              }
                                            )
                                          ],
                                          2
                                        )
                                      : _vm._e()
                                  ]
                                })
                              ],
                              2
                            )
                          ])
                        ]),
                        _c(
                          "div",
                          { staticClass: "modal-contents-area-right" },
                          [
                            _c(
                              "div",
                              { staticClass: "icon-wrapper" },
                              [
                                _c("IconComment", {
                                  attrs: { width: "25px", height: "25px" }
                                }),
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("labels.comment")))
                                ])
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "chat-area-wrapper" },
                              [
                                _vm.studentData[_vm.studentIndex]
                                  ? _c(
                                      "ChatArea",
                                      _vm._g(
                                        {
                                          attrs: {
                                            "teacher-comment":
                                              _vm.teacherComment,
                                            "last-submit-add-date":
                                              _vm.studentData[_vm.studentIndex]
                                                .lastSubmitAddDate,
                                            "haishin-start-date":
                                              _vm.haishinStartDate,
                                            "submit-date": _vm.studentData[
                                              _vm.studentIndex
                                            ].handedDate
                                              ? _vm.studentData[
                                                  _vm.studentIndex
                                                ].handedDate +
                                                _vm.studentData[
                                                  _vm.studentIndex
                                                ].handedTime
                                              : "",
                                            "student-comment":
                                              _vm.studentData[_vm.studentIndex]
                                                .studentComment,
                                            comments:
                                              _vm.studentData[_vm.studentIndex]
                                                .comments,
                                            "return-comment":
                                              _vm.studentData[_vm.studentIndex]
                                                .teacherComment,
                                            "init-message": _vm.initMessage,
                                            "student-index": _vm.studentIndex,
                                            status:
                                              _vm.studentData[_vm.studentIndex]
                                                .status,
                                            "return-date":
                                              _vm.studentData[_vm.studentIndex]
                                                .returnDate,
                                            "school-info": _vm.schoolInfo,
                                            "editing-permission":
                                              _vm.editingPermission
                                          }
                                        },
                                        {
                                          "on-change-message":
                                            _vm.onChangeMessage
                                        }
                                      )
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-end mb-2" },
                      [
                        true
                          ? _c(
                              "div",
                              {
                                staticClass: "text-end return-annotation-text"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("labels.recommendEvalMessage")
                                    ) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "row gx-2 justify-content-center" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "modal-student-handed-buttons",
                            class: {
                              hidden: !_vm.studentData[_vm.studentIndex]
                            }
                          },
                          [
                            _c("ButtonBorderCircleMedium", {
                              attrs: {
                                "label-name": _vm.$t("buttons.cancel"),
                                "label-color": "#ff7f27",
                                "border-color": "#ff7f27",
                                width: "180px",
                                padding: "0"
                              },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.onClickCancel.apply(
                                    null,
                                    arguments
                                  )
                                }
                              }
                            }),
                            _vm.isStudentNotHanded()
                              ? _c(
                                  "div",
                                  { staticClass: "return-buttons" },
                                  [
                                    _c("ButtonBorderCircleMedium", {
                                      attrs: {
                                        "label-name": _vm.$t(
                                          "buttons.sendSave"
                                        ),
                                        "editing-permission":
                                          _vm.editingPermission,
                                        color: "send-back",
                                        "label-color": "#ff7f27",
                                        "border-color": "#ff7f27",
                                        width: "180px",
                                        padding: "0"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.onClickSendSave.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  { staticClass: "return-buttons" },
                                  [
                                    _c("ButtonBorderCircleMedium", {
                                      attrs: {
                                        "label-name": _vm.$t(
                                          "buttons.sendBack"
                                        ),
                                        "editing-permission":
                                          _vm.editingPermission,
                                        color: "send-back",
                                        "label-color": "#ff7f27",
                                        "border-color": "#ff7f27",
                                        width: "180px",
                                        padding: "0"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.onClickSendBack.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    }),
                                    _c("ButtonBorderCircleMedium", {
                                      attrs: {
                                        "label-name": _vm.$t(
                                          "buttons.returnOf"
                                        ),
                                        "editing-permission":
                                          _vm.editingPermission,
                                        color: "layout-theme-light",
                                        "label-color": "#ff7f27",
                                        "border-color": "#ff7f27",
                                        width: "180px",
                                        padding: "0"
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.onClickReturnOf.apply(
                                            null,
                                            arguments
                                          )
                                        }
                                      }
                                    })
                                  ],
                                  1
                                )
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _c("ul", { staticClass: "prev-area" }, [
                    _vm.isSubmitPrevData(_vm.studentIndex)
                      ? _c("li", {
                          staticClass: "prev",
                          on: {
                            click: function($event) {
                              return _vm.onClickDataTransferArea(_vm.isPrevData)
                            }
                          }
                        })
                      : _vm._e()
                  ]),
                  _c("ul", { staticClass: "next-area" }, [
                    _vm.isSubmitNextData(_vm.studentIndex)
                      ? _c("li", {
                          staticClass: "next",
                          on: {
                            click: function($event) {
                              return _vm.onClickDataTransferArea(
                                !_vm.isPrevData
                              )
                            }
                          }
                        })
                      : _vm._e()
                  ])
                ]
              )
            ],
            1
          )
        ])
      ]),
      _vm.showImageDetailModal
        ? _c(
            "ModalHandedImageDetail",
            _vm._g(
              {
                attrs: {
                  "student-data": _vm.studentData[_vm.studentIndex],
                  "selected-note-index": _vm.selectedNoteIndex,
                  "show-student-name": _vm.showStudentName,
                  "selected-note-item":
                    _vm.noteItemsByDate[_vm.selectedNoteSubmitDate],
                  "homework-details": _vm.homeworkDetails,
                  "config-page-teacher": _vm.configPageTeacher,
                  "cond-teacher": _vm.condTeacher,
                  editable: _vm.imageDetailEditable,
                  "write-info-object-to-string": _vm.writeInfoObjectToString,
                  "editing-permission": _vm.editingPermission
                },
                on: {
                  "update:selectedNoteItem": function($event) {
                    return _vm.$set(
                      _vm.noteItemsByDate,
                      _vm.selectedNoteSubmitDate,
                      $event
                    )
                  },
                  "update:selected-note-item": function($event) {
                    return _vm.$set(
                      _vm.noteItemsByDate,
                      _vm.selectedNoteSubmitDate,
                      $event
                    )
                  },
                  "update:homeworkDetails": function($event) {
                    _vm.homeworkDetails = $event
                  },
                  "update:homework-details": function($event) {
                    _vm.homeworkDetails = $event
                  },
                  "update:configPageTeacher": function($event) {
                    _vm.configPageTeacher = $event
                  },
                  "update:config-page-teacher": function($event) {
                    _vm.configPageTeacher = $event
                  },
                  "update:condTeacher": function($event) {
                    _vm.condTeacher = $event
                  },
                  "update:cond-teacher": function($event) {
                    _vm.condTeacher = $event
                  },
                  close: function($event) {
                    _vm.showImageDetailModal = false
                  }
                }
              },
              {
                "selected-node-transfer": _vm.transferImageDetailModal,
                showConfirmForOverWriteInfoSize: function() {
                  return (_vm.showOverWriteSize = true)
                }
              }
            )
          )
        : _vm._e(),
      _vm.showReturnMessage
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.returnMessage },
            on: { "close-confirm-modal-ok-only": _vm.onClickForReturnMessage }
          })
        : _vm._e(),
      _vm.showSendBackMessage
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.sendBackMessage },
            on: { "close-confirm-modal-ok-only": _vm.onClickForSendBackMessage }
          })
        : _vm._e(),
      _vm.showSendSaveMessage
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.sendSaveMessage },
            on: { "close-confirm-modal-ok-only": _vm.onClickForSendSaveMessage }
          })
        : _vm._e(),
      _vm.showCautionCloseMessage
        ? _c(
            "ModalConfirm",
            _vm._g(
              {
                attrs: { message: _vm.$t("messages.confirm.notSavedChanges") }
              },
              {
                "ok-confirm-modal": _vm.onClickOkForCautionCloseMessage,
                "close-confirm-modal": _vm.onClickCloseForCautionCloseMessage
              }
            )
          )
        : _vm._e(),
      _vm.showCautionTransferMessage
        ? _c(
            "ModalConfirm",
            _vm._g(
              {
                attrs: { message: _vm.$t("messages.confirm.notSavedChanges") }
              },
              {
                "ok-confirm-modal": _vm.onClickOkForCautionTransferMessage,
                "close-confirm-modal": _vm.onClickCloseForCautionTransferMessage
              }
            )
          )
        : _vm._e(),
      _vm.showCancelSendBackMessage
        ? _c(
            "ModalConfirm",
            _vm._g(
              { attrs: { message: _vm.$t("messages.confirm.cancelSendBack") } },
              {
                "ok-confirm-modal": _vm.onClickOkForCancelSendBackMessage,
                "close-confirm-modal": _vm.onClickCloseForCancelSendBackMessage
              }
            )
          )
        : _vm._e(),
      _vm.showOverWriteSize
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.$t("messages.error.overWriteInfoSize") },
            on: {
              "close-confirm-modal-ok-only": function() {
                return (_vm.showOverWriteSize = false)
              }
            }
          })
        : _vm._e(),
      _vm.showNoEditPermission
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.$t("messages.error.noEditingPermission") },
            on: {
              "close-confirm-modal-ok-only": function($event) {
                _vm.showNoEditPermission = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }