var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "icon-comment" }, [
    _c(
      "svg",
      {
        staticStyle: { "enable-background": "new 0 0 512 512" },
        attrs: {
          id: "_x32_",
          version: "1.1",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          x: "0px",
          y: "0px",
          width: _vm.width,
          height: _vm.height,
          viewBox: "0 0 512 512",
          "xml:space": "preserve"
        }
      },
      [
        _c("g", [
          _c("path", {
            style: "fill:" + _vm.fillColor + ";",
            attrs: {
              d:
                "M493.3,386.7c11.7-16.7,18.7-36.4,18.7-57.3c0-26.1-10.8-50.2-28.1-69c-17.4-18.9-41.4-33-68.9-40.5l-7.2,26.6\n          c23,6.2,42.5,17.9,55.9,32.6c13.4,14.7,20.9,31.9,20.9,50.4c0,14.9-4.8,28.9-13.7,41.5c-8.8,12.6-21.8,23.6-37.7,31.8l-11.6,6\n          l15.1,33.7c-45.7-8-88.1-22.8-109.6-31.2l-0.4-0.2l-0.4-0.1c-28.8-9-51.2-26.8-62.6-47.7l-24.2,13.3c15.5,28.1,43.5,49.5,77.7,60.4\n          v0c26.5,10.3,82.3,29.8,140.8,36.4l23.9,2.7L457,420.5C471.5,411.4,484,400,493.3,386.7z"
            }
          }),
          _c("path", {
            style: "fill:" + _vm.fillColor + ";",
            attrs: {
              d:
                "M359.1,286.8c22.4-25.5,35.9-57.3,35.9-91.6c0-22.4-5.8-43.9-16.1-63.1c-15.4-28.9-40.8-52.8-72.2-69.6\n          c-31.4-16.8-68.9-26.4-109.2-26.4C143.8,36.1,95,53.3,59,81.7c-18,14.2-32.7,31.3-43,50.5C5.8,151.4,0,172.8,0,195.2\n          c0,28.6,9.4,55.5,25.6,78.4C39.4,293.3,58,310,80.1,323.1l-33.2,74.2l23.9-2.7c82.7-9.2,161.9-37,199.5-51.5\n          C306,331.7,336.9,312.2,359.1,286.8z M260.7,317.2l-0.4,0.2C227.8,330.1,162,353,92,363.9l23.7-53l-11.6-6\n          c-23.5-12-42.7-28.4-56-47.2c-13.2-18.8-20.5-40-20.5-62.5c0-17.7,4.5-34.5,12.8-50.1c12.4-23.3,33.6-43.7,60.9-58.3\n          c27.2-14.6,60.4-23.2,96.2-23.2c47.8,0,90.8,15.4,121.3,39.6c15.3,12.1,27.5,26.3,35.8,41.9c8.3,15.6,12.8,32.4,12.8,50.1\n          c0,27-10.5,52.1-29.1,73.4c-18.6,21.3-45.4,38.5-77.1,48.5L260.7,317.2z"
            }
          }),
          _c("path", {
            style: "fill:" + _vm.fillColor + ";",
            attrs: {
              d:
                "M119.6,177.7c-11.8,0-21.4,9.6-21.4,21.4c0,11.8,9.6,21.4,21.4,21.4c11.8,0,21.5-9.6,21.5-21.4\n          C141,187.3,131.4,177.7,119.6,177.7z"
            }
          }),
          _c("path", {
            style: "fill:" + _vm.fillColor + ";",
            attrs: {
              d:
                "M202.3,177.7c-11.8,0-21.4,9.6-21.4,21.4c0,11.8,9.6,21.4,21.4,21.4c11.8,0,21.4-9.6,21.4-21.4\n          C223.7,187.3,214.1,177.7,202.3,177.7z"
            }
          }),
          _c("path", {
            style: "fill:" + _vm.fillColor + ";",
            attrs: {
              d:
                "M285,177.7c-11.8,0-21.4,9.6-21.4,21.4c0,11.8,9.6,21.4,21.4,21.4c11.8,0,21.5-9.6,21.5-21.4\n          C306.5,187.3,296.9,177.7,285,177.7z"
            }
          })
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }