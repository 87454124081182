<template>
  <div class="form-date-field">
    <label
      v-if="showTitle"
      :for="id"
      class="form-label"
      :class="{ 'required-label': isRequire}"
    >{{ labelText }}</label>
    <input
      :id="id"
      v-model="inputValue"
      type="date"
      class="form-control form-control-sm"
      :aria-describedby="help"
      :disabled="disabled"
      :min="dateRange.min"
      :max="dateRange.max"
    >
  </div>
</template>

<script>
export default {
  name: "FormDateField",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    labelText: {
      type: String,
      default: function () {
        return ""
      },
    },
    id: {
      type: String,
      default: function () {
        return ""
      },
    },
    help: {
      type: String,
      default: function () {
        return ""
      },
    },
    initialValue: {
      type: String,
      default: function () {
        return ""
      },
    },
    value: {
      type: String,
      default: function () {
        return ""
      },
    },
    isRequire: {
      type: Boolean,
      default: function (){
        return false
      }
    },
    disabled: { type: Boolean, default: false },
    dateRange: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },

  data: function () {
    return {
    }
  },

  computed: {
    showTitle: {
      get() {
        if (this.labelText === null || this.labelText === undefined || this.labelText === "") {
          return false
        }
        return true
      },
    },
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value)
      },
    }
  },

  mounted: function () {
    if (this.initialValue !== null && this.initialValue != "") {
      // 初期値設定
      this.inputValue = this.initialValue
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep label {
  display: inline;
}
.required-label:before { 
   content:"*";
   color:red;
}
</style>
