var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "button-open-help" }, [
    _c(
      "a",
      { attrs: { href: _vm.url, target: "_blank" } },
      [_c("IconHelp", { attrs: { width: "25px", height: "25px" } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }