<template>
  <div class="form-login-password-field">
    <label
      :for="id"
      class="form-label"
    >{{ labelText }}</label>
    <p class="passwordBox">
      <input
        :id="id"
        :type="inputType"
        :value="value"
        :disabled="isDisabled"
        style="background-color: #e9e9e9"
        class="form-control form-login-password-control"
        :aria-describedby="help"
        @input="$emit('input', $event.target.value)"
      >
      <span
        class="field-icon"
      >
        <i
          :class="[{'eye_open':is_eye_open}, {'eye_close':is_eye_close}]" 
          @click="changePWview()"
        />
      </span>
    </p>
    <div :id="help">
      {{ helpMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: "FormLoginPasswordField",
  props: {
    labelText: {
      type: String,
      default: function () {
        return ""
      },
    },
    id: {
      type: String,
      default: function () {
        return ""
      },
    },
    value: {
      type: String,
      default: function () {
        return ""
      },
    },
    help: {
      type: String,
      default: function () {
        return ""
      },
    },
    helpMessage: {
      type: String,
      default: function () {
        return ""
      },
    },
    isDisabled: Boolean,
  },
  data: function () {
    return {
      is_eye_open: true,
      is_eye_close: false,
      inputType: "password",
    }
  },
  methods: {
    changePWview: function () {
            this.is_eye_open = !this.is_eye_open;
            this.is_eye_close = !this.is_eye_close;
            this.inputType = this.is_eye_close ? "text" : "password";
      },
  },
}
</script>
<style>
.form-label {
  margin: 0;
}
.form-login-password-control {
  height: 50px;
}
.form-login-password-field {
  width: 94%;
}
.form-login-password-field > label {
  margin: 0;
}
::-ms-reveal{
  display: none;
}
.passwordBox {
  position: relative;
}
.eye_open {
  background: url("../../assets/images/login/eye_open.png");
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
}
.eye_close {
  background: url("../../assets/images/login/eye_close.png");
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
}
.field-icon {
  position: absolute;
  top:15px;
  right: 10px;
  width: 25px;
  height: 25px;
}
</style>