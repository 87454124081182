<template>
  <div class="button-preview">
    <button
      type="button"
      class="btn"
    >
      <IconPreview
        width="25px"
        height="25px"
      />
    </button>
  </div>
</template>

<script>
import IconPreview from "@/components/atoms/icons/IconPreview.vue"

export default {
  name: "ButtonPreview",
  components: {
    IconPreview,
  },
}
</script>
