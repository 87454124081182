/**
 * アカウント関連 API
 * WebAPI が3種類あるが、API に依存せず、アカウントに関する API を定義する
 * ※LMS サーバの API は全て POST メソッド、Request body
 */
import apiInstance from './axiosBase'
import { funcs } from '@/dbs/indexedDb'

export default {
  /**
   * Create 系 API
   */

  /**
   * Read 系 API
   */

  /**
   * セッショントークンを取得する
   * @param {String} accountId ID
   * @param {String} password パスワード
   * @returns セッショントークン
   */
  async getSessionToken(accountId, password) {
    let headers = {
      'Accept': 'application/json',
      'Authorization': `Bearer ${password}`
    }

    try {
      return await apiInstance.axiosStreamBase({
        method: 'get',
        url: `/accounts/${accountId}/token`,
        headers: headers,
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 数研アカウント情報を取得する
   * @param {Number} serviceId サービス ID（LMS は、2:新デジタル教科書システム）
   * @param {String} accountId ID
   * @param {String} sessionToken セッショントークン
   * @returns 数研アカウント情報
   */
  async getSukenAccountInfo(serviceId, accountId, sessionToken) {
    try {
      // アカウント基盤のクエリストリングの区切り記号が「/」となっているため、
      // URL のパスストリングとして記述
      return await apiInstance.axiosAccountBase({
        method: 'get',
        url: `/account/info/serviceid=${serviceId}&accountid=${accountId}&token=${sessionToken}`,
      })
    } catch (error) {
      return error
    }
  },

  /**
   * LMS サーバ API に認証を実行する
   * @param {String} accountId ID
   * @param {String} apiToken LMS API トークン
   * @returns API トークン
   */
  async getLmsApiToken(accountId, apiToken) {
    try {
      return await apiInstance.axiosLmsBase({
        method: 'post',
        url: `/lms/login`,
        data: {
          account_id: accountId,
          key: process.env.VUE_APP_LMS_API_KEY,
          token: apiToken,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 数研アカウントが管理対象のグループ ID 一覧を取得する
   * @param {Number} serviceId サービス ID（LMS は、2:新デジタル教科書システム）
   * @param {String} accountId ID
   * @param {String} sessionToken セッショントークン
   * @returns オブジェクト型（数研アカウント ID、グループ ID の一覧）
   */
  async getGroupIdsOfSukenAccount(serviceId, accountId, sessionToken) {
    // console.log(`グループリスト取得`)
    try {
      // アカウント基盤のクエリストリングの区切り記号が「/」となっているため、
      // URL のパスストリングとして記述
      return await apiInstance.axiosAccountBase({
        method: 'get',
        url: `/group/list/serviceid=${serviceId}&accountid=${accountId}&token=${sessionToken}`,
      })
    } catch (error) {
      return error.response
    }
  },

  /**
   * 数研アカウントとグループIDに紐づく商品ID一覧を取得する
   * @param {Number} serviceId サービス ID（LMS は、2:新デジタル教科書システム）
   * @param {Number} groupId グループID
   * @param {String} accountId ID
   * @param {String} sessionToken セッショントークンProduct
   * @returns 生成された閲覧権限 Cookie
   */
  async getProductIdsOfSukenAccount(serviceId, groupId, accountId, sessionToken) {
    console.log(`商品ID一覧取得`)
    try {
      // アカウント基盤のクエリストリングの区切り記号が「/」となっているため、
      // URL のパスストリングとして記述
      return await apiInstance.axiosAccountBase({
        method: 'get',
        url: `/group/licenselist/serviceid=${serviceId}&groupid=${parseInt(groupId)}&targetaccountid=&productid=&accountid=${accountId}&token=${sessionToken}`,
      })
    } catch (error) {
      return error.response
    }
  },

  /**
   * グループ ID に紐づくアカウントが所有している教材パッケージ関連のグループアカウントリストを取得する
   * @param {String} groupId グループ ID
   * @param {String} teacherAccountId 先生アカウント ID
   * @param {Array} curriculums 教科（例 ['ma', 'sa']）
   * @param {String} sessionToken セッショントークン
   * @returns 教材パッケージの一覧
   */
  async getSukenAccountWithBooksAccounts(
    groupId,
    teacherAccountId,
    sessionToken
  ) {
    // console.log(`[データ配信API] /groups/${groupId}/accounts/products/books/accounts getSukenAccountWithBooksAccounts`)
    let headers = {
      'Accept': 'application/json',
      'Authorization': `Bearer ${sessionToken}`
    }


    try {
      return await apiInstance.axiosStreamLmsApiBase({
        method: 'get',
        url: `/groups/${groupId}/accounts/products/books/accounts`,
        headers: headers,
        params: {
          teacher_account_id: teacherAccountId,
        },
      })
    } catch (error) {
      return Promise.reject(error)
    }
  },

  /**
   * グループ ID に紐づくアカウントが所有している教材パッケージの一覧を取得する
   * @param {String} groupId グループ ID
   * @param {String} teacherAccountId 先生アカウント ID
   * @param {Array} curriculums 教科（例 ['ma', 'sa']）
   * @param {String} sessionToken セッショントークン
   * @param {Array} groupAccountIdList グループアカウントリスト
   * @param {String} randomKey ランダムキー
   * @param {Number} timeout タイムアウト
   * @returns 教材パッケージの一覧
   */
  async getSukenAccountWithBooks(
    groupId,
    teacherAccountId,
    curriculums,
    sessionToken,
    groupAccountIdList,
    randomKey,
    timeout = 120000
  ) {
    let headers = {
      'Accept': 'application/json',
      'Authorization': `Bearer ${sessionToken}`
    }

    let paramCurriculums
    if(typeof curriculums === 'object'){
      if(curriculums.length > 1){
        paramCurriculums = Array.from(new Set(curriculums)).join(',')
      }else{
        paramCurriculums = curriculums[0]
      }
    }else{
      paramCurriculums = curriculums
    }

    let url = `/groups/${groupId}/accounts/products/books`;
    // let key = `${url}-${teacherAccountId}-${paramCurriculums}`;
    // // 有効期限内のレスポンスキャッシュが存在する場合、レスポンスとして返却する
    // let responseCache = apiInstance.response(key);
    // if (responseCache !== null) {
    //   return responseCache.data;
    // }
    let paramGroupAccountIdList
    if(typeof groupAccountIdList === 'object'){
      if(groupAccountIdList.length > 1){
        paramGroupAccountIdList = Array.from(new Set(groupAccountIdList)).join(',')
      }else{
        paramGroupAccountIdList = groupAccountIdList[0]
      }
    }else{
      paramGroupAccountIdList = groupAccountIdList
    }

    // console.log(`[データ配信API] /groups/${groupId}/accounts/products/books getSukenAccountWithBooks`)
    try {
      let responseData = await apiInstance.axiosStreamLmsApiBase({
        method: 'get',
        url: url,
        headers: headers,
        params: {
          teacher_account_id: teacherAccountId,
          curriculums: paramCurriculums,
          group_account_id_list: paramGroupAccountIdList,
          random_key: randomKey,
        },
        timeout: timeout,
      })
      // // レスポンスをキャッシュとして保存する
      // apiInstance.setResponseCache(key, responseData);
      return responseData;
    } catch (error) {
      return Promise.reject(error)
    }
  },

  /**
   * グループ ID に紐づくアカウントが所有している教材パッケージの閲覧権限を生成する
   * @param {String} groupId グループ ID
   * @param {String} studentAccountId 生徒アカウント ID
   * @param {String} productId 商品 ID
   * @param {String} bookId 教材 ID
   * @param {String} teacherAccountId 先生アカウント ID
   * @param {String} sessionToken セッショントークン
   * @returns 生成された閲覧権限 Cookie
   */
  async getCookieSukenAccountWithBooks(
    groupId,
    studentAccountId,
    productId,
    bookId,
    teacherAccountId,
    sessionToken
  ) {
    let url = `/groups/${groupId}/accounts/${studentAccountId}/products/${productId}/books/${bookId}/credentials`;
    let key = `${url}-${teacherAccountId}`;
    // 有効期限内のレスポンスキャッシュが存在する場合、レスポンスとして返却する
    let responseCache = await funcs.responseCache.get(key);
    if (responseCache !== null) {
      return responseCache.data;
    }

    let headers = {
      'Accept': 'application/json',
      'Authorization': `Bearer ${sessionToken}`
    }

    // console.log(`[データ配信API] /groups/${groupId}/accounts/${studentAccountId}/products/${productId}/books/${bookId}/credentials getCookieSukenAccountWithBooks`)
    try {
      let responseData = await apiInstance.axiosStreamLmsApiBase({
        method: 'post',
        url: url,
        headers: headers,
        params: {
          teacher_account_id: teacherAccountId
        },
      })
      // レスポンスをキャッシュとして保存する
      await funcs.responseCache.set(key, responseData);
      return responseData;
    } catch (error) {
      return error
    }
  },

  /**
   * Update 系 API
   */

  /**
   * Delete 系 API
   */
}