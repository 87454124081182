<template>
  <div class="form-login-id-field">
    <label
      :for="id"
      class="form-label"
    >{{ labelText }}</label>
    <input
      :id="id"
      type="text"
      :value="value"
      :disabled="isDisabled"
      class="form-control form-login-id-control"
      placeholder=" "
      :aria-describedby="help"
      @input="$emit('input', $event.target.value)"
    >
    <div :id="help">
      {{ helpMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: "FormLoginIdField",
  props: {
    labelText: {
      type: String,
      default: function () {
        return ""
      },
    },
    id: {
      type: String,
      default: function () {
        return ""
      },
    },
    value: {
      type: String,
      default: function () {
        return ""
      },
    },
    help: {
      type: String,
      default: function () {
        return ""
      },
    },
    helpMessage: {
      type: String,
      default: function () {
        return ""
      },
    },
    isDisabled: Boolean,
  },
}
</script>

<style lang="scss" scoped>
:focus {
  outline: none;
  border: 1px solid #fd9140;
}
label {
  margin: 0;
}
input:not(:placeholder-shown) {
  background-color: #fdf1e9;
}
input:placeholder-shown {
  background-color: #e9e9e9;
}
.form-login-id-control {
  height: 50px;
}
.form-login-id-field {
  margin-top: 2%;
  width: 94%;
}
</style>
