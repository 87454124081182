/**
 * 値関連の変換関数
 */

export default {
  /**
   * オブジェクトを配列に変換する
   * @param {Object} object 対象のオブジェクトデータ
   * @param {String} keyName キー名称（未指定は「key」）
   * @param {String} valueName 値名称（未指定は「value」）
   * @returns 変換した配列
   */
  objectToArray(object, keyName = "key", valueName = "value") {
    return Object.entries(object).map(([key, value]) => ({
      [keyName]: key,
      [valueName]: value,
    }))
  },

  /**
   * 配列内の文字列をカンマ区切りにして返す
   * @param {Array} arr 文字列の配列
   * @returns カンマ区切りの文字列
   */
  stringArrayToCommaDelimitedString(arr) {
    if (arr.length === 0) {
      return ""
    }
    let str = ""
    for (let i = 0; i < arr.length - 1; i++) {
      str += arr[i] + ","
    }
    str += arr[arr.length - 1]
    return str
  },

  /**
   * 年月日数字8桁を区切り記号付きの年月日文字列に変換する
   * @param {String} date: yyyyMMdd形式
   * @param {String} separator: 区切り記号（省略時は「/」）
   * @return {String} date: yyyy/M/d形式
   */
  toDateStringSeparator(date, separator = "/") {
    const year = date.slice(0, 4)
    const month = parseInt(date.slice(4, 6))
    const day = parseInt(date.slice(6))

    return `${year}${separator}${month}${separator}${day}`
  },

  /**
   * 年月日時分12桁を区切り記号付きの年月日時分文字列に変換する
   * @param {String} date yyyyMMddHHmm形式
   * @param {String} separator 区切り記号（省略時は「/」
   * @returns {String} date: yyyy/M/D H:mm形式
   */
  toDateTimeStringSeparator(date, separator = "/") {
    const year = date.slice(0, 4)
    const month = parseInt(date.slice(4, 6))
    const day = parseInt(date.slice(6, 8))
    const hour = parseInt(date.slice(8, 10))
    const minute = date.slice(10, 12)
    return `${year}${separator}${month}${separator}${day} ${hour}:${minute}`
  },

  /**
   * 年月日数字8桁を日付型の年月日に変換する
   * @param {String} date: yyyyMMdd形式
   * @return {Date} date形式
   */
  stringToDate(date) {
    return new Date(date)
  },

  /**
   * 年月日数字8桁を区切り記号とゼロパディング付きの年月日に変換する
   * @param {String} date yyyyMMdd 形式の日付文字列
   * @param {String} separator 区切り記号（省略時は「/」）
   * @return {String} yyyy/MM/dd 形式の日付文字列
   */
  toDateStringSeparatorZeroPadding(date, separator = "/") {
    const year = date.slice(0, 4)
    const month = parseInt(date.slice(4, 6))
    const day = parseInt(date.slice(6))

    return `${year}${separator}${("0" + month).slice(-2)}${separator}${(
      "0" + day
    ).slice(-2)}`
  },

  /**
   * 日時形式をyyyy/MM/dd形式に変換する
   * @param {Date} date 日時（yyyy-MM-dd hh:mm:ss.000）
   * @return {Date} yyyy/MM/dd 形式の日付
   */
  datetimeToDate(date) {
    if (date) {
      return new Date(date.getFullYear(), date.getMonth(), date.getDate())
    }
    return date
  },

  /**
   * Date型の引数を「yyyy-MM-dd」形式の日付文字列に変換する
   * @param {Date} dt
   * @returns 「yyyy-MM-dd」形式の日付文字列
   */
  formatDate(dt) {
    var y = dt.getFullYear()
    var m = ("00" + (dt.getMonth() + 1)).slice(-2)
    var d = ("00" + dt.getDate()).slice(-2)
    return y + "-" + m + "-" + d
  },
  /**
   * Date型の引数を「yyyy/MM/dd hh:mm:ss」形式の日付文字列に変換する
   * @param {Date} dt
   * @returns 「yyyy/MM/dd hh:mm:ss」形式の日付文字列
   */
  formatDatetime(dt) {
    var y = dt.getFullYear()
    var m = ("00" + (dt.getMonth() + 1)).slice(-2)
    var d = ("00" + dt.getDate()).slice(-2)
    var h = ("00" + dt.getHours()).slice(-2)
    var mm = ("00" + dt.getMinutes()).slice(-2)
    var s = ("00" + dt.getSeconds()).slice(-2)
    return y + "/" + m + "/" + d + " " + h + ":" + mm + ":" + s
  },

  /**
   * Date型の引数を「yyyy/MM/dd」形式の日付文字列に変換する
   * @param {Date} dt
   * @returns 「yyyy/MM/dd」形式の日付文字列
   */
  formatDateForSlash(dt) {
    var y = dt.getFullYear()
    var m = dt.getMonth() + 1
    var d = dt.getDate()
    return y + "/" + m + "/" + d
  },

  /**
   * Date型の引数を「yyyyMMddhhmmss」形式の日付文字列に変換する
   * @param {Date} dt
   * @returns 「yyyyMMddhhmmss」形式の日付文字列
   */
  formatDateForYYYYMMDDHHMMSS(dt) {
    return "" + dt.getFullYear() + ("00" + (dt.getMonth() + 1)).slice(-2) + ("00" + dt.getDate()).slice(-2) + ("00" + dt.getHours()).slice(-2) + ("00" + dt.getMinutes()).slice(-2) + ("00" + dt.getSeconds()).slice(-2)
  },

  /**
   * setMonthを安全に行う
   * @param {Date} date Date型オブジェクト
   * @param {Number} monthIndex 月のインデックス（例：1月なら0）
   * @returns {Date} 安全にsetMonthした後のDate
   */
  setMonthSafely(date, monthIndex) {
    // setMonthした時、存在しない日付だった場合は繰り上がってしまう
    // （例：4/31は存在しないので5/1に繰り上がる）
    // その為、繰り上がった場合は正しい日付に戻す必要がある
    // 参考資料：https://tukihatu.hatenablog.com/entry/20120531/1338458469
    // 判定方法：締切日の指定は月単位なので、退避しておいた日付と食い違っていたら「繰り上がった」と見做す
    const day = date.getDate()
    date.setMonth(monthIndex)
    if (day !== date.getDate()) {
      // うるう年を考慮する
      // judgeDateが3/1であり、尚且つ年が4の倍数なら2を
      // そうでないなら1を現在の日付から減算する
      if (date.getMonth() !== 2 || date.getFullYear() % 4 !== 0) {
        date.setDate(date.getDate() - 1)
      } else {
        date.setDate(date.getDate() - 2)
      }
    }
    return date
  },
}
