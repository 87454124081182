<template>
  <div class="icon-bulk-return">
    <svg id="_レイヤー_1" data-name="レイヤー 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65.41 55.37" :width="width"
      :height="height">
      <defs>
      </defs>
      <path class="cls-2" d="m2.5,40.17V4.91c0-1.33,1.08-2.41,2.41-2.41h29.55" />
      <path class="cls-2"
        d="m45.31,47.25v3.27c0,1.3-1.05,2.35-2.35,2.35H15.06c-1.34,0-2.43-1.09-2.43-2.44V14.96c0-1.34,1.09-2.43,2.43-2.43h27.93c1.28,0,2.32,1.04,2.32,2.32v14.36" />
      <line class="cls-2" x1="20.24" y1="22.58" x2="37.69" y2="22.58" />
      <line class="cls-2" x1="20.24" y1="32.68" x2="31.02" y2="32.68" />
      <line class="cls-2" x1="20.24" y1="42.83" x2="31.02" y2="42.83" />
      <rect class="cls-2" x="35.63" y="35.97" width="14.16" height="4.43" />
      <polygon class="cls-1" points="61.66 38.19 54.7 44.41 54.7 31.97 61.66 38.19" />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconBulkReturn',
  props: {
    width: {
      type: String,
      default: function () {
        return '25px'
      },
    },
    height: {
      type: String,
      default: function () {
        return '25px'
      },
    },
  },

  data: () => ({
    // アイコンにタイトルを設定する場合は、iconName を表示するようにする
    // ファイル名：宿題進捗_戻る(icooon-mono).svg
    iconName: '一括返却'
  }),
}
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: #fa710b;
}

.cls-1,
.cls-2 {
  stroke: #fa710b;
  stroke-miterlimit: 10;
  stroke-width: 5px;
}

.cls-2 {
  fill: none;
}
</style>