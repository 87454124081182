<template>
  <footer class="footer">
    <div class="text-end">
      {{ $t("labels.copyright") }}
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
}
</script>

<style lang="scss" scoped>
footer {
  line-height: 42px;
  margin-right: 10px;
  padding: 0;
  font-size: 0.8rem;
}
</style>