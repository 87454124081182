<template>
  <div class="loading-screen">
    <div class="background">
      <div class="spinner-border spinner" />
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingScreen",
  mounted() {
    // 何かしらにフォーカスが当たっていれば外す
    // ※ローディング画面でクリックはブロックできても、Enterキー押下はブロックできないので
    document.activeElement.blur()
  },
}
</script>

<style lang="scss" scoped>
.background {
  background-color: #ffffff;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5rem;
  height: 5rem;
  color: var(--bs-layout-theme);
}
</style>
