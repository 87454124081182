<template>
  <div class="button-clear">
    <button
      type="button"
      class="btn"
    >
      <IconClose
        width="10px"
        height="10px"
        colorStyle="#212529"
      />
      <div class="btn-label" v-text="$t('buttons.clear')"></div>
    </button>
  </div>
</template>

<script>
import IconClose from "@/components/atoms/icons/IconClose.vue"

export default {
  name: "ButtonClear",
  components: {
    IconClose,
  },

  data: () => ({}),
}
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  font-weight: bold;
  padding: 0.16em 1.0em;
  border: 1px solid;
  font-size: 0.75em;
}
.btn-label {
  margin: 0;
  padding: 0;
  margin-left: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>