var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header" },
    [
      _c("nav", { staticClass: "navbar navbar-expand-lg navbar-light mx-3" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c(
            "div",
            { staticClass: "row justify-content-between align-items-end" },
            [
              _c("div", { staticClass: "col-md-6" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [_c("SViewerLogo")], 1)
                ]),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "span",
                      {
                        staticClass: "navbar-brand fw-bold header-title",
                        on: { click: _vm.pushToRoot }
                      },
                      [_vm._v(_vm._s(_vm.$t("labels.managementSite")))]
                    ),
                    _c("span", { staticClass: "navbar-text" }, [
                      _vm._v(_vm._s(_vm.loginUserInfo.schoolName))
                    ])
                  ])
                ])
              ]),
              _c(
                "div",
                { staticClass: "col-md-auto text-end" },
                [
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.loginUserInfo.userName) +
                        " " +
                        _vm._s(_vm.$t("labels.dear"))
                    )
                  ]),
                  _c("ButtonMini", {
                    staticClass: "mini-button",
                    attrs: {
                      "label-name": _vm.$t("buttons.logout"),
                      color: "layout-theme",
                      "label-color": "white",
                      "border-color": "#ff7f27"
                    },
                    nativeOn: {
                      click: function($event) {
                        return _vm.onLogout.apply(null, arguments)
                      }
                    }
                  })
                ],
                1
              )
            ]
          )
        ])
      ]),
      _vm.isLoading ? _c("LoadingScreen") : _vm._e(),
      _vm.displayGroupReloadConfirm
        ? _c(
            "ModalConfirm",
            _vm._g(
              {
                attrs: { message: _vm.$t("messages.confirm.refleshGroupList") }
              },
              {
                "ok-confirm-modal": _vm.onRefleshGroupList,
                "close-confirm-modal": function() {
                  _vm.displayGroupReloadConfirm = false
                }
              }
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }