<template>
  <div class="modal-reset-ques-num-stdb-question">
    <transition
      name="modal"
      appear
    >
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container-outer">
            <ButtonClose
              class="modal-default-button"
              @click.native="$emit('close')"
            />
            <div class="modal-container mx-3 py-4">
              <div class="row mb-5 question-setting mt-3">
                <div class="col-md-4 tree-wrap">
                  <FormTextField
                    :label-text="$t('labels.taskName')"
                    :initial-value="taskName"
                    :max-length="50"
                    :disabled="true"
                    @input="inputTaskName"
                    @on-blur="blurTaskName"
                  />
                  <div
                    v-if="showMsgTaskName"
                    class="text-danger"
                  >
                    {{ showMsgTaskName }}
                  </div>
                  <br>

                  <div v-once>
                    {{ $t("labels.howToDisplayInViewer") }}
                  </div>
                  <div id="display-method">
                    <label class="px-4 py-1">
                      <input
                        v-model="checkDisplayMethod"
                        type="radio"
                        value="oneQuestion"
                        :disabled="true"
                      >
                      {{ $t("labels.oneQuestion") }}
                    </label>
                    <div class="px-5 py-1">
                      <label>
                        <input
                          id="chkAnswer"
                          v-model="answerValue"
                          type="checkbox"
                          :disabled="true"
                          @change="onChangedCheckboxValue"
                        >
                        <label for="chkOneQuestion">{{
                          $t("labels.answer")
                        }}</label>
                        &nbsp;&nbsp;
                      </label>
                      <label>
                        <input
                          id="chkExplanation"
                          v-model="explanationValue"
                          type="checkbox"
                          :disabled="true"
                          @change="onChangedCheckboxValue"
                        >
                        <label for="chkExplanation">{{
                          $t("labels.explanation")
                        }}</label>
                      </label>
                    </div>
                    <!-- Docがない場合、ラジオ非表示 -->
                    <div>
                      <label class="mx-4 py-1">
                        <input
                          v-model="checkDisplayMethod"
                          type="radio"
                          value="followLayout"
                          :disabled="true"
                        >
                        {{ $t("labels.followLayout") }}
                      </label>
                    </div>
                  </div>

                  <br>

                  <FormTextField
                    :label-text="$t('labels.sendMemo')"
                    :initial-value="sendMemo"
                    :max-length="50"
                    :disabled="true"
                    @input="inputSendMemo"
                    @on-blur="blurSendMemo"
                  />
                  <div
                    v-if="showMsgSendMemo"
                    class="text-danger"
                  >
                    {{ showMsgSendMemo }}
                  </div>
                  <br>
                  <div>
                    <p v-once>
                      {{ $t("labels.questionQuantity") }}
                    </p>
                    <div
                      class="question-area"
                      style="overflow-y: auto"
                    >
                      <div class="question-amount mx-3">
                        <div
                          v-for="(item, index) in questionQuantityList"
                          :key="index"
                        >
                          <div class="row py-1">
                            <div
                              v-if="stdbPreviewNum === 0"
                              class="col-2 text-center"
                            >
                              {{ "[" + (Number(index) + 1) + "]" }}
                            </div>
                            <div
                              v-else
                              class="col-2 text-center"
                            >
                              {{ "[" + stdbPreviewNum + "]" }}
                            </div>
                            <div
                              class="col-2"
                              @click="onClickQuestionCountMinus(index)"
                            >
                              <i class="icon-minus bi bi-dash-circle-fill" />
                            </div>
                            <div class="col-4 text-center">
                              {{
                                $t("labels.questionNumber", { number: item })
                              }}
                            </div>
                            <div
                              class="col-2"
                              @click="onClickQuestionCountPlus(index)"
                            >
                              <i class="icon-plus bi bi-plus-circle-fill" />
                            </div>
                            <div class="col-2" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-8 preview-image-container">
                  <div class="card w-100">
                    <div class="card-header">
                      {{ $t("labels.preview") }}
                    </div>
                    <div v-if="checkDisplayMethod == 'oneQuestion'">
                      <div class="window-body">
                        <div v-if="stdbPreviewNum === 0">
                          {{ "[" + (oneQuestionCount + 1) + "]" }}
                        </div>
                        <div v-else>
                          {{ "[" + stdbPreviewNum + "]" }}
                        </div>
                        <ul
                          class="prev-next"
                          style="list-style: none"
                        >
                          <li
                            v-show="
                              checkDisplayMethod == 'oneQuestion' &&
                                oneQuestionCount > 0
                            "
                            class="bi bi-chevron-left"
                            style="top: 45%"
                            @click="onClickArrowMinus"
                          />
                          <li
                            v-show="
                              checkDisplayMethod == 'oneQuestion' &&
                                oneQuestionCount < imageQList.length - 1
                            "
                            class="bi bi-chevron-right"
                            style="top: 45%"
                            @click="onClickArrowPlus"
                          />
                        </ul>
                        <div class="px-3 py-3 card-body preview-window">
                          <div class="card overflow-auto h-100">
                            <SettingPreviewImage
                              v-if="imageQList.length"
                              :image="imageQList[oneQuestionCount].file_image"
                              class="card-body"
                            />
                          </div>
                        </div>
                        <div class="card-body">
                          <SettingPreviewArea
                            ref="previewArea"
                            :list="settingList[oneQuestionCount]"
                            :stdb-flg="true"
                          />
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="window-body-layout">
                        <div class="h-auto text-end">
                          {{ layoutCount + 1 + " / " + imageDocList.length }}
                        </div>
                        <div class="preview-window">
                          <div class="preview-layout">
                            <div class="px-3 py-3 card-body">
                              <div class="card overflow-auto">
                                <img
                                  v-if="imageDocList.length"
                                  class="container-fluid"
                                  :src="imageDocList[layoutCount].file_image"
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <ul
                          class="prev-next py-1"
                          style="list-style: none"
                        >
                          <li
                            v-show="
                              checkDisplayMethod == 'followLayout' &&
                                layoutCount > 0
                            "
                            class="bi bi-chevron-left"
                            style="top: 45%"
                            @click="onClickArrowMinus"
                          />
                          <li
                            v-show="
                              checkDisplayMethod == 'followLayout' &&
                                layoutCount < imageDocList.length - 1
                            "
                            class="bi bi-chevron-right"
                            style="top: 45%"
                            @click="onClickArrowPlus"
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row gx-5 justify-content-center">
                <div class="col-auto">
                  <ButtonBorderCircleMedium
                    :label-name="$t('buttons.cancel')"
                    :label-color="colorLayoutTheme"
                    :border-color="colorLayoutTheme"
                    @click.native="$emit('close')"
                  />
                </div>
                <div class="col-auto">
                  <!-- 保存処理 -->
                  <ButtonBorderCircleMedium
                    :label-name="$t('buttons.save')"
                    color="layout-theme-light"
                    :label-color="colorLayoutTheme"
                    :border-color="colorLayoutTheme"
                    @click.native="onClickSave"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/**
 * 宿題管理 - STDBプレビューモーダル
 */
import { db } from "@/dbs/indexedDb"
import ButtonClose from "@/components/atoms/buttons/ButtonClose.vue"
import FormTextField from "@/components/atoms/FormTextField.vue"
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"
import SettingPreviewImage from "@/components/atoms/SettingPreviewImage.vue"
import SettingPreviewArea from "@/components/organisms/SettingPreviewArea.vue"
import { displayFlagCode, settingTabIdCode } from "@/constant/question.js"

export default {
  name: "ModalResetQuesNumStdbQuestion",
  components: {
    ButtonClose,
    FormTextField,
    ButtonBorderCircleMedium,
    SettingPreviewImage,
    SettingPreviewArea,
  },
  props: {
    taskName: {
      type: String,
      require: true,
      default: function () {
        return ""
      },
    },

    sendMemo: {
      type: String,
      require: true,
      default: function () {
        return ""
      },
    },

    imageList: {
      type: Array,
      default: function () {
        return []
      },
    },

    // ファイルパス
    filePath: {
      type: String,
      default: "",
    },
  },

  data: () => ({
    colorLayoutTheme: "#ff7f27",
    modalTaskName: "", // 課題名
    modalSendMemo: "", // 送信メモ
    showMsgTaskName: "", // 課題名エラーメッセージ
    showMsgSendMemo: "", // 送信メモエラーメッセージ
    answerValue: false, // 答えチェックボックスの値
    explanationValue: false, // 解説チェックボックスの値
    checkDisplayMethod: "oneQuestion", // 選択中のラジオボタンの文字列
    oneQuestionCount: 0, // 一問ずつ画像の何番目かのカウンター
    layoutCount: 0, // レイアウト通り画像の何番目かのカウンター

    imageQList: [], // 問題画像リスト
    imageEList: [], // 解説画像リスト
    imageAList: [], // 答え画像リスト
    imageDocList: [], // レイアウト通り画像リスト

    /**
     * 画像表示エリア下部分に表示するjsonデータ
     * 例：
     * [
     *  { "id": 1, "label": "答", "content": "data:image/png;base64,iVBORw0KGgo..." },
     *  { "id": 2, "label": "解説", "content": "data:image/png;base64,iVBORw0KGgo..." },
     * ]
     */
    settingList: [],
    isCheckBoxDisabled: false, // チェックボックスが無効状態かどうか
    questionQuantityList: [], // 問題数入力
    stdbPreviewNum: 0,
  }),

  beforeMount: async function () {
    // 画像の振り分け
    this.imageDocList = this.imageList.filter((item) => {
      // 前方一致"{数値}"
      return item.file_name.match(/^\d/)
    })
    this.imageQList = this.imageList.filter((item) => {
      // 前方一致"Q{数値}"
      return item.file_name.match(/^Q\d/)
    })
    this.imageEList = this.imageList.filter((item) => {
      // 前方一致"E{数値}"
      return item.file_name.match(/^E\d/)
    })
    this.imageAList = this.imageList.filter((item) => {
      // 前方一致"A{数値}"
      return item.file_name.match(/^A\d/)
    })

    // 入力項目の初期設定
    this.modalTaskName = this.taskName
    this.modalSendMemo = this.sendMemo
  },

  mounted: async function () {
    // 初期表示
    if (this.filePath != "") {
      const result = await this.getUploadHomeworkDetailItems(this.filePath)
      if (result !== undefined) {
        this.questionQuantityList =
          result.uploadHomeworkDetailItem.questionCount
      }
    }

    if (!this.imageQList.length) {
      // DOCファイルしかない場合
      this.checkDisplayMethod = "followLayout"
    }

    if (!this.questionQuantityList.length) {
      if (this.checkDisplayMethod === "oneQuestion") {
        for (var i = 0; i < this.imageQList.length; i++) {
          this.questionQuantityList[i] = 1
        }
      } else {
        //問題数は1つ
        this.questionQuantityList[0] = 1
      }
    }

    // 一問ずつの場合タブを表示
    if (this.checkDisplayMethod === "oneQuestion") {
      // 一問ずつの場合タブを表示
      this.answerValue = 0 < this.imageAList.length
      this.explanationValue = 0 < this.imageEList.length
      // タブの生成
      this.onChangedCheckboxValue()
      // タブ選択の設定
      this.$refs.previewArea.setCurrentId(settingTabIdCode.answerId)
    }
  },

  methods: {
    /**
     * 登録処理
     */
    onClickSave: function () {
      this.$emit("save", {
        questionQuantityList: this.questionQuantityList, // 問題数
        modalTaskName: this.modalTaskName, // 課題名
        modalSendMemo: this.modalSendMemo, // 送信メモ
        answerValue: this.answerValue, // 答えチェックボックスの値
        explanationValue: this.explanationValue, // 解説チェックボックスの値
      })
    },

    /**
     * アップロード一覧（IndexedDB）取得
     */
    async getUploadHomeworkDetailItems(filePath) {
      return await db.uploadHomeworkDetailItems.get(filePath)
    },

    /**
     * 表示画像のインデックスカウントアップ
     */
    onClickArrowPlus: function () {
      if (this.checkDisplayMethod == "oneQuestion") {
        this.oneQuestionCount =
          (this.oneQuestionCount + 1) % this.imageQList.length
      } else {
        this.layoutCount = (this.layoutCount + 1) % this.imageDocList.length
      }
    },

    /**
     * 表示画像のインデックスカウントダウン
     */
    onClickArrowMinus: function () {
      if (this.checkDisplayMethod == "oneQuestion") {
        this.oneQuestionCount = this.oneQuestionCount - 1
        if (this.oneQuestionCount < 0) {
          this.oneQuestionCount = this.oneQuestionCount + this.imageQList.length
        }
      } else {
        this.layoutCount = this.layoutCount - 1
        if (this.layoutCount < 0) {
          this.layoutCount = this.layoutCount + this.imageDocList.length
        }
      }
    },

    /**
     * 問題数の[+]押したとき
     */
    onClickQuestionCountPlus: function (number) {
      this.$set(
        this.questionQuantityList,
        number,
        this.questionQuantityList[number] + 1
      )
      // 最大50まで
      if (this.questionQuantityList[number] > 50) {
        this.questionQuantityList[number] = 50
      }
    },

    /**
     * 問題数の[-]押したとき
     */
    onClickQuestionCountMinus: function (number) {
      this.$set(
        this.questionQuantityList,
        number,
        this.questionQuantityList[number] + -1
      )
      // 最小1まで
      if (this.questionQuantityList[number] < 1) {
        this.questionQuantityList[number] = 1
      }
    },

    /**
     * 課題名入力値取得
     */
    inputTaskName: function (text) {
      this.modalTaskName = text
    },

    /**
     * 送信メモ入力値取得
     */
    inputSendMemo: function (text) {
      this.modalSendMemo = text
    },

    /**
     * 課題名フォーカスアウト処理
     */
    blurTaskName: function (text) {
      this.showMsgTaskName = ""
      // 課題名必須チェック
      if (text.length > 50) {
        this.showMsgTaskName = this.$t("messages.error.overTextLength", {
          maxLength: "50",
        })
      }
    },

    /**
     * 送信メモフォーカスアウト処理
     */
    blurSendMemo: function (text) {
      this.showMsgSendMemo = ""
      // 送信メモ必須チェック
      if (text.length > 50) {
        this.showMsgSendMemo = this.$t("messages.error.overTextLength", {
          maxLength: "50",
        })
      }
    },

    /**
     * チェックボックスの値が変化した時
     */
    onChangedCheckboxValue: function () {
      this.settingList.splice(0)

      for (var i = 0; i < this.imageQList.length; i++) {
        if (this.answerValue) {
          if (this.explanationValue) {
            this.settingList.push(
              ...[
                [
                  {
                    id: settingTabIdCode.answerId,
                    label: this.$t("labels.answer"),
                    content: this.imageAList.length
                      ? this.imageAList[i].file_image
                      : "",
                    displayFlag: displayFlagCode.display,
                    tagIndex: 1,
                  },
                  {
                    id: settingTabIdCode.explainId,
                    label: this.$t("labels.explanation"),
                    content: this.imageEList.length
                      ? this.imageEList[i].file_image
                      : "",
                    displayFlag: displayFlagCode.display,
                    tagIndex: 2,
                  },
                ],
              ]
            )
          } else {
            this.settingList.push(
              ...[
                [
                  {
                    id: settingTabIdCode.answerId,
                    label: this.$t("labels.answer"),
                    content: this.imageAList.length
                      ? this.imageAList[i].file_image
                      : "",
                    displayFlag: displayFlagCode.display,
                    tagIndex: 1,
                  },
                  {
                    id: settingTabIdCode.explainId,
                    label: this.$t("labels.explanation"),
                    content: this.imageEList.length
                      ? this.imageEList[i].file_image
                      : "",
                    displayFlag: displayFlagCode.grayout,
                    tagIndex: 2,
                  },
                ],
              ]
            )
            this.$refs.previewArea.setCurrentId(settingTabIdCode.answerId)
          }
        } else {
          if (this.explanationValue) {
            this.settingList.push(
              ...[
                [
                  {
                    id: settingTabIdCode.answerId,
                    label: this.$t("labels.answer"),
                    content: this.imageAList.length
                      ? this.imageAList[i].file_image
                      : "",
                    displayFlag: displayFlagCode.grayout,
                    tagIndex: 1,
                  },
                  {
                    id: settingTabIdCode.explainId,
                    label: this.$t("labels.explanation"),
                    content: this.imageEList.length
                      ? this.imageEList[i].file_image
                      : "",
                    displayFlag: displayFlagCode.display,
                    tagIndex: 2,
                  },
                ],
              ]
            )
            this.$refs.previewArea.setCurrentId(settingTabIdCode.answerId)
          }
        }
      }

      this.isCheckBoxDisabled = !this.isCheckBoxDisabled
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;

    .modal-container-outer {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;

      .modal-default-button {
        text-align: right;
      }

      .modal-container {
        overflow: auto;
        max-height: 80vh;
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
        padding: 20px 30px;
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
      }
    }
  }
}

.preview-image-container {
  height: max-content;

  .card-header {
    height: 7%;
    padding: 0.4rem;
    border-bottom: none;
    background: var(--bs-table-header);
    color: var(--bs-layout-theme);
    font-weight: bold;
  }
}

.window-body,
.window-body-layout {
  margin-bottom: 3%;
  padding: 2% 6%;
  max-height: 66vh;
  min-height: 500px;

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  li {
    position: absolute;
    top: 50%;
    font-size: 48px;
    width: 25px;
    height: 35px;
    cursor: pointer;
    left: -8px;
    color: #0070c0;
  }

  li:nth-of-type(2) {
    left: auto;
    right: 20px;
  }
}

span.number {
  display: inline-block;
  width: 35px;
}

.preview-window {
  height: 200px;
}

.window-body-layout {
  div {
    height: 420px;
  }
}

// 問題数の数字部分
.question-amount {
  @media (max-width: 1020px) {
    padding-left: 0;
  }

  li {
    div {
      min-width: 210px;
    }
  }
}

.question-area {
  height: 220px;

  .icon-minus:before,
  .icon-plus:before {
    color: #0070c0;

    font-size: 120%;
    vertical-align: bottom;
    cursor: pointer;
  }
}
</style>
