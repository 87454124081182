<template>
  <div class="button-down">
    <button
      type="button"
      class="btn"
      :disabled="disabled"
    >
      ▼
    </button>
  </div>
</template>

<script>
export default {
  name: "ButtonDown",
  props: {
    disabled: { type: Boolean, default: false },
  },
}
</script>

<style lang="scss" scoped>
.btn:disabled {
  opacity: 0.3 !important;
}
</style>


