var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "left-menu-layout", attrs: { id: "left-menu-layout" } },
    [
      _c("div", { staticClass: "container-fluid mainArea" }, [
        _c("div", { staticClass: "row h-100" }, [
          _c(
            "div",
            {
              ref: "leftArea",
              staticClass: "d-inline-block p-0 left-area",
              class: { modalStyle: _vm.isModalView }
            },
            [
              _c(
                "div",
                { staticClass: "sidbar-fixed" },
                [
                  _c("Sidebar", {
                    ref: "sidebarArea",
                    attrs: { size: _vm.sidebarAreaSize },
                    on: {
                      "change-modal-view": _vm.changeModalView,
                      "on-logout": _vm.onLogout
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              ref: "contentsArea",
              staticClass: "d-inline-block p-0 contentsArea"
            },
            [
              _c(
                "div",
                { staticClass: "contentsArea-flex" },
                [_c("router-view", { staticStyle: { height: "100%" } })],
                1
              )
            ]
          )
        ])
      ]),
      _vm.isLoading ? _c("LoadingScreen") : _vm._e(),
      _vm.displayLogoutConfirm
        ? _c(
            "ModalConfirm",
            _vm._g(
              { attrs: { message: _vm.$t("messages.confirm.logout") } },
              {
                "ok-confirm-modal": function() {
                  this$1.$router.push({ name: "Login" })
                },
                "close-confirm-modal": function() {
                  this$1.displayLogoutConfirm = false
                }
              }
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }