var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-parameters-homework-register" },
    [
      _c("div", { staticClass: "row mb-1" }, [
        _c(
          "div",
          { staticClass: "col-md-6 col-lg-5" },
          [
            _c("FormTextField", {
              attrs: {
                id: "homeworkName",
                "label-text": _vm.$t("labels.homeworkName"),
                value: _vm.homeworkName,
                "initial-value": _vm.initialHomeworkName,
                "max-length": 100,
                "is-require": true
              },
              on: {
                input: function($event) {
                  _vm.homeworkName = $event
                }
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row mb-1" }, [
        _c(
          "div",
          { staticClass: "col-auto" },
          [
            _c("FormSelectBox", {
              ref: "groupSelectBox",
              attrs: {
                id: "group",
                "label-text": _vm.$t("labels.group"),
                value: _vm.selectedGroup,
                options: _vm.generateGroupIdItems,
                "initial-value": _vm.initialStreamGroup,
                disabled: _vm.isPublished,
                "is-require": true
              },
              on: { input: _vm.onSelectGroup }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-auto" },
          [
            _c("FormSelectBox", {
              ref: "curriculumSelectBox",
              attrs: {
                id: "curriculum",
                "label-text": _vm.$t("labels.curriculum"),
                value: _vm.selectedCurriculum,
                options: _vm.generateCurriculumItems,
                "initial-value": _vm.initialCurriculum,
                disabled: _vm.isPublished,
                "is-require": true
              },
              on: { input: _vm.onSelectCurriculum }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-auto" },
          [
            _c("FormSelectBox", {
              ref: "subjectSelectBox",
              attrs: {
                id: "subject",
                "label-text": _vm.$t("labels.subject"),
                value: _vm.selectedSubject,
                options: _vm.generateSubjectItems,
                "initial-value": _vm.initialSubject,
                disabled: _vm.isPublished
              },
              on: {
                input: function($event) {
                  _vm.selectedSubject = $event
                }
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-auto" },
          [
            _c("FormDateField", {
              attrs: {
                "label-text": _vm.$t("labels.startDate"),
                value: _vm.startDate,
                "initial-value": _vm.initialStartDate,
                disabled: _vm.isPublished,
                "date-range": _vm.startDateRange,
                "is-require": true
              },
              on: { input: _vm.onSelectedStartDate }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-auto" },
          [
            _c("FormDateField", {
              attrs: {
                "label-text": _vm.$t("labels.deadlineDate"),
                value: _vm.deadlineDate,
                "initial-value": _vm.initialDeadlineDate,
                "date-range": _vm.deadlineDateRange,
                "is-require": true
              },
              on: { input: _vm.onSelectedDeadlineDate }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-auto col-category" },
          [
            _c("FormSelectBox", {
              ref: "categorySelectBox",
              attrs: {
                id: "category",
                "label-text": _vm.$t("labels.category"),
                value: _vm.selectedCategory,
                options: _vm.generateCategoryItems
              },
              on: { input: _vm.onSelectCategory }
            }),
            _c(
              "a",
              {
                staticClass: "category-edit-link",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.onClickCategoryEdit($event)
                  }
                }
              },
              [_vm._v(" 編集 ")]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row mb-1" }, [
        _c("div", { staticClass: "col-12" }, [
          _vm.showCanNotGetTextbook
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("messages.error.canNotGetTextbook")) + " "
                )
              ])
            : _vm._e(),
          _vm.showCanNotGetStudent
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("messages.error.canNotGetStudent")) + " "
                )
              ])
            : _vm._e()
        ])
      ]),
      _c("div", { staticClass: "row mb-1" }, [
        _c(
          "div",
          { staticClass: "col" },
          [
            _c(
              "SelectGroupMembers",
              _vm._g(
                {
                  ref: "groupMembers",
                  attrs: {
                    options: _vm.generateGroupMemberItems,
                    "initial-members": _vm.initialStreamGroupMembers,
                    "is-add-only": _vm.isPublished,
                    "is-require": true
                  },
                  on: { input: _vm.onSelectedGroupMembers }
                },
                {
                  "change-stream-my-self": _vm.onCheckedStreamMySelf
                }
              )
            )
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.selectedGroupMembers.length > 100,
                expression: "selectedGroupMembers.length > 100"
              }
            ],
            staticClass: "mt-2 mb-2 text-danger"
          },
          [_vm._v(_vm._s(_vm.$t("labels.recommendNumberOfDeliveryMessage")))]
        )
      ]),
      _vm.showConfirmDeleteBySelectGroup
        ? _c(
            "ModalConfirm",
            _vm._g(
              { attrs: { message: _vm.confirmMessage } },
              {
                "ok-confirm-modal": _vm.onOkShowConfirmDeleteBySelectGroup,
                "close-confirm-modal": _vm.onCloseConfirmModalGroup
              }
            )
          )
        : _vm._e(),
      _vm.showConfirmDeleteBySelectCurriculum
        ? _c(
            "ModalConfirm",
            _vm._g(
              { attrs: { message: _vm.confirmMessage } },
              {
                "ok-confirm-modal": _vm.onOkShowConfirmDeleteBySelectCurriculum,
                "close-confirm-modal": _vm.onCloseConfirmModalCurriculum
              }
            )
          )
        : _vm._e(),
      _vm.isInvalidDateRange
        ? _c("ModalConfirmOkOnly", {
            attrs: {
              message: _vm.$t("messages.error.validateDateRange", {
                maxDeadlineDate:
                  _vm.paramReleaseEndPeriodItems[0].items[0].value -
                  _vm.paramDeadlinePeriodItems[0].items[0].value
              })
            },
            on: {
              "close-confirm-modal-ok-only": function($event) {
                return _vm.onClickInvalidDateRange()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }