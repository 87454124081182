/**
 * 宿題管理用 store
 */
import { homeworkTypeCode } from "@/constant/homework"
export const homework = {
  namespaced: true,
  state: {
    headerInfo: {},
    // カテゴリー TODO: 宿題一覧画面で取得した値がセットされるようにする。
    categoryItems: [],
    homeworkDetailItems: [],
    // 進捗状況取得用パラメータ一時保存領域
    // ※進捗画面でリロードするとデータが飛んでしまう問題への対処用
    temporaryProgressParams: {},

    // 宿題追加のアップロードリスト（STDB）
    uploadStdbDetailItems: [],

    // 宿題追加のアップロードリスト（PDF）
    uploadPdfDetailItems: [],

    // 宿題ファイルリスト
    homeworkFileHistoryItems: [],

    // 宿題設定リスト
    homeworkSetItems: [],

    // 進捗確認済みの生徒の一覧
    // Key： 宿題キー
    // Vakue: 進捗確認済みの生徒のIDの配列
    checkedStudentList: {},

    // 転校済みの生徒の一覧
    // Key： グループID
    // Vakue: 転校済みの生徒のIDの配列
    transferStudentList: {},

    // 先生書き込み情報
    writeInfo: {
      // ツールバー情報
      toolbarInfo: {}
    },
  },

  getters: {
    headerInfo: (state) => {
      return state.headerInfo
    },
    categoryItems: (state) => {
      return state.categoryItems
    },
    homeworkDetailItems: (state) => {
      return state.homeworkDetailItems
    },
    temporaryProgressParams: (state) => {
      return state.temporaryProgressParams
    },
    uploadStdbDetailItems: (state) => {
      return state.uploadStdbDetailItems
    },
    uploadPdfDetailItems: (state) => {
      return state.uploadPdfDetailItems
    },
    homeworkFileHistoryItems: (state) => {
      return state.homeworkFileHistoryItems
    },
    homeworkSetItems: (state) => {
      return state.homeworkSetItems
    },
    checkedStudentList: (state) => {
      return state.checkedStudentList
    },
    transferStudentList: (state) => {
      return state.transferStudentList
    },
    writeInfo: (state) => {
      return state.writeInfo
    },
    toolbarInfoOfwriteInfo: (state) => {
      return state.writeInfo.toolbarInfo
    },
  },

  mutations: {
    setHeaderInfo(state, payload) {
      state.headerInfo = payload
    },
    setCategoryItems(state, payload) {
      state.categoryItems = payload
    },
    setHomeworkDetailItems(state, payload) {
      if (payload.questionType === 0) {
        // 表の削除ボタン押下時または検索時処理
        state.homeworkDetailItems = payload.homeworkDetailItems
      } else {
        // 専用子画面（教材、STDB、PDF）から追加された際の処理
        // 配信データが空ではない場合、そのままデータを投入する
        if (state.homeworkDetailItems.length > 0) {
          let homeworkDetailItems = []
          // 専用子画面から追加したデータが存在するかをチェックする
          if (payload.questionType === homeworkTypeCode.stdb) {
            homeworkDetailItems = state.homeworkDetailItems.filter((item) => {
              return (
                item.questionType !== homeworkTypeCode.stdb &&
                item.questionType !== homeworkTypeCode.stdbLayout
              )
            })
          } else {
            homeworkDetailItems = state.homeworkDetailItems.filter(
              (item) => item.questionType !== payload.questionType
            )
          }

          // 専用子画面から追加したデータが存在しない場合置き換える
          if (homeworkDetailItems.length === 0) {
            state.homeworkDetailItems = payload.homeworkDetailItems
          } else {
            // 他の専用子画面から追加したデータが存在する場合、末尾に追加する
            payload.homeworkDetailItems.forEach((item) => {
              homeworkDetailItems.push(item)
            })
            state.homeworkDetailItems = homeworkDetailItems
          }
        } else {
          // 配信データが空の場合、そのままデータを投入する
          state.homeworkDetailItems = payload.homeworkDetailItems
        }
      }
    },
    deleteHomeworkDetailItem(state, payload) {
      const fileNmSeq = state.homeworkDetailItems[payload].fileNmSeq
      state.homeworkDetailItems.splice(payload, 1)
      state.homeworkSetItems = state.homeworkSetItems.filter(
        (item) => item.fileNmSeq !== fileNmSeq
      )

      // 枝番の修正
      state.homeworkDetailItems.forEach((item, index) => {
        const num = index + 1
        item.homeworkEdaNo = num
        item.sortedDisplayNo = num
      })
    },
    setTemporaryProgressParams(state, payload) {
      state.temporaryProgressParams = payload
    },
    sortHomeworkDetailItems(state, payload) {
      const items = state.homeworkDetailItems
      const itemA = items[payload.indexA]
      const itemB = items[payload.indexB]

      // ソート番号の入れ替え
      const tmp = itemA.sortedDisplayNo
      itemA.sortedDisplayNo = itemB.sortedDisplayNo
      itemA.homeworkEdaNo = itemB.homeworkEdaNo
      itemB.sortedDisplayNo = tmp
      itemB.homeworkEdaNo = tmp

      // 物理的なソート
      items.splice(payload.indexA, 1, itemB)
      items.splice(payload.indexB, 1, itemA)

      state.homeworkdDetailItems = items
    },
    clearHeaderInfo(state) {
      state.headerInfo = {}
    },
    clearHomeworkDetailItems(state) {
      state.homeworkDetailItems = []
    },
    /**
     * 書籍IDを指定して宿題詳細を削除
     */
    deleteHomeworkDetailItemWithBookId(state, payload) {
      state.homeworkDetailItems = state.homeworkDetailItems.filter(
        (x) => x.subQuestionInfo.teachingMaterials !== payload
      )
    },
    clearUploadStdbDetailItems(state) {
      state.uploadStdbDetailItems.splice(0)
    },
    clearUploadPdfDetailItems(state) {
      state.uploadPdfDetailItems.splice(0)
    },
    setUploadStdbDetailItems(state, payload) {
      state.uploadStdbDetailItems = payload
    },
    setUploadPdfDetailItems(state, payload) {
      state.uploadPdfDetailItems = payload
    },

    clearHomeworkFileHistoryItems(state) {
      state.homeworkFileHistoryItems.splice(0)
    },
    setHomeworkFileHistoryItems(state, payload) {
      state.homeworkFileHistoryItems = payload
    },

    clearHomeworkSetItems(state) {
      state.homeworkSetItems.splice(0)
    },
    setHomeworkSetItems(state, payload) {
      state.homeworkSetItems = payload
    },

    // 進捗確認済みの生徒を追加
    addCheckedStudentProgress(state, payload) {
      if (!state.checkedStudentList[payload.homeworkKey]) {
        state.checkedStudentList[payload.homeworkKey] = []
      }
      if (
        !state.checkedStudentList[payload.homeworkKey].some(
          (x) => x === payload.studentId
        )
      ) {
        state.checkedStudentList[payload.homeworkKey].push(payload.studentId)
      }
    },
    // 進捗確認済みの生徒を未確認に戻す
    deleteCheckedStudentProgress(state, payload) {
      if (!state.checkedStudentList[payload.homeworkKey]) {
        return;
      }
      const idx = (state.checkedStudentList[payload.homeworkKey]).indexOf(payload.studentId)
      if (idx >= 0) {
        (state.checkedStudentList[payload.homeworkKey]).splice(idx, 1)
      }
    },
    // 指定した宿題キーの進捗確認済み生徒情報を消去
    deleteCheckedStudentList: (state, payload) => {
      state.checkedStudentList[payload] = undefined
    },
    // 転校済みの生徒を追加
    addTransferStudentList(state, payload) {
      if (!state.transferStudentList[payload.homeworkKey]) {
        state.transferStudentList[payload.homeworkKey] = []
      }
      if (
        !state.transferStudentList[payload.homeworkKey].some(
          (x) => x === payload.studentId
        )
      ) {
        state.transferStudentList[payload.homeworkKey].push(payload.studentId)
      }
    },
    // 指定したグループIDの転校済み生徒情報を消去
    deleteTransferStudentList: (state, payload) => {
      state.transferStudentList[payload] = undefined
    },
    /** 先生書き込み情報のツールバー選択状態を保存 */
    setToolbarInfoOfWriteInfo: (state, payload) => {
      state.writeInfo.toolbarInfo = payload;
    },
  },

  actions: {
    /**
     * 宿題詳細情報を store に保存
     */
    saveHomeworkDetailInfo(
      { commit },
      { headerInfo: headerInfo, homeworkDetail: homeworkDetail }
    ) {
      commit("setHeaderInfo", headerInfo)
      commit("setHomeworkDetailItems", {
        homeworkDetailItems: homeworkDetail,
        questionType: 0,
      })
    },

    saveUploadStdbDetailItems({ commit }, payload) {
      return new Promise((resolve) => {
        commit("setUploadStdbDetailItems", payload)
        resolve()
      })
    },

    async saveUploadPdfDetailItems({ commit }, payload) {
      await commit("setUploadPdfDetailItems", payload)
    },
    /**
     * 宿題をソート
     */
    sortHomeworkDetailItems({ commit }, { indexA, indexB }) {
      commit("sortHomeworkDetailItems", { indexA: indexA, indexB: indexB })
    },
    /**
     * 宿題を一件削除
     */
    deleteHomeworkDetailItem({ commit }, index) {
      commit("deleteHomeworkDetailItem", index)
    },
    /**
     * 進捗確認済みの生徒を追加
     */
    addCheckedStudentProgress({ commit }, { homeworkKey, studentId }) {
      commit("addCheckedStudentProgress", {
        homeworkKey: homeworkKey,
        studentId: studentId,
      })
    },
    /**
     * 進捗確認済みの生徒を追加
     */
     addTransferStudentList({ commit }, { homeworkKey, studentId }) {
      commit("addTransferStudentList", {
        homeworkKey: homeworkKey,
        studentId: studentId,
      })
    },
    /** 先生書き込み情報のツールバー選択状態を保存 */
    setToolbarInfoOfWriteInfo({ commit }, toolbarInfo) {
      commit("setToolbarInfoOfWriteInfo",toolbarInfo )
    },
  },
}
