<template>
  <div class="check-box">
    <!-- TODO: 返却するイベントも「change」に合わした方がいい -->
    <label :for="id">
      <input
        :id="id"
        v-model="inputValue"
        type="checkbox"
        :disabled="disabled"
        @change="$emit('input', $event.target.checked)"
      >
      {{ label }}
    </label>
  </div>
</template>

<script>

export default {
  name: "CheckBox",
  model: {
    prop: "value",
    event: "update",
  },
  props: {
    label: {
      type: String,
      default: function () {
        return ""
      },
    },
    value: {
      type: Boolean,
      required: false,
      default: function() {
        return undefined
      },
    },
    initialChecked: { 
      type: Boolean,
      default: function() {
        return undefined
      },
    },
    disabled: { type: Boolean, default: false },
    id: {
      type: String,
      required: false,
      default: function() {
        return undefined
      },
    }
  },

  data: function () {
    return {
      checked: this.initialChecked,
    }
  },

  computed: {
    /** 当コンポーネントを利用する側がv-modelを使用しているかどうかの判定 */
    isUsedVModel: {
      get() {
        // propsの値が初期値(undefined)のままならv-model不使用と判断
        return this.value !== undefined;
      }
    },
    inputValue: {
      get() {
        if (this.isUsedVModel) {
          // v-model使用(props.valueを使用する)
          return this.value;
        } else {
          // v-model不使用(data.checkedを使用する)
          return this.checked;
        }
      },
      set(value) {
        if (this.isUsedVModel) {
          // v-model使用
          if (this.value !== value) {
            this.$emit('update', value);
          }
        } else {
          // v-model不使用(data.checkedを使用する)
          this.checked = value;
        }
      }
    },
  },

  mounted: function () {
    if (this.initialChecked != undefined) {
      this.inputValue = this.initialChecked;
    }
    if (!this.isUsedVModel) {
        // v-model不使用(初期状態を返す)
        this.$emit('input', this.inputValue);
    }
  },
  methods: {
    /**
     * チェック状態を更新
     */
    setChecked: function (checked) {
      if (this.inputValue !== checked) {
        this.inputValue = checked
      }
    }
  },
}
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
}
</style>
