<template>
  <div class="button-submit">
    <div class="d-grid gap-2">
      <button
        type="submit"
        :class="['btn']"
        :style="buttonStyle"
        :disabled="isDisabled"
      >
        {{ labelName }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonSubmit",
  props: {
    labelName: {
      type: String,
      default: function () {
        return ""
      },
    },
    color: {
      type: String,
      default: function () {
        return ""
      },
    },
    labelColor: {
      type: String,
      default: function () {
        return ""
      },
    },
    borderColor: {
      type: String,
      default: function () {
        return ""
      },
    },
    isDisabled: Boolean,
  },
  data: () => ({
    btnClass: "btn",
  }),
  computed: {
    buttonStyle: function () {
      return {
        color: this.labelColor,
        "background-color": this.color,
        border: `solid 1px ${this.borderColor}`,
        padding: '8px 0',
      }
    },
  },
}
</script>
