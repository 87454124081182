<template>
  <div class="setting-tab">
    <li
      :class="[active, 'preview-tab']"
      class="w-100"
      @click="onChangeTab"
    >
      {{ label }}
    </li>
  </div>
</template>

<script>
export default {
  name: "SettingTab",
  props: {
    id: {
      type: Number,
      default: function () {
        return 0
      },
    },
    label: {
      type: String,
      default: function () {
        return ""
      },
    },
    value: {
      type: Number,
      default: function () {
        return 0
      },
    },
    content: {
      type: String,
      default: function () {
        return ""
      },
    },
  },
  computed: {
    active() {
      return this.value === this.id ? "active" : false
    },
  },
  methods: {
    onChangeTab() {
      this.$emit("input", this.id)
    },
  },
}
</script>

<style lang="scss" scoped>
li {
  list-style: none;
}
.setting-tab {
  width: 25%;
}
.preview-tab {
  cursor: pointer;
  font-size: 12px;
  height: 100%;
  text-align: center;
  padding: 0.6em 1em;
  line-height: 1.2;
  background: #f1f1f0;
  border-top: solid 2px #e5e5e5;
  border-radius: 3px 3px 0 0;
  cursor: pointer;
}
.active {
  cursor: default;
  border-top: solid 3px #ff7f27;
  border-left: solid 1px #b3b3b3;
  border-right: solid 1px #b3b3b3;
  background: #fff;
}
</style>