<template>
  <div
    class="modal-bulk-return"
  >
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container-outer">
          <div
            class="modal-container px-3 py-4"
            style="width: 800px;"
          >
            <ButtonClose
              class="modal-default-button"
              @click.native="showCheckCloseModal"
            />
            <div class="container-fluid">
              <div class="col text-center m-auto">
                <h6
                  class="m-0"
                >
                  {{ $t("titles.bulkReturn") }}
                </h6>
              </div>
            </div>
            <div class="w-100 modal-contents-area">
              <div
                class="d-flex justify-content-around modal-contents-area-inner"
              >
                <div style="width: 100%;">
                  <div class="chat-area">
                    <div class="chat-cotents-wrapper">
                      <div
                        class="d-flex position-relative"
                      >
                        <!-- コメント -->
                        <p class="comment-area">
                          <textarea
                            v-model="comment"
                            :placeholder="$t(disableComment ? 'placeholder.disableComment' : 'placeholder.returnComment')"
                            aria-label="chat"
                            class="form-control h-100"
                            maxlength="150"
                            :readonly="disableComment"
                          />
                        </p>
                        <!-- スタンプ -->
                        <StampArea
                          :stamps="stamps"
                          :selected-stamp="stamp"
                          :init-stamp-code="initStampCode"
                          :bulk-view=true
                          v-on="{
                            'on-change-stamp': onChangeStamp,
                          }"
                        />
                        <!-- 評価 -->
                        <EvalArea
                          :init-eval-code="initEvalCode"
                          :init-eval-manual-set-flg="initEvalManualSetFlg"
                          :bulk-view=true
                          v-on="{
                            'on-change-eval': onChangeEval,
                          }"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="d-flex justify-content-end"
              >
                <div
                  v-if="true"
                  class="text-end return-annotation-text"
                >
                  {{ $t("labels.recommendEvalMessage") }}
                </div>
              </div>
              <div
                class="row gx-2 justify-content-center mt-4"
              >
                <div class="modal-bulk-return-buttons">
                  <ButtonBorderCircleMedium
                    :label-name="$t('buttons.cancel')"
                    label-color="#ff7f27"
                    border-color="#ff7f27"
                    width="180px"
                    padding="0"
                    @click.native="onClickCancel"
                    class="ps-3 pe-3"
                  />
                  <div
                    v-if="isStudentNotHanded()"
                    class="return-buttons"
                  >
                    <ButtonBorderCircleMedium
                      :label-name="$t('buttons.sendSave')"
                      color="send-back"
                      label-color="#ff7f27"
                      border-color="#ff7f27"
                      width="180px"
                      padding="0"
                      @click.native="onClickSendSave"
                      class="ps-3 pe-3"
                    />
                  </div>
                  <div
                    v-else
                    class="return-buttons"
                  >
                    <ButtonBorderCircleMedium
                      :label-name="$t('buttons.sendBack')"
                      color="send-back"
                      label-color="#ff7f27"
                      border-color="#ff7f27"
                      width="180px"
                      padding="0"
                      @click.native="onClickSendBack"
                      class="ps-3 pe-3"
                    />
                    <ButtonBorderCircleMedium
                      :label-name="$t('buttons.returnOf')"
                      color="layout-theme-light"
                      label-color="#ff7f27"
                      border-color="#ff7f27"
                      width="180px"
                      padding="0"
                      @click.native="onClickReturnOf"
                      class="ps-3 pe-3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 返却メッセージ用 -->
    <ModalConfirmOkOnly
      v-if="showReturnMessage"
      :message="returnMessage"
      @close-confirm-modal-ok-only="onClickForReturnMessage"
    />
    <!-- やり直しメッセージ用 -->
    <ModalConfirmOkOnly
      v-if="showSendBackMessage"
      :message="sendBackMessage"
      @close-confirm-modal-ok-only="onClickForSendBackMessage"
    />
    <!-- 登録メッセージ用 -->
    <ModalConfirmOkOnly
      v-if="showSendSaveMessage"
      :message="sendSaveMessage"
      @close-confirm-modal-ok-only="onClickForSendSaveMessage"
    />
    <!-- 保存前遷移時用 -->
    <ModalConfirm
      v-if="showCautionCloseMessage"
      :message="$t('messages.confirm.bulkNotSavedChanges')"
      v-on="{
        'ok-confirm-modal': onClickOkForCautionCloseMessage,
        'close-confirm-modal': onClickCloseForCautionCloseMessage,
      }"
    />
    <!-- 再提出解除確認用 -->
    <ModalConfirm
      v-if="showCancelSendBackMessage"
      :message="$t('messages.confirm.bulkCancelSendBack')"
      v-on="{
        'ok-confirm-modal': onClickOkForCancelSendBackMessage,
        'close-confirm-modal': (onClickCloseForCancelSendBackMessage),
      }"
    />
  </div>
</template>

<script>
import { mapMutations } from "vuex"
import ButtonClose from "@/components/atoms/buttons/ButtonClose.vue"
import StampArea from "@/components/organisms/StampArea.vue"
import EvalArea from "@/components/organisms/EvalArea.vue"
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"
import ModalConfirm from "@/components/organisms/modal/ModalConfirm.vue"
import ModalConfirmOkOnly from "@/components/organisms/modal/ModalConfirmOkOnly.vue"
import { handedStatus } from "@/constant/homework"

export default {
  name: "ModalBulkReturn",
  components: {
    ButtonClose,
    StampArea,
    EvalArea,
    ButtonBorderCircleMedium,
    ModalConfirm,
    ModalConfirmOkOnly,
},
  props: {
    studentData: {
      type: Array,
      default: function () {
        return []
      },
    },
    stamps: {
      type: Array,
      default: function () {
        return []
      },
    },
    schoolInfo: {
      type: Object,
      default: function () {
        return {
          "COMMENT": undefined,
        };
      },
    },
  },

  data: function () {
    return {
      studentIndexList: [],
      originalStudentIndexList: [],
      comment: "",
      evalCode: 0,
      evalManualSetFlg: 0,
      stampCode: this.stamps.length,
      showReturnMessage: false,
      showCancelSendBackMessage: false,
      returnMessage: "",
      showSendBackMessage: false,
      showSendSaveMessage: false,
      sendBackMessage: "",
      sendSaveMessage: "",
      // 評価コード初期値
      defaultEvalCode: 0,
      defaultEvalManualSetFlg: 0,
      // スタンプコード初期値
      defaultStampCode: this.stamps.length,
      showCautionCloseMessage: false,
      submiting: false,
      displayChatView: false,
      handedStatus: handedStatus,
    }
  },

  computed: {
    stamp() {
      if (!this.stamps[this.stampCode - 1]) {
        return ""
      }
      return this.stamps[this.stampCode - 1].stamp
    },
    initEvalCode() {
      return 0
    },
    initEvalManualSetFlg() {
      return 0
    },
    initStampCode() {
      return this.stamps[this.defaultStampCode - 1].code
    },
    initMessage () {
      return ""
    },
    /** コメント無効化？ */
    disableComment() {
      return this.schoolInfo["COMMENT"] == '0';
    },
  },

  async mounted() {
    this.comment = this.initMessage
    this.evalCode = this.initEvalCode
    this.evalManualSetFlg = this.initEvalManualSetFlg
    this.stampCode = this.initStampCode
    this.studentData.forEach((item, index) => {
      if (item.bulkCheck) {
        // 一括返却
        this.studentIndexList.push(index)
        this.originalStudentIndexList.push(item.originIndex)
      }
    })
  },

  methods: {
    ...mapMutations("homework", [
      "deleteCheckedStudentProgress",
    ]),
    // 評価を親画面に返す
    onChangeEval(evalCode, evalManualSetFlg) {
      this.evalCode = evalCode
      this.evalManualSetFlg = evalManualSetFlg
    },
    // スタンプを親画面に返す
    onChangeStamp(stampCode) {
      this.stampCode = stampCode
    },
    // 返却完了メッセージOK押下時
    onClickForReturnMessage() {
      this.submiting = false
      this.showReturnMessage = false
      this.noteItemsByDate = {}
      this.$emit("on-reload")
      this.$emit("close")
    },
    // 返却ボタン押下時
    onClickReturnOf(ev, confirmed) {
      if (!confirmed && (this.checkCancelSendBack())) {
        // 再提出指示中の場合は確認ダイアログ表示
        this.showCancelSendBackMessage = true
        return
      }
      const returnedStatus = [
        handedStatus.returned,
        handedStatus.returnChecked,
      ]
      const statusNotChange = returnedStatus.includes(this.studentData.find(item => item.bulkCheck).status)
      this.submiting = true
            this.$emit(
        "on-click-return-of",
        [],
        this.originalStudentIndexList,
        this.evalCode || this.defaultEvalCode, // 評価コードが設定されていない場合はデフォルト値を渡す
        this.evalManualSetFlg || this.defaultEvalManualSetFlg,
        this.stampCode || this.defaultStampCode, // スタンプコードが設定されていない場合はデフォルト値を渡す
        this.comment,
        [],
        (result) => {
          if (result) {
            // 返却処理成功時
            if (statusNotChange) {
              // ステータス変更なし
              this.returnMessage = this.$t("messages.success.bulkCompleteRegistration")
            } else {
              this.returnMessage = this.$t("messages.success.bulkReturn")
            }
            this.showReturnMessage = true
          } else {
            // 返却処理失敗時
            this.returnMessage = this.$t("messages.error.bulkExlusiveUpdateHomework")
            this.showReturnMessage = true
          }
        },
        true,
      )
      // this.$emit("close")
    },
    // やり直し完了メッセージOK押下時
    onClickForSendBackMessage() {
      this.showSendBackMessage = false
      this.noteItemsByDate = {}
      this.$emit("on-reload")
      this.$emit("close")
    },
    // やり直しボタン押下時
    onClickSendBack() {
      const sendbackStatus = [
        handedStatus.sendBack,
        handedStatus.sendBackCheck,
      ]
      const statusNotChange = sendbackStatus.includes(this.studentData.find(item => item.bulkCheck).status)
      this.$emit(
        "on-click-send-back",
        [],
        this.originalStudentIndexList,
        this.evalCode || this.defaultEvalCode, // 評価コードが設定されていない場合はデフォルト値を渡す
        this.evalManualSetFlg || this.defaultEvalManualSetFlg,
        this.stampCode || this.defaultStampCode, // スタンプコードが設定されていない場合はデフォルト値を渡す
        this.comment,
        [],
        (result) => {
          if (result) {
            // やり直し処理成功時
            if (statusNotChange) {
              // ステータス変更なし
              this.sendBackMessage = this.$t("messages.success.bulkCompleteRegistration")
            } else {
              this.sendBackMessage = this.$t("messages.success.bulkSendBack")
            }
            // やり直したら進捗確認済みから外す（再提出時にnewになるように）
            this.studentIndexList.forEach((item) => {
              this.deleteCheckedStudentProgress({
                homeworkKey: this.studentData[item].homeworkKey,
                studentId: this.studentData[item].accountId,
              })
            })
            this.showSendBackMessage = true
          } else {
            // やり直し処理失敗時
            this.sendBackMessage = this.$t("messages.error.bulkExlusiveUpdateHomework")
            this.showSendBackMessage = true
          }
        },
        true,
      )
      // this.$emit("close")
    },
    // 登録完了メッセージOK押下時
    onClickForSendSaveMessage() {
      this.showSendSaveMessage = false
      this.noteItemsByDate = {}
      this.$emit("on-reload")
      this.$emit("close")
    },
    // 登録ボタン押下時
    onClickSendSave() {
            this.$emit(
        "on-click-send-save",
        [],
        this.originalStudentIndexList,
        this.evalCode || this.defaultEvalCode, // 評価コードが設定されていない場合はデフォルト値を渡す
        this.evalManualSetFlg || this.defaultEvalManualSetFlg,
        this.stampCode || this.defaultStampCode, // スタンプコードが設定されていない場合はデフォルト値を渡す
        this.comment,
        [],
        (result) => {
          if (result) {
            // 登録処理成功時
            this.sendSaveMessage = this.$t("messages.success.bulkCompleteRegistration")
            this.showSendSaveMessage = true
          } else {
            // 登録処理失敗時
            this.sendSaveMessage = this.$t("messages.error.bulkExlusiveUpdateHomeworkSendSave")
            this.showSendSaveMessage = true
          }
        },
        true,
      )
    },
    onClickOkForCancelSendBackMessage(ev) {
      this.onClickReturnOf(ev, true)
    },
    onClickCloseForCancelSendBackMessage() {
      this.showCancelSendBackMessage = false
    },
    onClickCancel() {
      this.$emit("close")
    },
    showCheckCloseModal() {
      if (this.isChangeData()) {
        this.showCautionCloseMessage = true
      } else {
        this.$emit("close")
      }
    },
    isChangeComment() {
      return this.initMessage != this.comment
    },
    isChangeData() {
      let isChanged = false;
      if(!isChanged){
        // コメント
        isChanged = this.isChangeComment();
      }
      if(!isChanged){
        //  評価チェック
        isChanged = this.evalCode != this.initEvalCode;
      }
      if(!isChanged){
        // 評価手動設定フラグチェック
        isChanged = this.evalManualSetFlg != this.initEvalManualSetFlg;
      }
      if(!isChanged){
        // スタンプチェック
        isChanged = this.stampCode != this.initStampCode;
      }
      return isChanged;
    },
    onClickOkForCautionCloseMessage() {
      this.showCautionCloseMessage = false
      this.$emit("close")
    },
    onClickCloseForCautionCloseMessage() {
      this.showCautionCloseMessage = false
    },
    isStudentNotHanded(){
      let isNotHanded = false
      this.studentData.forEach((item) => {
        if (item.bulkCheck) {
          // 未提出者
          if(item.status === handedStatus.notProgress ||
              item.status === handedStatus.progressing ||
              item.status === handedStatus.waitHand){
            isNotHanded = true
          }
        }
      })
      return isNotHanded;
    },
    checkCancelSendBack() {
      let isCancelSendBack = false
      this.studentIndexList.forEach((item) => {
        if (this.studentData[item].status == handedStatus.sendBack || this.studentData[item].status == handedStatus.sendBackCheck) {
          isCancelSendBack = true
        }
      })
      return isCancelSendBack;
    }
  },
}

</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 98;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;

    .modal-container-outer {
      width: 100%;
      margin-left: auto;
      margin-right: auto;


      .modal-container {
        position: relative;
        max-width: 85%;
        margin-left: auto;
        margin-right: auto;
        padding: 20px 30px;
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        text-align: center;
        .modal-default-button {
          position: absolute;
          right: calc(-3.0em - 20px);
          top: calc(-0.5em - 30px);
        }

        .modal-right-content {
          width: 60%;
          height: 100%;
        }
        .modal-contents-area {
          @media (min-width: 1280px) {
            max-width: 90%;
            margin: 0 auto;
          }
          height: 90%;
        }
        .modal-contents-area::-webkit-scrollbar {
          display: none;
        }
      }

      .container-fluid {
        @media (min-width: 1280px) {
          max-width: 90%;
        }
        position: relative;
        margin-bottom: 2rem;
        ::v-deep .btn {
          padding: 0.3em 1em 0.3em;
          font-size: 14px;
        }

      }

      //ヘッダー以外のボタンスタイル
      ::v-deep .btn {
        padding: 0.5em 3em 0.5em;
        font-size: 14px;
      }
    }
  }
}

p {
  margin: 0;
}
.chat-cotents-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-color: #fff;
}

.comment-area {
  flex-grow: 1;
}

// コメント入力エリア
.form-control {
  resize: none;
  border-radius: 1px;
  width: 100%;
}
.bg-eval-manual {
  background: #ffffcc;
}

.modal-bulk-return {
  display: flex;
  justify-content: center;

  .modal-bulk-return-buttons {
    display: flex;
    justify-content: center;
    .button-border-circle-medium {
      // margin: 0 16px;
      
    }
    .button-border-circle-medium:first-child {
      // margin-right: 60px;
    }
    .return-buttons {
      display: flex;
      justify-content: center;
    }
  }
  .return-buttons {
    display: flex;
    justify-content: center;
  }
  .return-annotation-text {
    font-size: 90%;
  }
}
</style>
