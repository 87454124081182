<template>
  <div class="link-text-router">
    <div
      v-if="!openUrl && name != 'Terms'"
      class="row"
    >
      <router-link
        class="nav-link col-12"
        style="padding: 0.3rem 1rem;"
        :to="{ name: name }"
      >
        <div
          class="row"
        >
          <div
            :is="iconName"
            class="col-3"
            style="padding-left: 1.1rem;"
            width="32"
            height="32"
            :fill-color="iconColor"
          />
          <div 
            class="col-9 py-1"
            :class="textClassObject"
          >
            {{ labelText }}
          </div>
        </div>
      </router-link>
    </div>
    <div v-else>
      <a
        v-if="name != 'Terms'"
        :href="openUrl"
        class="nav-link"
        target="_blank"
      >
        <div class="text-underline" :class="textClassObject">{{ labelText }}</div>
      </a>
      <div
        v-else
        class="nav-link"
      >
        <div class="text-underline" :class="textClassObject">{{ labelText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import IconHomeworkMono from "@/components/atoms/icons/IconHomeworkMono.vue"
import IconQuestion from "@/components/atoms/icons/IconQuestion.vue"

export default {
  name: "LinkTextRouter",
  components: {
    IconHomeworkMono,
    IconQuestion,
    
  },
  props: {
    name: {
      type: String,
      default: function () {
        return ""
      },
    },
    iconName: {
      type: String,
      default: function () {
        return ""
      },
    },
    iconColor: {
      type: String,
      default: function () {
        return ""
      },
    },
    labelText: {
      type: String,
      default: function () {
        return ""
      },
    },
    fontColor: {
      type: String,
      default: function () {
        return ""
      },
    },
    openUrl: {
      type: String,
      default: function () {
        return ""
      },
    },
  },

  computed: {
    textClassObject: function () {
      return {
        "text-white": "text-white" === this.fontColor,
        "text-dark": "text-dark" === this.fontColor,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.text-underline {
  text-decoration: underline;
  cursor: pointer;
}
</style>
