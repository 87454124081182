<template>
  <div class="icon-return">
    <svg
      id="_x32_"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      :width="width"
      :height="height"
      viewBox="0 0 512 512"
      style="opacity: 1;"
      xml:space="preserve"
    >
      <g>
        <path
          class="st0"
          d="M452.422,155.809c-36.6-36.713-87.875-59.612-143.837-59.578h-73.337V84.847V8.939L46.711,162.247
          L235.248,315.57v-78.228v-8.269h73.337c19.694,0.033,36.924,7.799,49.906,20.658c12.868,12.99,20.634,30.212,20.667,49.914
          c-0.033,19.702-7.8,36.924-20.667,49.897c-12.982,12.876-30.212,20.642-49.906,20.676H0v132.842h308.585
          c55.962,0.033,107.237-22.866,143.837-59.578c36.698-36.6,59.612-87.875,59.578-143.837
          C512.033,243.684,489.12,192.408,452.422,155.809z"
          style="fill: rgb(250, 113, 11);"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconReturn',
  props: {
    width: {
      type: String,
      default: function () {
        return '25px'
      },
    },
    height: {
      type: String,
      default: function () {
        return '25px'
      },
    },
  },

  data: () => ({
    // アイコンにタイトルを設定する場合は、iconName を表示するようにする
    // ファイル名：宿題進捗_戻る(icooon-mono).svg
    iconName: '戻る'
  }),
}
</script>

<style lang="scss" scoped>

</style>