import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import "./validation/vee-validate"
import "./validation/message"
import "./validation/custom-rules"
import i18n from "./i18n"

import "./assets/styles/scss/bootstrap-custom.scss"
import "./assets/styles/scss/font.scss"
import "bootstrap-icons/font/bootstrap-icons.css"

Vue.config.productionTip = false

Vue.component("ValidationProvider", ValidationProvider)
Vue.component("ValidationObserver", ValidationObserver)

const vm = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app")

export default vm
