var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "icon-homework-mono" }, [
    _c(
      "svg",
      {
        staticStyle: { "enable-background": "new 0 0 70.6 70.6" },
        attrs: {
          id: "レイヤー_1",
          version: "1.1",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          x: "0px",
          y: "0px",
          width: _vm.width,
          height: _vm.height,
          viewBox: "0 0 70.6 70.6",
          "xml:space": "preserve"
        }
      },
      [
        _c("g", [
          _c("path", {
            staticClass: "st1",
            attrs: {
              fill: _vm.fillColor,
              d:
                "M66.1,46.2c-1-3.4-1.9-6.8-2.9-10.1c-0.3-1.1-0.6-2.2-1-3.3l-2.6,2.6c0.9,3.4,1.7,6.7,2.6,10.1\n      c0.4,1.7-0.7,3.1-2.5,2.6c-3-1.2-6.3-2.1-9.5-2.3c-3.7-0.2-7.4,0.6-10.8,2c-0.4,0.2-2.9,2-3,0.7c-0.1-3-0.1-6.1-0.2-9.1\n      C36,33,35.9,26.7,35.8,20.5c0-0.4,0.9-0.5,1-0.6c1.4-0.5,2.9-0.9,4.4-1.2c3.7-0.6,7.3-0.3,10.8,0.6c1.1,0.3,2.3,0.6,3.2,1.4L57,19\n      c-0.6-0.4-1.2-0.8-1.8-1.1c-3-1.4-6.9-1.9-10.2-2c-3.5-0.1-7.1,0.5-10.4,1.6c-0.6,0.2-1.7-0.4-2.4-0.5c-0.8-0.2-1.6-0.4-2.4-0.5\n      c-1.8-0.3-3.5-0.5-5.3-0.5c-3.4-0.1-6.9,0.6-10.1,1.6c-0.7,0.3-0.2,0.1,0,0c-2,0.8-4.3,2-4.9,4.2c-0.2,0.6-0.3,1.1-0.5,1.7\n      c-0.8,2.8-1.6,5.6-2.4,8.4c-1.1,3.8-2.2,7.7-3.3,11.5c-0.6,2.2-1.9,5.1-1.4,7.5c0.6,2.9,3.4,4.3,6.2,3.7c1-0.2,1.9-0.6,2.8-1\n      c1.5-0.6,3.1-1,4.7-1.4c3.9-0.8,7.8-0.4,11.6,0.6c0.9,0.2,4,0.8,4.1,1.7c0.1,0.9,1.1,0.6,1.7,0.6c0.6,0,1.3,0,1.9,0\n      c0.6,0,1.6,0.2,2.1,0c0.6-0.2,0.1-0.9,0.7-1.1c0.7-0.3,1.5-0.6,2.2-0.8c1.7-0.5,3.5-0.9,5.3-1.2c3.8-0.6,7.5-0.2,11.2,1\n      c2.2,0.7,4.3,1.9,6.6,1.5c3.2-0.6,4.5-3.8,3.7-6.8C66.4,47.3,66.3,46.8,66.1,46.2z M32.5,27.9c-0.1,6.8-0.3,13.5-0.4,20.3\n      c0,0.5-0.1,0.7-0.6,0.7c-2.7-1.6-6-2.6-9.1-3c-3.7-0.5-7.3,0-10.8,1.1c-1.1,0.3-2.3,1.1-3.5,1.1c-1.3,0-2.1-1.2-1.8-2.4\n      c0.8-3.2,1.6-6.4,2.4-9.6c1-3.8,1.9-7.5,2.9-11.3c0.2-0.9,0.5-1.9,0.7-2.8c0.6-2.3,3.8-2.7,5.8-3.1c3.6-0.7,7.3-0.7,10.9,0.1\n      c0.6,0.1,3.8,0.6,3.7,1.5C32.6,23,32.6,25.5,32.5,27.9z"
            }
          }),
          _c("path", {
            staticClass: "st1",
            attrs: {
              fill: _vm.fillColor,
              d:
                "M41.7,43.7l9.5-2L70,22.9l-7.5-7.5L43.8,34.2L41.7,43.7z M66.4,22.9L50.3,39l-1.7-1.7l16.1-16.1L66.4,22.9z\n      M62.5,19.1l1.7,1.7L48.1,36.8l-1.7-1.7L62.5,19.1z M46.1,35.9l3.5,3.5l-3.3,0.7l-1-1L46.1,35.9z"
            }
          })
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }