<template>
  <div class="modal-preview-pdf-question">
    <transition
      name="modal"
      appear
    >
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container-outer">
            <ButtonClose
              class="modal-default-button"
              @click.native="$emit('close')"
            />
            <div class="modal-container mx-3 py-4">
              <div class="row mb-5 question-setting mt-3">
                <div class="col-4 align-self-stretch tree-wrap modal-contents">
                  <FormTextField
                    class="py-1"
                    :label-text="$t('labels.taskName')"
                    :initial-value="taskName"
                    :disabled="isDisabled"
                    :max-length="50"
                    @input="inputTaskName"
                    @on-blur="blurTaskName"
                  />
                  <div
                    v-if="showMsgTaskName"
                    class="text-danger"
                  >
                    {{ showMsgTaskName }}
                  </div>
                  <FormTextField
                    class="py-1"
                    :label-text="$t('labels.sendMemo')"
                    :initial-value="sendMemo"
                    :disabled="isDisabled"
                    :max-length="50"
                    @input="inputSendMemo"
                    @on-blur="blurSendMemo"
                  />
                  <div
                    v-if="showMsgSendMemo"
                    class="text-danger"
                  >
                    {{ showMsgSendMemo }}
                  </div>
                  <br>
                  <div>
                    <p v-once>
                      {{ $t("labels.questionQuantity") }}
                    </p>
                    <div class="question-area">
                      <div class="question-amount mx-3">
                        <div class="row py-1">
                          <div
                            class="col-2"
                            :style="{
                              visibility: `${
                                isDisabled ? 'hidden' : 'visible'
                              }`,
                            }"
                            @click="
                              questionCount = Math.max(questionCount - 1, 1)
                            "
                          >
                            <i class="icon-minus bi bi-dash-circle-fill" />
                          </div>
                          <div class="col-4 text-center">
                            {{
                              $t("labels.questionNumber", {
                                number: questionCount,
                              })
                            }}
                          </div>
                          <div
                            class="col-2"
                            :style="{
                              visibility: `${
                                isDisabled ? 'hidden' : 'visible'
                              }`,
                            }"
                            @click="
                              questionCount = Math.min(questionCount + 1, 50)
                            "
                          >
                            <i class="icon-plus bi bi-plus-circle-fill" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <PreviewPdfContainer
                  class="col-8"
                  :img-list="imageList"
                />

                <div
                  v-if="!isDisabled"
                  class="row px-0 mb-3"
                >
                  <div
                    class="col-12"
                    style="text-align: right"
                  >
                    <div
                      style="font-size: 70%; text-align: left; position: relative; display: inline-block"
                    >
                      {{ $t("labels.recommendPageMessage") }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="!isDisabled"
                class="row gx-5 justify-content-center"
              >
                <div class="col-auto">
                  <ButtonBorderCircleMedium
                    :label-name="$t('buttons.cancel')"
                    :label-color="colorLayoutTheme"
                    :border-color="colorLayoutTheme"
                    @click.native="$emit('close')"
                  />
                </div>
                <div class="col-auto">
                  <!-- 保存処理 -->
                  <ButtonBorderCircleMedium
                    :label-name="$t('buttons.save')"
                    color="layout-theme-light"
                    :label-color="colorLayoutTheme"
                    :border-color="colorLayoutTheme"
                    :disabled="isProcessing"
                    @click.native="onClickSave"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/**
 * 宿題管理 - PDFプレビューモーダル
 */
import ButtonClose from "@/components/atoms/buttons/ButtonClose.vue"
import FormTextField from "@/components/atoms/FormTextField.vue"
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"
import PreviewPdfContainer from "@/components/organisms/PreviewPdfContainer.vue"

export default {
  name: "ModalPreviewPdfQuestion",
  components: {
    ButtonClose,
    FormTextField,
    ButtonBorderCircleMedium,
    PreviewPdfContainer,
  },
  props: {
    taskName: {
      type: String,
      require: true,
      default: function () {
        return ""
      },
    },
    sendMemo: {
      type: String,
      require: true,
      default: function () {
        return ""
      },
    },
    imageList: {
      type: Array,
      require: true,
      default: function () {
        return ""
      },
    },
    defaultQuestionCount: {
      type: Number,
      require: true,
      default: function () {
        return 1
      },
    },
    isDisabled: { type: Boolean, default: false },
  },

  data: function () {
    return {
      colorLayoutTheme: "#ff7f27",
      settingList: null, // 設定リスト
      messageDisplayFlg: false, // メッセージ表示フラグ
      modalSendMemo: "", // 送信メモ
      modalTaskName: "", // 課題名
      showMsgTaskName: "", // 課題名エラーメッセージ
      showMsgSendMemo: "", // 送信メモエラーメッセージ
      questionCount: this.defaultQuestionCount, // 問題数
      isProcessing: false,  // 処理中？
    }
  },

  mounted: function () {
    this.modalTaskName = this.taskName
    this.modalSendMemo = this.sendMemo
    this.$emit("preview-open-flg")
  },

  methods: {
    // 登録処理
    onClickSave: function () {
      if (this.isProcessing || this.isError()) return

      this.isProcessing = true
      this.$emit("save", {
        taskName: this.modalTaskName,
        sendMemo: this.modalSendMemo,
        img: this.imageList,
        questionCount: this.questionCount,
      })
    },

    // 課題名入力値取得
    inputTaskName: function (text) {
      this.modalTaskName = text
    },

    // 送信メモ入力値取得
    inputSendMemo: function (text) {
      this.modalSendMemo = text
    },

    // 課題名フォーカスアウト処理
    blurTaskName: function (text) {
      this.showMsgTaskName = ""
      // 課題名必須チェック
      if (text.length > 50) {
        this.showMsgTaskName = this.$t("messages.error.overTextLength", {
          maxLength: "50",
        })
      }
    },

    // 送信メモフォーカスアウト処理
    blurSendMemo: function (text) {
      this.showMsgSendMemo = ""
      // 送信メモ必須チェック
      if (text.length > 50) {
        this.showMsgSendMemo = this.$t("messages.error.overTextLength", {
          maxLength: "50",
        })
      }
    },

    // エラーチェック
    isError: function () {
      let retFlg = false
      if (this.modalTaskName.length <= 0) {
        this.showMsgTaskName = this.$t("messages.error.required")
        retFlg = true
      } else {
        this.blurTaskName(this.modalTaskName)
        this.blurSendMemo(this.modalSendMemo)
        if (this.showMsgTaskName || this.showMsgSendMemo) {
          retFlg = true
        }
      }
      return retFlg
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;

    .modal-container-outer {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;

      .modal-default-button {
        text-align: right;
      }

      .modal-container {
        overflow: auto;
        max-height: 80vh;
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
        padding: 20px 30px;
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;

        .card-preview {
          height: calc(100% - 40px);

          .card-header {
            height: 5%;
            padding: 0.4rem;
            border-bottom: none;
            background: var(--bs-table-header);
            color: var(--bs-layout-theme);
            font-weight: bold;
            text-align: left;
          }
        }
      }
    }
  }
}
.modal-contents {
  @media (max-width: 1020px) {
    width: 100% !important;
  }
}

.question-area {
  height: 220px;

  .icon-minus:before,
  .icon-plus:before {
    color: #0070c0;

    font-size: 120%;
    vertical-align: bottom;
    cursor: pointer;
  }
}
</style>
