var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "title-homework" }, [
    _c("ul", { staticClass: "list-group list-group-horizontal title-area" }, [
      _c(
        "li",
        { staticClass: "list-group-item border-0 pt-0 ps-0 pe-2 pb-0" },
        [_c("IconHomeworkMono", { attrs: { width: "30px", height: "30px" } })],
        1
      ),
      _c(
        "li",
        { staticClass: "list-group-item border-0 px-0 pb-0 title-text" },
        [_c("h5", [_vm._v(_vm._s(_vm.$t("titles.homework")))])]
      ),
      _c(
        "li",
        { staticClass: "list-group-item border-0 ps-3 pe-1 pb-0 title-help" },
        [_c("ButtonOpenHelp", { attrs: { url: _vm.helpLink } })],
        1
      ),
      _vm.showRequiredMessage
        ? _c("li", { staticClass: "list-group-item border-0 title-text" }, [
            _c("label", { staticClass: "required-label" }, [
              _vm._v(" " + _vm._s(_vm.$t("labels.required")) + " ")
            ])
          ])
        : _vm._e()
    ]),
    _vm.showButtons
      ? _c(
          "div",
          { staticClass: "btn-create-homework" },
          [
            _c("ButtonBorderCircleMedium", {
              attrs: {
                "label-name": _vm.$t("buttons.createHomework"),
                color: "layout-theme-light",
                "label-color": _vm.colorLayoutTheme,
                "border-color": _vm.colorLayoutTheme
              },
              nativeOn: {
                click: function($event) {
                  return _vm.createHomework.apply(null, arguments)
                }
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.showButtons
      ? _c("div", { staticClass: "sub-functions-area" }, [
          _vm.showButtons
            ? _c(
                "div",
                { ref: "sub-functions", staticClass: "sub-functions" },
                [
                  _c("div", {
                    staticClass: "button-leader",
                    domProps: { textContent: _vm._s("Ⲷ") },
                    on: { click: _vm.toggleSubFunction }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "sub-menu-list",
                      attrs: { hidden: !_vm.showSubMenu }
                    },
                    [
                      _c("div", {
                        staticClass: "sub-menu-item",
                        domProps: {
                          textContent: _vm._s(_vm.$t("buttons.exportHomework"))
                        },
                        on: { click: _vm.onClickExportHomework }
                      }),
                      _c("div", {
                        staticClass: "sub-menu-item",
                        domProps: {
                          textContent: _vm._s(_vm.$t("buttons.settingEval"))
                        },
                        on: { click: _vm.onClickSetEval }
                      })
                    ]
                  )
                ]
              )
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }