<template>
  <span class="icon-account-info">
    <svg
      id="レイヤー_1"
      data-name="レイヤー 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 500 500"
      :width="width"
      :height="height"
    >
      <defs>
        <linearGradient
          id="名称未設定グラデーション_2"
          x1="4.32"
          y1="493.76"
          x2="9.12"
          y2="493.76"
          gradientTransform="matrix(44.45, 0, 0, -25.27, -34.06, 12662.54)"
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset="0"
            stop-color="#7db2ff"
          />
          <stop
            offset="1"
            stop-color="#a4e7ff"
          />
        </linearGradient>
      </defs>
      <path
        class="cls-1"
        d="M80.08,369a48.36,48.36,0,0,1-3.38,7.51A43.1,43.1,0,0,1,68,387.63a52.11,52.11,0,0,1-10.13,7.92L55,392.12A49,49,0,0,0,66.41,382q5-6,6.46-9.93H21.77v-4.48H78.46Zm-29.84,8.83A94.91,94.91,0,0,1,49,395.58a28.6,28.6,0,0,1-5,11.41,27.94,27.94,0,0,1-9.49,8.11A34.42,34.42,0,0,1,25,418.81L23,414.65q11.77-3.33,16.57-10.3t5.08-17.69l.06-8.79Z"
      />
      <path
        class="cls-1"
        d="M112.51,363.71V375.8h27.81v2.45q0,11-.32,15c-.21,2.62-.54,5.38-1,8.3a38.31,38.31,0,0,1-1.81,7.54,15.79,15.79,0,0,1-2.66,4.9,9.6,9.6,0,0,1-3.89,2.67,18.78,18.78,0,0,1-6.54.92,65.63,65.63,0,0,1-11.3-1.24l.13-4.82a52.93,52.93,0,0,0,10.57,1.17,13,13,0,0,0,4.36-.73q2.08-.72,3.63-3.87a31.77,31.77,0,0,0,2.43-9.28,103.77,103.77,0,0,0,.87-14.36l0-4.07H112.13q-1,14.64-7.39,23.81A46.9,46.9,0,0,1,88,418.81l-2.67-3.62a46.29,46.29,0,0,0,15.11-14q5.33-8.12,6.31-20.84H88V375.8h19V363.71Z"
      />
      <path
        class="cls-1"
        d="M207.09,375.26A80.51,80.51,0,0,1,206,389.5a38.54,38.54,0,0,1-3,9.84A28.25,28.25,0,0,1,198,406.7a30.67,30.67,0,0,1-7.82,5.75,49.83,49.83,0,0,1-11,4.12,89.05,89.05,0,0,1-14.11,2.3l-1.3-4.66A86.63,86.63,0,0,0,176.84,412a46.22,46.22,0,0,0,9.86-3.71,25.73,25.73,0,0,0,6.68-4.68,25.31,25.31,0,0,0,4.54-6.11,33.82,33.82,0,0,0,2.35-7.74,57.07,57.07,0,0,0,1-11.19l0-2.22H159.64v17.8h-5.52V372.09H177.7v-8.31h5.78v8.31h23.61Z"
      />
      <path
        class="cls-1"
        d="M274.47,387a56,56,0,0,1-13.84,16.08,62.48,62.48,0,0,1-17.69,10.09,93.5,93.5,0,0,1-21.63,4.65L220.2,413a85.39,85.39,0,0,0,18.53-3.79,56.09,56.09,0,0,0,13.89-6.64,70.06,70.06,0,0,0,9.6-7.71,52.29,52.29,0,0,0,7.9-10.52ZM246.8,379l-3.59,4.16a169.82,169.82,0,0,0-22.6-11.78l2.73-4A135.76,135.76,0,0,1,246.8,379Z"
      />
      <path
        class="cls-1"
        d="M339.63,396l-3.33,4.41a116.64,116.64,0,0,0-15.78-8.66,125.06,125.06,0,0,0-16.34-6.41V419h-5.65V363.46h5.71v17A156.33,156.33,0,0,1,339.63,396Z"
      />
      <path
        class="cls-1"
        d="M352.64,374.15q-.12,4-.86,9.83a53.81,53.81,0,0,1-1.58,8.55l-4-.79a82.73,82.73,0,0,0,2.32-17.9Zm8,45.58H356.1V362h4.57Zm7.9-42-3.39,1-2.38-8.38,3.39-.8Zm39.77,7.11h-40.5V381.2H385v-4.63H370.83v-3.46H385v-4.19H368.64v-3.49H385v-3.85h4.95v3.85h17.27v3.49H389.9v4.19h15.2v3.46H389.9v4.63h18.44Zm-8.88,23.68H375.78v11.26h-4.7V389h33.23v26a3.59,3.59,0,0,1-1.6,3,5.52,5.52,0,0,1-3.38,1.21h-6.89l-.54-4.13H397a2.64,2.64,0,0,0,1.7-.6,1.68,1.68,0,0,0,.75-1.3Zm0-11.56v-4.63H375.78V397Zm-23.68,8.22h23.68v-4.88H375.78Z"
      />
      <path
        class="cls-1"
        d="M429,419.79V407H417.16v-3.71H429v-7.43H417.1v-3.61h6.95l-2.95-10.64h-3.62v-3.71h11.61v-6.7h-9.74v-3.55h9.74v-5.46H434v5.46h9.68v3.55H434v6.7h10.89v3.71h-2.79l-3.43,10.64h6.41v3.61H433.92v7.43h10V407h-10v12.76Zm-.64-27.51h6.13l3-10.64h-12ZM448,419.57v-55.7h25.39v13a3.88,3.88,0,0,1-1.65,3.16,6.06,6.06,0,0,1-3.84,1.29h-7.23l-.67-3.91h5.78q2.85,0,2.85-2.31v-7.59H452.9v18.92h22.66a36.68,36.68,0,0,1-7.11,21.45,49.94,49.94,0,0,0,9.49,8l-3.05,3.46a52.85,52.85,0,0,1-9.11-8.16,35.74,35.74,0,0,1-9.52,7.84l-2.12-3a34.19,34.19,0,0,0,9-8.09l-.13-.16a35.49,35.49,0,0,1-4.47-7.54,35.92,35.92,0,0,1-2.83-10.2H452.9v29.48Zm17.62-15.07a29.34,29.34,0,0,0,5-14.41H459.78A31,31,0,0,0,465.66,404.5Z"
      />
      <path
        class="cls-2"
        d="M371.12,248.11H158.18V137a9.88,9.88,0,0,1,9.87-9.9H361.23a9.88,9.88,0,0,1,9.89,9.88V248.11Z"
      />
      <path
        class="cls-3"
        d="M143.43,255.38a4.35,4.35,0,0,1-4.37-4.35h0V123.25A14.28,14.28,0,0,1,153.31,109H346.69a14.28,14.28,0,0,1,14.25,14.25V230.66a4.37,4.37,0,1,1-8.73,0h0V123.25a5.53,5.53,0,0,0-5.52-5.53H153.31a5.52,5.52,0,0,0-5.52,5.52h0V251A4.38,4.38,0,0,1,143.43,255.38Z"
      />
      <path
        class="cls-3"
        d="M353.06,284.83H146.92a33.8,33.8,0,0,1-33.75-33.75,4.39,4.39,0,0,1,4.37-4.39H382.46a4.37,4.37,0,0,1,4.37,4.35h0A33.82,33.82,0,0,1,353.06,284.83ZM122.28,255.39a25.07,25.07,0,0,0,24.63,20.71H353.06a25,25,0,0,0,24.64-20.71Z"
      />
      <circle
        class="cls-4"
        cx="256.97"
        cy="164.83"
        r="17.19"
      />
      <path
        class="cls-4"
        d="M222.09,227.32a4.94,4.94,0,0,1-4.94-4.93h0v-2.91A26.25,26.25,0,0,1,241.9,192H272a26.26,26.26,0,0,1,24.76,27.52v2.91a4.94,4.94,0,0,1-4.93,4.94H222.09Z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: "IconAccountInfo",
  props: {
    width: {
      type: String,
      default: function () {
        return ''
      },
    },
    height: {
      type: String,
      default: function () {
        return ''
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.cls-1 {
  fill: #1a1a1a;
  stroke: #1a1a1a;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
.cls-2 {
  fill: url(#名称未設定グラデーション_2);
}
.cls-3 {
  fill: #3a4951;
}
.cls-4 {
  fill: #fff;
}
</style>