/**
 * パラメータ設定値情報 store
 */
import convertValue from '@/repositories/convert-value'

export const parameterSettings = {
  namespaced: true,
  state: {
    /**
     * ヘッダー情報
     */
    headerInfo: {
      // json ファイル名
      fileName: '',
      // json ファイル URL クエリストリングのパラメータ情報
      urlParameters: {
        expires: '',
        signature: '',
        keyPairId: '',
      },
    },

    /**
     * 設定情報
     */

    // 共通
    public: {
      // 外部連携
      linkTheSystemItems: [],
      // アップロード上限値
      uploadMaximumLimitItems: [],
      // 公開終了期間
      releaseEndPeriodItems: [],
      // 保持期間
      keepPeriodItems: [],
      // 締切期間
      deadlinePeriodItems: [],
      // API設定
      apiSettingItems: [],
    },

    // 宿題管理
    homework: {
      // 宿題作成上限値
      createHomeworkMaximumLimitItems: [],
      // 各宿題上限値
      homeworkMaximumLimitItems: [],
    },
  },

  getters: {
    // ヘッダー情報
    headerInfo: (state) => {
      return state.headerInfo
    },

    // 共通
    linkTheSystemItems: (state) => {
      return state.public.linkTheSystemItems
    },
    uploadMaximumLimitItems: (state) => {
      return state.public.uploadMaximumLimitItems
    },
    releaseEndPeriodItems: (state) => {
      return state.public.releaseEndPeriodItems
    },
    keepPeriodItems: (state) => {
      return state.public.keepPeriodItems
    },
    deadlinePeriodItems: (state) => {
      return state.public.deadlinePeriodItems
    },
    apiSettingItems: (state) => {
      return state.public.apiSettingItems
    },

    // 宿題管理
    createHomeworkMaximumLimitItems: (state) => {
      return state.homework.createHomeworkMaximumLimitItems
    },
    homeworkMaximumLimitItems: (state) => {
      return state.homework.homeworkMaximumLimitItems
    },
  },

  mutations: {
    // ヘッダー情報
    // actions のみから使用する（単独使用禁止）
    setHeaderInfo(state, payload) {
      state.headerInfo.fileName = payload.headerInfo.fileName
      state.headerInfo.urlParameters.expires = payload.headerInfo.urlParameters.expires
      state.headerInfo.urlParameters.signature = payload.headerInfo.urlParameters.signature
      state.headerInfo.urlParameters.keyPairId = payload.headerInfo.urlParameters.keyPairId
    },

    // 初期化
    // actions のみから使用する（単独使用禁止）
    clearHeaderInfo(state) {
      state.headerInfo = {}
    },

    // 共通
    // 変更
    setLinkTheSystemItems(state, payload) {
      state.public.linkTheSystemItems = payload.linkTheSystemItems
    },
    setUploadMaximumLimitItems(state, payload) {
      state.public.uploadMaximumLimitItems = payload.uploadMaximumLimitItems
    },
    setReleaseEndPeriodItems(state, payload) {
      state.public.releaseEndPeriodItems = payload.releaseEndPeriodItems
    },
    setKeepPeriodItems(state, payload) {
      state.public.keepPeriodItems = payload.keepPeriodItems
    },
    setDeadlinePeriodItems(state, payload) {
      state.public.deadlinePeriodItems = payload.deadlinePeriodItems
    },
    setApiSettingItems(state, payload) {
      state.public.apiSettingItems = payload.apiSettingItems
    },

    // 初期化
    clearLinkTheSystemItems(state) {
      state.public.linkTheSystemItems.splice(0)
    },
    clearUploadMaximumLimitItems(state) {
      state.public.uploadMaximumLimitItems.splice(0)
    },
    clearReleaseEndPeriodItems(state) {
      state.public.releaseEndPeriodItems.splice(0)
    },
    clearKeepPeriodItems(state) {
      state.public.keepPeriodItems.splice(0)
    },
    clearDeadlinePeriodItems(state) {
      state.public.deadlinePeriodItems.splice(0)
    },

    // 宿題管理
    // 変更
    setCreateHomeworkMaximumLimitItems(state, payload) {
      state.homework.createHomeworkMaximumLimitItems = payload.createHomeworkMaximumLimitItems
    },
    setHomeworkMaximumLimitItems(state, payload) {
      state.homework.homeworkMaximumLimitItems = payload.homeworkMaximumLimitItems
    },

    // 初期化
    clearCreateHomeworkMaximumLimitItems(state) {
      state.homework.createHomeworkMaximumLimitItems.splice(0)
    },
    clearHomeworkMaximumLimitItems(state) {
      state.homework.homeworkMaximumLimitItems.splice(0)
    },
  },

  actions: {
    saveConfigSettings({ commit }, configJson) {
      // ヘッダー情報
      commit('setHeaderInfo', { headerInfo: configJson.parameterSettings.headerInfo })

      // 共通
      // 外部連携
      const linkTheSystems = convertValue.objectToArray(configJson.parameterSettings.Public.ExternalCooperation, 'itemName', 'items')
      const linkTheSystemItems = linkTheSystems.map(x => {
        return {
          itemName: x.itemName,
          items: convertValue.objectToArray(x.items, 'code', 'value')
        }
      })
      commit('setLinkTheSystemItems', { linkTheSystemItems: linkTheSystemItems })
      // アップロード上限値
      const uploadMaximumLimits = convertValue.objectToArray(configJson.parameterSettings.Public.UploadMaximumValue, 'itemName', 'items')
      const uploadMaximumLimitItems = uploadMaximumLimits.map(x => {
        return {
          itemName: x.itemName,
          items: convertValue.objectToArray(x.items, 'code', 'value')
        }
      })
      commit('setUploadMaximumLimitItems', { uploadMaximumLimitItems: uploadMaximumLimitItems })
      // 公開終了期間
      const releaseEndPeriods = convertValue.objectToArray(configJson.parameterSettings.Public.PublicationEndPeriod, 'itemName', 'items')
      const releaseEndPeriodItems = releaseEndPeriods.map(x => {
        return {
          itemName: x.itemName,
          items: convertValue.objectToArray(x.items, 'code', 'value')
        }
      })
      commit('setReleaseEndPeriodItems', { releaseEndPeriodItems: releaseEndPeriodItems })
      // 保持期間
      const keepPeriods = convertValue.objectToArray(configJson.parameterSettings.Public.RetentionPeriod, 'itemName', 'items')
      const keepPeriodItems = keepPeriods.map(x => {
        return {
          itemName: x.itemName,
          items: convertValue.objectToArray(x.items, 'code', 'value')
        }
      })
      commit('setKeepPeriodItems', { keepPeriodItems: keepPeriodItems })
      // 締切期間
      const deadlinePeriods = convertValue.objectToArray(configJson.parameterSettings.Public.DeliveryPeriod, 'itemName', 'items')
      const deadlinePeriodItems = deadlinePeriods.map(x => {
        return {
          itemName: x.itemName,
          items: convertValue.objectToArray(x.items, 'code', 'value')
        }
      })
      commit('setDeadlinePeriodItems', { deadlinePeriodItems: deadlinePeriodItems })

      // API設定
      const apiSettings = convertValue.objectToArray(configJson.parameterSettings.Public.ApiSetting, 'itemName', 'items')
      const apiSettingItems = apiSettings.map(x => {
        return {
          itemName: x.itemName,
          items: convertValue.objectToArray(x.items, 'code', 'value')
        }
      })
      commit('setApiSettingItems', { apiSettingItems: apiSettingItems })


      // 宿題管理
      // 宿題作成上限値
      const createHomeworkMaximumLimits = convertValue.objectToArray(configJson.parameterSettings.Homework.HomeworkCreationMaximumValue, 'itemName', 'items')
      const createHomeworkMaximumLimitItems = createHomeworkMaximumLimits.map(x => {
        return {
          itemName: x.itemName,
          items: convertValue.objectToArray(x.items, 'code', 'value')
        }
      })
      commit('setCreateHomeworkMaximumLimitItems', { createHomeworkMaximumLimitItems: createHomeworkMaximumLimitItems })
      // 各宿題上限値
      const homeworkMaximumLimits = convertValue.objectToArray(configJson.parameterSettings.Homework.EachHomeworkMaximumValue, 'itemName', 'items')
      const homeworkMaximumLimitItems = homeworkMaximumLimits.map(x => {
        return {
          itemName: x.itemName,
          items: convertValue.objectToArray(x.items, 'code', 'value')
        }
      })
      commit('setHomeworkMaximumLimitItems', { homeworkMaximumLimitItems: homeworkMaximumLimitItems })
    },
  },
}