<template>
  <div class="button-forward">
    <button
      type="button"
      :class="['btn', 'btn-' + color]"
      :style="buttonStyle"
    >
      <div class="d-flex flex-row">
        {{ labelName }}
        &nbsp;
        <IconCaretRight
          width="15px"
          height="15px"
        />
      </div>
    </button>
  </div>
</template>

<script>
import IconCaretRight from "@/components/atoms/icons/IconCaretRight.vue"

export default {
  name: "ButtonForward",
  components: {
    IconCaretRight,
  },
  props: {
    labelName: {
      type: String,
      default: function () {
        return ''
      },
    },
    color: {
      type: String,
      default: function () {
        return ''
      },
    },
    labelColor: {
      type: String,
      default: function () {
        return ''
      },
    },
    borderColor: {
      type: String,
      default: function () {
        return ''
      },
    },
  },

  computed: {
    buttonStyle: function () {
      return {
        color: this.labelColor,
        border: `solid 1px ${this.borderColor}`,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  padding: 0.5em 3em 0.6em;
  font-weight: bold;
}
</style>