<template>
  <div class="modal-progress-preview-single-stdb-question">
    <transition
      name="modal"
      appear
    >
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container-outer">
            <ButtonClose
              class="modal-default-button"
              @click.native="$emit('close')"
            />
            <div
              class="modal-container px-2 py-4"
              style="overflow: auto; max-height: 80vh"
            >
              <div class="row mb-2 task-name">
                <div
                  v-if="selectedHomeworkItemIndex !== 0"
                  class="col-2"
                >
                  <div class="show-three-point">
                    No.{{ selectedHomeworkItemIndex }}
                  </div>
                </div>
                <div class="col-10">
                  <div class="show-three-point">
                    {{ item.taskName }}
                  </div>
                </div>
              </div>
              <LoadingScreen v-if="isLoading" />
              <PreviewContainer
                ref="previewContainer"
                :image="image"
                :setting-list="settingList"
                :stdb-flg="true"
              />
            </div>
          </div>
        </div>
      </div>
    </transition>
    <ModalConfirmOkOnly
      v-if="showConfirmCaution"
      :message="cautionMessage"
      @close-confirm-modal-ok-only="closeModal"
    />
  </div>
</template>

<script>
/**
 * 宿題管理 - 教材の問題プレビューモーダル
 */
import mixin from "@/mixins/mixin"
import ButtonClose from "@/components/atoms/buttons/ButtonClose.vue"
import PreviewContainer from "@/components/organisms/PreviewContainer.vue"
import LoadingScreen from "@/components/atoms/LoadingScreen.vue"
import homeworkRepository from "@/repositories/homework"
import { settingTabIdCode } from "@/constant/question"
import ModalConfirmOkOnly from "@/components/organisms/modal/ModalConfirmOkOnly.vue"

export default {
  name: "ModalProgressPreviewSingleStdbQuestion",
  components: {
    ButtonClose,
    PreviewContainer,
    LoadingScreen,
    ModalConfirmOkOnly,
  },
  mixins: [mixin],
  props: {
    item: {
      type: Object,
      require: true,
      default: function () {
        return {}
      },
    },
    selectedHomeworkItemIndex: {
      type: Number,
      default: function () {
        return 0
      },
    },
  },

  data: () => ({
    image: "",
    settingList: [],
    isLoading: false,
    cautionMessage: "",
    showConfirmCaution: false,
  }),
  async mounted() {
    this.settingList = []

    let fileList = this.item.fileList
      ? this.item.fileList.map((file) => {
          return {
            fileName: file.file_name,
            filePath: file.file_image,
          }
        })
      : []
    if (fileList.length === 0) {
      this.isLoading = true
      try {
        fileList = await this.fetchFileList()
      } catch (error) {
        // メッセージ出力
        if (this.item.isPublished) {
          this.showModalOkOnly(
            this.$t("messages.error.uploadPublicEndPreviewForPublish")
          )
        } else {
          this.showModalOkOnly(this.$t("messages.error.uploadPublicEndPreview"))
        }
        this.isLoading = false
        return
      }
    }

    fileList.forEach((info) => {
      const prefix = info.fileName[0]
      switch (prefix) {
        case "Q":
          this.image = info.filePath
          break
        case "A":
          this.appendToSettingList(
            settingTabIdCode.answerId,
            info.filePath,
            prefix
          )
          break
        case "E":
          this.appendToSettingList(
            settingTabIdCode.explainId,
            info.filePath,
            prefix
          )
          break
      }
    })

    if (this.settingList.length > 0) {
      // IDの順に並べ替え、一番左のものを選択
      this.settingList.sort((a, b) => (a.id > b.id ? 1 : -1))
      this.$set(
        this.$refs.previewContainer,
        "currentId",
        this.settingList[0].id
      )
      this.$refs.previewContainer.initializeId()
    }

    this.isLoading = false
  },
  methods: {
    /**
     * ファイルリストを取得
     */
    fetchFileList: async function () {
      return await homeworkRepository.getHomeworkStreamFileListForStdbPreview(
        this.loginUserInfo.accountId,
        this.loginUserInfo.schoolId,
        this.item.mode,
        this.item.key,
        this.item.keyNo,
        this.item.fileNmSeq,
        this.loginUserInfo.lmsApiToken
      )
    },
    /**
     * settingListに画像情報を追加
     */
    appendToSettingList: function (id, filePath, prefix) {
      let label = ""
      switch (prefix) {
        case "A":
          label = this.$t("labels.answer")
          break
        case "E":
          label = this.$t("labels.explanation")
          break
      }
      const item = {
        content: filePath,
        id: id,
        label: label,
      }
      this.settingList.splice(0, 0, item)
    },
    /*
     * 閉じるモーダル
     */
    closeModal: function () {
      this.showConfirmCaution = false
      this.$emit("close")
    },

    /**
     * OKだけのモーダルを表示する
     */
    showModalOkOnly: function (message) {
      this.cautionMessage = message
      this.showConfirmCaution = true
    },
  },
}
</script>

<style lang="scss" scoped>
.show-three-point {
  max-width: fit-content;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.task-name {
  width: 80% !important;
  margin: 0 auto !important;
  @media (max-width: 1020px) {
    width: 100% !important;
  }
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;

    .modal-container-outer {
      max-width: 1200px;
      width: 70vw;
      margin-left: auto;
      margin-right: auto;

      .modal-default-button {
        width: 51px;
        margin-left: auto;
      }

      .modal-container {
        margin-left: auto;
        margin-right: auto;
        padding: 20px 30px;
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        text-align: center;

        .card-preview {
          height: calc(100% - 40px);

          .card-header {
            height: 5%;
            padding: 0.4rem;
            border-bottom: none;
            background: var(--bs-table-header);
            color: var(--bs-layout-theme);
            font-weight: bold;
            text-align: left;
          }

          ::v-deep li {
            padding: 0;
            list-style: none;
          }

          // タブ画像エリア
          ::v-deep .preview-display {
            height: 90%;
          }
        }
      }
    }
  }
}

// トランジション（アニメーション）設定
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: opacity 0.8s;
}
</style>
