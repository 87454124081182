var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "icon-preview" }, [
    _c(
      "svg",
      {
        staticStyle: { opacity: "1" },
        attrs: {
          id: "_x32_",
          version: "1.1",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          x: "0px",
          y: "0px",
          width: _vm.width,
          height: _vm.height,
          viewBox: "0 0 512 512",
          "xml:space": "preserve"
        }
      },
      [
        _c("g", [
          _c("path", {
            staticClass: "st0",
            staticStyle: { fill: "rgb(0, 0, 0)" },
            attrs: {
              d:
                "M105.764,464.839c-28.914,0-52.374-23.371-52.374-52.373V155.473h81.045c24.121,0,43.612-19.498,43.612-43.62\n      V30.818h164.726c28.913,0,52.292,23.371,52.292,52.372v124.487c10.076,6.116,19.579,13.554,28.09,22.057\n      c0.904,0.911,1.896,1.903,2.72,2.896V83.19C425.875,37.255,388.62,0,342.774,0H165.333l-9.092,9.003L31.585,133.66l-9.004,9.092\n      v269.715c0,45.855,37.335,83.19,83.183,83.19h177.448c0,0-30.293-26.154-38.658-30.817H105.764z"
            }
          }),
          _c("path", {
            staticClass: "st0",
            staticStyle: { fill: "rgb(0, 0, 0)" },
            attrs: {
              d:
                "M212.502,229.734c9.246-9.246,19.491-17.095,30.479-23.46H109.733v29.414h97.147\n      C208.703,233.702,210.518,231.718,212.502,229.734z"
            }
          }),
          _c("rect", {
            staticClass: "st0",
            staticStyle: { fill: "rgb(0, 0, 0)" },
            attrs: {
              x: "217.125",
              y: "127.787",
              width: "119.784",
              height: "29.43"
            }
          }),
          _c("path", {
            staticClass: "st0",
            staticStyle: { fill: "rgb(0, 0, 0)" },
            attrs: {
              d:
                "M109.733,288.883v29.414h60.142c1.153-9.996,3.218-19.83,6.438-29.414H109.733z"
            }
          }),
          _c("path", {
            staticClass: "st0",
            staticStyle: { fill: "rgb(0, 0, 0)" },
            attrs: {
              d:
                "M109.733,400.906h74.51c-4.704-9.504-8.261-19.33-10.818-29.414h-63.692V400.906z"
            }
          }),
          _c("path", {
            staticClass: "st0",
            staticStyle: { fill: "rgb(0, 0, 0)" },
            attrs: {
              d:
                "M480.483,464.727c0,0-36.634-32.044-45.403-40.788c-12.859-12.803-17.998-18.966-13.464-29.365\n      c19.862-40.498,12.989-90.774-20.693-124.447c-42.353-42.361-111.03-42.361-153.384,0c-42.354,42.362-42.354,111.023,0,153.384\n      c33.665,33.665,83.972,40.538,124.438,20.684c10.407-4.534,16.579,0.606,29.374,13.473c8.753,8.761,40.788,45.395,40.788,45.395\n      c16.61,16.61,29.389,6.397,37.077-1.275C486.872,494.116,497.093,481.337,480.483,464.727z M368.637,391.217\n      c-24.524,24.517-64.28,24.517-88.812,0c-24.517-24.517-24.517-64.288,0-88.797c24.532-24.517,64.288-24.517,88.812,0\n      C393.154,326.928,393.154,366.7,368.637,391.217z"
            }
          })
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }