<template>
  <div class="icon-qestion">
    <svg
      id="レイヤー_1"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      :width="width"
      :height="height"
      viewBox="0 0 70.6 70.6"
      style="enable-background:new 0 0 70.6 70.6;"
      xml:space="preserve"
    >
      <g>
        <path
          class="st0"
          d="M47.9,25.5H19.7c-1.2,0-2.1-1-2.1-2.1c0-1.2,1-2.1,2.1-2.1h28.2c1.2,0,2.1,1,2.1,2.1
          C50,24.6,49.1,25.5,47.9,25.5z"
          :fill="fillColor"
        />
        <path
          class="st0"
          d="M47.9,36.9H19.7c-1.2,0-2.1-1-2.1-2.1c0-1.2,1-2.1,2.1-2.1h28.2c1.2,0,2.1,1,2.1,2.1
          C50,36,49.1,36.9,47.9,36.9z"
          :fill="fillColor"
        />
        <path
          class="st0"
          d="M47.9,48.3H19.7c-1.2,0-2.1-1-2.1-2.1c0-1.2,1-2.1,2.1-2.1h28.2c1.2,0,2.1,1,2.1,2.1
          C50,47.4,49.1,48.3,47.9,48.3z"
          :fill="fillColor"
        />
        <path
          class="st0"
          d="M67.6,62.8l-3.4,3.4c-0.4,0.4-0.9,0.4-1.3,0L45.3,48.6c-0.4-0.4-0.4-0.9,0-1.3l3.4-3.4c0.4-0.4,0.9-0.4,1.3,0
          l17.6,17.6C67.9,61.8,67.9,62.4,67.6,62.8z"
          :fill="fillColor"
        />
        <path
          class="st0"
          d="M48,56.3H12.9V13.3h41.9v30.7l4.2,4.2v-37c0-1.2-0.9-2.1-2.1-2.1H10.8c-1.2,0-2.1,0.9-2.1,2.1v47.1
          c0,1.2,0.9,2.1,2.1,2.1h41.4L48,56.3z"
          :fill="fillColor"
        />
      </g>
      <rect
        x="0"
        y="0"
        class="st1"
        width="70.6"
        height="70.6"
        style="fill:none;"
      />
    </svg>
  </div>
</template>
<script>
export default {
name: 'IconQuestion',
props: {
  width: {
      type: String,
      default: function () {
        return '25px'
      },
    },
  height: {
      type: String,
      default: function () {
        return '25px'
      },
    },
  fillColor: {
    type: String,
    require: false,
    default: "#323333"
  },
},

data: () => ({
  // アイコンにタイトルを設定する場合は、iconName を表示するようにする
  // ファイル名：navi_問題管理.svg
  iconName: '問題'
})



}
</script>
