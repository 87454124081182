var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "icon-edit" }, [
    _c(
      "svg",
      {
        staticStyle: { opacity: "1" },
        attrs: {
          id: "_x32_",
          version: "1.1",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          x: "0px",
          y: "0px",
          width: _vm.width,
          height: _vm.height,
          viewBox: "0 0 512 512",
          "xml:space": "preserve"
        }
      },
      [
        _c("g", [
          _c("path", {
            staticClass: "st0",
            staticStyle: { fill: "rgb(250, 113, 11)" },
            attrs: {
              d:
                "M506.53,88.263L423.737,5.47c-7.294-7.293-19.118-7.293-26.411,0L47.776,341.176\n      c-2.168,2.168-3.769,4.836-4.661,7.768L0.836,487.86c-4.35,14.293,9.011,27.654,23.304,23.305l143.382-43.639h0L506.53,114.675\n      C513.823,107.381,513.823,95.556,506.53,88.263z M63.524,465.077l-16.584-16.584l24.373-80.107l47.004,25.29l25.296,47.012\n      L63.524,465.077z M453.986,75.041L156.172,374.747l-18.919-18.918l299.72-297.828l17.013,17.012\n      C453.986,75.024,453.986,75.03,453.986,75.041z"
            }
          })
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }