var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "button-clear" }, [
    _c(
      "button",
      { staticClass: "btn", attrs: { type: "button" } },
      [
        _c("IconClose", {
          attrs: { width: "10px", height: "10px", colorStyle: "#212529" }
        }),
        _c("div", {
          staticClass: "btn-label",
          domProps: { textContent: _vm._s(_vm.$t("buttons.clear")) }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }