<template>
  <div
    class="homework-progress"
  >
    <div class="row mt-3 mb-2 mx-4 justify-content-around">
      <div class="col-auto p-0">
        <TitleHomework :help-link="$t('url.helps.homeworkProgress')" />
      </div>
      <div class="col text-center homework-name py-2">
        {{ homeworkName }}
      </div>
      <div class="col-auto p-0">
        <div class="d-flex flex-column">
          <div>
            {{
              $t("labels.startDateReplaceWords", {
                date: "：" + params.startDate,
              })
            }}
          </div>
          <div>
            {{
              $t("labels.deadlineDateReplaceWords", {
                date: "：" + params.deadlineDate,
              })
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3 mx-4">
      <div class="col mt-2 mb-2 p-0">
        <div class="row justify-content-around align-items-center">
          <div class="col-auto">
            <router-link :to="{ name: 'Homework' }">
              <ButtonReturn
                :label-name="$t('buttons.returnToList')"
                label-color="#ff7f27"
                border-color="#ff7f27"
              />
            </router-link>
          </div>
          <div class="col-auto">
            <div class="bulk-return">
              <div
                class="bulk-return-button"
                :class="{ hide: isBulkMode }"
              >
                <ButtonBulkReturn
                  :label-name="labelBulkMode"
                  :style="{color : editingPermission ? 'layout-theme-light' : ''}"
                  label-color="#ff7f27"
                  border-color="#ff7f27"
                  font-size="0.9rem"
                  @click.native="onClickBulkMode"
                  :editing-permission="editingPermission"
                />
              </div>
              <div
                v-if="isBulkMode"
                class="bulk-return-menu"
              >
                <div class="bulk-return-menu-frame">
                  <label>{{ labelSubmissionStatus }}</label>
                  <FormSelectBox
                    id="submissionStatus"
                    ref="submissionStatusSelectBox"
                    :value="selectedSubmissionStatus"
                    :options="generateSubmissionStatusItems"
                    :initial-value="initialSubmissionStatus"
                    :disabled="false"
                    :is-require="false"
                    @input="onSelectSubmissionStatus"
                  />

                  <ButtonBorderCircleMedium
                    :label-name="$t('buttons.cancel')"
                    label-color="#ff7f27"
                    border-color="#ff7f27"
                    font-size="0.9rem"
                    width="100px"
                    padding="0"
                    @click.native="onClickBulkMode"
                  />

                  <ButtonBorderCircleMedium
                    :label-name="labelBulkReturn"
                    color="layout-theme-light"
                    label-color="#ff7f27"
                    border-color="#ff7f27"
                    font-size="0.9rem"
                    width="100px"
                    padding="0"
                    :disabled="bulkReturnDisabled"
                    @click.native="onClickBulkReturn"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col text-end">
            <div class="d-flex justify-content-end align-items-center">
              <div
                class="sub-functions-area"
              >
                <div
                  ref="sub-functions"
                  class="sub-functions"
                >
                  <div
                    class="button-leader"
                    @click="toggleSubFunction"
                    v-text="'Ⲷ'"
                  ></div>
                  <div
                    class="sub-menu-list"
                    :hidden="!showSubMenu"
                  >
                    <div
                      class="sub-menu-item"
                      @click="onClickExport"
                      v-text="$t('buttons.fileExport')"
                    ></div>
                    <div
                      class="sub-menu-item"
                      :class="{'no-editing-permission' : !editingPermission}"
                      @click="onClickHomeworkEvalSet"
                      v-text="$t('buttons.homeworkEvalSet')"
                    ></div>
                  </div>
                </div>
              </div>
              <CheckBox
                ref="showStudentNameCheckBox"
                label="生徒名を表示"
                :initial-checked="true"
                @input="onChangedShowStudentNameCheckBox"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      ref="containerScroller"
      class="container-scroller"
    >
      <div class="col" style="min-height: calc(100% - 42px);">
        <div class="w-100" style="padding-left: 40px;">
          <div
            v-if="showWhole"
            ref="wholeArea"
            class="mb-4"
          >
            <div class="col pe-0">
              <TableHomeworkProgressWhole
                :header-items="!isLoading ? homeworkHeaderItems : []"
                :child-question-rate-items="
                  !isLoading ? childQuestionRateItems : []
                "
                :book-items="bookItems"
                :student-items="studentItems"
                :homework-details="homeworkDetails"
                v-on="{
                  'opend': tableHomeworkProgressWholeOpenedHandler,
                  'closed': tableHomeworkProgressWholeClosedHandler,
                }"
              />
            </div>
          </div>
          <div>
            <div class="col pe-0">
              <TableHomeworkProgressStudents
                ref="tableHomeworkProgressStudents"
                :header-items="!isLoading ? homeworkHeaderItems : []"
                :items="!isLoading ? homeworkProgressItems : []"
                :view-items="!isLoading ? viewProgressItems : []"
                :grade-stamps="stamps"
                :teacher-comment="teacherComment"
                :haishin-start-date="haishinStartDate"
                :show-student-name="showStudentName"
                :progress-params="params"
                :homework-details="homeworkDetails"
                :show-bulk-check="showBulkCheck"
                :show-whole="showWhole"
                :book-items="bookItems"
                :student-items="studentItems"
                :editing-permission="editingPermission"
                :school-info="schoolInfo"
                @update-is-loading="isLoading2 = $event"
                v-on="{
                  'on-click-return-of': onClickReturnOf,
                  'on-click-send-back': onClickSendBack,
                  'on-click-send-save': onClickSendSave,
                  'on-checked-student-data': onCheckedStudentData,
                  'on-change-show-student-name':
                    onChangedShowStudentNameCheckBox,
                  'on-reload': reloadStudentsInfo,
                  'on-close-bulk-return': closeModalBulkReturn,
                  'on-show-whole': tableHomeworkProgressWholeOpenedHandler,
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
    <ModalConfirmOkOnly
      v-if="showNoEditPermission"
      :message="$t('messages.error.noEditingPermission')"
      @close-confirm-modal-ok-only="showNoEditPermission = false"
    />
    <ModalSettingEval
      v-if="showHomeworkEvalSetModal"
      :initial-data="homeworkEvalSet"
      :is-common="false"
      v-on="{
        set: onClickEvalSet,
        cancel: closeModalHomeworkEvalSet,
      }"
    />
    <LoadingScreen v-if="isLoading" />
    <!-- isLoading2は内部データを読み直さない場合に使うローディング -->
    <LoadingScreen v-if="isLoading2" />
  </div>
</template>

<script>
/**
 * 宿題管理 - 宿題進捗
 */
import { mapGetters, mapMutations, mapActions } from "vuex";
import mixin from "@/mixins/mixin";
import TitleHomework from "@/components/molecules/TitleHomework.vue";
import ButtonReturn from "@/components/atoms/buttons/ButtonReturn.vue";
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"
import FormSelectBox from "@/components/atoms/FormSelectBox.vue"
import CheckBox from "@/components/atoms/CheckBox.vue";
import TableHomeworkProgressWhole from "@/components/organisms/TableHomeworkProgressWhole.vue";
import TableHomeworkProgressStudents from "@/components/organisms/TableHomeworkProgressStudents.vue";
import LoadingScreen from "@/components/atoms/LoadingScreen.vue";
import homeworkRepository from "@/repositories/homework";
import accountRepository from "@/repositories/account";
import { handedStatus, noteType } from "@/constant/homework"
import ModalSettingEval from "@/components/organisms/modal/ModalSettingEval.vue"
import { funcs } from "@/dbs/indexedDb"
import ButtonBulkReturn from "../components/atoms/buttons/ButtonBulkReturn.vue";
import Footer from "@/components/organisms/common/Footer.vue"
import ModalConfirmOkOnly from "@/components/organisms/modal/ModalConfirmOkOnly.vue"

export default {
  name: "HomeworkProgress",
  components: {
    TitleHomework,
    ButtonReturn,
    ButtonBulkReturn,
    ButtonBorderCircleMedium,
    CheckBox,
    TableHomeworkProgressWhole,
    TableHomeworkProgressStudents,
    LoadingScreen,
    ModalSettingEval,
    FormSelectBox,
    Footer,
    ModalConfirmOkOnly,
},

  mixins: [mixin],
  props: {
    progressParams: {
      type: Object,
      require: true,
      default: function () {
        return {};
      },
    },
  },

  data: function () {
    return {
      colorLayoutTheme: "#ff7f27",
      params: this.progressParams,
      homeworkName: "",
      homeworkHeaderItems: [],
      homeworkProgressItems: [],
      viewProgressItems: [],
      childQuestionRateItems: [],
      isLoading: false,
      isLoading2: false,
      // 初期値はチェックボックス ON とする
      showStudentName: true,
      teacherComment: "",
      haishinStartDate: "",
      // 生徒別データで選択したスタンプコード
      selectedStampCode: 0,
      // 生徒名称リスト
      groupMemberItems: [],
      studentItems: [],
      bookItems: [],
      homeworkDetails: {},
      systemDate: "",
      // 宿題評価設定
      showHomeworkEvalSetModal: false,
      homeworkEvalSet: {},
      tableHomeworkProgressWholeHeightClose: NaN,
      tableHomeworkProgressWholeHeightOpen: NaN,
      // 一括返却
      isBulkMode: false,
      labelBulkMode: "一括返却",
      labelBulkReturn: "返却",
      showBulkCheck: false,
      // 提出状況
      labelSubmissionStatus: "返却対象",
      selectedSubmissionStatus: 0,
      initialSubmissionStatus: "0",

      /** 学校共通設定情報 */
      schoolInfo: {},

      showWhole: true,
      showSubMenu: false,
      showNoEditPermission: false,
      editingPermission: true,
      createTeacher:""
    };
  },

  watch: {
    async showWhole(v) {
      let displayPreviousValues = await funcs.displayPreviousValues.get()
      Object.assign(displayPreviousValues, {
        showHomeworkProgressWhole: v
      })
      await funcs.displayPreviousValues.put(displayPreviousValues)
    }
  },

  computed: {
    ...mapGetters("gradeStamp", ["stamps"]),
    ...mapGetters("homework", [
      "temporaryProgressParams",
      "checkedStudentList",
    ]),
    ...mapGetters("nameConversions", ["gradeStampItems"]),
    /**
     * 提出ステータスプルダウンリスト生成
     */
    generateSubmissionStatusItems: function () {
      const array = [{
        label: '選択してください', value: '0'
      }].concat(this.homeworkProgressItems.map((v) => {
        // ステータスをプルダウン情報にコンバート
        return this.$refs.tableHomeworkProgressStudents.convertHandedStatusToSelectionValue(
          v.status,
          v.expired
        )
      }).reduce((a, v) => {
        // 重複を除去
        if (!a.some(e => e.value === v.value)) {
          a.push(v)
        }
        return a
      }, []))
      
      // ソート
      array.sort((a, b) => {
        return Number(a.value) > Number(b.value) ? 1 : -1
      })
      
      return array
    },    
    bulkReturnDisabled: function () {
      return this.$refs.tableHomeworkProgressStudents.getBulkReturnDisabled()
    },
  },
  created: function () {
    if (!Object.keys(this.params).length) {
      this.params = JSON.parse(JSON.stringify(this.temporaryProgressParams));
    } else {
      // 宿題一覧から貰ったパラメータが存在すればそれをstoreに保存する
      // ※this.progressParamsはリロードしたら消えてしまうため
      this.setTemporaryProgressParams(JSON.parse(JSON.stringify(this.params)));
    }
    this.homeworkName = this.params.homeworkName;
  },
  mounted: async function () {
    console.time('mounted');
    // セッション期間が有効かチェックする
    if (!await this.checkSession()) {
      return
    }

    window.document.addEventListener('click', this.closeSubMenu)

    let displayPreviousValues = await funcs.displayPreviousValues.get()
    let showHomeworkProgressWhole =
      displayPreviousValues && displayPreviousValues.showHomeworkProgressWhole !== undefined
      ? displayPreviousValues.showHomeworkProgressWhole
      : undefined

    if (showHomeworkProgressWhole !== undefined) {
      this.showWhole = showHomeworkProgressWhole;
    }

    // 教材パッケージ一覧のレスポンスキャッシュをクリア
    await funcs.responseCache.clearMatch("/accounts/products/books");
    
    // 宿題データのロード
    await this.loadHwData(true);
    this.isLoading = true;

    if (this.homeworkProgressItems.length > 0) {
        this.deleteTransferStudentList(this.params.homeworkKey)

      this.homeworkProgressItems.forEach((item) => {
        switch (item.status) {
          case handedStatus.handed:
            // グループから外されている場合
            if (item.accountName == "―") {
              // 転校した生徒のIDをStoreに保存
              this.addTransferStudentList({
                homeworkKey: this.params.homeworkKey,
                studentId: item.accountId,
              });
            }
            break;
          case handedStatus.handChecked:
            // 確認した生徒のIDをStoreに保存
            this.addCheckedStudentProgress({
              homeworkKey: this.params.homeworkKey,
              studentId: item.accountId,
            });
            break;
          case handedStatus.returned:
            // 確認した生徒のIDをStoreに保存
            this.addCheckedStudentProgress({
              homeworkKey: this.params.homeworkKey,
              studentId: item.accountId,
            });
            break;
          case handedStatus.returnChecked:
            // 確認した生徒のIDをStoreに保存
            this.addCheckedStudentProgress({
              homeworkKey: this.params.homeworkKey,
              studentId: item.accountId,
            });
            break;

          default:
            break;
        }
      });
    }
    
    // 共有グループ用の課題において宿題編集の権限があるかどうかの判定
    if(this.params.groupType == "share" && this.params.editor == "onlyme"){
      if(this.loginUserInfo.accountId == this.homeworkDetails.createTeacher){
        this.editingPermission = true;
      }else{
        this.editingPermission = false;
      }
    }


    // 学校共通設定の取得
    this.schoolInfo = await this.getSchoolInfo();

    this.isLoading = false;
    console.timeEnd('mounted');
    
    // 提出データ詳細画面の準備
    this.$refs.tableHomeworkProgressStudents.setupInit();
  },

  beforeDestroy() {
    window.document.removeEventListener('click', this.closeSubMenu)
  },

  methods: {
    ...mapActions("gradeStamp", ["saveGradeStamps"]),
    ...mapActions("homework", ["addCheckedStudentProgress", "addTransferStudentList"]),
    ...mapMutations("homework", ["setTemporaryProgressParams", "deleteTransferStudentList"]),

    async loadHwData(isLoadAccountInfo) {
      this.isLoading = true;

      // 宿題進捗情報を取得
      const promises = [this.getHomeworkProgress()];
      // グループIDに紐づくアカウントが所有している教材パッケージの一覧を取得
      if (isLoadAccountInfo) {
        promises.push(this.getAccountWithBookItems());
      }
      await Promise.all(promises);

      // 生徒情報をリストに反映
      try {
        // 生徒情報を取得
        await this.getStudentsInfo(this.params.homeworkKey, this.params.schoolId);
      } catch (error) {
        console.log(error);
      } finally {
        // 生徒情報をリストに反映
        homeworkRepository.setStudentsInfo(
          this.homeworkHeaderItems,
          this.homeworkProgressItems,
          this.groupMemberItems,
          this.childQuestionRateItems,
          this.homeworkDetails
        );
        // ステータス更新
        this.updateHandedStatus();
        this.isLoading = false;
      }

      // 宿題情報をノート情報に反映
      for (const studentData of this.homeworkProgressItems) {
        for (const note of studentData.noteItems) {
          if (note.noteType !== noteType.writeInfo) {
            continue;
          }
          note.homework_syubetu_kbn = this.homeworkDetails.homeworkInfo.find(hw => hw.homeworkEdaNo === note.homeworkEdaNo).kind;
        }
      }
    },
    // 返却押下時保存処理
    async onClickReturnOf(studentIndex, studentIndexList, evalCode, evalManualSetFlg, stampCode, comment, noteWriteInfoList, callback, isBulk = false) {
      try {
        if (isBulk) {
          this.isLoading2 = true;
        }
        const stamp = this.stamps.find((s) => s.code === stampCode);
        let studentAccountId = ""
        let studentAccountIdList = []
        if (studentIndexList && studentIndexList.length > 0) {
          studentIndexList.forEach((item) => {
            studentAccountIdList.push(this.homeworkProgressItems[item].accountId)
            this.homeworkProgressItems[item].stamp = stamp.code;
          })
        } else {
          studentAccountId = this.homeworkProgressItems[studentIndex].accountId
          this.homeworkProgressItems[studentIndex].stamp = stamp.code;
        }
        try {
          await homeworkRepository.updateHomeworkProgressByStudent(
            studentAccountId,
            studentAccountIdList,
            this.params.schoolId,
            this.params.homeworkKey,
            1,
            this.loginUserInfo.accountId,
            evalCode,
            evalManualSetFlg,
            stampCode,
            comment,
            [],
            noteWriteInfoList,
            this.loginUserInfo.lmsApiToken,
            this.systemDate,
          );
          if (callback) {
            callback(true);
          }
        } catch (error) {
          console.log(error);
          if (this.checkExlusive(error)) {
            // 競合・排他エラーの場合
            if (callback) {
              callback(false);
            }
            return;
          }
          this.$router.push({
            name: "APIError",
            params: { status: error.status },
          });
        }
      } finally {
        if (isBulk) {
          this.isLoading2 = false;
        }
      }
    },
    // やり直し押下時
    async onClickSendBack(studentIndex, studentIndexList, evalCode, evalManualSetFlg, stampCode, comment, noteWriteInfoList, callback, isBulk = false) {
      try {
        if (isBulk) {
          this.isLoading2 = true;
        }
        const stamp = this.stamps.find((s) => s.code === stampCode);
        let studentAccountId = ""
        let studentAccountIdList = []
        if (studentIndexList && studentIndexList.length > 0) {
          studentIndexList.forEach((item) => {
            studentAccountIdList.push(this.homeworkProgressItems[item].accountId)
            this.homeworkProgressItems[item].stamp = stamp.code;
          })
        } else {
          studentAccountId = this.homeworkProgressItems[studentIndex].accountId
          this.homeworkProgressItems[studentIndex].stamp = stamp.code;
        }
        try {
          await homeworkRepository.updateHomeworkProgressByStudent(
            studentAccountId,
            studentAccountIdList,
            this.params.schoolId,
            this.params.homeworkKey,
            2,
            this.loginUserInfo.accountId,
            evalCode,
            evalManualSetFlg,
            stampCode,
            comment,
            [],
            noteWriteInfoList,
            this.loginUserInfo.lmsApiToken,
            this.systemDate,
          );

          if (callback) {
            callback(true);
          }
        } catch (error) {
          console.log(error);
          if (this.checkExlusive(error)) {
            // 競合・排他エラーの場合
            if (callback) {
              callback(false);
            }
            return;
          }
          this.$router.push({
            name: "APIError",
            params: { status: error.status },
          });
        }        
      } finally {
        if (isBulk) {
          this.isLoading2 = false;
        }
      }
    },
    // 登録押下時
    async onClickSendSave(studentIndex, studentIndexList, evalCode, evalManualSetFlg, stampCode, comment, noteWriteInfoList, callback, isBulk = false) {
      try {
        if (isBulk) {
          this.isLoading2 = true;
        }
        const stamp = this.stamps.find((s) => s.code === stampCode);
        let studentAccountId = ""
        let studentAccountIdList = []
        if (studentIndexList && studentIndexList.length > 0) {
          studentIndexList.forEach((item) => {
            studentAccountIdList.push(this.homeworkProgressItems[item].accountId)
            this.homeworkProgressItems[item].stamp = stamp.code;
          })
        } else {
          studentAccountId = this.homeworkProgressItems[studentIndex].accountId
          this.homeworkProgressItems[studentIndex].stamp = stamp.code;
        }
        try {
          await homeworkRepository.updateHomeworkProgressByStudent(
            studentAccountId,
            studentAccountIdList,
            this.params.schoolId,
            this.params.homeworkKey,
            3,
            this.loginUserInfo.accountId,
            evalCode,
            evalManualSetFlg,
            stampCode,
            comment,
            [],
            noteWriteInfoList,
            this.loginUserInfo.lmsApiToken,
            this.systemDate,
          );

          if (callback) {
            callback(true);
          }
        } catch (error) {
          console.log(error);
          if (this.checkExlusive(error)) {
            // 競合・排他エラーの場合
            if (callback) {
              callback(false);
            }
            return;
          }
          this.$router.push({
            name: "APIError",
            params: { status: error.status },
          });
        }
      } finally {
        if (isBulk) {
          this.isLoading2 = false;
        }
      }
    },
    async reloadStudentsInfo() {
      await this.loadHwData(false);
    },
    checkExlusive(error) {
      return error.status === 409 || error.status === 550;
    },
    async getAccountWithBookItems() {
      try {
        const responce = await accountRepository.getSukenAccountWithBooks(
          this.params.groupId,
          this.loginUserInfo.accountId,
          this.params.curriculum,
          this.loginUserInfo.sessionToken,
          this.paramApiSettingItems.find(v => v.itemName === 'accountsProductsBooks').items
        );
        this.studentItems = responce.accountItems;
        this.bookItems = responce.bookItems;
      } catch (error) {
        console.error(error);
      }
    },
    async getHomeworkProgress() {
      // 宿題進捗情報を取得
      let promise = null;
      try {
        promise = await homeworkRepository.getHomeworkProgressList(
          this.loginUserInfo.accountId,
          this.loginUserInfo.schoolId,
          this.params.homeworkKey,
          this.params.deadlineDate,
          this.loginUserInfo.lmsApiToken,
          this.paramApiSettingItems.find(v => v.itemName === 'MSHW0403').items
        );
      } catch (error) {
        console.error(error);
        this.$router.push({ name: "APIError", params: { status: error.status } });
      }

      // システム日付
      this.systemDate = promise.systemDate;

      // ヘッダー情報
      // 親問題単位で扱えるように加工する
      const headers = promise.headerItems;
      const childQuestionRates = promise.childQuestionRateItems;
      const headerItems = [];
      const childQuestionRateItems = [];
      headers.forEach((header, index) => {
        const headerIndex = Number(header.split("\n")[0]) - 1;
        if (!headerItems[headerIndex]) {
          headerItems[headerIndex] = [];
          childQuestionRateItems[headerIndex] = [];
        }
        headerItems[headerIndex].push(header);
        childQuestionRateItems[headerIndex].push(childQuestionRates[index]);
      });
      this.homeworkHeaderItems = headerItems;

      // 子問題別レート情報
      this.childQuestionRateItems = childQuestionRateItems;

      // 進捗詳細情報
      this.homeworkProgressItems = promise.progressItems;

      // 先生コメント
      this.teacherComment = promise.teacherComment;

      // 宿題評価設定
      this.homeworkEvalSet = promise.homeworkEvalSet;

      // 配信開始日
      this.haishinStartDate = this.params.startDate;

      // 採点スタンプ画像情報が無い場合は、S3 より取得する
      if (!this.stamps.length) {
        // 採点スタンプ画像を取得
        homeworkRepository
          .getGradeStamps(this.gradeStampItems)
          .then((response) => {
            // store に登録
            this.saveGradeStamps(response);
          });
      }
    },
    /**
     * 生徒情報を取得する処理
     */
    async getStudentsInfo(homeworkKey, schoolId) {
      const promise = await homeworkRepository.getStudentsInfo(
        this.loginUserInfo.accountId,
        schoolId,
        homeworkKey,
        this.loginUserInfo.lmsApiToken,
        this.loginUserInfo.sessionToken,
        this.nameCurriculums,
        this.paramApiSettingItems,
      );
      this.groupMemberItems = promise.groupMemberItems;
      this.homeworkDetails = promise.homeworkDetails;
      this.createTeacher = promise.homeworkDetails.createTeacher;
    },
    /**
     * 提出ステータスを更新
     */
    updateHandedStatus() {
      const checkedIdList =
        this.checkedStudentList[this.params.homeworkKey] || [];
      this.homeworkProgressItems.forEach((item) => {
        // 「提出済み」かつStoreに確認済み情報が保持されていればステータスを「確認済み」とする
        if (
          item.status === handedStatus.handed &&
          checkedIdList.some((x) => x === item.accountId)
        ) {
          this.$set(item, "status", handedStatus.handChecked);
        }
      });

      // 表示用進捗詳細情報リストを取得
      this.viewProgressItems = homeworkRepository.getViewProgressItems(
        this.homeworkProgressItems,
        this.nameHomeworkStatusItems,
        this.homeworkHeaderItems,
        this.progressParams.homeworkKey,
        this.$t("labels.expired")
      );
    },
    /**
     * 生徒のデータを確認した
     */
    onCheckedStudentData(accountId) {
      const homeworkProgressItem = this.homeworkProgressItems.find((item) => item.accountId == accountId);
      if (
        homeworkProgressItem.status == handedStatus.notProgress ||
        homeworkProgressItem.status == handedStatus.sendBack || 
        homeworkProgressItem.status == handedStatus.sendBackCheck
      ) {
        // 未提出・やり直しの場合は確認済にしない
        return;
      }
      // 確認した生徒のIDをStoreに保存
      this.addCheckedStudentProgress({
        homeworkKey: this.params.homeworkKey,
        studentId: homeworkProgressItem.accountId,
      });
      // ステータス更新
      this.updateHandedStatus();
    },
    /*
     * 生徒名の表示フラグ変更
     */
    onChangedShowStudentNameCheckBox(isShow) {
      this.showStudentName = isShow;
      this.$refs.showStudentNameCheckBox.setChecked(isShow);
    },
    /** 学校共通設定情報取得 */
    async getSchoolInfo(){
      try {
        return await homeworkRepository.getSchoolInfo(
          this.loginUserInfo.accountId,
          this.loginUserInfo.schoolId,
          this.loginUserInfo.lmsApiToken,
          ["COMMENT"]
        );
      } catch(error) {
        console.error(error);
        throw error;
      }
    },
    /**
     * Excelファイルエクスポート
     */
    async onClickExport() {
      this.showSubMenu = false;
      this.isLoading = true;

      homeworkRepository.outputProgressInfo(
        this.params,
        this.homeworkHeaderItems,
        this.childQuestionRateItems,
        this.viewProgressItems,
      );

      this.isLoading = false;
    },
    /**
     * 宿題評価設定
     */
     async onClickHomeworkEvalSet() {
      console.log(this.homeworkEvalSet)
      if(this.editingPermission){
        this.showSubMenu = false
        this.showHomeworkEvalSetModal = true
      }else{
        // 編集権限がない場合はモーダルを表示
        this.showNoEditPermission = true;
      }
    },
    closeModalHomeworkEvalSet() {
      this.showHomeworkEvalSetModal = false
    },
    // 設定押下時
    async onClickEvalSet(evalSet) {
      this.isLoading = true
      try {
        await homeworkRepository.updateHomeworkEvalSet(
          this.loginUserInfo.lmsApiToken,
          this.loginUserInfo.schoolId,
          this.loginUserInfo.accountId,
          this.params.homeworkKey,
          evalSet,
        );
        await this.loadHwData(false)
      } catch (error) {
        console.log(error)
        this.isLoading = false
        this.$router.push({
          name: "APIError",
          params: { status: error.status },
        });
      } finally {
        this.showHomeworkEvalSetModal = false
      }
    },

    /** 全体の表のオープンハンドラ */
    async tableHomeworkProgressWholeOpenedHandler() {
      this.showWhole = true;
      // テーブルを下のほうにスクロールしている場合でも全体表が見えるようにスクロール位置を変更
      this.$refs.containerScroller.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },

    /** 全体の表のクローズハンドラ */
    async tableHomeworkProgressWholeClosedHandler() {
      this.showWhole = false;
    },
    /**
     * 提出状況セレクトボックス選択
     */
    onSelectSubmissionStatus: async function (event) {
      this.selectedSubmissionStatus = event
      if (event != 0) {
        this.showBulkCheck = true
        await this.$nextTick()
        this.$refs.tableHomeworkProgressStudents.selectBulkReturnStatus(this.selectedSubmissionStatus)
      } else {
        this.showBulkCheck = false
      }
    },
    /**
     * 宿題の一括返却切替
     */
    onClickBulkMode: function () {
      if(this.editingPermission){
        this.showBulkCheck = false
        this.isBulkMode = !this.isBulkMode
      }else{
        // 編集権限がない場合はモーダルを表示
        this.showNoEditPermission = true;
      }
    },
    /**
     * 宿題の一括返却
     */
    onClickBulkReturn: function () {
      if (this.bulkReturnDisabled) {
        return
      }
      this.$refs.tableHomeworkProgressStudents.onClickBulkReturn()
    },
    closeModalBulkReturn() {
      this.showBulkCheck = false
      this.isBulkMode = false
    },
    /**
     * サブメニュー表示切り替え
     */
    toggleSubFunction($event) {
      $event.stopPropagation()
      this.showSubMenu = !this.showSubMenu
    },
    closeSubMenu() {
      this.showSubMenu = false
    },
  },
};
</script>

<style lang="scss" scoped>
.homework-progress {
  overflow: visible;

  ::v-deep .title-homework {
    margin-bottom: 0;
  }

  .container-scroller {
    width: 100%;
    height: calc(100% - 142px);
    overflow: auto auto;
  }

  .sub-functions-area {
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    margin-right: 1.0rem;
    .sub-functions {
      position: absolute;
      font-size: var(--bs-body-font-size);
      .button-leader {
        cursor: pointer;
        width: 30px;
        height: 30px;
        background-color: var(--bs-table-header);
        color: var(--bs-layout-theme);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: bold;
      }

      .sub-menu-list {
        position: absolute;
        width: 160px;
        height: auto;
        top: 38px;
        right: 0px;
        z-index: 20;
        text-align: left;

        .sub-menu-item {
          cursor: pointer;
          font-weight: 900;
          padding: 10px 20px;
          background-color: #FFF;
          border-left: solid var(--bs-layout-theme) 2px;
          border-right: solid var(--bs-layout-theme) 2px;
          border-bottom: solid var(--bs-layout-theme) 1px;

          &:first-child {
            border-top: solid var(--bs-layout-theme) 2px;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
          }

          &:last-child {
            border-bottom: solid var(--bs-layout-theme) 2px;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
          }

          &:hover {
            background-color: var(--bs-table-row);
          }

          &.no-editing-permission{
            color: rgba(128, 128, 128, 0.3);
            cursor: auto!important;
          }
        }
      }
    }
  }
}
.homework-name {
  font-size: 120%;
  font-weight: bold;
}

.bulk-return {
  display: flex;
  height: auto;
  // position: relative;

  .bulk-return-button {
    &.hide {
      visibility: hidden;
    }
  }

  .bulk-return-menu {
    position: absolute;
    display: flex;
    align-items: center;
    background-color: #fff;

    .bulk-return-menu-frame {
      position: relative;
      display: flex;
      border-radius: 6px;
      display: flex;
      align-items: center;

      &> * {
        padding-right: 6px;
      }

      .bulk-return-menu-title{
        position: absolute;
        height: 20px;
        top: -10px;
        left: 20px;
        padding: 0 10px;
        background-color: #fff;
      }
    }
  }
}

</style>
