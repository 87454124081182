var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "badge-status" }, [
    _c("span", { staticClass: "badge", style: _vm.statusStyle }, [
      _vm._v(_vm._s(_vm.labelText))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }