<template>
  <div class="button-progress">
    <button
      type="button"
      class="btn"
    >
      <IconProgress
        width="25px"
        height="25px"
      />
    </button>
  </div>
</template>

<script>
import IconProgress from "@/components/atoms/icons/IconProgress.vue"

export default {
  name: "ButtonProgress",
  components: {
    IconProgress,
  },
}
</script>