<template>
  <footer class="footer">
    <div class="text-end">
      {{ $t("labels.copyright") }}
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
}
</script>
