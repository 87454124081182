var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "icon-camera" }, [
    _c(
      "svg",
      {
        attrs: {
          id: "b",
          xmlns: "http://www.w3.org/2000/svg",
          x: "0px",
          y: "0px",
          width: _vm.width,
          height: _vm.height,
          viewBox: "0 0 324.46 231.9"
        }
      },
      [
        _c("g", { attrs: { id: "c" } }, [
          _c("rect", {
            staticClass: "f",
            staticStyle: { fill: "#fff", "stroke-width": "0px" },
            attrs: { x: "10.02", y: "9.55", width: "235.54", height: "186.21" }
          }),
          _c("path", {
            staticClass: "e",
            staticStyle: { fill: "#EE7C2F", "stroke-width": "0px" },
            attrs: {
              d: "M0,0v209h253.79V0H0ZM233.88,189.09H19.91V19.9h213.98v169.19Z"
            }
          }),
          _c("path", {
            staticClass: "e",
            staticStyle: { fill: "#EE7C2F", "stroke-width": "0px" },
            attrs: {
              d:
                "M161.65,80.57c-2.06-3.12-5.54-4.99-9.28-4.99-3.74,0-7.22,1.89-9.27,5.01l-35.8,54.48-19.76-22.39c-2.29-2.6-5.66-3.98-9.12-3.73-3.46.24-6.6,2.09-8.5,4.98l-33.16,50.53h180.26l-55.37-83.88Z"
            }
          }),
          _c("path", {
            staticClass: "e",
            staticStyle: { fill: "#EE7C2F", "stroke-width": "0px" },
            attrs: {
              d:
                "M86.73,91.97c12.44,0,22.53-10.09,22.53-22.53s-10.09-22.53-22.53-22.53-22.53,10.09-22.53,22.53c0,12.45,10.09,22.53,22.53,22.53Z"
            }
          })
        ]),
        _c("g", { attrs: { id: "d" } }, [
          _c("rect", {
            staticClass: "f",
            staticStyle: { fill: "#fff", "stroke-width": "0px" },
            attrs: {
              x: "150.75",
              y: "115.99",
              width: "163.26",
              height: "107.41"
            }
          }),
          _c("polygon", {
            staticClass: "f",
            staticStyle: { fill: "#fff", "stroke-width": "0px" },
            attrs: {
              points:
                "198.87 90.21 174.24 127.73 287.41 127.73 265.32 90.21 198.87 90.21"
            }
          }),
          _c("path", {
            staticClass: "e",
            staticStyle: { fill: "#EE7C2F", "stroke-width": "0px" },
            attrs: {
              d:
                "M232.02,150.18c-10.29,0-18.63,8.41-18.63,18.79s8.34,18.79,18.63,18.79,18.63-8.41,18.63-18.79-8.34-18.79-18.63-18.79Z"
            }
          }),
          _c("path", {
            staticClass: "e",
            staticStyle: { fill: "#EE7C2F", "stroke-width": "0px" },
            attrs: {
              d:
                "M319.18,116.67c-3.24-3.28-7.81-5.35-12.8-5.35h-19.57c-1.57,0-3.05-.94-3.72-2.46l-5.86-13.12c-2.9-6.51-9.35-10.74-16.5-10.74h-57.39c-7.15,0-13.6,4.23-16.51,10.73h0s-5.86,13.13-5.86,13.13c-.68,1.52-2.15,2.47-3.72,2.46h-19.57c-4.99,0-9.56,2.07-12.81,5.35-3.25,3.27-5.27,7.85-5.27,12.83v84.23c0,4.98,2.02,9.56,5.27,12.83,3.25,3.28,7.83,5.35,12.81,5.34h148.72c4.99,0,9.56-2.06,12.8-5.34,3.26-3.27,5.28-7.85,5.28-12.83v-84.23c0-4.98-2.02-9.56-5.28-12.83ZM310.48,213.73c0,1.2-.46,2.21-1.22,2.99-.78.76-1.75,1.2-2.88,1.21h-148.72c-1.14,0-2.12-.45-2.88-1.21-.76-.78-1.22-1.79-1.22-2.99v-84.23c0-1.2.46-2.21,1.22-2.99.77-.77,1.74-1.2,2.88-1.21h19.57c7.14,0,13.6-4.24,16.5-10.75l5.85-13.12c.69-1.52,2.16-2.46,3.75-2.46h57.39c1.59,0,3.06.94,3.74,2.45l5.86,13.13c2.89,6.51,9.35,10.74,16.49,10.74h19.57c1.13,0,2.1.44,2.88,1.21.76.78,1.22,1.8,1.22,2.99v84.23h0Z"
            }
          }),
          _c("path", {
            staticClass: "e",
            staticStyle: { fill: "#EE7C2F", "stroke-width": "0px" },
            attrs: {
              d:
                "M232.02,127.73c-11.31,0-21.58,4.64-28.96,12.1-7.4,7.46-11.98,17.79-11.97,29.15,0,11.36,4.58,21.7,11.97,29.15,7.39,7.46,17.65,12.1,28.96,12.1s21.58-4.64,28.96-12.1c7.4-7.45,11.98-17.79,11.97-29.15,0-11.36-4.58-21.69-11.97-29.15-7.39-7.46-17.66-12.1-28.96-12.1ZM253.34,190.55c-5.48,5.52-12.98,8.91-21.32,8.91s-15.84-3.39-21.32-8.91c-5.47-5.52-8.85-13.12-8.85-21.57,0-8.45,3.39-16.04,8.85-21.57,5.48-5.52,12.98-8.91,21.32-8.91,8.33,0,15.84,3.39,21.32,8.91,5.47,5.53,8.85,13.12,8.86,21.57,0,8.45-3.38,16.05-8.86,21.57Z"
            }
          }),
          _c("rect", {
            staticClass: "e",
            staticStyle: { fill: "#EE7C2F", "stroke-width": "0px" },
            attrs: { x: "281.2", y: "137.38", width: "12.42", height: "12.42" }
          })
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }