<template>
  <div class="button-microsoft-login">
    <button
      type="button"
      class="btn"
      :style="buttonStyle"
    >
      <div class="d-flex flex-row align-item-center">
        <IconMicrosoftLoginLogo class="col-12" />
      </div>
    </button>
  </div>
</template>

<script>
import IconMicrosoftLoginLogo from "@/components/atoms/icons/IconMicrosoftLoginLogo.vue"

export default {
  name: "ButtonMicrosoftLogin",
  components: {
    IconMicrosoftLoginLogo,
  },
  props: {
    width: {
      type: String,
      default: function () {
        return ''
      },
    },
  },

  computed: {
    buttonStyle: function () {
      return {
        width: `${this.width}`,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  background-color: transparent;
  border: none;
  padding: none;
  outline: none;
}
.button-microsoft-login > button{
  padding: 0;
}
</style>