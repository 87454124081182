<template>
  <div class="button-open-help">
    <a
      :href="url"
      target="_blank"
    >
      <IconHelp
        width="25px"
        height="25px"
      />
    </a>
  </div>
</template>

<script>
import IconHelp from "@/components/atoms/icons/IconHelp.vue"

export default {
  name: "ButtonOpenHelp",
  components: {
    IconHelp,
  },
  props: {
    url: {
      type: String,
      default: function () {
        return ''
      },
    },
  },
}
</script>
