<template>
  <div class="modal-select-question-type">
    <transition
      name="modal"
      appear
    >
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container-outer">
            <div class="modal-container p-4">
              <h3>更新情報</h3>
              <!-- eslint-disable-next-line -->
              <div class="modal-text" v-html="updateInfoText"></div>
              <div class="row h-25 gx-5 justify-content-center">
                <div class="col-auto">
                  <ButtonBorderCircleMedium
                    :label-name="$t('buttons.close')"
                    color="layout-theme-light"
                    :width="buttonWidth"
                    :label-color="colorLayoutTheme"
                    :border-color="colorLayoutTheme"
                    @click.native="onClickCloseUpdateInfo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/**
 * 更新情報モーダル
 */
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"

export default {
  name: "ModalUpdateInfo",

  components: {
    ButtonBorderCircleMedium,
  },
  
  props: {
    updateInfoText: {
      type: String,
      default: function () {
        return ""
      },
    },
  },

  data: () => ({
    colorLayoutTheme: "#ff7f27",
    buttonWidth: "180px"
  }),

  methods: {
    async onClickCloseUpdateInfo() {
      return new Promise(resolve => 
        resolve(this.$emit("close-update-info"))
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-select-question-type {
  position: absolute;

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-wrapper {
      display: table-cell;
      vertical-align: middle;

      .modal-container-outer {
        margin-left: auto;
        margin-right: auto;

        .modal-default-button {
          width: 51px;
          margin-left: auto;
        }

        .modal-container {
          background-color: #fff;
          border: 2px solid rgba(0, 0, 0, 0.7);
          border-radius: 10px;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
          transition: all 0.3s ease;
          text-align: center;

          h3 {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 1.5rem;
          }

          .modal-text {
            max-width: 800px;
            width: 60vw;
            text-align: left;
            overflow-wrap: break-word;
            max-height: 55vh;
            overflow-y: auto;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
</style>
