<template>
  <div class="chat-area">
    <div class="chat-cotents-wrapper">
      <ul
        ref="chatView"
        class="container chat-view"
      >
        <li
          v-if="displayTeacherComment && !disableComment"
          class="teacher text-end"
        >
          <p>先生</p>
          <span>{{ teacherComment }}</span>
        </li>
        <li
          v-if="haishinStartDate"
          class="text-fixed"
        >
          {{ haishinStartDate }} 宿題を配信しました
        </li>
        <li
          v-if="comments[0] && comments[0].teacher_comment && comments[0].comment_kbn === 1 && !disableComment"
          class="teacher text-end"
        >
          <p>先生</p>
          <span>{{ comments[0].teacher_comment }}</span>
        </li>
        <li
          v-if="comments[0] && comments[0].update_date && comments[0].comment_kbn === 1"
          class="text-fixed"
        >
          {{ convertValue.toDateTimeStringSeparator(comments[0].update_date) }} 宿題を通知しました
        </li>
        <li
          v-if="displayStudentComment && !disableComment"
          class="student text-start"
        >
          <p>生徒</p>
          <span>{{ studentComment }}</span>
        </li>
        <li
          v-if="submitDate"
          class="text-fixed"
        >
          {{ convertValue.toDateTimeStringSeparator(submitDate) }} 宿題が提出されました
        </li>
        <template v-for="(comment, index) in comments">
          <li
            v-if="comment.teacher_comment && comment.comment_kbn === 0 && !disableComment"
            :key="`teacher-comment-${index}`"
            class="teacher text-end"
          >
            <p>先生</p>
            <span>{{ comment.teacher_comment }}</span>
          </li>
          <li
            v-if="comment.resubmit_instract_date && comment.comment_kbn === 0"
            :key="`resubmit-instract-date-${index}`"
            class="text-fixed"
          >
            {{ convertValue.toDateTimeStringSeparator(comment.resubmit_instract_date) }} 宿題のやり直しを指示しました
          </li>
          <li
            v-if="comment.student_comment && !disableComment"
            :key="`student-comment-${index}`"
            class="student text-start"
          >
            <p>生徒</p>
            <span>{{ comment.student_comment }}</span>
          </li>
          <li
            v-if="comment.resubmit_date"
            :key="`resubmit-date-${index}`"
            class="text-fixed"
          >
            {{ convertValue.toDateTimeStringSeparator(comment.resubmit_date) }} 宿題が提出されました
          </li>
        </template>
        <template
          v-if="displayReturnComment"
        >
          <li
            v-if="returnComment && !disableComment"
            class="teacher text-end"
          >
            <p>先生</p>
            <span>{{ returnComment }}</span>
          </li>
          <li
            v-if="returnDate"
            class="text-fixed"
          >
            {{ convertValue.toDateTimeStringSeparator(returnDate) }} 宿題を返却しました
          </li>
        </template>
      </ul>
      <!-- コメント -->
      <div
        class="d-flex position-relative"
        :class="{ 'no-editing-permission':!editingPermission}"
      >
        <p class="comment-area">
          <textarea
            v-model="message"
            :placeholder="$t(disableComment ? 'placeholder.disableComment' : 'placeholder.returnComment')"
            aria-label="chat"
            class="form-control h-30"
            maxlength="150"
            :readonly="disableComment"
            @blur="onChangeMessage()"
          />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { handedStatus } from "@/constant/homework"
import convertValue from "@/repositories/convert-value"

export default {
  name: "ChatArea",

  props: {
    teacherComment: {
      type: String,
      default: function () {
        return ""
      },
    },
    haishinStartDate: {
      type: String,
      default: function () {
        return ""
      },
    },
    lastSubmitAddDate: {
      type: String,
      default: function () {
        return ""
      },
    },
    submitDate: {
      type: String,
      default: function () {
        return ""
      },
    },
    studentComment: {
      type: String,
      default: function () {
        return ""
      },
    },
    comments: {
      type: Array,
      default: function () {
        return[]
      },
    },
    status: {
      type: Number,
      default: function () {
        return 0
      },
    },
    returnComment: {
      type: String,
      default: function () {
        return ""
      },
    },
    initMessage: {
      type: String,
      default: function () {
        return ""
      },
    },
    studentIndex: {
      type: Number,
      default: function () {
        return 0
      },
    },
    returnDate: {
      type: String,
      default: function () {
        return ""
      },
    },
    schoolInfo: {
      type: Object,
      default: function () {
        return {
          COMMENT: undefined,
        };
      },
    },
    editingPermission: {
      type: Boolean,
      default: function () {
        return true;
      }
    },
  },
  data: () => ({
    message: "",
    initStatus: "",
    convertValue: convertValue,
  }),
  computed: {
    displayStudentComment() {
      if (!this.studentComment || this.studentComment === "undefined") {
        return false
      } else {
        return true
      }
    },
    displayTeacherComment() {
      if (!this.teacherComment || this.teacherComment === "undefined") {
        return false
      } else {
        return true
      }
    },
    displayReturnComment() {
      if (this.initStatus === handedStatus.returned || this.initStatus === handedStatus.returnChecked) {
        return true
      } else {
        return false
      }
    },
    /** コメント無効化？ */
    disableComment() {
      return this.schoolInfo.COMMENT === '0';
    },
  },
  watch: {
    studentIndex() {
      if (!this.disableComment) {
        // コメント有効時のみ初期メッセージを設定
        this.message = this.initMessage
      }
      this.initStatus = this.status
      // コメント欄のスクロール位置を設定
      this.setCommentScrollPosition()
    },
  },
  mounted() {
    if (!this.disableComment) {
      // コメント有効時のみ初期メッセージを設定
      this.message = this.initMessage
    }
    this.initStatus = this.status
    // コメント欄のスクロール位置を設定
    this.setCommentScrollPosition()
  },
  methods: {
    // コメント変更時処理
    onChangeMessage() {
      if (this.disableComment) {
        // コメント無効化時はメッセージ変更は無視
        return;
      }
      this.$emit("on-change-message", this.message)
    },
    // チャット欄のスクロール位置を設定
    setCommentScrollPosition() {
      this.$nextTick(() => {
        // 初期表示でチャット欄のスクロール位置を最終コメントの一番上とする
        // ただし、コメントがない場合は固定コメントを対象とする
        const comments = this.$el.querySelectorAll(".container.chat-view>li");
        let lastComment = null;
        for(let i = comments.length - 1; i >= 0; i--){
            const comment = comments[i];
            if(comment.classList.contains("text-fixed") && lastComment){
              break;
            }
            lastComment = comment;
        }
        if(lastComment && lastComment.parentNode){
          lastComment.parentNode.scrollTop = lastComment.offsetTop
        }
      });
    },
  },
}
</script>

<style lang="scss" scoped>
ul {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
  }
}
p {
  margin: 0;
}
.chat-area {
  height: 100%;
}
.chat-cotents-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 0;
  background-color: #fff;
}
.chat-view {
  flex-grow: 1;
  min-height: 0;
  width: initial;
  height: 160px;
  overflow-y: scroll;
  background: #fdf1e9;
  list-style: none;
  margin: 0;
  padding: 1em 2em 1rem 2em;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom-style: none;
  border-radius: 0.25rem 0.25rem 0 0;
  @media (max-width: 768px) {
    height: 180px;
  }
  @media (min-height: 1080px) {
    height: 176px;
  }
  li {
    //コメントエリア
    span {
      position: relative;
      display: inline-block;
      text-align: left;
      padding: 0.5em;
      background-color: #fff;
      border-radius: 10px;
      border: 1px solid #b3b3b3;
      white-space: pre-wrap;

      &::before {
        content: "";
        position: absolute;
        display: block;
        top: 8px;
        right: -18px;
        width: 18px;
        height: 18px;
        background: url(../../assets/images/fukidasitip.svg) no-repeat;
        background-size: contain;
        // ↓この指定だと、iOSの古いSafariで正しく反転されなくなる
        //transform: scale(-100%, 1);
        transform: scale(-1, 1);
      }
    }
  }
  .student {
    span {
      margin-right: 0;
      &::before {
        transform: scale(1);
        right: auto;
        left: -18px;
        transform: scale(100%, 1);
      }
    }
  }
  .text-fixed {
    font-size: 14px;
    color: #444;
    padding: 4px;
  }
}

.no-editing-permission{
  pointer-events: none;
  textarea, &>div{
    background-color: rgba(128, 128, 128, 0.3);
  }
}

.comment-area {
  flex-grow: 1;
}

// コメント入力エリア
.form-control {
  resize: none;
  border-radius: 1px;
  width: 100%;
}
.bg-eval-manual {
  background: #ffffcc;
}
</style>