<template>
  <span class="icon-caution">
    <svg
      id="_x32_"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      :width="width"
      :height="height"
      viewBox="0 0 512 512"
      style="enable-background:new 0 0 512 512;"
      xml:space="preserve"
    >
      <path
        d="M504,444.4L274.3,46.5c-8.1-14.1-28.5-14.1-36.6,0L8,444.4c-8.1,14.1,2,31.7,18.3,31.7h459.5
        C502,476.1,512.2,458.5,504,444.4z"
      />
      <g>
        <path
          class="st0"
          d="M506.5,446.3L274.5,44.4c-8.2-14.2-28.8-14.2-37,0L5.5,446.3c-8.2,14.2,2.1,32,18.5,32h464.1
          C504.5,478.3,514.8,460.5,506.5,446.3z M276.8,422c0,2.7-9.3,4.8-20.8,4.8c-11.5,0-20.8-2.2-20.8-4.8v-33.3c0-2.7,9.3-4.8,20.8-4.8
          c11.5,0,20.8,2.2,20.8,4.8V422z M273.4,344.2c0,0.1,0.1,0.2,0.1,0.3c0,4.3-7.8,7.8-17.4,7.8c-9.6,0-17.4-3.5-17.4-7.8
          c0-0.1,0.1-0.2,0.1-0.3l-10.8-139.1c0-4.3,12.6-7.8,28.2-7.8s28.2,3.5,28.2,7.8L273.4,344.2z"
          style="fill: #FFDE14;"
        />
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: 'IconCaution',
  props: {
    width: {
      type: String,
      default: function () {
        return '25px'
      },
    },
    height: {
      type: String,
      default: function () {
        return '25px'
      },
    },
  },

  data: () => ({
    // アイコンにタイトルを設定する場合は、iconName を表示するようにする
    // ファイル名：宿題作成_注意アイコン(icooon-mono).svg
    iconName: '注意'
  }),
}
</script>

<style lang="scss" scoped>

</style>