<template>
  <div
    class="col preview preview-wrapper"
  >
    <div class="card w-100">
      <div class="card-header">
        {{ $t("labels.preview") }}
      </div>
      <div>
        <div class="px-3 py-3 card-body preview-window">
          <div class="card overflow-auto h-100">
            <SettingPreviewImage
              :image="image"
              class="card-body"
            />
          </div>
        </div>
        <div class="card-body">
          <SettingPreviewArea
            ref="previewArea"
            :list="settingList"
            :message-display-flg="messageDisplayFlg"
            :stdb-flg="stdbFlg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SettingPreviewArea from "@/components/organisms/SettingPreviewArea.vue"
import SettingPreviewImage from "@/components/atoms/SettingPreviewImage.vue"
export default {
  name: "PreviewContainer",
  components: { SettingPreviewArea, SettingPreviewImage },
  props: {
    settingList: {
      type: Array,
      default: function () {
        return []
      },
    },
    image: {
      type: String,
      default: function () {
        return ""
      },
    },
    stdbFlg: {
      type: Boolean,
      default: function (){
        return false
      }
    },
    messageDisplayFlg: Boolean,
  },
  data: () => ({
    currentId: 1,
  }),
  methods: {
    initializeId: function () {
      this.$refs.previewArea.setCurrentId(this.currentId)
    },
  },
}
</script>
<style lang="scss" scoped>
.preview {
  height: max-content;

  .card-header {
    height: 7%;
    padding: 0.4rem;
    border-bottom: none;
    background: var(--bs-table-header);
    color: var(--bs-layout-theme);
    font-weight: bold;
  }
}
.preview-window {
  height: 200px;
}
.preview-wrapper{
  width: 80% !important;
  margin: 0 auto !important; 
  @media(max-width:1020px) {
    width: 100% !important;
  }
}
</style>
