var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "button-submit" }, [
    _c("div", { staticClass: "d-grid gap-2" }, [
      _c(
        "button",
        {
          class: ["btn"],
          style: _vm.buttonStyle,
          attrs: { type: "submit", disabled: _vm.isDisabled }
        },
        [_vm._v(" " + _vm._s(_vm.labelName) + " ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }