<template>
  <div
    id="left-menu-layout"
    class="left-menu-layout"
  >
    <Header ref="headerArea" />
    <div class="container-fluid mainArea">
      <div
        class="row h-100"
        :style="rowStyle"
      >
        <div
          ref="leftArea"
          class="col-md-3 col-lg-2 left-area"
          :class="{ modalStyle: isModalView }"
        >
          <div
            class="sidbar-fixed"
            :style="sidebarStyle"
          >
            <Sidebar
              ref="sidebarArea"
              :size="sidebarAreaSize"
              @change-modal-view="changeModalView"
            />
          </div>
        </div>
        <div
          class="col-md-9 ms-sm-auto col-lg-10 px-0 contentsArea"
          :style="contentsAreaStyle"
        >
          <div class="contentsArea-flex">
            <router-view :style="contentsStyle" />
          </div>
          <Footer
            ref="footerArea"
            :style="footerStyle"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/organisms/common/Header.vue"
import Footer from "@/components/organisms/common/Footer.vue"
import Sidebar from "@/components/organisms/common/Sidebar.vue"

export default {
  name: "LeftMenuLayout",
  components: {
    Header,
    Footer,
    Sidebar,
  },

  data: () => ({
    headerHeight: 76,
    footerHeight: 32,
    sidebarAreaSize: {
      width: 0,
      height: 0,
    },
    isModalView: false,
  }),

  computed: {
    rowStyle: function () {
      return `height: calc(100% - ${this.headerHeight}px); padding-top: ${this.headerHeight}px;`
    },
    sidebarStyle: function () {
      return `top: ${this.headerHeight}px`
    },
    contentsAreaStyle: function () {
      return `height: ${
        this.sidebarAreaSize.height - this.footerHeight
      }px; overflow-y: auto;`
    },
    contentsStyle: function () {
      return `height: ${this.sidebarAreaSize.height - this.footerHeight - 30}px;`
    },
    footerStyle: function () {
      return `height: ${this.footerHeight}px;`
    },
  },

  created: function () {
    // インスタンスを作成した後、イベントリスナに登録
    window.addEventListener("resize", this.onHandleResize, false)
  },
  mounted: function () {
    this.onHandleResize()
  },
  beforeDestroy: function () {
    // インスタンスを破棄する前に、イベントリスナから削除
    window.removeEventListener("resize", this.onHandleResize, false)
  },

  methods: {
    getHeaderHeight: function () {
      return this.$refs.headerArea.$el.getBoundingClientRect().height
    },
    getFooterHeight: function () {
      return this.$refs.footerArea.$el.getBoundingClientRect().height
    },
    getSidebarAreaWidth: function () {
      return this.$refs.sidebarArea.$el.getBoundingClientRect().width
    },
    getSidebarAreaHeight: function () {
      return this.$refs.leftArea.clientHeight
    },

    onHandleResize: function () {
      this.headerHeight = this.getHeaderHeight()
      this.footerHeight = this.getFooterHeight()
      this.sidebarAreaSize.width = this.getSidebarAreaWidth()
      this.sidebarAreaSize.height = this.getSidebarAreaHeight()
    },

    changeModalView(value) {
      this.isModalView = value;
    },

    refreshGroupList() {
      this.$refs.headerArea.onRefleshGroupList();
    },
  },
}
</script>

<style lang="scss" scoped>
.left-menu-layout {
  position: relative;
  height: 100%;
  min-height: 100%;
}

.container-fluid {
  position: absolute;
}

.left-area {
  position: relative;
  padding: 0;
  height: 100%;
}
.modalStyle {
  z-index: 45;
}

.sidbar-fixed {
  position: sticky;
  top: 0;
  height: 100%;
}

.contentsArea {
  position: relative;
}
.right-contents-area {
  position: relative;
}
.mainArea {
  height: 100%;
}
.contentsArea-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
}
footer {
  position: fixed;
  bottom: 0%;
  right: 0%;
  margin: 0;
  padding: 0;
  z-index: 10;
}
</style>
