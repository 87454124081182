var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "title-question" }, [
    _c("ul", { staticClass: "list-group list-group-horizontal" }, [
      _c(
        "li",
        { staticClass: "list-group-item border-0 pt-0 ps-0 pe-2 pb-0" },
        [_c("IconQuestion", { attrs: { width: "30px", height: "30px" } })],
        1
      ),
      _c(
        "li",
        { staticClass: "list-group-item border-0 px-0 pb-0 title-text" },
        [
          _c("h5", { staticClass: "m-0" }, [
            _vm._v(" " + _vm._s(_vm.$t("titles.question")) + " ")
          ])
        ]
      ),
      _c(
        "li",
        { staticClass: "list-group-item border-0 ps-3 pe-1 pb-0 title-help" },
        [
          _c("ButtonOpenHelp", { attrs: { url: _vm.$t("url.helps.question") } })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }