<template>
  <div
    ref="top_element"
    class="homework-filter-condition pt-2"
  >
    <div class="condition-header">
      <!-- 検索条件開閉ボタン -->
      <div class="open-close">
        <div
          class="oc-link"
          @click="isOpened=!isOpened"
        >
          <p
            class="oc-icon"
            v-text="`${isOpened ? '－' : '＋'}`"
          />
          <p v-text="`${$t('buttons.searchCondition')}`" />
        </div>
      </div>
      <!-- クリアボタン -->
      <div
        v-if="isOpened"
        class="btn-clear"
      >
        <ButtonClear @click.native="clearCondition" />
      </div>
    </div>
    <div
      v-if="isOpened"
      class="condition-area ps-3"
    >
      <div class="row mt-2 mb-0">
        <div class="col-auto mb-1 condition-item-area">
          <div class="condition-item">
            <div class="condition-item-label">
              宿題名：
            </div>
            <div class="condition-item-value">
              <input
                v-model="inputedHomeworkName"
                type="text"
                class="form-control form-control-sm homework-name"
                maxlength="100"
              >
            </div>
          </div>
        </div>
        <div class="col-auto mb-1 condition-item-area">
          <div class="condition-item">
            <div class="condition-item-label">
              状態：
            </div>
            <div class="checkbox-list">
              <label
                v-for="(status, key) in statusList"
                :key="key"
              >
                <input
                  v-model="status.checked"
                  type="checkbox"
                  name="status"
                  @change="onChangeStatusList($event, status)"
                >
                <span
                  class="checkbox-item"
                  v-text="status.name"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-auto mb-1 condition-item-area">
          <div class="condition-item">
            <div class="condition-item-label">
              グループ：
            </div>
            <div class="condition-item-value">
              <select
                v-model="selectedGroup"
                class="form-select form-select-sm"
              >
                <option
                  v-for="(group, key) in groupList"
                  :key="key"
                  :value="group.id"
                  v-text="group.name"
                />
              </select>              
            </div>
          </div>
        </div>
        <div class="col-auto mb-1 condition-item-area">
          <div class="condition-item">
            <div class="condition-item-label">
              教科：
            </div>
            <div class="condition-item-value">
              <select
                v-model="selectedCurriculum"
                class="form-select form-select-sm"
              >
                <option
                  v-for="(curriculum, key) in curriculumList"
                  :key="key"
                  :value="curriculum.id"
                  v-text="curriculum.name"
                />
              </select>
            </div>
          </div>
        </div>
        <div class="col-auto mb-1 condition-item-area">
          <div class="condition-item">
            <div class="condition-item-label">
              科目：
            </div>
            <div class="condition-item-value">
              <select
                v-model="selectedSubject"
                class="form-select form-select-sm"
              >
                <option
                  v-for="(subject, key) in subjectList"
                  :key="key"
                  :value="subject.id"
                  v-text="subject.name"
                />
              </select>           
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-auto mb-1 condition-item-area">
          <div class="condition-item">
            <div class="condition-item-label">
              開始日：
            </div>
            <div class="condition-item-value">
              <div class="date-area">
                <FormDateField
                  v-model="inputedFromDate"
                  :is-require="false"
                />
              </div>
              <span>～</span>
              <div class="date-area">
                <FormDateField
                  v-model="inputedToDate"
                  :is-require="false"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-auto mb-1 condition-item-area">
          <div class="condition-item">
            <div class="condition-item-label">
              提出数：
            </div>
            <div class="condition-item-value">
              <select
                v-model="selectedNotHanded"
                class="form-select form-select-sm"
              >
                <option
                  v-for="(not_handed, key) in notHandedList"
                  :key="key"
                  :value="not_handed.id"
                  v-text="not_handed.name"
                />
              </select>           
            </div>
          </div>
        </div>        
      </div>
      <div class="row mb-0">
        <div class="col-auto mb-1 condition-item-area">
          <div class="condition-item">
            <div class="condition-item-label">
              カテゴリー：
            </div>
            <div class="checkbox-list row">
              <div class="col-auto">
                <label
                  v-for="(category, key) in categoryList"
                  :key="key"
                >
                  <input
                    v-model="category.checked"
                    type="checkbox"
                    name="category"
                    @change="onChangeCategoryList($event, category)"
                  >
                  <span
                    class="checkbox-item category-label"
                    v-text="category.name"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import homework from "../../api/homework";
import { category } from "../../constant/homework";
import mixin from "../../mixins/mixin"
import FormDateField from "../atoms/FormDateField.vue"
// import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"
import ButtonClear from "@/components/atoms/buttons/ButtonClear.vue"

/**
 * 宿題一覧検索条件
 */
export default {
  name: "HomeworkFilterCondition",
  components: {
    FormDateField,
    ButtonClear,
  },
  mixins: [mixin],
  props: {
    homeworkList: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data: () => ({
    isWatching: true,
    colorLayoutTheme: "#ff7f27",
    isOpened: false,
    statusList: [
      {
        id: null,
        isAll: true,
        name: "すべて",
        checked: true,
      },
    ],
    categoryList: [
      {
        id: null,
        isAll: true,
        name: "すべて",
        checked: true,
      },
    ],
    dateRange: {
      min: "",
      max: "",
    },
    notHandedList: [
    {
        id: null,
        name: "指定なし",
      },
      {
        id: 1,
        name: "未提出者あり",
      },
      {
        id: 0,
        name: "未提出者なし",
      },
    ],
    curriculumList: [
      {
        id: null,
        name: "すべて"
      }
    ],
    subjectList: [
      {
        id: null,
        name: "すべて"
      }
    ],
    inputedFromDate: "",
    inputedToDate: "",
    selectedGroup: null,
    selectedNotHanded: null,
    selectedCurriculum: null,
    selectedSubject: null,
    inputedHomeworkName: "",
    closeHeight: NaN,
    openHeight: NaN,
  }),
  computed: {
    groupList: {
      get() {
        // グループ選択リスト作成
        const groups = new Set();
        this.homeworkList.forEach(s => groups.add(s.groupId));
        const groupInfoList = Array.from(groups).filter(s => s !== 0).map(s => {
          return {
            id: s,
            name: this.loginUserInfo.groupIds.find(g => g.groupId === s).groupName,
          }
        }).sort((a, b) => {
          if (a.name > b.name) {
            return 1;
          } else if (a.name < b.name) {
            return -1;
          } else {
            return 0;
          }
        });
        return [{
          id: null,
          name: "すべて",
        }, ...groupInfoList]
      }
    },
    topElement: {
      get() {
        return this.$refs.top_element;
      }
    },
    fromDateRange: {
      get() {
        return {
          min: this.dateRange.min,
          max: this.inputedToDate == "" || this.dateRange.max < this.inputedToDate ? this.dateRange.max : this.inputedToDate,
        }
      },
    },
    toDateRange: {
      get() {
        return {
          min: this.inputedFromDate == "" || this.dateRange.min > this.inputedFromDate ? this.dateRange.min : this.inputedFromDate,
          max: this.dateRange.max,
        }
      },
    },
  },
  watch: {
    homeworkList() {
      // フィルター適用
      this.applyFilterList()
    },
    isOpened(newValue) {
      if (newValue) {
        if (isNaN(this.openHeight)) {
          const ins = this;
          this.$nextTick(() => {
            this.openHeight = this.topElement.clientHeight;
            this.$emit("opened", ins.closeHeight, ins.openHeight);
          });
        } else {
          this.$emit("opened", this.closeHeight, this.openHeight);
        }
      } else {
        this.$emit("closed", this.closeHeight, this.openHeight);
      }
    },
    /** 変更監視：開始日FROM選択状態 */
    inputedFromDate() {
      // フィルター適用
      this.applyFilterList();
    },
    /** 変更監視：開始日TO選択状態 */
    inputedToDate() {
      // フィルター適用
      this.applyFilterList();
    },
    /** 変更監視：グループ選択状態 */
    selectedGroup() {
      // フィルター適用
      this.applyFilterList();
    },
    /** 変更監視：未提出者選択状態 */
    selectedNotHanded() {
      // フィルター適用
      this.applyFilterList();
    },
    /** 変更監視：教科選択状態 */
    selectedCurriculum(newValue) {
      this.isWatching = false;

      try {
        this.subjectList.splice(1, this.subjectList.length - 1);
        this.selectedSubject = null;

        if (newValue == null) {
          return;
        }

        const foundCurriculumKey = this.nameCurriculums.keyItems.find((ck) => Number(ck.code) === newValue);
        if (foundCurriculumKey == null) {
          return;
        }

        const nameSubjects = this.nameSubjects;
        const subjects = new Set();

        this.homeworkList.filter(s => s.curriculum == newValue && s.subject != 0).forEach(s => subjects.add(s.subject));
        this.subjectList.push(
          ...Array.from(subjects).sort().map(s => {
            const foundSubjectItems = nameSubjects.find(
              (s2) => s2.curriculumKey === foundCurriculumKey.name
            )
            let foundSubject = null;
            if (foundSubjectItems) {
              foundSubject = foundSubjectItems.subjectItems.find(
                (s2) => Number(s2.code) === s
              )
            }
            return {
              "id": s,
              "name": foundSubject ? foundSubject.name : "",
            }
          })
        );
      } finally {
        // フィルター適用
        this.isWatching = true;
        this.applyFilterList();
      }
    },
    /** 変更監視：科目選択状態 */
    selectedSubject() {
      // フィルター適用
      this.applyFilterList();
    },
    /** 変更監視：宿題名入力状態 */
    inputedHomeworkName() {
      // フィルター適用
      this.applyFilterList();
    },
  },

  /** ライフサイクルフック(搭載後) */
  mounted() {
    const nameCurriculums = this.nameCurriculums;

    // ステータス選択リスト作成
    const statuses = new Set();
     this.homeworkList.forEach(s => statuses.add(s.status));
     this.statusList = this.statusList.concat(
      ...Array.from(statuses).sort().map(s => {
        return {
          id: s,
          isAll: false,
          name: this.getStatusName(s),
          checked: true,
        }
      })
     )

    // カテゴリー選択リスト作成
    const categories = new Set();
    this.homeworkList.forEach(s => categories.add(s.category || category.noneName));
    // ソート
    const categorySelections =
      Array.from(categories)
      .sort((a, b) => {
        // 「未設定」を末尾にする
        if (a === category.noneName) {
          return 1;
        }
        if (b === category.noneName) {
          return -1;
        }
        if (a === b) {
          return 0;
        }
        return a > b ? 1 : -1;
      })
      .map(s => {
        return {
          id: s,
          isAll: false,
          name: s,
          checked: true,
        }
      });
    this.categoryList = this.categoryList.concat(categorySelections)

    // 開始日の範囲を取得
    for (const homework of this.homeworkList) {
      const startDate = homework["startDate"];
      if(!startDate){
        continue;
      }
      const formatedDate = `${startDate.slice(0, 4)}-${startDate.slice(4, 6)}-${startDate.slice(6, 8)}`;
      if (this.dateRange.min == "" || formatedDate < this.dateRange.min) {
        this.dateRange.min = formatedDate;
      }
      if (this.dateRange.max == "" || formatedDate > this.dateRange.max) {
        this.dateRange.max = formatedDate;
      }
    }

    // 教科選択リスト作成
    const curriculums = new Set()
    this.homeworkList.forEach(s => curriculums.add(s.curriculum));
    this.curriculumList.splice(1, this.curriculumList.length - 1,
      ...Array.from(curriculums).filter(s => s !== 0).sort().map(s => {
        return {
          id: s,
          name: nameCurriculums.items.find(c => Number(c.code) === s).name,
        }
      })
    );

    const ins = this;
    this.$nextTick(()=>{
      ins.closeHeight = ins.topElement.clientHeight;
      ins.applyFilterList();
    });
  },

  methods: {
    /**
     * ステータス名取得
     * @param {*} status 
     */
    getStatusName(status) {
      const statusInfo = this.nameStreamStatusItems.find(
        (s) => Number(s.code) === status
      );
      if (statusInfo) {
        return statusInfo.name;
      }
      return "";
    },

    /** 検索条件初期化 */
    clearCondition() {
      this.isWatching = false;
      this.statusList.forEach(v => v.checked = true)
      this.categoryList.forEach(v => v.checked = true)
      this.inputedFromDate = "";
      this.inputedToDate = "";
      this.selectedGroup = null;
      this.selectedNotHanded = null;
      this.selectedCurriculum = null;
      this.selectedSubject = null;
      this.inputedHomeworkName = "";
      this.isWatching = true;

      // まとめてフィルターを適用する
      this.applyFilterList();
    },

    /** フィルター適用 */
    applyFilterList() {
      if (!this.isWatching) {
        // 監視中以外はフィルター適用を実行しない
        return;
      }

      const is_omiit = (homework, field, filter_value, none_filter_value = null, conv_rule = []) => {
        if (filter_value === none_filter_value) {
          return false;
        }
        // 項目名の置換ルール（conv_rule）が指定されている場合は変換してから比較
        const cr = conv_rule.find(v => v[0] === filter_value);
        const cv = cr ? cr[1] : filter_value
        return homework[field] !== cv;
      };
      const is_omiit_span = (homework, field, filter_from_value, filter_to_value, none_filter_value) => {
        if (filter_from_value !== none_filter_value) {
          if (homework[field] < filter_from_value) {
            return true;
          }
        }
        if (filter_to_value !== none_filter_value) {
          if (homework[field] > filter_to_value) {
            return true;
          }
        }
        return false;
      };
      const is_omiit_includes = (homework, field, filter_value, none_filter_value) => {
        if (filter_value === none_filter_value) {
          return false;
        }
        const val = homework[field];
        if (val === null) {
          return true;
        }
        return !val.includes(filter_value);
      };

      const is_omiit_checkbox = (value, items, none_filter_value = null, none_value = null) => {
        const selectedIdList = items.filter(v => v.checked && !v.isAll).map(v => v.id)

        return !selectedIdList.includes(value === none_value ? none_filter_value : value)
      }

      const inputedFromDate = this.inputedFromDate.replaceAll(/[^0-9]/g, "")
      const inputedToDate = this.inputedToDate.replaceAll(/[^0-9]/g, "")

      const filteredList = [];
      for (const homework of this.homeworkList) {
        if (is_omiit_checkbox(homework.status, this.statusList)) {
          continue;
        }
        if (is_omiit_checkbox(homework.category, this.categoryList, category.noneName, null)) {
          continue;
        }
        if (is_omiit_span(homework, "startDate", inputedFromDate, inputedToDate, "")) {
          continue;
        }
        if (is_omiit(homework, "groupId", this.selectedGroup, null)) {
          continue;
        }
        if (this.selectedNotHanded !== null) {
          const vals = homework["HandInCount"].split("/");
          const submit = parseInt(vals[0]);
          const delivers = parseInt(vals[1]);
          
          switch(this.selectedNotHanded) {
            case 0:
              // 未提出者なし
              if (submit !== delivers) {
                continue
              }
              break;
            case 1:
              // 未提出者あり
              if (submit === delivers) {
                continue;
              }
          }
        }
        if (is_omiit(homework, "curriculum", this.selectedCurriculum, null)) {
          continue;
        }
        if (is_omiit(homework, "subject", this.selectedSubject, null)) {
          continue;
        }
        if (is_omiit_includes(homework, "homeworkName", this.inputedHomeworkName, "")) {
          continue;
        }

        filteredList.push(homework);
      }

      const ins = this;
      const conditionStasus = ins.statusList.filter(s => s.checked && s.isAll).length > 0 ? "すべて" : ins.statusList.filter(s => s.checked && !s.isAll).map(v => v.name).join("、")
      const conditionCategory = ins.categoryList.filter(s => s.checked && s.isAll).length > 0 ? "すべて" : ins.categoryList.filter(s => s.checked && !s.isAll).map(v => v.name).join("、")
      let haishin_start_date = ""
      if (ins.inputedFromDate !== "" || ins.inputedToDate !== "") {
        if (ins.inputedFromDate !== "") {
          haishin_start_date += ins.inputedFromDate.replaceAll(/-/g, "/").replaceAll(/\/0([0-9])/g, "/$1");
          haishin_start_date += " ";
        }
        haishin_start_date += "～"
        if (ins.inputedToDate !== "") {
          haishin_start_date += " ";
          haishin_start_date += ins.inputedToDate.replaceAll(/-/g, "/").replaceAll(/\/0([0-9])/g,"/$1")
        }
      }
      const condition = {
        "status": conditionStasus,
        "category": conditionCategory,
        "haishin_start_date": haishin_start_date,
        "not_handed": ins.notHandedList.find(s => s.id === ins.selectedNotHanded).name,
        "haishin_grp": ins.selectedGroup === null ? "すべて" : ins.groupList.find(s => s.id === ins.selectedGroup).name,
        "curriculum": ins.selectedCurriculum === null ? "すべて" : ins.curriculumList.find(s => s.id === ins.selectedCurriculum).name,
        "subject": ins.selectedSubject === null ? "すべて" : ins.subjectList.find(s => s.id === ins.selectedSubject).name,
      }

      this.$emit("change-filter", filteredList, condition);
    },
    /** 状態変更時 */
    onChangeStatusList($event, status) {
      if (status.isAll) {
        // すべてが切り替わった場合
        // その他のチェックボックスを一括切り替え
        this.statusList.forEach((v) => {
          if (v.isAll) {
            return
          }
          v.checked = status.checked
        })
      } else {
        if (this.statusList.filter((v) => !v.isAll && !v.checked).length === 0) {
          // すべて選択
          // すべてを選択
          this.statusList.find((v) => v.isAll).checked = true
        } else {
          // 1個以上未選択
          // すべてを未選択
          this.statusList.find((v) => v.isAll).checked = false
        }
      }
      this.applyFilterList()
    },
    /** カテゴリ変更時 */
    onChangeCategoryList($event, category) {
      if (category.isAll) {
        // すべてが切り替わった場合
        // その他のチェックボックスを一括切り替え
        this.categoryList.forEach((v) => {
          if (v.isAll) {
            return
          }
          v.checked = category.checked
        })
      } else {
        if (this.categoryList.filter((v) => !v.isAll && !v.checked).length === 0) {
          // すべて選択
          // すべてを選択
          this.categoryList.find((v) => v.isAll).checked = true
        } else {
          // 1個以上未選択
          // すべてを未選択
          this.categoryList.find((v) => v.isAll).checked = false
        }
      }
      this.applyFilterList()
    },    
  },
}

</script>

<style lang="scss" scoped>
.homework-filter-condition {
  padding: 0;
  padding-bottom: 20px;
  align-items: center;
  margin: 0;

  .condition-header {
    display: flex;
    .open-close {
    margin: 0;
    display: flex;

      .oc-link {
        display: flex;
        align-items: center;
        cursor: pointer;
        .oc-icon {
          display: flex;
          width: 18px;
          height: 18px;
          font-size: 16px;
          justify-content: center;
          align-items: center;
          border: 1px solid var(--bs-layout-theme);
          margin-right: 4px;
        }

        p {
          margin: 0;
          padding: 0;
          font-size: 18px;
          font-weight: 900;
          color: var(--bs-layout-theme);
        }      
      }
    }
    .btn-clear {
      display: flex;
      align-items: center;
      margin-left: 38px;
    }
  }

  table {
    width: 800px;
    //border: 1px solid #b3b3b3;

    tr {
      height: 40px;

      th {
        background: var(--bs-table-header);
        color: var(--bs-layout-theme);
        text-align: center;
        vertical-align: middle;
        min-width: 100px;
      }
      td {
        padding: 2px 8px;
      }

      .cell {
        border: 1px solid #b3b3b3;
      }
    }
  }

  .checkbox-list {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    label {
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
    }

    input[type="checkbox"] {
      cursor: pointer;
    }
    .checkbox-item {
      z-index: 1;
      white-space: nowrap;
      display: inline-block;
      padding: 0 0.8em 0 0.2em;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
    }
  }

  .category-label {
    white-space: nowrap;
  }

  .form-control {
    width: unset;
    display: inline-block;
  }

  .form-select {
    width: unset;
    display: inline-block;
  }

  .date-area {
    min-width: 120px;
    display: inline-block;
  }

  .homework-name {
    width: 16em;
  }

  .condition-area {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2px;
    max-width: 960px;

    .condition-item-area {
      margin-right: 20px;
      .condition-item {
        justify-content: flex-start;
        align-items: center;
        display: flex;
        min-height: 40px;
        .condition-item-label {
          text-wrap: nowrap;
          white-space: nowrap;
          font-weight: 900;
        }
        .condition-item-value {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
  }
}
</style>