<template>
  <div class="setting-preview-area">
    <div v-if="list">
      <div class="preview-tab">
        <template v-for="item in displayList">
          <SettingTab
            :key="item.id"
            v-bind="item"
            :value="displayId"
            @input="(id) => (currentId = id)"
          />
        </template>
      </div>
      <div class="card preview-display">
        <div
          v-if="isDisplayNotShowStudent"
          class="not-display text-danger"
        >
          {{ $t("messages.error.notShowStudent") }}
        </div>

        <div>
          <div v-if="isContent">
            <SettingPreviewContent
              class="card-body"
              :content="current.content"
            />
          </div>
          <div v-else>
            <SettingPreviewImage
              class="card-body"
              :image="current.content"
              :stdb-flg="stdbFlg"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SettingPreviewContent from "../atoms/SettingPreviewContent.vue"
import SettingPreviewImage from "../atoms/SettingPreviewImage.vue"
import SettingTab from "../atoms/SettingTab.vue"
import { displayFlagCode } from "@/constant/question.js"

export default {
  name: "SettingPreviewArea",

  components: {
    SettingTab,
    SettingPreviewContent,
    SettingPreviewImage,
  },
  props: {
    list: {
      type: Array,
      default: function () {
        return []
      },
    },
    messageDisplayFlg: Boolean,
    stdbFlg: {
      type: Boolean,
      default: function () {
        return false
      },
    }
  },
  data: () => ({
    currentId: 1,
  }),
  computed: {
    /**
     * 現在表示されている要素
     */
    current() {
      return this.displayList.find((el) => el.id === this.displayId) || {}
    },
    /**
     * 現在表示されている要素はコンテンツか？
     */
    isContent() {
      const item = this.displayList.find((el) => el.id === this.displayId) || {}
      return "tagIndex" in item
    },
    /**
     * 表示する要素だけを抽出したリスト
     */
    displayList() {
      return this.list.filter((item) => item && this.isShowTab(item))
    },
    /**
     * 表示するID
     */
    displayId() {
      let id = this.currentId
      for (let i = 0; i < this.list.length; i++) {
        const item = this.list[i]
        // 要素が無い場合は値を返却
        if (!item) {
          return id
        }

        // 現在表示しようとしている要素が非表示ならIDをズラす
        if (!this.isShowTab(item) && item.id === id) {
          // HACK:「listの中の要素のIDが昇順」と言う前提の処理
          id++
        }
      }
      return id
    },
    /**
     * 「生徒には表示されません」の文言を表示するか？
     */
    isDisplayNotShowStudent() {
      if (this.messageDisplayFlg) {
        if (this.list[this.displayId - 1].content === "") {
          return false
        }
        return !this.list[this.displayId - 1].checkFlg
      }
      return this.messageDisplayFlg
    },
  },
  methods: {
    /**
     * currentIdをセット
     */
    setCurrentId(id) {
      this.currentId = id
    },
    /**
     * タブを表示するか？
     */
    isShowTab(item) {
      // コンテンツのタブかつツリー上で非表示になっている場合は非表示
      if ("tagIndex" in item && item.displayFlag === displayFlagCode.grayout) {
        return false
      }

      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.setting-preview-area {
  min-height: 240px;

  .preview-tab {
    display: flex;
    justify-content: flex-end;
    flex-flow: row wrap-reverse;
  }
  .preview-display {
    overflow-x: auto;
    height: 100%;
    position: relative;
  }
  .not-display {
    position: absolute;
    top: 15%;
    left: 50%;
    opacity: 0.8;
    background: #fff;
    transform: translate(-50%, 50%);
    font-size: 120%;
    font-weight: bold;
    padding: 0.2em 0.5em;
  }
}
</style>
