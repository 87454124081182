<template>
  <div class="modal-setting-eval">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div
          class="modal-container p-4"
          style="position: relative; max-height: 80vh;"
        >
          <ButtonClose
            class="modal-default-button"
            @click.native="onClickClose"
          />
          <div class="header-area">
            <div class="help-button-area">
              <ButtonOpenHelp :url="$t('url.helps.evalSetting')" />            
            </div>
          </div>


          <div class="detail-area">
            <div class="option-area">
              <div class="row mb-2 justify-content-center">
                <div class="col fw-bold option-title" v-text="$t('labels.settingEval.title')"/>
                <div class="col-auto target-submission-items">
                  <div class="target-submission-item">
                    <input name="use-eval" id="use-eval-0" type="radio" v-model="eval_set_flg" value="1">
                    <label for="use-eval-0" v-text="$t('labels.settingEval.use')"/>
                  </div>
                  <div class="target-submission-item">
                    <input name="use-eval" id="use-eval-1" type="radio" v-model="eval_set_flg" value="0">
                    <label for="use-eval-1" v-text="$t('labels.settingEval.notUse')"/>
                  </div>
                </div>
              </div>

              <div class="row mb-2 justify-content-center" :class="{ invisible: eval_set_flg != '1' }">
                <div class="col fw-bold option-title" v-text="$t('labels.settingEval.evalTarget')"/>
                <div class="col-auto target-submission-items">
                  <div class="target-submission-item">
                    <input name="target-submission" id="target-submission-0" type="radio" v-model="detail_set_flg" value="0">
                    <label for="target-submission-0" v-text="$t('labels.settingEval.evalTargetStatus')"/>
                  </div>
                  <div class="target-submission-item">
                    <input name="target-submission" id="target-submission-1" type="radio" v-model="detail_set_flg" value="1">
                    <label for="target-submission-1" v-text="$t('labels.settingEval.evalTargetStatusAndNote')"/>
                  </div>
                </div>
              </div>

            </div>
            <div v-if="detail_set_flg === '0' && eval_set_flg == '1'" class="row justify-content-center">
              <div class="col-auto condition-area">
                <table>
                  <thead>
                    <tr>
                      <th
                        v-for="(setting, key) in settings"
                        :key="`condistion-header-${key}`"
                      >
                        <div v-html="$t(`status.submit.${setting.labelId}`)" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        v-for="(setting, key) in settings"
                        :key="`condistion-body-${key}`"
                      >
                        <div class="eval-one">
                          <div
                            class="eval-label"
                            :class="{
                              'eval-none': settingItems[setting.varName] === 0,
                            }"
                            :style="{
                              color: getEvalColor(setting.varName),
                              visibility: !setting.varName ? 'hidden' : 'visible',
                            }"
                            v-text="getEvalName(setting.varName)"
                          />
                          <div class="eval-spinner">
                            <div
                              class="eval-button eval-button-upper"
                              :style="{
                                visibility: settingItems[setting.varName] === 1 || !setting.varName ? 'hidden' : 'visible',
                              }"
                              @click="upEval(setting.varName)"
                            />
                            <div
                              class="eval-button eval-button-downer"
                              :style="{
                                visibility: settingItems[setting.varName] === 0 || !setting.varName ? 'hidden' : 'visible',
                              }"
                              @click="downEval(setting.varName)"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  v-if="eval_set_flg != 1"
                  class="condition-cover"
                />
              </div>
            </div>

            <div v-if="detail_set_flg === '1' && eval_set_flg == '1'" class="row justify-content-center">
              <div class="col-auto condition-area">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th
                        v-for="(setting, key) in settings"
                        :key="`condistion-header-${key}`"
                      >
                        <div v-html="$t(`status.submit.${setting.labelId}`)" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td v-text="$t('labels.settingEval.subimtNote')"/>
                      <template
                        v-for="(setting, key) in settings"
                      >
                        <td
                          :rowspan="key === settings.length - 1 ? 2 : 1"
                          :key="`condistion-body-${key}`"
                        >
                          <div class="eval-one">
                            <div
                              class="eval-label"
                              :class="{
                                'eval-none': settingItems[setting.varName] === 0,
                              }"
                              :style="{
                                color: getEvalColor(setting.varName),
                                visibility: !setting.varName ? 'hidden' : 'visible',
                              }"
                              v-text="getEvalName(setting.varName)"
                            />
                            <div class="eval-spinner">
                              <div
                                class="eval-button eval-button-upper"
                                :style="{
                                  visibility: settingItems[setting.varName] === 1 || !setting.varName ? 'hidden' : 'visible',
                                }"
                                @click="upEval(setting.varName)"
                              />
                              <div
                                class="eval-button eval-button-downer"
                                :style="{
                                  visibility: settingItems[setting.varName] === 0 || !setting.varName ? 'hidden' : 'visible',
                                }"
                                @click="downEval(setting.varName)"
                              />
                            </div>
                          </div>
                        </td>
                      </template>
                    </tr>
                    <tr>
                      <td v-text="$t('labels.settingEval.notSubimtNote')"/>
                      <template
                        v-for="(setting, key) in settings"
                      >
                        <td
                          v-if="key !== settings.length - 1"
                          :key="`condistion-body-${key}`"
                        >
                          <div class="eval-one">
                            <div
                              class="eval-label"
                              :class="{
                                'eval-none': settingItems[setting.varNameNote] === 0,
                              }"
                              :style="{
                                color: getEvalColor(setting.varNameNote),
                                visibility: !setting.varNameNote ? 'hidden' : 'visible',
                              }"
                              v-text="getEvalName(setting.varNameNote)"
                            />
                            <div class="eval-spinner">
                              <div
                                class="eval-button eval-button-upper"
                                :style="{
                                  visibility: settingItems[setting.varNameNote] === 1 || !setting.varNameNote ? 'hidden' : 'visible',
                                }"
                                @click="upEval(setting.varNameNote)"
                              />
                              <div
                                class="eval-button eval-button-downer"
                                :style="{
                                  visibility: settingItems[setting.varNameNote] === 0 || !setting.varNameNote ? 'hidden' : 'visible',
                                }"
                                @click="downEval(setting.varNameNote)"
                              />
                            </div>
                          </div>
                        </td>
                      </template>
                    </tr>                  
                  </tbody>
                </table>
                <div
                  v-if="eval_set_flg != 1"
                  class="condition-cover"
                />
              </div>
            </div>


            <!-- ボタンエリア -->
            <div class="row gx-5 justify-content-center button-area">
              <div class="col">
                <div class="row justify-content-center mb-2 pb-3">
                  <div class="col-auto attention-area">
                    <div v-if="isCommon" class="col-auto eval-attention justify-content-center">
                      {{ $t('labels.settingEval.attention') }}
                    </div>
                    <div v-else class="col-auto eval-attention justify-content-center">
                      {{ $t('labels.settingEval.attention2') }}
                    </div>
                  </div>
                </div>
                <div class="row gx-5 justify-content-center">
                  <!--- キャンセルボタン -->
                  <div class="col-auto">
                    <ButtonBorderCircleMedium
                      :label-name="$t('buttons.cancel')"
                      :label-color="colorLayoutTheme"
                      :border-color="colorLayoutTheme"
                      font-size="0.9rem"
                      width="10rem"
                      @click.native="$emit('cancel')"
                    />
                  </div>
                  <!--- 設定ボタン -->
                  <div class="col-auto">
                    <ButtonBorderCircleMedium
                      :label-name="$t('buttons.set')"
                      color="layout-theme-light"
                      :label-color="colorLayoutTheme"
                      :border-color="colorLayoutTheme"
                      font-size="0.9rem"
                      width="10rem"
                      @click.native="$emit('set', settingItems)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalConfirm
      v-if="showCloseMessage"
      :message="$t('messages.confirm.evalNotSavedChanges')"
      v-on="{
        'ok-confirm-modal': onClickOkConfirModal,
        'close-confirm-modal': onClickCancelConfirModal,
      }"
    />
  </div>
</template>

<script>
/**
 * 確認ダイアログ
 */
import mixin from "../../../mixins/mixin"
import ButtonOpenHelp from "@/components/atoms/buttons/ButtonOpenHelp.vue"
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"
import ButtonClose from "@/components/atoms/buttons/ButtonClose.vue"
import ModalConfirm from "@/components/organisms/modal/ModalConfirm.vue"

export default {
  name: "ModalSettingEval",
  components: {
    ButtonOpenHelp,
    ButtonBorderCircleMedium,
    ButtonClose,
    ModalConfirm,
  },
  mixins: [mixin],
  props: {
    isCommon: {
      type: Boolean,
      default: function () {
        return false
      },
    },
    initialData: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    colorLayoutTheme: "var(--bs-layout-theme)",
    settings: [
      {
        labelId:"submitted",
        varName: "handed_eval",
        varNameNote: "note_handed_eval",
      },
      {
        labelId: "submitted_overdue",
        varName: "handed_expired_eval",
        varNameNote: "note_handed_expired_eval",
      },
      {
        labelId: "retry",
        varName: "send_back_eval",
        varNameNote: "note_send_back_eval",
      },
      {
        labelId: "not_submit",
        varName: "not_handed_eval",
        varNameNote: null,
      },
    ],
    settingItems: {},
    showCloseMessage: false,
  }),

  computed: {
    eval_set_flg: {
      get() {
        // console.log(`eval_set_flg.get: ${this.settingItems.eval_set_flg}`)
        return this.settingItems.eval_set_flg == 1 ? "1" : "0";
      },
      set(value) {
        // console.log(`eval_set_flg.set: ${value}`)
        this.settingItems.eval_set_flg = (value == "1" ? 1 : 0)
      },
    },
    detail_set_flg: {
      get() {
        return this.settingItems.detail_set_flg == 1 ? "1" : "0";
      },
      set(value) {
        this.settingItems.detail_set_flg = (value == "1" ? 1 : 0)
      },
    },
    handed_eval: {
      get() {
        return this.settingItems.handed_eval;
      },
      set(value) {
        this.settingItems.handed_eval = value
      }
    },
    handed_expired_eval: {
      get() {
        return this.settingItems.handed_expired_eval;
      },
      set(value) {
        this.settingItems.handed_expired_eval = value
      }
    },
    send_back_eval: {
      get() {
        return this.value.send_back_eval;
      },
      set(value) {
        this.settingItems.send_back_eval = value
      }
    },
    not_handed_eval: {
      get() {
        return this.value.not_handed_eval;
      },
      set(value) {
        this.settingItems.not_handed_eval = value
      }
    },
    note_handed_eval: {
      get() {
        return this.settingItems.note_handed_eval;
      },
      set(value) {
        this.settingItems.note_handed_eval = value
      }
    },
    note_handed_expired_eval: {
      get() {
        return this.settingItems.note_handed_expired_eval;
      },
      set(value) {
        this.settingItems.note_handed_expired_eval = value
      }
    },
    note_send_back_eval: {
      get() {
        return this.settingItems.note_send_back_eval;
      },
      set(value) {
        this.settingItems.note_send_back_eval = value
      }
    },
  },

  mounted: function() {
    this.settingItems = JSON.parse(JSON.stringify(this.initialData))
  },

  methods: {
    getEvalName(var_name) {
      switch(this.settingItems[var_name]) {
        case 0: return this.$t("labels.settingEval.none");
        case 1: return "A";
        case 2: return "B";
        case 3: return "C";
        case 4: return "D";
        case 5: return "E";
        default:  return "";
      }
    },
    getEvalColor(var_name) {
      switch(this.settingItems[var_name]) {
        case 1: return "#d15443";
        case 2: return "#4878bd";
        case 3: return "#bc8920";
        case 4: return "#55935e";
        case 5: return "#8f5cb4";
        default:  return "#000";
      }
    },
    upEval(var_name) {
      if (this.settingItems[var_name] == 0 ) {
        this.settingItems[var_name] = 5;
      } else {
        this.settingItems[var_name]--;
      }
    },
    downEval(var_name) {
      if (this.settingItems[var_name] == 5 ) {
        this.settingItems[var_name] = 0;
      } else {
        this.settingItems[var_name]++;
      }
    },
    onClickClose() {
      if (JSON.stringify(this.settingItems) !== JSON.stringify(this.initialData)) {
        this.showCloseMessage = true
      } else {
        this.$emit('cancel')
      }
    },
    onClickOkConfirModal() {
      this.$emit('cancel')
    },
    onClickCancelConfirModal() {
      this.showCloseMessage = false
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 98;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;

    .modal-container {
      width: 900px;
      margin: 0px auto;
      padding: 20px 50px;
      background-color: #fff;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      text-align: center;

      .modal-default-button {
        position: absolute;
        right: -40px;
        top: -40px;
      }

      .header-area {
        position: relative;
        .title {
          font-size: 20px;
          font-weight: bold;
        }
        .help-button-area {
          position: absolute;
          top: 0;
          right: 0;
        }
      }


      .detail-area {
        display: flex;
        flex-direction: column;
        height: 420px;
        align-items: center;

        .option-area {
          display: flex;
          max-width: 640px;
          flex-direction: column;
          //justify-content: flex-start;
          align-items: flex-start;

          .option-title {
            width: 160px;
            text-align: right;
          }
        }
        .target-submission-items {
          display: flex;
          .target-submission-item:not(:last-child) {
            margin-right: 30px;
          }
        }
        .attention-area {
          width: 580px;
          display: flex;
          .eval-set-flg {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-grow: 1;
          }
          .eval-attention {
            display: flex;
            justify-content: flex-end;
            flex-grow: 1;
          }
        }
        .button-area {
          height: 100%;
          display: flex;
          align-items: flex-end;
        }
      }
      .condition-area {
        display: flex;
        flex-direction: column;
        align-items: left;
        margin: 10px 10px;
        position: relative;

        table {
          margin: 0;
          padding: 0;
          width: 100%;
          thead {

            tr {
              height: 1.7em;
              border-top: 1px solid #b3b3b3;
            }

            th {
              border-left: 1px solid #b3b3b3;
              border-right: 1px solid #b3b3b3;
              text-align: center;
              background-color: var(--bs-table-header);
              color: var(--bs-layout-theme);
              width: 120px;
            }

          }
          tbody {
            td {
              border: 1px solid #b3b3b3;
              margin: 0;
              padding: 1.2em 0;

              &:first-child {
                white-space: pre-wrap;
              }

              .eval-one {
                position: relative;
                display: flex;
                flex-direction: row;
                gap: 0px;
                align-items: center;
                justify-content: center;
                padding: 0;
                .eval-spinner {
                  position: absolute;
                  right: 16px;
                  display: flex;
                  flex-direction: column;
                }
                .eval-button {
                  cursor: pointer;

                  &:hover {
                    opacity: 0.8;
                  }

                  &-upper {
                    &::after {
                      content: "▲";
                    }
                  }
                  &-downer {
                    &::after {
                      content: "▼";
                    }
                  }
                }
                .eval-label {
                  width: 70px;
                  font-family: 'Quicksand', var(--bs-body-font-family);
                  font-size: 30px;
                  font-weight: 700;
                  text-align: center;
                  font-size: xx-large;
                  &.eval-none {
                    font-family: var(--bs-body-font-family);
                    font-size: x-large;
                  }
                }              
              }
            }
          }
        }
        .condition-cover {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.5);
        }
      }
      ::v-deep .btn {
        padding: 0.6rem 0.5rem;
      }
    }
  }
}
</style>
