var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      class: ["btn", "btn-" + _vm.color, "btn-sm"],
      style: _vm.buttonStyle,
      attrs: { type: "button" }
    },
    [_vm._v(" " + _vm._s(_vm.labelName) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }