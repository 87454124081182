<!-- コメント制御 -->
<template>
  <div
    class="comment-control"
  >
    <!-- タイトル -->
    <div class="mt-3 mx-4 mb-2">
      <TitleCommentControl
        :help-link="$t('url.helps.comment')"
      />
    </div>

    <div class="description">
      <div
        class="description-body"
        v-text="$t('labels.commentControl.description.body')"
      />
      <div
        class="description-annotation mt-4"
        v-html="$t('labels.commentControl.description.annotation')"
      />
    </div>

    <div class="input-area">
      <!-- チェックボックス -->
      <CheckBox
        v-model="commentFlagBool"
        :label="$t('labels.commentControl.checkbox')"
      />

      <!-- 登録ボタン -->
      <ButtonBorderCircleMedium
        :label-name="$t('buttons.set')"
        color="layout-theme-light"
        :label-color="colorLayoutTheme"
        :border-color="colorLayoutTheme"
        width="180px"
        padding="0"
        @click.native="onClickSave"
      />
    </div>

    <!-- フッター -->
    <Footer></Footer>

    <!-- 保存完了ダイアログ -->
    <ModalConfirmOkOnly
      v-if="showSaveComplete"
      :message="$t('labels.commentControl.saveComplete')"
      message-align="center"
      :button-label="$t('buttons.ok')"
      @close-confirm-modal-ok-only="showSaveComplete = false"
    />

    <!-- セッション期限切れ時のエラーダイアログ -->
    <ModalConfirmOkOnly
      v-if="sessionDisconnect"
      :message="$t('messages.error.overLimitSessionToken')"
      @close-confirm-modal-ok-only="onSessionDisconnect()"
    />

    <!-- ローディング -->
    <LoadingScreen v-if="isLoading" />
  </div>
</template>

<script>
/**
 * コメント制御
 */
import mixin from "../mixins/mixin"
import homeworkRepository from "../repositories/homework"
import { apiResponseCode } from "../constant/network"
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"
import CheckBox from "@/components/atoms/CheckBox.vue"
import LoadingScreen from "@/components/atoms/LoadingScreen.vue"
import TitleCommentControl from "@/components/molecules/TitleCommentControl.vue"
import ModalConfirmOkOnly from "@/components/organisms/modal/ModalConfirmOkOnly.vue"
import Footer from "@/components/organisms/common/Footer.vue"

export default {
  name: 'CommentControl',
  components: {
    ButtonBorderCircleMedium,
    CheckBox,
    LoadingScreen,
    TitleCommentControl,
    ModalConfirmOkOnly,
    Footer,
  },

  mixins: [mixin],

  data: () => ({
    colorLayoutTheme: '#ff7f27',
    isLoading: true,
    commentFlag: '0',
    showSaveComplete: false,
  }),

  computed: {
    commentFlagBool: {
      get() {
        return this.commentFlag === '1';
      },
      set(value) {
        this.commentFlag = value ? '1' : '0';
      },
    }
  },

  async mounted() {
    // セッションチェック
    if (!await this.checkSession()) {
      return
    }

    await this.init()
  },

  methods: {
    /**
     * 初期処理
     */
    async init() {
      // コメント制御を取得
      await this.getCommentFlag();

      // ローディング終了
      this.isLoading = false;
    },

    /**
     * セッションが切れた際のログアウト処理
     */
    async onSessionDisconnect() {
      await this.setSessionDisconnectFalse()
      await this.clearSessionInfo()
      await this.$router.push({ name: "Login" })
    },

    /** 登録ボタン押下 */
    async onClickSave() {
      try {
        this.isLoading = true;
        await this.saveCommentFlag();
      } finally {
        this.isLoading = false;
      }
    },

    /** コメント制御の取得(学校共通情報の取得) */
    async getCommentFlag() {
      try {
        const schoolInfo = await homeworkRepository.getSchoolInfo(
          this.loginUserInfo.accountId,
          this.loginUserInfo.schoolId,
          this.loginUserInfo.lmsApiToken,
          ["COMMENT"]
        );
        // デフォルトは「1:許可」
        this.commentFlag = schoolInfo.COMMENT ? schoolInfo.COMMENT : "1";
      } catch(error) {
        if (error.status === apiResponseCode.internalServerError) {
          this.$router.push({
            name: "APIError",
            params: { status: error.status },
          })
        }
      }
    },

    /** コメント制御の保存 */
    async saveCommentFlag() {
      try {
        await homeworkRepository.saveSchoolInfo(
          this.loginUserInfo.accountId,
          this.loginUserInfo.schoolId,
          this.loginUserInfo.lmsApiToken,
          "COMMENT",
          this.commentFlag
        );
        this.showSaveComplete = true;
      } catch(error) {
        if (error.status === apiResponseCode.internalServerError) {
          this.$router.push({
            name: "APIError",
            params: { status: error.status },
          })
        }
      }
    },
  },
  
}
</script>

<style lang="scss" scoped>

.comment-control {
  display: flex;
  flex-direction: column;

  .description {
    position: relative;
    border:2px solid #ff7f27;
    border-radius: 25px;
    margin: 20px;
    padding: 20px;
    background: #FFF;

    .description-body {
      white-space: pre-wrap;

      .indent1 {
        margin-left: 1em;
      }
    }
    .description-annotation {
      text-align: left;
      padding-left: 1em;
      text-indent: -1em;
    }
  }

  .input-area {
    height: 100%;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap:60px;

    ::v-deep label {
      font-weight: bold;
      font-size: 18px;

      input {
        width: 0.8em;
        height: 0.8em;
      }
    }
  }
}

</style>
