var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "icon-circle" }, [
    _c(
      "svg",
      {
        staticClass: "bi bi-caret-right-fill",
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: _vm.width,
          height: _vm.height,
          fill: _vm.fill,
          viewBox: "0 0 16 16"
        }
      },
      [
        _c("path", {
          attrs: {
            d:
              "m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }