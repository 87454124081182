var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-select-question-type" },
    [
      _c("transition", { attrs: { name: "modal", appear: "" } }, [
        _c("div", { staticClass: "modal-mask" }, [
          _c("div", { staticClass: "modal-wrapper" }, [
            _c(
              "div",
              { staticClass: "modal-container-outer" },
              [
                _c("ButtonClose", {
                  staticClass: "modal-default-button",
                  nativeOn: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "modal-container p-4 pb-5",
                    staticStyle: { overflow: "auto", "max-height": "80vh" }
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col" }, [
                        _c("p", [_vm._v("追加するデータの種類を選択")])
                      ])
                    ]),
                    _c("div", { staticClass: "row justify-content-center" }, [
                      _c(
                        "div",
                        { staticClass: "col-auto mt-4" },
                        [
                          _c("ButtonBorderCircleMedium", {
                            attrs: {
                              "label-name": _vm.$t(
                                "buttons.questionOfTextbook"
                              ),
                              color: "layout-theme-light",
                              width: _vm.buttonWidth,
                              "label-color": _vm.colorLayoutTheme,
                              "border-color": _vm.colorLayoutTheme
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.onClickAddTextbookQuestion.apply(
                                  null,
                                  arguments
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row justify-content-center" }, [
                      _c(
                        "div",
                        { staticClass: "col-auto mt-4" },
                        [
                          _c("ButtonBorderCircleMedium", {
                            attrs: {
                              "label-name": _vm.$t("buttons.stdbPrint"),
                              color: "layout-theme-light",
                              width: _vm.buttonWidth,
                              "label-color": _vm.colorLayoutTheme,
                              "border-color": _vm.colorLayoutTheme,
                              disabled: !_vm.isMyHomework
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.onClickAddStdbQuestion.apply(
                                  null,
                                  arguments
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row justify-content-center" }, [
                      _c(
                        "div",
                        { staticClass: "col-auto mt-4" },
                        [
                          _c("ButtonBorderCircleMedium", {
                            attrs: {
                              "label-name": _vm.$t("buttons.pdf"),
                              color: "layout-theme-light",
                              width: _vm.buttonWidth,
                              "label-color": _vm.colorLayoutTheme,
                              "border-color": _vm.colorLayoutTheme,
                              disabled: !_vm.isMyHomework
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.onClickAddPdfQuestion.apply(
                                  null,
                                  arguments
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    !_vm.isMyHomework
                      ? _c("div", { staticClass: "row" }, [
                          _c("div", {
                            staticClass: "col-auto mt-4 footer-message",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t(
                                  "labels.otherTeacherStdbPdfAddNotAllowedMessage"
                                )
                              )
                            }
                          })
                        ])
                      : _vm._e()
                  ]
                )
              ],
              1
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }