<template>
  <div
    class="side-menu d-flex flex-column justify-content-between"
    :style="sizeStyle"
  >
    <div class="nav flex-column">
      <div
        v-for="item in items"
        :key="item.name"
      >
        <div
          class="row py-0"
          :class="{
            active: activeName === item.name,
            'over-active':
              activeName !== item.name && overItemName === item.name,
          }"
          style="--bs-gutter-x: 0rem"
          @click="onMenuClick(item)"
          @mouseover="overItemName = item.name"
          @mouseleave="overItemName = ''"
        >
          <LinkTextRouter
            class="col-12"
            :name="item.name"
            :icon-name="item.iconName"
            :icon-color="item.iconColor"
            :label-text="item.displayName"
            :font-color="item.fontColor"
            :open-url="item.openUrl"
          />
        </div>
      </div>
    </div>
    <ul class="nav flex-column mb-3">
      <li
        v-for="item in bottomItems"
        :key="item.name"
      >
        <ul
          class="list-inline"
          :class="{ active: activeName === item.name }"
        >
          <li class="list-inline-item">
            <LinkTextRouter
              :name="item.name"
              :label-text="item.displayName"
              :font-color="item.fontColor"
              :open-url="item.openUrl"
              @click.native="onMenuClick(item)"
            />
          </li>
          <li
            v-if="item.name != 'Terms'"
            class="list-inline-item"
          >
            <IconLink
              width="16px"
              height="16px"
            />
          </li>
        </ul>
      </li>
    </ul>

    <ModalTerms
      v-if="showModal == 'terms'"
      :is-agree-required="false"
      @close-terms="showModal = ''; $emit('change-modal-view', false)"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex"
import LinkTextRouter from "@/components/atoms/LinkTextRouter.vue"
// import IconGroupManage from "@/components/atoms/icons/IconGroupManage.vue"
// import IconHelp from "@/components/atoms/icons/IconHelp.vue"
import IconLink from "@/components/atoms/icons/IconLink.vue"
import ModalTerms from "@/components/organisms/modal/ModalTerms.vue"
export default {
  name: "SideMenu",
  components: {
    LinkTextRouter,
    // IconGroupManage,
    // IconHelp,
    IconLink,
    ModalTerms,
  },
  props: {
    size: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },

  data: function () {
    return {
      items: [],
      bottomItems: [],
      currentMenu: "",
      overItemName: "",
      showModal: "",
    }
  },

  computed: {
    ...mapGetters("menu", ["activeName", "allMenuItems"]),

    sizeStyle: function () {
      return `width: ${this.size.width}px; height: ${this.size.height}px;`
    },
  },

  mounted: function () {
    let pageName = this.$route.name
    // サイドメニューに表示されているページのトップにいるとは限らないので、
    // サイドメニューに対応させる
    switch (pageName) {
      case "HomeworkRegister":
      case "HomeworkAddTextbookQuestion":
      case "HomeworkAddStdbQuestion":
      case "HomeworkAddPdfQuestion":
      case "HomeworkProgress":
        pageName = "Homework"
    }
    this.setActiveName({ activeName: pageName })
    this.updateMenuItem(pageName)
  },

  updated: function () {
    if (this.currentMenu !== this.activeName) {
      this.updateItems()
      this.currentMenu = this.activeName
    }
  },

  methods: {
    ...mapMutations("menu", ["setActiveName", "setMenuItems"]),

    ...mapActions("menu", ["updateMenuItem"]),

    updateItems: function () {
      this.items = []
      this.bottomItems = []

      const allItem = this.allMenuItems
      allItem.forEach((item) => {
        if (!item.isBottomMenu) {
          this.items.push(item)
        } else {
          this.bottomItems.push(item)
        }
      })
    },

    onMenuClick: function (selectedItem) {
      // 下グループの場合
      if (selectedItem.isBottomMenu) {
        // 利用規約の場合モーダルを表示
        if (selectedItem.name == 'Terms') {
          this.showModal = 'terms'
          this.$emit('change-modal-view', true)
        }
        return
      }

      this.setActiveName({ activeName: selectedItem.name })
      this.updateMenuItem(selectedItem.name)
    },
  },
}
</script>

<style lang="scss" scoped>
.side-menu {
  position: fixed;
  border-right: solid 1px silver;

  .nav {
    .list-inline {
      padding: 0 0.7rem;
    }

    .active {
      background: var(--bs-layout-theme);
    }
    .over-active {
      background: #ffdbca;
    }
  }
}
</style>
