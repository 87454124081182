<template>
  <div
    class="modal-student-handed-data"
  >
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container-outer">
          <ButtonClose
            class="modal-default-button"
            @click.native="showCheckCloseModal"
          />
          <div
            ref="modalContainer"
            class="modal-container"
          >
            <div class="p-0 container-fluid d-flex align-items-center">
              <!-- 提出日時 -->
              <div class="d-inline-block align-middle m-auto handed-data-area text-start">
                <p class="m-0 small">
                  {{
                    $t("labels.handedDateReplaceWords")
                  }}
                  <span style="white-space: nowrap;">
                    {{ !studentData[studentIndex] ? "" : toYMDHMDate(studentData[studentIndex].lastSubmitAddDate) }}
                  </span>
                </p>
              </div>
              <!-- タイトル -->
              <div class="d-inline-block align-middle text-center m-auto show-student-name">
                <h6
                  v-if="showStudentName && studentData[studentIndex]"
                  class="m-0"
                >
                  {{ studentData[studentIndex].accountName }}の提出データ
                </h6>
                <h6
                  v-if="!showStudentName && studentData[studentIndex]"
                  class="m-0"
                >
                  {{
                    studentData[studentIndex].anonymousAccountName
                  }}の提出データ
                </h6>
              </div>
              <div class="d-inline-block stamp-eval-wrapper">
                <div class="d-flex justify-content-end gap-4" :class="{'no-editing-permission':!editingPermission}">
                  <!-- スタンプ -->
                  <StampArea
                    v-if="studentData[studentIndex]"
                    :stamps="stamps"
                    :selected-stamp="stamp"
                    :init-stamp-code="initStampCode"
                    :student-index="studentIndex"
                    v-on="{
                      'on-change-stamp': onChangeStamp,
                    }"
                  />
                  <!-- 評価 -->
                  <EvalArea
                    v-if="studentData[studentIndex]"
                    :init-eval-code="initEvalCode"
                    :init-eval-manual-set-flg="initEvalManualSetFlg"
                    :student-index="studentIndex"
                    v-on="{
                      'on-change-eval': onChangeEval,
                    }"
                  />
                </div>
              </div>
            </div>
            <div class="w-100 modal-contents-area">
              <div
                class="d-flex justify-content-around modal-contents-area-inner"
              >
                <!-- 写真 -->
                <div class="modal-contents-area-left">
                  <div class="icon-wrapper">
                    <IconCamera
                      width="25px"
                      height="25px"
                    />
                    <span>{{ $t("labels.note") }}</span>
                  </div>
                  <div class="card photo-area">
                    <div class="p-0 photo-area-wrapper">
                      <template v-for="(d, dIdx) in noteItemDateList">
                        <div
                          v-if="noteItemsByDate[d]"
                          :key="`date-index-${dIdx}`"
                          class="m-0 p-2 ps-3 text-start photo-area-date"
                        >
                          {{ toYMDHMDate(noteItemsByDate[d].lastSubmitAddDate) }} 提出
                        </div>
                        <ul
                          v-if="studentData[studentIndex] && noteItemsByDate[d]"
                          :key="`notes-index-${dIdx}`"
                          class="row row-cols-4 m-0 p-1 photo-area-list"
                        >
                          <template
                            v-for="(note, childIndex) in noteItemsByDate[d].noteItems"
                          >
                            <li
                              v-if="note.showThumbnail"
                              :key="childIndex"
                              class="photo-area-list-item"
                            >
                              <div
                                class="justify-content-center notebook"
                              >
                                <div class="notebook-wrapper">
                                  <div class="note-header">
                                    <div
                                      v-if="note.noteType === 1"
                                      class="lesson-no"
                                    >
                                      No.{{ note.lessonNo }}
                                    </div>
                                    <IconEdit
                                      v-if="showIconEdit(note)"
                                      width="10"
                                      height="10"
                                    />
                                  </div>
                                  <div class="note-content">
                                    <HomeworkNoteImage
                                      :content_scale="1"
                                      :img.sync="note"
                                      :teacher-padding="false"
                                      :draggable="false"
                                      :editable="false"
                                      :scale="1"
                                      :config-page-teacher.sync="configPageTeacher"
                                      :cond-teacher.sync="condTeacher"
                                      @click.native="openImageDetail(d, childIndex)"
                                    />
                                  </div>
                                </div>
                              </div>
                            </li>
                          </template>
                        </ul>
                      </template>
                    </div>
                  </div>
                </div>
                <!-- コメント -->
                <div class="modal-contents-area-right">
                  <div class="icon-wrapper">
                    <IconComment
                      width="25px"
                      height="25px"
                    />
                    <span>{{ $t("labels.comment") }}</span>
                  </div>
                  <div class="chat-area-wrapper">
                    <ChatArea
                      v-if="studentData[studentIndex]"
                      :teacher-comment="teacherComment"
                      :last-submit-add-date="studentData[studentIndex].lastSubmitAddDate"
                      :haishin-start-date="haishinStartDate"
                      :submit-date="(studentData[studentIndex].handedDate ? studentData[studentIndex].handedDate + studentData[studentIndex].handedTime : '')"
                      :student-comment="
                        studentData[studentIndex].studentComment
                      "
                      :comments="studentData[studentIndex].comments"
                      :return-comment="studentData[studentIndex].teacherComment"
                      :init-message="initMessage"
                      :student-index="studentIndex"
                      :status="studentData[studentIndex].status"
                      :return-date="studentData[studentIndex].returnDate"
                      :school-info="schoolInfo"
                      :editing-permission="editingPermission"
                      v-on="{
                        'on-change-message': onChangeMessage,
                      }"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end mb-2">
                <div
                  v-if="true"
                  class="text-end return-annotation-text"
                >
                  {{ $t("labels.recommendEvalMessage") }}
                </div>
              </div>
              <div class="row gx-2 justify-content-center">
                <div
                  class="modal-student-handed-buttons"
                  :class="{ hidden: !studentData[studentIndex] }"
                >
                  <ButtonBorderCircleMedium
                    :label-name="$t('buttons.cancel')"
                    label-color="#ff7f27"
                    border-color="#ff7f27"
                    width="180px"
                    padding="0"
                    @click.native="onClickCancel"
                  />
                  <div
                    v-if="isStudentNotHanded()"
                    class="return-buttons"
                  >
                    <ButtonBorderCircleMedium
                      :label-name="$t('buttons.sendSave')"
                      :editing-permission="editingPermission"
                      color="send-back"
                      label-color="#ff7f27"
                      border-color="#ff7f27"
                      width="180px"
                      padding="0"
                      @click.native="onClickSendSave"
                    />
                  </div>
                  <div
                    v-else
                    class="return-buttons"
                  >
                    <ButtonBorderCircleMedium
                      :label-name="$t('buttons.sendBack')"
                      :editing-permission="editingPermission"
                      color="send-back"
                      label-color="#ff7f27"
                      border-color="#ff7f27"
                      width="180px"
                      padding="0"
                      @click.native="onClickSendBack"
                    />
                    <ButtonBorderCircleMedium
                      :label-name="$t('buttons.returnOf')"
                      :editing-permission="editingPermission"
                      color="layout-theme-light"
                      label-color="#ff7f27"
                      border-color="#ff7f27"
                      width="180px"
                      padding="0"
                      @click.native="onClickReturnOf"
                    />
                  </div>
                </div>
              </div>
            </div>
            <ul class="prev-area">
              <li
                v-if="isSubmitPrevData(studentIndex)"
                class="prev"
                @click="onClickDataTransferArea(isPrevData)"
              />
            </ul>
            <ul class="next-area">
              <li
                v-if="isSubmitNextData(studentIndex)"
                class="next"
                @click="onClickDataTransferArea(!isPrevData)"
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
    <ModalHandedImageDetail
      v-if="showImageDetailModal"
      :student-data="studentData[studentIndex]"
      :selected-note-index="selectedNoteIndex"
      :show-student-name="showStudentName"
      :selected-note-item.sync="noteItemsByDate[selectedNoteSubmitDate]"
      :homework-details.sync="homeworkDetails"
      :config-page-teacher.sync="configPageTeacher"
      :cond-teacher.sync="condTeacher"
      :editable="imageDetailEditable"
      :write-info-object-to-string="writeInfoObjectToString"
      :editing-permission="editingPermission"
      v-on="{
        'selected-node-transfer': transferImageDetailModal,
        showConfirmForOverWriteInfoSize: ()=>showOverWriteSize = true,
      }"
      @close="showImageDetailModal = false"
    />
    <!-- 返却メッセージ用 -->
    <ModalConfirmOkOnly
      v-if="showReturnMessage"
      :message="returnMessage"
      @close-confirm-modal-ok-only="onClickForReturnMessage"
    />
    <!-- やり直しメッセージ用 -->
    <ModalConfirmOkOnly
      v-if="showSendBackMessage"
      :message="sendBackMessage"
      @close-confirm-modal-ok-only="onClickForSendBackMessage"
    />
    <!-- 登録メッセージ用 -->
    <ModalConfirmOkOnly
      v-if="showSendSaveMessage"
      :message="sendSaveMessage"
      @close-confirm-modal-ok-only="onClickForSendSaveMessage"
    />
    <!-- 保存前遷移時用 -->
    <ModalConfirm
      v-if="showCautionCloseMessage"
      :message="$t('messages.confirm.notSavedChanges')"
      v-on="{
        'ok-confirm-modal': onClickOkForCautionCloseMessage,
        'close-confirm-modal': onClickCloseForCautionCloseMessage,
      }"
    />
    <!-- 保存前遷移時用 -->
    <ModalConfirm
      v-if="showCautionTransferMessage"
      :message="$t('messages.confirm.notSavedChanges')"
      v-on="{
        'ok-confirm-modal': onClickOkForCautionTransferMessage,
        'close-confirm-modal': onClickCloseForCautionTransferMessage,
      }"
    />
    <!-- 再提出解除確認用 -->
    <ModalConfirm
      v-if="showCancelSendBackMessage"
      :message="$t('messages.confirm.cancelSendBack')"
      v-on="{
        'ok-confirm-modal': onClickOkForCancelSendBackMessage,
        'close-confirm-modal': (onClickCloseForCancelSendBackMessage),
      }"
    />
    <!-- 書き込みオーバー用警告ダイアログ -->
    <ModalConfirmOkOnly
      v-if="showOverWriteSize"
      :message="$t('messages.error.overWriteInfoSize')"
      @close-confirm-modal-ok-only="()=>showOverWriteSize = false"
    />
    <ModalConfirmOkOnly
      v-if="showNoEditPermission"
      :message="$t('messages.error.noEditingPermission')"
      @close-confirm-modal-ok-only="showNoEditPermission = false"
    />
  </div>
</template>

<script>
import { mapMutations } from "vuex"
import ButtonClose from "@/components/atoms/buttons/ButtonClose.vue"
import IconComment from "@/components/atoms/icons/IconComment.vue"
import IconCamera from "@/components/atoms/icons/IconCamera.vue"
import StampArea from "@/components/organisms/StampArea.vue"
import EvalArea from "@/components/organisms/EvalArea.vue"
import ChatArea from "@/components/organisms/ChatArea.vue"
import ModalHandedImageDetail from "@/components/organisms/modal/ModalHandedImageDetail.vue"
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"
import convertValue from "@/repositories/convert-value"
import ModalConfirm from "@/components/organisms/modal/ModalConfirm.vue"
import ModalConfirmOkOnly from "@/components/organisms/modal/ModalConfirmOkOnly.vue"
import { handedStatus, homeworkTypeCode, noteType } from "@/constant/homework"
import HomeworkNoteImage from "@/components/atoms/HomeworkNoteImage.vue"
import IconEdit from "@/components/atoms/icons/IconEdit.vue"
import _ from "lodash"

export default {
  name: "ModalStudentHandedData",
  components: {
    ButtonClose,
    IconCamera,
    IconComment,
    StampArea,
    EvalArea,
    ChatArea,
    ButtonBorderCircleMedium,
    ModalHandedImageDetail,
    ModalConfirm,
    ModalConfirmOkOnly,
    HomeworkNoteImage,
    IconEdit,
},
  props: {
    studentData: {
      type: Array,
      default: function () {
        return []
      },
    },
    stamps: {
      type: Array,
      default: function () {
        return []
      },
    },
    studentNum: {
      type: Number,
      default: function () {
        return 0
      },
    },
    submitIndexList: {
      type: Array,
      default: function () {
        return []
      },
    },
    showStudentName: {
      type: Boolean,
    },
    teacherComment: {
      type: String,
      default: function () {
        return ""
      },
    },
    haishinStartDate: {
      type: String,
      default: function () {
        return ""
      },
    },
    homeworkDetails: {
      type: Object,
      default: function () {
        return {}
      },
    },
    quesImageList: {
      type: Array,
      default: function () {
        return {}
      },
    },
    configPageTeacher: {
      type: Object,
      default: function () {
        return {}
      },
    },
    condTeacher: {
      type: Object,
      default: function () {
        return {}
      },
    },
    schoolInfo: {
      type: Object,
      default: function () {
        return {
          "COMMENT": undefined,
        };
      },
    },
    editingPermission: {
      type: Boolean,
      default: function () {
        return true;
      }
    },
  },

  data: function () {
    return {
      colorLayoutTheme: "#ff7f27",
      studentIndex: this.studentNum,
      noteItemsByDate: [],
      noteItemDateList: [],
      comment: "",
      evalCode: 0,
      evalManualSetFlg: 0,
      stampCode: this.stamps.length,
      isPrevData: true,
      selectedNoteIndex: 0,
      selectedNoteSubmitDate: "",
      imageDetailEditable: false,
      showImageDetailModal: false,
      showReturnMessage: false,
      showCancelSendBackMessage: false,
      returnMessage: "",
      showSendBackMessage: false,
      showSendSaveMessage: false,
      sendBackMessage: "",
      sendSaveMessage: "",
      // 評価コード初期値
      defaultEvalCode: 0,
      defaultEvalManualSetFlg: 0,
      // スタンプコード初期値
      defaultStampCode: this.stamps.length,
      showCautionCloseMessage: false,
      showCautionTransferMessage: false,
      submiting: false,
      showOverWriteSize: false,
      handedStatus: handedStatus,
      beforeSubmitValue: {},
      showNoEditPermission: false,
    }
  },

  computed: {
    initEvalCode() {
      return !this.studentData || this.studentData.length === 0 || !this.studentData[this.studentIndex].eval
        ? this.defaultEvalCode
        : this.studentData[this.studentIndex].eval
    },
    initEvalManualSetFlg() {
      return !this.studentData || this.studentData.length === 0 || !this.studentData[this.studentIndex].evalManualSetFlg
        ? this.defaultEvalManualSetFlg
        : this.studentData[this.studentIndex].evalManualSetFlg
    },
    stamp() {
      if (!this.stamps[this.stampCode - 1]) {
        return ""
      }
      return this.stamps[this.stampCode - 1].stamp
    },
    initStampCode() {
      return !this.studentData || this.studentData.length === 0 || !this.studentData[this.studentIndex].stamp
        ? this.stamps[this.defaultStampCode - 1].code
        : this.stamps[this.studentData[this.studentIndex].stamp - 1].code
    },
    initMessage () {
      if (!this.studentData || this.studentData.length === 0) {
        return ""
      }
      return this.getInitMessage()
    },
    lastNoteDate(){
      return Object.keys(this.noteItemsByDate).reduce((a,b)=>a>b?a:b,'');
    },
    /** 登録時に評価コードのみの変更可どうか */
    isChangedEvalCodeOnly() {
      const v = this.getSubmitValue()
      return v.evalCode !== this.beforeSubmitValue.evalCode
        && v.comment === this.beforeSubmitValue.comment
        && v.stampCode === this.beforeSubmitValue.stampCode
        && _.isEqual(v.writeInfo, this.beforeSubmitValue.writeInfo)
    },
  },
  watch: {
    showImageDetailModal(newValue, oldValue){
      if(oldValue && !newValue){
        // 拡大画面を閉じた時、先生書き込みをサムネイルに反映させるため描画更新
        this.noteItemsByDate = this.cloneObject(this.noteItemsByDate);
      }
    },
    async studentNum() {
      this.comment = this.getInitMessage()
      this.evalCode = this.studentData[this.studentIndex].eval
      this.evalManualSetFlg = this.studentData[this.studentIndex].evalManualSetFlg
      this.stampCode = this.studentData[this.studentIndex].stamp
      await this.resetNote()
    },
    async studentData() {
      if (this.studentData && this.studentData.length > 0) {
        this.comment = this.getInitMessage()
        this.evalCode = this.studentData[this.studentIndex].eval
        this.evalManualSetFlg = this.studentData[this.studentIndex].evalManualSetFlg
        this.stampCode = this.studentData[this.studentIndex].stamp
      }
      await this.resetNote()
    }
  },

  async mounted() {
    // リサイズ時にモーダルサイズの調整を行う（iPad対応）
    window.addEventListener('resize', () => {
      this.adjustModal();
    });
    this.adjustModal();

    this.comment = this.initMessage
    this.evalCode = this.studentData[this.studentIndex].eval
    this.evalManualSetFlg = this.studentData[this.studentIndex].evalManualSetFlg
    this.stampCode = this.studentData[this.studentIndex].stamp
    await this.resetNote()
    this.beforeSubmitValue = this.getSubmitValue()
  },

  methods: {
    ...mapMutations("homework", [
      "deleteCheckedStudentProgress",
    ]),
    /**
     * モーダルサイズ調整
     */
    adjustModal() {
      var e = this.$refs.modalContainer;
      if (e) {
        var v = window.innerHeight;
        e.style.height = `${ (v - 20) }px`;
      }
    },
    /** 返却値セットを取得する */
    getSubmitValue() {
      return {
        comment: this.comment,
        evalCode: this.evalCode || this.defaultEvalCode,                          // 評価コードが設定されていない場合はデフォルト値を渡す
        evalManualSetFlg: this.evalManualSetFlg || this.defaultEvalManualSetFlg,  // 評価コード設定フラグが設定されていない場合はデフォルト値を渡す
        stampCode: this.stampCode || this.defaultStampCode,                       // スタンプコードが設定されていない場合はデフォルト値を渡す
        writeInfo: this.getNoteWriteInfoList(),
      }
    },
    // スタンプを親画面に返す
    onChangeStamp(stampCode) {
      this.stampCode = stampCode
    },
    // 評価を親画面に返す
    onChangeEval(evalCode, evalManualSetFlg) {
      this.evalCode = evalCode
      this.evalManualSetFlg = evalManualSetFlg
    },
    // コメントを親画面に返す
    onChangeMessage(message) {
      this.comment = message
    },
    // 返却完了メッセージOK押下時
    onClickForReturnMessage() {
      this.submiting = false
      this.showReturnMessage = false
      this.noteItemsByDate = {}
      this.$emit("on-reload")
    },
    // 返却ボタン押下時
    onClickReturnOf(ev, confirmed) {
      if(this.editingPermission){
        if (!confirmed && (this.studentData[this.studentIndex].status == handedStatus.sendBack || this.studentData[this.studentIndex].status == handedStatus.sendBackCheck)) {
          // 再提出指示中の場合は確認ダイアログ表示
          this.showCancelSendBackMessage = true
          return
        }
        this.submiting = true
        const submitValue = this.getSubmitValue()
        const returnedStatus = [
          handedStatus.returned,
          handedStatus.returnChecked,
        ]
        const statusNotChange = returnedStatus.includes(this.studentData[this.studentNum].status)
        this.$emit(
          "on-click-return-of",
          this.studentData[this.studentIndex].originIndex,
          [],
          submitValue.evalCode,
          submitValue.evalManualSetFlg,
          submitValue.stampCode,
          submitValue.comment,
          submitValue.writeInfo,
          (result) => {
            if (result) {
              // 返却処理成功時
              let studentName = this.studentData[this.studentIndex].anonymousAccountName
              if (this.showStudentName) {
                studentName = this.studentData[this.studentIndex].accountName
              }
              if (statusNotChange && this.isChangedEvalCodeOnly) {
                // 評価コードのみ変更時
                this.returnMessage = this.$t("messages.success.changedEvalCodeOnly", {
                  studentName: studentName,
                })
              } else {
                this.returnMessage = this.$t("messages.success.return", {
                  studentName: studentName,
                })
              }
              this.showReturnMessage = true
              // 次回のために現在値を保持
              this.beforeSubmitValue = this.getSubmitValue()
            } else {
              // 返却処理失敗時
              this.returnMessage = this.$t("messages.error.exlusiveUpdateHomework")
              this.showReturnMessage = true
            }
          },
        )
      }else{
        // 編集権限がない場合はモーダルを表示
        this.showNoEditPermission = true;
      }
      // this.$emit("close")
    },
    // やり直し完了メッセージOK押下時
    onClickForSendBackMessage() {
      this.showSendBackMessage = false
      this.noteItemsByDate = {}
      this.$emit("on-reload")
    },
    // やり直しボタン押下時
    onClickSendBack() {
      // this.showSendBackMessage = !this.showSendBackMessage
      if(this.editingPermission){
        const submitValue = this.getSubmitValue()
        const sendbackStatus = [
          handedStatus.sendBack,
          handedStatus.sendBackCheck,
        ]
        const statusNotChange = sendbackStatus.includes(this.studentData[this.studentNum].status)
        this.$emit(
          "on-click-send-back",
          this.studentData[this.studentIndex].originIndex,
          [],
          submitValue.evalCode,
          submitValue.evalManualSetFlg,
          submitValue.stampCode,
          submitValue.comment,
          submitValue.writeInfo,
          (result) => {
            if (result) {
              // やり直し処理成功時
              let studentName = this.studentData[this.studentIndex].anonymousAccountName
              if (this.showStudentName) {
                studentName = this.studentData[this.studentIndex].accountName
              }
              if (statusNotChange && this.isChangedEvalCodeOnly) {
                // 評価コードのみ変更時
                this.sendBackMessage = this.$t("messages.success.changedEvalCodeOnly", {
                  studentName: studentName,
                })
              } else {
                this.sendBackMessage = this.$t("messages.success.sendBack", {
                  studentName: studentName,
                })
              }
              // やり直したら進捗確認済みから外す（再提出時にnewになるように）
              this.deleteCheckedStudentProgress({
                homeworkKey: this.studentData[this.studentIndex].homeworkKey,
                studentId: this.studentData[this.studentIndex].accountId,
              })
              this.showSendBackMessage = true
              // 次回のために現在値を保持
              this.beforeSubmitValue = this.getSubmitValue()
            } else {
              // やり直し処理失敗時
              this.sendBackMessage = this.$t("messages.error.exlusiveUpdateHomework")
              this.showSendBackMessage = true
            }
          },
        )
      }else{
        // 編集権限がない場合はモーダルを表示
        this.showNoEditPermission = true;
      }
      // this.$emit("close")
    },
    // 登録完了メッセージOK押下時
    onClickForSendSaveMessage() {
      this.showSendSaveMessage = false
      this.noteItemsByDate = {}
      this.$emit("on-reload")
    },
    //登録ボタン押下時
    onClickSendSave() {
      if(this.editingPermission){
        const submitValue = this.getSubmitValue()
        this.$emit(
          "on-click-send-save",
          this.studentData[this.studentIndex].originIndex,
          [],
          submitValue.evalCode,
          submitValue.evalManualSetFlg,
          submitValue.stampCode,
          submitValue.comment,
          submitValue.writeInfo,
          (result) => {
            if (result) {
              // 登録処理成功時
              let studentName = this.studentData[this.studentIndex].anonymousAccountName
              if (this.showStudentName) {
                studentName = this.studentData[this.studentIndex].accountName
              }
              if (this.isChangedEvalCodeOnly) {
                // 評価コードのみ変更時
                this.sendSaveMessage = this.$t("messages.success.changedEvalCodeOnly", {
                  studentName: studentName,
                })
              } else {
                this.sendSaveMessage = this.$t("messages.success.sendSave", {
                  studentName: studentName,
                })
              }
              this.showSendSaveMessage = true
              // 次回のために現在値を保持
              this.beforeSubmitValue = this.getSubmitValue()
            } else {
              // 登録処理失敗時
              this.sendSaveMessage = this.$t("messages.error.exlusiveUpdateHomeworkSendSave")
              this.showSendSaveMessage = true
            }
          },
        )
      }else{
        // 編集権限がない場合はモーダルを表示
        this.showNoEditPermission = true;
      }
    },
    onClickOkForCancelSendBackMessage(ev) {
      this.onClickReturnOf(ev, true)
    },
    onClickCloseForCancelSendBackMessage() {
      this.showCancelSendBackMessage = false
    },
    openImageDetail(d, childIndex) {
      this.selectedNoteSubmitDate = d
      this.selectedNoteIndex = childIndex
      this.imageDetailEditable = d == this.lastNoteDate
      this.showImageDetailModal = true
    },
    onClickCancel() {
      this.$emit("close")
    },
    toSlashDate(dateString) {
      return convertValue.toDateStringSeparator(dateString)
    },
    toYMDHMDate(dateString) {
      if (!dateString) {
        return ""
      }
      return convertValue.toDateTimeStringSeparator(dateString)
    },
    isSubmitPrevData(index) {
      return this.submitIndexList.filter((item) => item < index).length > 0
    },
    isSubmitNextData(index) {
      return this.submitIndexList.filter((item) => item > index).length > 0
    },
    onClickDataTransferArea(isPrev) {
      this.isPrev = isPrev
      this.showCheckTransferModal()
    },
    transferImageDetailModal(index) {
      this.selectedNoteIndex = index
    },
    showCheckCloseModal() {
      if (this.isChangeData()) {
        this.showCautionCloseMessage = true
      } else {
        this.$emit("close")
      }
    },
    isChangeComment() {
      return this.initMessage != this.comment
    },
    isChangeData() {
      let isChanged = false;
      if(!isChanged){
        // isChanged
        isChanged = this.isChangeComment();
      }
      if(!isChanged){
        // 評価チェック
        isChanged = this.evalCode != this.studentData[this.studentIndex].eval;
      }
      if(!isChanged){
        // 評価手動設定フラグチェック
        isChanged = this.evalManualSetFlg != this.studentData[this.studentIndex].evalManualSetFlg;
      }
      if(!isChanged){
        // スタンプチェック
        isChanged = this.stampCode != this.studentData[this.studentIndex].stamp;
      }
      if(!isChanged){
        // 先生書き込み有無
        if(this.lastNoteDate){
          isChanged = this.noteItemsByDate[this.lastNoteDate].noteItems.some(note=>note.writeInfoTeacherObject.writeInfo.isEdited);
        }
      }
      return isChanged;
    },
    onClickOkForCautionCloseMessage() {
      this.showCautionCloseMessage = false
      this.$emit("close")
    },
    onClickCloseForCautionCloseMessage() {
      this.showCautionCloseMessage = false
    },
    showCheckTransferModal() {
      if (this.isChangeData()) {
        this.showCautionTransferMessage = true
      } else {
        if (this.isPrev) {
          this.comment = ""
          this.studentIndex =
            this.submitIndexList[
              this.submitIndexList.indexOf(this.studentIndex) - 1
            ]
        } else {
          this.comment = ""
          this.studentIndex =
            this.submitIndexList[
              this.submitIndexList.indexOf(this.studentIndex) + 1
            ]
        }
        this.$emit("on-click-student-data-transfer", this.studentIndex, this.studentData[this.studentIndex].accountId, this.studentData[this.studentIndex].noteItems)
      }
    },
    onClickOkForCautionTransferMessage() {
      this.showCautionTransferMessage = false
      if (this.isPrev) {
        this.comment = ""
        this.studentIndex =
          this.submitIndexList[
            this.submitIndexList.indexOf(this.studentIndex) - 1
          ]
      } else {
        this.comment = ""
        this.studentIndex =
          this.submitIndexList[
            this.submitIndexList.indexOf(this.studentIndex) + 1
          ]
      }
      this.$emit("on-click-student-data-transfer", this.studentIndex, this.studentData[this.studentIndex].accountId, this.studentData[this.studentIndex].noteItems)
    },
    onClickCloseForCautionTransferMessage() {
      this.showCautionTransferMessage = false
    },
    getInitMessage() {
      const item = this.studentData[this.studentIndex]
      const status = item.status
      if (status === handedStatus.returned || status === handedStatus.returnChecked) {
        return item.teacherComment
      }
      if (status === handedStatus.sendBack || status === handedStatus.sendBackCheck) {
        if (item.comments.length > 0) {
          return item.comments[item.comments.length - 1].teacher_comment || ""
        }
      }
      if (status === handedStatus.notProgress || status === handedStatus.progressing || status === handedStatus.waitHand) {
        if (item.comments.length > 0) {
          return item.comments[0].teacher_comment || ""
        }
      }
      return ""
    },
    async resetNote() {
      const noteItemsByDate = {}

      if(this.studentData && this.studentData.length > this.studentIndex){
        for(const note of this.studentData[this.studentIndex].noteItems){
          note.writeInfoStudentObject = null;
          if(note.noteType === noteType.writeInfo){
            // 書き込み情報の場合
            // 問題画像取得
            const quesImg = this.quesImageList.find(quesImg=>{
              if(quesImg.homeworkEdaNo !== note.homeworkEdaNo){
                return false;
              }
              switch(quesImg.kind){
                case homeworkTypeCode.textbook:   return true;
                case homeworkTypeCode.pdf:        return quesImg.pageNo === note.pageNo;
                case homeworkTypeCode.stdbLayout: return quesImg.pageNo === note.pageNo;
                case homeworkTypeCode.stdb:       return quesImg.pageNo === note.pageNo;
                default:  return false;
              }
            });
            if(quesImg){
              // サムネイルに表示するかどうか
              switch(quesImg.kind){
                case homeworkTypeCode.textbook:
                  note.showThumbnail = true;
                  break;
                case homeworkTypeCode.pdf:
                case homeworkTypeCode.stdbLayout:
                case homeworkTypeCode.stdb:
                  note.showThumbnail = note.pageNo === 1;
                  break;
              }
              note.lessonNo = quesImg.lessonNo;
              note.taskName = quesImg.taskName;
              if(quesImg.pageNo > 0){
                note.pageNo = quesImg.pageNo;
                note.maxPage = quesImg.maxPage;
              }
              note.noteFilePath = quesImg.filePath;
              note.writeInfoStudentObject = this.writeInfoStringToObject(note.writeInfoStudent, quesImg.configPage, quesImg.cond);
              note.imageWidth = quesImg.width;
              note.imageHeight = quesImg.height;
            }
          }else{
            // ノート写真の場合
            note.showThumbnail = true;
            const img = await this.loadImage(note.noteFilePath);
            note.imageWidth = img.naturalWidth;
            note.imageHeight = img.naturalHeight;
          }

          note.writeInfoTeacherObject = this.writeInfoStringToObject(note.writeInfoTeacher, this.configPageTeacher, this.condTeacher);

          if (!noteItemsByDate[note.submitDate]) {
            noteItemsByDate[note.submitDate] = {
              lastSubmitAddDate: "",
              noteItems: [],
            }
          }
          var item = noteItemsByDate[note.submitDate];
          if (item.lastSubmitAddDate < note.lastSubmitAddDate) {
            item.lastSubmitAddDate = note.lastSubmitAddDate
          }
          item.noteItems.push(note)
        }
      }
      
      // 日付の降順にソート
      this.noteItemDateList = Object.keys(noteItemsByDate).sort((a, b) => a > b ? -1 : 1)

      this.noteItemsByDate = noteItemsByDate
    },
    /**
     * 画像の読み込み
     * @param {*} src 
     */
    loadImage(src){
      return new Promise((resolve, reject)=>{
        const img = new Image();
        img.onload = ()=>{
          resolve(img);
        };
        img.onerror = e=>reject(e);
        img.src = src;
      });
    },
    /**
     * 書き込み情報(文字列)をオブジェク情報に変換
     * @param {String} value 書き込み情報(文字列) 
     * @param {*} configPage ブックコンフィグ
     * @param {*} cond 書き込み定義
     */
    writeInfoStringToObject(value, configPage, cond) {
      const penTypes = ['path', 'line', 'vartHori', 'rect', 'ellipse'];
      const penToolTypes = ['pen', 'marker', 'ruler'];
      const textEscapeMap = this.getTextEscapeMap();

      const pens = [];
      const texts = [];
      const stamps = [];
      const compasses = [];

      // 返却用オブジェクト
      const returnObj = {
        writeInfo: {
          cond: cond,
          configPage: configPage,
          pens: pens,
          texts: texts,
          stamps: stamps,
          compasses: compasses,
        },
        write_info: ''
      }

      if (!value) {
        return returnObj;
      }

      const newWriteList = [];
      // データ圧縮の為、座標情報や大きさの値を小数第3位までにしてテキスト量を圧縮する
      value.split(',').forEach(item => {
        if (!item) {
          return;
        }
        const arr = item.split(':');
        const writeType = arr[0] ? arr[0] : '';
        const writeVal = arr[1] ? arr[1] : '';
        const writeVals = writeVal.split('<>');
        switch (writeType) {
          case 'pen': // ペン
            {
              const penType = penTypes[writeVals[0]];
              const toolTypeIndex = (penType === 'path') ? 6 : 9;
              let toolType = 'pen';
              if (writeVals[toolTypeIndex]){
                toolType = penToolTypes[parseInt(writeVals[toolTypeIndex])];
              }
              const penInfo = {
                type: penType,
                size: parseInt(writeVals[1]),
                color: parseInt(writeVals[2]),
                opacity: parseInt(writeVals[3]),
                zIndex: parseInt(writeVals[4]),
                toolType: toolType,
              }
              const paramStartIdx = 5;
              switch (penType) {
                case 'path':    // パス
                  {
                    const svgPath = writeVals[paramStartIdx];
                    const pattern = /( *[MLHVCSQTAZ ])( *[0-9]+(\.[0-9]+)?)( *X *)( *[0-9]+(\.[0-9]+)?)/g;
                    let vals = [];
                    for (const match of svgPath.matchAll(pattern)) {
                      // [0] ヒットした全体
                      // [1] ( *[MLHVCSQTAZ ])の部分
                      // [2] 1つ目の( *[0-9]+(\.[0-9]+)?)の部分  変換対象 桁詰め
                      // [3] [2]内の(\.[0-9]+)?の部分
                      // [4] ( *X *)の部分                      変換対象 X前後のスペースは不要(svgではx,y座標を区切るカンマ)
                      // [5] 2つ目の( *[0-9]+(\.[0-9]+)?)の部分  変換対象 桁詰め
                      // [6] [5]内の(\.[0-9]+)?の部分
                      const rep = match[1] + this.roundDecimalPlaces(match[2]) + 'X' + this.roundDecimalPlaces(match[5]);
                      vals.push(rep);
                    }
                    writeVals[paramStartIdx] = vals.join('');
                    if (vals.length === 0) {
                      writeVals[paramStartIdx] = svgPath;
                    }
                    penInfo.path = writeVals[paramStartIdx].replaceAll('X', ',');    // 詳細画面で使用するパスはカンマに戻しておく
                  }
                  break;
                case 'line':        // 直線
                case 'vartHori':    // 水平線、垂直線
                case 'rect':        // 矩形
                case 'ellipse':     // 円、楕円
                  for (let i = 0; i < 4; i++) {
                    writeVals[paramStartIdx + i] = this.roundDecimalPlaces(writeVals[paramStartIdx + i]);
                  }
                  switch (penType) {
                    case 'line':        // 直線
                    case 'vartHori':    // 水平線、垂直線
                      penInfo.x1 = writeVals[paramStartIdx + 0];
                      penInfo.y1 = writeVals[paramStartIdx + 1];
                      penInfo.x2 = writeVals[paramStartIdx + 2];
                      penInfo.y2 = writeVals[paramStartIdx + 3];
                      if (penInfo.x1 === penInfo.x2 && penInfo.y1 === penInfo.y2) {
                        // 直線の開始点と終了点が同じの場合は表示されないので圧縮のためにも除外する
                        return;
                      }
                      break;
                    case 'rect':        // 矩形
                      penInfo.x = writeVals[paramStartIdx + 0];
                      penInfo.y = writeVals[paramStartIdx + 1];
                      penInfo.width = writeVals[paramStartIdx + 2];
                      penInfo.height = writeVals[paramStartIdx + 3];
                      if (penInfo.width === 0 || penInfo.height === 0) {
                        // 矩形の幅、高さがゼロだと表示されないので圧縮のためにも除外する
                        return;
                      }
                      break;
                    case 'ellipse':     // 円、楕円
                      penInfo.cx = writeVals[paramStartIdx + 0];
                      penInfo.cy = writeVals[paramStartIdx + 1];
                      penInfo.rx = writeVals[paramStartIdx + 2];
                      penInfo.ry = writeVals[paramStartIdx + 3];
                      if (penInfo.rx === 0 || penInfo.ry === 0) {
                        // 楕円の半径(x or y)がゼロだと表示されないので圧縮のためにも除外する
                        return;
                      }
                      break;
                  }
                  break;
                default:
                  // 上記以外のペンの種類は対象外
                  return;
              }
              pens.push(penInfo);
            }
            break;
          case 'stamp':
            {
              for (const i of [1, 2]) {
                writeVals[i] = this.roundDecimalPlaces(writeVals[i]);
              }
              const stampInfo = {
                stampIdx: parseInt(writeVals[0]),
                x: writeVals[1],
                y: writeVals[2],
                color: parseInt(writeVals[3]),
                size: parseInt(writeVals[4]),
                zIndex: parseInt(writeVals[5]),
              }
              stamps.push(stampInfo);
            }
            break;
          case 'text':
            {
              for (const i of [4, 5, 6, 7]) {
                writeVals[i] = this.roundDecimalPlaces(writeVals[i]);
              }
              let text = writeVals[8] ? writeVals[8] : '';
              if (text) {
                // エスケープ文字列を元の文字に戻す
                text = text.replaceAll(/=&([^=]+)=/g, (match, p1) => {
                  for(const dic of textEscapeMap){
                    if(p1 === dic[1]){
                      return dic[0];
                    }
                  }
                  return match;
                });
              }
              const textInfo = {
                textColor: parseInt(writeVals[0]),
                textSize: parseInt(writeVals[1]),
                textBackColor: parseInt(writeVals[2]),
                textFont: parseInt(writeVals[3]),
                x: writeVals[4],
                y: writeVals[5],
                width: writeVals[6],
                height: writeVals[7],
                text: text,
                blind: parseInt(writeVals[9]),
                zIndex: parseInt(writeVals[10]),
              }
              texts.push(textInfo);
            }
            break;
          case 'compass': // コンパス
            {
              const compassInfo = {
                  size: parseInt(writeVals[0]),
                  color: parseInt(writeVals[1]),
                  zIndex: parseInt(writeVals[2]),
                  d: "",
                  cx: parseInt(writeVals[4]),
                  cy: parseInt(writeVals[5]),
                  r: parseInt(writeVals[6]),
                  rSub: parseInt(writeVals[7]),
                  isCircle: parseInt(writeVals[8]),
                  isErase: false,
              }
              let svgPath = writeVals[3].replace(/X/g, ",");
              svgPath = svgPath.replace(/S/g, " ")
              compassInfo.d = svgPath;
              compasses.push(compassInfo);
            }
            break;
          default:
            // タブへの書き込みは対象外
            return;
        }
        newWriteList.push(`${writeType}:${writeVals.join('<>')}`);
      });

      // 提出時に送信する書き込み文字列
      returnObj.write_info = newWriteList.join(',');

      return returnObj;
    },
    /** 返却API用の書き込み情報リストを作成して返す */
    getNoteWriteInfoList(){
      const result =[];

      if(this.lastNoteDate){
        const notes = this.noteItemsByDate[this.lastNoteDate].noteItems;
        if(notes){
          notes.forEach(note=>{
            result.push({
              homework_note_no: note.homeworkNoteNo,
              write_info_teacher: this.writeInfoObjectToString(note.writeInfoTeacherObject.writeInfo),
            });
          });
        }
      }

      return result;
    },
    /**
     * 書き込み情報の表示用オブジェクトをAPI送受信用の文字列に変換する
     * @param {*} writeInfoObject 書き込み情報の表示用オブジェクト
     * @returns API送受信用の文字列
     */
    writeInfoObjectToString(writeInfoObject) {
      if (!writeInfoObject) {
        return '';
      }

      const pens = writeInfoObject.pens;
      const texts = writeInfoObject.texts;
      const stamps = writeInfoObject.stamps;

      const penTypes = ['path', 'line', 'vartHori', 'rect', 'ellipse'];

      let results = [];

      // ペン変換
      pens.forEach(pen => {
        const penParams = [
          penTypes.indexOf(pen.type),
          pen.size,
          pen.color,
          pen.opacity,
          pen.zIndex
        ];

        switch (pen.type) {
          case 'path':
            penParams.push(pen.path.replace(/,\s?/g, 'X'));
            break;
          case 'line':
          case 'vartHori':
            if (pen.x1 !== pen.x2 || pen.y1 !== pen.y2) {
              // 開始点と終了点が異なる場合のみ対象
              penParams.push(
                pen.x1,
                pen.y1,
                pen.x2,
                pen.y2
              );
            }
            break;
          case 'rect':
            if (pen.width > 0 && pen.height > 0) {
              // 縦横の幅がある場合のみ対象
              penParams.push(
                pen.x,
                pen.y,
                pen.width,
                pen.height
              );
            }
            break;
          case 'ellipse':
            if (pen.rx > 0 && pen.ry > 0) {
              // 縦横の幅がある場合のみ対象
              penParams.push(
                pen.cx,
                pen.cy,
                pen.rx,
                pen.ry
              );
            }
            break;
          default:
            return;
        }
        results.push(`pen:${penParams.join('<>')}`);
      });

      // スタンプ変換
      stamps.forEach(stamp => {
        const stampParams = [
          stamp.stampIdx,
          stamp.x,
          stamp.y,
          stamp.size,
          stamp.zIndex,
        ];
        results.push(`stamp:${stampParams.join('<>')}`);
      });

      // テキスト変換
      const textEscapeeMap = this.getTextEscapeMap()
      texts.forEach(text => {
        if(text.text === ''){
          // テキスト文字なしはスキップ
          return;
        }
        if(text.width === 0 || text.height === 0){
          // 大きさなしはスキップ
          return;
        }
        // テキスト文字のエスケープ処理
        let txt = text.text;
        textEscapeeMap.forEach(dic => {
          txt = txt.replaceAll(dic[0], `=&${dic[1]}=`);
        });
        const textParams = [
          text.textColor,
          text.textSize,
          text.textBackColor,
          text.textFont,
          text.x,
          text.y,
          text.width,
          text.height,
          txt,
          0,
          text.zIndex,
        ];
        results.push(`text:${textParams.join('<>')}`);
      });
      return results.join(',');
    },
    /** テキストの置換対象文字列定義 */
    getTextEscapeMap() {
      // [0]:置き換え対象文字
      // [1]:置き換え後エスケープ文字 実際は =& をプレフィクス = をサフィクスとしてはさむ
      //     例 abc,def -> abc=&comm=def
      // プレフィクスおよびサフィクスで使用している文字列をエスケープしていないので
      // テキストにエスケープ後文字列を入力すると次回表示時にエスケープ解除してしまう
      //   画面入力で abc=&lt=defと入力すると 次回表示時に abc<def と表示する
      return [
        [',', 'comm'],
        ['<', 'lt;'],
        ['>', 'gt;'],
        [':', 'colo'],
        ["'", 'squt'],
        ['"', 'dqut'],
        ['\\n', 'yen'],
        ['\n', 'ent'],
        ['\\', 'sl']
      ];
    },
    /**
     * 小数点以下3桁にした数値を返す
     * @param {*} value 
     */
    roundDecimalPlaces(value) {
      return parseFloat(parseFloat(value).toFixed(3));
    },
    /**
     * 対象オブジェクトのクローン(別インスタンス)を取得する
     * @param {*} value クローン対象
     * @returns クローンインスタンス
     */
    cloneObject(value){
      return JSON.parse(JSON.stringify(value));
    },
    showIconEdit(note){
      if(note.noteType === noteType.picture){
        return this.isWriteInfoTeacher(note);
      } else {
        return this.noteItemsByDate[note.submitDate].noteItems.some(note2=>note2.homeworkEdaNo === note.homeworkEdaNo && this.isWriteInfoTeacher(note2));
      }
    },
    isWriteInfoTeacher(note){
      if(note.writeInfoTeacherObject){
        const writeInfo = note.writeInfoTeacherObject.writeInfo;
        if(writeInfo){
          if(writeInfo.pens && writeInfo.pens.length > 0){
            return true;
          }
          if(writeInfo.texts && writeInfo.texts.length > 0){
            return true;
          }
          if(writeInfo.stamps && writeInfo.stamps.length > 0){
            return true;
          }
        }
      }
      return false;
    },
    isStudentNotHanded(){
      if(this.studentData && this.studentData.length > this.studentIndex){
        // 未提出者
        if(this.studentData[this.studentIndex].status === handedStatus.notProgress ||
            this.studentData[this.studentIndex].status === handedStatus.progressing ||
            this.studentData[this.studentIndex].status === handedStatus.waitHand){
          return true;
        }
      }
      return false;
    },
  },
}

</script>

<style lang="scss" scoped>
ul {
  li {
    list-style: none;
    padding: 0.4em;
  }
  margin: 0 auto;
}
.modal-mask {
  position: fixed;
  z-index: 98;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;

    .modal-container-outer {
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      .modal-default-button {
        position: absolute;
        left: calc(100vw - 50px);
      }

      .modal-container {
        position: relative;
        width: calc(100vw - 100px);
        height: calc(100vh - 20px);
        margin: auto;
        padding: 15px 44px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        text-align: center;
        .modal-right-content {
          width: 60%;
          height: 100%;
          .modal-right-content-photo {
            height: 320px;
          }
        }
        .modal-contents-area {
          display: flex;
          flex-direction: column;
          height: calc(100% - 60px);
          .modal-contents-area-inner {
            flex-grow: 1;
            min-height: 0;
          }
          .modal-contents-area-left {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 70%;
            padding-right: 30px;
          }
          .modal-contents-area-right {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 30%;
          }
        }
        .modal-contents-area::-webkit-scrollbar {
          display: none;
        }
      }
      .return-annotation-text {
        font-size: 90%;
      }

      .container-fluid {
        position: relative;
        height: 60px;
        ::v-deep .btn {
          padding: 0.3em 1em 0.3em;
          font-size: 14px;
        }

        .show-student-name {
          width: 50%;
          max-width: calc(100% - 300px);
          h6 {
            max-height: 60px;
          }
        }

        .check-everyones-handed-image-area {
          position: absolute;
          top: -6px;
          left: 0;
        }

        .handed-data-area {
          width: 25%;
          min-width: 150px;
        }

        .stamp-eval-wrapper {
          width: 25%;
          min-width: 150px;
        }
      }

      .notebook {
        height: 100%;
        .notebook-wrapper {
          height: 100%;

          div {
            height: 100%;
            ::v-deep .image {
              display: flex;
              height: 100%;
            }
            ::v-deep img {
              display: block;
              width: 100%;
              height: auto;
              padding: 0;
              margin: auto;
            }
          }

          .note-header {
            width: 100%;
            height: 24px;
            padding: 0;
            margin: 0;
            display: table;
            .lesson-no {
              display: table-cell;
              text-align: left;
              padding: 0;
              margin: 0;
              width: auto;
              height: 100%;
              font-weight: bold;
            }

            .icon-edit {
              display: table-cell;
              text-align: right;
              padding: 0;
              margin: 0;
              width: 15px;
              height: 15px;
            }
          }

          .note-content {
            width: 100%;
            height: calc(100% - 24px);
            border: 1px solid #ccc;
          }
        }
      }
      .next-area {
        position: fixed;
        top: 50%;
        right: 57px;
        margin: 0;
        padding: 0;
      }
      .next {
        display: block;
        padding: 20px 15px;
        cursor: pointer;
        height: auto;
        transform: translateY(-50%);

        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          background: #0070c0;
          width: 20px;
          height: 4px;
          transform: rotate(-45deg);
          top: 24px;
          right: 5px;
        }
        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          background: #0070c0;
          width: 20px;
          height: 4px;
          transform: rotate(45deg);
          top: 12px;
          right: 5px;
        }
      }
      .prev-area {
        position: fixed;
        top: 50%;
        left: 57px;
        margin: 0;
        padding: 0;
      }
      .prev {
        display: block;
        padding: 20px 15px;
        cursor: pointer;
        height: auto;
        transform: translateY(-50%);

        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          background: #0070c0;
          width: 20px;
          height: 4px;
          transform: rotate(-45deg);
          top: 12px;
          left: 5px;
        }
        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          background: #0070c0;
          width: 20px;
          height: 4px;
          transform: rotate(45deg);
          top: 24px;
          left: 5px;
        }
      }
    }
  }
}
.icon-wrapper {
  display: flex;
  margin: 0 0 0.6rem;
  span {
    margin: 0.1rem 0 0 1rem;
    font-weight: bold;
  }
}
.photo-area {
  height: 100%;
  overflow-y: scroll;
  padding: 0;
  &-wrapper {
    height: 100%;
    z-index: 1;
  }
  &-list {
    // height: 100%;
    &-item {
      height: 10vw;
    }
  }
  &-date {
    background: #eee;
    display: flex;
    align-items: center;
  }
}
.chat-area-wrapper {
  flex-grow: 1;
  min-height: 0;
}

.modal-student-handed-buttons {
  display: flex;
  justify-content: center;

  &.hidden {
    visibility: hidden;
  }
  .button-border-circle-medium {
    margin: 0 16px;
  }
  .button-border-circle-medium:first-child {
    margin-right: 30px;
  }
  .return-buttons {
    display: flex;
    justify-content: center;
  }
}

// トランジション（アニメーション）設定
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: opacity 0.8s;
}
.setting-preview-image {
  padding: 0%;
}
.no-editing-permission{
  pointer-events: none;
  &>div{
    background-color: rgba(128, 128, 128, 0.3);
  }
}
</style>
