var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "icon-help" }, [
    _c(
      "svg",
      {
        staticStyle: { "enable-background": "new 0 0 70.6 70.6" },
        attrs: {
          id: "レイヤー_1",
          version: "1.1",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          x: "0px",
          y: "0px",
          width: _vm.width,
          height: _vm.height,
          viewBox: "0 0 70.6 70.6",
          "xml:space": "preserve"
        }
      },
      [
        _c("g", [
          _c("path", {
            attrs: {
              fill: _vm.fillColor,
              d:
                "M44.3,20.4c-1-0.9-2.3-1.6-3.7-2c-1.4-0.5-3-0.7-4.7-0.7c-1.6,0-3.1,0.3-4.5,0.8c-1.4,0.5-2.6,1.3-3.6,2.3\n          c-1,1-1.9,2.2-2.5,3.6c-0.4,0.9-0.7,1.9-0.9,2.9c-0.2,0.9,0.6,1.8,1.5,1.8h2.9c1,0,1.9-0.7,2.2-1.7c0.8-2.9,2.5-4.4,5-4.4\n          c0.8,0,1.4,0.1,2.1,0.4c0.6,0.2,1.2,0.6,1.6,1c0.4,0.4,0.8,0.9,1.1,1.5c0.3,0.6,0.4,1.2,0.4,1.9c0,0.4,0,0.7-0.1,1\n          c-0.1,0.3-0.2,0.7-0.4,1c-0.2,0.4-0.6,0.8-1.1,1.3c-0.5,0.5-1.1,1-1.9,1.7c-1.9,1.5-3.1,3-3.8,4.4c-0.4,0.7-0.6,1.7-0.8,2.9\n          c-0.2,1.2-0.3,1.7-0.3,3.5c0,0,0,0,0,0h0c0,0,0,0,0,0h6.1c0-2.6,0.2-3.3,0.5-4c0.2-0.4,0.5-0.8,1-1.4c0.5-0.5,1.2-1.1,2.1-1.8\n          c1.7-1.4,2.9-2.7,3.7-3.9c0,0,0,0,0,0c0.1-0.2,0.2-0.3,0.3-0.5c0.8-1.3,1.1-2.8,1.1-4.5c0-1.4-0.3-2.7-0.9-3.9\n          C46.1,22.3,45.3,21.2,44.3,20.4z"
            }
          }),
          _c("path", {
            attrs: {
              fill: _vm.fillColor,
              d:
                "M39,46.1h-6.3c-0.2,0-0.4,0.2-0.4,0.4v6.1c0,0.2,0.2,0.4,0.4,0.4H39c0.2,0,0.4-0.2,0.4-0.4v-6.1\n          C39.4,46.3,39.2,46.1,39,46.1z"
            }
          }),
          _c("path", {
            attrs: {
              fill: _vm.fillColor,
              d:
                "M35.9,3.1C18.1,3.1,3.6,17.5,3.6,35.3s14.4,32.2,32.2,32.2s32.2-14.4,32.2-32.2S53.7,3.1,35.9,3.1z M35.9,62.5\n          c-15,0-27.2-12.2-27.2-27.2S20.9,8.1,35.9,8.1s27.2,12.2,27.2,27.2S50.9,62.5,35.9,62.5z"
            }
          })
        ]),
        _c("rect", {
          staticClass: "st1",
          attrs: { x: "0.6", y: "0", width: "70.6", height: "70.6" }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }