<template>
  <div class="button-delete">
    <button
      type="button"
      class="btn"
      :disabled="disabled"
    >
      <IconDeleteUpload
        width="25px"
        height="25px"
      />
    </button>
  </div>
</template>

<script>
import IconDeleteUpload from "@/components/atoms/icons/IconDeleteUpload.vue"

export default {
  name: "ButtonDelete",
  components: {
    IconDeleteUpload,
  },

  props: {
    disabled: { type: Boolean, default: false },
  },

  data: () => ({}),
}
</script>

<style lang="scss" scoped></style>
