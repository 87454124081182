var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-select-box" }, [
    _c("fieldset", [
      _c(
        "label",
        {
          staticClass: "form-label",
          class: { "required-label": _vm.isRequire },
          attrs: { for: _vm.id }
        },
        [_vm._v(_vm._s(_vm.labelText))]
      ),
      _vm.isGroupSelect
        ? _c("span", { staticStyle: { "font-size": "85%" } }, [
            _vm._v("    グループの作成・編集は"),
            _c(
              "a",
              {
                attrs: {
                  href: "https://account.chart.co.jp/group/list",
                  target: "_blank"
                }
              },
              [_vm._v("こちら")]
            )
          ])
        : _vm._e(),
      _c(
        "select",
        {
          ref: "selectBox",
          staticClass: "form-select form-select-sm",
          attrs: { disabled: _vm.disabled },
          domProps: { value: _vm.selected },
          on: {
            change: function($event) {
              return _vm.$emit("input", $event.target.value)
            }
          }
        },
        [
          _vm._l(_vm.options, function(option, index) {
            return [
              _c(
                "option",
                {
                  key: index,
                  staticClass: "h6",
                  domProps: { value: option.value }
                },
                [_vm._v(" " + _vm._s(option.label) + " ")]
              )
            ]
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }