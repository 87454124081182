<template>
  <div class="modal-preview-pdf-question">
    <transition
      name="modal"
      appear
    >
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container-outer">
            <ButtonClose
              class="modal-default-button"
              @click.native="$emit('close')"
            />
            <div class="modal-container mx-3 py-4">
              <div class="row mb-5 question-setting mt-3">
                <div class="col-md-4 tree-wrap">
                  <FormTextField
                    :label-text="$t('labels.taskName')"
                    :initial-value="taskName"
                    :disabled="isDisabled"
                    :max-length="50"
                    @input="inputTaskName"
                    @on-blur="blurTaskName"
                  />
                  <div
                    v-if="showMsgTaskName"
                    class="text-danger"
                  >
                    {{ showMsgTaskName }}
                  </div>

                  <br>

                  <div v-once>
                    {{ $t("labels.howToDisplayInViewer") }}
                  </div>
                  <div id="display-method">
                    <div>
                      <label class="px-4 py-1">
                        <input
                          v-model="checkDisplayMethod"
                          type="radio"
                          value="oneQuestion"
                          :disabled="isDisabled || imageQList.length == 0"
                          @click="clickOneQuestion"
                        >
                        {{ $t("labels.oneQuestion") }}
                      </label>
                      <div class="px-5 py-1">
                        <!-- Aがない場合チェック非表示 -->
                        <label>
                          <input
                            id="chkAnswer"
                            v-model="answerValue"
                            type="checkbox"
                            :disabled="
                              checkDisplayMethod == 'followLayout' ||
                                isDisabled ||
                                imageQList.length == 0 ||
                                imageAList.length == 0
                            "
                            @change="onChangedCheckboxValue"
                          >
                          <label for="chkAnswer">{{
                            $t("labels.answer")
                          }}</label>
                          &nbsp;&nbsp;
                        </label>
                        <label>
                          <input
                            id="chkExplanation"
                            v-model="explanationValue"
                            type="checkbox"
                            :disabled="
                              checkDisplayMethod == 'followLayout' ||
                                isDisabled ||
                                imageQList.length == 0 ||
                                imageEList.length == 0
                            "
                            @change="onChangedCheckboxValue"
                          >
                          <label for="chkExplanation">{{
                            $t("labels.explanation")
                          }}</label>
                        </label>
                      </div>
                    </div>
                    <!-- Docがない場合、ラジオ非表示 -->
                    <div>
                      <label class="mx-4 py-1">
                        <input
                          v-model="checkDisplayMethod"
                          type="radio"
                          value="followLayout"
                          :disabled="isDisabled || imageDocList.length == 0"
                          @click="clickFollowLayout"
                        >
                        {{ $t("labels.followLayout") }}
                      </label>
                    </div>
                  </div>

                  <br>

                  <FormTextField
                    :label-text="$t('labels.sendMemo')"
                    :initial-value="sendMemo"
                    :disabled="isDisabled"
                    :max-length="50"
                    @input="inputSendMemo"
                    @on-blur="blurSendMemo"
                  />
                  <div
                    v-if="showMsgSendMemo"
                    class="text-danger"
                  >
                    {{ showMsgSendMemo }}
                  </div>

                  <br>
                  <div>
                    <p v-once>
                      {{ $t("labels.questionQuantity") }}
                    </p>
                    <div
                      class="question-area"
                      style="overflow-y: auto"
                    >
                      <div class="question-amount mx-3">
                        <div
                          v-for="(item, index) in questionQuantityList"
                          :key="index"
                        >
                          <div class="row py-1">
                            <div
                              v-if="stdbPreviewNum === 0"
                              class="col-2 text-center"
                            >
                              {{ "[" + (Number(index) + 1) + "]" }}
                            </div>
                            <div
                              v-else
                              class="col-2 text-center"
                            >
                              {{ "[" + stdbPreviewNum + "]" }}
                            </div>
                            <div
                              class="col-2"
                              :style="{
                                visibility: `${
                                  isDisabled ? 'hidden' : 'visible'
                                }`,
                              }"
                              @click="onClickQuestionCountMinus(index)"
                            >
                              <i class="icon-minus bi bi-dash-circle-fill" />
                            </div>
                            <div class="col-4 text-center">
                              {{
                                $t("labels.questionNumber", { number: item })
                              }}
                            </div>
                            <div
                              class="col-2"
                              :style="{
                                visibility: `${
                                  isDisabled ? 'hidden' : 'visible'
                                }`,
                              }"
                              @click="onClickQuestionCountPlus(index)"
                            >
                              <i class="icon-plus bi bi-plus-circle-fill" />
                            </div>
                            <!-- <div class="col-2"></div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-8 preview-image-container">
                  <div class="card w-100">
                    <div class="card-header">
                      {{ $t("labels.preview") }}
                    </div>
                    <div v-if="checkDisplayMethod == 'oneQuestion'">
                      <div class="window-body">
                        <div v-if="stdbPreviewNum === 0">
                          {{ "[" + (oneQuestionCount + 1) + "]" }}
                        </div>
                        <div v-else>
                          {{ "[" + stdbPreviewNum + "]" }}
                        </div>
                        <ul
                          class="prev-next"
                          style="list-style: none"
                        >
                          <li
                            v-show="
                              checkDisplayMethod == 'oneQuestion' &&
                                oneQuestionCount > 0
                            "
                            class="bi bi-chevron-left"
                            style="top: 45%"
                            @click="onClickArrowMinus"
                          />
                          <li
                            v-show="
                              checkDisplayMethod == 'oneQuestion' &&
                                oneQuestionCount < imageQList.length - 1
                            "
                            class="bi bi-chevron-right"
                            style="top: 45%"
                            @click="onClickArrowPlus"
                          />
                        </ul>
                        <div class="px-3 py-3 card-body preview-window">
                          <div class="card overflow-auto h-100">
                            <SettingPreviewImage
                              v-if="imageQList.length"
                              :image="imageQList[oneQuestionCount].file_image"
                              class="card-body"
                            />
                          </div>
                        </div>
                        <div class="card-body">
                          <SettingPreviewArea
                            ref="previewArea"
                            :list="settingList[oneQuestionCount]"
                            :stdb-flg="true"
                          />
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="window-body-layout">
                        <div class="h-auto text-end">
                          {{ layoutCount + 1 + " / " + imageDocList.length }}
                        </div>
                        <div class="preview-window">
                          <div class="preview-layout">
                            <div class="px-3 py-3 card-body">
                              <div
                                class="card"
                                style="overflow: auto !important"
                              >
                                <img
                                  v-if="imageDocList.length"
                                  class="container-fluid"
                                  :src="imageDocList[layoutCount].file_image"
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                        <ul
                          class="prev-next py-1"
                          style="list-style: none"
                        >
                          <li
                            v-show="
                              checkDisplayMethod == 'followLayout' &&
                                layoutCount > 0
                            "
                            class="bi bi-chevron-left"
                            style="top: 45%"
                            @click="onClickArrowMinus"
                          />
                          <li
                            v-show="
                              checkDisplayMethod == 'followLayout' &&
                                layoutCount < imageDocList.length - 1
                            "
                            class="bi bi-chevron-right"
                            style="top: 45%"
                            @click="onClickArrowPlus"
                          />
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  v-if="!isDisabled && checkDisplayMethod == 'followLayout'"
                  class="row px-0 mb-3"
                >
                  <div
                    class="col-12"
                    style="text-align: right"
                  >
                    <div
                      style="
                        font-size: 70%;
                        text-align: left;
                        position: relative;
                        display: inline-block;
                      "
                    >
                      {{ $t("labels.recommendPageMessage") }}
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="!isDisabled"
                class="row gx-5 justify-content-center"
              >
                <div class="col-auto">
                  <ButtonBorderCircleMedium
                    :label-name="$t('buttons.cancel')"
                    :label-color="colorLayoutTheme"
                    :border-color="colorLayoutTheme"
                    @click.native="$emit('close')"
                  />
                </div>
                <div class="col-auto">
                  <!-- 保存処理 -->
                  <ButtonBorderCircleMedium
                    :label-name="$t('buttons.save')"
                    color="layout-theme-light"
                    :label-color="colorLayoutTheme"
                    :border-color="colorLayoutTheme"
                    :disabled="isProcessing"
                    @click.native="onClickSave"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/**
 * 宿題管理 - STDBプレビューモーダル
 */
import ButtonClose from "@/components/atoms/buttons/ButtonClose.vue"
import FormTextField from "@/components/atoms/FormTextField.vue"
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"
import SettingPreviewImage from "@/components/atoms/SettingPreviewImage.vue"
import SettingPreviewArea from "@/components/organisms/SettingPreviewArea.vue"

import { displayFlagCode, settingTabIdCode } from "@/constant/question.js"
import { homeworkTypeCode } from "@/constant/homework"

export default {
  name: "ModalPreviewSTDBQuestion",
  components: {
    ButtonClose,
    FormTextField,
    ButtonBorderCircleMedium,
    SettingPreviewImage,
    SettingPreviewArea,
  },
  props: {
    taskName: {
      type: String,
      require: true,
      default: function () {
        return ""
      },
    },

    sendMemo: {
      type: String,
      require: true,
      default: function () {
        return ""
      },
    },

    imageList: {
      type: Array,
      default: function () {
        return []
      },
    },

    prmQuestionQuantity: {
      type: null,
      default: function () {
        return 0
      },
    },

    prmQuestionType: {
      type: Number,
      default: function () {
        return 0
      },
    },

    // 答えチェックボックスの値
    ansDispFlg: {
      type: Boolean,
      default: false,
    },

    // 解説チェックボックスの値
    explainDispFlg: {
      type: Boolean,
      default: false,
    },

    isDisabled: { type: Boolean, default: false },

    // isHiddenQuesCount: { type: Boolean, default: false },
  },

  data: () => ({
    colorLayoutTheme: "#ff7f27",
    messageDisplayFlg: false, // メッセージ表示フラグ
    modalSendMemo: "", // 送信メモ
    modalTaskName: "", // 課題名
    showMsgTaskName: "", // 課題名エラーメッセージ
    showMsgSendMemo: "", // 送信メモエラーメッセージ
    oneQuestionCount: 0, // 一問ずつ画像の何番目かのカウンター
    layoutCount: 0, // レイアウト通り画像の何番目かのカウンター
    answerValue: false, // 答えチェックボックスの値
    explanationValue: false, // 解説チェックボックスの値

    imageQList: [], // 問題画像リスト
    imageEList: [], // 解説画像リスト
    imageAList: [], // 答え画像リスト
    imageDocList: [], // レイアウト通り画像リスト

    /**
     * 画像表示エリア下部分に表示するjsonデータ
     * 例：
     * [
     *  { "id": 1, "label": "答", "content": "data:image/png;base64,iVBORw0KGgo..." },
     *  { "id": 2, "label": "解説", "content": "data:image/png;base64,iVBORw0KGgo..." },
     * ]
     */
    settingList: [],
    checkDisplayMethod: "oneQuestion", // 選択中のラジオボタンの文字列
    isCheckBoxDisabled: false, // チェックボックスが無効状態かどうか
    questionQuantityList: [], // 問題数入力
    tempQuestionQuantityList: [], // 問題数保存領域
    isLayoutPreview: false,
    stdbPreviewNum: 0,
    isProcessing: false, // 処理中？
  }),

  beforeMount: function () {
    // 画像の振り分け
    this.imageDocList = this.imageList.filter((item) => {
      // 前方一致"{数値}"
      return item.file_name.match(/^\d/)
    })
    this.imageQList = this.imageList.filter((item) => {
      // 前方一致"Q{数値}"
      return item.file_name.match(/^Q\d/)
    })
    this.imageEList = this.imageList.filter((item) => {
      // 前方一致"E{数値}"
      return item.file_name.match(/^E\d/)
    })
    this.imageAList = this.imageList.filter((item) => {
      // 前方一致"A{数値}"
      return item.file_name.match(/^A\d/)
    })

    // プレビュー表示の場合
    if (this.isDisabled) {
      // パラメータあり
      var toString = Object.prototype.toString
      if (
        0 <
        toString.call(this.prmQuestionQuantity).toLowerCase().indexOf("number")
      ) {
        this.questionQuantityList = [this.prmQuestionQuantity]
      } else {
        this.questionQuantityList = this.prmQuestionQuantity
      }

      this.isLayoutPreview =
        this.prmQuestionType === homeworkTypeCode.stdbLayout

      // 親問題の場合シーケンス番号を抽出
      let rowNum = this.taskName.match(/\(\d{1,3}\)$/)
      if (!this.isLayoutPreview && rowNum !== null) {
        // シーケンス番号を取得
        this.stdbPreviewNum = rowNum[0].length
          ? rowNum[0].replace("(", "").replace(")", "")
          : 0
      }

      this.checkDisplayMethod = this.isLayoutPreview
        ? "followLayout"
        : "oneQuestion"

      this.answerValue = JSON.parse(JSON.stringify(this.ansDispFlg))
      this.explanationValue = JSON.parse(JSON.stringify(this.explainDispFlg))
    } else {
      if (!this.imageQList.length) {
        // DOCファイルしかない場合
        this.checkDisplayMethod = "followLayout"
      }
      // 初期表示
      this.setupQuestionQuantityList()
    }
    // 入力項目の初期設定
    this.modalTaskName = this.taskName
    this.modalSendMemo = this.sendMemo
  },

  mounted: function () {
    // 一問ずつの場合タブを表示
    if (this.prmQuestionType === homeworkTypeCode.stdb) {
      // 一問ずつの場合タブを表示
      this.answerValue = 0 < this.imageAList.length
      this.explanationValue = 0 < this.imageEList.length
      // タブの生成
      this.onChangedCheckboxValue()
      // タブ選択の設定
      this.$refs.previewArea.setCurrentId(settingTabIdCode.answerId)
    }
    this.$emit("preview-open-flg")
  },

  methods: {
    /**
     * 問題数をセット
     */
    setupQuestionQuantityList: function () {
      const temp = this.tempQuestionQuantityList
      this.tempQuestionQuantityList = JSON.parse(
        JSON.stringify(this.questionQuantityList)
      )
      if (!temp.length) {
        this.questionQuantityList.splice(0, this.questionQuantityList.length)
        if (this.checkDisplayMethod === "oneQuestion") {
          for (var i = 0; i < this.imageQList.length; i++) {
            this.questionQuantityList[i] = 1
          }
        } else {
          //問題数は1つ
          this.questionQuantityList[0] = 1
        }
      } else {
        this.questionQuantityList = temp
      }
    },
    /**
     * 登録処理
     */
    onClickSave: function () {
      if (this.isProcessing || this.isError()) return

      this.isProcessing = true
      let imgList = []
      let questionType
      if (this.checkDisplayMethod == "oneQuestion") {
        // 一問ずつ
        /*
          以下の形式のオブジェクト配列を作成
          imgList = [
            [ １ページ目（答えチェックなければimageA、解説チェックなければimageEプロパティは無し）
              {file_name:"ファイル名", file_image:"data:image/png;base64,iVBO..."},
              {file_name:"ファイル名", file_image:"data:image/png;base64,iVBO..."},
              {file_name:"ファイル名", file_image:"data:image/png;base64,iVBO..."},
            ],
            [ ２ページ目
            ...
          ]
        */
        for (let i = 0; i < this.imageQList.length; i++) {
          let imgItem = []
          imgItem.push(this.imageQList[i])
          if (this.answerValue) {
            imgItem.push(this.imageAList[i])
          }
          if (this.explanationValue) {
            imgItem.push(this.imageEList[i])
          }
          imgList.push(imgItem)
        }
        questionType = homeworkTypeCode.stdb
      } else {
        // レイアウト通り
        /*
          以下の形式のオブジェクト配列を作成
          imgList = [
            １ページ目
            {file_name:"ファイル名", file_image:"data:image/png;base64,iVBO..."},
            ２ページ目
            {file_name:"ファイル名", file_image:"data:image/png;base64,iVBO..."},
            ...
          ]
        */
        imgList = this.imageDocList

        questionType = homeworkTypeCode.stdbLayout
      }

      this.$emit("save", {
        taskName: this.modalTaskName, // 課題名
        sendMemo: this.modalSendMemo, // 送信メモ
        questionType: questionType, // 問題タイプ
        questionCount: this.questionQuantityList, // 問題数
        img: imgList, // 画像
        ansDispFlg: this.answerValue, // 答え表示フラグ
        explainDispFlg: this.explanationValue, // 解説表示フラグ
      })
    },

    /**
     * 表示画像のインデックスカウントアップ
     */
    onClickArrowPlus: function () {
      if (this.checkDisplayMethod == "oneQuestion") {
        this.oneQuestionCount =
          (this.oneQuestionCount + 1) % this.imageQList.length
      } else {
        this.layoutCount = (this.layoutCount + 1) % this.imageDocList.length
      }
    },

    /**
     * 表示画像のインデックスカウントダウン
     */
    onClickArrowMinus: function () {
      if (this.checkDisplayMethod == "oneQuestion") {
        this.oneQuestionCount = this.oneQuestionCount - 1
        if (this.oneQuestionCount < 0) {
          this.oneQuestionCount = this.oneQuestionCount + this.imageQList.length
        }
      } else {
        this.layoutCount = this.layoutCount - 1
        if (this.layoutCount < 0) {
          this.layoutCount = this.layoutCount + this.imageDocList.length
        }
      }
    },

    /**
     * 問題数の[+]押したとき
     */
    onClickQuestionCountPlus: function (number) {
      this.$set(
        this.questionQuantityList,
        number,
        this.questionQuantityList[number] + 1
      )
      // 最大50まで
      if (this.questionQuantityList[number] > 50) {
        this.questionQuantityList[number] = 50
      }
    },

    /**
     * 問題数の[-]押したとき
     */
    onClickQuestionCountMinus: function (number) {
      this.$set(
        this.questionQuantityList,
        number,
        this.questionQuantityList[number] + -1
      )
      // 最小1まで
      if (this.questionQuantityList[number] < 1) {
        this.questionQuantityList[number] = 1
      }
    },

    /**
     * チェックボックスの値が変化した時
     */
    onChangedCheckboxValue: function () {
      this.settingList.splice(0)

      for (var i = 0; i < this.imageQList.length; i++) {
        if (this.answerValue) {
          if (this.explanationValue) {
            this.settingList.push(
              ...[
                [
                  {
                    id: settingTabIdCode.answerId,
                    label: this.$t("labels.answer"),
                    content: this.imageAList.length
                      ? this.imageAList[i].file_image
                      : "",
                    displayFlag: displayFlagCode.display,
                    tagIndex: 1,
                  },
                  {
                    id: settingTabIdCode.explainId,
                    label: this.$t("labels.explanation"),
                    content: this.imageEList.length
                      ? this.imageEList[i].file_image
                      : "",
                    displayFlag: displayFlagCode.display,
                    tagIndex: 2,
                  },
                ],
              ]
            )
          } else {
            this.settingList.push(
              ...[
                [
                  {
                    id: settingTabIdCode.answerId,
                    label: this.$t("labels.answer"),
                    content: this.imageAList.length
                      ? this.imageAList[i].file_image
                      : "",
                    displayFlag: displayFlagCode.display,
                    tagIndex: 1,
                  },
                  {
                    id: settingTabIdCode.explainId,
                    label: this.$t("labels.explanation"),
                    content: this.imageEList.length
                      ? this.imageEList[i].file_image
                      : "",
                    displayFlag: displayFlagCode.grayout,
                    tagIndex: 2,
                  },
                ],
              ]
            )
            this.$refs.previewArea.setCurrentId(settingTabIdCode.answerId)
          }
        } else {
          if (this.explanationValue) {
            this.settingList.push(
              ...[
                [
                  {
                    id: settingTabIdCode.answerId,
                    label: this.$t("labels.answer"),
                    content: this.imageAList.length
                      ? this.imageAList[i].file_image
                      : "",
                    displayFlag: displayFlagCode.grayout,
                    tagIndex: 1,
                  },
                  {
                    id: settingTabIdCode.explainId,
                    label: this.$t("labels.explanation"),
                    content: this.imageEList.length
                      ? this.imageEList[i].file_image
                      : "",
                    displayFlag: displayFlagCode.display,
                    tagIndex: 2,
                  },
                ],
              ]
            )
            this.$refs.previewArea.setCurrentId(settingTabIdCode.answerId)
          }
        }
      }

      this.isCheckBoxDisabled = !this.isCheckBoxDisabled
    },

    /**
     * ラジオボタン「１問ずつ」が選択された
     */
    clickOneQuestion: async function () {
      // タブの生成
      await (this.checkDisplayMethod = "oneQuestion")
      // タブ選択の設定
      this.$refs.previewArea.setCurrentId(settingTabIdCode.answerId)
      // 問題数の再設定
      this.setupQuestionQuantityList()
    },

    /**
     * ラジオボタン「レイアウト通り」が選択された
     */
    clickFollowLayout: function () {
      this.checkDisplayMethod = "followLayout"
      // 問題数の再設定
      this.setupQuestionQuantityList()
    },

    /**
     * 課題名入力値取得
     */
    inputTaskName: function (text) {
      this.modalTaskName = text
    },

    /**
     * 送信メモ入力値取得
     */
    inputSendMemo: function (text) {
      this.modalSendMemo = text
    },

    /**
     * 課題名フォーカスアウト処理
     */
    blurTaskName: function (text) {
      this.showMsgTaskName = ""
      // 課題名必須チェック
      if (text.length > 50) {
        this.showMsgTaskName = this.$t("messages.error.overTextLength", {
          maxLength: "50",
        })
      }
    },

    /**
     * 送信メモフォーカスアウト処理
     */
    blurSendMemo: function (text) {
      this.showMsgSendMemo = ""
      // 送信メモ必須チェック
      if (text.length > 50) {
        this.showMsgSendMemo = this.$t("messages.error.overTextLength", {
          maxLength: "50",
        })
      }
    },

    /**
     * エラーチェック
     */
    isError: function () {
      let retFlg = false
      if (this.modalTaskName.length <= 0) {
        this.showMsgTaskName = this.$t("messages.error.required")
        retFlg = true
      } else {
        this.blurTaskName(this.modalTaskName)
        this.blurSendMemo(this.modalSendMemo)
        if (this.showMsgTaskName || this.showMsgSendMemo) {
          retFlg = true
        }
      }
      return retFlg
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;

    .modal-container-outer {
      width: 80vw;
      margin-left: auto;
      margin-right: auto;

      .modal-default-button {
        text-align: right;
      }

      .modal-container {
        overflow: auto;
        max-height: 80vh;
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
        padding: 20px 30px;
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
      }
    }
  }
}

.preview-image-container {
  height: max-content;

  .card-header {
    height: 7%;
    padding: 0.4rem;
    border-bottom: none;
    background: var(--bs-table-header);
    color: var(--bs-layout-theme);
    font-weight: bold;
  }
}

.window-body,
.window-body-layout {
  margin-bottom: 3%;
  padding: 2% 6%;
  max-height: 66vh;
  min-height: 500px;

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  li {
    position: absolute;
    top: 50%;
    font-size: 48px;
    width: 25px;
    height: 35px;
    cursor: pointer;
    left: -8px;
    color: #0070c0;
  }

  li:nth-of-type(2) {
    left: auto;
    right: 20px;
  }
}

span.number {
  display: inline-block;
  width: 35px;
}

.preview-window {
  height: 200px;
}

.window-body-layout {
  div {
    height: 420px;
    img {
      -webkit-user-drag: none;
      user-select: none;
    }
  }
}

// 問題数の数字部分
.question-amount {
  @media (max-width: 1020px) {
    padding-left: 0;
  }

  li {
    div {
      min-width: 210px;
    }
  }
}

.question-area {
  height: 220px;

  .icon-minus:before,
  .icon-plus:before {
    color: #0070c0;

    font-size: 120%;
    vertical-align: bottom;
    cursor: pointer;
  }
}
</style>
