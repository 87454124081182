var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "col preview preview-wrapper" }, [
    _c("div", { staticClass: "card w-100" }, [
      _c("div", { staticClass: "card-header" }, [
        _vm._v(" " + _vm._s(_vm.$t("labels.preview")) + " ")
      ]),
      _c("div", [
        _c("div", { staticClass: "px-3 py-3 card-body preview-window" }, [
          _c(
            "div",
            { staticClass: "card overflow-auto h-100" },
            [
              _c("SettingPreviewImage", {
                staticClass: "card-body",
                attrs: { image: _vm.image }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "card-body" },
          [
            _c("SettingPreviewArea", {
              ref: "previewArea",
              attrs: {
                list: _vm.settingList,
                "message-display-flg": _vm.messageDisplayFlg,
                "stdb-flg": _vm.stdbFlg
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }