<template>
  <div class="tree-question">
    <div class="container-md p-0 h-100">
      <div
        class="card h-100"
        style="padding-top: 1rem;"
      >
        <ul v-if="displayAllCheckFlg">
          <li
            key="allcheck"
            class="tree"
          >
            <input
              id="allcheck"
              v-model="allCheckFlg"
              type="checkbox"
              @change="onChangedFlg()"
            >
            {{ $t("labels.selectAll") }}
          </li>
        </ul>
        <TreeCheckBox
          ref="treeCheckBox"
          :list="list"
          :selected-item="selectedItem"
          :grayout-hidden-mode="grayoutHiddenMode"
          class="tree-checkbox"
          v-on="{
            'clicked-question-title': emitQuestionImage,
            'change-preview-tabs': emitChangePreviewTabs,
            'changed-child-flg': onChangedChild,
            'change-display-message': emitChangeDisplayMessage,
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TreeCheckBox from "@/components/atoms/TreeCheckBox.vue"
import { displayFlagCode } from "@/constant/question.js"

export default {
  name: "TreeQuestion",
  components: { TreeCheckBox },
  props: {
    list: {
      type: Array,
      default: function () {
        return []
      },
    },
    // グレーアウトしているものを非表示にするか？
    grayoutHiddenMode: { type: Boolean, default: false },
    displayAllCheckFlg: { type: Boolean, default: false },
  },
  data() {
    return {
      allCheckFlg: false,

      // 現在選択されている問題
      // ※TreeCheckBoxにブロードキャストする為のもの
      selectedItem: null,
    }
  },
  mounted() {
    this.updateAllCheck()
  },
  methods: {
    /**
     * 値を再帰的に一括変更する処理（全選択/全解除処理）
     */
    bulkValueChange(list) {
      let anyChecked = false
      list.forEach((item) => {
        if (item.nodes) {
          const check = this.bulkValueChange(item.nodes)
          // 問答無用で再描画を走らせる為、一旦反転させたものを突っ込む
          this.$set(item, "checkFlg", !check)
          this.$set(item, "checkFlg", check)
          if (check) {
            anyChecked = true
          }
        } else {
          if (item.displayFlag !== displayFlagCode.grayout) {
            this.$set(item, "checkFlg", this.allCheckFlg)
            if (item.checkFlg) {
              anyChecked = true
            }
          }
        }
      })
      return anyChecked
    },
    /**
     * 全選択/全解除チェック時処理
     */
    onChangedFlg() {
      this.bulkValueChange(this.list)
      this.$emit("clicked-select-all", this.list)
      this.$emit("on-dirty")
    },
    /**
     * 画像パスを親画面に返す処理
     */
    emitQuestionImage(item) {
      this.$emit("clicked-question-title", item)
      this.selectedItem = item
    },
    /**
     * タブの差し替えを親要素に返す
     */
    emitChangePreviewTabs(item) {
      this.$emit("change-preview-tabs", item)
    },
    /**
     * 子要素に更新が入った
     */
    onChangedChild() {
      this.updateAllCheck()
      this.$emit("on-dirty")
    },
    /**
     * 子要素更新時の処理
     */
    onChangedChildFlg() {
      this.$refs.treeCheckBox.onChangedChildFlg()
    },
    /**
     * 「全てチェック」のチェックボックスを更新
     */
    updateAllCheck() {
      let allChecked = true
      this.list.forEach(item => {
        allChecked = allChecked && this.isAllChildrenChecked(item)

      })
      this.allCheckFlg = allChecked
    },
    /**
     * 全ての子、孫にチェックが入っているかどうかを判定する再帰関数
     */
    isAllChildrenChecked(item) {
      // 子要素が存在する場合true
      if (this.isFolder(item)) {
        let allChecked = true
        item.nodes.forEach((node) => {
          if (!this.isAllChildrenChecked(node) && !this.isGrayOut(node)) {
            allChecked = false
          }
        })
        return allChecked
      }
      return item.checkFlg
    },
    /**
     * グレーアウトするか？
     */
    isGrayOut(item) {
      if (
        "displayFlag" in item &&
        item.displayFlag === displayFlagCode.grayout
      ) {
        return true
      }
      if (!this.isFolder(item)) {
        return false
      }
      let hasGrayoutChild = true
      item.nodes.forEach((child) => {
        if (hasGrayoutChild && !this.isGrayOut(child)) {
          hasGrayoutChild = false
        }
      })
      return hasGrayoutChild
    },
    /**
     * 要素内に子要素を含むかを返す処理
     */
    isFolder(item) {
      return "nodes" in item && item.nodes && item.nodes.length > 0
    },
    /**
     * チェックが入った時にメッセージの表示、非表示を切り替える
     */
    emitChangeDisplayMessage(item) {
      this.$emit("change-display-message", item)
    },
    /**
     * 「すべて選択」のチェックボックスを強制的に外す
     * ※リストの選択状態には干渉しない
     */
    uncheckAllCheckBox() {
      this.allCheckFlg = false
    },
  },
}
</script>

<style lang="scss" scoped>
.tree-question {
  flex-direction: column;
  flex: 0 0 23.75%;
  margin-right: 1.9%;
  max-height: auto;
}
.tree-checkbox {
  overflow-y: auto;
  height: 100%;
  @media (max-width: 992px) {
    overflow-x: auto;
  }
}

ul {
  list-style: none;
}
.tree {
  display: block;
}

#allcheck {
  margin-top: 1rem;
}
.label {
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
