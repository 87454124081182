<template>
  <div class="button-apple-login">
    <button
      type="button"
      class="btn"
      :style="buttonStyle"
    >
      <div class="d-flex flex-row align-item-center">
        <IconAppleLoginLogo class="col-12" />
      </div>
    </button>
  </div>
</template>

<script>
import IconAppleLoginLogo from "@/components/atoms/icons/IconAppleLoginLogo.vue"

export default {
  name: "ButtonAppleLogin",
  components: {
    IconAppleLoginLogo,
  },
  props: {
    width: {
      type: String,
      default: function () {
        return ''
      },
    },
  },

  computed: {
    buttonStyle: function () {
      return {
        width: `${this.width}`,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.btn {
  background-color: transparent;
  border: none;
  padding: none;
  outline: none;
}
.button-apple-login > button{
  padding: 0;
}
</style>