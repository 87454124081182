<template>
  <!-- TODO:CSSを共通処理に移動 -->
  <div class="error-background">
    <div
      class="container"
      style="height: 100%"
    >
      <div class="row justify-content-center">
        <div class="col-12 col-xl-9">
          <FailedLoginContent />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FailedLoginContent from "@/components/organisms/FailedLoginContent.vue"
/**
 * ソーシャルログイン失敗時 画面
 * ※コンポーネント名の先頭に数字は使用しない事
 */
export default {
  name: "FailedLogin",
  components: {
    FailedLoginContent,
  },
}
</script>

<style lang="scss" scoped>
.error-background {
  min-height: 100vh;
}
</style>
