var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "icon-answer-status" }, [
    _c(
      "svg",
      {
        staticStyle: { "enable-background": "new 0 0 128.5 120.3" },
        attrs: {
          id: "レイヤー_1",
          version: "1.1",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          x: "0px",
          y: "0px",
          width: _vm.width,
          height: _vm.height,
          viewBox: "0 0 128.5 120.3",
          "xml:space": "preserve"
        }
      },
      [
        _c("g", [
          _c("polygon", {
            staticStyle: { fill: "#EF7B2F" },
            attrs: { points: "58.3,50.7 81.1,50.7 76.6,55.4 58.3,55.4 \t" }
          }),
          _c("polygon", {
            staticStyle: { fill: "#EF7B2F" },
            attrs: { points: "58.3,39.2 93.5,39.2 88.8,44 58.3,44 \t" }
          }),
          _c("polygon", {
            staticStyle: { fill: "#EF7B2F" },
            attrs: { points: "58.3,70.8 63.1,70.8 59.4,75.5 58.3,75.5 \t" }
          }),
          _c("path", {
            staticStyle: { fill: "#EF7B2F" },
            attrs: {
              d:
                "M123.6,44.8l-11.7-11.7c-1-0.7-2.3-0.7-3.1,0.2L91.1,51l-7.3,7.3L58.4,83.7L58,84.9l-1.2,4L55,94.5l-1.3,4\n        l-1.4,4.4c-0.1,0.2-0.1,0.4,0,0.6c0,0.1,0,0.2,0.1,0.3c0,0,0,0,0,0c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0,0,0,0c0.1,0.1,0.1,0.1,0.2,0.2\n        c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0\n        c0.1,0,0.1,0,0.2,0l19.2-6l10.5-10.5l7.3-7.3l32.6-32.6c0.1-0.1,0.2-0.2,0.3-0.3C124.7,47.1,124.5,45.7,123.6,44.8z M75,92l-0.9-3\n        l9.8-9.8l7.3-7.3l27.3-27.3l2,2L91.1,75.9l-1.5,1.5l-5.8,5.8l-6,6L75,92z M60.8,98.9c-0.2-0.5-0.5-1.1-1.1-1.6\n        c-0.5-0.5-1.1-0.9-1.6-1.1l2.3-7.3l1-3.3l0.7-0.7l1.3-1.3l4.3,1.3l0.4,0.1v3.9v0.5h4.3l1.2,4l-2.2,2.2L60.8,98.9z M117.2,43.3\n        L91.1,69.4l-7.3,7.3L72.9,87.6h-3.1v-3.1l14-14l7.3-7.3l23-23L117.2,43.3z M91.1,60.8L83.9,68L68.5,83.3l-2.2-0.7l-0.7-0.2\n        l-0.2-0.1l-0.1,0l10.4-10.4l8.2-8.2l7.3-7.3l9.6-9.6l9.9-9.9l2.2,2.2L91.1,60.8z"
            }
          }),
          _c("path", {
            staticStyle: { fill: "#EF7B2F" },
            attrs: {
              d:
                "M113.5,66.2L113.5,66.2l-6.4,6.3v36.1c0,1.2-1,2.1-2.1,2.1H13.7c-1.2,0-2.1-1-2.1-2.1V20.9\n        c0-1.2,1-2.1,2.1-2.1c0,0,16.7,0,16.7,0h41.3c0,0,33.4,0,33.4,0c1.2,0,2.1,1,2.1,2.1V28l6.4-6.4v-8.4c0-4.7-3.8-8.5-8.5-8.5H71.7\n        c0,0,0,0,0,0H30.4c0,0,0,0,0,0H13.7c-4.7,0-8.5,3.8-8.5,8.5v95.4c0,4.7,3.8,8.5,8.5,8.5H105c4.7,0,8.5-3.8,8.5-8.5V77.2V66.2\n        L113.5,66.2z M103.4,8.1c1.8,0,3.3,1.5,3.3,3.3c0,1.8-1.5,3.3-3.3,3.3c-1.8,0-3.3-1.5-3.3-3.3C100,9.6,101.5,8.1,103.4,8.1z\n        M94.5,8.1c1.8,0,3.3,1.5,3.3,3.3c0,1.8-1.5,3.3-3.3,3.3c-1.8,0-3.3-1.5-3.3-3.3C91.1,9.6,92.6,8.1,94.5,8.1z M85.1,8.1\n        c1.8,0,3.3,1.5,3.3,3.3c0,1.8-1.5,3.3-3.3,3.3c-1.8,0-3.3-1.5-3.3-3.3C81.7,9.6,83.2,8.1,85.1,8.1z"
            }
          }),
          _c("path", {
            staticStyle: { fill: "#EF7B2F" },
            attrs: {
              d:
                "M34.7,60.7c-7.5,0-13.5-6.1-13.5-13.5s6.1-13.5,13.5-13.5c7.5,0,13.5,6.1,13.5,13.5S42.1,60.7,34.7,60.7z\n        M34.7,39.7c-4.1,0-7.4,3.3-7.4,7.4s3.3,7.4,7.4,7.4s7.4-3.3,7.4-7.4S38.8,39.7,34.7,39.7z"
            }
          }),
          _c("polygon", {
            staticStyle: { fill: "#EF7B2F" },
            attrs: {
              points:
                "46.7,77.5 42,72.7 34.7,80 27.4,72.7 22.6,77.5 29.9,84.8 22.6,92.1 27.4,96.9 34.7,89.6 42,96.9 \n        46.7,92.1 39.4,84.8"
            }
          })
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }