var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-login-id-field" }, [
    _c("label", { staticClass: "form-label", attrs: { for: _vm.id } }, [
      _vm._v(_vm._s(_vm.labelText))
    ]),
    _c("input", {
      staticClass: "form-control form-login-id-control",
      attrs: {
        id: _vm.id,
        type: "text",
        disabled: _vm.isDisabled,
        placeholder: " ",
        "aria-describedby": _vm.help
      },
      domProps: { value: _vm.value },
      on: {
        input: function($event) {
          return _vm.$emit("input", $event.target.value)
        }
      }
    }),
    _c("div", { attrs: { id: _vm.help } }, [
      _vm._v(" " + _vm._s(_vm.helpMessage) + " ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }