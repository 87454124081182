var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-select-question-type" },
    [
      _c("transition", { attrs: { name: "modal", appear: "" } }, [
        _c("div", { staticClass: "modal-mask" }, [
          _c("div", { staticClass: "modal-wrapper" }, [
            _c("div", { staticClass: "modal-container-outer" }, [
              _c("div", { staticClass: "modal-container p-4" }, [
                _c("h3", [_vm._v("更新情報")]),
                _c("div", {
                  staticClass: "modal-text",
                  domProps: { innerHTML: _vm._s(_vm.updateInfoText) }
                }),
                _c(
                  "div",
                  { staticClass: "row h-25 gx-5 justify-content-center" },
                  [
                    _c(
                      "div",
                      { staticClass: "col-auto" },
                      [
                        _c("ButtonBorderCircleMedium", {
                          attrs: {
                            "label-name": _vm.$t("buttons.close"),
                            color: "layout-theme-light",
                            width: _vm.buttonWidth,
                            "label-color": _vm.colorLayoutTheme,
                            "border-color": _vm.colorLayoutTheme
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.onClickCloseUpdateInfo.apply(
                                null,
                                arguments
                              )
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                )
              ])
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }