var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "left-menu-layout", attrs: { id: "left-menu-layout" } },
    [
      _c("Header", { ref: "headerArea" }),
      _c("div", { staticClass: "container-fluid mainArea" }, [
        _c("div", { staticClass: "row h-100", style: _vm.rowStyle }, [
          _c(
            "div",
            {
              ref: "leftArea",
              staticClass: "col-md-3 col-lg-2 left-area",
              class: { modalStyle: _vm.isModalView }
            },
            [
              _c(
                "div",
                { staticClass: "sidbar-fixed", style: _vm.sidebarStyle },
                [
                  _c("Sidebar", {
                    ref: "sidebarArea",
                    attrs: { size: _vm.sidebarAreaSize },
                    on: { "change-modal-view": _vm.changeModalView }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticClass: "col-md-9 ms-sm-auto col-lg-10 px-0 contentsArea",
              style: _vm.contentsAreaStyle
            },
            [
              _c(
                "div",
                { staticClass: "contentsArea-flex" },
                [_c("router-view", { style: _vm.contentsStyle })],
                1
              ),
              _c("Footer", { ref: "footerArea", style: _vm.footerStyle })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }