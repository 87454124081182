<template>
  <div class="button-export">
    <button
      type="button"
      class="btn"
    >
      <IconExport
        width="25px"
        height="25px"
      />
    </button>
  </div>
</template>

<script>
import IconExport from "@/components/atoms/icons/IconExport.vue"

export default {
  name: "ButtonExport",
  components: {
    IconExport,
  },
}
</script>