var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showWhole,
          expression: "showWhole"
        }
      ],
      staticClass: "table-homework-progress-whole"
    },
    [
      _c("table", [
        _c("thead", [
          _c(
            "tr",
            [
              _vm._l(_vm.setHeaderItems, function(headerArray, arrayIndex) {
                return _vm._l(headerArray, function(header, index) {
                  return _c(
                    "th",
                    {
                      key: "header-" + arrayIndex + "-" + index,
                      staticClass: "col ps-0",
                      class: {
                        first: index === 0,
                        last: index === headerArray.length - 1
                      }
                    },
                    [
                      arrayIndex === 0 && index === 0
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "d-flex table-homework-progress-whole-toggle"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-center align-items-center table-homework-progress-whole-toggle-button",
                                  on: {
                                    click: function($event) {
                                      _vm.showWhole = !_vm.showWhole
                                    }
                                  }
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "d-flex justify-content-center align-items-center table-homework-progress-whole-toggle-button-inner",
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.showWhole ? "－" : "＋"
                                      )
                                    }
                                  })
                                ]
                              )
                            ]
                          )
                        : _c("div", { staticClass: "text-center pre-line" }, [
                            _vm._v(" " + _vm._s(header) + " ")
                          ])
                    ]
                  )
                })
              })
            ],
            2
          )
        ]),
        _c(
          "tbody",
          [
            _vm._l(_vm.previewItems, function(item, index) {
              return _c(
                "tr",
                { key: "first-" + index, staticClass: "text-center" },
                [
                  _c("td", { staticClass: "itemName" }, [
                    _vm._v(" " + _vm._s(item.itemName) + " ")
                  ]),
                  _vm._l(_vm.headerItems, function(array, arrayIndex) {
                    return [
                      _c(
                        "td",
                        {
                          key: "preview-" + index + "-" + arrayIndex,
                          staticClass:
                            "child-question-preview bg-layout-theme-light col last",
                          attrs: { colspan: array.length }
                        },
                        [
                          _c("ButtonPreview", {
                            nativeOn: {
                              click: function($event) {
                                return _vm.onClickPreview(arrayIndex)
                              }
                            }
                          })
                        ],
                        1
                      )
                    ]
                  })
                ],
                2
              )
            }),
            _c(
              "tr",
              { staticClass: "text-center" },
              [
                _c("td", { staticClass: "itemName" }, [
                  _vm._v(" " + _vm._s(_vm.responseRateName) + " ")
                ]),
                _vm._l(_vm.childQuestionRateItems, function(
                  itemArray,
                  arrayIndex
                ) {
                  return _vm._l(itemArray, function(item, index) {
                    return _c(
                      "td",
                      {
                        key: "second-" + arrayIndex + "-" + index,
                        staticClass: "child-question bg-layout-theme-light col",
                        class: {
                          first: index === 0,
                          last: index === itemArray.length - 1
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-end" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !item.responseRate ? 0 : item.responseRate
                                ) +
                                " "
                            ),
                            _c(
                              "div",
                              { staticClass: "align-self-end percent" },
                              [_vm._v(" % ")]
                            )
                          ]
                        )
                      ]
                    )
                  })
                })
              ],
              2
            ),
            _c(
              "tr",
              { staticClass: "text-center" },
              [
                _c("td", { staticClass: "itemName" }, [
                  _vm._v(" " + _vm._s(_vm.responseCorrectRateName) + " ")
                ]),
                _vm._l(_vm.childQuestionRateItems, function(
                  itemArray,
                  arrayIndex
                ) {
                  return _vm._l(itemArray, function(item, index) {
                    return _c(
                      "td",
                      {
                        key: "third-" + arrayIndex + "-" + index,
                        staticClass: "child-question bg-layout-theme-light col",
                        class: {
                          first: index === 0,
                          last: index === itemArray.length - 1
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-end" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !item.responseCorrectRate
                                    ? 0
                                    : item.responseCorrectRate
                                ) +
                                " "
                            ),
                            _c(
                              "div",
                              { staticClass: "align-self-end percent" },
                              [_vm._v(" % ")]
                            )
                          ]
                        )
                      ]
                    )
                  })
                })
              ],
              2
            ),
            _c(
              "tr",
              { staticClass: "text-center" },
              [
                _c("td", { staticClass: "itemName" }, [
                  _vm._v(" " + _vm._s(_vm.correctRateName) + " ")
                ]),
                _vm._l(_vm.childQuestionRateItems, function(
                  itemArray,
                  arrayIndex
                ) {
                  return _vm._l(itemArray, function(item, index) {
                    return _c(
                      "td",
                      {
                        key: "third-" + arrayIndex + "-" + index,
                        staticClass: "child-question bg-layout-theme-light col",
                        class: {
                          first: index === 0,
                          last: index === itemArray.length - 1
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-end" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !item.correctRate ? 0 : item.correctRate
                                ) +
                                " "
                            ),
                            _c(
                              "div",
                              { staticClass: "align-self-end percent" },
                              [_vm._v(" % ")]
                            )
                          ]
                        )
                      ]
                    )
                  })
                })
              ],
              2
            )
          ],
          2
        )
      ]),
      _vm.currentPreview === _vm.previewKind.textbook
        ? _c("ModalPreviewTextbookQuestion", {
            attrs: {
              item: _vm.questionInfo,
              curriculum: _vm.homeworkDetails.curriculum,
              "stream-group": _vm.homeworkDetails.groupId,
              "login-user-info": _vm.loginUserInfo,
              "student-items": _vm.studentItems,
              "book-items": _vm.bookItems,
              "task-name": _vm.questionInfo.taskName,
              "selected-homework-item-index": _vm.selectedHomeworkItemIndex
            },
            on: {
              close: function($event) {
                _vm.currentPreview = _vm.previewKind.none
              }
            }
          })
        : _vm._e(),
      _vm.currentPreview === _vm.previewKind.stdbLayout ||
      _vm.currentPreview === _vm.previewKind.pdf
        ? _c("ModalProgressPreviewStdbPdfQuestion", {
            attrs: {
              item: _vm.questionInfo,
              "selected-homework-item-index": _vm.selectedHomeworkItemIndex
            },
            on: {
              close: function($event) {
                _vm.currentPreview = _vm.previewKind.none
              }
            }
          })
        : _vm._e(),
      _vm.currentPreview === _vm.previewKind.stdb
        ? _c("ModalProgressPreviewSingleStdbQuestion", {
            attrs: {
              item: _vm.questionInfo,
              "selected-homework-item-index": _vm.selectedHomeworkItemIndex
            },
            on: {
              close: function($event) {
                _vm.currentPreview = _vm.previewKind.none
              }
            }
          })
        : _vm._e(),
      _vm.showConfirmCaution
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.cautionMessage },
            on: {
              "close-confirm-modal-ok-only": function($event) {
                _vm.showConfirmCaution = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }