<template>
  <!-- TODO:CSSを共通処理に移動 -->
  <div class="error-background">
    <div class="error d-flex justify-content-center align-items-center">
      <ForbiddenContent />
    </div>
  </div>
</template>

<script>
import ForbiddenContent from "@/components/organisms/ForbiddenContent.vue"
/**
 * 403 Forbidden URL 画面
 * ※コンポーネント名の先頭に数字は使用しない事
 */
export default {
  name: "Forbidden",
  components: {
    ForbiddenContent,
  },
}
</script>

<style lang="scss" scoped>
.error-background {
  height: 100%;
  background: #e9e9e9;
}

.error {
  max-width: 550px;
  height: 100%;
  margin: auto;
}
</style>
