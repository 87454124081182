<template>
  <div class="form-text-area">
    <label
      :for="ariaLabel"
      class="form-label"
    >{{ labelText }}</label>
    <textarea
      :value="value"
      :aria-label="ariaLabel"
      class="form-control"
      :style="rowStyle"
      maxlength="150"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)"
      :disabled="disableComment"
    />
  </div>
</template>

<script>
export default {
  name: "FormTextArea",
  props: {
    labelText: {
      type: String,
      default: function () {
        return ""
      },
    },
    ariaLabel: {
      type: String,
      default: function () {
        return ""
      },
    },
    help: {
      type: String,
      default: function () {
        return ""
      },
    },
    row: {
      type: String,
      default: function () {
        return ""
      },
    },
    initialValue: {
      type: String,
      default: function () {
        return ""
      },
    },
    placeholder: {
      type: String,
      default: function () {
        return ""
      },
    },
    disableComment: {
      type: Boolean,
      default: function () {
        return false
      },
    },
  },

  data: function () {
    return {
      value: this.initialValue,
    }
  },

  computed: {
    rowStyle: function () {
      return `height: calc(1rem * (${this.row} + 1)); line-height: 1;`
    },
    isHomeworkComment: function() {
      return this.labelText === this.$t('labels.comment')
    }
  },

  mounted: function () {
    // 初期値を呼び出し元に返す
    this.$emit("input", this.value)
  },
}
</script>
