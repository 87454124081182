var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.label
    ? _c("li", { class: [_vm.active, "tab"], on: { click: _vm.changeTab } }, [
        _vm._v(" " + _vm._s(_vm.label) + " ")
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }