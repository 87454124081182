<template>
  <span class="icon-google-login-logo">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384.4 56"
    >
      <g
        id="レイヤー_2"
        data-name="レイヤー 2"
      >
        <g id="google">
          <rect
            class="cls-1"
            x="1.5"
            y="1.5"
            width="381.4"
            height="53"
            rx="26.5"
          />
          <circle
            class="cls-2"
            cx="340.99"
            cy="28"
            r="11.77"
          />
          <path
            class="cls-3"
            d="M338.68,33.64a.84.84,0,0,1-.55-.19.57.57,0,0,1,0-.92L343.56,28l-5.43-4.53a.57.57,0,0,1,0-.92.9.9,0,0,1,1.1,0l5.85,4.87a.73.73,0,0,1,0,1.16l-5.85,4.87A.84.84,0,0,1,338.68,33.64Zm5.29-5.3h0Z"
          />
          <path
            d="M115.35,34.35A5,5,0,0,1,113,36.13a9.39,9.39,0,0,1-3.46.59,6.77,6.77,0,0,1-3.55-.94,6.36,6.36,0,0,1-2.4-2.67,9.35,9.35,0,0,1-.87-4V27.67A8.57,8.57,0,0,1,104.48,22a6,6,0,0,1,4.84-2,6.2,6.2,0,0,1,4.12,1.3,5.71,5.71,0,0,1,1.91,3.71H113.2a3.57,3.57,0,0,0-3.87-3.25,3.82,3.82,0,0,0-3.28,1.52,7.43,7.43,0,0,0-1.13,4.41V29a7,7,0,0,0,1.26,4.38,4.08,4.08,0,0,0,3.4,1.62,7.43,7.43,0,0,0,2.12-.27,3.34,3.34,0,0,0,1.51-.9V30.11h-3.79V28.35h5.93Z"
          />
          <path
            d="M118.07,30.3a7.27,7.27,0,0,1,.7-3.21,5.17,5.17,0,0,1,4.82-3,5.13,5.13,0,0,1,4,1.71,6.63,6.63,0,0,1,1.53,4.57v.15a7.14,7.14,0,0,1-.68,3.18,5.13,5.13,0,0,1-1.94,2.2,5.41,5.41,0,0,1-2.9.79,5.15,5.15,0,0,1-4-1.72,6.59,6.59,0,0,1-1.53-4.55Zm2.09.25a5.19,5.19,0,0,0,.94,3.25A3,3,0,0,0,123.61,35a3,3,0,0,0,2.51-1.24,5.65,5.65,0,0,0,.94-3.48,5.22,5.22,0,0,0-1-3.24,3.05,3.05,0,0,0-2.52-1.24,3,3,0,0,0-2.49,1.23A5.61,5.61,0,0,0,120.16,30.55Z"
          />
          <path
            d="M131.19,30.3a7.14,7.14,0,0,1,.7-3.21,5.14,5.14,0,0,1,4.81-3,5.14,5.14,0,0,1,4,1.71,6.63,6.63,0,0,1,1.53,4.57v.15a7.27,7.27,0,0,1-.68,3.18,5.13,5.13,0,0,1-1.94,2.2,5.41,5.41,0,0,1-2.9.79,5.14,5.14,0,0,1-4-1.72,6.59,6.59,0,0,1-1.53-4.55Zm2.09.25a5.19,5.19,0,0,0,.94,3.25A3,3,0,0,0,136.73,35a3,3,0,0,0,2.51-1.24,5.71,5.71,0,0,0,.93-3.48,5.15,5.15,0,0,0-.95-3.24,3,3,0,0,0-2.52-1.24,3,3,0,0,0-2.48,1.23A5.61,5.61,0,0,0,133.28,30.55Z"
          />
          <path
            d="M144.36,30.31a7.13,7.13,0,0,1,1.32-4.52,4.54,4.54,0,0,1,6.95-.1l.1-1.35h1.9V36.2a5,5,0,0,1-1.4,3.72,5.19,5.19,0,0,1-3.76,1.36,6.19,6.19,0,0,1-2.57-.57A4.32,4.32,0,0,1,145,39.17l1.08-1.24a4.06,4.06,0,0,0,3.27,1.65,3.22,3.22,0,0,0,2.36-.85,3.29,3.29,0,0,0,.85-2.41v-1a4.28,4.28,0,0,1-3.41,1.44A4.16,4.16,0,0,1,145.68,35,7.53,7.53,0,0,1,144.36,30.31Zm2.09.24a5.46,5.46,0,0,0,.84,3.23A2.74,2.74,0,0,0,149.65,35a3.05,3.05,0,0,0,2.89-1.78V27.62a3.08,3.08,0,0,0-2.87-1.74,2.76,2.76,0,0,0-2.37,1.18A6,6,0,0,0,146.45,30.55Z"
          />
          <path d="M160,36.49h-2.08V19.24H160Z" />
          <path
            d="M168.38,36.72a5.34,5.34,0,0,1-4-1.63,6,6,0,0,1-1.55-4.34v-.38a7.33,7.33,0,0,1,.69-3.23,5.32,5.32,0,0,1,1.94-2.22,4.79,4.79,0,0,1,2.69-.8,4.55,4.55,0,0,1,3.68,1.56,6.73,6.73,0,0,1,1.31,4.47V31h-8.23a4.29,4.29,0,0,0,1.05,2.9A3.3,3.3,0,0,0,168.5,35a3.61,3.61,0,0,0,1.86-.45,4.87,4.87,0,0,0,1.34-1.19l1.27,1A5.13,5.13,0,0,1,168.38,36.72Zm-.25-10.9a2.79,2.79,0,0,0-2.12.92,4.46,4.46,0,0,0-1,2.56h6.09v-.15a4,4,0,0,0-.86-2.46A2.61,2.61,0,0,0,168.13,25.82Z"
          />
          <path
            d="M195.42,22.4h-5.63a11,11,0,0,0-4,3.2,7.5,7.5,0,0,0-1.95,4.85,5.11,5.11,0,0,0,1,3.24,6,6,0,0,0,2.77,1.9,14.31,14.31,0,0,0,4.58.6c.44,0,.89,0,1.36,0l-.11,1.67h-.06c-1.39,0-2.53,0-3.45-.08a11.66,11.66,0,0,1-2.88-.55,7.73,7.73,0,0,1-2.68-1.45,6.17,6.17,0,0,1-1.71-2.26,7,7,0,0,1-.57-2.9,8.4,8.4,0,0,1,1.38-4.47,12.61,12.61,0,0,1,3.63-3.76H175.87V20.86h19.55Zm-2.78,4.78-1.3.58a28.5,28.5,0,0,0-1.62-3.15l1.21-.55A25,25,0,0,1,192.64,27.18Zm3.26-.68-1.29.52a24.14,24.14,0,0,0-1.6-3.1l1.19-.58A31.43,31.43,0,0,1,195.9,26.5Z"
          />
          <path d="M218,37.24H200.1V20.89H218Zm-1.91-1.57V22.38H202V35.67Z" />
          <path
            d="M231.22,18.88a14.22,14.22,0,0,1-.93,2.3h10.9l-.09,1.56a16,16,0,0,1-.58,2.62,23.29,23.29,0,0,1-.86,2.51,18.45,18.45,0,0,1-1.34,2.6,14.4,14.4,0,0,1-2,2.54,15.41,15.41,0,0,1-2.74,2.17A19.25,19.25,0,0,1,230,36.93a28,28,0,0,1-4.45,1.23l-.66-1.61q6.48-1.26,9.86-4.64A15.72,15.72,0,0,0,239,22.82H229.4A21.33,21.33,0,0,1,226.12,27,20.11,20.11,0,0,1,222,30.13L221,28.89a19.89,19.89,0,0,0,5.19-4.81,18.56,18.56,0,0,0,3-5.47Zm8.72,1.38-1.28.57A25.37,25.37,0,0,0,237,17.76l1.23-.55A24.92,24.92,0,0,1,239.94,20.26Zm3.29-.41-1.29.52a22.35,22.35,0,0,0-1.61-3.1l1.18-.55A27.33,27.33,0,0,1,243.23,19.85Z"
          />
          <path
            d="M258,38.08h-2V26.9a43.62,43.62,0,0,1-10.51,3.92l-.6-1.54a37.74,37.74,0,0,0,11.5-4.7,23.53,23.53,0,0,0,6.32-5.86l1.85.72A24.48,24.48,0,0,1,258,25.6Z"
          />
          <path
            d="M288.42,26.84a19.76,19.76,0,0,1-4.9,5.69,21.87,21.87,0,0,1-6.26,3.57,33.34,33.34,0,0,1-7.65,1.65L269.22,36a30.79,30.79,0,0,0,6.55-1.34,19.93,19.93,0,0,0,4.92-2.35,26.25,26.25,0,0,0,3.4-2.73,18.31,18.31,0,0,0,2.79-3.72ZM278.63,24l-1.27,1.47a59.4,59.4,0,0,0-8-4.16l1-1.41A48.4,48.4,0,0,1,278.63,24Z"
          />
          <g
            id="logo_googleg_48dp"
            data-name="logo googleg 48dp"
          >
            <path
              id="Shape"
              class="cls-4"
              d="M58.34,28.3a14.52,14.52,0,0,0-.3-2.67H45.61v5.18h7.1a6.08,6.08,0,0,1-2.66,4v3.4h4.44a13.36,13.36,0,0,0,3.85-9.91Z"
            />
            <path
              id="Shape-2"
              data-name="Shape"
              class="cls-5"
              d="M45.61,41.32a12.8,12.8,0,0,0,8.88-3.26l-4.44-3.25a8.29,8.29,0,0,1-4.44,1.33,8,8,0,0,1-7.4-5.48H33.77v3.26a13,13,0,0,0,11.84,7.4Z"
            />
            <path
              id="Shape-3"
              data-name="Shape"
              class="cls-6"
              d="M38.21,30.52a7.28,7.28,0,0,1,0-5v-3.4H33.77A11.33,11.33,0,0,0,32.29,28a11.33,11.33,0,0,0,1.48,5.92l4.44-3.4Z"
            />
            <path
              id="Shape-4"
              data-name="Shape"
              class="cls-7"
              d="M45.61,20a7.6,7.6,0,0,1,5,1.92l3.85-3.85a13.22,13.22,0,0,0-20.72,4l4.44,3.4A8,8,0,0,1,45.61,20Z"
            />
            <path
              id="Shape-5"
              data-name="Shape"
              class="cls-8"
              d="M32.29,14.68H58.93V41.32H32.29Z"
            />
          </g>
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: "IconGoogleLoginLogo",
};
</script>
<style lang="scss" scoped>
.cls-1,
.cls-3 {
  fill: #fff;
}
.cls-1 {
  stroke: #2f7bb9;
  stroke-miterlimit: 10;
  stroke-width: 3px;
}
.cls-2 {
  fill: #2f7bb9;
}
.cls-4 {
  fill: #4285f4;
}
.cls-4,
.cls-5,
.cls-6,
.cls-7 {
  fill-rule: evenodd;
}
.cls-5 {
  fill: #34a853;
}
.cls-6 {
  fill: #fbbc05;
}
.cls-7 {
  fill: #ea4335;
}
.cls-8 {
  fill: none;
}
</style>