var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "setting-tab" }, [
    _c(
      "li",
      {
        staticClass: "w-100",
        class: [_vm.active, "preview-tab"],
        on: { click: _vm.onChangeTab }
      },
      [_vm._v(" " + _vm._s(_vm.label) + " ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }