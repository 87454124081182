<template>
  <div class="icon-help">
    <svg
      id="_x32_"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      :width="width"
      :height="height"
      viewBox="0 0 70.6 70.6"
      style="enable-background:new 0 0 70.6 70.6;"
      xml:space="preserve"
    >
      <rect
        y="0"
        class="st0"
        width="70.6"
        height="70.6"
      />
      <g>
        <path
          class="st1"
          d="M70.6,55.4V5.1h-61v50.3L70.6,55.4L70.6,55.4z M65.8,50.6H14.4V9.9h51.5L65.8,50.6L65.8,50.6z"
        />
        <polygon
          class="st1"
          points="56.2,60.1 4.8,60.1 4.8,19.5 4.8,14.7 0,14.7 0,64.9 61,64.9 61,60.1 	"
        />
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconLink',
  props: {
    width: {
      type: String,
      default: function () {
        return '25px'
      },
    },
    height: {
      type: String,
      default: function () {
        return '25px'
      },
    },
  },

  data: () => ({
    // アイコンにタイトルを設定する場合は、iconName を表示するようにする
    // ファイル名：navi_リンク(icooon-mono).svg
    iconName: 'リンク'
  }),
}
</script>

<style lang="scss" scoped>
.st0{fill:none;}
.st1{fill:#323333;}
</style>