/**
 * 問題管理関連 Question
 */

import { apiResponseCode } from "../constant/network"
import { settingTabIdCode, checkFlagCode } from "../constant/question"
import i18n from "../i18n"
import questionApi from "../api/question"

export default {
  /**
   * Create 系 API
   */
  /**
   * Read 系 API
   */

  /**
   * 問題表示設定を取得する
   * @param {String} apiToken LMS API トークン
   * @param {String} schoolId 学校 ID
   * @param {Number} groupId グループID
   * @param {String} bookId 書籍ID
   * @param {String} accountId アカウントID
   */
  async getSettingQuestionInfo(apiToken, schoolId, groupId, bookId, accountId) {
    const promise = await questionApi.getSettingQuestionInfo(
      apiToken,
      schoolId,
      groupId,
      bookId,
      accountId
    )

    // API エラー
    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }

    // TreeQuestionに投げる多次元連想配列を構築
    const data = {}
    const ids = [
      settingTabIdCode.hintId,
      settingTabIdCode.answerId,
      settingTabIdCode.explainId,
      settingTabIdCode.contentId,
    ]
    ids.forEach((id) => {
      data[id] = {}
      const srcList =
        id !== settingTabIdCode.contentId
          ? promise.data.data.ques_set_list
          : promise.data.data.ques_contents_set_list
      srcList.forEach((item) => {
        if (!data[id][item.chapter_id]) {
          data[id][item.chapter_id] = {}
        }
        if (!data[id][item.chapter_id][item.node_id]) {
          data[id][item.chapter_id][item.node_id] = {}
        }
        if (id != settingTabIdCode.contentId) {
          // ３タブ
          let flag = false
          switch (id) {
            case settingTabIdCode.hintId:
              flag = item.hint_disp_flg
              break
            case settingTabIdCode.answerId:
              flag = item.ans_disp_flg
              break
            case settingTabIdCode.explainId:
              flag = item.explain_disp_flg
              break
          }
          if (flag !== checkFlagCode.notUse) {
            data[id][item.chapter_id][item.node_id][item.ques_parent_id] = {
              flag: flag === checkFlagCode.display,
            }
          }
        } else {
          // コンテンツ
          if (!data[id][item.chapter_id][item.node_id][item.ques_parent_id]) {
            data[id][item.chapter_id][item.node_id][item.ques_parent_id] = {}
          }
          data[id][item.chapter_id][item.node_id][item.ques_parent_id][
            item.contents_id
          ] = { flag: item.contents_disp_flg === checkFlagCode.display }
        }
      })
    })

    return data
  },

  /**
   * 引数の教科、教材に紐づく問題一覧を取得する
   * @param {String} curriculum 教科
   * @param {String} bookId 教材 ID
   * @returns 問題一覧
   */

  async getTextbookConfigJson(curriculum, bookId) {
    const promise = await questionApi.getTextbookConfigJson(curriculum, bookId)
    // API エラー
    if (!promise || promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }
    return promise
  },

  /**
   * 引数を変換しグループリストを返す処理
   * @param {Array} groupIds
   * @returns グループリスト
   */
  getGroupSelect(groupIds) {
    let groups = new Array()
    class GroupSelect {
      constructor(label, value) {
        this.label = label
        this.value = value
      }
    }
    groups.push(new GroupSelect(i18n.t("labels.pleaseSelect"), "0"))

    if (groupIds && groupIds.length > 0) {
      groupIds.forEach((groupId) => {
        groups.push(new GroupSelect(groupId.groupName, groupId.groupId))
      })
    }
    return groups
  },

  /**
   * 引数の教科、教材に紐づくコンテンツを取得する
   * @param {String} curriculum 教科
   * @param {String} bookId 教材 ID
   * @returns コンテンツ一覧
   */
  async getContentsListJson(curriculum, bookId) {
    const promise = await questionApi.getContentsListJson(curriculum, bookId)
    if (!promise) {
      return []
    }
    // API エラー
    if (promise.status !== apiResponseCode.ok) {
      if (promise.status === apiResponseCode.notFound) {
        return []
      }
      return Promise.reject(promise)
    }
    return promise
  },

  /**
   * コンテンツのサムネイル取得する
   * @param {String} curriculum 教科
   * @param {String} bookId 教材 ID
   * @param {String} thumbnail サムネイル名
   * @return サムネイルS21MJK1_tc
   */
  async getContentsThumbnail(curriculum, bookId, thumbnail) {
    return await questionApi.getContentsThumbnail(curriculum, bookId, thumbnail)
  },

  /**
   * 教材の画像を取得する
   * @param {String} imgPath
   * @returns image
   */
  async getQuestionImage(imgPath) {
    const promise = await questionApi.getQuestionImage(imgPath)
    // Blob形式で返ってきていない場合空文字を返す
    if (
      !promise ||
      !promise.headers ||
      promise.headers["content-type"] !== "image/jpeg"
    ) {
      return ""
    }
    return window.URL.createObjectURL(promise.data)
  },
  /**
   * コンテンツの画像を取得する
   * @param {String} curriculum 教科
   * @param {String} textbookId 書籍ID
   * @param {String} imgPath 画像パス
   * @returns image
   */
  async getContentImage(curriculum, textbookId, imgPath) {
    const promise = await questionApi.getContentImage(
      curriculum,
      textbookId,
      imgPath
    )
    // Blob形式で返ってきていない場合空文字を返す
    if (!promise || promise.headers["content-type"] !== "image/jpeg") {
      return ""
    }
    return window.URL.createObjectURL(promise.data)
  },
  /**
   * Update 系 API
   */

  /**
   * クラス、教材、問題に対してヒント、答、解説、コンテンツの選択有無の一括登録を行う。
   * @param {String} apiToken アクセストークン
   * @param {String} accountId アカウントID
   * @param {String} schoolId 学校ID
   * @param {String} groupId グループID
   * @param {String} notebookId 書籍ID
   * @returns 処理結果
   */
  async updateQuestionControl(
    apiToken,
    accountId,
    schoolId,
    groupId,
    notebookId,
    infoList
  ) {
    // class化して変換する前のデータ構造を構築
    const questionSetList = []
    infoList[settingTabIdCode.hintId].forEach((chapter, chapterIndex) => {
      chapter.nodes.forEach((node, nodeIndex) => {
        node.nodes.forEach((question, questionIndex) => {
          questionSetList.push({
            chapterId: chapter.id,
            nodeId: node.id,
            questionParentId: question.id,
            hintDisplayFlg: question.checkFlg,
            answerDisplayFlg:
              infoList[settingTabIdCode.answerId][chapterIndex].nodes[nodeIndex]
                .nodes[questionIndex].checkFlg,
            explainDisplayFlg:
              infoList[settingTabIdCode.explainId][chapterIndex].nodes[
                nodeIndex
              ].nodes[questionIndex].checkFlg,
          })
        })
      })
    })

    const contentsSetList = []
    if (infoList[settingTabIdCode.contentId]) {
      infoList[settingTabIdCode.contentId].forEach((chapter) => {
        chapter.nodes.forEach((node) => {
          node.nodes.forEach((question) => {
            question.nodes.forEach((content) => {
              contentsSetList.push({
                chapterId: chapter.id,
                nodeId: node.id,
                questionParentId: question.id,
                contentsId: content.id,
                // HACK:checkFlgはundefinedの可能性があるようなので「!!」で強制的に真偽値に変換
                //      ※コンテンツには「未使用」の概念が無い
                contentsDisplayFlg: !!content.checkFlg,
              })
            })
          })
        })
      })
    }
    const questionSetLists = this.getQuestionSetLists(questionSetList)
    const contentsSetLists = this.getQuestionSetContentsLists(contentsSetList)

    const promise = await questionApi.updateQuestionControl(
      apiToken,
      accountId,
      schoolId,
      groupId,
      notebookId,
      questionSetLists,
      contentsSetLists
    )

    // API エラー
    if (promise.status !== apiResponseCode.ok) {
      return Promise.reject(promise)
    }

    return promise.data
  },

  /**
   * 変数の変換を実施
   * @param {Array} questionSetList
   */
  getQuestionSetLists(questionSetList) {
    let questionSetLists = new Array()
    class QuestionSetList {
      constructor(
        chapterId,
        nodeId,
        questionParentId,
        hintDisplayFlg,
        answerDisplayFlg,
        explainDisplayFlg
      ) {
        const getFlagValue = (displayFlg) => {
          // displayFlgがundefinedの場合は「未使用」のコードを返す
          if (displayFlg === undefined) {
            return checkFlagCode.notUse
          }
          return displayFlg ? checkFlagCode.display : checkFlagCode.hide
        }

        this.chapter_id = chapterId
        this.node_id = nodeId
        this.ques_parent_id = questionParentId
        this.hint_disp_flg = getFlagValue(hintDisplayFlg)
        this.ans_disp_flg = getFlagValue(answerDisplayFlg)
        this.explain_disp_flg = getFlagValue(explainDisplayFlg)
      }
    }

    questionSetList.forEach((questionSet) => {
      questionSetLists.push(
        new QuestionSetList(
          questionSet.chapterId,
          questionSet.nodeId,
          questionSet.questionParentId,
          questionSet.hintDisplayFlg,
          questionSet.answerDisplayFlg,
          questionSet.explainDisplayFlg
        )
      )
    })
    return questionSetLists
  },
  /**
   * 変数の変換を実施
   * @param {Array} questionSetContentsList
   */
  getQuestionSetContentsLists(questionSetContentsList) {
    let questionSetContentsLists = new Array()
    class QuestionSetContentsList {
      constructor(
        chapterId,
        nodeId,
        questionParentId,
        contentsId,
        contentsDisplayFlg
      ) {
        this.chapter_id = chapterId
        this.node_id = nodeId
        this.ques_parent_id = questionParentId
        this.contents_id = contentsId
        this.contents_disp_flg = contentsDisplayFlg
          ? checkFlagCode.display
          : checkFlagCode.hide
      }
    }

    questionSetContentsList.forEach((questionSetContents) => {
      questionSetContentsLists.push(
        new QuestionSetContentsList(
          questionSetContents.chapterId,
          questionSetContents.nodeId,
          questionSetContents.questionParentId,
          questionSetContents.contentsId,
          questionSetContents.contentsDisplayFlg
        )
      )
    })
    return questionSetContentsLists
  },

  /**
   * エスビューワ側のJSONを取得する
   * @param {String} url ドメインなしのURL(/books/はのぞく)
   * @returns JSONファイル内容
   */
  async getSviewerJson(url){
    const promise = await questionApi.getSviewerJson(url)
    if (!promise) {
      return {};
    }
    // API エラー
    if (promise.status !== apiResponseCode.ok) {
      if (promise.status === apiResponseCode.notFound) {
        return {};
      }
      return Promise.reject(promise)
    }
    return promise.data;
  }
}
