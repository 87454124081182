<template>
  <div class="service-unavailable-content">
    <div class="text-center">
      <h1>ただいまアクセスが集中しています</h1>
    </div>
    <div class="content">
      <p>日頃よりエスビューアをご利用いただき、誠にありがとうございます。</p>
      <p>ただいまアクセス集中により、つながりにくくなっております。</p>
      <p>申し訳ございませんが、しばらく時間をおいてから再度アクセスしてください。</p>
    </div>
    <div class="row">
      <div class="col-6 offset-3 text-center">
        <router-link :to="{ name: 'Login' }">
          <ButtonForward
            :label-name="$t('buttons.returnToLogin')"
            color="secondary"
          />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonForward from "@/components/atoms/buttons/ButtonForward.vue"

export default {
  name: "ServiceUnavailableContent",
  components: {
    ButtonForward,
  },
}
</script>

<style lang="scss" scoped>
.service-unavailable-content {
  padding: 2.5em;
  min-width: 730px;
  border-style: double;
}
.content {
  padding: 1.5em;
}
.footer {
  justify-content: center;
}
</style>
