<template>
  <div class="homework-add-pdf-question">
    <div class="mt-3 mb-4 mx-4">
      <TitleHomework
        ref="titleArea"
        :help-link="$t('url.helps.addQuestion')"
      />
      <div class="d-flex justify-content-center">
        <p class="mb-0 fw-bold">
          {{ $t("labels.addPdfQuestion") }}
        </p>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row mb-3 stream-data-list">
        <div class="col">
          <div class="capacity-bar">
            <p class="mb-m">
              {{ $t("labels.uploadList") }}
            </p>
            <DataCapacityContent
              :max-byte="maximumLimitSize"
              :now-byte="nowSaveSize"
              :stdb-byte="stdbSaveSize"
              :pdf-byte="pdfSaveSize"
            />
          </div>
          <ModalPreviewPdfQuestion
            v-if="showPreviewPdfQuestion"
            :task-name="taskName"
            :image-list="pdfImageList"
            @close="showPreviewPdfQuestion = false"
            v-on="{
              save: onInputPdf,
              'preview-open-flg': onPreviewOpen,
            }"
          />
          <ModalResetQuesNumPdfQuestion
            v-if="showResetQuesNumPdfQuestion"
            :task-name="taskName"
            :send-memo="sendMemo"
            :image-list="pdfImageList"
            :file-path="tempFilePath"
            v-on="{
              save: resetQuesNumPdfQuestion,
              close: closeResetQuesNumStdbQuestion,
            }"
          />
          <div
            class="card card-body overflow-auto my-2 p-3 file-select"
            @dragover.prevent="drag = true"
            @dragleave.prevent="drag = false"
            @drop.prevent="fileSelected"
          >
            <div class="row">
              <div class="col-8 col-xl-10">
                {{ $t("labels.fileDragAndDropOrFileSelect") }}
              </div>
              <div class="col-4 col-xl-2 button-file-select">
                <button
                  type="button"
                  class="btn btn-black btn-sm px-4 py-1"
                  color="table-row-upload"
                  :style="buttonStyle"
                  @click="onClickFileSelect"
                >
                  {{ $t("buttons.fileSelect") }}
                </button>

                <input
                  ref="input"
                  type="file"
                  class="d-none"
                  accept=".pdf"
                  @change="fileSelected"
                >
              </div>
            </div>
          </div>
          <TableHomeworkUploadDataList
            :upload-data-list="showUploadItems"
            :display-count="5"
            v-on="{
              'change-checkbox': checkUploadTableRow,
              'delete-upload-data': deleteUploadTableRow,
            }"
          />
        </div>
      </div>
    </div>
    <p class="explanation">
      {{ $t("labels.selectStreamDataTheUploadListFirst") }}
      <br>
      {{ $t("labels.selectStreamDataTheUploadListLate") }}
    </p>
    <div class="container-fluid">
      <div v-if="showErrorMessageFlg">
        {{ $t("messages.error.canNotPublishSelectedTextbook") }}
      </div>
      <div class="row mb-1 stream-data-list">
        <div class="col">
          <TableHomeworkUploadStreamDataList
            :stream-data-list="currentHomeworkDetailItems"
            :display-count="5"
            :limit="homeworkMaximumLimitItems[2].items[0].value"
            :hide-sort-buttons="true"
            v-on="{
              delete: deleteStreamTableRow,
              'changed-stream-data-list': changedStreamDataList,
            }"
          />
        </div>
      </div>
      <div class="row px-0 mb-4">
        <div
          class="col-12"
          style="text-align: right"
        >
        </div>
      </div>
      <div class="row gx-5 justify-content-center">
        <div class="col-auto">
          <ButtonBorderCircleMedium
            :label-name="$t('buttons.cancel')"
            :label-color="colorLayoutTheme"
            :border-color="colorLayoutTheme"
            @click.native="onClickCancel"
          />
        </div>
        <div class="col-auto">
          <ButtonBorderCircleMedium
            :label-name="$t('buttons.register')"
            color="layout-theme-light"
            :label-color="colorLayoutTheme"
            :border-color="colorLayoutTheme"
            @click.native="onClickRegister"
          />
        </div>
      </div>
    </div>
    <LoadingScreen v-if="isLoading" />
    <ModalConfirmOkOnly
      v-if="showConfirmCaution"
      :message="cautionMessage"
      @close-confirm-modal-ok-only="showConfirmCaution = false"
    />
    <ModalConfirmOkOnly
      v-if="sessionDisconnect"
      :message="$t('messages.error.overLimitSessionToken')"
      @close-confirm-modal-ok-only="onSessionDisconnect()"
    />
  </div>
</template>

<script>
/**
 * 宿題管理 - PDF
 */
import { db } from "@/dbs/indexedDb"
import { mapActions, mapGetters, mapMutations } from "vuex"
import mixin from "@/mixins/mixin"
import TitleHomework from "@/components/molecules/TitleHomework.vue"
import DataCapacityContent from "@/components/organisms/DataCapacityContent.vue"
import TableHomeworkUploadDataList from "@/components/organisms/TableHomeworkUploadDataList.vue"
import TableHomeworkUploadStreamDataList from "@/components/organisms/TableHomeworkUploadStreamDataList.vue"
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"
import ModalPreviewPdfQuestion from "@/components/organisms/modal/ModalPreviewPdfQuestion.vue"
import ModalResetQuesNumPdfQuestion from "@/components/organisms/modal/ModalResetQuesNumPdfQuestion.vue"
import ModalConfirmOkOnly from "@/components/organisms/modal/ModalConfirmOkOnly.vue"
import LoadingScreen from "@/components/atoms/LoadingScreen.vue"

import { homeworkTypeCode } from "@/constant/homework"
import { streamStatus } from "@/constant/homework"
import homeworkRepository from "@/repositories/homework"
import homeworkApi from "@/api/homework"

import axios from "axios"

const pdfjsLib = require("pdfjs-dist")
const pdfjsWorker = require("pdfjs-dist/build/pdf.worker.entry")
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker

export default {
  // コンポートの名前
  name: "HomeworkAddPdfQuestion",

  // 使用するコンポーネント(依存関係)
  components: {
    TitleHomework,
    DataCapacityContent,
    ModalPreviewPdfQuestion,
    TableHomeworkUploadDataList,
    TableHomeworkUploadStreamDataList,
    ModalResetQuesNumPdfQuestion,
    ButtonBorderCircleMedium,
    ModalConfirmOkOnly,
    LoadingScreen,
  },

  mixins: [mixin],
  props: {
    homeworkStatus: { type: Number, required: false, default: 999 },
  },
  data: () => ({
    colorLayoutTheme: "#ff7f27", // レイアウトテーマカラー(オレンジ)
    questionList: [], // 問題リスト
    uploadHomeworkDetailItems: [], // アップロード一覧
    currentHomeworkDetailItems: [], // 配信データ一覧
    isLoading: false, // ローディング中か
    showErrorMessageFlg: false, // エラーメッセージフラグ
    isOverLimit: false, // 上限超えているフラグ
    maximumLimitSize: 0, // 最大容量
    nowSaveSize: 0, // 現在の使用容量
    nowSaveSizeByte: 0, // 現在の使用容量
    stdbSaveSize: 0, // STDB容量
    stdbSaveSizeByte: 0, // STDB容量
    pdfSaveSize: 0, // PDF容量
    pdfSaveSizeByte: 0, // PDF容量
    pdfImageList: [], // BASE64に変換したPDF情報
    showPreviewPdfQuestion: false, // pdfダイアログ
    showConfirmCaution: false, // 警告確認
    cautionMessage: "", // 警告メッセージ
    showResetQuesNumPdfQuestion: false,
    tempPdfParam: {},
    tempFilePath: "",
    runningFileSelected: false,
  }),

  computed: {
    ...mapGetters("homework", [
      // Store情報
      "headerInfo", // ヘッダ情報
      "uploadPdfDetailItems", // アップロード一覧（一時保存用）
      "uploadStdbDetailItems", // アップロード一覧（一時保存用）
      "homeworkDetailItems", // 配信一覧
      "homeworkFileHistoryItems", // ファイルリスト
    ]),
    ...mapGetters("parameterSettings", [
      "homeworkMaximumLimitItems", // LMSサーバプロパティ情報
      "uploadMaximumLimitItems", // アップロード上限情報
    ]),

    buttonStyle: function () {
      return {
        color: "black",
        border: "solid 1px #b3b3b3",
      }
    },
    showUploadItems() {
      return this.uploadHomeworkDetailItems.filter(
        (item) => item.deleteFlg == false
      )
    },
  },

  created: function () {
    // イベントリスナ登録
    window.addEventListener("resize", this.onHandleResize, false)
    window.addEventListener("dragover", this.dragAndDropSelectedFile)
  },

  // マウント直後に実行
  mounted: async function () {
    // セッション期間が有効かチェックする
    if (!await this.checkSession()) {
      return
    }
    this.init()
  },

  // 破棄される前
  beforeDestroy: function () {
    // インスタンスを破棄する前に、イベントリスナから削除
    window.removeEventListener("resize", this.onHandleResize, false)
    window.removeEventListener("dragover", this.dragAndDropSelectedFile)
  },

  // メソッド
  methods: {
    ...mapMutations("homework", [
      "setUploadStdbDetailItems",
      "setUploadPdfDetailItems",
      "setHomeworkDetailItems",
      "setHomeworkFileHistoryItems",
    ]),
    ...mapActions("homework", [
      "saveUploadStdbDetailItems",
      "saveUploadPdfDetailItems",
    ]),

    async init() {
      this.isLoading = true

      // 配信ファイル履歴リスト取得
      const haishinPromise = await this.getHomeworkStreamFileHistoryListForPdf()
      let haishinList = haishinPromise.data.data.haishinfile_history_list
      // PDFの保存容量
      this.pdfSaveSize = haishinPromise.data.data.pdf_save_size
      // STDBの保存容量
      this.stdbSaveSize = haishinPromise.data.data.stdb_save_size

      // 処理途中で画面再描画された際の復帰対応(Storeから取得)
      if (this.uploadPdfDetailItems.length) {
        this.uploadHomeworkDetailItems = JSON.parse(
          JSON.stringify(this.uploadPdfDetailItems)
        )
        if (
          this.uploadHomeworkDetailItems &&
          this.uploadHomeworkDetailItems.length > 1
        ) {
          // listをソートする
          const dt = JSON.parse(JSON.stringify(this.uploadHomeworkDetailItems))
          let result = dt.sort(function (a, b) {
            const aData = new Date(a.updateDate)
            const bData = new Date(b.updateDate)
            return aData > bData ? -1 : 1 //オブジェクトの降順ソート
          })
          this.uploadHomeworkDetailItems = result
        }
        // 差分を取得する
        await this.getDiffStdbUploadItem()
        await this.getDiffPdfUploadItem(haishinList)
      } else {
        this.editMode = this.headerInfo.editMode
        try {
          let uploadList = []
          for (let i = 0; i < haishinList.length; i++) {
            let item = haishinList[i]

            let endUseDate =
              item.last_use_date.substr(0, 4) +
              "/" +
              item.last_use_date.substr(4, 2) +
              "/" +
              item.last_use_date.substr(6, 2)
            let endPubDate =
              item.expiration_date.substr(0, 4) +
              "/" +
              item.expiration_date.substr(4, 2) +
              "/" +
              item.expiration_date.substr(6, 2)

            uploadList.push({
              checkFlg: false,
              taskName: item.title, // 課題名
              mainQuestionInfo: {
                // メイン情報
                chapterId: item.main_info[0].chapter_id, // 章ID
                nodeId: item.main_info[0].node_id, // 節ID
                quesParentId: item.main_info[0].ques_parent_id, // 親問題ID
                fileName: item.main_info[0].file_name, // ファイル名
              },
              subQuestionInfo: {
                // サブ情報
                teachingMaterials: item.sub_info[0].teaching_materials, // 教材ID
                filePath: item.sub_info[0].file_path, // ファイルパス
              },
              sendMemo: item.send_memo, // 送信メモ
              questionType: homeworkTypeCode.pdf, // 問題数
              questionCount: 1, // 問題数
              ansDispFlg: 0, // 答え表示フラグ
              explainDispFlg: 0, // 解説表示フラグ

              haishinFileNo: item.haishin_file_no, // 配信ファイル連番 新規の場合、0
              endUseDate: endUseDate, // 最終利用日
              endPubDate: endPubDate, // 有効期限
              fileStatusFlg: 1, // "0"：新規、"1"：再利用、"2"：削除
              updateDate: item.update_date,
              deleteFlg: false,
            })
          }
          if (uploadList && uploadList.length > 1) {
            // listをソートする
            const dt = JSON.parse(JSON.stringify(uploadList))
            let result = dt.sort(function (a, b) {
              const aData = new Date(a.updateDate)
              const bData = new Date(b.updateDate)
              return aData > bData ? -1 : 1 //オブジェクトの降順ソート
            })
            uploadList = result
          }
          this.uploadHomeworkDetailItems = uploadList
          // 全てのチェックを一旦外す
          const list = this.uploadHomeworkDetailItems.map((item) => {
            item = JSON.parse(JSON.stringify(item))
            item.checkFlg = false
            return item
          })
          await this.saveUploadPdfDetailItems(list)

          // 差分を取得する
          await this.getStdbUploadSize()
        } catch (error) {
          console.log(error)
        }
      }

      // Storeから配信データ一覧取得
      this.currentHomeworkDetailItems = JSON.parse(
        JSON.stringify(this.homeworkDetailItems)
      ).filter(
        (item) =>
          // PDFの情報のみを取得
          item.questionType === homeworkTypeCode.pdf
      )
      this.setCheckFlgCurrentHomeworkDetailItem()

      this.pdfSaveSizeByte = this.pdfSaveSize * 1000000000
      this.stdbSaveSizeByte = this.stdbSaveSize * 1000000000
      this.nowSaveSize =
        (Number(this.pdfSaveSize) * 100 + Number(this.stdbSaveSize) * 100) / 100
      this.nowSaveSizeByte = this.nowSaveSize * 1000000000
      // 保存容量の合計値
      this.maximumLimitSize = this.uploadMaximumLimitItems[0].items[0].value

      if (this.uploadStdbDetailItems.length) {
        let filteredAddReserveStdb = this.uploadStdbDetailItems.filter(
          (item) => item.haishinFileNo == 0
        )
        filteredAddReserveStdb.forEach((item) => {
          this.stdbSaveSizeByte += item.size || 0
          this.nowSaveSizeByte += item.size || 0
        })

        let notExistSizelength = 0

        let filteredDeleteReserveStdb = this.uploadStdbDetailItems.filter(
          (item) => item.deleteFlg === true
        )
        let filteredAliveReserveStdb = this.uploadStdbDetailItems.filter(
          (item) => item.deleteFlg === false
        )
        filteredDeleteReserveStdb.forEach((item) => {
          if (item.size) {
            this.stdbSaveSizeByte -= item.size || 0
            this.nowSaveSizeByte -= item.size || 0
          } else {
            notExistSizelength++
          }
        })

        if (notExistSizelength > 0) {
          if (filteredAliveReserveStdb.length == 0) {
            this.stdbSaveSize = 0
            this.nowSaveSize = 0
          } else {
            const calcTargetLength =
              filteredAliveReserveStdb.length + notExistSizelength
            let stdbSaveSizeByte = this.stdbSaveSizeByte
            let nowSaveSizeByte = this.nowSaveSizeByte

            let avgStdbSaveSizeByte = stdbSaveSizeByte / calcTargetLength
            this.stdbSaveSizeByte =
              avgStdbSaveSizeByte * filteredAliveReserveStdb.length

            let avgNowSaveSizeByte = nowSaveSizeByte / calcTargetLength
            this.nowSaveSizeByte =
              avgNowSaveSizeByte * filteredAliveReserveStdb.length
            this.stdbSaveSize =
              Math.round((this.stdbSaveSizeByte / 1000000000) * 100) / 100
            this.nowSaveSize =
              Math.round((this.nowSaveSizeByte / 1000000000) * 100) / 100
            if (this.stdbSaveSize === 0) {
              // STDBの保存容量が0.01以下の場合、0.01を表示する
              this.stdbSaveSize = 0.01
            }
          }
        } else {
          this.stdbSaveSize =
            Math.round((this.stdbSaveSizeByte / 1000000000) * 100) / 100
          this.nowSaveSize =
            Math.round((this.nowSaveSizeByte / 1000000000) * 100) / 100
        }

        if (filteredAliveReserveStdb.length !== 0 && this.stdbSaveSize === 0) {
          this.stdbSaveSize = 0.01
        }
      }

      if (this.uploadPdfDetailItems.length) {
        let filteredAddReservePdf = this.uploadPdfDetailItems.filter(
          (item) => item.haishinFileNo == 0
        )
        filteredAddReservePdf.forEach((item) => {
          this.pdfSaveSizeByte += item.size || 0
          this.nowSaveSizeByte += item.size || 0
        })

        let notExistSizelength = 0
        let filteredDeleteReservePdf = this.uploadPdfDetailItems.filter(
          (item) => item.deleteFlg === true
        )
        let filteredAliveReservePdf = this.uploadPdfDetailItems.filter(
          (item) => item.deleteFlg === false
        )
        filteredDeleteReservePdf.forEach((item) => {
          if (item.size) {
            this.pdfSaveSizeByte -= item.size || 0
            this.nowSaveSizeByte -= item.size || 0
          } else {
            notExistSizelength++
          }
        })

        if (notExistSizelength > 0) {
          if (filteredAliveReservePdf.length == 0) {
            this.pdfSaveSizeByte = 0
            this.nowSaveSizeByte = 0
            this.pdfSaveSize = 0
            this.nowSaveSize = 0
          } else {
            const calcTargetLength =
              filteredAliveReservePdf.length + notExistSizelength
            let pdfSaveSizeByte = this.pdfSaveSizeByte
            let nowSaveSizeByte = this.nowSaveSizeByte

            let avgPdfSaveSizeByte = pdfSaveSizeByte / calcTargetLength
            this.pdfSaveSizeByte =
              avgPdfSaveSizeByte * filteredAliveReservePdf.length

            let avgNowSaveSizeByte = nowSaveSizeByte / calcTargetLength
            this.nowSaveSizeByte =
              avgNowSaveSizeByte * filteredAliveReservePdf.length
            this.stdbSaveSize =
              Math.round((this.stdbSaveSizeByte / 1000000000) * 100) / 100
            this.nowSaveSize =
              Math.round((this.nowSaveSizeByte / 1000000000) * 100) / 100
            if (this.pdfSaveSize === 0) {
              // PDFの保存容量が0.01以下の場合、0.01を表示する
              this.pdfSaveSize = 0.01
            }
          }
        } else {
          this.pdfSaveSize =
            Math.round((this.pdfSaveSizeByte / 1000000000) * 100) / 100
          this.nowSaveSize =
            Math.round((this.nowSaveSizeByte / 1000000000) * 100) / 100
        }
        if (filteredAliveReservePdf.length !== 0 && this.pdfSaveSize === 0) {
          this.pdfSaveSize = 0.01
        }
      }

      this.nowSaveSize =
        Math.round((this.stdbSaveSize + this.pdfSaveSize) * 100) / 100

      // プログレス終了
      this.isLoading = false
    },
    /**
     * 配信データ内にある課題にチェックマークを付ける
     */
    setCheckFlgCurrentHomeworkDetailItem() {
      let uploadList = []
      for (let i = 0; i < this.uploadHomeworkDetailItems.length; i++) {
        let upItem = this.uploadHomeworkDetailItems[i]
        // 詳細テーブルの情報に一致するアップロード一覧情報より、必要な情報を詳細テーブル行にコピーする
        this.currentHomeworkDetailItems.some((hwItem) => {
          // 一致情報がある場合
          if (
            hwItem.subQuestionInfo.filePath ===
              upItem.subQuestionInfo.filePath &&
            hwItem.taskName === upItem.taskName
          ) {
            upItem.checkFlg = true // チェック
            upItem.questionType = hwItem.questionType // 種別
            upItem.questionCount = hwItem.questionCount // 問題数
            hwItem.haishinFileNo = upItem.haishinFileNo // 配信ファイル連番
            hwItem.ansDispFlg = upItem.ansDispFlg // 答えフラグ
            hwItem.explainDispFlg = upItem.explainDispFlg // 解説フラグ
            hwItem.endPubDate = upItem.endPubDate // 公開終了日
          }
        })
        uploadList.push(upItem)
      }
      this.uploadHomeworkDetailItems = uploadList
    },

    /**
     * セッションが切れた際のログアウト処理
     */
    async onSessionDisconnect() {
      await this.setSessionDisconnectFalse()
      await this.clearSessionInfo()
      await this.$router.push({ name: "Login" })
    },

    /**
     * ファイルクリックイベント発火
     */
    onClickFileSelect: function () {
      this.$refs.input.click()
    },

    /**
     * STDBアップロードファイル差分取得
     */
    async getDiffStdbUploadItem() {
      // 配信ファイル履歴リスト取得
      const haishinPromise =
        await this.getHomeworkStreamFileHistoryListForStdb()
      let haishinList = haishinPromise.data.data.haishinfile_history_list

      let uploadList = JSON.parse(JSON.stringify(this.uploadStdbDetailItems))

      // 追加
      await new Promise((resolve) => {
        const promises = haishinList.map((hl) => {
          return new Promise((resolveFetch) => {
            const addFlg = uploadList.every((hdi) => {
              return hl.sub_info[0].file_path !== hdi.subQuestionInfo.filePath
            })
            const questionCountList = this.getQuestionCount(
              hl.sub_info[0].file_path
            )
            let endUseDate =
              hl.last_use_date.substr(0, 4) +
              "/" +
              hl.last_use_date.substr(4, 2) +
              "/" +
              hl.last_use_date.substr(6, 2)
            let endPubDate =
              hl.expiration_date.substr(0, 4) +
              "/" +
              hl.expiration_date.substr(4, 2) +
              "/" +
              hl.expiration_date.substr(6, 2)
            if (addFlg) {
              uploadList.push({
                checkFlg: false,
                taskName: hl.title, // 課題名
                mainQuestionInfo: {
                  // メイン情報
                  chapterId: hl.main_info[0].chapter_id, // 章ID
                  nodeId: hl.main_info[0].node_id, // 節ID
                  quesParentId: hl.main_info[0].ques_parent_id, // 親問題ID
                  fileName: hl.main_info[0].file_name, // ファイル名
                },
                subQuestionInfo: {
                  // サブ情報
                  teachingMaterials: hl.sub_info[0].teaching_materials, // 教材ID
                  filePath: hl.sub_info[0].file_path, // ファイルパス
                },
                sendMemo: hl.send_memo, // 送信メモ
                questionCount: questionCountList, // 問題数
                ansDispFlg: false, // 答え表示フラグ
                explainDispFlg: false, // 解説表示フラグ

                homeworkEdaNo: "", // 宿題枝番
                haishinFileNo: hl.haishin_file_no, // 配信ファイル連番 新規の場合、0
                endUseDate: endUseDate, // 最終利用日
                endPubDate: endPubDate, // 有効期限
                fileStatusFlg: 1, // "0"：新規、"1"：再利用、"2"：削除
                updateDate: hl.update_date,
                deleteFlg: false,
              })
            }

            const updatedIndex = uploadList.findIndex((hdi) => {
              const existFlg =
                hl.sub_info[0].file_path == hdi.subQuestionInfo.filePath
              const updatedFlg = hl.haishin_file_no != hdi.haishinFileNo
              return existFlg && updatedFlg
            })

            if (updatedIndex != -1) {
              let updateItem = uploadList[updatedIndex]
              uploadList[updatedIndex] = {
                checkFlg: updateItem.checkFlg,
                taskName: updateItem.taskName,
                mainQuestionInfo: updateItem.mainQuestionInfo,
                subQuestionInfo: updateItem.subQuestionInfo,
                sendMemo: updateItem.sendMemo,
                questionCount: updateItem.questionCount,
                ansDispFlg: updateItem.ansDispFlg,
                explainDispFlg: updateItem.explainDispFlg,
                homeworkEdaNo: updateItem.homeworkEdaNo,
                haishinFileNo: hl.haishin_file_no,
                endUseDate: endUseDate,
                endPubDate: endPubDate,
                fileStatusFlg: updateItem.fileStatusFlg,
                updateDate: hl.update_date,
                deleteFlg: updateItem.deleteFlg,
              }
            }
            resolveFetch()
          })
        })
        Promise.all(promises).then(() => resolve(haishinList))
      })

      // 削除
      let aliveItems = []
      uploadList.forEach((hdi) => {
        const deleteFlg = haishinList.every((hl) => {
          return (
            hdi.subQuestionInfo.filePath !== hl.sub_info[0].file_path &&
            hdi.haishinFileNo !== 0
          )
        })
        if (!deleteFlg || haishinList.length == 0) {
          aliveItems.push(hdi)
        }
      })

      // listをソートする
      if (aliveItems && aliveItems.length > 1) {
        const dt = JSON.parse(JSON.stringify(aliveItems))
        let result = dt.sort(function (a, b) {
          const aData = new Date(a.updateDate)
          const bData = new Date(b.updateDate)
          return aData > bData ? -1 : 1 //オブジェクトの降順ソート
        })
        aliveItems = result
      }
      uploadList = aliveItems

      // store登録
      // 全てのチェックを一旦外す
      const list = uploadList.map((item) => {
        item = JSON.parse(JSON.stringify(item))
        item.checkFlg = false
        return item
      })
      await this.saveUploadStdbDetailItems(list)
    },

    async getStdbUploadSize() {
      // 配信ファイル履歴リスト取得
      const haishinPromise =
        await this.getHomeworkStreamFileHistoryListForStdb()
      let haishinList = haishinPromise.data.data.haishinfile_history_list
      if (haishinList.length > 0 && this.stdbSaveSize == 0) {
        this.stdbSaveSize = 0.01
      }
    },

    /**
     * IndexedDBから問題数の配列を取得
     * @param {String} filePath ファイルパス
     * @returns 問題数の配列
     */
    getQuestionCount: async function (filePath) {
      const item = await this.getUploadHomeworkDetailItems(filePath)
      if (!item) {
        return []
      }

      return item.uploadHomeworkDetailItem.questionCount || []
    },

    /**
     * PDFアップロードファイル差分取得
     */
    async getDiffPdfUploadItem(haishinList) {
      // 追加
      await new Promise((resolve) => {
        const promises = haishinList.map((hl) => {
          return new Promise((resolveFetch) => {
            const addFlg = this.uploadHomeworkDetailItems.every((hdi) => {
              return hl.sub_info[0].file_path !== hdi.subQuestionInfo.filePath
            })
            let endUseDate =
              hl.last_use_date.substr(0, 4) +
              "/" +
              hl.last_use_date.substr(4, 2) +
              "/" +
              hl.last_use_date.substr(6, 2)
            let endPubDate =
              hl.expiration_date.substr(0, 4) +
              "/" +
              hl.expiration_date.substr(4, 2) +
              "/" +
              hl.expiration_date.substr(6, 2)
            if (addFlg) {
              this.uploadHomeworkDetailItems.push({
                checkFlg: false,
                taskName: hl.title, // 課題名
                mainQuestionInfo: {
                  // メイン情報
                  chapterId: hl.main_info[0].chapter_id, // 章ID
                  nodeId: hl.main_info[0].node_id, // 節ID
                  quesParentId: hl.main_info[0].ques_parent_id, // 親問題ID
                  fileName: hl.main_info[0].file_name, // ファイル名
                },
                subQuestionInfo: {
                  // サブ情報
                  teachingMaterials: hl.sub_info[0].teaching_materials, // 教材ID
                  filePath: hl.sub_info[0].file_path, // ファイルパス
                },
                sendMemo: hl.send_memo, // 送信メモ
                questionCount: 1, // 問題数
                ansDispFlg: false, // 答え表示フラグ
                explainDispFlg: false, // 解説表示フラグ

                homeworkEdaNo: "", // 宿題枝番
                haishinFileNo: hl.haishin_file_no, // 配信ファイル連番 新規の場合、0
                endUseDate: endUseDate, // 最終利用日
                endPubDate: endPubDate, // 有効期限
                fileStatusFlg: 1, // "0"：新規、"1"：再利用、"2"：削除
                updateDate: hl.update_date,
                deleteFlg: false,
              })
            }

            const updatedIndex = this.uploadHomeworkDetailItems.findIndex(
              (hdi) => {
                const existFlg =
                  hl.sub_info[0].file_path == hdi.subQuestionInfo.filePath
                const updatedFlg = hl.haishin_file_no != hdi.haishinFileNo
                return existFlg && updatedFlg
              }
            )

            if (updatedIndex != -1) {
              let updateItem = this.uploadHomeworkDetailItems[updatedIndex]
              this.uploadHomeworkDetailItems[updatedIndex] = {
                checkFlg: updateItem.checkFlg,
                taskName: updateItem.taskName,
                mainQuestionInfo: updateItem.mainQuestionInfo,
                subQuestionInfo: updateItem.subQuestionInfo,
                sendMemo: updateItem.sendMemo,
                questionCount: updateItem.questionCount,
                ansDispFlg: updateItem.ansDispFlg,
                explainDispFlg: updateItem.explainDispFlg,
                homeworkEdaNo: updateItem.homeworkEdaNo,
                haishinFileNo: hl.haishin_file_no,
                endUseDate: endUseDate,
                endPubDate: endPubDate,
                fileStatusFlg: updateItem.fileStatusFlg,
                updateDate: hl.update_date,
                deleteFlg: updateItem.deleteFlg,
              }
            }

            resolveFetch()
          })
        })
        Promise.all(promises).then(() => resolve(haishinList))
      })

      // 削除
      let aliveItems = []
      this.uploadHomeworkDetailItems.forEach((hdi) => {
        const deleteFlg = haishinList.every((hl) => {
          return (
            hdi.subQuestionInfo.filePath !== hl.sub_info[0].file_path &&
            hdi.haishinFileNo !== 0
          )
        })
        if (!deleteFlg || haishinList.length == 0) {
          aliveItems.push(hdi)
        }
      })

      // listをソートする
      if (aliveItems && aliveItems.length > 1) {
        const dt = JSON.parse(JSON.stringify(aliveItems))
        let result = dt.sort(function (a, b) {
          const aData = new Date(a.updateDate)
          const bData = new Date(b.updateDate)
          return aData > bData ? -1 : 1 //オブジェクトの降順ソート
        })
        aliveItems = result
      }
      this.uploadHomeworkDetailItems = aliveItems

      // store登録
      // 全てのチェックを一旦外す
      const list = this.uploadHomeworkDetailItems.map((item) => {
        item = JSON.parse(JSON.stringify(item))
        item.checkFlg = false
        return item
      })
      await this.setUploadPdfDetailItems(list)
    },

    /**
     * ファイルが選択されたとき
     */
    fileSelected: async function (event) {
      if (this.showPreviewPdfQuestion || this.runningFileSelected) {
        return
      }
      this.runningFileSelected = true
      let files = event.target.files
        ? event.target.files
        : event.dataTransfer.files
      let file = files[0]
      let fileNameParts = file.name.split('.')
      let extension = fileNameParts.length < 2 ? '' : fileNameParts.pop().toLowerCase()

      // ファイルが複数の場合、
      if (files.length !== 1) {
        this.runningFileSelected = false
        return
      }
      // ファイル形式が「pdf」「PDF」以外の場合
      else if (extension !== "pdf") {
        // 拡張子エラー
        this.showModalOkOnly(this.$t("messages.error.displayPDFOnly"))
        this.runningFileSelected = false
        return
      }

      if (file.size > 20971520) {
        const sizeMb = Math.round(file.size / 1000000)
        this.showModalOkOnly(
          this.$t("messages.error.over20MBsize", { fileSize: sizeMb })
        )
        this.runningFileSelected = false
        return
      }

      // ファイル名を渡す(拡張子外す)
      this.taskName = fileNameParts.join('.');

      // プログレス開始
      this.isLoading = true

      const fileData = await this.readFileAsync(file)
      const option = {
        data: fileData,
        cMapUrl: "/cmaps/", // PDF日本語対応
        cMapPacked: true, // PDF日本語対応
      }

      let loadingTask = pdfjsLib.getDocument(option)
      loadingTask.promise
        .then(async (pdf) => {
          this.pdfDoc = pdf
          this.totalPageNum = pdf.numPages

          await this.pdfToImage() // PDFから画像情報読み込み

          // PDFプレビュー表示
          this.showPreviewPdfQuestion = true
        })
        .catch(() => {
          // プログレス終了
          this.isLoading = false
          // エラー
          this.showModalOkOnly(this.$t("messages.error.pdfFileRead"))
          this.runningFileSelected = false
        })

      // プログレスはModalPreviewオープン後に終了させる

      // 次回同じファイルが追加された際にもonchangeが実行されるようにクリア
      event.target.value = ""
      this.runningFileSelected = false
    },

    /**
     * ファイルサイズ再計算
     * エラーが発生した場合はfalseを返す
     */
    recalcFileSize: function (fileSize) {
      // ファイルアップロード後のAPI使用容量 = アップロードしようとしてる容量 + 今添付したファイル容量
      let afterUploadSize = Math.round(this.nowSaveSizeByte + fileSize)
      // アップロードを行うと全体容量のファイルサイズ上限を超える場合
      if (afterUploadSize >= this.maximumLimitSize * 1000000000) {
        this.showModalOkOnly(this.$t("messages.error.overMaximumLimitSize"))
        return false
      }

      this.nowSaveSizeByte = afterUploadSize
      this.pdfSaveSizeByte = Math.round(this.pdfSaveSizeByte + fileSize)

      this.nowSaveSize =
        Math.round((this.nowSaveSizeByte / 1000000000) * 100) / 100
      this.pdfSaveSize =
        Math.round((this.pdfSaveSizeByte / 1000000000) * 100) / 100
      this.nowSaveSize = this.nowSaveSize == 0 ? 0.01 : this.nowSaveSize
      this.pdfSaveSize = this.pdfSaveSize == 0 ? 0.01 : this.pdfSaveSize

      return true
    },

    /**
     * ファイル情報読み込み
     */
    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.onerror = reject
        reader.readAsArrayBuffer(file)
      })
    },

    /**
     * PDFファイルからPNG情報に変換し保持
     */
    async pdfToImage() {
      // 画質を上げるかどうかの計算対象
      const maximumAreaForPdfjs = 268435456
      const canZoomArea = maximumAreaForPdfjs / 4
      let list = []
      for (let i = 1; i <= this.totalPageNum; i++) {
        // 1ページから
        const page = await this.pdfDoc.getPage(i)
        const canvas = document.createElement("canvas")
        const canvasContext = canvas.getContext("2d")

        // 倍率を指定
        let viewport = page.getViewport({ scale: 1 })
        const nowArea = viewport.width * viewport.height

        if (nowArea < canZoomArea) {
          viewport = page.getViewport({ scale: 1.5 })
        } else if (nowArea < 150000000) {
          viewport = page.getViewport({ scale: 0.9 })
        } else if (nowArea < 200000000) {
          viewport = page.getViewport({ scale: 0.8 })
        } else if (nowArea < 268435456) {
          viewport = page.getViewport({ scale: 0.7 })
        }

        canvas.width = viewport.width
        canvas.height = viewport.height

        // レンダリング
        let rendar_content = {
          canvasContext: canvasContext,
          viewport: viewport,
        }
        await page.render(rendar_content).promise

        // ファイル名は001.png~連番
        list.push({
          file_name: ("000" + i).slice(-3) + ".png",
          file_image: canvas.toDataURL("image/png", 1.0),
        })
      }
      this.pdfImageList = list
    },

    /**
     * PDF問題数再設定処理
     */
    resetQuesNumPdfQuestion: async function (pdfDetail) {
      const item = this.tempPdfParam.item
      item.questionCount = pdfDetail.questionCount
      if (item.fileStatusFlg === 0) {
        // 新規追加
        await this.addStreamList(
          item,
          this.tempPdfParam.strPeriodDate,
          this.tempPdfParam.filePath
        )
      } else {
        // 再利用
        await this.addStreamListReuse(
          item,
          this.tempPdfParam.strPeriodDate,
          this.tempPdfParam.filePath
        )
      }

      // IndexedDB更新
      await this.putUploadHomeworkDetailItems({
        id: this.tempPdfParam.filePath,
        uploadHomeworkDetailItem: item,
      })

      // homeworkEdaNo等を振りなおす
      this.sortNumberReset()

      // リスト上限チェック
      this.onOverLimit()

      // uploadHomeworkDetailItemsの情報も更新
      this.uploadHomeworkDetailItems.forEach((item) => {
        if (item.subQuestionInfo.filePath === this.tempPdfParam.filePath) {
          this.$set(item, "questionCount", pdfDetail.questionCount)
        }
      })

      // 全てのチェックを一旦外したものでStoreを更新
      const list = this.uploadHomeworkDetailItems.map((item) => {
        item = JSON.parse(JSON.stringify(item))
        item.checkFlg = false
        return item
      })
      await this.setUploadPdfDetailItems(list)

      this.showResetQuesNumPdfQuestion = false
    },
    /**
     * PDF問題数再設定中断処理
     */
    closeResetQuesNumStdbQuestion: function () {
      this.uploadHomeworkDetailItems[
        this.tempPdfParam.item.index
      ].checkFlg = false
      this.removeFromStreamList(this.tempPdfParam.item)
      this.showResetQuesNumPdfQuestion = false
    },

    /**
     * 配信データ上限チェック
     */
    onOverLimit: function () {
      let detailCount = this.currentHomeworkDetailItems.length
      // 配信データが30件を超える場合
      let detailMaxCount = this.homeworkMaximumLimitItems[2].items[0].value
      if (detailCount > detailMaxCount) {
        // エラー
        this.showModalOkOnly(
          this.$t("messages.error.overRegistMax", { maxCount: detailMaxCount })
        )
      }
    },

    /**
     * 配信履歴一覧を取得（STDB）
     */
    getHomeworkStreamFileHistoryListForStdb: async function () {
      return await homeworkApi.getHomeworkStreamFileHistoryList(
        this.loginUserInfo.accountId, // アカウントID
        this.loginUserInfo.schoolId, // 学校ID
        homeworkTypeCode.stdbLayout, // 宿題種別
        this.loginUserInfo.lmsApiToken // APIトークン
      )
    },

    /**
     * 配信履歴一覧を取得（PDF）
     */
    getHomeworkStreamFileHistoryListForPdf: async function () {
      return await homeworkApi.getHomeworkStreamFileHistoryList(
        this.loginUserInfo.accountId, // アカウントID
        this.loginUserInfo.schoolId, // 学校ID
        homeworkTypeCode.pdf, // 宿題種別
        this.loginUserInfo.lmsApiToken // APIトークン
      )
    },

    /**
     * アップロード一覧取得
     */
    getHomeworkStreamFileInfo: async function (haishinFileNo) {
      return await homeworkApi.getHomeworkStreamFileInfo(
        this.loginUserInfo.accountId, // アカウントID
        this.loginUserInfo.schoolId, // 学校ID
        0, // モード
        0, // 宿題キー
        haishinFileNo, // 配信ファイル連番
        this.loginUserInfo.lmsApiToken // APIトークン
      )
    },

    /*
     * PDFモーダルによる登録処理
     */
    async onInputPdf(pdfDetail) {
      // セッション期間が有効かチェックする
      await this.checkSession()

      // ファイルサイズ計算
      let totalImageFileSize = 0
      pdfDetail.img.forEach((img) => {
        const blob = new Blob([img.file_image])
        totalImageFileSize += blob.size
      })

      // ファイルサイズ再計算
      if (!this.recalcFileSize(totalImageFileSize)) {
        return
      }

      // アップロード一覧追加
      // 公開終了日
      const periodTime = homeworkRepository.getPeriodDate(
        this.paramKeepPeriodItems[2].items[0].value
      )
      let strPeriodTime =
        periodTime.getFullYear() +
        "/" +
        ("00" + (periodTime.getMonth() + 1)).slice(-2) +
        "/" +
        ("00" + periodTime.getDate()).slice(-2)
      // 一意のフォルダ名 システム日付時間秒（ミリ秒単位）＋ランダム８桁英数字
      let filePath = "" + new Date().getTime() + this.getRandomAlphabetString(8)

      // アップロード一覧追加
      await this.addUploadList(
        pdfDetail,
        strPeriodTime,
        filePath,
        totalImageFileSize
      )

      // 配信データ登録
      await this.addStreamList(pdfDetail, strPeriodTime, filePath)

      // homeworkEdaNo等を振りなおす
      this.sortNumberReset()

      // リスト上限チェック
      this.onOverLimit()
      this.showPreviewPdfQuestion = false
    },
    /**
     * homeworkEdaNo等再設定
     */
    sortNumberReset: function () {
      let streamList = JSON.parse(
        JSON.stringify(this.currentHomeworkDetailItems)
      )
      for (var i = 0; i < streamList.length; i++) {
        let item = streamList[i]
        const num = i + 1
        this.$set(item, "homeworkEdaNo", num)
        this.$set(item, "sortedDisplayNo", num)
      }

      this.currentHomeworkDetailItems = streamList
    },

    /**
     * アップロード一覧追加
     */
    async addUploadList(pdfDetail, periodTime, filePath, fileSize) {
      let useDate = new Date()
      let strUseDate =
        useDate.getFullYear() +
        "/" +
        ("00" + (useDate.getMonth() + 1)).slice(-2) +
        "/" +
        ("00" + useDate.getDate()).slice(-2)

      let uploadList = JSON.parse(
        JSON.stringify(this.uploadHomeworkDetailItems)
      )
      uploadList.unshift({
        checkFlg: true,
        taskName: pdfDetail.taskName, // 課題名
        mainQuestionInfo: {
          // メイン情報
          chapterId: "", // 章ID
          nodeId: "", // 節ID
          quesParentId: "", // 親問題ID
          fileName: "", // ファイル名
        },
        subQuestionInfo: {
          // サブ情報
          teachingMaterials: "", // 教材ID
          filePath: filePath, // ファイルパス
        },
        questionType: homeworkTypeCode.pdf, // 宿題種別
        sendMemo: pdfDetail.sendMemo, // 送信メモ
        homeworkEdaNo: 0, // 宿題枝番
        questionCount: pdfDetail.questionCount, // 問題数
        ansDispFlg: 0, // 答え表示フラグ
        explainDispFlg: 0, // 解説表示フラグ
        externalFilePath: "", // 外部ファイルパス
        haishinFileNo: 0, // 配信ファイル連番：追加時は0
        endUseDate: strUseDate, // 最終利用日
        endPubDate: periodTime, // 有効期限
        fileStatusFlg: 0, // "0"：新規、"1"：再利用、"2"：削除
        size: fileSize, // ファイルサイズ：APIに保存されない、新規追加時のサイズチェックでしか使わない値
        deleteFlg: false,
      })
      this.uploadHomeworkDetailItems = uploadList
      // 全てのチェックを一旦外す
      const list = this.uploadHomeworkDetailItems.map((item) => {
        item = JSON.parse(JSON.stringify(item))
        item.checkFlg = false
        return item
      })
      await this.setUploadPdfDetailItems(list)

      // indexedDB
      await this.uploadHomeworkDetailItems.map(async (item) => {
        await this.putUploadHomeworkDetailItems({
          id: item.subQuestionInfo.filePath,
          uploadHomeworkDetailItem: item,
        })
      })

      // 画像情報の登録(ファイルパス+課題名)
      await this.putIdbQuestionImage({
        id: filePath,
        uploadFileList: pdfDetail.img,
      })
    },

    /**
     * 配信データ一覧追加
     */
    async addStreamList(pdfDetail, periodTime, filePath) {
      let streamList = JSON.parse(
        JSON.stringify(this.currentHomeworkDetailItems)
      )
      let taskName = pdfDetail.taskName
      streamList.push({
        questionType: homeworkTypeCode.pdf, // 宿題種別
        taskName: taskName, // 課題名
        mainQuestionInfo: {
          // メイン情報
          chapterId: "", // 章ID
          nodeId: "", // 節ID
          quesParentId: "", // 親問題ID
          fileName: "", // ファイル名
        },
        subQuestionInfo: {
          // サブ情報
          bookId: "", // 教材ID
          filePath: filePath, // ファイルパス
        },
        sendMemo: pdfDetail.sendMemo, // 送信メモ
        questionCount: pdfDetail.questionCount, // 問題数
        sortedDisplayNo: 0, // 表示順
        sortedDisplaySuffixNumber: 0, // 表示枝番
        endPubDate: periodTime, // 有効期限
        haishinFileNo: 0, // 配信ファイル連番：追加時は0固定
      })

      // store登録
      this.currentHomeworkDetailItems = streamList
    },

    /**
     * 配信データ一覧追加(再利用登録)
     */
    async addStreamListReuse(pdfDetail, periodTime, filePath) {
      let streamList = JSON.parse(
        JSON.stringify(this.currentHomeworkDetailItems)
      )
      let taskName = pdfDetail.taskName
      streamList.push({
        questionType: homeworkTypeCode.pdf, // 宿題種別
        taskName: taskName, // 課題名
        mainQuestionInfo: {
          // メイン情報
          chapterId: "", // 章ID
          nodeId: "", // 節ID
          quesParentId: "", // 親問題ID
          fileName: "", // ファイル名
        },
        subQuestionInfo: {
          // サブ情報
          bookId: "", // 教材ID
          filePath: filePath, // ファイルパス
        },
        sendMemo: pdfDetail.sendMemo, // 送信メモ
        questionCount: pdfDetail.questionCount, // 問題数
        sortedDisplayNo: 0, // 表示順
        sortedDisplaySuffixNumber: 0, // 表示枝番
        endPubDate: periodTime, // 有効期限

        homeworkEdaNo: 0, // 宿題-枝番
        ansDispFlg: pdfDetail.ansDispFlg, // 答え表示フラグ
        explainDispFlg: pdfDetail.explainDispFlg, // 解説表示フラグ

        haishinFileNo: pdfDetail.haishinFileNo,
      })

      // indexedDB
      await this.uploadHomeworkDetailItems.map(async (item) => {
        await this.putUploadHomeworkDetailItems({
          id: item.subQuestionInfo.filePath,
          uploadHomeworkDetailItem: item,
        })
      })

      // store登録
      this.currentHomeworkDetailItems = streamList
    },

    /**
     * アップロード一覧変更処理
     */
    changedUploadDataList(uploadDataList) {
      this.uploadHomeworkDetailItems = uploadDataList
    },

    /**
     * 配信データ一覧変更処理
     */
    changedStreamDataList(streamDataList) {
      this.currentHomeworkDetailItems = streamDataList
    },

    /**
     * アップロード一覧チェック操作
     */
    checkUploadTableRow: async function (event) {
      let uploadList = JSON.parse(
        JSON.stringify(this.uploadHomeworkDetailItems)
      )

      // チェック内容更新
      const uploadIndex = uploadList.findIndex(
        (item) =>
          item.subQuestionInfo.filePath === event.item.subQuestionInfo.filePath
      )
      uploadList[uploadIndex].checkFlg = event.check
      uploadList[uploadIndex].questionType = event.item.questionType

      let item = event.item
      if (event.check) {
        // チェックされた場合
        this.isLoading = true

        // 最終利用日更新
        const now = new Date()
        uploadList[uploadIndex].endUseDate = now.toLocaleDateString()
        const periodDate = homeworkRepository.getPeriodDate(
          this.paramKeepPeriodItems[2].items[0].value
        )
        const strPeriodDate =
          periodDate.getFullYear() +
          "/" +
          ("00" + (periodDate.getMonth() + 1)).slice(-2) +
          "/" +
          ("00" + periodDate.getDate()).slice(-2)
        uploadList[uploadIndex].endPubDate = strPeriodDate

        let img = []
        // 新規アップロードファイルのチェック
        if (0 === item.haishinFileNo) {
          // 新規Uploadファイルの場合IndexedDBからファイル情報を取得
          let key = item.subQuestionInfo.filePath
          img = (await this.getIdbQuestionImage(key)).uploadFileList
        } else {
          // そうでないならAPIを実行して取得
          const info = await this.getHomeworkStreamFileInfo(item.haishinFileNo)
          img = await this.getApiImageList(info)

          // 画像情報が読み込めなかった場合配信終了エラー表示
          if (img === null) {
            // メッセージ出力
            this.showModalOkOnly(
              this.$t("messages.error.uploadPublicEndPreview")
            )
            this.isLoading = false
            return
          }
        }

        //問題数設定のモーダルを出して、ここに対応させる
        this.taskName = item.taskName
        this.sendMemo = item.sendMemo
        this.pdfImageList = img
        this.tempPdfParam = {
          item: item,
          strPeriodDate: strPeriodDate,
          filePath: item.subQuestionInfo.filePath,
        }
        this.tempFilePath = item.subQuestionInfo.filePath
        this.showResetQuesNumPdfQuestion = true
      } else {
        // チェック外れた場合

        // アップロード情報に関連する配信データを削除
        this.removeFromStreamList(item)
      }

      this.uploadHomeworkDetailItems = uploadList
      this.isLoading = false
    },
    /**
     * 要素を配信リストから除外
     */
    removeFromStreamList: function (item) {
      let streamList = JSON.parse(
        JSON.stringify(this.currentHomeworkDetailItems)
      )
      streamList = streamList.filter((elem) => {
        if (1 === item.fileStatusFlg) {
          // ファイルステータスが再利用の場合、ファイルパスとファイル名を比較
          return (
            elem.subQuestionInfo.filePath !== item.subQuestionInfo.filePath ||
            elem.taskName !== item.taskName
          )
        } else {
          return elem.subQuestionInfo.filePath !== item.subQuestionInfo.filePath
        }
      })
      this.currentHomeworkDetailItems = streamList
    },
    /**
     * 画像リストを取得
     */
    getApiImageList: async function (streamFileInfo) {
      if (streamFileInfo.status !== 200) {
        // ファイル保持期限切れ
        return null
      }
      let fileList = streamFileInfo.data.data.upload_file_list
      let fileImgList = []
      for (let i = 0; i < fileList.length; i++) {
        let item = fileList[i]
        let url = item.file_path
        let base64 = await this.downloadImageToBase64(url)

        fileImgList.push({
          file_image: base64,
          file_name: item.file_name,
        })
      }
      return fileImgList
    },
    /**
     * 画像ファイルダウンロード
     */
    downloadImageToBase64: async function (externalUrl) {
      let retBase64 = null
      try {
        const response = await axios.get(externalUrl, {
          responseType: "arraybuffer",
        })
        // arrayBuffer -> blob
        const blob = new Blob([response.data], { type: "image/png" })

        // blob -> base64
        await new Promise((res) => {
          let reader = new FileReader()
          reader.onload = () => {
            res(reader.result)
          }
          reader.readAsDataURL(blob)
        }).then((ret) => {
          retBase64 = ret
        })
      } catch (ex) {
        // 空情報を返す（配信期限切れ）
        console.log(ex)
      }

      return retBase64
    },
    /**
     * アップロード一覧削除処理
     */
    deleteUploadTableRow: async function (deleteRowItem) {
      // アップロード一覧
      let uploadList = JSON.parse(
        JSON.stringify(this.uploadHomeworkDetailItems)
      )

      let aliveList = uploadList.filter((item) => item.deleteFlg == false)
      const index = uploadList.findIndex(
        (item) =>
          item.subQuestionInfo.filePath ===
          deleteRowItem.subQuestionInfo.filePath
      )

      // index位置の情報を1件取り除く
      if (0 <= index) {
        const avgPdfSaveSizeByte = Math.round(
          this.pdfSaveSizeByte / aliveList.length
        )
        // indexが0より大きい場合(一致あり)のみ処理
        if (uploadList[index].haishinFileNo === 0) {
          uploadList.splice(index, 1)
        } else {
          uploadList[index].deleteFlg = true
        }
        this.pdfSaveSizeByte = avgPdfSaveSizeByte * (aliveList.length - 1)
        this.uploadHomeworkDetailItems = uploadList
        // store登録
        // 全てのチェックを一旦外す
        const list = this.uploadHomeworkDetailItems.map((item) => {
          item = JSON.parse(JSON.stringify(item))
          item.checkFlg = false
          return item
        })
        await this.setUploadPdfDetailItems(list)
      }
      if (aliveList.length - 1 === 0) {
        this.nowSaveSize = this.stdbSaveSize
        this.nowSaveSizeByte = this.nowSaveSize * 1000000000
        this.pdfSaveSize = 0
        this.pdfSaveSizeByte = 0
      } else {
        this.pdfSaveSize =
          Math.round((this.pdfSaveSizeByte / 1000000000) * 100) / 100
        this.pdfSaveSize = this.pdfSaveSize == 0 ? 0.01 : this.pdfSaveSize
        this.nowSaveSizeByte = Math.round(this.stdbSaveSizeByte + this.pdfSaveSizeByte)
        this.nowSaveSize = Math.round((this.nowSaveSizeByte / 1000000000) * 100) / 100
      }

      // 配信一覧
      let isDelete = this.headerInfo.homeworkStatus === streamStatus.creating
      if (isDelete) {
        // 配信ない場合は削除
        let streamList = JSON.parse(
          JSON.stringify(this.currentHomeworkDetailItems)
        )
        let itemKey = deleteRowItem.subQuestionInfo.filePath
        streamList = streamList.filter((elem) => {
          return elem.subQuestionInfo.filePath !== itemKey
        })
        this.currentHomeworkDetailItems = streamList
      }
      await this.createHomeworkFileHistoryList()
    },

    /*
     * 配信テーブル行削除
     */
    deleteStreamTableRow: function (deleteRowItem) {
      // 配信一覧から削除
      let streamList = JSON.parse(
        JSON.stringify(this.currentHomeworkDetailItems)
      )
      streamList.splice(deleteRowItem.index, 1)
      this.currentHomeworkDetailItems = streamList
      const streamIndex = this.currentHomeworkDetailItems.findIndex(
        (item) =>
          item.subQuestionInfo.filePath ===
          deleteRowItem.subQuestionInfo.filePath
      )
      // 配信一覧に同一ファイルの親問題がある場合(ない場合は-1が返る)
      if (0 <= streamIndex) {
        // 処理終了
        return
      }

      // 対象アップロードファイルのチェックを外す
      let uploadList = JSON.parse(
        JSON.stringify(this.uploadHomeworkDetailItems)
      )
      const uploadIndex = this.uploadHomeworkDetailItems.findIndex(
        (item) =>
          item.subQuestionInfo.filePath ===
          deleteRowItem.subQuestionInfo.filePath
      )
      uploadList[uploadIndex].checkFlg = false
      this.uploadHomeworkDetailItems = uploadList
    },

    /**
     * 登録押下
     */
    onClickRegister: async function () {
      // 登録チェック
      if (this.isRegistError()) {
        return
      }

      // 宿題ファイルリスト作成
      await this.createHomeworkFileHistoryList()

      // 配信データを追加(既存の配信データよりPDF情報を全削除し、今回登録データを追加)
      this.setHomeworkDetailItems({
        questionType: homeworkTypeCode.pdf,
        homeworkDetailItems: this.currentHomeworkDetailItems,
      })

      // アップロード一覧の存在チェック
      if (await this.notExistCheckForUploadList()) {
        this.isLoading = false
        return
      }

      this.$router.push({ name: "HomeworkRegister" })
    },

    /**
     * 登録チェック
     * return: エラーがある場合true
     */
    isRegistError: function () {
      // 配信リストが30件を超える場合
      let detailMaxCount = this.homeworkMaximumLimitItems[2].items[0].value
      if (detailMaxCount < this.currentHomeworkDetailItems.length) {
        // メッセージ出力
        this.showModalOkOnly(
          this.$t("messages.error.overRegistMax", { maxCount: detailMaxCount })
        )
        return true
      }

      // 配信データ一覧のレコードをアップロード一覧のデータに含まれているかチェック（整合性チェック）
      let errorFiles = []
      this.currentHomeworkDetailItems.some((streamItem) => {
        const hitIndex = this.uploadHomeworkDetailItems.findIndex(
          (uploadItem) =>
            uploadItem.subQuestionInfo.filePath ===
            streamItem.subQuestionInfo.filePath
        )
        if (hitIndex < 0) {
          errorFiles.push("・" + streamItem.taskName)
        }
      })
      if (0 < errorFiles.length) {
        // メッセージ出力
        this.showModalOkOnly(
          this.$t("messages.error.uploadPublicEndRegist", {
            fileNames: errorFiles.join("\n"),
          })
        )
        return true
      }
      return false
    },

    /**
     * 宿題ファイルリスト作成
     */
    createHomeworkFileHistoryList: async function () {
      let fileList = []
      // アップロード分ループを回して宿題ファイル作成
      let willUploadItems = this.uploadHomeworkDetailItems.filter(
        (item) => item.checkFlg === true
      )
      willUploadItems.forEach((item) => {
        // 新規（登録なし、アップロードあり）
        let setParam = {}
        if (0 === item.haishinFileNo) {
          setParam = {
            haishinFileNo: item.haishinFileNo,
            fileStatusFlg: 0,
          }
        } else {
          // 再利用
          setParam = {
            haishinFileNo: item.haishinFileNo,
            fileStatusFlg: 1,
          }
        }
        fileList.push({
          haishin_file_no: setParam.haishinFileNo, // 配信ファイル連番：ファイルステータスフラグが"0"：新規の場合、0
          homework_syubetu_kbn: item.questionType, // 宿題種別
          title: item.taskName, // 課題名
          main_info: [
            {
              // メイン情報
              chapter_id: item.mainQuestionInfo.chapterId, // 章ID
              node_id: item.mainQuestionInfo.nodeId, // 節ID
              ques_parent_id: item.mainQuestionInfo.quesParentId, // 親問題ID
              file_name: item.mainQuestionInfo.fileName, // ファイル名
            },
          ],
          sub_info: [
            {
              // サブ情報
              teaching_materials: item.subQuestionInfo.teachingMaterials, // 教材ID
              file_path: item.subQuestionInfo.filePath, // ファイルパス
            },
          ],
          send_memo: item.sendMemo, // 送信メモ
          expiration_date: item.endPubDate.replaceAll("/", ""), // 有効期限
          file_status_flg: setParam.fileStatusFlg, // "0"：新規、"1"：再利用、"2"：削除

          // ※Storeに画像情報を設定すると容量エラーとなるため、登録直前でIndexedDBから取得すること
          upload_file_list: new Array(),
        })
      })

      // 削除データ生成
      let delHaishinItems = []
      let willDeleteItems = this.uploadHomeworkDetailItems.filter(
        (item) => item.deleteFlg === true
      )
      willDeleteItems.forEach((item) => {
        delHaishinItems.push({
          title: item.taskName, // 課題名
          expiration_date: item.expiration_date,
          file_status_flg: 2, // "0"：新規、"1"：再利用、"2"：削除
          haishin_file_no: item.haishinFileNo,
          homework_syubetu_kbn: homeworkTypeCode.pdf,
          main_info: [
            {
              // メイン情報
              chapter_id: item.mainQuestionInfo.chapterId, // 章ID
              node_id: item.mainQuestionInfo.nodeId, // 節ID
              ques_parent_id: item.mainQuestionInfo.quesParentId, // 親問題ID
              file_name: item.mainQuestionInfo.fileName, // ファイル名
            },
          ],
          sub_info: [
            {
              // サブ情報
              teaching_materials: item.subQuestionInfo.teachingMaterials, // 教材ID
              file_path: item.subQuestionInfo.filePath, // ファイルパス
            },
          ],
          send_memo: item.sendMemo,
          upload_file_list: [],
        })
      })

      let fileHisList = JSON.parse(
        JSON.stringify(this.homeworkFileHistoryItems)
      )
      fileHisList = fileHisList.filter((item) => {
        return item.homework_syubetu_kbn !== homeworkTypeCode.pdf
      })
      fileHisList = fileHisList.concat(delHaishinItems).concat(fileList)

      this.setHomeworkFileHistoryItems(fileHisList)
    },

    /**
     * アップロード一覧（IndexedDB）追加
     */
    async putUploadHomeworkDetailItems(item) {
      return await db.uploadHomeworkDetailItems.put(item)
    },
    /**
     * アップロード一覧（IndexedDB）取得
     */
    async getUploadHomeworkDetailItems(filePath) {
      return await db.uploadHomeworkDetailItems.get(filePath)
    },
    /**
     * アップロード一覧（IndexedDB）削除
     */
    async deleteUploadHomeworkDetailItems(filePath) {
      return await db.uploadHomeworkDetailItems.delete(filePath)
    },
    /**
     * 画像一覧（IndexedDB）追加
     */
    async putIdbQuestionImage(item) {
      return await db.idbQuestionImage.put(item)
    },
    /**
     * 画像一覧（IndexedDB）取得
     */
    async getIdbQuestionImage(filePath) {
      return await db.idbQuestionImage.get(filePath)
    },
    /**
     * 画像一覧（IndexedDB）削除
     */
    async deleteIdbQuestionImage(filePath) {
      return await db.idbQuestionImage.delete(filePath)
    },

    /**
     * キャンセル押下
     */
    async onClickCancel() {
      // 一旦全てのチェックを外す
      const list = this.uploadHomeworkDetailItems.map((item) => {
        item = JSON.parse(JSON.stringify(item))
        item.checkFlg = false
        return item
      })
      await this.setUploadPdfDetailItems(list)

      this.$router.push({ name: "HomeworkRegister" })
    },

    /**
     * OKだけのモーダルを表示する
     */
    showModalOkOnly: function (message) {
      this.cautionMessage = message
      this.showConfirmCaution = true
    },

    /**
     * アップロード一覧の存在チェックを行う（true: 存在しない, false: 存在する）
     */
    async notExistCheckForUploadList() {
      if (this.homeworkFileHistoryItems.length > 0) {
        let filteredHomeworkFileHistoryItems =
          this.homeworkFileHistoryItems.filter(
            (hisItem) => hisItem.file_status_flg !== 0
          )

        if (filteredHomeworkFileHistoryItems.length > 0) {
          // 再利用または削除予定のアップロード一覧が1つでも存在する場合存在チェックを行う

          let deletedList = []

          // PDFの要素のうち既に存在しない配信ファイルを使用している宿題がないかをチェック
          if (filteredHomeworkFileHistoryItems.length > 0) {
            const result = await homeworkApi.getHomeworkStreamFileHistoryList(
              this.loginUserInfo.accountId, // アカウントID
              this.loginUserInfo.schoolId, // 学校ID
              homeworkTypeCode.pdf, // 宿題種別
              this.loginUserInfo.lmsApiToken // APIトークン
            )
            let haishinList = result.data.data.haishinfile_history_list
            const deletedItems = filteredHomeworkFileHistoryItems.filter(
              (item) => {
                return haishinList.every((elem) => {
                  return elem.haishin_file_no != item.haishin_file_no
                })
              }
            )
            deletedList = deletedList.concat(deletedItems)
          }
          if (deletedList.length > 0) {
            let filteredHomeworkDetailItems = this.homeworkDetailItems.filter(
              (item) => {
                return item.questionType == homeworkTypeCode.pdf
              }
            )
            let deleteTasks = filteredHomeworkDetailItems.filter((item) => {
              return deletedList.some((elem) => {
                return (
                  elem.sub_info[0].file_path == item.subQuestionInfo.filePath
                )
              })
            })

            if (deleteTasks.length > 0) {
              let taskNames = ""
              deleteTasks.forEach((item) => {
                taskNames += "・" + item.taskName + "\n"
              })
              // エラーメッセージ表示
              this.showModalOkOnly(
                this.$t("messages.error.notExistUploadFileForPublishOrSave", {
                  taskName: taskNames,
                })
              )
              return true
            }
          }
        }
      }
      return false
    },
    /**
     * ファイルをドラッグアンドドロップした時の処理
     */
    dragAndDropSelectedFile(e) {
      e.preventDefault()
      if (!this.$el.querySelector(".file-select").contains(e.target)) {
        e.dataTransfer.dropEffect = "none"
        return
      }
      e.stopPropagation()
      e.dataTransfer.dropEffect = "copy"
    },
    onPreviewOpen() {
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.button-file-select {
  display: inline;

  .btn-sm {
    padding: 0rem 0.5rem;
    border-radius: 2rem;
  }
}

.explanation {
  margin-top: 1em;
  background: url(../assets/images/arrow_blue.png) center center/auto 100%
    no-repeat;
  padding: 1.3em 0 1.3em 60%;
  @media (max-width: 1280px) {
    background-size: 15%;
  }
}

.capacity-bar {
  position: relative;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
</style>
