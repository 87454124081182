var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-homework-stream-data-list" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-8" }, [
          _c(
            "label",
            {
              staticClass: "form-label mt-3 mb-1",
              class: { "required-label": _vm.isRequire }
            },
            [_vm._v(_vm._s(_vm.$t("labels.streamData")))]
          )
        ]),
        _vm.limit === 0
          ? _c("div", { staticClass: "col-4 text-end" }, [
              _c("label", { staticClass: "form-label mt-3 mb-1 mx-3" }, [
                _vm._v(" " + _vm._s(_vm.streamDataList.length) + "件 ")
              ])
            ])
          : _c("div", { staticClass: "col-4 text-end" }, [
              _c("label", { staticClass: "form-label mt-3 mb-1 mx-3" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.streamDataList.length) +
                    "/" +
                    _vm._s(_vm.limit) +
                    "件 "
                )
              ])
            ])
      ]),
      _c(
        "div",
        {
          staticClass: "table-wrap",
          style: { "max-height": 42 * (_vm.displayCount + 1) + "px" }
        },
        [
          _c("table", [
            _c("thead", [
              _c(
                "tr",
                { staticClass: "table-header" },
                _vm._l(_vm.headers, function(header, index) {
                  return _c("th", { key: index }, [
                    _c("div", [_vm._v(_vm._s(header))])
                  ])
                }),
                0
              )
            ]),
            _c(
              "tbody",
              _vm._l(_vm.dataList, function(item, index) {
                return _c(
                  "tr",
                  { key: index, staticClass: "text-center px-1" },
                  [
                    item
                      ? [
                          _c("td", { staticClass: "text-start px-1" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c(
                                  "div",
                                  { staticClass: "mx-4 show-three-point" },
                                  [_vm._v(" " + _vm._s(item.taskName) + " ")]
                                )
                              ])
                            ])
                          ]),
                          _c(
                            "td",
                            [
                              _c("ButtonPreview", {
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.onClickPreview(item)
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c("ButtonDelete", {
                                attrs: { disabled: _vm.isPublished },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.onClickDelete(item, index)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      : [_c("td", { attrs: { colspan: 3 } })]
                  ],
                  2
                )
              }),
              0
            )
          ])
        ]
      ),
      _vm.showPreviewPdfQuestion
        ? _c("ModalPreviewPdfQuestion", {
            attrs: {
              "task-name": _vm.taskName,
              "send-memo": _vm.sendMemo,
              "image-list": _vm.imageList,
              "default-question-count": _vm.questionQuantity,
              "is-disabled": true
            },
            on: {
              close: function($event) {
                _vm.showPreviewPdfQuestion = false
              }
            }
          })
        : _vm._e(),
      _vm.showPreviewStdbQuestion
        ? _c("ModalPreviewStdbQuestion", {
            attrs: {
              "task-name": _vm.taskName,
              "send-memo": _vm.sendMemo,
              "prm-question-type": _vm.itemQuestionType,
              "prm-question-quantity": _vm.questionQuantity,
              "image-list": _vm.imageList,
              "ans-disp-flg": _vm.ansDispFlg,
              "explain-disp-flg": _vm.explainDispFlg,
              "is-disabled": true
            },
            on: {
              close: function($event) {
                _vm.showPreviewStdbQuestion = false
              }
            }
          })
        : _vm._e(),
      _vm.showConfirmDelete
        ? _c(
            "ModalConfirm",
            _vm._g(
              {
                attrs: {
                  message: _vm.$t(
                    "messages.confirm.deleteHomeworkReplaceWords",
                    {
                      homeworkName: _vm.deleteHomeworkName
                    }
                  )
                }
              },
              {
                "ok-confirm-modal": _vm.okConfirmModal,
                "close-confirm-modal": _vm.closeConfirmModal
              }
            )
          )
        : _vm._e(),
      _vm.showConfirmCaution
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.cautionMessage },
            on: {
              "close-confirm-modal-ok-only": function($event) {
                _vm.showConfirmCaution = false
              }
            }
          })
        : _vm._e(),
      _vm.isLoading ? _c("LoadingScreen") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }