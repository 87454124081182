var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "forbidden-content" }, [
    _vm._m(0),
    _vm._m(1),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-6 text-center" },
        [
          _c(
            "router-link",
            { attrs: { to: { name: "Login" } } },
            [
              _c("ButtonForward", {
                attrs: {
                  "label-name": _vm.$t("buttons.returnToLogin"),
                  color: "secondary"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "col-6 text-center" }, [
        _c(
          "a",
          { attrs: { href: _vm.inquiryUrl, target: "_blank" } },
          [
            _c("ButtonForward", {
              attrs: {
                "label-name": _vm.$t("buttons.toInquiry"),
                color: "secondary"
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-center" }, [
      _c("h1", [_vm._v("エラーが発生しました。")]),
      _c("h3", { staticClass: "text-muted" }, [_vm._v(" 403 Not Found ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _c("p", [_vm._v("指定したページへのアクセスが出来ませんでした。")]),
      _c("p", [_vm._v("時間をおいて再度お試しください。")]),
      _c("p", [
        _vm._v("それでも改善しない場合は数研出版までお問い合わせください。")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }