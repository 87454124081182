<template>
  <div class="button-copy">
    <button
      type="button"
      class="btn"
      :class="{'disable' : disable}"
    >
      <IconCopy
        width="25px"
        height="25px"
      />
    </button>
  </div>
</template>

<script>
import IconCopy from "@/components/atoms/icons/IconCopy.vue"

export default {
  name: "ButtonCopy",
  components: {
    IconCopy,
  },
  props: {
    disable : { type: Boolean, default: false }
  },
}
</script>

<style lang="scss" scoped>
.btn.disable{
  opacity: 0.3 !important;
  filter:grayscale(1);
  cursor: auto!important;
}
</style>