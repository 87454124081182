var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "setting-preview-content" }, [
    _vm.content
      ? _c("div", { staticClass: "content text-align-center" }, [
          _c("img", {
            staticClass: "content-item",
            attrs: { src: _vm.content }
          })
        ])
      : _vm.content === ""
      ? _c(
          "div",
          {
            staticClass:
              "no_image d-flex align-items-center justify-content-center"
          },
          [_vm._v(" 画像がありません。 ")]
        )
      : _c("div")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }