/**
 * 変換処理関連
 */

import { settingTabIdCode } from "../constant/question"

export default {
  /**
   * タブリスト生成
   * @param {Object} questionList 問題リスト
   * @param {Object} contentsList コンテンツリスト
   * @returns タブリスト
   */
  getDisplayFilterTab(questionList, contentsList) {
    // スライドIDが一致するコンテンツが存在するかチェック
    const self = this
    function checkMatchContent() {
      if (contentsList) {
        let nodesSection = self.checkMatchNodesIdAndChapterNodesId(questionList)
        let contentExistFlag = false
        let slide = []
        for (let i in nodesSection) {
          for (let slideId of Object.keys(nodesSection[i])) {
            slide.push(slideId)
          }
        }
        for (let slideId of slide) {
          for (let content of Object.keys(contentsList)) {
            if (slideId === content) {
              contentExistFlag = true
              break
            }
          }
          if (contentExistFlag) {
            break
          }
        }
        return contentExistFlag
      }
      return false
    }

    let filterTab = []
    filterTab.push(
      {
        id: settingTabIdCode.hintId,
        label: questionList.tab.text[0],
        content: null,
      },
      {
        id: settingTabIdCode.answerId,
        label: questionList.tab.text[1],
        content: null,
      },
      {
        id: settingTabIdCode.explainId,
        label: questionList.tab.text[2],
        content: null,
      }
    )
    if (checkMatchContent()) {
      filterTab.push({
        id: settingTabIdCode.contentId,
        label: "コンテンツ",
        content: null,
      })
    }
    return filterTab
  },

  /**
   * le_ques_config.jsonのタブごとの変換結果を返却
   * @param {Object} questionList le_ques
   * @param {Object} contentsList le_content
   * @param {Number} currentId 現在のID
   * @returns 変換処理結果
   */
  generateObjectTree(questionList, contentList, currentId) {
    switch (currentId) {
      case settingTabIdCode.hintId:
        return this.generateHintObjectTree(questionList)
      case settingTabIdCode.answerId:
        return this.generateAnswerObjectTree(questionList)
      case settingTabIdCode.explainId:
        return this.generateExplainObjectTree(questionList)
      case settingTabIdCode.contentId:
        return this.generateContentObjectTree(questionList, contentList)
      default:
        break
    }
  },

  /**
   * le_ques_config.jsonからChapterのnodeを切り出して返却
   */
  getChapterNode(questionList) {
    let chaptersNode = []
    for (let chapter of questionList.chapters) {
      Object.keys(chapter.nodes).map((key) =>
        chaptersNode.push({
          id: chapter.nodes[key][1],
          title: chapter.nodes[key][0],
        })
      )
    }
    return chaptersNode
  },

  /**
   * le_ques_config.jsonのNodesのIdとChapterNodesのIdが一致するものを返却
   */
  checkMatchNodesIdAndChapterNodesId(questionList) {
    // ChapterのNodesの節IDを返却
    let chapterNodesSectionId = []
    const chapters = this.getChapterNode(questionList)
    for (let node of chapters) {
      chapterNodesSectionId.push(node.id)
    }

    // Chaptersの節IDとNodesの節IDが一致していたらNodesの節を返却
    let nodesSection = []
    for (let nodesSectionId of Object.keys(questionList.nodes)) {
      for (let nodesId of chapterNodesSectionId) {
        if (nodesSectionId === nodesId) {
          nodesSection.push(questionList.nodes[nodesSectionId])
        }
      }
    }
    return nodesSection
  },

  /**
   * Nodesの親問題を入れた節を返す
   */
  getParentQuestion(questionList, baseNum, sliceNum) {
    let chaptersNode = this.getChapterNode(questionList)
    let nodesSection = this.checkMatchNodesIdAndChapterNodesId(questionList)
    let section = []
    for (let i in chaptersNode) {
      chaptersNode[i].nodes = []
      Object.keys(nodesSection[i]).map((key) =>
        chaptersNode[i].nodes.push({
          id: key,
          title:
            nodesSection[i][key].questions.parents[1] === ""
              ? nodesSection[i][key].questions.parents[0]
              : nodesSection[i][key].questions.parents.join("　"),
          img: nodesSection[i][key].img,
          displayFlag: nodesSection[i][key].data.slice(baseNum, sliceNum)[0],
          displayFilter: nodesSection[i][key].data.slice(1, 4),
          questionCount: nodesSection[i][key].questions.children.length,
        })
      )
      section.push(chaptersNode[i])
    }
    return section
  },

  /**
   * chapterのnodesに変換したデータを入れて返す
   */
  getGenerateObject(questionList, baseNum, sliceNum) {
    let prevIndex = 0
    const parentQuestions = this.getParentQuestion(
      questionList,
      baseNum,
      sliceNum
    )
    return Object.keys(questionList.chapters).map((key) => {
      const obj = {
        id: questionList.chapters[key].id,
        title: questionList.chapters[key].title,
        nodes: parentQuestions.slice(
          prevIndex,
          prevIndex + questionList.chapters[key].nodes.length
        ),
        suffix: questionList.tab.ext,
      }
      prevIndex += obj.nodes.length
      return obj
    })
  },

  /**
   * 表示制御 - ヒントタブの変換処理を返却
   */
  generateHintObjectTree(questionList) {
    return this.getGenerateObject(questionList, 1, 2)
  },
  /**
   * 表示制御 - 解答タブの変換処理を返却
   */
  generateAnswerObjectTree(questionList) {
    return this.getGenerateObject(questionList, 2, 3)
  },
  /**
   * 表示制御 - 解説タブの変換処理を返却
   */
  generateExplainObjectTree(questionList) {
    return this.getGenerateObject(questionList, 3, 4)
  },
  /**
   * 表示制御 - コンテンツタブの変換処理を返却
   */
  generateContentObjectTree(questionList, contentsList) {
    const self = this
    function checkContentAndMatchSlideId() {
      let nodeSection = self.checkMatchNodesIdAndChapterNodesId(questionList)
      let contentsLink = []
      for (let i in nodeSection) {
        for (let slideid of Object.keys(nodeSection[i])) {
          let result = Object.keys(contentsList).filter(
            (content) => content === slideid
          )
          contentsLink[slideid] = result.length
            ? contentsList[result].contentslink
            : []
        }
      }
      return contentsLink
    }

    /**
     * 表示制御 - コンテンツ層の生成
     */
    function createContentLayer(questionList) {
      let parentQuestion = self.getParentQuestion(questionList, NaN, NaN)
      let contentsLink = checkContentAndMatchSlideId()
      let content = {}
      const separator = "<>"
      for (let i in contentsLink) {
        let contentLink = Object.keys(contentsLink[i]).map((key) => {
          const contentsFlag = Number(contentsLink[i][key].split(separator)[5])
          let displayFlag = 1
          let checkFlag = false
          switch (contentsFlag) {
            case 1:
              displayFlag = 0
              checkFlag = true
              break
            case 2:
              checkFlag = true
              break
            default:
              break
          }
          const contentIdLength = 100 // コンテンツIDの長さ
          return {
            id: contentsLink[i][key]
              .split(separator)[2]
              .substr(-contentIdLength, contentIdLength),
            title: contentsLink[i][key].split(separator)[1],
            img: contentsLink[i][key].split(separator)[4],
            displayFlag: displayFlag,
            checkFlg: checkFlag,
            isContent: true,
          }
        })
        content[i] = contentLink
      }
      parentQuestion.forEach((question) => {
        question.nodes.forEach((item) => {
          item.displayFlag =
            content[item.id] && content[item.id].length > 0 ? 1 : 0
          item.nodes = JSON.parse(JSON.stringify(content[item.id]))
        })
      })
      return parentQuestion
    }

    let prevIndex = 0
    return Object.keys(questionList.chapters).map((key) => {
      const obj = {
        id: questionList.chapters[key].id,
        title: questionList.chapters[key].title,
        nodes: createContentLayer(questionList).slice(
          prevIndex,
          prevIndex + questionList.chapters[key].nodes.length
        ),
      }
      prevIndex += obj.nodes.length
      return obj
    })
  },
  /**
   * 宿題作成 - 配信データ追加ツリーの変換処理を返却
   */
  generateStreamObjectTree(questionList) {
    return this.getGenerateObject(questionList, 5, 6)
  },
}
