<template>
  <div class="page-not-found-content">
    <div class="text-center">
      <h1>ファイルが見つかりません。</h1>
      <h3 class="text-muted">
        404 Not Found
      </h3>
    </div>
    <div class="content">
      <p>アクセスしたURLが存在しません。</p>
      <p>
        ブックマークに登録して直接開かれている場合は登録しなおしてください。
      </p>
      <p>考えられる原因としては以下の通りです。</p>
      <p>・指定されたURLが存在しません。</p>
      <p>
        ・URLが間違っているか、ファイルが移動、または名前が変更されています。
      </p>
      <p>・ご覧になっていたページからのリンクが無効になっています。</p>
    </div>
    <div class="row">
      <div class="col-6 text-center">
        <router-link :to="{ name: 'Login' }">
          <ButtonForward
            :label-name="$t('buttons.returnToLogin')"
            color="secondary"
          />
        </router-link>
      </div>
      <div class="col-6 text-center">
        <a
          :href="inquiryUrl"
          target="_blank"
        >
          <ButtonForward
            :label-name="$t('buttons.toInquiry')"
            color="secondary"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonForward from "@/components/atoms/buttons/ButtonForward.vue"

export default {
  name: "PageNotFoundContent",
  components: {
    ButtonForward,
  },
  data: () => ({
    inquiryUrl: "https://www.chart.co.jp/inquiry/inquiry.html",
  }),
}
</script>

<style lang="scss" scoped>
.page-not-found-content {
  padding: 2.5em;
  min-width: 600px;
  border-style: double;
}
.content {
  padding: 1.5em;
}
.footer {
  justify-content: center;
}
</style>
