var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chat-area" }, [
    _c("div", { staticClass: "chat-cotents-wrapper" }, [
      _c(
        "ul",
        { ref: "chatView", staticClass: "container chat-view" },
        [
          _vm.displayTeacherComment && !_vm.disableComment
            ? _c("li", { staticClass: "teacher text-end" }, [
                _c("p", [_vm._v("先生")]),
                _c("span", [_vm._v(_vm._s(_vm.teacherComment))])
              ])
            : _vm._e(),
          _vm.haishinStartDate
            ? _c("li", { staticClass: "text-fixed" }, [
                _vm._v(
                  " " + _vm._s(_vm.haishinStartDate) + " 宿題を配信しました "
                )
              ])
            : _vm._e(),
          _vm.comments[0] &&
          _vm.comments[0].teacher_comment &&
          _vm.comments[0].comment_kbn === 1 &&
          !_vm.disableComment
            ? _c("li", { staticClass: "teacher text-end" }, [
                _c("p", [_vm._v("先生")]),
                _c("span", [_vm._v(_vm._s(_vm.comments[0].teacher_comment))])
              ])
            : _vm._e(),
          _vm.comments[0] &&
          _vm.comments[0].update_date &&
          _vm.comments[0].comment_kbn === 1
            ? _c("li", { staticClass: "text-fixed" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.convertValue.toDateTimeStringSeparator(
                        _vm.comments[0].update_date
                      )
                    ) +
                    " 宿題を通知しました "
                )
              ])
            : _vm._e(),
          _vm.displayStudentComment && !_vm.disableComment
            ? _c("li", { staticClass: "student text-start" }, [
                _c("p", [_vm._v("生徒")]),
                _c("span", [_vm._v(_vm._s(_vm.studentComment))])
              ])
            : _vm._e(),
          _vm.submitDate
            ? _c("li", { staticClass: "text-fixed" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.convertValue.toDateTimeStringSeparator(_vm.submitDate)
                    ) +
                    " 宿題が提出されました "
                )
              ])
            : _vm._e(),
          _vm._l(_vm.comments, function(comment, index) {
            return [
              comment.teacher_comment &&
              comment.comment_kbn === 0 &&
              !_vm.disableComment
                ? _c(
                    "li",
                    {
                      key: "teacher-comment-" + index,
                      staticClass: "teacher text-end"
                    },
                    [
                      _c("p", [_vm._v("先生")]),
                      _c("span", [_vm._v(_vm._s(comment.teacher_comment))])
                    ]
                  )
                : _vm._e(),
              comment.resubmit_instract_date && comment.comment_kbn === 0
                ? _c(
                    "li",
                    {
                      key: "resubmit-instract-date-" + index,
                      staticClass: "text-fixed"
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.convertValue.toDateTimeStringSeparator(
                              comment.resubmit_instract_date
                            )
                          ) +
                          " 宿題のやり直しを指示しました "
                      )
                    ]
                  )
                : _vm._e(),
              comment.student_comment && !_vm.disableComment
                ? _c(
                    "li",
                    {
                      key: "student-comment-" + index,
                      staticClass: "student text-start"
                    },
                    [
                      _c("p", [_vm._v("生徒")]),
                      _c("span", [_vm._v(_vm._s(comment.student_comment))])
                    ]
                  )
                : _vm._e(),
              comment.resubmit_date
                ? _c(
                    "li",
                    {
                      key: "resubmit-date-" + index,
                      staticClass: "text-fixed"
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.convertValue.toDateTimeStringSeparator(
                              comment.resubmit_date
                            )
                          ) +
                          " 宿題が提出されました "
                      )
                    ]
                  )
                : _vm._e()
            ]
          }),
          _vm.displayReturnComment
            ? [
                _vm.returnComment && !_vm.disableComment
                  ? _c("li", { staticClass: "teacher text-end" }, [
                      _c("p", [_vm._v("先生")]),
                      _c("span", [_vm._v(_vm._s(_vm.returnComment))])
                    ])
                  : _vm._e(),
                _vm.returnDate
                  ? _c("li", { staticClass: "text-fixed" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.convertValue.toDateTimeStringSeparator(
                              _vm.returnDate
                            )
                          ) +
                          " 宿題を返却しました "
                      )
                    ])
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "d-flex position-relative",
          class: { "no-editing-permission": !_vm.editingPermission }
        },
        [
          _c("p", { staticClass: "comment-area" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.message,
                  expression: "message"
                }
              ],
              staticClass: "form-control h-30",
              attrs: {
                placeholder: _vm.$t(
                  _vm.disableComment
                    ? "placeholder.disableComment"
                    : "placeholder.returnComment"
                ),
                "aria-label": "chat",
                maxlength: "150",
                readonly: _vm.disableComment
              },
              domProps: { value: _vm.message },
              on: {
                blur: function($event) {
                  return _vm.onChangeMessage()
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.message = $event.target.value
                }
              }
            })
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }