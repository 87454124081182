var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "icon-circle" }, [
    _c(
      "svg",
      {
        staticClass: "bi bi-dash",
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          width: _vm.width,
          height: _vm.height,
          fill: _vm.dashStyle,
          viewBox: "0 0 16 16"
        }
      },
      [
        _c("path", {
          attrs: {
            d: "M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }