var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "icon-progress-unconfirm" }, [
    _c(
      "svg",
      {
        staticStyle: { opacity: "1" },
        attrs: {
          id: "_x32_",
          version: "1.1",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          x: "0px",
          y: "0px",
          width: _vm.width,
          height: _vm.height,
          viewBox: "0 0 512 512",
          "xml:space": "preserve"
        }
      },
      [
        _c("g", [
          _c("rect", {
            staticClass: "st0",
            attrs: { x: "114", y: "158.2", width: "81.4", height: "309.5" }
          }),
          _c("path", {
            staticClass: "st0",
            attrs: {
              d:
                "M357.7,207.1c-4.3,5.8-6.7,12.8-6.7,20.1c0,9,3.5,17.4,9.9,23.8c6.4,6.4,14.8,9.9,23.8,9.9\n          c9,0,17.4-3.5,23.8-9.8c12-12,13-30.8,3.1-44L357.7,207.1L357.7,207.1z"
            }
          }),
          _c("path", {
            staticClass: "st0",
            attrs: {
              d:
                "M342,334.1v133.6h81.4V335.6c-12.3,4.4-25.4,6.7-38.8,6.7C369.8,342.2,355.3,339.4,342,334.1z"
            }
          }),
          _c("path", {
            staticClass: "st0",
            attrs: {
              d:
                "M269.7,227.3c0-30.7,11.9-59.5,33.6-81.3c2-2,3.9-3.8,6-5.5V44.3H228v423.5h81.4V314.1c-2.1-1.7-4.1-3.6-6-5.5\n          C281.6,286.9,269.7,258,269.7,227.3z"
            }
          }),
          _c("rect", {
            staticClass: "st0",
            attrs: { y: "304.8", width: "81.4", height: "162.9" }
          }),
          _c("path", {
            staticClass: "st0",
            attrs: {
              d:
                "M504.6,319.1l-43.9-42.8c9.4-14.5,14.4-31.4,14.4-49.1c0-24.2-9.4-46.9-26.5-64c-17.1-17.1-39.8-26.5-64-26.5\n          s-46.9,9.4-64,26.5c-17.1,17.1-26.5,39.8-26.5,64c0,24.2,9.4,46.9,26.5,64c17.1,17.1,39.8,26.5,64,26.5c17.7,0,34.6-5.1,49.1-14.5\n          l42.8,43.9c8,9.6,19.7,10.2,28.8,1.1C514.4,339.3,514.2,327.1,504.6,319.1z M384.6,275.9c-13,0-25.2-5.1-34.3-14.2\n          c-9.2-9.2-14.2-21.4-14.2-34.3c0-13,5-25.2,14.2-34.3c9.2-9.2,21.4-14.2,34.3-14.2c13,0,25.2,5.1,34.3,14.2\n          c18.9,18.9,18.9,49.7,0,68.7C409.8,270.8,397.6,275.9,384.6,275.9z"
            }
          })
        ]),
        _c("circle", {
          staticClass: "st1",
          attrs: { cx: "414.8", cy: "96.2", r: "90.4" }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }