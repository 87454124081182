var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-preview-textbook-question" },
    [
      _c("transition", { attrs: { name: "modal", appear: "" } }, [
        _c("div", { staticClass: "modal-mask" }, [
          _c("div", { staticClass: "modal-wrapper" }, [
            _c(
              "div",
              { staticClass: "modal-container-outer" },
              [
                _c("ButtonClose", {
                  staticClass: "modal-default-button",
                  nativeOn: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "modal-container px-2 py-4",
                    staticStyle: { overflow: "auto", "max-height": "80vh" }
                  },
                  [
                    _c("div", { staticClass: "row mb-2 task-name" }, [
                      _vm.selectedHomeworkItemIndex !== 0
                        ? _c("div", { staticClass: "col-2" }, [
                            _c("div", { staticClass: "show-three-point" }, [
                              _vm._v(
                                " No." +
                                  _vm._s(_vm.selectedHomeworkItemIndex) +
                                  " "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.bookName
                        ? _c("div", { staticClass: "col-3" }, [
                            _c("div", { staticClass: "show-three-point" }, [
                              _vm._v(" " + _vm._s(_vm.bookName) + " ")
                            ])
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "col-7" }, [
                        _c("div", { staticClass: "show-three-point" }, [
                          _vm._v(" " + _vm._s(_vm.taskName) + " ")
                        ])
                      ])
                    ]),
                    _vm.isLoading ? _c("LoadingScreen") : _vm._e(),
                    _c("PreviewContainer", {
                      attrs: {
                        image: _vm.image,
                        "setting-list": _vm.settingList
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }