var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "previwe-pdf-container col-8" }, [
    _c("div", { staticClass: "card w-100" }, [
      _c("div", { staticClass: "card-header" }, [
        _vm._v(" " + _vm._s(_vm.$t("labels.preview")) + " ")
      ]),
      _c("div", { staticClass: "mb-1 mx-2 text-end" }, [
        _vm._v(
          " " + _vm._s(_vm.currentPageNum + 1 + " / " + _vm.totalPageNum) + " "
        )
      ]),
      _c("div", { staticClass: "py-3 card-body preview-window window-body" }, [
        _c("div", { staticClass: "card" }, [
          _vm.image
            ? _c("div", { staticClass: "image" }, [
                _c("img", {
                  staticClass: "container-fluid",
                  attrs: { src: _vm.image }
                })
              ])
            : _vm.image === ""
            ? _c(
                "div",
                {
                  staticClass:
                    "no_image d-flex align-items-center justify-content-center"
                },
                [_vm._v(" 画像がありません。 ")]
              )
            : _c("div")
        ]),
        _c(
          "ul",
          { staticClass: "prev-next", staticStyle: { "list-style": "none" } },
          [
            _c("li", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentPageNum > 0,
                  expression: "currentPageNum > 0"
                }
              ],
              staticClass: "bi bi-chevron-left",
              staticStyle: { top: "45%" },
              on: { click: _vm.prevPage }
            }),
            _c("li", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.currentPageNum < _vm.totalPageNum - 1,
                  expression: "currentPageNum < (totalPageNum) -1"
                }
              ],
              staticClass: "bi bi-chevron-right",
              staticStyle: { top: "45%" },
              on: { click: _vm.nextPage }
            })
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }