<template>
  <div class="previwe-pdf-container col-8">
    <div class="card w-100">
      <div class="card-header">
        {{ $t("labels.preview") }}
      </div>
      <div class="mb-1 mx-2 text-end">
        {{ (currentPageNum + 1) + " / " + totalPageNum }}
      </div>
      <div class="py-3 card-body preview-window window-body">
        <div class="card">
          <div
            v-if="image"
            class="image"
          >
            <img
              class="container-fluid"
              :src="image"
            >
          </div>
          <!-- imageが空文字なら「画像がありません」と表示 -->
          <div
            v-else-if="image === ''"
            class="no_image d-flex align-items-center justify-content-center"
          >
            画像がありません。
          </div>
          <!--
            imageがnullなら何も表示しない
            ※初期表示での使用を想定
          -->
          <div v-else />
        </div>
        <ul
          class="prev-next"
          style="list-style: none"
        >
          <li
            v-show="currentPageNum > 0"
            class="bi bi-chevron-left"
            style="top: 45%;"
            @click="prevPage"
          />
          <li
            v-show="currentPageNum < (totalPageNum) -1"
            class="bi bi-chevron-right"
            style="top: 45%;"
            @click="nextPage"
          />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "PreviewPdfContainer",

  props: {
    previewBase64: {
      type: String,
      require: true,
      default: function () {
        return ""
      },
    },
    imgList: {
      type: Array,
      require: true,
      default: () => {return []}
    }
  },

  data: ()=>({
    currentPageNum: 0,  // 現在のページ
    totalPageNum: 1,    // 総ページ数
    image: "",
    imageList:[],
  }),
  
  mounted : function (){
    this.imageList = this.imgList
    this.totalPageNum = this.imgList.length
    this.image = this.imgList[0].file_image
  },

  methods: {

    // 前ページへ
    prevPage: function() {
      if (this.currentPageNum <= 0) return
      this.currentPageNum--
      this.image = this.imgList[this.currentPageNum].file_image
    },
    
    // 後ページへ
    nextPage: function() {
      if (this.currentPageNum > this.totalPageNum) return
      this.currentPageNum++
      this.image = this.imgList[this.currentPageNum].file_image
    },
  },
}
</script>

<style lang="scss" scoped>
.previwe-pdf-container {
  height: max-content;
  margin: 0 auto !important; 
  @media(max-width:1020px) {
    width: 100% !important;
  }

  .card-header {
    height: 7%;
    padding: 0.4rem;
    border-bottom: none;
    background: var(--bs-table-header);
    color: var(--bs-layout-theme);
    font-weight: bold;
  }
}

.preview-window {
  height: 500px;
  padding: 30px 30px;
  
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }

  li {
    position: absolute;
    top: 50%;
    font-size: 48px;
    width: 25px;
    height: 35px;
    cursor: pointer;
    left: -10px;
    color: #0070c0;
  }

  li:nth-of-type(2){
    left: auto;
    right: 15px;
  }
}

.no_image {
  height: 170px;
  font-size: 24px;
}

.image {
  height: 450px;
  overflow: auto;

  img {
    -webkit-user-drag: none;
    user-select: none;
  }
}
</style>
