var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-column login-background" }, [
    _c(
      "div",
      { staticClass: "login d-flex justify-content-center align-self-center" },
      [
        _c(
          "LoginForm",
          _vm._g(
            {
              attrs: {
                "is-authentication-error": _vm.isAuthenticationError,
                "is-progressing": _vm.isProgressing,
                "is-session-error": _vm.isSessionError
              }
            },
            {
              login: _vm.onLogin,
              "login-for-apple": _vm.onLoginForApple,
              "login-for-google": _vm.onLoginForGoogle,
              "login-for-microsoft": _vm.onLoginForMicrosoft
            }
          )
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }