<template>
  <div class="login-form d-flex justify-content-center login-form-fontFamily">
    <div
      class="flex-fill d-flex flex-column align-items-center justify-content-center my-4 mx-4"
    >
      <div class="logo-wrap row align-items-center mt-3 mb-5">
        <SViewerLogo class="text-center text-md-end col-md-6 px-1 mb-2"/>
        <span class="sviewer-logo-title text-center text-md-start col-md-6 px-1">
          <font :color="colorLayoutTheme">
            {{ $t("labels.loginTitle") }}
          </font>
        </span>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-6 login-form-area">
            <div class="d-flex flex-column">
              <div class="d-flex justify-content-center border-bottom mb-3">
                <h6>{{ $t("labels.signinWithSuken") }}</h6>
              </div>
              <div class="d-flex acount-login-form">
                <ValidationObserver ref="form">
                  <form
                    class="acount-login-form-margin"
                    @submit.prevent="onSubmit"
                  >
                    <div class="mb-3 text-start">
                      <ValidationProvider
                        name="id"
                        mode="passive"
                        rules="required|min:1|max:40|possibleLoginId"
                      >
                        <FormLoginIdField
                          id="userName"
                          v-model="idValue"
                          label-text="ID"
                          :is-disabled="isProgressing"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="mb-3 text-start">
                      <ValidationProvider
                        name="password"
                        mode="passive"
                        rules="required|min:1|possibleLoginPassword"
                      >
                        <FormLoginPasswordField
                          id="password"
                          v-model="password"
                          :label-text="$t('labels.password')"
                          :is-disabled="isProgressing"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="mb-3">
                      <span
                        v-if="isAuthenticationError"
                        class="text-danger"
                      >{{
                        authenticationErrorMessage
                      }}</span>
                      <span
                        v-else-if="isSessionError"
                        class="text-danger"
                      >{{
                        $t('messages.error.overLimitSessionToken')
                      }}</span>
                      <span
                        v-else
                        class="text-danger"
                      >{{
                        validateMessage
                      }}</span>
                    </div>

                    <div class="mt-4 mb-4 login-submit">
                      <ButtonSubmit
                        :label-name="$t('buttons.login')"
                        :color="colorLayoutTheme"
                        label-color="white"
                        :is-disabled="isDisabledButtonSubmit"
                      />
                    </div>
                    <div class="mb-3 adminMessage-style">
                      <p class="font-small">
                        {{ $t("labels.adminMessage") }}
                      </p>
                    </div>
                    <div class="mb-3 forgetPassword-style">
                      <LinkText
                        class="font-small"
                        :link-url="helpUrl"
                        :label-text="$t('labels.forgotPassword')"
                      />
                      <p class="text-muted font-small">
                        {{ $t("labels.transferMessage") }}
                      </p>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>

          <div class="col-6 platform-login">
            <div class="d-flex flex-column content-height">
              <div class="d-flex justify-content-center border-bottom mb-4">
                <h6>{{ $t("labels.signinWithSocial") }}</h6>
              </div>
              <div class="d-flex justify-content-center mb-4 apple-btn-size">
                <ButtonAppleLogin
                  width="100%"
                  @click.native="onClickLoginForApple"
                />
              </div>
              <div class="d-flex justify-content-center mb-4 google-btn-size">
                <ButtonGoogleLogin
                  width="100%"
                  @click.native="onClickLoginForGoogle"
                />
              </div>
              <div class="d-flex justify-content-center microsoft-btn-size">
                <ButtonMicrosoftLogin
                  width="100%"
                  @click.native="onClickLoginForMicrosoft"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row align-self-end terms">
        <LinkTermsText
          class="font-small"
          :label-text="$t('labels.termsOfService')"
          @click.native="showModal = 'terms'"
        />
      </div>
    </div>

    <ModalTerms
      v-if="showModal == 'terms'"
      :is-agree-required="false"
      @close-terms="showModal = ''"
    />
  </div>
</template>

<script>
import SViewerLogo from "@/components/atoms/SViewerLogo.vue"
import FormLoginIdField from "@/components/atoms/FormLoginIdField.vue"
import FormLoginPasswordField from "@/components/atoms/FormLoginPasswordField.vue"
import LinkText from "@/components/atoms/LinkText.vue"
import LinkTermsText from "@/components/atoms/LinkTermsText.vue"
import ButtonAppleLogin from "@/components/atoms/buttons/ButtonAppleLogin.vue"
import ButtonGoogleLogin from "@/components/atoms/buttons/ButtonGoogleLogin.vue"
import ButtonMicrosoftLogin from "@/components/atoms/buttons/ButtonMicrosoftLogin.vue"
import ButtonSubmit from "@/components/atoms/ButtonSubmit.vue"
import ModalTerms from "@/components/organisms/modal/ModalTerms.vue"

export default {
  name: "LoginForm",
  components: {
    SViewerLogo,
    FormLoginIdField,
    FormLoginPasswordField,
    LinkText,
    LinkTermsText,
    ButtonAppleLogin,
    ButtonGoogleLogin,
    ButtonMicrosoftLogin,
    ButtonSubmit,
    ModalTerms,
  },
  props: {
    // ログイン認証エラーフラグ
    isAuthenticationError: { type: Boolean, require: false },
    // ログイン中フラグ
    isProgressing: { type: Boolean, require: false },
    // セッション切れフラグ
    isSessionError: { type: Boolean, require: false },
  },

  data: () => ({
    colorLayoutTheme: "#ff7f27",
    idValue: "",
    password: "",
    linkUrl: "https://www.chart.co.jp/software/digital/sviewer/terms.html",
    helpUrl: "https://www.chart.co.jp/software/digital/sviewer/idpw.html",
    validateMessage: "",
    authenticationErrorMessage: "",
    showModal: "",
  }),

  computed: {
    isReauiredIdAndPassword: function () {
      // ID とパスワードの両方を入力したらログインボタンを活性に変更する
      // 両方入力されていなければ非活性とする
      return !(this.idValue && this.password)
    },
    isDisabledButtonSubmit: function () {
      return (this.isProgressing)
    }
  },

  mounted: function () {
    this.authenticationErrorMessage = this.$t(
      "messages.error.loginAuthentication"
    )
  },

  methods: {
    onSubmit: function () {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          // ID のエラーメッセージ this.$refs.form.errors.id[0]
          // パスワードのエラーメッセージ this.$refs.form.errors.password[0]
          this.validateMessage = this.$t("messages.error.incorrectIdOrPassword")
          return
        }

        // Wait until the models are updated in the UI
        this.$nextTick(() => {
          this.$refs.form.reset()
        })

        // 入力した ID とパスワードを views コンポーネントに渡す
        this.$emit("login", { id: this.idValue, password: this.password })
      })
    },
    onClickLoginForApple() {
      this.$emit("login-for-apple")
    },
    onClickLoginForGoogle() {
      this.$emit("login-for-google")
    },
    onClickLoginForMicrosoft() {
      this.$emit("login-for-microsoft")
    },
  },
}
</script>

<style lang="scss" scoped>
h6 {
  font-size: 20px;
  color: #5e5e5e;
  font-weight: bold;
}
.login-form {
  background: #fafafa;
  margin: 1.75rem 2rem;
  width: 100%;
  max-width: 970px;
  height: auto;
  .acount-login-form-margin {
    margin-left: 4.2%;
  }
  .logo-wrap {
    width: 100%;
    height: auto;
    ::v-deep #sViewerLogo {
      width: 100%;
      max-width: 230px;
      height: auto;
    }
  }
}
.sviewer-logo-title {
  font-size: 45px;
  line-height: 100%;
}
.login-form-fontFamily {
  font: 400 87.5%/1.5em 'Open Sans', sans-serif;
}
.acount-login-form {
  width: 100%
}
.acount-login-form > span {
  width: 100%;
}
.login-submit {
  width: 95.5%;
}
.platform-login {
  width: 45%;
  padding-left: 1.5rem;
  @media (min-width: 991px) {
      padding-left: 2.8rem;
  }
}
.content-height {
  height: 20rem;
}

.adminMessage-style {
  text-indent: -1em;
  padding-left: 1em;
}

.forgetPassword-style > div{
  text-align: center;
}

.forgetPassword-style > p{
  text-align: center;
}

.font-small {
  font-size: 14px;
}

.apple-btn-size > div {
  width: 100%;
}
.google-btn-size > div {
  width: 100%;
}
.microsoft-btn-size > div {
  width: 100%;
}
.login-form-area {
  position: relative;
  width: 55%;
  padding-right: 5%;
  border-right: solid 1px #e1e1e1;
}
.terms-text {
  font-size: 1rem;
    @media (max-width: 991px) {
    font-size: .8rem;
  }
    @media (max-width: 768px) {
    font-size: .7rem;
  }
}
</style>
