/**
 * 英語
 * 定義していなくても、日本語翻訳が使用される。
 */

const messages =
{
  en: {
    buttons: {
      ok: 'OK',
      cancel: 'Cancel',
      login: 'Login',
      logout: 'Logout',
    },
  
    messages: {
      error: {
        incorrectIdOrPassword: 'Incorrect id or password.',
      }
    },
  }
}

export default messages