<template>
  <div class="modal-input-category">
    <transition
      name="modal"
      appear
    >
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container-outer">
            <div
              class="modal-container p-4"
              style="overflow: auto; max-height: 80vh"
            >
              <div class="row mt-2">
                <div class="col text-start">
                  <FormTextField
                    id="categoryName"
                    label-text="カテゴリー名を入力してください。（15文字以内）"
                    :value="categoryName"
                    :initial-value="initialCategoryName"
                    :max-length="15"
                    :is-require="false"
                    @input="categoryName = $event"
                  />
                </div>
              </div>
              <div
                class="row mt-1 error-message"
                :class="{ 'error-message-visible': showError }"
              >
                <div class="col text-start">
                  {{ errorMessage }}
                </div>
              </div>
              <div class="row h-25 gx-5 justify-content-center mt-4">
                <div class="col-auto">
                  <ButtonBorderCircleMedium
                    label-name="キャンセル"
                    padding="0"
                    :label-color="colorLayoutTheme"
                    :border-color="colorLayoutTheme"
                    font-size="1.0rem"
                    width="8rem"
                    @click.native="onClickCancel"
                  />
                </div>
                <div class="col-auto">
                  <ButtonBorderCircleMedium
                    label-name="OK"
                    padding="0"
                    color="layout-theme-light"
                    :label-color="colorLayoutTheme"
                    :border-color="colorLayoutTheme"
                    :disabled="!categoryName || showError"
                    font-size="1.0rem"
                    width="8rem"
                    @click.native="onClickOk"
                  />
                </div>
              </div>              
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/**
 * 宿題管理 - カテゴリー入力モーダル
 */
import { mapGetters } from "vuex"
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"
import FormTextField from "@/components/atoms/FormTextField.vue"
export default {
  name: "ModalInputCategory",
  components: {
    ButtonBorderCircleMedium,
    FormTextField,
  },
  props: {
    initialCategoryName: {
      type: String,
      default: "",
    },
    actionRow: {
      type: Number,
      default: 0,
    }
  },
  data: () => ({
    colorLayoutTheme: "#ff7f27",
    categoryName: "",
    showError: false,
    errorMessage: "",
  }),
  computed: {
    ...mapGetters("homework", [
      "categoryItems",
    ]),
  },
  watch: {
    categoryName(newValue) {
      if (newValue && newValue === this.initialCategoryName) {
        this.showError = false
        this.errorMessage = ""
        return
      }
      if (!newValue || newValue.match(/^[\s\u3000\r\n\t]*$/)) {
        this.showError = true
        this.errorMessage = "※カテゴリー名称は必須です。（空白のみの名前も登録できません。）"
        return
      }
      if (this.categoryItems.find(v => v === newValue)) {
        this.showError = true
        this.errorMessage = "※すでに存在するカテゴリー名称です。"
        return
      }
      if (newValue.match(/,/g)) {
        this.showError = true
        this.errorMessage = "※カテゴリー名称に「,」は使用できません。"
        return
      }
      this.showError = false
      this.errorMessage = ""
    }
  },
  methods: {
    /**
     * OK押下時
     */
    onClickOk() {
      if (!this.categoryName || this.showError) {
        return
      }
      this.$emit(
        "on-click-ok",
        {
          action: this.initialCategoryName ? 'upd' : 'add',
          actionRow: this.actionRow,
          initialCategoryName: this.initialCategoryName,
          categoryName: this.categoryName
        }
      )
    },
    /**
     * キャンセル押下時
     */
    onClickCancel() {
      this.$emit("on-click-cancel")
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;

    .modal-container-outer {
      width: 560px;
      margin-left: auto;
      margin-right: auto;

      .modal-default-button {
        width: 51px;
        margin-left: auto;
      }

      .modal-container {
        width: 480px;
        // height: 380px;
        margin: 0px auto;
        padding: 20px 30px;
        background-color: #fff;

        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        text-align: center;

        .error-message {
          height: 30px;
          font-size: 0.9em;
          color: red;
          visibility: hidden;
          &.error-message-visible {
            visibility: visible;
          }
        }
      }
    }
  }
}

// トランジション（アニメーション）設定
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: opacity 0.8s;
}
</style>
