<template>
  <div class="select-group-members">
    <label :class="{ 'required-label': isRequire }">配信先</label>
    <!-- TODO: 暫定で v-model と input イベントを両方定義 -->
    <multiselect
      v-model="selectedGroupMembers"
      :options="groupMembers"
      :multiple="true"
      :taggable="true"
      :searchable="false"
      :loading="loaded"
      :options-limit="2000"
      placeholder=""
      select-label="選択"
      select-group-label="全員を選択"
      selected-label="選択済"
      deselect-label="選択解除"
      deselect-group-label="全員解除"
      group-values="members"
      group-label="groupName"
      :group-select="true"
      label="name"
      track-by="code"
      open-direction="below"
      @select="onSelect"
      @remove="onRemove"
      v-on="{
        'delete-tag': onDeleteTag,
      }"
    />
  </div>
</template>

<script>
/**
 * グループメンバ コンポーネント
 */
import Multiselect from "vue-multiselect"

export default {
  name: "SelectGroupMembers",
  components: {
    Multiselect,
  },
  props: {
    options: {
      type: Array,
      default: function () {
        return []
      },
    },
    initialMembers: {
      type: Array,
      default: function () {
        return []
      },
    },
    isRequire: {
      type: Boolean,
      default: function () {
        return false
      },
    },
    isAddOnly: { type: Boolean },
  },

  data: function () {
    return {
      selectedGroupMembers: [],
      previousInitialGroupMembers: [],
      loaded: true,
      isSafari: false,
    }
  },
  computed: {
    groupMembers: {
      cache: false,
      get() {
        return [
          {
            groupName: "すべて",
            members: this.options.map((x) => {
              return {
                code: x.accountId,
                name: x.accountName,
                bookItems: x.bookItems,
                $isDisabled: x.$isDisabled,
              }
            }),
          },
        ]
      },
    },
  },

  async mounted() {
    await this.setInitialMembers()
    this.loaded = false

    let agent = window.navigator.userAgent.toLowerCase()
    this.isSafari = (agent.indexOf("chrome") == -1 && agent.indexOf("safari") != -1)
    if(this.isSafari) window.addEventListener('touchstart', this.closeMultiselect);
  },
  beforeDestroy() {
    if(this.isSafari) window.removeEventListener('touchstart', this.closeMultiselect);
  },

  methods: {
    /**
     * initialMembersを設定
     */
    async setInitialMembers() {
      // 配信中の場合、引数の値を変更不可にする
      if (this.isAddOnly) {
        // 引数の値配信先を変更できないようにする
        this.initialMembers.forEach((item) => {
          // vue-multiselectの仕様上"$isDisabled"をtrueにすると選択不可能
          this.$set(item, "$isDisabled", true)
        })
        this.options.forEach((item) => {
          // vue-multiselectの仕様上"$isDisabled"をtrueにすると選択不可能
          const index = this.initialMembers.findIndex((option) => {
            return option.code == item.accountId
          })
          if (index !== -1) {
            this.$set(item, "$isDisabled", true)
          } else {
            this.$set(item, "$isDisabled", false)
          }
        })
      }
        this.selectedGroupMembers = this.initialMembers
        this.$emit("input", this.selectedGroupMembers)
        await this.$nextTick()
        this.previousInitialGroupMembers = this.initialMembers
    },

    async onSelect() {
      await this.$nextTick()
      this.$emit("input", this.selectedGroupMembers)
    },

    /**
     * 削除押下時の処理
     */
    async onRemove(selectedOption) {
      await this.$nextTick()
      // 全削除押下時にうまく処理が動かないことがあるため処理を明記
      // （本来ならライブラリ側がうまくやってくれるため意識しなくてもよい）
      if (this.selectedGroupMembers.length === selectedOption.length) {
        this.onDeleteTag()
      }
      this.$emit("input", this.selectedGroupMembers)
    },
    /**
     * 削除処理
     */
    onDeleteTag: function () {
      if (this.isAddOnly) {
        this.selectedGroupMembers.splice(
          this.previousInitialGroupMembers.length,
          this.selectedGroupMembers.length
        )
      } else {
        this.selectedGroupMembers.splice(0)
      }
    },
    /**
     * クリック時のイベント
     * 欄外クリック時にMultiselectを閉じる
     */
    closeMultiselect(event) {
      if(!this.$el.querySelector('.multiselect').contains(event.target)){
        this.$el.querySelector('.multiselect').blur();
      }
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.select-group-members {
  .checkbox-stream-myself {
    ul {
      padding-left: 0;
    }
  }

  ::v-deep .multiselect {
    .multiselect__tag {
      color: #000;
      background: var(--bs-gray-400);
    }
  }
}
.required-label:before {
  content: "*";
  color: red;
}
</style>
