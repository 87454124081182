var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-bulk-return" },
    [
      _c("div", { staticClass: "modal-mask" }, [
        _c("div", { staticClass: "modal-wrapper" }, [
          _c("div", { staticClass: "modal-container-outer" }, [
            _c(
              "div",
              {
                staticClass: "modal-container px-3 py-4",
                staticStyle: { width: "800px" }
              },
              [
                _c("ButtonClose", {
                  staticClass: "modal-default-button",
                  nativeOn: {
                    click: function($event) {
                      return _vm.showCheckCloseModal.apply(null, arguments)
                    }
                  }
                }),
                _c("div", { staticClass: "container-fluid" }, [
                  _c("div", { staticClass: "col text-center m-auto" }, [
                    _c("h6", { staticClass: "m-0" }, [
                      _vm._v(" " + _vm._s(_vm.$t("titles.bulkReturn")) + " ")
                    ])
                  ])
                ]),
                _c("div", { staticClass: "w-100 modal-contents-area" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-around modal-contents-area-inner"
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { width: "100%" } },
                        [
                          _c(
                            "ChatArea",
                            _vm._g(
                              {
                                attrs: {
                                  stamps: _vm.stamps,
                                  "selected-stamp": _vm.stamp,
                                  "init-eval-code": _vm.initEvalCode,
                                  "init-eval-manual-set-flg":
                                    _vm.initEvalManualSetFlg,
                                  "init-stamp-code": _vm.initStampCode,
                                  "init-message": _vm.initMessage,
                                  submiting: _vm.submiting,
                                  "display-chat-view": _vm.displayChatView
                                }
                              },
                              {
                                "on-change-eval": _vm.onChangeEval,
                                "on-change-stamp": _vm.onChangeStamp,
                                "on-change-message": _vm.onChangeMessage
                              }
                            )
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c("div", { staticClass: "d-flex justify-content-end" }, [
                    true
                      ? _c(
                          "div",
                          { staticClass: "text-end return-annotation-text" },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("labels.recommendEvalMessage")) +
                                " "
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    { staticClass: "row gx-2 justify-content-center mt-4" },
                    [
                      _c(
                        "div",
                        { staticClass: "modal-bulk-return-buttons" },
                        [
                          _c("ButtonBorderCircleMedium", {
                            staticClass: "ps-3 pe-3",
                            attrs: {
                              "label-name": _vm.$t("buttons.cancel"),
                              "label-color": "#ff7f27",
                              "border-color": "#ff7f27",
                              width: "180px",
                              padding: "0"
                            },
                            nativeOn: {
                              click: function($event) {
                                return _vm.onClickCancel.apply(null, arguments)
                              }
                            }
                          }),
                          _vm.isStudentNotHanded()
                            ? _c(
                                "div",
                                { staticClass: "return-buttons" },
                                [
                                  _c("ButtonBorderCircleMedium", {
                                    staticClass: "ps-3 pe-3",
                                    attrs: {
                                      "label-name": _vm.$t("buttons.sendSave"),
                                      color: "send-back",
                                      "label-color": "#ff7f27",
                                      "border-color": "#ff7f27",
                                      width: "180px",
                                      padding: "0"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.onClickSendSave.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _c(
                                "div",
                                { staticClass: "return-buttons" },
                                [
                                  _c("ButtonBorderCircleMedium", {
                                    staticClass: "ps-3 pe-3",
                                    attrs: {
                                      "label-name": _vm.$t("buttons.sendBack"),
                                      color: "send-back",
                                      "label-color": "#ff7f27",
                                      "border-color": "#ff7f27",
                                      width: "180px",
                                      padding: "0"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.onClickSendBack.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  }),
                                  _c("ButtonBorderCircleMedium", {
                                    staticClass: "ps-3 pe-3",
                                    attrs: {
                                      "label-name": _vm.$t("buttons.returnOf"),
                                      color: "layout-theme-light",
                                      "label-color": "#ff7f27",
                                      "border-color": "#ff7f27",
                                      width: "180px",
                                      padding: "0"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.onClickReturnOf.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                        ],
                        1
                      )
                    ]
                  )
                ])
              ],
              1
            )
          ])
        ])
      ]),
      _vm.showReturnMessage
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.returnMessage },
            on: { "close-confirm-modal-ok-only": _vm.onClickForReturnMessage }
          })
        : _vm._e(),
      _vm.showSendBackMessage
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.sendBackMessage },
            on: { "close-confirm-modal-ok-only": _vm.onClickForSendBackMessage }
          })
        : _vm._e(),
      _vm.showSendSaveMessage
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.sendSaveMessage },
            on: { "close-confirm-modal-ok-only": _vm.onClickForSendSaveMessage }
          })
        : _vm._e(),
      _vm.showCautionCloseMessage
        ? _c(
            "ModalConfirm",
            _vm._g(
              {
                attrs: {
                  message: _vm.$t("messages.confirm.bulkNotSavedChanges")
                }
              },
              {
                "ok-confirm-modal": _vm.onClickOkForCautionCloseMessage,
                "close-confirm-modal": _vm.onClickCloseForCautionCloseMessage
              }
            )
          )
        : _vm._e(),
      _vm.showCancelSendBackMessage
        ? _c(
            "ModalConfirm",
            _vm._g(
              {
                attrs: {
                  message: _vm.$t("messages.confirm.bulkCancelSendBack")
                }
              },
              {
                "ok-confirm-modal": _vm.onClickOkForCancelSendBackMessage,
                "close-confirm-modal": _vm.onClickCloseForCancelSendBackMessage
              }
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }