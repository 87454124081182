<template>
  <div class="modal-preview-textbook-question">
    <transition
      name="modal"
      appear
    >
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container-outer">
            <ButtonClose
              class="modal-default-button"
              @click.native="$emit('close')"
            />
            <div
              class="modal-container px-2 py-4"
              style="overflow: auto; max-height: 80vh"
            >
              <div class="row mb-2 task-name">
                <div
                  v-if="selectedHomeworkItemIndex !== 0"
                  class="col-2"
                >
                  <div class="show-three-point">
                    No.{{ selectedHomeworkItemIndex }}
                  </div>
                </div>
                <div
                  v-if="bookName"
                  class="col-3"
                >
                  <div class="show-three-point">
                    {{ bookName }}
                  </div>
                </div>
                <div class="col-7">
                  <div class="show-three-point">
                    {{ taskName }}
                  </div>
                </div>
              </div>
              <LoadingScreen v-if="isLoading" />
              <PreviewContainer
                :image="image"
                :setting-list="settingList"
              />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/**
 * 宿題管理 - 教材の問題プレビューモーダル
 */
import ButtonClose from "@/components/atoms/buttons/ButtonClose.vue"
import PreviewContainer from "@/components/organisms/PreviewContainer.vue"
import questionRepository from "@/repositories/question"
import questionObjectGeneratorRepository from "@/repositories/questionObjectGenerator"
import LoadingScreen from "@/components/atoms/LoadingScreen.vue"
import accountRepository from "@/repositories/account"

export default {
  name: "ModalPreviewTextbookQuestion",
  components: {
    ButtonClose,
    PreviewContainer,
    LoadingScreen,
  },
  props: {
    taskName: {
      type: String,
      require: true,
      default: function () {
        return ""
      },
    },
    item: {
      type: Object,
      require: true,
      default: function () {
        return {}
      },
    },
    curriculum: {
      type: String,
      require: true,
      default: function () {
        return ""
      },
    },
    loginUserInfo: {
      type: Object,
      default: function () {
        return {}
      },
    },
    streamGroup: {
      type: Number,
      default: function () {
        return 0
      },
    },
    studentItems: {
      type: Array,
      require: true,
      default: function () {
        return []
      },
    },
    bookItems: {
      type: Array,
      require: true,
      default: function () {
        return []
      },
    },
    selectedHomeworkItemIndex: {
      type: Number,
      default: function () {
        return 0
      },
    },
  },

  data: () => ({
    colorLayoutTheme: "#ff7f27",
    image: "",
    questionList: [],
    settingList: null,
    isLoading: false,
  }),
  computed: {
    /**
     * bookIdから書籍タイトルを取得
     * @returns 書籍タイトル
     */
    bookName() {
      const book = this.bookItems.find(
        (book) => book.bookId === this.item.bookId
      )
      return book ? book.bookTitle : ""
    },
  },
  async mounted() {
    this.isLoading = true
    this.settingList = null
    // プレビュー画像のパスを作成
    const img = `./textbook/${this.curriculum}/${this.item.bookId}/${this.item.chapterId}/page/learning_img/${this.item.quesParentId}.jpg`

    try {
      await this.getBookAndStudentAccount()
      await this.getTextbookConfig()
      await this.getSettingFilterTab()
      await this.getImg(img)
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    /**
     * 書籍と書籍を持つ生徒アカウントから
     * 教材パッケージの閲覧権限を取得
     */
    async getBookAndStudentAccount() {
      try {
        const bookAndAccount = await accountRepository.getBookAndStudentAccount(
          this.studentItems,
          this.bookItems,
          this.item.bookId
        )
        await this.getCredentials(bookAndAccount)
      } catch (error) {
        console.error(error)
      }
    },
    /**
     * 教材パッケージの閲覧権限を取得
     * @returns 閲覧権限 Cookie
     */
    async getCredentials(bookAndAccount) {
      if (!bookAndAccount || !bookAndAccount.student || !bookAndAccount.book) {
        console.warn(`教材情報が存在しないため閲覧権限の取得は行いません`)
        return
      }
      try {
        return await accountRepository.getCookieSukenAccountWithBooks(
          this.streamGroup,
          bookAndAccount.student.accountId,
          bookAndAccount.book.productIds[0],
          this.item.bookId,
          this.loginUserInfo.accountId,
          this.loginUserInfo.sessionToken
        )
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    /**
     * 教材を取得
     */
    async getTextbookConfig() {
      try {
        const textbookConfigPromise =
          await questionRepository.getTextbookConfigJson(
            this.curriculum,
            this.item.bookId
          )
        this.$set(this, "questionList", textbookConfigPromise.data)
      } catch (error) {
        console.error(error)
      }
    },
    /**
     * ３タブを取得
     */
    async getSettingFilterTab() {
      try {
        const previewTab = []
        const tabList = questionObjectGeneratorRepository.getDisplayFilterTab(
          this.questionList,
          null
        )
        // 1,2タブの時nullを入れる
        for (let tab of tabList) {
          previewTab.push(tab.label ? tab : null)
        }
        this.settingList = previewTab
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    /**
     * プレビュー画像を取得
     * @param {String} imgSrc プレビュー画像のパス
     */
    async getImg(imgSrc) {
      try {
        // 問題の画像取得
        this.image = await questionRepository.getQuestionImage(imgSrc)
        const suffix = this.questionList.tab.ext
        // タブ画像のパスを作成
        suffix.forEach(async (suffix, index) => {
          const convertedImgSrc = imgSrc.replace(/\.jpg/, `_${suffix}.jpg`)
          // タブごとの画像を取得する
          this.settingList[index].content =
            await questionRepository.getQuestionImage(convertedImgSrc)
        })
      } catch (error) {
        console.error(error)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.show-three-point {
  max-width: fit-content;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.task-name {
  width: 80% !important;
  margin: 0 auto !important;
  @media (max-width: 1020px) {
    width: 100% !important;
  }
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;

    .modal-container-outer {
      max-width: 1200px;
      width: 70vw;
      margin-left: auto;
      margin-right: auto;

      .modal-default-button {
        text-align: right;
      }

      .modal-container {
        margin-left: auto;
        margin-right: auto;
        padding: 20px 30px;
        background-color: #fff;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        text-align: center;

        .card-preview {
          height: calc(100% - 40px);

          .card-header {
            height: 5%;
            padding: 0.4rem;
            border-bottom: none;
            background: var(--bs-table-header);
            color: var(--bs-layout-theme);
            font-weight: bold;
            text-align: left;
          }

          ::v-deep li {
            padding: 0;
            list-style: none;
          }

          // タブ画像エリア
          ::v-deep .preview-display {
            height: 90%;
          }
        }
      }
    }
  }
}

// トランジション（アニメーション）設定
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: opacity 0.8s;
}
</style>
