// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/arrow_blue.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".button-file-select[data-v-ba2e5fe8] {\n  display: inline;\n}\n.button-file-select .btn-sm[data-v-ba2e5fe8] {\n  padding: 0rem 0.5rem;\n  border-radius: 2rem;\n}\n.explanation[data-v-ba2e5fe8] {\n  margin-top: 1em;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center/auto 100% no-repeat;\n  padding: 1.3em 0 1.3em 60%;\n}\n@media (max-width: 1280px) {\n.explanation[data-v-ba2e5fe8] {\n    background-size: 15%;\n}\n}\n.capacity-bar[data-v-ba2e5fe8] {\n  position: relative;\n  justify-content: space-between;\n  display: flex;\n  align-items: center;\n}", ""]);
// Exports
module.exports = exports;
