<template>
  <div class="link-text">
    <a
      :href="linkUrl"
      target="_blank"
    >{{ labelText }}</a>
  </div>
</template>

<script>
export default {
  name: "LinkText",
  props: {
    linkUrl: {
      type: String,
      default: function () {
        return ""
      },
    },
    labelText: {
      type: String,
      default: function () {
        return ""
      },
    },
  },
}
</script>
