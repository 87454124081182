var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-text-field" }, [
    _c(
      "label",
      {
        staticClass: "form-label ",
        class: { "required-label": _vm.isRequire },
        attrs: { for: _vm.id }
      },
      [_vm._v(_vm._s(_vm.labelText))]
    ),
    _c(
      "input",
      _vm._g(
        {
          ref: "input",
          staticClass: "form-control form-control-sm",
          attrs: {
            id: _vm.id,
            type: "text",
            "aria-describedby": _vm.help,
            disabled: _vm.disabled,
            placeholder: _vm.placeholder
          },
          domProps: { value: _vm.value }
        },
        {
          input: _vm.onInput,
          blur: _vm.onBlur,
          compositionstart: _vm.startComposition,
          compositionend: _vm.endComposition
        }
      )
    ),
    _c("div", { attrs: { id: _vm.help } }, [
      _vm._v(" " + _vm._s(_vm.helpMessage) + " ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }