<template>
  <div class="chat-area">
    <div class="chat-cotents-wrapper">
      <div class="col">
        <ul
          v-if="displayChatView"
          ref="chatView"
          class="container chat-view"
        >
          <li
            v-if="displayTeacherComment"
            class="teacher text-end"
          >
            <p>先生</p>
            <span>{{ teacherComment }}</span>
          </li>
          <li
            v-if="haishinStartDate"
            class="text-fixed"
          >
            {{ haishinStartDate }} 宿題を配信しました
          </li>
          <li
            v-if="comments[0] && comments[0].teacher_comment && comments[0].comment_kbn === 1"
            class="teacher text-end"
          >
            <p>先生</p>
            <span>{{ comments[0].teacher_comment }}</span>
          </li>
          <li
            v-if="comments[0] && comments[0].update_date && comments[0].comment_kbn === 1"
            class="text-fixed"
          >
            {{ convertValue.toDateTimeStringSeparator(comments[0].update_date) }} 宿題を通知しました
          </li>
          <li
            v-if="displayStudentComment"
            class="student text-start"
          >
            <p>生徒</p>
            <span>{{ studentComment }}</span>
          </li>
          <li
            v-if="submitDate"
            class="text-fixed"
          >
            {{ convertValue.toDateTimeStringSeparator(submitDate) }} 宿題が提出されました
          </li>
          <template v-for="(comment, index) in comments">
            <li
              v-if="comment.teacher_comment && comment.comment_kbn === 0"
              :key="`teacher-comment-${index}`"
              class="teacher text-end"
            >
              <p>先生</p>
              <span>{{ comment.teacher_comment }}</span>
            </li>
            <li
              v-if="comment.resubmit_instract_date && comment.comment_kbn === 0"
              :key="`resubmit-instract-date-${index}`"
              class="text-fixed"
            >
              {{ convertValue.toDateTimeStringSeparator(comment.resubmit_instract_date) }} 宿題のやり直しを指示しました
            </li>
            <li
              v-if="comment.student_comment"
              :key="`student-comment-${index}`"
              class="student text-start"
            >
              <p>生徒</p>
              <span>{{ comment.student_comment }}</span>
            </li>
            <li
              v-if="comment.resubmit_date"
              :key="`resubmit-date-${index}`"
              class="text-fixed"
            >
              {{ convertValue.toDateTimeStringSeparator(comment.resubmit_date) }} 宿題が提出されました
            </li>
          </template>
          <template
            v-if="displayReturnComment"
          >
            <li
              v-if="returnComment"
              class="teacher text-end"
            >
              <p>先生</p>
              <span>{{ returnComment }}</span>
            </li>
            <li
              v-if="returnDate"
              class="text-fixed"
            >
              {{ convertValue.toDateTimeStringSeparator(returnDate) }} 宿題を返却しました
            </li>
          </template>
        </ul>
        <div
          class="d-flex position-relative comment-wrapper"
          :class="{ 'chat-view-visible': displayChatView }"
        >
          <div
            class="evals"
            :class="[
              evalManualSetFlg === 1
                ? 'bg-eval-manual'
                : '',
            ]"
          >
            <div
              class="d-flex align-items-center justify-content-center"
              style="width: 100%; height: 100%"
              @click="toggleShowEvals"
            >
              <div
                v-if="evalCode === 0"
              >
                <label class="text-secondary">評価</label>
              </div>
              <div
                v-else
                style="font-size: 2.8em;"
              >
                <EvalStatus
                  :eval-code="evalCode"
                />
              </div>
            </div>

            <ul v-if="showEvals">
              <li
                v-for="idx in 6"
                :key="idx"
              >
                <div
                  class="d-flex justify-content-center align-items-center eval-item"
                  :class="[
                    evalCode === (idx < 6 ? idx : 0)
                      ? 'border-blue'
                      : '',
                      idx === 6 ? 'none-item' : ''
                  ]"
                >
                  <EvalStatus
                    :eval-code="(idx < 6 ? idx : 0)"
                    v-on="{
                      'on-change-eval': onChangeEval,
                    }"
                  />
                </div>
              </li>
            </ul>
          </div>
          <div class="stamps">
            <div
              v-if="stampCode === stamps.length"
              class="d-flex align-items-center justify-content-center"
              style="width: 100%; height: 100%"
              @click="toggleStamps"
            >
              <label class="text-secondary">スタンプ</label>
            </div>
            <div v-else>
              <img
                :src="stamp"
                @click="toggleStamps"
              >
            </div>

            <ul v-if="showStamps">
              <li
                v-for="(item, index) in displayStamps"
                :key="index"
              >
                <div
                  v-if="stampCode === item.code"
                  class="border-blue"
                >
                  <img
                    :src="stampList(item)"
                    @click="onChangeStamp(item)"
                  >
                </div>
                <div v-else>
                  <img
                    :src="stampList(item)"
                    @click="onChangeStamp(item)"
                  >
                </div>
              </li>
            </ul>
          </div>
          <p class="comment-area">
            <textarea
              v-model="message"
              :placeholder="$t('placeholder.returnComment')"
              aria-label="chat"
              class="form-control h-100"
              maxlength="150"
              @blur="onChangeMessage()"
            />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { handedStatus } from "@/constant/homework"
import convertValue from "@/repositories/convert-value"
import EvalStatus from "@/components/atoms/EvalStatus.vue"

export default {
  name: "ChatArea",
  components: {
    EvalStatus,
  },

  props: {
    stamps: {
      type: Array,
      default: function () {
        return []
      },
    },
    teacherComment: {
      type: String,
      default: function () {
        return ""
      },
    },
    haishinStartDate: {
      type: String,
      default: function () {
        return ""
      },
    },
    lastSubmitAddDate: {
      type: String,
      default: function () {
        return ""
      },
    },
    submitDate: {
      type: String,
      default: function () {
        return ""
      },
    },
    studentComment: {
      type: String,
      default: function () {
        return ""
      },
    },
    comments: {
      type: Array,
      default: function () {
        return[]
      },
    },
    status: {
      type: Number,
      default: function () {
        return 0
      },
    },
    returnComment: {
      type: String,
      default: function () {
        return ""
      },
    },
    selectedStamp: {
      type: String,
      default: function () {
        return ""
      },
    },
    initEvalCode: {
      type: Number,
      default: function () {
        return 0
      },
    },
    initEvalManualSetFlg: {
      type: Number,
      default: function () {
        return 0
      },
    },
    initStampCode: {
      type: Number,
      default: function () {
        return this.stamps.length
      },
    },
    initMessage: {
      type: String,
      default: function () {
        return ""
      },
    },
    studentIndex: {
      type: Number,
      default: function () {
        return 0
      },
    },
    returnDate: {
      type: String,
      default: function () {
        return ""
      },
    },
    submiting: {
      type: Boolean,
      default: function() {
        return false
      },
    },
    displayChatView: {
      type: Boolean,
      default: function() {
        return true
      }
    }
  },
  data: () => ({
    message: "",
    showEvals: false,
    evalCode: 0,
    evalManualSetFlg: 0,
    showStamps: false,
    stamp: "",
    stampCode: "",
    isSafari: false,
    initStatus: "",
    convertValue: convertValue,
  }),
  computed: {
    displayStudentComment() {
      if (!this.studentComment || this.studentComment === "undefined") {
        return false
      } else {
        return true
      }
    },
    displayTeacherComment() {
      if (!this.teacherComment || this.teacherComment === "undefined") {
        return false
      } else {
        return true
      }
    },
    displayReturnComment() {
      if (this.initStatus === handedStatus.returned || this.initStatus === handedStatus.returnChecked) {
        return true
      } else {
        return false
      }
    },
    displayEvals() {
      return true
    },
    displayStamps() {
      return this.stamps.filter(item => item.code !== 7 && item.code !== 9 && item.code !== this.stamps.length)
    }
  },
  watch: {
    studentIndex() {
      this.message = this.initMessage
      this.evalCode = this.initEvalCode
      this.evalManualSetFlg = this.initEvalManualSetFlg
      this.stampCode = this.initStampCode
      this.initStatus = this.status
      // コメント欄のスクロール位置を設定
      this.setCommentScrollPosition()
    },
    stampCode() {
      this.stamp = this.getStamp(this.stampCode)
    }
  },
  mounted() {
    //スタンプコードの初期値を返す
    this.message = this.initMessage
    this.evalCode = this.initEvalCode
    this.evalManualSetFlg = this.initEvalManualSetFlg
    this.stampCode = this.initStampCode
    this.initStatus = this.status

    let agent = window.navigator.userAgent.toLowerCase()
    this.isSafari = (agent.indexOf("chrome") == -1 && agent.indexOf("safari") != -1)

    if(this.isSafari){
      window.addEventListener('touchstart', this.closeEvalWindow);
      window.addEventListener('touchstart', this.closeStampWindow);
    } else {
      window.addEventListener('click', this.closeEvalWindow);
      window.addEventListener('click', this.closeStampWindow);
    }
    // コメント欄のスクロール位置を設定
    this.setCommentScrollPosition()
  },
  beforeDestroy() {
    if(this.isSafari){
      window.removeEventListener('touchstart', this.closeEvalWindow);
      window.removeEventListener('touchstart', this.closeStampWindow);
    } else {
      window.removeEventListener('click', this.closeEvalWindow);
      window.removeEventListener('click', this.closeStampWindow);
    }
  },
  methods: {
    // 評価変更時処理
    onChangeEval(code) {
      if (code === null) {
        return
      }

      this.evalCode = code
      this.evalManualSetFlg = 1
      this.showEvals = !this.showEvals
      this.$emit("on-change-eval", this.evalCode, this.evalManualSetFlg)
    },
    // スタンプ変更時処理
    onChangeStamp(item) {
      if (item.stamp === null) {
        return
      }

      this.showStamps = false
      if (this.stampCode === item.code) {
        this.stampCode = this.stamps.length
      } else {
        this.stampCode = item.code
      }
      this.$emit("on-change-stamp", this.stampCode)
    },
    // 返却ボタン押下時処理
    onChangeMessage() {
      this.$emit("on-change-message", this.evalCode, this.evalManualSetFlg, this.stampCode, this.message)
    },
    closeEvalWindow(event) {
      if (this.showEvals
        && !this.$el.querySelector('.evals').contains(event.target)) {
        this.showEvals = false
      }
    },
    stampList(item) {
      return item.stamp
    },
    closeStampWindow(event) {
      if(this.showStamps
        && !this.$el.querySelector('.stamps').contains(event.target)){
          this.showStamps = false;
        }
    },
    // チャット欄のスクロール位置を設定
    setCommentScrollPosition() {
      this.$nextTick(() => {
        // 初期表示でチャット欄のスクロール位置を最終コメントの一番上とする
        // ただし、コメントがない場合は固定コメントを対象とする
        const comments = this.$el.querySelectorAll(".container.chat-view>li");
        let lastComment = null;
        for(let i = comments.length - 1; i >= 0; i--){
            const comment = comments[i];
            if(comment.classList.contains("text-fixed") && lastComment){
              break;
            }
            lastComment = comment;
        }
        if(lastComment && lastComment.parentNode){
          lastComment.parentNode.scrollTop = lastComment.offsetTop
        }
      });
    },
    // スタンプ画像の取得 
    getStamp(stampCode) {
      if (this.stamps) {
        const stamp = this.stamps.find((item)=>item.code === stampCode)
        if (stamp) {
          return stamp.stamp
        } else {
          return ""
        }
      } else {
        return ""
      }
    },
    /**
     * 評価吹き出し切り替え
     */
    toggleShowEvals(event) {
      event.stopPropagation()
      this.showEvals = !this.showEvals
      if (this.showEvals) {
        this.showStamps = false
      }
    },
    /**
     * スタンプ吹き出し切り替え
     */
    toggleStamps(event) {
      event.stopPropagation()
      this.showStamps = !this.showStamps
      if (this.showStamps) {
        this.showEvals = false
      }
    }
  },
}
</script>

<style lang="scss" scoped>
ul {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
  }
}
p {
  margin: 0;
}
.chat-cotents-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  background-color: #fff;
}
.chat-view {
  width: initial;
  height: 160px;
  overflow-y: scroll;
  background: #fdf1e9;
  list-style: none;
  margin: 0;
  padding: 1em 2em 1rem 2em;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom-style: none;
  border-radius: 0.25rem 0.25rem 0 0;
  @media (max-width: 768px) {
    height: 180px;
  }
  @media (min-height: 1080px) {
    height: 176px;
  }
  li {
    //コメントエリア
    span {
      position: relative;
      display: inline-block;
      text-align: left;
      padding: 0.5em;
      background-color: #fff;
      border-radius: 10px;
      border: 1px solid #b3b3b3;
      white-space: pre-wrap;

      &::before {
        content: "";
        position: absolute;
        display: block;
        top: 8px;
        right: -18px;
        width: 18px;
        height: 18px;
        background: url(../../assets/images/fukidasitip.svg) no-repeat;
        background-size: contain;
        // ↓この指定だと、iOSの古いSafariで正しく反転されなくなる
        //transform: scale(-100%, 1);
        transform: scale(-1, 1);
      }
    }
  }
  .student {
    span {
      margin-right: 0;
      &::before {
        transform: scale(1);
        right: auto;
        left: -18px;
        transform: scale(100%, 1);
      }
    }
  }
  .text-fixed {
    font-size: 14px;
    color: #444;
    padding: 4px;
  }
}
//評価表示
.evals {
  position: relative;
  //width: 35%;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0 0 0 0.25rem;
  ul {
    display: flex;
    justify-content: start;
    align-items: center;
    position: absolute;
    bottom: calc(100% + 15px);
    left: 6px;
    width: 380px;
    padding: 10px 4px;
    flex-wrap: wrap;
    background: #fff;
    border: 1px solid #ff7f27;
    border-radius: 5px;
    column-count: 1;
    &::before {
      content: "";
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-top: 16px solid #ff7f27;
      position: absolute;
      bottom: -16px;
      left: calc(50px - 6px - 16px);
    }
    &::after {
      content: "";
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-top: 16px solid #fff;
      position: absolute;
      bottom: -15px;
      left: calc(50px - 6px - 16px);
    }
    li {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .eval-item {
        width: 55px;
        height: 55px;
        font-size: xx-large;
        &.none-item {
          font-size: x-large;
        }
      }
    }
  }
}
//スタンプ表示
.stamps {
  position: relative;
  //width: 35%;
  width: 100px;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  justify-content: center;
  align-items: center;
  ul {
    display: flex;
    position: absolute;
    bottom: 120px;
    left: 6px;
    width: 340px;
    padding: 2%;
    flex-wrap: wrap;
    background: #fff;
    border: 1px solid #ff7f27;
    border-radius: 5px;
    &::before {
      content: "";
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-top: 16px solid #ff7f27;
      position: absolute;
      bottom: -16px;
      left: calc(50px - 6px - 16px);
    }
    &::after {
      content: "";
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-top: 16px solid #fff;
      position: absolute;
      bottom: -15px;
      left: calc(50px - 6px - 16px);
    }
    li {
      width: 20%;
      cursor: pointer;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    cursor: pointer;
    padding: 5px;
  }
}

.comment-area {
  flex-grow: 1;
}

.comment-wrapper {
  height: 106px;
  &.chat-view-visible {
    @media (max-width: 768px) {
      height: 30%;
    }
    @media (min-height: 1080px) {
      height: 30%;
    }
  }
}
// コメント入力エリア
.form-control {
  resize: none;
  border-radius: 1px;
  width: 100%;
}
img {
  -webkit-user-drag: none;
  user-select: none;
}
.border-blue {
  border-color: rgb(93, 93, 255);
  border-width: 3px;
  border-style: solid;
}
.bg-layout-theme-light {
  background: var(--bs-layout-theme-light);
}
.bg-eval-manual {
  background: #ffffcc;
}

</style>
