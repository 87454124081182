/**
 * コード値・名称変換情報 store
 */
import convertValue from '@/repositories/convert-value'

export const nameConversions = {
  namespaced: true,
  state: {
    /**
     * ヘッダー情報
     */
    headerInfo: {
      // json ファイル名
      fileName: '',
      // json ファイル URL クエリストリングのパラメータ情報
      urlParameters: {
        expires: '',
        signature: '',
        keyPairId: '',
      },
    },

    /**
     * 設定情報
     */

    // 宿題管理
    homework: {
      // 教科
      curriculums: {
        items: [],
        keyItems: [],
        shortName: [],
      },
      // 科目
      subjects: [],
      // 宿題種別
      homeworkTypeItems: [],
      // 配信ステータス
      streamStatusItems: [],
      // 宿題ステータス
      homeworkStatusItems: [],
      // 採点スタンプ
      gradeStampItems: [],
    },

    // 学習の記録
    learningRecord: {
      // 結果区分
      resultClassItems: [],
      // プロンプト区分
      promptClassItems: [],
      // 子問題スタンプ
      childQuestionStampItems: [],
    },

    // アカウント情報
    accountInfo: {
      // 教科変換テーブル
      curriculumConversionTable: [],
    },
  },

  getters: {
    // ヘッダー情報
    headerInfo: (state) => {
      return state.headerInfo
    },

    // 宿題管理
    curriculums: (state) => {
      return state.homework.curriculums
    },
    subjects: (state) => {
      return state.homework.subjects
    },
    homeworkTypeItems: (state) => {
      return state.homework.homeworkTypeItems
    },
    streamStatusItems: (state) => {
      return state.homework.streamStatusItems
    },
    homeworkStatusItems: (state) => {
      return state.homework.homeworkStatusItems
    },
    gradeStampItems: (state) => {
      return state.homework.gradeStampItems
    },

    // 学習の記録
    resultClassItems: (state) => {
      return state.learningRecord.resultClassItems
    },
    promptClassItems: (state) => {
      return state.learningRecord.promptClassItems
    },
    childQuestionStampItems: (state) => {
      return state.learningRecord.childQuestionStampItems
    },

    // アカウント情報
    curriculumConversionTable: (state) => {
      return state.accountInfo.curriculumConversionTable
    },
  },

  mutations: {
    // ヘッダー情報
    // actions のみから使用する（単独使用禁止）
    setHeaderInfo(state, payload) {
      state.headerInfo.fileName = payload.headerInfo.fileName
      state.headerInfo.urlParameters.expires = payload.headerInfo.urlParameters.expires
      state.headerInfo.urlParameters.signature = payload.headerInfo.urlParameters.signature
      state.headerInfo.urlParameters.keyPairId = payload.headerInfo.urlParameters.keyPairId
    },

    // 初期化
    // actions のみから使用する（単独使用禁止）
    clearHeaderInfo(state) {
      state.headerInfo = {}
    },

    // 宿題管理
    // 変更
    setCurriculums(state, payload) {
      state.homework.curriculums = payload.curriculums
    },
    setSubjects(state, payload) {
      state.homework.subjects = payload.subjects
    },
    setHomeworkTypeItems(state, payload) {
      state.homework.homeworkTypeItems = payload.homeworkTypeItems
    },
    setStreamStatusItems(state, payload) {
      state.homework.streamStatusItems = payload.streamStatusItems
    },
    setHomeworkStatusItems(state, payload) {
      state.homework.homeworkStatusItems = payload.homeworkStatusItems
    },
    setGradeStampItems(state, payload) {
      state.homework.gradeStampItems = payload.gradeStampItems
    },

    // 初期化
    clearCurriculums(state) {
      state.homework.curriculums = {}
    },
    clearSubjects(state) {
      state.homework.subjects.splice(0)
    },
    clearHomeworkTypeItems(state) {
      state.homework.homeworkTypeItems.splice(0)
    },
    clearStreamStatusItems(state) {
      state.homework.streamStatusItems.splice(0)
    },
    clearHomeworkStatusItems(state) {
      state.homework.homeworkStatusItems.splice(0)
    },
    clearGradeStampItems(state) {
      state.homework.gradeStampItems.splice(0)
    },

    // 学習の記録
    // 変更
    setResultClassItems(state, payload) {
      state.learningRecord.resultClassItems = payload.resultClassItems
    },
    setPromptClassItems(state, payload) {
      state.learningRecord.promptClassItems = payload.promptClassItems
    },
    setChildQuestionStampItems(state, payload) {
      state.learningRecord.childQuestionStampItems = payload.childQuestionStampItems
    },

    // 初期化
    clearResultClassItems(state) {
      state.homework.resultClassItems.splice(0)
    },
    clearPromptClassItems(state) {
      state.homework.promptClassItems.splice(0)
    },
    clearChildQuestionStampItems(state) {
      state.homework.childQuestionStampItems.splice(0)
    },

    // アカウント情報
    // 変更
    setCurriculumConversionTable(state, payload) {
      state.accountInfo.curriculumConversionTable = payload.curriculumConversionTable
    },
    // 初期化
    clearCurriculumConversionTable(state) {
      state.accountInfo.curriculumConversionTable.splice(0)
    },
  },

  actions: {
    /**
     * 設定値 json を配列に変換して、store に登録する
     * @param {Object} {} Vuex インスタンス 
     * @param {Object} configJson 設定値 json
     */
    saveConfigSettings({ commit }, configJson) {
      // ヘッダー情報
      commit('setHeaderInfo', { headerInfo: configJson.nameConversions.headerInfo })

      // 宿題管理
      // 教科
      let curriculumItems = convertValue.objectToArray(configJson.nameConversions.Homework.Curriculum.Curriculum, 'code', 'name')
      let curriculumKeyItems = convertValue.objectToArray(configJson.nameConversions.Homework.Curriculum.CurriculumKey, 'code', 'name')
      let curriculumShortNameItems = convertValue.objectToArray(configJson.nameConversions.Homework.Curriculum.CurriculumShortName, 'code', 'name')
      commit('setCurriculums', {
        curriculums: {
          items: curriculumItems,
          keyItems: curriculumKeyItems,
          shortName: curriculumShortNameItems,
        }
      })
      // 科目
      const subjects = convertValue.objectToArray(configJson.nameConversions.Homework.Subject, 'curriculumKey', 'subjectItems')
      const subjectArray = subjects.map(x => {
        return {
          curriculumKey: x.curriculumKey,
          subjectItems: convertValue.objectToArray(x.subjectItems, 'code', 'name')
        }
      })
      commit('setSubjects', { subjects: subjectArray })
      // 宿題種別
      let homeworkTypeItems = convertValue.objectToArray(configJson.nameConversions.Homework.HomeworkKind.HomeworkKind, 'code', 'name')
      commit('setHomeworkTypeItems', { homeworkTypeItems: homeworkTypeItems })
      // 配信ステータス
      let streamStatusItems = convertValue.objectToArray(configJson.nameConversions.Homework.HaishinStatus.HaishinStatus, 'code', 'name')
      commit('setStreamStatusItems', { streamStatusItems: streamStatusItems })
      // 宿題ステータス
      let homeworkStatusItems = convertValue.objectToArray(configJson.nameConversions.Homework.HomeworkStatus.Status, 'code', 'name')
      commit('setHomeworkStatusItems', { homeworkStatusItems: homeworkStatusItems })
      // 採点スタンプ
      let gradeStampItems = convertValue.objectToArray(configJson.nameConversions.Homework.ScoringStamp.ScoringStamp, 'code', 'name')
      commit('setGradeStampItems', { gradeStampItems: gradeStampItems })

      // 学習の記録
      // 結果区分
      let resultClassItems = convertValue.objectToArray(configJson.nameConversions.LearningRecord.ResultKind.ResultKind, 'code', 'name')
      commit('setResultClassItems', { resultClassItems: resultClassItems })
      // プロンプト区分
      let promptClassItems = convertValue.objectToArray(configJson.nameConversions.LearningRecord.PromptKind.PromptKind, 'code', 'name')
      commit('setPromptClassItems', { promptClassItems: promptClassItems })
      // 子問題スタンプ
      let childQuestionStampItems = convertValue.objectToArray(configJson.nameConversions.LearningRecord.QuestionChildStamp.QuestionChildStamp, 'code', 'name')
      commit('setChildQuestionStampItems', { childQuestionStampItems: childQuestionStampItems })

      // 教科変換テーブル
      const curriculumConversionTable = convertValue.objectToArray(configJson.nameConversions.AccountInfo.Curriculum.LinkCurriculum, 'code', 'value')
      commit('setCurriculumConversionTable', { curriculumConversionTable: curriculumConversionTable })
    },
  },
}