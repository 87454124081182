var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "data-capacity-content" }, [
    _c("div", [
      _c("ul", [
        _c("li"),
        _c("li", { style: { width: +_vm.capacityRatio + "%" } })
      ]),
      _c("p", [
        _vm._v(
          " " +
            _vm._s(_vm.$t("labels.lmsMaxByte", { maxByte: _vm.maxByte })) +
            " "
        ),
        _c("span", [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("labels.lmsNowByte", {
                  nowByte: _vm.nowByte,
                  stdbByte: _vm.stdbByte,
                  pdfByte: _vm.pdfByte
                })
              ) +
              " "
          )
        ]),
        _vm._v(" " + _vm._s(_vm.$t("labels.lmsByteUse")) + " ")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }