/**
 * 宿題管理用採点スタンプ画像 store
 */
export const gradeStamp = {
  namespaced: true,
  state: {
    stamps: [],
  },

  getters: {
    stamps: (state) => {
      return state.stamps
    },
  },

  mutations: {
    addStamps(state, payload) {
      payload.stamps.forEach(stamp => {
        state.stamps.push(stamp)
      })
    },
    clearStamps(state) {
      state.stamps.splice(0)
    }
  },

  actions: {
    async saveGradeStamps({ commit }, stamps) {
      await commit('clearStamps')
      await commit('addStamps', { stamps: stamps })
    }
  },
}