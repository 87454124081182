<!-- コメント制御用タイトル -->
<template>
  <div class="title-comment-control">
    <ul class="list-group list-group-horizontal title-area">
      <li class="list-group-item border-0 pt-0 ps-0 pe-2 pb-0">
        <IconComment
          width="30px"
          height="30px"
          fill-color="#323333"
        />
      </li>
      <li class="list-group-item border-0 px-0 pb-0 title-text">
        <h5>{{ $t("titles.commentControl") }}</h5>
      </li>
      <li class="list-group-item border-0 ps-3 pe-1 pb-0 title-help">
        <ButtonOpenHelp :url="helpLink" />
      </li>
    </ul>
  </div>
</template>

<script>
import IconComment from "@/components/atoms/icons/IconComment.vue"
import ButtonOpenHelp from "@/components/atoms/buttons/ButtonOpenHelp.vue"

export default {
  name: "TitleCommentControl",
  components: {
    IconComment,
    ButtonOpenHelp,
  },
  props: {
    // ヘルプページへのリンク
    helpLink: {
      type: String,
      default: function () {
        return ""
      },
    },
  },
  data: () => ({
    colorLayoutTheme: "#ff7f27",
    showSubMenu: false,
    contentsArea: undefined,
  }),
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.title-comment-control {
  position: relative;
  display: flex;
  font-size: 10px;
  height: 2.8rem;
  align-items: center;

  .list-group {
    height: 2.8rem;
    align-items: center;

    li {
      padding: 0;
    }
  }

  .title-area {
    flex-grow: 1;
    .title-text {
      h5 {
        margin: 0;
      }
    }
  }
}
</style>
