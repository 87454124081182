var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "icon-help" }, [
    _c(
      "svg",
      {
        staticStyle: { "enable-background": "new 0 0 70.6 70.6" },
        attrs: {
          id: "_x32_",
          version: "1.1",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          x: "0px",
          y: "0px",
          width: _vm.width,
          height: _vm.height,
          viewBox: "0 0 70.6 70.6",
          "xml:space": "preserve"
        }
      },
      [
        _c("rect", {
          staticClass: "st0",
          attrs: { y: "0", width: "70.6", height: "70.6" }
        }),
        _c("g", [
          _c("path", {
            staticClass: "st1",
            attrs: {
              d:
                "M70.6,55.4V5.1h-61v50.3L70.6,55.4L70.6,55.4z M65.8,50.6H14.4V9.9h51.5L65.8,50.6L65.8,50.6z"
            }
          }),
          _c("polygon", {
            staticClass: "st1",
            attrs: {
              points:
                "56.2,60.1 4.8,60.1 4.8,19.5 4.8,14.7 0,14.7 0,64.9 61,64.9 61,60.1 \t"
            }
          })
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }