<template>
  <div class="data-capacity-content">
    <div>
      <ul>
        <li />
        <li
          :style="{ 'width': + capacityRatio + '%' }"
        />
      </ul>
      <p>
        {{ $t('labels.lmsMaxByte',{
          maxByte : maxByte
        }) }}
        <span>
          {{ $t('labels.lmsNowByte', {
            nowByte : nowByte,
            stdbByte : stdbByte,
            pdfByte : pdfByte,
          }) }}
        </span>
        {{ $t('labels.lmsByteUse') }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataCapacityContent",
  props: {
    // 最大容量
    maxByte: {
      type: Number,
      default: function () {
        return 0
      },
    },
    // 現在容量
    nowByte: {
      type: Number,
      default: function () {
        return 0
      },
    },
    // STDB容量
    stdbByte: {
      type: Number,
      default: function () {
        return 0
      },
    },
    // PDF容量
    pdfByte: {
      type: Number,
      default: function () {
        return 0
      },
    },
  },
  data: () => ({
    capacityRatio : "0"
  }),

  updated: function () {
    // 比率計算
    this.capacityRatio = (this.nowByte !== 0 && this.maxByte !== 0)
        ? String(((this.nowByte*100) / (this.maxByte*100))*100)
        : "0"
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>

ul {
    position: relative;
    width: 100%;
    height: 2px;
    list-style: none;
    padding: 0;
    li {
        position: absolute;
        width: 100%;
        height: 2px;
        background: #e1e1e1;  // 空き容量バー
    }
    li:nth-of-type(2) {
        background: #5b9bd5;  // 使用量バー
    }
}
div p {
    font-size: font(s);
    margin-top: .5em;
    color: #b3b3b3; // ～を使用中
    span {
        color: #5b9bd5; // 使用容量文字
    }
}

</style>



