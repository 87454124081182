var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stamps", class: { "bulk-view": _vm.bulkView } },
    [
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center justify-content-center",
          staticStyle: { width: "100%", height: "100%", cursor: "pointer" },
          on: { click: _vm.toggleStamps }
        },
        [
          _vm.stampCode === _vm.stamps.length
            ? _c("div", [
                _c(
                  "label",
                  {
                    staticClass: "text-secondary",
                    staticStyle: { cursor: "pointer" }
                  },
                  [_vm._v("スタンプ")]
                )
              ])
            : _c(
                "div",
                {
                  staticClass: "selected-stamp",
                  class: { "bulk-view": _vm.bulkView }
                },
                [_c("img", { attrs: { src: _vm.stamp } })]
              )
        ]
      ),
      _vm.showStamps
        ? _c(
            "ul",
            { class: { "bulk-view": _vm.bulkView } },
            _vm._l(_vm.displayStamps, function(item, index) {
              return _c("li", { key: index }, [
                _vm.stampCode === item.code
                  ? _c(
                      "div",
                      {
                        staticClass: "border-blue",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function($event) {
                            return _vm.onChangeStamp(item)
                          }
                        }
                      },
                      [_c("img", { attrs: { src: _vm.stampList(item) } })]
                    )
                  : _c(
                      "div",
                      {
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function($event) {
                            return _vm.onChangeStamp(item)
                          }
                        }
                      },
                      [_c("img", { attrs: { src: _vm.stampList(item) } })]
                    )
              ])
            }),
            0
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }