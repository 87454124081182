<template>
  <span class="s-viewer-logo">
    <img
      id="sViewerLogo"
      src="../../assets/logo.png"
      alt="sViewerLogo"
    >
  </span>
</template>

<script>
export default {
  name: "SViewerLogo",
}
</script>

<style scoped>
img {
	-webkit-user-drag: none;
	user-select: none;
}
</style>