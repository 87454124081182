<template>
  <div class="table-homework-stream-data-list">
    <div class="row">
      <div class="col-8">
        <label
          class="form-label mt-3 mb-1"
          :class="{ 'required-label': isRequire }"
        >{{ $t("labels.streamData") }}</label>
      </div>
      <div
        v-if="limit === 0"
        class="col-4 text-end"
      >
        <label class="form-label mt-3 mb-1 mx-3"> {{ streamDataList.length }}件 </label>
      </div>
      <div
        v-else
        class="col-4 text-end"
      >
        <label class="form-label mt-3 mb-1 mx-3"> {{ streamDataList.length }}/{{ limit }}件 </label>
      </div>
    </div>
    <!--
      行数は最大表示数 + ヘッダ１行分とする。
      １行の高さはCSS部分で設定しているものに対して少し余裕を持たせたものとしている。
      ※丁度に設定すると何故か最大表示数と要素数が一致した時でもスクロールバーが活性化されてしまう為。
    -->
    <div
      class="table-wrap"
      :style="{ 'max-height': `${42 * (displayCount + 1)}px` }"
    >
      <table>
        <thead>
          <tr class="table-header">
            <th
              v-for="(header, index) in headers"
              :key="index"
            >
              <div>{{ header }}</div>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(item, index) in dataList"
            :key="index"
            class="text-center px-1"
          >
            <template v-if="item">
              <td class="text-start px-1">
                <div class="row">
                  <div class="col-12">
                    <div class="mx-4 show-three-point">
                      {{ item.taskName }}
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <ButtonPreview @click.native="onClickPreview(item)" />
              </td>
              <td>
                <ButtonDelete
                  :disabled="isPublished"
                  @click.native="onClickDelete(item, index)"
                />
              </td>
            </template>
            <template v-else>
              <!-- colspanの値はitemがnullでない場合の表示数と合わせる -->
              <td :colspan="3" />
            </template>
          </tr>
        </tbody>
      </table>
    </div>
    
    <ModalPreviewPdfQuestion
      v-if="showPreviewPdfQuestion"
      :task-name="taskName"
      :send-memo="sendMemo"
      :image-list="imageList"
      :default-question-count="questionQuantity"
      :is-disabled="true"
      @close="showPreviewPdfQuestion = false"
    />
    
    <ModalPreviewStdbQuestion
      v-if="showPreviewStdbQuestion"
      :task-name="taskName"
      :send-memo="sendMemo"
      :prm-question-type="itemQuestionType"
      :prm-question-quantity="questionQuantity"
      :image-list="imageList"
      :ans-disp-flg="ansDispFlg"
      :explain-disp-flg="explainDispFlg"
      :is-disabled="true"
      @close="showPreviewStdbQuestion = false"
    />

    <ModalConfirm
      v-if="showConfirmDelete"
      :message="
        $t('messages.confirm.deleteHomeworkReplaceWords', {
          homeworkName: deleteHomeworkName
        })
      "
      v-on="{
        'ok-confirm-modal': okConfirmModal,
        'close-confirm-modal': closeConfirmModal,
      }"
    />
    
    <ModalConfirmOkOnly
      v-if="showConfirmCaution"
      :message="cautionMessage"
      @close-confirm-modal-ok-only="showConfirmCaution = false"
    />
    <LoadingScreen v-if="isLoading" />
  </div>
</template>

<script>
import { db } from "@/dbs/indexedDb"
import mixin from "@/mixins/mixin"
import { mapGetters } from "vuex"
import ButtonPreview from "@/components/atoms/buttons/ButtonPreview.vue"
import ButtonDelete from "@/components/atoms/buttons/ButtonDelete.vue"
import ModalPreviewPdfQuestion from "@/components/organisms/modal/ModalPreviewPdfQuestion.vue"
import ModalPreviewStdbQuestion from "@/components/organisms/modal/ModalPreviewStdbQuestion.vue"
import ModalConfirm from "@/components/organisms/modal/ModalConfirm.vue"
import ModalConfirmOkOnly from "@/components/organisms/modal/ModalConfirmOkOnly.vue"
import { homeworkTypeCode } from "@/constant/homework"
import homeworkApi from "@/api/homework"
import LoadingScreen from "@/components/atoms/LoadingScreen.vue"
import axios from 'axios'

export default {
  name: "TableHomeworkUploadStreamDataList",
  components: {
    ButtonPreview,
    ButtonDelete,
    ModalPreviewPdfQuestion,
    ModalPreviewStdbQuestion,
    ModalConfirm,
    ModalConfirmOkOnly,
    LoadingScreen,
  },
  mixins: [mixin],
  props: {
    questionType: {
      type: Number,
      require: true,
      default: function () {
        return 0
      },
    },
    streamDataList: {
      type: Array,
      default: function () {
        return []
      },
    },
    displayCount: {
      type: Number,
      default: function () {
        return 0
      },
    },
    isRequire: {
      type: Boolean,
      default: function () {
        return false
      },
    },
    limit: {
      type: Number,
      default: function () {
        return 0
      },
    },
    isPublished: { type: Boolean },
  },

  data: () => ({
    headers: [
      "課題名",
      "プレビュー",
      "削除",
    ],
    showConfirmCaution: false,      // 警告確認
    cautionMessage: "",             // 警告メッセージ
    showPreviewPdfQuestion: false,
    showPreviewStdbQuestion: false,
    taskName: "",
    showConfirmDelete: false,
    deleteItem: {},
    deleteItemIndex: -1,
    deleteHomeworkName: "",
    sendMemo: "",
    imageList: [],
    itemQuestionType: 0,
    questionQuantity: 0,
    ansDispFlg: false,
    explainDispFlg: false,
    isLoading: false,
  }),
  computed: {
    ...mapGetters("homework", ["headerInfo", "homeworkDetailItems"]),
    dataList: {
      get: function () {
        const length = Math.max(this.streamDataList.length, this.displayCount)
        const list = []
        for (let i = 0; i < length; i++) {
          const item =
            i < this.streamDataList.length
              ? JSON.parse(JSON.stringify(this.streamDataList[i]))
              : null
          if (item) {
            this.$set(item, "index", i)
          }
          list.push(item)
        }
        return list
      },
      set: function (value) {
        this.$emit("changed-stream-data-list", value)
      },
    },
  },
  
  methods: {
    onClickPreview: async function (item) {
      this.isLoading = true

      this.taskName = item.taskName               // 課題名
      this.sendMemo = item.sendMemo               // 送信メモ
      this.itemQuestionType = item.questionType   // 問題種別
      if(0 === item.haishinFileNo){
        // 0：新規追加の場合はIndexedDBから取得
        this.imageList = (await db.idbQuestionImage.get(item.subQuestionInfo.filePath)).uploadFileList
      } else {
        // 0以外：APIを実行し取得
        this.imageList = await this.getApiImageList(item.haishinFileNo)

        // 画像情報が読み込めなかった場合配信終了エラー表示
        if(this.imageList === null){
          // メッセージ出力
          this.showModalOkOnly(
            this.$t("messages.error.uploadPublicEndPreview")
          )
          this.isLoading = false
          return
        }
      }
      
      // STDB一問ずつの場合、一問だけ抽出
      if(item.questionType === homeworkTypeCode.stdb){
        const index = ('000' + (item.fileNmSeq)).slice(-3) + ".png"
        let images = []
        for(var i = 0; i < this.imageList.length; i++){
          let image = this.imageList[i]
          
          if(image.file_name.match(new RegExp("^Q" + index))
            || image.file_name.match(new RegExp("^A" + index))
            || image.file_name.match(new RegExp("^E" + index))){
            images.push(image)
          }
        }
        this.imageList = images
      }

      // 昇順ソート
      this.imageList.sort((a, b) => a.file_name < b.file_name ? -1 : 1)

      this.isLoading = false
      this.questionQuantity = item.questionCount // 問題数
      switch (item.questionType) {
        // PDF
        case homeworkTypeCode.pdf:
          this.showPreviewPdfQuestion = true
          break

        // STDBレイアウト通り
        case homeworkTypeCode.stdbLayout:
          this.showPreviewStdbQuestion = true
          break

        // STDB一問ずつ
        case homeworkTypeCode.stdb:
          this.ansDispFlg = item.ansDispFlg          // 答え表示フラグ
          this.explainDispFlg = item.explainDispFlg  // 解説表示フラグ
          this.showPreviewStdbQuestion = true
          break
      }
    },

    /**
     * 画像リストを取得
     */
    getApiImageList: async function(haishinFileNo) {

      let streamFileInfo = await this.getHomeworkStreamFileInfo(haishinFileNo)
      if(streamFileInfo.status !== 200){
        // ファイル保持期限切れ
        return null
      }
      let fileList = streamFileInfo.data.data.upload_file_list
      if(fileList.length == 0){
        // ファイル削除済
        return null
      }
      let fileImgList = []
      for(let i = 0; i < fileList.length; i++){
        let item = fileList[i]
        let url = item.file_path
        let base64 = await this.downloadImageToBase64(url)

        fileImgList.push({
          file_image: base64,
          file_name: item.file_name
        })
      }
      return fileImgList
    },
    
    /**
     * アップロード一覧取得
     */
    getHomeworkStreamFileInfo: async function(haishinFileNo) {
      return await homeworkApi.getHomeworkStreamFileInfo(
        this.loginUserInfo.accountId,         // アカウントID
        this.loginUserInfo.schoolId,          // 学校ID
        0,                                    // モード(0:アップロード一覧、1:配信一覧)
        0,                                    // 宿題キー
        haishinFileNo,                        // 配信ファイル連番
        this.loginUserInfo.lmsApiToken,       // APIトークン
      )
    },

    /**
     * 画像ファイルダウンロード
     */
    downloadImageToBase64: async function (externalUrl){
      let retBase64 = null
      try{
        const response = await axios.get(externalUrl, {
          responseType: 'arraybuffer'
        })
        // arrayBuffer -> blob
        const blob = new Blob([response.data], { type: "image/png" })

        // blob -> base64
        await new Promise((res) => {
          let reader = new FileReader()
          reader.onload = () => {
            res(reader.result)
          }
          reader.readAsDataURL(blob)
        }).then((ret) => {
          retBase64 = ret
        })
      } catch (ex) {
        // 空情報を返す（配信期限切れ）
      }

      return retBase64
    },

    /**
     * 削除ボタン押下時
     */
    onClickDelete: function (item, index) {
      this.deleteItem = item
      this.deleteItemIndex = index
      this.deleteHomeworkName = item.taskName

      if (this.isPublished) {
        return
      } // 配信中もしくは配信終了しているものは削除不可

      // 削除処理を実行する
      this.showConfirmDelete = true
    },

    /**
     * 削除確認モーダルOK処理
     */
    okConfirmModal: function () {
      // 親コンポーネントの宿題削除処理を実行
      this.$emit("delete", this.deleteItem)
      this.showConfirmDelete = false
    },

    /**
     * 削除確認モーダルを閉じる。
     */
    closeConfirmModal: function () {
      this.showConfirmDelete = false
    },

    /**
     * OKだけのモーダルを表示する
     */
    showModalOkOnly: function (message) {
      this.cautionMessage = message
      this.showConfirmCaution = true
    },
  },
}
</script>

<style lang="scss" scoped>
.caution {
  cursor: pointer;
}

.table-wrap {
  overflow-y: scroll;

  table {
    table-layout: fixed;
    width: 100%;
    border: solid 1px #b3b3b3;

    thead {
      position: sticky;
      top: 0;
      // 暫定対応なので別の箇所でz-indexを設定する時のレスポンス対応時は考慮が必要
      z-index: 1;
      tr {
        height: 40px;
        background: var(--bs-table-header);
        color: var(--bs-layout-theme);
        // 課題名
        th:nth-child(1) {
          text-align: center;
        }
        // プレビュー
        th:nth-child(2) {
          text-align: center;
          width: 10%;
          @media (max-width: 991px) {
            width: 12%;
            text-align: center;
          }
        }
        // 削除
        th:nth-child(3) {
          text-align: center;
          width: 10%;
          @media (max-width: 991px) {
            width: 12%;
            text-align: center;
          }
        }
      }
    }

    tbody {
      tr {
        height: 40px;
        // プレビュー
        td:nth-child(2) {
          width: 10%;
          // アイコンクリック範囲設定
          ::v-deep .button-preview {
            display: contents;
            width: 25px;
            height: 25px;
          }
        }
        // 削除
        td:nth-child(3) {
          width: 10%;
          // アイコンクリック範囲設定
          ::v-deep .button-delete {
            display: contents;
            width: 25px;
            height: 25px;
          }
        }
      }
      tr:nth-child(odd) {
        background: var(--bs-table-row);
      }
    }
  }
}
.table-header {
  font-size: 1rem;
  @media (max-width: 991px) {
    font-size: 0.8rem;
  }
}
.required-label:before {
  content: "*";
  color: red;
}
.show-three-point {
  max-width: fit-content;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
