<template>
  <div
    class="table-header-label"
    @click="onClick"
    :style="{
        cursor: sortKey ? 'pointer' : 'default',
      }"
  >
    <div v-text="labelText" />
    <div
      v-if="sortKey && isSelected"
      class="sort-mark"
      :style="{
        'font-size': `${sortMarkSizeRate * 100}%`,
      }"
      v-text="sortOrder === 1 ? '▲' : '▼'"
    />
  </div>
</template>

<script>
export default {
  name: "TableHeaderLabel",
  props: {
    labelText: {
      type: String,
      default: function () {
        return ''
      },
    },
    sortKey: {
      type: String,
      default: function () {
        return ''
      },
    },
    isSelected: Boolean,
    initSortOrder: {
      type: Number,
      default: function () {
        return -1
      },
    },
    sortMarkSizeRate: {
      type: Number,
      default: function() {
        return 1;
      },
    },
  },
  data: function () {
    return {
      // 1:昇順、-1:降順
      sortOrder: this.initSortOrder,
    }
  },
  methods: {
    onClick: function () {
      if (!this.sortKey) {
        return
      }

      if (this.isSelected) {
        this.sortOrder *= -1
      } else {
        this.sortOrder = 1
      }
      this.$emit("on-selected", this.sortKey, this.sortOrder)
    },
  },
}
</script>

<style lang="scss" scoped>
.table-header-label {

  cursor: default;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
