<template>
  <span class="icon-circle">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :width="width"
      :height="height"
      :fill="fill"
      class="bi bi-caret-right-fill"
      viewBox="0 0 16 16"
    >
      <path
        d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"
      />
    </svg>
  </span>
</template>

<script>
/**
 * Caret right （右向き三角）アイコン
 * Bootstrap アイコンを使用
 */
export default {
  name: "IconCaretRight",
  props: {
    width: {
      type: String,
      default: function () {
        return '25px'
      },
    },
    height: {
      type: String,
      default: function () {
        return '25px'
      },
    },
    colorStyle: {
      type: String,
      default: function () {
        return '#FFFFFF'
      },
    },
  },

  computed: {
    fill: function() {
      return this.colorStyle ? this.colorStyle : "currentColor"
    },
  },
};
</script>
