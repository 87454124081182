// ツリーの表示フラグ
export const displayFlagCode = {
  // グレーアウト
  grayout: 0,
  // 表示
  display: 1
}
// ３タブ＋コンテンツのID
export const settingTabIdCode = {
  // 指針
  hintId: 1,
  // 答え
  answerId: 2,
  // 解説
  explainId: 3,
  // コンテンツ
  contentId: 4
}

// チェックフラグ
// ※バックエンドとやり取りする数値
export const checkFlagCode = {
  // 非表示
  hide: 0,
  // 表示
  display: 1,
  // 未使用
  notUse: 2,
}
