var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "icon-bulk-return" }, [
    _c(
      "svg",
      {
        attrs: {
          id: "_レイヤー_1",
          "data-name": "レイヤー 1",
          xmlns: "http://www.w3.org/2000/svg",
          viewBox: "0 0 65.41 55.37",
          width: _vm.width,
          height: _vm.height
        }
      },
      [
        _c("defs"),
        _c("path", {
          staticClass: "cls-2",
          attrs: { d: "m2.5,40.17V4.91c0-1.33,1.08-2.41,2.41-2.41h29.55" }
        }),
        _c("path", {
          staticClass: "cls-2",
          attrs: {
            d:
              "m45.31,47.25v3.27c0,1.3-1.05,2.35-2.35,2.35H15.06c-1.34,0-2.43-1.09-2.43-2.44V14.96c0-1.34,1.09-2.43,2.43-2.43h27.93c1.28,0,2.32,1.04,2.32,2.32v14.36"
          }
        }),
        _c("line", {
          staticClass: "cls-2",
          attrs: { x1: "20.24", y1: "22.58", x2: "37.69", y2: "22.58" }
        }),
        _c("line", {
          staticClass: "cls-2",
          attrs: { x1: "20.24", y1: "32.68", x2: "31.02", y2: "32.68" }
        }),
        _c("line", {
          staticClass: "cls-2",
          attrs: { x1: "20.24", y1: "42.83", x2: "31.02", y2: "42.83" }
        }),
        _c("rect", {
          staticClass: "cls-2",
          attrs: { x: "35.63", y: "35.97", width: "14.16", height: "4.43" }
        }),
        _c("polygon", {
          staticClass: "cls-1",
          attrs: { points: "61.66 38.19 54.7 44.41 54.7 31.97 61.66 38.19" }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }