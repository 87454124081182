var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "icon-progress-not-handed" }, [
    _c(
      "svg",
      {
        attrs: {
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          viewBox: "0,0,256,256",
          width: _vm.width,
          height: _vm.height
        }
      },
      [
        _c(
          "g",
          {
            staticStyle: { "mix-blend-mode": "normal" },
            attrs: {
              fill: "#fa710b",
              "fill-rule": "nonzero",
              stroke: "none",
              "stroke-width": "1",
              "stroke-linecap": "butt",
              "stroke-linejoin": "miter",
              "stroke-miterlimit": "10",
              "stroke-dasharray": "",
              "stroke-dashoffset": "0",
              "font-family": "none",
              "font-weight": "none",
              "font-size": "none",
              "text-anchor": "none"
            }
          },
          [
            _c("g", { attrs: { transform: "scale(10.66667,10.66667)" } }, [
              _c("path", {
                attrs: {
                  d:
                    "M13.26172,14.86719l2.48047,2.48047c-0.37891,0.72266 -0.41797,1.44141 -0.01953,1.83984l4.52734,4.52734c0.57031,0.57031 1.8125,0.25781 2.76563,-0.69922c0.95703,-0.95703 1.26953,-2.19531 0.69922,-2.76562l-4.52344,-4.52734c-0.40234,-0.39844 -1.12109,-0.35937 -1.84375,0.01563l-2.48047,-2.47656zM8.5,0c-4.69531,0 -8.5,3.80469 -8.5,8.5c0,4.69531 3.80469,8.5 8.5,8.5c4.69531,0 8.5,-3.80469 8.5,-8.5c0,-4.69531 -3.80469,-8.5 -8.5,-8.5zM8.5,15c-3.58984,0 -6.5,-2.91016 -6.5,-6.5c0,-3.58984 2.91016,-6.5 6.5,-6.5c3.58984,0 6.5,2.91016 6.5,6.5c0,3.58984 -2.91016,6.5 -6.5,6.5z"
                }
              })
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }