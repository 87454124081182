<template>
  <div
    class="stamps"
    :class="{ 'bulk-view': bulkView }"
  >
    <div
      class="d-flex align-items-center justify-content-center"
      style="width: 100%; height: 100%; cursor: pointer;"
      @click="toggleStamps"
    >
      <div
        v-if="stampCode === stamps.length"
      >
        <label class="text-secondary" style="cursor: pointer;">スタンプ</label>
      </div>
      <div
        v-else
        class="selected-stamp"
        :class="{ 'bulk-view': bulkView }"
      >
        <img
          :src="stamp"
        >
      </div>
    </div>

    <ul
      v-if="showStamps"
      :class="{ 'bulk-view': bulkView }"
    >
      <li
        v-for="(item, index) in displayStamps"
        :key="index"
      >
        <div
          v-if="stampCode === item.code"
          class="border-blue"
          style="cursor: pointer;"
          @click="onChangeStamp(item)"
        >
          <img
            :src="stampList(item)"
          >
        </div>
        <div
          v-else
          style="cursor: pointer;"
          @click="onChangeStamp(item)"
        >
          <img
            :src="stampList(item)"
          >
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "StampArea",

  props: {
    stamps: {
      type: Array,
      default: function () {
        return []
      },
    },
    selectedStamp: {
      type: String,
      default: function () {
        return ""
      },
    },
    initStampCode: {
      type: Number,
      default: function () {
        return this.stamps.length
      },
    },
    studentIndex: {
      type: Number,
      default: function () {
        return 0
      },
    },
    bulkView: {
      type: Boolean,
      default: function() {
        return false
      }
    },
  },
  data: () => ({
    showStamps: false,
    stamp: "",
    stampCode: "",
    isSafari: false,
  }),
  computed: {
    displayStamps() {
      return this.stamps.filter(item => item.code !== 7 && item.code !== 9 && item.code !== this.stamps.length)
    }
  },
  watch: {
    studentIndex() {
      this.stampCode = this.initStampCode
    },
    stampCode() {
      this.stamp = this.getStamp(this.stampCode)
    }
  },
  mounted() {
    this.stampCode = this.initStampCode

    let agent = window.navigator.userAgent.toLowerCase()
    this.isSafari = (agent.indexOf("chrome") == -1 && agent.indexOf("safari") != -1)

    if(this.isSafari){
      window.addEventListener('touchstart', this.closeStampWindow);
    } else {
      window.addEventListener('click', this.closeStampWindow);
    }
  },
  beforeDestroy() {
    if(this.isSafari){
      window.removeEventListener('touchstart', this.closeStampWindow);
    } else {
      window.removeEventListener('click', this.closeStampWindow);
    }
  },
  methods: {
    // スタンプ変更時処理
    onChangeStamp(item) {
      if (item.stamp === null) {
        return
      }

      this.showStamps = false
      if (this.stampCode === item.code) {
        this.stampCode = this.stamps.length
      } else {
        this.stampCode = item.code
      }
      this.$emit("on-change-stamp", this.stampCode)
    },
    stampList(item) {
      return item.stamp
    },
    closeStampWindow(event) {
      if(this.showStamps
        && !this.$el.contains(event.target)){
          this.showStamps = false;
        }
    },
    // スタンプ画像の取得
    getStamp(stampCode) {
      if (this.stamps) {
        const stamp = this.stamps.find((item)=>item.code === stampCode)
        if (stamp) {
          return stamp.stamp
        } else {
          return ""
        }
      } else {
        return ""
      }
    },
    /**
     * スタンプ吹き出し切り替え
     */
    toggleStamps() {
      this.showStamps = !this.showStamps
      if (this.showStamps) {
        this.showEvals = false
      }
    }
  },
}
</script>

<style lang="scss" scoped>
ul {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
  }
}
.stamps {
  position: relative;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  &.bulk-view {
    width: 100px;
    height: 100px;
  }
  .selected-stamp {
    width: 60px;
    height: 60px;
    &.bulk-view {
      width: 100px;
      height: 100px;
    }
  }
  ul {
    display: flex;
    position: absolute;
    top: calc(100% + 15px);
    right: -16px;
    width: 340px;
    padding: 2%;
    flex-wrap: wrap;
    background: #fff;
    border: 1px solid #ff7f27;
    border-radius: 5px;
    z-index: 2;
    &::before {
      content: "";
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-bottom: 16px solid #ff7f27;
      position: absolute;
      top: -16px;
      right: calc(50px - 6px - 16px);
    }
    &::after {
      content: "";
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-bottom: 16px solid #fff;
      position: absolute;
      top: -15px;
      right: calc(50px - 6px - 16px);
    }
    &.bulk-view {
      top: auto;
      bottom: calc(100% + 15px);
      &::before {
        border-top: 16px solid #ff7f27;
        border-bottom: none;
        top: auto;
        bottom: -16px;
      }
      &::after {
        border-top: 16px solid #fff;
        border-bottom: none;
        top: auto;
        bottom: -15px;
      }
    }
    li {
      width: 20%;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    cursor: pointer;
    padding: 5px;
  }
}
img {
  -webkit-user-drag: none;
  user-select: none;
}
.border-blue {
  border-color: rgb(93, 93, 255);
  border-width: 3px;
  border-style: solid;
}
</style>