/**
 * 設定値 json store
 */
export const configJson = {
  namespaced: true,
  state: {
    nameConversions: {},
    parameterSettings: {},
  },

  getters: {
    nameConversions: (state) => {
      return state.nameConversions
    },
    parameterSettings: (state) => {
      return state.parameterSettings
    },
  },

  mutations: {
    setNameConversions(state, payload) {
      state.nameConversions = payload.nameConversions
    },
    setParameterSettings(state, payload) {
      state.parameterSettings = payload.parameterSettings
    },
    clearNameConversions(state) {
      state.nameConversions = {}
    },
    clearParameterSettings(state) {
      state.parameterSettings = {}
    },
  },

  actions: {
    /**
     * 設定値 json を store に登録する
     * @param {Object} {} Vuex インスタンス 
     * @param {Object} configJson 設定値 json
     */
    saveConfigSettings({ dispatch }, configJson) {
      // コード値・名称変換情報を store に登録
      dispatch('nameConversions/saveConfigSettings', configJson, { root: true })

      // パラメータ設定値情報を store に登録
      dispatch('parameterSettings/saveConfigSettings', configJson, { root: true })
    },
  },
}