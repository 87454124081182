<template>
  <div class="setting-preview-image">
    <div
      v-if="image"
      class="image"
    >
      <img
        class="container-fluid"
        :src="image"
      >
    </div>
    <!-- imageが空文字なら「画像がありません」と表示 -->
    <div
      v-else-if="image == '' && !stdbFlg"
      class="no_image d-flex align-items-center justify-content-center"
    >
      画像がありません。
    </div>
    <!--
      imageがnullなら何も表示しない
      ※初期表示での使用を想定
    -->
    <div
      v-else
      class="no_image d-flex"
    />
  </div>
</template>

<script>
export default {
  name: "SettingPreviewImage",
  props: {
    /**
     * 以下を受け付けて表示を分ける
     * ・画像のURL: 当該画像
     * ・空文字： 「画像がありません」の文言
     * ・null: 何も表示しない
     */
    image: {
      type: String,
      default: function () {
        return ""
      },
    },
    stdbFlg: Boolean,
  },
}
</script>

<style lang="scss" scoped>
.image {
  height: 190px;
  max-width: 850px;
}
.no_image {
  height: 190px;
  font-size: 24px;
}
img {
  -webkit-user-drag: none;
  user-select: none;
}
</style>
