var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-preview-pdf-question" },
    [
      _c("transition", { attrs: { name: "modal", appear: "" } }, [
        _c("div", { staticClass: "modal-mask" }, [
          _c("div", { staticClass: "modal-wrapper" }, [
            _c(
              "div",
              { staticClass: "modal-container-outer" },
              [
                _c("ButtonClose", {
                  staticClass: "modal-default-button",
                  nativeOn: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                }),
                _c("div", { staticClass: "modal-container mx-3 py-4" }, [
                  _c(
                    "div",
                    { staticClass: "row mb-5 question-setting mt-3" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "col-4 align-self-stretch tree-wrap modal-contents"
                        },
                        [
                          _c("FormTextField", {
                            staticClass: "py-1",
                            attrs: {
                              "label-text": _vm.$t("labels.taskName"),
                              "initial-value": _vm.taskName,
                              disabled: _vm.isDisabled,
                              "max-length": 50
                            },
                            on: {
                              input: _vm.inputTaskName,
                              "on-blur": _vm.blurTaskName
                            }
                          }),
                          _vm.showMsgTaskName
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v(" " + _vm._s(_vm.showMsgTaskName) + " ")
                              ])
                            : _vm._e(),
                          _c("FormTextField", {
                            staticClass: "py-1",
                            attrs: {
                              "label-text": _vm.$t("labels.sendMemo"),
                              "initial-value": _vm.sendMemo,
                              disabled: _vm.isDisabled,
                              "max-length": 50
                            },
                            on: {
                              input: _vm.inputSendMemo,
                              "on-blur": _vm.blurSendMemo
                            }
                          }),
                          _vm.showMsgSendMemo
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v(" " + _vm._s(_vm.showMsgSendMemo) + " ")
                              ])
                            : _vm._e(),
                          _c("br"),
                          _c("div", [
                            _vm._m(0),
                            _c("div", { staticClass: "question-area" }, [
                              _c(
                                "div",
                                { staticClass: "question-amount mx-3" },
                                [
                                  _c("div", { staticClass: "row py-1" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col-2",
                                        style: {
                                          visibility:
                                            "" +
                                            (_vm.isDisabled
                                              ? "hidden"
                                              : "visible")
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.questionCount = Math.max(
                                              _vm.questionCount - 1,
                                              1
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "icon-minus bi bi-dash-circle-fill"
                                        })
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "col-4 text-center" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("labels.questionNumber", {
                                                number: _vm.questionCount
                                              })
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col-2",
                                        style: {
                                          visibility:
                                            "" +
                                            (_vm.isDisabled
                                              ? "hidden"
                                              : "visible")
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.questionCount = Math.min(
                                              _vm.questionCount + 1,
                                              50
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "icon-plus bi bi-plus-circle-fill"
                                        })
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ])
                          ])
                        ],
                        1
                      ),
                      _c("PreviewPdfContainer", {
                        staticClass: "col-8",
                        attrs: { "img-list": _vm.imageList }
                      }),
                      !_vm.isDisabled
                        ? _c("div", { staticClass: "row px-0 mb-3" }, [
                            _c(
                              "div",
                              {
                                staticClass: "col-12",
                                staticStyle: { "text-align": "right" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-size": "70%",
                                      "text-align": "left",
                                      position: "relative",
                                      display: "inline-block"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("labels.recommendPageMessage")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  !_vm.isDisabled
                    ? _c(
                        "div",
                        { staticClass: "row gx-5 justify-content-center" },
                        [
                          _c(
                            "div",
                            { staticClass: "col-auto" },
                            [
                              _c("ButtonBorderCircleMedium", {
                                attrs: {
                                  "label-name": _vm.$t("buttons.cancel"),
                                  "label-color": _vm.colorLayoutTheme,
                                  "border-color": _vm.colorLayoutTheme
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.$emit("close")
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-auto" },
                            [
                              _c("ButtonBorderCircleMedium", {
                                attrs: {
                                  "label-name": _vm.$t("buttons.save"),
                                  color: "layout-theme-light",
                                  "label-color": _vm.colorLayoutTheme,
                                  "border-color": _vm.colorLayoutTheme,
                                  disabled: _vm.isProcessing
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.onClickSave.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ],
              1
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" " + _vm._s(_vm.$t("labels.questionQuantity")) + " ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }