<template>
  <div class="setting-preview-content">
    <div
      v-if="content"
      class="content text-align-center"
    >
      <img
        class="content-item"
        :src="content"
      >
    </div>
    <!-- imageが空文字なら「画像がありません」と表示 -->
    <div
      v-else-if="content === ''"
      class="no_image d-flex align-items-center justify-content-center"
    >
      画像がありません。
    </div>
    <!--
      imageがnullなら何も表示しない
      ※初期表示での使用を想定
    -->
    <div v-else />
  </div>
</template>

<script>
export default {
  name: "SettingPreviewContent",
  props: {
    /**
     * 以下を受け付けて表示を分ける
     * ・画像のURL: 当該画像
     * ・空文字： 「画像がありません」の文言
     * ・null: 何も表示しない
     */
    content: {
      type: String,
      default: function () {
        return ""
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  height: 170px;
}
.content-item {
  width: 100%;
  display: block;
  margin: auto;
}
.no_image {
  height: 170px;
  font-size: 24px;
}
img {
	-webkit-user-drag: none;
	user-select: none;
}
</style>
