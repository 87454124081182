/**
 * 宿題管理関連 API
 * ※LMS サーバの API は全て POST メソッド、Request body
 */
import apiInstance from "./axiosBase"

export default {
  /**
   * Create 系 API
   */

  /**
   * Read 系 API
   */

  /**
   * 宿題一覧を取得する
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {String} apiToken LMS API トークン
   * @returns 宿題一覧
   */
  async getHomeworkList(accountId, schoolId, apiToken) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/search`,
        data: {
          account_id: accountId,
          school_id: schoolId,
          token: apiToken,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 宿題詳細情報を取得する
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {Number} homeworkKey 宿題キー
   * @param {String} apiToken LMS API トークン
   */
  async getHomeworkDetail(accountId, schoolId, homeworkKey, apiToken) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/Detail`,
        data: {
          token: apiToken,
          account_id: accountId,
          school_id: schoolId,
          homework_key: homeworkKey,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 宿題進捗情報を取得する（StepFunctions）
   * @param {*} param 
   */
    async getHomeworkProgress(accountId, schoolId, homeworkKey, apiToken) {
      try {
        return await apiInstance.axiosLmsBase({
          method: "post",
          url: `/homework/SlctResultInfoSF`,
          data: {
            token: apiToken,
            school_id: schoolId,
            homework_key: homeworkKey,
            account_id: accountId,
          },
        })
      } catch (error) {
        return error
      }
    },
  
    /**
     * 宿題進捗情報を取得の実行ステータス取得
     * @param {string} executionArn
     * @returns 処理結果
     */
    async getStatusForHomeworkProgress(executionArn) {
      try {
        return await apiInstance.axiosLmsBase({
          method: "post",
          url: `/homework/SlctResultInfoSF/status`,
          data: {
            "executionArn": executionArn,
          },
        })
      } catch (error) {
        return error
      }
    },
  /**
   * 宿題進捗情報を取得する
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {Number} homeworkKey 宿題キー
   * @param {String} apiToken LMS API トークン
   * @returns 宿題進捗情報
   */
  async getHomeworkProgressAll(accountId, schoolId, homeworkKey, apiToken) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/SlctResultInfo`,
        data: {
          token: apiToken,
          school_id: schoolId,
          homework_key: homeworkKey,
          account_id: accountId,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 宿題進捗情報を取得する（ヘッダー情報）
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {Number} homeworkKey 宿題キー
   * @param {String} apiToken LMS API トークン
   * @returns 宿題進捗情報
   */
  async getHomeworkProgressList(accountId, schoolId, homeworkKey, apiToken) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/SlctResultInfoHeader`,
        data: {
          token: apiToken,
          school_id: schoolId,
          homework_key: homeworkKey,
          account_id: accountId,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 宿題進捗情報（詳細）を取得する
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {Number} homeworkKey 宿題キー
   * @param {Array} studentAccountIdList 生徒アカウントリスト
   * @param {String} apiToken LMS API トークン
   * @returns 宿題進捗情報
   */
  async getHomeworkProgressDetailList(accountId, schoolId, homeworkKey, studentAccountIdList, apiToken) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/SlctResultInfoDetail`,
        data: {
          token: apiToken,
          school_id: schoolId,
          homework_key: homeworkKey,
          account_id: accountId,
          student_account_id_list: studentAccountIdList,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 生徒のノート画像を取得する
   * @param {String} accountId アカウントID
   * @param {String} schoolId 学校 ID
   * @param {Number} homeworkKey 宿題キー
   * @param {String} studentAccountId 生徒アカウントID
   * @param {String} apiToken LMS API トークン
   * @returns 宿題進捗情報の生徒のノート画像を取得する
   */
  async searchStudentNoteInfo(
    accountId,
    schoolId,
    homeworkKey,
    studentAccountId,
    apiToken,
    timeout = 120000
  ) {
    try {
      let prmAccountId;
      let prmAccountIdList;
      if (Array.isArray(studentAccountId)) {
        prmAccountIdList = studentAccountId;
      } else {
        prmAccountId = studentAccountId;
      }
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/SlctNoteFileInfo`,
        data: {
          token: apiToken,
          school_id: schoolId,
          homework_key: homeworkKey,
          account_id: accountId,
          student_account_id: prmAccountId,
          student_account_id_list: prmAccountIdList,
        },
        timeout: timeout,
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 採点スタンプ画像を取得する
   * @param {String} fileName スタンプ画像ファイル名
   * @returns スタンプ画像
   */
  async getGradeStamps(fileName) {
    try {
      return await apiInstance.axiosAwsS3LinkTextbookBase({
        method: "get",
        // 配置先は教材連携用のバケット（アクセス制限のない場所でないと取得できないため）
        url: `/lms-assets/stamp/${fileName}`,
        headers: {
          "Content-Type": "image/svg+xml",
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 教材設定を取得する
   * @returns 教材設定
   */
  async getBookConfig(fileName) {
    try {
      return await apiInstance.axiosAwsS3LinkTextbookBase({
        method: "get",
        // 配置先は教材連携用のバケット（アクセス制限のない場所でないと取得できないため）
        url: `/lms-assets/json/${fileName}`,
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 宿題配信したファイルの履歴情報（STDB、PDF ファイル）を取得する
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {Number} mode モード "0"："アップロード一覧"、"1"："課題一覧"
   * @param {Number} homeworkKey モードが"0"場合、0(固定)、モードが"1"の場合、必須。
   * @param {Number} keyNo モードが"0"の場合、配信ファイル連番、モードが"1"の場合、宿題-枝番
   * @param {String} apiToken LMS API トークン
   * @returns 配信ファイル履歴一覧
   */
  async getHomeworkStreamFileInfo(
    accountId,
    schoolId,
    mode,
    homeworkKey,
    keyNo,
    apiToken
  ) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/SlctHaishinFileHis`,
        data: {
          token: apiToken,
          account_id: accountId,
          school_id: schoolId,
          mode: mode,
          homework_key: homeworkKey,
          key_no: keyNo,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 配信した宿題種別毎のファイル履歴一覧を取得する
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {Number} homeworkType 宿題種別
   * @param {String} apiToken LMS API トークン
   * @returns 配信ファイル履歴一覧
   */
  async getHomeworkStreamFileHistoryList(
    accountId,
    schoolId,
    homeworkType,
    apiToken,
    targetFile = 0,
  ) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/HaishinFileHisList`,
        data: {
          token: apiToken,
          account_id: accountId,
          school_id: schoolId,
          homework_syubetu_kbn: homeworkType,
          target_file: targetFile,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 宿題の種別ごとの登録済一覧を取得する
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {Number} homeworkKey 宿題キー
   * @param {Number} homeworkType 宿題種別
   * @param {String} apiToken LMS API トークン
   * @returns 宿題の問題設定情報一覧
   */
  async getQuestionOfHomework(
    accountId,
    schoolId,
    homeworkKey,
    homeworkType,
    apiToken
  ) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/InsCompList`,
        data: {
          token: apiToken,
          account_id: accountId,
          school_id: schoolId,
          homework_key: homeworkKey,
          homework_syubetu_kbn: homeworkType,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 宿題共通設定を取得する
   * @param {String} apiToken LMS API トークン
   * @param {String} schoolId 学校 ID
   * @param {String} accountId ID
   * @returns 宿題共通設定
   */
   async getHomeworkCmnInfo(apiToken, schoolId, accountId) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/CmnInfo`,
        data: {
          token: apiToken,
          school_id: schoolId,
          account_id: accountId,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 宿題共通設定を取得する
   * @param {String} apiToken LMS API トークン
   * @param {String} schoolId 学校 ID
   * @param {String} accountId ID
   * @returns 宿題共通設定
   */
   async getHomeworkSchoolInfo(apiToken, schoolId, accountId, setKey) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/SchoolInfo`,
        data: {
          token: apiToken,
          school_id: schoolId,
          account_id: accountId,
          set_key_list: setKey,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * Update 系 API
   */

  /**
   * 宿題保存・配信
   * @param {Object} params パラメータオブジェクト
   * @param {Number} mode モード（0: 保存、1: 配信）
   * @param {String} apiToken APIトークン
   * @returns 処理結果
   */
  async saveOrPublishHomework(accountId, params, mode, apiToken) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/Inshomework`,
        data: {
          token: apiToken,
          school_id: params.schoolId,
          homework_key: params.homeworkKey,
          account_id: accountId,
          haishin_status: params.status,
          mode: mode,
          curriculum: params.curriculum,
          subject: params.subject,
          homework_nm: params.homeworkName,
          category: params.category,
          haishin_start_date: params.startDate,
          haishin_end_date: params.deadlineDate,
          teacher_comment: params.comment,
          haishin_self_flg: params.sendToSelfFlag,
          open_date_time: params.openDateTime,
          HomeworkDetailList: params.detailList,
          HomeworkQuesSetList: params.questionSetList,
          HomeworkFileHistoryList: params.fileHistoryList,
          haishin_grp: params.groupId,
          haishin_account_id: params.publishAccountList,
          haishin_grp_type: params.haishinGrpType,
          editable: params.editable,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 生徒別宿題データを更新する（返却する）
   * @param {String} studentAccountId 生徒アカウント ID
   * @param {Array} studentAccountIdList 生徒アカウント IDリスト
   * @param {String} schoolId 学校 ID
   * @param {Number} homeworkKey 宿題キー
   * @param {Number} mode モード（0：評価セレクト選択、1：返却）
   * @param {String} accountId ID
   * @param {Number} evalCode 評価
   * @param {Number} evalManualSetFlg 評価手動設定フラグ
   * @param {Number} gradeStamp 採点スタンプ
   * @param {String} teacherComment 先生のコメント
   * @param {Array} drawList お絵描きデータ一覧
   * @param {Array} writeInfoList 書き込み情報一覧
   * @param {String} apiToken LMS API トークン
   * @param {String} systemDate
   * @returns 処理結果
   */
  async updateHomeworkProgressByStudent(
    studentAccountId,
    studentAccountIdList,
    schoolId,
    homeworkKey,
    mode,
    accountId,
    evalCode,
    evalManualSetFlg,
    gradeStamp,
    teacherComment,
    drawList,
    writeInfoList,
    apiToken,
    systemDate,
  ) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/UpdHomeworkScoreStamp`,
        data: {
          token: apiToken,
          student_account_id: studentAccountId,
          student_account_id_list: studentAccountIdList,
          school_id: schoolId,
          homework_key: homeworkKey,
          mode: mode,
          account_id: accountId,
          eval: evalCode,
          eval_manual_set_flg: evalManualSetFlg,
          score_stamp: gradeStamp,
          teacher_comment: teacherComment,
          NoteDrawList: drawList,
          NoteWriteInfoList: writeInfoList,
          system_date: systemDate,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * オリジナル教材アップロード用のURL取得
   * @param {String} accountId ID
   * @param {String} sessionToken セッショントークン
   * @returns 宿題の問題設定情報一覧
   */
  async giveUncompRequest(accountId, sessionToken) {
    let headers = {
      Accept: "application/json",
      Authorization: `Bearer ${sessionToken}`,
    }
    try {
      return await apiInstance.axiosStreamBase({
        method: "post",
        url: `/accounts/${accountId}/files/uncompressings`,
        headers: headers,
      })
    } catch (error) {
      return error
    }
  },

  /**
   * アップロードしたオリジナル教材取得
   * @param {String} accountId ID
   * @param {String} sessionToken セッショントークン
   * @returns 宿題の問題設定情報一覧
   */
  async uploadSpoSpr(uploadURL, fileData) {
    let headers = {
      "Content-Type": fileData["type"],
    }
    try {
      return await apiInstance.axiosLmsBase({
        method: "put",
        url: uploadURL,
        data: fileData,
        headers: headers,
        processData: false,
        contentType: false,
      })
    } catch (error) {
      return error
    }
  },

  /**
   * アップロードしたオリジナル教材取得
   * @param {String} uncompressingURL 解凍URL
   * @param {String} sessionToken セッショントークン
   * @returns 宿題の問題設定情報一覧
   */
  async getUncompressingsURL(uncompressingURL, sessionToken) {
    let headers = {
      Accept: "application/json",
      Authorization: `Bearer ${sessionToken}`,
    }
    try {
      return await apiInstance.axiosStreamBaseForExclusionCredentials({
        method: "get",
        url: uncompressingURL,
        headers: headers,
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 宿題共通設定を更新する
   * @param {String} apiToken LMS API トークン
   * @param {String} schoolId 学校 ID
   * @param {String} accountId 先生用アカウントID
   * @param {Object} evalSet 評価設定
   * @param {Object} categorySet カテゴリー設定
   * @returns 処理結果
   */
   async updateHomeworkCmnSet(
    apiToken,
    schoolId,
    accountId,
    evalSet,
    categorySet,
  ) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/CmnSet`,
        data: {
          token: apiToken,
          school_id: schoolId,
          account_id: accountId,
          eval: evalSet,
          category: categorySet,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 宿題共通設定を更新する
   * @param {String} apiToken LMS API トークン
   * @param {String} schoolId 学校 ID
   * @param {String} accountId 先生用アカウントID
   * @param {Object} evalSet 評価設定
   * @param {Object} categorySet カテゴリー設定
   * @returns 処理結果
   */
   async updateHomeworkSchoolSet(
    apiToken,
    schoolId,
    accountId,
    setKey,
    setValue,
    changeInfo,
  ) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/SchoolInfoSet`,
        data: {
          token: apiToken,
          school_id: schoolId,
          account_id: accountId,
          set_key: setKey,
          set_value: setValue,
          change_info: changeInfo,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 宿題評価設定を更新する
   * @param {String} apiToken LMS API トークン
   * @param {String} schoolId 学校 ID
   * @param {String} accountId 先生用アカウントID
   * @param {String} homeworkKey 宿題キー
   * @param {Object} evalSet 評価設定
   * @returns 処理結果
   */
   async updateHomeworkEvalSet(
    apiToken,
    schoolId,
    accountId,
    homeworkKey,
    evalSet,
  ) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/EvalSet`,
        data: {
          token: apiToken,
          school_id: schoolId,
          account_id: accountId,
          homework_key: homeworkKey,
          eval_set_flg: evalSet.eval_set_flg,
          detail_set_flg: evalSet.detail_set_flg,
          handed_eval: evalSet.handed_eval,
          handed_expired_eval: evalSet.handed_expired_eval,
          send_back_eval: evalSet.send_back_eval,
          not_handed_eval: evalSet.not_handed_eval,
          note_handed_eval: evalSet.note_handed_eval,
          note_handed_expired_eval: evalSet.note_handed_expired_eval,
          note_send_back_eval: evalSet.note_send_back_eval,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * Delete 系 API
   */

  /**
   * 宿題を1件削除する
   * @param {String} accountId ID
   * @param {Number} schoolId 学校 ID
   * @param {Number} homeworkKey 宿題キー
   * @param {String} apiToken LMS API トークン
   */
  async deleteHomework(accountId, schoolId, homeworkKey, apiToken) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/delete`,
        data: {
          account_id: accountId,
          school_id: schoolId,
          homework_key: homeworkKey,
          token: apiToken,
        },
      })
    } catch (error) {
      return error
    }
  },

    /**
   * 宿題を1件削除する
   * @param {string} executionArn
   * @returns 処理結果
   */
    async getStatusDeleteHomework(executionArn) {
      try {
        return await apiInstance.axiosLmsBase({
          method: "post",
          url: `/homework/delete/status`,
          data: {
            "executionArn": executionArn,
          },
          })
      } catch (error) {
        return error
      }
    },
  
  /**
   * 宿題記録のエクスポート
   * @param {*} param 
   */
  async exportHomework(param) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/Export`,
        data: param,
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 宿題記録のエクスポートの実行ステータス取得
   * @param {string} executionArn
   * @returns 処理結果
   */
  async getStatusForExportHomework(executionArn) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/Export/status`,
        data: {
          "executionArn": executionArn,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * StepFunctionsで生成されたJSONを取得する
   * @param {string} url 
   * @returns 処理結果
   */
  async getStepFunctionsResponseJson(url) {
    try {
      return await apiInstance.axiosAwsS3Base({
        method: "get",
        url: url,
        timeout: 300000,
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 学校共通設定情報を取得する
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {String} apiToken LMS API トークン
   * @param {String[]} keyList 取得キーリスト
   * @returns 学校共通設定情報
   */
  async getSchoolInfo(accountId, schoolId, apiToken, keyList) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/SchoolInfo`,
        data: {
          account_id: accountId,
          school_id: schoolId,
          token: apiToken,
          set_key_list: keyList.join(","),
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 学校共通設定情報を登録する
   * @param {String} accountId ID
   * @param {String} schoolId 学校 ID
   * @param {String} apiToken LMS API トークン
   * @param {String} keyName キー名
   * @param {String} value 値
   * @returns 学校共通設定情報
   */
  async saveSchoolInfo(accountId, schoolId, apiToken, keyName, value) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/homework/SchoolInfoSet`,
        data: {
          account_id: accountId,
          school_id: schoolId,
          token: apiToken,
          set_key: keyName,
          set_value: value,
        },
      })
    } catch (error) {
      return error
    }
  },
}
