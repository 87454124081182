<template>
  <div
    class="evals"
    :class="{
      'bg-eval-manual': evalManualSetFlg === 1,
      'bulk-view': bulkView
    }"
  >
    <div
      class="d-flex align-items-center justify-content-center"
      style="width: 100%; height: 100%; cursor: pointer;"
      @click="toggleShowEvals"
    >
      <div
        v-if="evalCode === 0"
      >
        <label class="text-secondary" style="cursor: pointer;">評価</label>
      </div>
      <div
        v-else
      >
        <label class="text-secondary" style="margin-top: 5px; cursor: pointer;">評価</label>
        <EvalStatus
          :eval-code="evalCode"
          style="font-size: 2em; margin-top: -12px;"
        />
      </div>
    </div>

    <ul
      v-if="showEvals"
      :class="{ 'bulk-view': bulkView }"
    >
      <li
        v-for="idx in 6"
        :key="idx"
      >
        <div
          class="d-flex justify-content-center align-items-center eval-item"
          :class="[
            evalCode === (idx < 6 ? idx : 0)
              ? 'border-blue'
              : '',
              idx === 6 ? 'none-item' : ''
          ]"
          style="cursor: pointer;"
          @click="onChangeEval(idx < 6 ? idx : 0)"
        >
          <EvalStatus
            :eval-code="(idx < 6 ? idx : 0)"
          />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import EvalStatus from "@/components/atoms/EvalStatus.vue"

export default {
  name: "EvalArea",
  components: {
    EvalStatus,
  },

  props: {
    width: {
      type: String,
      default: function () {
        return '60px'
      },
    },
    height: {
      type: String,
      default: function () {
        return '60px'
      },
    },
    initEvalCode: {
      type: Number,
      default: function () {
        return 0
      },
    },
    initEvalManualSetFlg: {
      type: Number,
      default: function () {
        return 0
      },
    },
    studentIndex: {
      type: Number,
      default: function () {
        return 0
      },
    },
    bulkView: {
      type: Boolean,
      default: function() {
        return false
      }
    },
  },
  data: () => ({
    showEvals: false,
    evalCode: 0,
    evalManualSetFlg: 0,
    isSafari: false,
  }),
  computed: {
    displayEvals() {
      return true
    },
  },
  watch: {
    studentIndex() {
      this.evalCode = this.initEvalCode
      this.evalManualSetFlg = this.initEvalManualSetFlg
    },
  },
  mounted() {
    this.evalCode = this.initEvalCode
    this.evalManualSetFlg = this.initEvalManualSetFlg

    let agent = window.navigator.userAgent.toLowerCase()
    this.isSafari = (agent.indexOf("chrome") == -1 && agent.indexOf("safari") != -1)

    if(this.isSafari){
      window.addEventListener('touchstart', this.closeEvalWindow);
    } else {
      window.addEventListener('click', this.closeEvalWindow);
    }
  },
  beforeDestroy() {
    if(this.isSafari){
      window.removeEventListener('touchstart', this.closeEvalWindow);
    } else {
      window.removeEventListener('click', this.closeEvalWindow);
    }
  },
  methods: {
    // 評価変更時処理
    onChangeEval(code) {
      if (code === null) {
        return
      }

      this.evalCode = code
      this.evalManualSetFlg = 1
      this.showEvals = !this.showEvals
      this.$emit("on-change-eval", this.evalCode, this.evalManualSetFlg)
    },
    closeEvalWindow(event) {
      if (this.showEvals
        && !this.$el.contains(event.target)) {
        this.showEvals = false
      }
    },
    /**
     * 評価吹き出し切り替え
     */
    toggleShowEvals() {
      this.showEvals = !this.showEvals
      if (this.showEvals) {
        this.showStamps = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
ul {
  padding: 0;
  margin: 0;
  li {
    list-style: none;
  }
}
.evals {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  width: 60px;
  height: 60px;
  &.bulk-view {
    width: 100px;
    height: 100px;
  }
  ul {
    display: flex;
    justify-content: start;
    align-items: center;
    position: absolute;
    top: calc(100% + 15px);
    right: -16px;
    width: 380px;
    padding: 10px 4px;
    flex-wrap: wrap;
    background: #fff;
    border: 1px solid #ff7f27;
    border-radius: 5px;
    column-count: 1;
    z-index: 2;
    &::before {
      content: "";
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-bottom: 16px solid #ff7f27;
      position: absolute;
      top: -16px;
      right: calc(50px - 6px - 16px);
    }
    &::after {
      content: "";
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-bottom: 16px solid #fff;
      position: absolute;
      top: -15px;
      right: calc(50px - 6px - 16px);
    }
    &.bulk-view {
      top: auto;
      bottom: calc(100% + 15px);
      &::before {
        border-top: 16px solid #ff7f27;
        border-bottom: none;
        top: auto;
        bottom: -16px;
      }
      &::after {
        border-top: 16px solid #fff;
        border-bottom: none;
        top: auto;
        bottom: -15px;
      }
    }
    li {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      .eval-item {
        width: 55px;
        height: 55px;
        font-size: xx-large;
        &.none-item {
          font-size: x-large;
        }
      }
    }
  }
}
.border-blue {
  border-color: rgb(93, 93, 255);
  border-width: 3px;
  border-style: solid;
}
.bg-eval-manual {
  background: #ffffcc;
}
</style>