<template>
  <div class="table-homework-progress-whole" v-show="showWhole">
    <table>
      <thead>
        <tr>
          <template v-for="(headerArray, arrayIndex) in setHeaderItems">
            <th
              v-for="(header, index) in headerArray"
              :key="`header-${arrayIndex}-${index}`"
              class="col ps-0"
              :class="{
                first: index === 0,
                last: index === headerArray.length - 1,
              }"
            >
              <div
                v-if="arrayIndex === 0 && index === 0"
                class="d-flex table-homework-progress-whole-toggle"
              >
                <div
                  class="d-flex justify-content-center align-items-center table-homework-progress-whole-toggle-button"
                  @click="showWhole = !showWhole"
                >
                  <div
                    class="d-flex justify-content-center align-items-center table-homework-progress-whole-toggle-button-inner"
                    v-text="showWhole ? '－' : '＋'"
                  >
                  </div>
                </div>
              </div>
              <div
                v-else
                class="text-center pre-line"
              >
                {{ header }}
              </div>
            </th>
          </template>
        </tr>
      </thead>

      <tbody>
        <!-- プレビュー -->
        <tr
          v-for="(item, index) in previewItems"
          :key="`first-${index}`"
          class="text-center"
        >
          <td class="itemName">
            {{ item.itemName }}
          </td>
          <!-- TODO: プレビューボタンのクリックイベントに必要な引数を渡す -->
          <template v-for="(array, arrayIndex) in headerItems">
            <td
              :key="`preview-${index}-${arrayIndex}`"
              class="child-question-preview bg-layout-theme-light col last"
              :colspan="array.length"
            >
              <ButtonPreview @click.native="onClickPreview(arrayIndex)" />
            </td>
          </template>
        </tr>

        <!-- 回答率 -->
        <tr class="text-center">
          <td class="itemName">
            {{ responseRateName }}
          </td>
          <template v-for="(itemArray, arrayIndex) in childQuestionRateItems">
            <td
              v-for="(item, index) in itemArray"
              :key="`second-${arrayIndex}-${index}`"
              class="child-question bg-layout-theme-light col"
              :class="{
                first: index === 0,
                last: index === itemArray.length - 1,
              }"
            >
              <div class="d-flex justify-content-end">
                {{ !item.responseRate ? 0 : item.responseRate }}
                <div class="align-self-end percent">
                  %
                </div>
              </div>
            </td>
          </template>
        </tr>
        <!-- 正答率（／回答） -->
        <tr class="text-center">
          <td class="itemName">
            {{ responseCorrectRateName }}
          </td>
          <template v-for="(itemArray, arrayIndex) in childQuestionRateItems">
            <td
              v-for="(item, index) in itemArray"
              :key="`third-${arrayIndex}-${index}`"
              class="child-question bg-layout-theme-light col"
              :class="{
                first: index === 0,
                last: index === itemArray.length - 1,
              }"
            >
              <div class="d-flex justify-content-end">
                {{ !item.responseCorrectRate ? 0 : item.responseCorrectRate }}
                <div class="align-self-end percent">
                  %
                </div>
              </div>
            </td>
          </template>
        </tr>
        <!-- 正答率（／全体） -->
        <tr class="text-center">
          <td class="itemName">
            {{ correctRateName }}
          </td>
          <template v-for="(itemArray, arrayIndex) in childQuestionRateItems">
            <td
              v-for="(item, index) in itemArray"
              :key="`third-${arrayIndex}-${index}`"
              class="child-question bg-layout-theme-light col"
              :class="{
                first: index === 0,
                last: index === itemArray.length - 1,
              }"
            >
              <div class="d-flex justify-content-end">
                {{ !item.correctRate ? 0 : item.correctRate }}
                <div class="align-self-end percent">
                  %
                </div>
              </div>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
    <ModalPreviewTextbookQuestion
      v-if="currentPreview === previewKind.textbook"
      :item="questionInfo"
      :curriculum="homeworkDetails.curriculum"
      :stream-group="`${Number(homeworkDetails.groupId) || 0}`"
      :login-user-info="loginUserInfo"
      :student-items="studentItems"
      :book-items="bookItems"
      :task-name="questionInfo.taskName"
      :selected-homework-item-index="selectedHomeworkItemIndex"
      @close="currentPreview = previewKind.none"
    />
    <ModalProgressPreviewStdbPdfQuestion
      v-if="
        currentPreview === previewKind.stdbLayout ||
          currentPreview === previewKind.pdf
      "
      :item="questionInfo"
      :selected-homework-item-index="selectedHomeworkItemIndex"
      @close="currentPreview = previewKind.none"
    />
    <ModalProgressPreviewSingleStdbQuestion
      v-if="currentPreview === previewKind.stdb"
      :item="questionInfo"
      :selected-homework-item-index="selectedHomeworkItemIndex"
      @close="currentPreview = previewKind.none"
    />
    <ModalConfirmOkOnly
      v-if="showConfirmCaution"
      :message="cautionMessage"
      @close-confirm-modal-ok-only="showConfirmCaution = false"
    />
  </div>
</template>

<script>
/**
 * 宿題進捗 - 全体の表
 */
import mixin from "@/mixins/mixin"
import ButtonPreview from "@/components/atoms/buttons/ButtonPreview.vue"
import ModalPreviewTextbookQuestion from "@/components/organisms/modal/ModalPreviewTextbookQuestion.vue"
import ModalProgressPreviewStdbPdfQuestion from "@/components/organisms/modal/ModalProgressPreviewStdbPdfQuestion.vue"
import ModalProgressPreviewSingleStdbQuestion from "@/components/organisms/modal/ModalProgressPreviewSingleStdbQuestion.vue"
import ModalConfirmOkOnly from "./modal/ModalConfirmOkOnly.vue"
import { homeworkTypeCode, streamFileInfoMode } from "../../constant/homework"

// プレビューの種類
const previewKind = {
  // プレビューしていない
  none: 0,
  // 書籍
  textbook: 1,
  // STDB(１問ずつ)
  stdb: 2,
  // STDB(レイアウト通り)
  stdbLayout: 3,
  // PDF
  pdf: 4,
}

export default {
  name: "TableHomeworkProgressWhole",
  components: {
    ButtonPreview,
    ModalPreviewTextbookQuestion,
    ModalProgressPreviewStdbPdfQuestion,
    ModalProgressPreviewSingleStdbQuestion,
    ModalConfirmOkOnly,
  },
  mixins: [mixin],
  props: {
    headerItems: {
      type: Array,
      default: function () {
        return []
      },
    },
    childQuestionRateItems: {
      type: Array,
      default: function () {
        return []
      },
    },
    studentItems: {
      type: Array,
      default: function () {
        return []
      },
    },
    bookItems: {
      type: Array,
      default: function () {
        return []
      },
    },
    homeworkDetails: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },

  data: function () {
    return {
      headers: [],
      previewItems: [{ itemName: "プレビュー" }],
      responseRateName: "回答率",
      responseCorrectRateName: "正答率（／回答）",
      correctRateName: "正答率（／全体）",
      questionInfo: {},
      cautionMessage: "",
      showConfirmCaution: false,
      selectedHomeworkItemIndex: 0,
      previewKind: previewKind, // templateからアクセスする為のもの
      // 現在プレビューしている問題の種類
      currentPreview: previewKind.none,
      showWhole: true,
      heightOpen: NaN,
      heightClose: NaN,
    }
  },

  computed: {
    setHeaderItems: function () {
      // 固定ヘッダ項目を初期設定
      (()=>{
        this.headers.length = 0
        this.headers.push([""])
      }).apply()

      // ヘッダーに子問題タイトルを追加する
      this.headerItems.forEach((x) => {
        this.headers.push(x)
      })
      return this.headers
    },
  },
  watch: {
    showWhole(newValue) {
      if (newValue) {
        // 開く
        this.$emit("opend", this.heightClose, this.heightOpen)
      } else {
        // 閉じる
        if (isNaN(this.heightClose)) {
          this.$nextTick(() => {
            this.measureHeight();
            this.$emit("closed", this.heightClose, this.heightOpen)
          });
        } else {
          this.$emit("closed", this.heightClose, this.heightOpen)
        }
      }
    },
  },
  methods: {
    onClickPreview: function (homeworkDetailIndex) {
      this.selectedHomeworkItemIndex = homeworkDetailIndex + 1
      const homeworkInfo = JSON.parse(
        JSON.stringify(this.homeworkDetails.homeworkInfo[homeworkDetailIndex])
      )
      if (homeworkInfo.kind === homeworkTypeCode.textbook) {
        if (this.bookItems.length === 0 && this.studentItems.length === 0) {
          this.showConfirmCaution = true
          this.cautionMessage = this.$t("messages.error.canNotGetPreviewFile")
          return
        }
        this.questionInfo = homeworkInfo
      } else {
        this.questionInfo = {
          mode: streamFileInfoMode.questionList,
          key: homeworkInfo.homeworkKey,
          keyNo: homeworkInfo.homeworkEdaNo,
          taskName: homeworkInfo.taskName,
          fileNmSeq: homeworkInfo.fileNmSeq,
          fileList: [],
          page: "progress",
          isPublished: true,
        }
      }

      switch (homeworkInfo.kind) {
        case homeworkTypeCode.textbook:
          // 書籍
          this.currentPreview = previewKind.textbook
          break
        case homeworkTypeCode.pdf:
          // PDF
          this.currentPreview = previewKind.pdf
          break
        case homeworkTypeCode.stdb:
          // STDB(１問ずつ)
          this.currentPreview = previewKind.stdb
          break
        case homeworkTypeCode.stdbLayout:
          // STDB(レイアウト通り)
          this.currentPreview = previewKind.stdbLayout
          break
      }
    },
    
    /** コンポーネントの高さを計測する */
    measureHeight: function() {
      if (this.showWhole) {
        this.heightOpen = this.$el.clientHeight;
      } else {
        this.heightClose = this.$el.clientHeight;
      }
    },
  },
  mounted: function() {
    this.$nextTick(() => {
      this.measureHeight();
    });
  },
  updated: function() {
    this.measureHeight();
  },
}
</script>

<style lang="scss" scoped>
.table-homework-progress-whole-toggle {
  //margin-left: calc(0.5rem - 1px) !important;

  .table-homework-progress-whole-toggle-button {
    cursor: pointer;
    width: 30px;
    height: 30px;
    .table-homework-progress-whole-toggle-button-inner {
      width: 14px;
      height: 14px;
      font-size: 12px;
      border: 1px solid var(--bs-body-color);
      color: var(--bs-body-color);
      background-color: var(--bs-white);
    }
  }
}
table {
  width: max-content;

  .pre-line {
    white-space: pre-line;
  }

  thead {
    border-top: 1px solid #b3b3b3;

    tr {
      height: 40px;
      background: var(--bs-table-header);
      color: var(--bs-layout-theme);

      &:first-child {
        border-bottom: 1px solid #b3b3b3;

        th {
          border-bottom: none;
        }
      }

      th {
        width: 4rem;
        &:first-child {
          width: calc(45.5rem + 3px);
        }
        border-left: 1px solid #b3b3b3;
        border-right: 1px solid #b3b3b3;
        border-bottom: 1px solid #e1e1e1;
      }
    }
  }

  tbody {
    tr {
      height: 50px;

      &:last-child {
        border-bottom: 1px solid #b3b3b3;

        td {
          border-bottom: none;
        }
      }

      td {
        padding: 0.25em 1em;
        border-left: 1px solid #b3b3b3;
        border-right: 1px solid #b3b3b3;
        border-bottom: 1px solid #e1e1e1;

        &.bg-layout-theme-light {
          background: var(--bs-layout-theme-light);
        }

        &.child-question-preview {
          width: 4rem;
          padding-left: 0.2rem;
          padding-right: 0.3rem;
        }
        &.child-question {
          width: 4rem;
          font-size: 1.3rem;
          padding-left: 0.2rem;
          padding-right: 0.3rem;

          .percent {
            font-size: 1rem;
          }
        }
      }
    }
  }
}

table .col {
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
}
table .col.first {
  border-left: 1px solid #b3b3b3;
}
table .col.last {
  border-right: 1px solid #b3b3b3;
}
</style>
