// import Vue from 'vue'
import { extend, localize } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import ja from 'vee-validate/dist/locale/ja.json'

// vee-validate の標準ルールを設定
for (let rule in rules) {
  extend(rule, rules[rule])
}
  
// Install vee-validate locales.
localize({
  ja,
})

// vee-validate のロケールを設定（デフォルトは英語）
localize('ja')