<template>
  <div class="homework-add-pdf-question">
    <div class="mt-3 mb-4 mx-4">
      <TitleHomework
        ref="titleArea"
        :help-link="$t('url.helps.addQuestion')"
      />
      <div class="d-flex justify-content-center">
        <p class="mb-0 fw-bold">
          {{ $t("labels.addStdbQuestion") }}
        </p>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row mb-3 stream-data-list">
        <div class="col">
          <div class="capacity-bar">
            <p class="mb-m">
              {{ $t("labels.uploadList") }}
            </p>
            <DataCapacityContent
              :max-byte="maximumLimitSize"
              :now-byte="nowSaveSize"
              :stdb-byte="stdbSaveSize"
              :pdf-byte="pdfSaveSize"
            />
          </div>
          <ModalPreviewStdbQuestion
            v-if="showPreviewStdbQuestion"
            :task-name="taskName"
            :send-memo="sendMemo"
            :question-type="questionType"
            :image-list="imageList"
            @close="onCloseInputStdb"
            v-on="{
              save: onInputStdb,
              'preview-open-flg': onPreviewOpen,
            }"
          />
          <ModalResetQuesNumStdbQuestion
            v-if="showResetQuesNumStdbQuestion"
            :task-name="taskName"
            :send-memo="sendMemo"
            :question-type="questionType"
            :image-list="imageList"
            :file-path="tempFilePath"
            @save="resetQuesNumStdbQuestion"
            @close="closeResetQuesNumStdbQuestion"
          />
          <div
            class="card card-body overflow-auto my-2 p-3 file-select"
            @dragover.prevent="drag = true"
            @dragleave.prevent="drag = false"
            @drop.prevent="readDecryptZip"
          >
            <div class="row">
              <div class="col-8 col-xl-10">
                {{ $t("labels.fileDragAndDropOrFileSelect") }}
              </div>
              <div class="col-4 col-xl-2 button-file-select">
                <button
                  type="button"
                  class="btn btn-black btn-sm px-4 py-1"
                  color="table-row-upload"
                  :style="buttonStyle"
                  @click="onClickFileSelect"
                >
                  {{ $t("buttons.fileSelect") }}
                </button>

                <div v-if="isChrome">
                  <input
                    ref="input"
                    type="file"
                    class="d-none"
                    accept=".spo,.spr"
                    @change="readDecryptZip"
                  >
                </div>
                <div v-else>
                  <input
                    ref="input"
                    type="file"
                    class="d-none"
                    @change="readDecryptZip"
                  >
                </div>
              </div>
            </div>
          </div>
          <div :key="updateKey">
            <TableHomeworkUploadDataList
              :upload-data-list="showUploadItems"
              :display-count="5"
              v-on="{
                'change-checkbox': checkUploadTableRow,
                'delete-upload-data': deleteUploadTableRow,
              }"
            />
          </div>
        </div>
      </div>
    </div>
    <p class="explanation">
      {{ $t("labels.selectStreamDataTheUploadListFirst") }}
      <br>
      {{ $t("labels.selectStreamDataTheUploadListLate") }}
    </p>
    <div class="container-fluid">
      <div v-if="showErrorMessageFlg">
        {{ $t("messages.error.canNotPublishSelectedTextbook") }}
      </div>
      <div class="row mb-1 stream-data-list">
        <div class="col">
          <TableHomeworkUploadStreamDataList
            :stream-data-list="currentHomeworkDetailItems"
            :display-count="5"
            :limit="homeworkMaximumLimitItems[1].items[0].value"
            :hide-sort-buttons="true"
            v-on="{
              delete: deleteStreamTableRow,
              'changed-stream-data-list': changedStreamDataList,
            }"
          />
        </div>
      </div>
      <div class="row px-0 mb-4">
        <div
          class="col-12"
          style="text-align: right"
        >
        </div>
      </div>
      <div class="row gx-5 justify-content-center">
        <div class="col-auto">
          <ButtonBorderCircleMedium
            :label-name="$t('buttons.cancel')"
            :label-color="colorLayoutTheme"
            :border-color="colorLayoutTheme"
            @click.native="onClickCancel"
          />
        </div>
        <div class="col-auto">
          <ButtonBorderCircleMedium
            :label-name="$t('buttons.register')"
            color="layout-theme-light"
            :label-color="colorLayoutTheme"
            :border-color="colorLayoutTheme"
            @click.native="onClickRegister"
          />
        </div>
      </div>
    </div>
    <LoadingScreen v-if="isLoading" />
    <ModalConfirmOkOnly
      v-if="showConfirmCaution"
      :message="cautionMessage"
      @close-confirm-modal-ok-only="showConfirmCaution = false"
    />
    <ModalConfirmOkOnly
      v-if="sessionDisconnect"
      :message="$t('messages.error.overLimitSessionToken')"
      @close-confirm-modal-ok-only="onSessionDisconnect()"
    />
    <Footer></Footer>
  </div>
</template>

<script>
/**
 * 宿題管理 - Studyaid D.B. プリント
 */
import { db } from "@/dbs/indexedDb"
import { mapActions, mapGetters, mapMutations } from "vuex"
import mixin from "@/mixins/mixin"
import TitleHomework from "@/components/molecules/TitleHomework.vue"
import DataCapacityContent from "@/components/organisms/DataCapacityContent.vue"
import TableHomeworkUploadDataList from "@/components/organisms/TableHomeworkUploadDataList.vue"
import TableHomeworkUploadStreamDataList from "@/components/organisms/TableHomeworkUploadStreamDataList.vue"
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"
import ModalPreviewStdbQuestion from "@/components/organisms/modal/ModalPreviewStdbQuestion.vue"
import ModalResetQuesNumStdbQuestion from "@/components/organisms/modal/ModalResetQuesNumStdbQuestion.vue"
import ModalConfirmOkOnly from "@/components/organisms/modal/ModalConfirmOkOnly.vue"
import LoadingScreen from "@/components/atoms/LoadingScreen.vue"
import Footer from "@/components/organisms/common/Footer.vue"

import { homeworkTypeCode } from "@/constant/homework"
import { streamStatus } from "@/constant/homework"
import homeworkRepository from "@/repositories/homework"
import homeworkApi from "@/api/homework"

import axios from "axios"
import JSZip from "jszip"

export default {
  // コンポートの名前
  name: "HomeworkAddStdbQuestion",

  // 使用するコンポーネント(依存関係)
  components: {
    TitleHomework,
    DataCapacityContent,
    ModalPreviewStdbQuestion,
    TableHomeworkUploadDataList,
    TableHomeworkUploadStreamDataList,
    ButtonBorderCircleMedium,
    ModalConfirmOkOnly,
    ModalResetQuesNumStdbQuestion,
    LoadingScreen,
    Footer,
  },

  mixins: [mixin],
  props: {
    homeworkStatus: { type: Number, required: false, default: 999 },
  },
  data: () => ({
    colorLayoutTheme: "#ff7f27", // レイアウトテーマカラー(オレンジ)
    uploadHomeworkDetailItems: [], // アップロード一覧
    currentHomeworkDetailItems: [], // 配信データ一覧
    isLoading: false, // ローディング中か
    showErrorMessageFlg: false, // エラーメッセージフラグ
    isOverLimit: false, // 上限超えているフラグ
    maximumLimitSize: 0, // 最大容量
    nowSaveSize: 0, // 現在の使用容量
    nowSaveSizeByte: 0, // 現在の使用容量
    stdbSaveSize: 0, // STDB容量
    stdbSaveSizeByte: 0, // STDB容量
    pdfSaveSize: 0, // PDF容量
    pdfSaveSizeByte: 0, // PDF容量
    showPreviewStdbQuestion: false, // STDBダイアログ
    showConfirmCaution: false, // 警告確認
    cautionMessage: "", // 警告メッセージ
    updateKey: true, // 更新キー
    isChrome: false, // Chromeであるか

    url4upload: "", // オリジナル教材ファイルアップロードURL
    url4checkUncompResult: "", // オリジナル教材解凍URL
    url4download: "", // オリジナル教材ファイルダウンロードURL
    standbyTime: 1, // 待ち時間
    judgeProcessing: undefined, // 受信処理待ちフラグ
    imageList: [],
    showResetQuesNumStdbQuestion: false,
    tempStdbParam: {},
    tempFilePath: "",
    runningReadDecryptZip: false,
  }),

  computed: {
    ...mapGetters("homework", [
      // Store情報
      "headerInfo", // ヘッダ情報
      "uploadPdfDetailItems", // アップロード一覧（一時保存用）
      "uploadStdbDetailItems", // アップロード一覧（一時保存用）
      "homeworkDetailItems", // 配信一覧
      "homeworkFileHistoryItems", // ファイルリスト
      "homeworkSetItems", // 宿題設定リスト
    ]),
    ...mapGetters("parameterSettings", [
      "homeworkMaximumLimitItems", // LMSサーバプロパティ情報
      "uploadMaximumLimitItems", // アップロード上限情報
    ]),

    buttonStyle: function () {
      return {
        color: "black",
        border: "solid 1px #b3b3b3",
      }
    },
    showUploadItems() {
      return this.uploadHomeworkDetailItems.filter(
        (item) => item.deleteFlg == false
      )
    },
  },

  created: function () {
    // イベントリスナ登録
    window.addEventListener("resize", this.onHandleResize, false)
    window.addEventListener("dragover", this.dragAndDropSelectedFile)
  },

  // マウント直後に実行
  mounted: async function () {
    // セッション期間が有効かチェックする
    if (!await this.checkSession()) {
      return
    }
    this.init()
    this.$parent.resetScroll();
  },

  // 破棄される前
  beforeDestroy: function () {
    // インスタンスを破棄する前に、イベントリスナから削除
    window.removeEventListener("resize", this.onHandleResize, false)
    window.removeEventListener("dragover", this.dragAndDropSelectedFile)
  },

  // メソッド
  methods: {
    ...mapMutations("homework", [
      "setUploadStdbDetailItems",
      "setUploadPdfDetailItems",
      "setHomeworkDetailItems",
      "setHomeworkFileHistoryItems",
      "setHomeworkSetItems",
    ]),
    ...mapActions("homework", [
      "saveUploadStdbDetailItems",
      "saveUploadPdfDetailItems",
    ]),

    async init() {
      this.isLoading = true

      // ブラウザ判断
      let agent = window.navigator.userAgent.toLowerCase()
      if (agent.indexOf("chrome") != -1) {
        this.isChrome = true
      }

      // 配信ファイル履歴リスト取得
      const haishinPromise =
        await this.getHomeworkStreamFileHistoryListForStdb()
      let haishinList = haishinPromise.data.data.haishinfile_history_list

      // PDFの保存容量
      this.pdfSaveSize = haishinPromise.data.data.pdf_save_size
      // STDBの保存容量
      this.stdbSaveSize = haishinPromise.data.data.stdb_save_size

      // 処理途中で画面再描画された際の復帰対応(Storeから取得)
      if (this.uploadStdbDetailItems.length) {
        this.uploadHomeworkDetailItems = JSON.parse(
          JSON.stringify(this.uploadStdbDetailItems)
        )
        if (
          this.uploadHomeworkDetailItems &&
          this.uploadHomeworkDetailItems.length > 1
        ) {
          // listをソートする
          const dt = JSON.parse(JSON.stringify(this.uploadHomeworkDetailItems))
          let result = dt.sort(function (a, b) {
            const aData = new Date(a.updateDate)
            const bData = new Date(b.updateDate)
            return aData > bData ? -1 : 1 //オブジェクトの降順ソート
          })
          this.uploadHomeworkDetailItems = result
        }
        // 差分を取得する
        await this.getDiffStdbUploadItem(haishinList)
        await this.getDiffPdfUploadItem()
      } else {
        this.editMode = this.headerInfo.editMode
        try {
          let uploadList = []
          for (let i = 0; i < haishinList.length; i++) {
            let item = haishinList[i]
            const questionCountList = await this.getQuestionCount(
              item.sub_info[0].file_path
            )
            let endUseDate =
              item.last_use_date.substr(0, 4) +
              "/" +
              item.last_use_date.substr(4, 2) +
              "/" +
              item.last_use_date.substr(6, 2)
            let endPubDate =
              item.expiration_date.substr(0, 4) +
              "/" +
              item.expiration_date.substr(4, 2) +
              "/" +
              item.expiration_date.substr(6, 2)

            uploadList.push({
              checkFlg: false,
              taskName: item.title, // 課題名
              mainQuestionInfo: {
                // メイン情報
                chapterId: item.main_info[0].chapter_id, // 章ID
                nodeId: item.main_info[0].node_id, // 節ID
                quesParentId: item.main_info[0].ques_parent_id, // 親問題ID
                fileName: item.main_info[0].file_name, // ファイル名
              },
              subQuestionInfo: {
                // サブ情報
                teachingMaterials: item.sub_info[0].teaching_materials, // 教材ID
                filePath: item.sub_info[0].file_path, // ファイルパス
              },
              sendMemo: item.send_memo, // 送信メモ
              questionCount: questionCountList, // 問題数
              ansDispFlg: false, // 答え表示フラグ
              explainDispFlg: false, // 解説表示フラグ

              homeworkEdaNo: "", // 宿題枝番
              haishinFileNo: item.haishin_file_no, // 配信ファイル連番 新規の場合、0
              endUseDate: endUseDate, // 最終利用日
              endPubDate: endPubDate, // 有効期限
              fileStatusFlg: 1, // "0"：新規、"1"：再利用、"2"：削除
              updateDate: item.update_date,
              deleteFlg: false,
            })
          }
          if (uploadList && uploadList.length > 1) {
            // listをソートする
            const dt = JSON.parse(JSON.stringify(uploadList))
            let result = dt.sort(function (a, b) {
              const aData = new Date(a.updateDate)
              const bData = new Date(b.updateDate)
              return aData > bData ? -1 : 1 //オブジェクトの降順ソート
            })
            uploadList = result
          }
          this.uploadHomeworkDetailItems = uploadList
          // store登録
          // 全てのチェックを一旦外す
          const list = this.uploadHomeworkDetailItems.map((item) => {
            item = JSON.parse(JSON.stringify(item))
            item.checkFlg = false
            return item
          })
          await this.saveUploadStdbDetailItems(list)

          // 差分を取得する
          await this.getPdfUploadSize()
        } catch (e) {
          console.log(e)
        }
      }

      // Storeから配信データ一覧取得
      this.currentHomeworkDetailItems = JSON.parse(
        JSON.stringify(this.homeworkDetailItems)
      ).filter(
        (item) =>
          // STDBの情報のみを取得
          item.questionType === homeworkTypeCode.stdb ||
          item.questionType === homeworkTypeCode.stdbLayout
      )
      this.setCheckFlgCurrentHomeworkDetailItem()

      if (this.pdfSaveSize === 0 && this.uploadPdfDetailItems.length) {
        // PDFの保存容量が0.01以下の場合、0.01を表示する
        this.pdfSaveSize = 0.01
      }
      this.stdbSaveSizeByte = this.stdbSaveSize * 1000000000
      this.pdfSaveSizeByte = this.pdfSaveSize * 1000000000
      this.nowSaveSize =
        (Number(this.pdfSaveSize) * 100 + Number(this.stdbSaveSize) * 100) / 100
      this.nowSaveSizeByte = this.nowSaveSize * 1000000000

      // 保存容量の合計値
      this.maximumLimitSize = this.uploadMaximumLimitItems[0].items[0].value

      if (this.uploadStdbDetailItems.length) {
        let filteredAddReserveStdb = this.uploadStdbDetailItems.filter(
          (item) => item.haishinFileNo == 0
        )
        filteredAddReserveStdb.forEach((item) => {
          this.stdbSaveSizeByte += item.size || 0
          this.nowSaveSizeByte += item.size || 0
        })

        let notExistSizelength = 0

        let filteredDeleteReserveStdb = this.uploadStdbDetailItems.filter(
          (item) => item.deleteFlg === true
        )
        let filteredAliveReserveStdb = this.uploadStdbDetailItems.filter(
          (item) => item.deleteFlg === false
        )
        filteredDeleteReserveStdb.forEach((item) => {
          if (item.size) {
            this.stdbSaveSizeByte -= item.size || 0
            this.nowSaveSizeByte -= item.size || 0
          } else {
            notExistSizelength++
          }
        })

        if (notExistSizelength > 0) {
          if (filteredAliveReserveStdb.length == 0) {
            this.stdbSaveSize = 0
            this.nowSaveSize = 0
          } else {
            const calcTargetLength =
              filteredAliveReserveStdb.length + notExistSizelength
            let stdbSaveSizeByte = this.stdbSaveSizeByte
            let nowSaveSizeByte = this.nowSaveSizeByte

            let avgStdbSaveSizeByte = stdbSaveSizeByte / calcTargetLength
            this.stdbSaveSizeByte =
              avgStdbSaveSizeByte * filteredAliveReserveStdb.length

            let avgNowSaveSizeByte = nowSaveSizeByte / calcTargetLength
            this.nowSaveSizeByte =
              avgNowSaveSizeByte * filteredAliveReserveStdb.length
            this.stdbSaveSize =
              Math.round((this.stdbSaveSizeByte / 1000000000) * 100) / 100
            this.nowSaveSize =
              Math.round((this.nowSaveSizeByte / 1000000000) * 100) / 100

            if (this.stdbSaveSize === 0) {
              // STDBの保存容量が0.01以下の場合、0.01を表示する
              this.stdbSaveSize = 0.01
            }
          }
        } else {
          this.stdbSaveSize =
            Math.round((this.stdbSaveSizeByte / 1000000000) * 100) / 100
          this.nowSaveSize =
            Math.round((this.nowSaveSizeByte / 1000000000) * 100) / 100
        }

        if (filteredAliveReserveStdb.length !== 0 && this.stdbSaveSize === 0) {
          this.stdbSaveSize = 0.01
        }
      }

      if (this.uploadPdfDetailItems.length) {
        let filteredAddReservePdf = this.uploadPdfDetailItems.filter(
          (item) => item.haishinFileNo == 0
        )
        filteredAddReservePdf.forEach((item) => {
          this.pdfSaveSizeByte += item.size || 0
          this.nowSaveSizeByte += item.size || 0
        })

        let notExistSizelength = 0
        let filteredDeleteReservePdf = this.uploadPdfDetailItems.filter(
          (item) => item.deleteFlg === true
        )
        let filteredAliveReservePdf = this.uploadPdfDetailItems.filter(
          (item) => item.deleteFlg === false
        )
        filteredDeleteReservePdf.forEach((item) => {
          if (item.size) {
            this.pdfSaveSizeByte -= item.size || 0
            this.nowSaveSizeByte -= item.size || 0
          } else {
            notExistSizelength++
          }
        })

        if (notExistSizelength > 0) {
          if (filteredAliveReservePdf.length == 0) {
            this.pdfSaveSizeByte = 0
            this.nowSaveSizeByte = 0
            this.pdfSaveSize = 0
            this.nowSaveSize = 0
          } else {
            const calcTargetLength =
              filteredAliveReservePdf.length + notExistSizelength
            let pdfSaveSizeByte = this.pdfSaveSizeByte
            let nowSaveSizeByte = this.nowSaveSizeByte

            let avgPdfSaveSizeByte = pdfSaveSizeByte / calcTargetLength
            this.pdfSaveSizeByte =
              avgPdfSaveSizeByte * filteredAliveReservePdf.length

            let avgNowSaveSizeByte = nowSaveSizeByte / calcTargetLength
            this.nowSaveSizeByte =
              avgNowSaveSizeByte * filteredAliveReservePdf.length
            this.stdbSaveSize =
              Math.round((this.stdbSaveSizeByte / 1000000000) * 100) / 100
            this.nowSaveSize =
              Math.round((this.nowSaveSizeByte / 1000000000) * 100) / 100
          }

          if (this.pdfSaveSize === 0) {
            // PDFの保存容量が0.01以下の場合、0.01を表示する
            this.pdfSaveSize = 0.01
          }
        } else {
          this.pdfSaveSize =
            Math.round((this.pdfSaveSizeByte / 1000000000) * 100) / 100
          this.nowSaveSize =
            Math.round((this.nowSaveSizeByte / 1000000000) * 100) / 100
        }
        if (filteredAliveReservePdf.length !== 0 && this.pdfSaveSize === 0) {
          this.pdfSaveSize = 0.01
        }
      }

      this.nowSaveSize =
        Math.round((this.stdbSaveSize + this.pdfSaveSize) * 100) / 100

      // プログレス終了
      this.isLoading = false
    },

    async getPdfUploadSize() {
      // 配信ファイル履歴リスト取得
      const haishinPromise = await this.getHomeworkStreamFileHistoryListForPdf()
      let haishinList = haishinPromise.data.data.haishinfile_history_list
      if (haishinList.length > 0 && this.pdfSaveSize === 0) {
        this.pdfSaveSize = 0.01
      }
    },
    /**
     * 配信データ内にある課題にチェックマークを付ける
     */
    setCheckFlgCurrentHomeworkDetailItem() {
      let uploadList = []
      for (let i = 0; i < this.uploadHomeworkDetailItems.length; i++) {
        let upItem = this.uploadHomeworkDetailItems[i]
        // 詳細テーブルの情報に一致するアップロード一覧情報より、必要な情報を詳細テーブル行にコピーする
        this.currentHomeworkDetailItems.some((hwItem) => {
          // 一致情報がある場合
          if (
            hwItem.subQuestionInfo.filePath === upItem.subQuestionInfo.filePath
          ) {
            upItem.checkFlg = true // チェック
            upItem.questionType = hwItem.questionType // 種別
            hwItem.haishinFileNo = upItem.haishinFileNo // 配信ファイル連番
            hwItem.ansDispFlg = upItem.ansDispFlg // 答えフラグ
            hwItem.explainDispFlg = upItem.explainDispFlg // 解説フラグ
          }
        })
        uploadList.push(upItem)
      }

      this.uploadHomeworkDetailItems = uploadList
    },
    /**
     * IndexedDBから問題数の配列を取得
     * @param {String} filePath ファイルパス
     * @returns 問題数の配列
     */
    getQuestionCount: async function (filePath) {
      const item = await this.getUploadHomeworkDetailItems(filePath)
      if (!item) {
        return []
      }

      return item.uploadHomeworkDetailItem.questionCount || []
    },

    /**
     * STDBアップロードファイル差分取得
     */
    async getDiffStdbUploadItem(haishinList) {
      // 追加
      await new Promise((resolve) => {
        const promises = haishinList.map((hl) => {
          return new Promise((resolveFetch) => {
            const addFlg = this.uploadHomeworkDetailItems.every((hdi) => {
              return hl.sub_info[0].file_path !== hdi.subQuestionInfo.filePath
            })
            const questionCountList = this.getQuestionCount(
              hl.sub_info[0].file_path
            )
            let endUseDate =
              hl.last_use_date.substr(0, 4) +
              "/" +
              hl.last_use_date.substr(4, 2) +
              "/" +
              hl.last_use_date.substr(6, 2)
            let endPubDate =
              hl.expiration_date.substr(0, 4) +
              "/" +
              hl.expiration_date.substr(4, 2) +
              "/" +
              hl.expiration_date.substr(6, 2)
            if (addFlg) {
              this.uploadHomeworkDetailItems.push({
                checkFlg: false,
                taskName: hl.title, // 課題名
                mainQuestionInfo: {
                  // メイン情報
                  chapterId: hl.main_info[0].chapter_id, // 章ID
                  nodeId: hl.main_info[0].node_id, // 節ID
                  quesParentId: hl.main_info[0].ques_parent_id, // 親問題ID
                  fileName: hl.main_info[0].file_name, // ファイル名
                },
                subQuestionInfo: {
                  // サブ情報
                  teachingMaterials: hl.sub_info[0].teaching_materials, // 教材ID
                  filePath: hl.sub_info[0].file_path, // ファイルパス
                },
                sendMemo: hl.send_memo, // 送信メモ
                questionCount: questionCountList, // 問題数
                ansDispFlg: false, // 答え表示フラグ
                explainDispFlg: false, // 解説表示フラグ

                homeworkEdaNo: "", // 宿題枝番
                haishinFileNo: hl.haishin_file_no, // 配信ファイル連番 新規の場合、0
                endUseDate: endUseDate, // 最終利用日
                endPubDate: endPubDate, // 有効期限
                fileStatusFlg: 1, // "0"：新規、"1"：再利用、"2"：削除
                updateDate: hl.update_date,
                deleteFlg: false,
              })
            }

            const updatedIndex = this.uploadHomeworkDetailItems.findIndex(
              (hdi) => {
                const existFlg =
                  hl.sub_info[0].file_path == hdi.subQuestionInfo.filePath
                const updatedFlg = hl.haishin_file_no != hdi.haishinFileNo
                return existFlg && updatedFlg
              }
            )

            if (updatedIndex != -1) {
              let updateItem = this.uploadHomeworkDetailItems[updatedIndex]
              this.uploadHomeworkDetailItems[updatedIndex] = {
                checkFlg: updateItem.checkFlg,
                taskName: updateItem.taskName,
                mainQuestionInfo: updateItem.mainQuestionInfo,
                subQuestionInfo: updateItem.subQuestionInfo,
                sendMemo: updateItem.sendMemo,
                questionCount: updateItem.questionCount,
                ansDispFlg: updateItem.ansDispFlg,
                explainDispFlg: updateItem.explainDispFlg,
                homeworkEdaNo: updateItem.homeworkEdaNo,
                haishinFileNo: hl.haishin_file_no,
                endUseDate: endUseDate,
                endPubDate: endPubDate,
                fileStatusFlg: 1,
                updateDate: hl.update_date,
                deleteFlg: updateItem.deleteFlg,
              }
            }
            resolveFetch()
          })
        })

        Promise.all(promises).then(() => resolve(haishinList))
      })

      // 削除
      let aliveItems = []
      this.uploadHomeworkDetailItems.forEach((hdi) => {
        const deleteFlg = haishinList.every((hl) => {
          return (
            hdi.subQuestionInfo.filePath !== hl.sub_info[0].file_path &&
            hdi.haishinFileNo !== 0
          )
        })
        if (!deleteFlg || haishinList.length == 0) {
          aliveItems.push(hdi)
        }
      })

      // listをソートする
      if (aliveItems && aliveItems.length > 1) {
        const dt = JSON.parse(JSON.stringify(aliveItems))
        let result = dt.sort(function (a, b) {
          const aData = new Date(a.updateDate)
          const bData = new Date(b.updateDate)
          return aData > bData ? -1 : 1 //オブジェクトの降順ソート
        })
        aliveItems = result
      }
      this.uploadHomeworkDetailItems = aliveItems

      // store登録
      // 全てのチェックを一旦外す
      const list = this.uploadHomeworkDetailItems.map((item) => {
        item = JSON.parse(JSON.stringify(item))
        item.checkFlg = false
        return item
      })
      await this.saveUploadStdbDetailItems(list)
    },

    /**
     * PDFアップロードファイル差分取得
     */
    async getDiffPdfUploadItem() {
      // 配信ファイル履歴リスト取得
      const haishinPromise = await this.getHomeworkStreamFileHistoryListForPdf()
      let haishinList = haishinPromise.data.data.haishinfile_history_list
      let uploadList = JSON.parse(JSON.stringify(this.uploadPdfDetailItems))

      // 追加
      await new Promise((resolve) => {
        const promises = haishinList.map((hl) => {
          return new Promise((resolveFetch) => {
            const addFlg = uploadList.every((hdi) => {
              return hl.sub_info[0].file_path !== hdi.subQuestionInfo.filePath
            })
            let endUseDate =
              hl.last_use_date.substr(0, 4) +
              "/" +
              hl.last_use_date.substr(4, 2) +
              "/" +
              hl.last_use_date.substr(6, 2)
            let endPubDate =
              hl.expiration_date.substr(0, 4) +
              "/" +
              hl.expiration_date.substr(4, 2) +
              "/" +
              hl.expiration_date.substr(6, 2)
            if (addFlg) {
              uploadList.push({
                checkFlg: false,
                taskName: hl.title, // 課題名
                mainQuestionInfo: {
                  // メイン情報
                  chapterId: hl.main_info[0].chapter_id, // 章ID
                  nodeId: hl.main_info[0].node_id, // 節ID
                  quesParentId: hl.main_info[0].ques_parent_id, // 親問題ID
                  fileName: hl.main_info[0].file_name, // ファイル名
                },
                subQuestionInfo: {
                  // サブ情報
                  teachingMaterials: hl.sub_info[0].teaching_materials, // 教材ID
                  filePath: hl.sub_info[0].file_path, // ファイルパス
                },
                sendMemo: hl.send_memo, // 送信メモ
                questionCount: 1, // 問題数
                ansDispFlg: false, // 答え表示フラグ
                explainDispFlg: false, // 解説表示フラグ

                homeworkEdaNo: "", // 宿題枝番
                haishinFileNo: hl.haishin_file_no, // 配信ファイル連番 新規の場合、0
                endUseDate: endUseDate, // 最終利用日
                endPubDate: endPubDate, // 有効期限
                fileStatusFlg: 1, // "0"：新規、"1"：再利用、"2"：削除
                updateDate: hl.update_date,
                deleteFlg: false,
              })
            }

            const updatedIndex = uploadList.findIndex((hdi) => {
              const existFlg =
                hl.sub_info[0].file_path == hdi.subQuestionInfo.filePath
              const updatedFlg = hl.haishin_file_no != hdi.haishinFileNo
              return existFlg && updatedFlg
            })

            if (updatedIndex != -1) {
              let updateItem = uploadList[updatedIndex]
              uploadList[updatedIndex] = {
                checkFlg: updateItem.checkFlg,
                taskName: updateItem.taskName,
                mainQuestionInfo: updateItem.mainQuestionInfo,
                subQuestionInfo: updateItem.subQuestionInfo,
                sendMemo: updateItem.sendMemo,
                questionCount: updateItem.questionCount,
                ansDispFlg: updateItem.ansDispFlg,
                explainDispFlg: updateItem.explainDispFlg,
                homeworkEdaNo: updateItem.homeworkEdaNo,
                haishinFileNo: hl.haishin_file_no,
                endUseDate: endUseDate,
                endPubDate: endPubDate,
                fileStatusFlg: updateItem.fileStatusFlg,
                updateDate: hl.update_date,
                deleteFlg: updateItem.deleteFlg,
              }
            }
            resolveFetch()
          })
        })
        Promise.all(promises).then(() => resolve(haishinList))
      })

      // 削除
      let aliveItems = []
      uploadList.forEach((hdi) => {
        const deleteFlg = haishinList.every((hl) => {
          return (
            hdi.subQuestionInfo.filePath !== hl.sub_info[0].file_path &&
            hdi.haishinFileNo !== 0
          )
        })
        if (!deleteFlg || haishinList.length == 0) {
          aliveItems.push(hdi)
        }
      })

      // listをソートする
      if (aliveItems && aliveItems.length > 1) {
        const dt = JSON.parse(JSON.stringify(aliveItems))
        let result = dt.sort(function (a, b) {
          const aData = new Date(a.updateDate)
          const bData = new Date(b.updateDate)
          return aData > bData ? -1 : 1 //オブジェクトの降順ソート
        })
        aliveItems = result
      }
      uploadList = aliveItems

      // store登録
      // 全てのチェックを一旦外す
      const list = uploadList.map((item) => {
        item = JSON.parse(JSON.stringify(item))
        item.checkFlg = false
        return item
      })
      await this.setUploadPdfDetailItems(list)
    },

    /**
     * モーダルタブ表示フラグを取得
     * /api/homework/SlctHaishinFileHisのモード0（アップロード一覧）では
     * 答え、解説フラグが空白のため、取得できる画像の有無からフラグの値を設定
     */
    getDispTabFlg: function (fileList) {
      let ret = {
        ansDispFlg: "",
        explainDispFlg: "",
      }

      ret.ansDispFlg = fileList.filter((item) => {
        // 前方一致"A{数値}"
        return item.file_name.match(/^A\d/)
      }).length
        ? 1
        : 0

      ret.explainDispFlg = fileList.filter((item) => {
        // 前方一致"E{数値}"
        return item.file_name.match(/^E\d/)
      }).length
        ? 1
        : 0

      return ret
    },

    /**
     * セッションが切れた際のログアウト処理
     */
    async onSessionDisconnect() {
      await this.setSessionDisconnectFalse()
      await this.clearSessionInfo()
      await this.$router.push({ name: "Login" })
    },

    // ファイルクリックイベント発火
    onClickFileSelect: function () {
      this.$refs.input.click()
    },

    // ファイルが選択されたとき
    readDecryptZip: async function (event) {
      if (this.showPreviewStdbQuestion || this.runningReadDecryptZip) {
        return
      }
      this.runningReadDecryptZip = true
      let is_uncompressed = true

      let files = event.target.files
        ? event.target.files
        : event.dataTransfer.files
      let file = files[0]
      let fileNameParts = file.name.split('.')
      let extension = fileNameParts.length < 2 ? '' : fileNameParts.pop().toLowerCase()

      // ファイルが複数の場合、
      if (files.length !== 1) {
        return
      }
      // 拡張子がオリジナル教材形式でない場合
      else if (
        extension !== "spo" &&
        extension !== "spr"
      ) {
        // 拡張子エラー
        this.showModalOkOnly(this.$t("messages.error.displaySTDBOnly"))
        this.runningReadDecryptZip = false
        return
      }

      if (file.size > 20971520) {
        const sizeMb = Math.round(file.size / 1000000)
        this.showModalOkOnly(
          this.$t("messages.error.over20MBsize", { fileSize: sizeMb })
        )
        this.runningReadDecryptZip = false
        return
      }

      // ファイル名を渡す(拡張子外す)
      this.taskName = fileNameParts.join('.');
      this.sendMemo = ""
      this.questionType = homeworkTypeCode.stdb

      const inFileReader = new FileReader()
      inFileReader.readAsDataURL(file)

      // プログレス開始
      this.isLoading = true

      await this.giveUncompRequest()

      //spo/sprファイルをアップロード
      await this.uploadSpoSpr(file)

      //処理結果取得
      do {
        if (this.judgeProcessing === undefined) {
          try {
            await this.getResultUncompDone(this.url4checkUncompResult)
          } catch (e) {
            is_uncompressed = false
          }
        } else {
          try {
            await this.wait(this.standbyTime * 1000) //Retry-Afterの秒数待ってから再実行
            await this.getResultUncompDone(this.url4checkUncompResult)
          } catch (e) {
            is_uncompressed = false
            this.judgeProcessing = false
          }
        }
      } while (this.judgeProcessing)

      try {
        if (is_uncompressed) {
          //ファイルをダウンロード、展開
          await this.openSpoSpr()
        } else {
          // プログレス終了
          this.isLoading = false
        }
      } catch (error) {
        // プログレス終了
        this.isLoading = false

        this.runningReadDecryptZip = false
        // ファイル形式エラー
        this.showModalOkOnly(this.$t("messages.error.stdbFileRead"))
      }

      // プログレスはModalPreviewオープン後に終了させる

      // 後処理
      event.target.value = ""
      this.runningReadDecryptZip = false
    },
    /**
     * ファイルサイズ再計算
     * エラーが発生した場合はfalseを返す
     */
    recalcFileSize: function (fileSize) {
      // ファイルアップロード後のAPI使用容量 = アップロードしようとしてる容量 + 今添付したファイル容量
      let afterUploadSize = Math.round(this.nowSaveSizeByte + fileSize)
      // アップロードを行うと全体容量のファイルサイズ上限を超える場合
      if (afterUploadSize >= this.maximumLimitSize * 1000000000) {
        this.showModalOkOnly(this.$t("messages.error.overMaximumLimitSize"))
        return false
      }

      this.nowSaveSizeByte = afterUploadSize
      this.stdbSaveSizeByte = Math.round(this.stdbSaveSizeByte + fileSize)

      this.nowSaveSize =
        Math.round((this.nowSaveSizeByte / 1000000000) * 100) / 100
      this.stdbSaveSize =
        Math.round((this.stdbSaveSizeByte / 1000000000) * 100) / 100
      this.nowSaveSize = this.nowSaveSize == 0 ? 0.01 : this.nowSaveSize
      this.stdbSaveSize = this.stdbSaveSize == 0 ? 0.01 : this.stdbSaveSize

      return true
    },

    /**
     * 配信データ上限チェック
     */
    onOverLimit: function () {
      let detailCount = this.currentHomeworkDetailItems.length
      // 配信データが30件を超える場合
      let detailMaxCount = this.homeworkMaximumLimitItems[1].items[0].value
      if (detailCount > detailMaxCount) {
        // エラー
        this.showModalOkOnly(
          this.$t("messages.error.overRegistMax", { maxCount: detailMaxCount })
        )
      }
      // TODO:STDBレイアウト通り5件の場合のチェックを追加
    },

    /**
     * 配信履歴一覧を取得（STDB）
     */
    getHomeworkStreamFileHistoryListForStdb: async function () {
      return await homeworkApi.getHomeworkStreamFileHistoryList(
        this.loginUserInfo.accountId, // アカウントID
        this.loginUserInfo.schoolId, // 学校ID
        homeworkTypeCode.stdbLayout, // 宿題種別
        this.loginUserInfo.lmsApiToken // APIトークン
      )
    },

    /**
     * 配信履歴一覧を取得（PDF）
     */
    getHomeworkStreamFileHistoryListForPdf: async function () {
      return await homeworkApi.getHomeworkStreamFileHistoryList(
        this.loginUserInfo.accountId, // アカウントID
        this.loginUserInfo.schoolId, // 学校ID
        homeworkTypeCode.pdf, // 宿題種別
        this.loginUserInfo.lmsApiToken // APIトークン
      )
    },

    /**
     * アップロード一覧取得
     */
    getHomeworkStreamFileInfo: async function (haishinFileNo) {
      return await homeworkApi.getHomeworkStreamFileInfo(
        this.loginUserInfo.accountId, // アカウントID
        this.loginUserInfo.schoolId, // 学校ID
        0, // モード
        0, // 宿題キー
        haishinFileNo, // 配信ファイル連番
        this.loginUserInfo.lmsApiToken // APIトークン
      )
    },

    /*
     * STDBモーダルによる登録処理
     */
    async onInputStdb(stdbDetail) {
      // セッション期間が有効かチェックする
      await this.checkSession()

      // ファイルサイズ計算
      let totalImageFileSize = 0
      const imgList = this.getImageList(stdbDetail)
      imgList.forEach((img) => {
        const blob = new Blob([img.file_image])
        totalImageFileSize += blob.size
      })

      // ファイルサイズ再計算
      if (!this.recalcFileSize(totalImageFileSize)) {
        return
      }

      // アップロード一覧追加
      // 公開終了日
      let periodTime = homeworkRepository.getPeriodDate(
        this.paramKeepPeriodItems[2].items[0].value
      )
      let strPeriodTime =
        periodTime.getFullYear() +
        "/" +
        ("00" + (periodTime.getMonth() + 1)).slice(-2) +
        "/" +
        ("00" + periodTime.getDate()).slice(-2)
      // 一意のフォルダ名 システム日付時間秒（ミリ秒単位）＋ランダム８桁英数字
      let filePath = "" + new Date().getTime() + this.getRandomAlphabetString(8)

      // アップロード一覧追加
      this.addUploadList(
        stdbDetail,
        strPeriodTime,
        filePath,
        totalImageFileSize
      )

      /// 配信データ登録
      if (stdbDetail.questionType === homeworkTypeCode.stdbLayout) {
        // レイアウト通り
        this.addStreamListLayout(stdbDetail, strPeriodTime, filePath)
      } else {
        // 一問ずつ登録
        this.addStreamList(stdbDetail, strPeriodTime, filePath)
      }
      // homeworkEdaNo等を振りなおす
      this.sortNumberReset()

      // リスト上限チェック
      this.onOverLimit()

      this.showPreviewStdbQuestion = false
    },
    /*
     * STDBモーダルの中断処理
     */
    onCloseInputStdb() {
      this.showPreviewStdbQuestion = false
    },

    /**
     * STDB問題数再設定処理
     */
    async resetQuesNumStdbQuestion(resetParam) {
      this.showResetQuesNumStdbQuestion = false
      if (this.tempStdbParam.item.questionType === homeworkTypeCode.stdb) {
        // １問ずつ
        await this.addStreamListReuse(
          this.tempStdbParam.item,
          this.tempStdbParam.strPeriodDate,
          this.tempStdbParam.filePath,
          resetParam
        )
      } else {
        await this.addStreamListLayoutReuse(
          this.tempStdbParam.item,
          this.tempStdbParam.strPeriodDate,
          this.tempStdbParam.filePath,
          resetParam
        )
      }
      // homeworkEdaNo等を振りなおす
      this.sortNumberReset()

      // 30件チェック
      this.onOverLimit()

      // uploadHomeworkDetailItemsの情報も更新
      this.uploadHomeworkDetailItems.forEach((item) => {
        if (item.subQuestionInfo.filePath === this.tempStdbParam.filePath) {
          this.$set(item, "questionCount", resetParam.questionQuantityList)
        }
      })

      // store登録
      // 全てのチェックを一旦外す
      const list = this.uploadHomeworkDetailItems.map((item) => {
        item = JSON.parse(JSON.stringify(item))
        item.checkFlg = false
        return item
      })
      await this.setUploadStdbDetailItems(list)

      // プログレス終了
      this.isLoading = false
    },
    /**
     * STDB問題数再設定中断処理
     */
    closeResetQuesNumStdbQuestion() {
      this.uploadHomeworkDetailItems[
        this.tempStdbParam.item.index
      ].checkFlg = false
      this.showResetQuesNumStdbQuestion = false
    },

    /**
     * homeworkEdaNo等再設定
     */
    sortNumberReset: function () {
      let streamList = JSON.parse(
        JSON.stringify(this.currentHomeworkDetailItems)
      )
      for (var i = 0; i < streamList.length; i++) {
        let item = streamList[i]
        const num = i + 1
        this.$set(item, "homeworkEdaNo", num)
        this.$set(item, "sortedDisplayNo", num)
      }

      this.currentHomeworkDetailItems = streamList
    },

    /**
     * APIで使用する形の画像List形式で取得
     */
    getImageList: function (stdbDetail) {
      if (stdbDetail.questionType === homeworkTypeCode.stdbLayout) {
        return stdbDetail.img
      }
      let retImgList = []
      for (var i = 0; i < stdbDetail.img.length; i++) {
        // 問題画像情報追加
        retImgList = retImgList.concat(stdbDetail.img[i])
      }
      return retImgList
    },

    /**
     * アップロード一覧追加
     */
    async addUploadList(stdbDetail, periodTime, filePath, fileSize) {
      let useDate = new Date()
      let strUseDate =
        useDate.getFullYear() +
        "/" +
        ("00" + (useDate.getMonth() + 1)).slice(-2) +
        "/" +
        ("00" + useDate.getDate()).slice(-2)

      let uploadList = JSON.parse(
        JSON.stringify(this.uploadHomeworkDetailItems)
      )
      uploadList.unshift({
        checkFlg: true,
        taskName: stdbDetail.taskName, // 課題名
        mainQuestionInfo: {
          // メイン情報
          chapterId: "", // 章ID
          nodeId: "", // 節ID
          quesParentId: "", // 親問題ID
          fileName: "", // ファイル名
        },
        subQuestionInfo: {
          // サブ情報
          teachingMaterials: "", // 教材ID
          filePath: filePath, // ファイルパス
        },
        questionType: stdbDetail.questionType, // 宿題種別
        sendMemo: stdbDetail.sendMemo, // 送信メモ
        homeworkEdaNo: 0, // 宿題枝番
        questionCount: stdbDetail.questionCount, // 問題数
        ansDispFlg: stdbDetail.ansDispFlg, // 答え表示フラグ
        explainDispFlg: stdbDetail.explainDispFlg, // 解説表示フラグ
        externalFilePath: "", // 外部ファイルパス
        haishinFileNo: 0, // 配信ファイル連番：追加時は0
        endUseDate: strUseDate, // 最終利用日
        endPubDate: periodTime, // 有効期限
        fileStatusFlg: 0, // "0"：新規、"1"：再利用、"2"：削除
        size: fileSize, // ファイルサイズ：APIに保存されない、新規追加時のサイズチェックでしか使わない値
        deleteFlg: false,
      })
      this.uploadHomeworkDetailItems = uploadList
      // store登録
      // 全てのチェックを一旦外す
      const list = this.uploadHomeworkDetailItems.map((item) => {
        item = JSON.parse(JSON.stringify(item))
        item.checkFlg = false
        return item
      })
      await this.setUploadStdbDetailItems(list)

      // indexedDB
      await this.uploadHomeworkDetailItems.map(async (item) => {
        await this.putUploadHomeworkDetailItems({
          id: item.subQuestionInfo.filePath,
          uploadHomeworkDetailItem: item,
        })
      })
      // 画像情報の登録(ファイルパス+課題名)
      await this.putIdbQuestionImage({
        id: filePath,
        uploadFileList: this.getImageList(stdbDetail),
      })
    },
    /**
     * 配信データ一覧（レイアウト通り）追加
     */
    addStreamListLayout(stdbDetail, periodTime, filePath) {
      // レイアウト通り
      let streamList = JSON.parse(
        JSON.stringify(this.currentHomeworkDetailItems)
      )
      let taskName = stdbDetail.taskName
      streamList.push({
        questionType: stdbDetail.questionType, // 宿題種別
        taskName: taskName, // 課題名
        mainQuestionInfo: {
          // メイン情報
          chapterId: "", // 章ID
          nodeId: "", // 節ID
          quesParentId: "", // 親問題ID
          fileName: "", // ファイル名
        },
        subQuestionInfo: {
          // サブ情報
          teachingMaterials: "", // 教材ID
          filePath: filePath, // ファイルパス
        },
        sendMemo: stdbDetail.sendMemo, // 送信メモ
        questionCount: stdbDetail.questionCount[0] || 1, // 問題数
        homeworkEdaNo: 0, // 宿題枝番
        sortedDisplayNo: 0, // 表示順
        sortedDisplaySuffixNumber: 0, // 表示枝番
        endPubDate: periodTime, // 有効期限
        fileNmSeq: 0, // ファイル名連番
        haishinFileNo: 0, // 配信ファイル連番：追加時は0固定
      })

      // store登録
      this.currentHomeworkDetailItems = streamList
    },

    /**
     * 配信データ一覧（一問ずつ）追加
     */
    addStreamList(stdbDetail, periodTime, filePath) {
      let streamList = JSON.parse(
        JSON.stringify(this.currentHomeworkDetailItems)
      )
      for (var i = 0; i < stdbDetail.img.length; i++) {
        let taskName = stdbDetail.taskName + "-[" + (i + 1) + "]"
        streamList.push({
          questionType: homeworkTypeCode.stdb, // 宿題種別
          taskName: taskName, // 課題名
          mainQuestionInfo: {
            // メイン情報
            chapterId: "", // 章ID
            nodeId: "", // 節ID
            quesParentId: "", // 親問題ID
            fileName: "", // ファイル名
          },
          subQuestionInfo: {
            // サブ情報
            teachingMaterials: "", // 教材ID
            filePath: filePath, // ファイルパス
          },
          sendMemo: stdbDetail.sendMemo, // 送信メモ
          questionCount: stdbDetail.questionCount[i] || 1, // 問題数
          sortedDisplayNo: 0, // 表示順
          sortedDisplaySuffixNumber: i, // 表示枝番
          endPubDate: periodTime, // 有効期限

          homeworkEdaNo: 0, // 宿題-枝番
          ansDispFlg: stdbDetail.ansDispFlg, // 答え表示フラグ
          explainDispFlg: stdbDetail.explainDispFlg, // 解説表示フラグ

          fileNmSeq: i + 1, // ファイル名連番
          haishinFileNo: 0, // 配信ファイル連番：追加時は0固定
        })
      }
      // store登録
      this.currentHomeworkDetailItems = streamList
    },

    /**
     * 配信データ一覧(レイアウト通り)追加(再利用登録)
     */
    async addStreamListLayoutReuse(
      stdbDetail,
      periodTime,
      filePath,
      resetParam
    ) {
      let streamList = JSON.parse(
        JSON.stringify(this.currentHomeworkDetailItems)
      )
      let taskName = stdbDetail.taskName
      streamList.push({
        questionType: homeworkTypeCode.stdbLayout, // 宿題種別
        taskName: taskName, // 課題名
        mainQuestionInfo: {
          // メイン情報
          chapterId: "", // 章ID
          nodeId: "", // 節ID
          quesParentId: "", // 親問題ID
          fileName: "", // ファイル名
        },
        subQuestionInfo: {
          // サブ情報
          teachingMaterials: "", // 教材ID
          filePath: filePath, // ファイルパス
        },
        sendMemo: stdbDetail.sendMemo, // 送信メモ
        questionCount: resetParam.questionQuantityList[0], // 問題数
        homeworkEdaNo: 0, // 宿題枝番
        sortedDisplayNo: 0, // 表示順
        sortedDisplaySuffixNumber: 0, // 表示枝番
        endPubDate: periodTime, // 有効期限
        fileNmSeq: 0, // ファイル名連番
        haishinFileNo: stdbDetail.haishinFileNo,
      })

      await this.putUploadHomeworkDetailItems({
        id: filePath,
        uploadHomeworkDetailItem: {
          ansDispFlg: stdbDetail.ansDispFlg,
          checkFlg: stdbDetail.checkFlg,
          endPubDate: stdbDetail.endPubDate,
          endUseDate: stdbDetail.endUseDate,
          explainDispFlg: stdbDetail.explainDispFlg,
          fileStatusFlg: stdbDetail.fileStatusFlg,
          haishinFileNo: stdbDetail.haishinFileNo,
          homeworkEdaNo: stdbDetail.homeworkEdaNo,
          mainQuestionInfo: stdbDetail.mainQuestionInfo,
          questionCount: resetParam.questionQuantityList,
          questionType: stdbDetail.questionType,
          sendMemo: stdbDetail.sendMemo,
          subQuestionInfo: stdbDetail.subQuestionInfo,
          taskName: stdbDetail.taskName,
          updateDate: stdbDetail.updateDate,
          deleteFlg: stdbDetail.deleteFlg,
        },
      })

      // store登録
      this.currentHomeworkDetailItems = streamList
    },

    /**
     * 配信データ一覧(一問ずつ)追加(再利用登録)
     */
    addStreamListReuse: async function (
      stdbDetail,
      periodTime,
      filePath,
      resetParam
    ) {
      let streamList = JSON.parse(
        JSON.stringify(this.currentHomeworkDetailItems)
      )
      // 親問題数を取得
      let imageQ = this.imageList.filter((item) => {
        // 前方一致"Q{数値}"
        return item.file_name.match(/^Q\d/)
      })
      for (var i = 0; i < imageQ.length; i++) {
        let taskName = resetParam.modalTaskName + "-[" + (i + 1) + "]"
        streamList.push({
          questionType: homeworkTypeCode.stdb, // 宿題種別
          taskName: taskName, // 課題名
          mainQuestionInfo: {
            // メイン情報
            chapterId: "", // 章ID
            nodeId: "", // 節ID
            quesParentId: "", // 親問題ID
            fileName: "", // ファイル名
          },
          subQuestionInfo: {
            // サブ情報
            teachingMaterials: "", // 教材ID
            filePath: filePath, // ファイルパス
          },
          sendMemo: resetParam.modalSendMemo, // 送信メモ
          questionCount: resetParam.questionQuantityList[i], // 問題数
          sortedDisplayNo: 0, // 表示順
          sortedDisplaySuffixNumber: i + 1, // 表示枝番
          endPubDate: periodTime, // 有効期限

          homeworkEdaNo: 0, // 宿題-枝番
          ansDispFlg: resetParam.answerValue, // 答え表示フラグ
          explainDispFlg: resetParam.explanationValue, // 解説表示フラグ

          haishinFileNo: stdbDetail.haishinFileNo, // 配信ファイル連番
          fileNmSeq: i + 1, // ファイル名連番
        })
      }

      await this.putUploadHomeworkDetailItems({
        id: filePath,
        uploadHomeworkDetailItem: {
          ansDispFlg: stdbDetail.ansDispFlg,
          checkFlg: stdbDetail.checkFlg,
          endPubDate: stdbDetail.endPubDate,
          endUseDate: stdbDetail.endUseDate,
          explainDispFlg: stdbDetail.explainDispFlg,
          fileStatusFlg: stdbDetail.fileStatusFlg,
          haishinFileNo: stdbDetail.haishinFileNo,
          homeworkEdaNo: stdbDetail.homeworkEdaNo,
          mainQuestionInfo: stdbDetail.mainQuestionInfo,
          questionCount: resetParam.questionQuantityList,
          questionType: stdbDetail.questionType,
          sendMemo: stdbDetail.sendMemo,
          subQuestionInfo: stdbDetail.subQuestionInfo,
          taskName: stdbDetail.taskName,
          updateDate: stdbDetail.updateDate,
          deleteFlg: stdbDetail.deleteFlg,
        },
      })

      // store登録
      this.currentHomeworkDetailItems = streamList
    },
    /**
     * アップロード一覧（IndexedDB）追加
     */
    async putUploadHomeworkDetailItems(item) {
      return await db.uploadHomeworkDetailItems.put(item)
    },
    /**
     * アップロード一覧（IndexedDB）取得
     */
    async getUploadHomeworkDetailItems(filePath) {
      return await db.uploadHomeworkDetailItems.get(filePath)
    },
    /**
     * アップロード一覧（IndexedDB）削除
     */
    async deleteUploadHomeworkDetailItems(filePath) {
      return await db.uploadHomeworkDetailItems.delete(filePath)
    },
    /**
     * 画像一覧（IndexedDB）追加
     */
    async putIdbQuestionImage(item) {
      return await db.idbQuestionImage.put(item)
    },
    /**
     * 画像一覧（IndexedDB）取得
     */
    async getIdbQuestionImage(filePath) {
      return await db.idbQuestionImage.get(filePath)
    },
    /**
     * 画像一覧（IndexedDB）削除
     */
    async deleteIdbQuestionImage(filePath) {
      return await db.idbQuestionImage.delete(filePath)
    },

    /**
     * アップロード一覧変更処理
     */
    changedUploadDataList(uploadDataList) {
      this.uploadHomeworkDetailItems = uploadDataList
    },

    /**
     * 配信データ一覧変更処理
     */
    changedStreamDataList(streamDataList) {
      this.currentHomeworkDetailItems = streamDataList
    },

    /**
     * アップロード一覧チェック操作
     */
    checkUploadTableRow: async function (event) {
      let item = event.item

      let uploadList = JSON.parse(
        JSON.stringify(this.uploadHomeworkDetailItems)
      )
      let streamList = JSON.parse(
        JSON.stringify(this.currentHomeworkDetailItems)
      )

      // チェック内容更新
      const uploadIndex = uploadList.findIndex(
        (item) =>
          item.subQuestionInfo.filePath ===
            event.item.subQuestionInfo.filePath &&
          item.taskName === event.item.taskName
      )
      uploadList[uploadIndex].checkFlg = event.check

      if (event.check) {
        // チェックされた場合

        // プログレス開始
        this.isLoading = true

        let streamFileInfo = await this.getHomeworkStreamFileInfo(
          item.haishinFileNo
        )
        if (item.haishinFileNo != 0) {
          let previewInfoList = streamFileInfo.data.data.preview_info_list[0]
          item.questionType = previewInfoList.homework_syubetu_kbn
        }

        // 最終利用日更新・公開終了日
        uploadList[uploadIndex].endUseDate = new Date().toLocaleDateString()
        const periodDate = homeworkRepository.getPeriodDate(
          this.paramKeepPeriodItems[2].items[0].value
        )
        const strPeriodDate =
          periodDate.getFullYear() +
          "/" +
          ("00" + (periodDate.getMonth() + 1)).slice(-2) +
          "/" +
          ("00" + periodDate.getDate()).slice(-2)
        uploadList[uploadIndex].endPubDate = strPeriodDate
        const questionType = item.questionType
        uploadList[uploadIndex].questionType = questionType

        let img = []
        let key = item.subQuestionInfo.filePath
        this.tempFilePath = key

        //問題数設定のモーダルを出して、ここに対応させる
        this.taskName = item.taskName
        this.sendMemo = item.sendMemo
        this.questionType = homeworkTypeCode.stdb

        if (0 === item.haishinFileNo) {
          // 0：新規追加の場合はIndexedDBから取得
          this.imageList = (
            await db.idbQuestionImage.get(item.subQuestionInfo.filePath)
          ).uploadFileList
        } else {
          // 0以外：APIを実行し取得
          this.imageList = await this.getApiImageList(streamFileInfo)

          // 画像情報が読み込めなかった場合配信終了エラー表示
          if (this.imageList === null) {
            // メッセージ出力
            this.showModalOkOnly(
              this.$t("messages.error.uploadPublicEndPreview")
            )
            this.isLoading = false
            return
          }
        }
        this.showResetQuesNumStdbQuestion = true
        this.tempStdbParam = {
          item: item,
          strPeriodDate: strPeriodDate,
          filePath: item.subQuestionInfo.filePath,
        }
        // 一問ずつ
        item["img"] = img
        this.addStreamList(item, item.endPubDate, item.subQuestionInfo.filePath)

        // homeworkEdaNo等を振りなおす
        this.sortNumberReset()
      } else {
        // チェック外れた場合

        // アップロード情報に関連する配信データを削除
        streamList = streamList.filter((elem) => {
          // ファイルステータスが再利用の場合、ファイルパスとファイル名を比較
          return elem.subQuestionInfo.filePath !== item.subQuestionInfo.filePath
        })
        this.currentHomeworkDetailItems = JSON.parse(JSON.stringify(streamList))
      }

      this.uploadHomeworkDetailItems = JSON.parse(JSON.stringify(uploadList))

      // プログレス終了
      this.isLoading = false
    },

    /**
     * IndexedDBから一問ずつ形式の情報を取得
     */
    getIdbSTDBImageList: async function (key, homeworkType) {
      let retImageList = []

      let res = (await this.getIdbQuestionImage(key)).uploadFileList
      if (homeworkType === homeworkTypeCode.stdbLayout) {
        return res
      }
      let imageQ = []
      let imageA = []
      let imageE = []
      for (let i = 0; i < res.length; i++) {
        // 頭文字Qの場合
        if (/^Q/.test(res[i].file_name)) {
          imageQ.push(res[i])
        }
        // 頭文字Aの場合
        if (/^A/.test(res[i].file_name)) {
          imageA.push(res[i])
        }
        // 頭文字Eの場合
        if (/^E/.test(res[i].file_name)) {
          imageE.push(res[i])
        }
      }
      for (let i = 0; i < imageQ.length; i++) {
        let img = []
        img.push(imageQ[i])
        if (imageA.length) img.push(imageA[i])
        if (imageE.length) img.push(imageE[i])

        retImageList.push(img)
      }
      return retImageList
    },

    /**
     * 画像リストを取得
     */
    getApiImageList: async function (streamFileInfo) {
      if (streamFileInfo.status !== 200) {
        // ファイル保持期限切れ
        return null
      }
      let fileList = streamFileInfo.data.data.upload_file_list
      let fileImgList = []
      for (let i = 0; i < fileList.length; i++) {
        let item = fileList[i]
        let url = item.file_path
        let base64 = await this.downloadImageToBase64(url)

        fileImgList.push({
          file_image: base64,
          file_name: item.file_name,
        })
      }
      return fileImgList
    },
    /**
     * 画像ファイルダウンロード
     */
    downloadImageToBase64: async function (externalUrl) {
      let retBase64 = null
      try {
        const response = await axios.get(externalUrl, {
          responseType: "arraybuffer",
        })
        // arrayBuffer -> blob
        const blob = new Blob([response.data], { type: "image/png" })

        // blob -> base64
        await new Promise((res) => {
          let reader = new FileReader()
          reader.onload = () => {
            res(reader.result)
          }
          reader.readAsDataURL(blob)
        }).then((ret) => {
          retBase64 = ret
        })
      } catch (ex) {
        // 空情報を返す（配信期限切れ）
        console.log(ex)
      }

      return retBase64
    },

    /**
     * アップロード一覧削除処理
     */
    deleteUploadTableRow: async function (deleteRowItem) {
      // アップロード一覧
      let uploadList = JSON.parse(
        JSON.stringify(this.uploadHomeworkDetailItems)
      )

      let aliveList = uploadList.filter((item) => item.deleteFlg == false)
      const index = uploadList.findIndex(
        (item) =>
          item.subQuestionInfo.filePath ===
          deleteRowItem.subQuestionInfo.filePath
      )

      // index位置の情報を1件取り除く
      if (0 <= index) {
        const avgStdbSaveSizeByte = Math.round(
          this.stdbSaveSizeByte / aliveList.length
        )
        // indexが0より大きい場合(一致あり)のみ処理
        if (uploadList[index].haishinFileNo === 0) {
          uploadList.splice(index, 1)
        } else {
          uploadList[index].deleteFlg = true
        }
        this.stdbSaveSizeByte = avgStdbSaveSizeByte * (aliveList.length - 1)
        this.uploadHomeworkDetailItems = uploadList
        // store登録
        // 全てのチェックを一旦外す
        const list = this.uploadHomeworkDetailItems.map((item) => {
          item = JSON.parse(JSON.stringify(item))
          item.checkFlg = false
          return item
        })
        await this.setUploadStdbDetailItems(list)
      }
      if (aliveList.length - 1 === 0) {
        this.nowSaveSize = this.pdfSaveSize
        this.nowSaveSizeByte = this.nowSaveSize * 1000000000
        this.stdbSaveSize = 0
        this.stdbSaveSizeByte = 0
      } else {
        this.stdbSaveSize =
          Math.round((this.stdbSaveSizeByte / 1000000000) * 100) / 100
        this.stdbSaveSize = this.stdbSaveSize == 0 ? 0.01 : this.stdbSaveSize
        this.nowSaveSizeByte = Math.round(this.stdbSaveSizeByte + this.pdfSaveSizeByte)
        this.nowSaveSize = Math.round((this.nowSaveSizeByte / 1000000000) * 100) / 100
      }

      // 配信一覧
      let isDelete = this.headerInfo.homeworkStatus === streamStatus.creating
      if (isDelete) {
        // 配信ない場合は削除
        let streamList = JSON.parse(
          JSON.stringify(this.currentHomeworkDetailItems)
        )
        let itemKey = deleteRowItem.subQuestionInfo.filePath
        streamList = streamList.filter((elem) => {
          return elem.subQuestionInfo.filePath !== itemKey
        })
        this.currentHomeworkDetailItems = streamList
      }
      await this.createHomeworkFileHistoryList()
    },

    /*
     * 配信テーブル行削除
     */
    deleteStreamTableRow: function (deleteRowItem) {
      // 配信一覧から削除
      let streamList = JSON.parse(
        JSON.stringify(this.currentHomeworkDetailItems)
      )
      streamList.splice(deleteRowItem.index, 1)
      this.currentHomeworkDetailItems = streamList
      const streamIndex = this.currentHomeworkDetailItems.findIndex(
        (item) =>
          item.subQuestionInfo.filePath ===
          deleteRowItem.subQuestionInfo.filePath
      )
      // 配信一覧に同一ファイルの親問題がある場合(ない場合は-1が返る)
      if (0 <= streamIndex) {
        // 処理終了
        return
      }

      // 対象アップロードファイルのチェックを外す
      let uploadList = JSON.parse(
        JSON.stringify(this.uploadHomeworkDetailItems)
      )
      const uploadIndex = this.uploadHomeworkDetailItems.findIndex(
        (item) =>
          item.subQuestionInfo.filePath ===
          deleteRowItem.subQuestionInfo.filePath
      )
      uploadList[uploadIndex].checkFlg = false
      this.uploadHomeworkDetailItems = uploadList
    },

    /**
     * 登録押下
     */
    onClickRegister: async function () {
      // 登録チェック
      if (this.isRegistError()) {
        return
      }

      // 宿題設定リスト作成
      this.createHomeworkQuesSetList()

      // 宿題ファイルリスト作成
      await this.createHomeworkFileHistoryList()

      // 配信データを追加(既存の配信データよりSTDB情報を全削除し、今回登録データを追加)
      this.setHomeworkDetailItems({
        questionType: homeworkTypeCode.stdb,
        homeworkDetailItems: this.currentHomeworkDetailItems,
      })

      // アップロード一覧の存在チェック
      if (await this.notExistCheckForUploadList()) {
        this.isLoading = false
        return
      }

      this.$router.push({ name: "HomeworkRegister" })
    },

    /**
     * 登録チェック
     * return: エラーがある場合true
     */
    isRegistError: function () {
      // 配信リストが30件を超える場合
      let detailMaxCount = this.homeworkMaximumLimitItems[1].items[0].value
      if (detailMaxCount < this.currentHomeworkDetailItems.length) {
        // メッセージ出力
        this.showModalOkOnly(
          this.$t("messages.error.overRegistMax", { maxCount: detailMaxCount })
        )
        return true
      }
      // TODO:STDBレイアウト通り5件の場合のチェックを追加

      // 配信データ一覧のレコードをアップロード一覧のデータに含まれているかチェック（整合性チェック）
      let errorFiles = []
      this.currentHomeworkDetailItems.some((streamItem) => {
        const hitIndex = this.uploadHomeworkDetailItems.findIndex(
          (uploadItem) =>
            uploadItem.subQuestionInfo.filePath ===
            streamItem.subQuestionInfo.filePath
        )
        if (hitIndex < 0) {
          errorFiles.push("・" + streamItem.taskName)
        }
      })
      if (0 < errorFiles.length) {
        // メッセージ出力
        this.showModalOkOnly(
          this.$t("messages.error.uploadPublicEndRegist", {
            fileNames: errorFiles.join("\n"),
          })
        )
        return true
      }
      return false
    },

    /**
     * 宿題問題リスト作成
     */
    createHomeworkQuesSetList: function () {
      let setList = JSON.parse(JSON.stringify(this.homeworkSetItems))
      let stdbList = JSON.parse(JSON.stringify(this.homeworkSetItems))
      // STDBのみ排除
      setList = setList.filter((item) => {
        return (
          item.question_type !== homeworkTypeCode.stdb &&
          item.question_type !== homeworkTypeCode.stdbLayout
        )
      })
      // STDBのみ抽出
      stdbList = stdbList.filter((item) => {
        return (
          item.question_type === homeworkTypeCode.stdb ||
          item.question_type === homeworkTypeCode.stdbLayout
        )
      })
      var hwList = this.currentHomeworkDetailItems
      for (let i = 0; i < hwList.length; i++) {
        let item = hwList[i]
        const existedStdb = stdbList.find(stdb => stdb.file_path == item.subQuestionInfo.filePath)
        setList.push({
          question_type: item.questionType, // 宿題種別(API登録には含めない：登録時の排除に使用)
          file_path: item.subQuestionInfo.filePath, // ファイルパス(API登録には含めない：登録時の排除に使用)
          homework_eda_no: setList.length + 1, // 宿題連番
          ans_disp_flg: !existedStdb ? item.ansDispFlg : existedStdb.ans_disp_flg, // 答え表示フラグ
          explain_disp_flg: !existedStdb ? item.explainDispFlg  : existedStdb.explain_disp_flg, // 解説表示フラグ
          fileNmSeq: item.fileNmSeq, // ファイル連番
        })
      }
      this.setHomeworkSetItems(setList)
    },

    /**
     * 宿題ファイルリスト作成
     */
    createHomeworkFileHistoryList: async function () {
      let fileList = []
      // アップロード分ループを回して宿題ファイル作成
      let willUploadItems = this.uploadHomeworkDetailItems.filter(
        (item) => item.checkFlg === true
      )
      willUploadItems.forEach((item) => {
        // 新規（登録なし、アップロードあり）
        let setParam = {}
        if (0 === item.haishinFileNo) {
          setParam = {
            haishinFileNo: item.haishinFileNo,
            fileStatusFlg: 0,
          }
        } else {
          // 再利用
          setParam = {
            haishinFileNo: item.haishinFileNo,
            fileStatusFlg: 1,
          }
        }
        fileList.push({
          haishin_file_no: setParam.haishinFileNo, // 配信ファイル連番：ファイルステータスフラグが"0"：新規の場合、0
          homework_syubetu_kbn: item.questionType, // 宿題種別
          title: item.taskName, // 課題名
          main_info: [
            {
              // メイン情報
              chapter_id: item.mainQuestionInfo.chapterId, // 章ID
              node_id: item.mainQuestionInfo.nodeId, // 節ID
              ques_parent_id: item.mainQuestionInfo.quesParentId, // 親問題ID
              file_name: item.mainQuestionInfo.fileName, // ファイル名
            },
          ],
          sub_info: [
            {
              // サブ情報
              teaching_materials: item.subQuestionInfo.teachingMaterials, // 教材ID
              file_path: item.subQuestionInfo.filePath, // ファイルパス
            },
          ],
          send_memo: item.sendMemo, // 送信メモ
          expiration_date: item.endPubDate.replaceAll("/", ""), // 有効期限
          file_status_flg: setParam.fileStatusFlg, // "0"：新規、"1"：再利用、"2"：削除

          // ※Storeに画像情報を設定すると容量エラーとなるため、登録直前でIndexedDBから取得すること
          upload_file_list: new Array(),
        })
      })

      // 削除データ生成
      let delHaishinItems = []
      let willDeleteItems = this.uploadHomeworkDetailItems.filter(
        (item) => item.deleteFlg === true
      )
      willDeleteItems.forEach((item) => {
        delHaishinItems.push({
          title: item.taskName, // 課題名
          expiration_date: item.expiration_date,
          file_status_flg: 2, // "0"：新規、"1"：再利用、"2"：削除
          haishin_file_no: item.haishinFileNo,
          homework_syubetu_kbn: homeworkTypeCode.stdbLayout,
          main_info: [
            {
              // メイン情報
              chapter_id: item.mainQuestionInfo.chapterId, // 章ID
              node_id: item.mainQuestionInfo.nodeId, // 節ID
              ques_parent_id: item.mainQuestionInfo.quesParentId, // 親問題ID
              file_name: item.mainQuestionInfo.fileName, // ファイル名
            },
          ],
          sub_info: [
            {
              // サブ情報
              teaching_materials: item.subQuestionInfo.teachingMaterials, // 教材ID
              file_path: item.subQuestionInfo.filePath, // ファイルパス
            },
          ],
          send_memo: item.sendMemo,
          upload_file_list: [],
        })
      })

      let fileHisList = JSON.parse(
        JSON.stringify(this.homeworkFileHistoryItems)
      )
      fileHisList = fileHisList.filter((item) => {
        return (
          item.homework_syubetu_kbn !== homeworkTypeCode.stdb &&
          item.homework_syubetu_kbn !== homeworkTypeCode.stdbLayout
        )
      })
      fileHisList = fileHisList.concat(delHaishinItems).concat(fileList)

      this.setHomeworkFileHistoryItems(fileHisList)
    },

    /**
     * キャンセル押下
     */
    async onClickCancel() {
      // 全てのチェックを一旦外す
      const list = this.uploadHomeworkDetailItems.map((item) => {
        item = JSON.parse(JSON.stringify(item))
        item.checkFlg = false
        return item
      })
      await this.setUploadStdbDetailItems(list)

      this.$router.push({ name: "HomeworkRegister" })
    },

    /**
     * OKだけのモーダルを表示する
     */
    showModalOkOnly: function (message) {
      this.cautionMessage = message
      this.showConfirmCaution = true
    },

    /**
     * zip解凍ファイルURLリクエスト
     */
    async giveUncompRequest() {
      const promise = await homeworkApi.giveUncompRequest(
        this.loginUserInfo.accountId,
        this.loginUserInfo.sessionToken
      )
      if (promise.status == "202") {
        this.url4upload = promise.data.file.upload_url
        this.url4checkUncompResult = promise.headers.location
      } else {
        if (promise.status == "400") {
          //専用のhtmlを表示
          location.href = "400.html"
        } else if (promise.status == "401") {
          //ログイン画面へリダイレクト
          await this.$router.push({ name: "Login" })
        } else if (promise.addStreamListLayoutReuse == "500") {
          // メッセージ出力
          this.showModalOkOnly(
            this.$t("messages.error.overFileSizeForDownload")
          )
        } else if (promise.status == "503") {
          //専用のHTMLを表示
          location.href = "503.html"
        } else {
          console.log("不明なエラー")
        }
      }
    },

    /**
     * spo/sprをアップロード
     */
    async uploadSpoSpr(file) {
      const promise = await homeworkApi.uploadSpoSpr(this.url4upload, file)
      if (promise === undefined) {
        this.showModalOkOnly(this.$t("messages.error.overFileSizeForDownload"))
        return
      }
      if (promise.status !== 200) {
        // 通信失敗時の処理
        this.showModalOkOnly("ファイルのアップロードに失敗しました")
      }
    },

    /**
     * zip解凍パスワードリダイレクトURL取得処理
     */
    async getResultUncompDone(url4check) {
      const promise = await homeworkApi.getUncompressingsURL(
        url4check,
        this.loginUserInfo.sessionToken
      )

      if (promise.status == "202") {
        // ZIP処理が継続中の場合
        this.standbyTime = promise.headers["retry-after"]
        this.judgeProcessing = true
        return
      } else if (promise.status == "200") {
        this.judgeProcessing = false
        this.url4download = promise.request.responseURL
        return
      }

      // 通信失敗時の処理
      var contentType = promise.headers["content-type"]
      if (
        promise.status === 400 &&
        contentType.toLowerCase().indexOf("application/xml") >= 0
      ) {
        this.judgeProcessing = false
        this.url4download = promise.request.responseURL
        return
      }

      if (promise.status == "400") {
        //専用のhtmlを表示
        location.href = "400.html"
      } else if (promise.status == "401") {
        //ログイン画面へリダイレクト
        await this.$router.push({ name: "Login" })
      } else if (promise.status == "403") {
        // ・数研アカウントIDと内部的に利用しているZIP解凍処理の番号の組み合わせが一致しない場合
        // ・拡張子エラー
        this.showModalOkOnly(
          "ファイルが開けませんでした。（403）<br>もう一度ファイルを選択してください。"
        )
        throw new Error("zip uncomp status error(403)")
      } else if (promise.status == "404") {
        //ZIP処理が存在しない場合
        this.showModalOkOnly(this.$t("messages.error.stdbFileRead"))
        throw new Error("not found methods error(404)")
      } else if (promise.status == "500") {
        this.showModalOkOnly(this.$t("messages.error.overFileSizeForDownload"))
        throw new Error("over file size error(500)")
      } else {
        throw new Error("不明なエラー")
      }
    },

    /**
     * ZIPファイルダウンロード
     */
    async downloadSpoSpr() {
      return new Promise((resolve, reject) => {
        axios
          .get(this.url4download, {
            dataType: "binary",
            responseType: "blob",
            "Content-Type": "application/zip",
          })
          .then((response) => {
            resolve(response.data)
          })
          .catch(() => {
            this.showModalOkOnly("ファイルのダウンロードに失敗しました")
            reject()
          })
      })
    },

    /**
     * SPO,SPRファイルの展開
     */
    async openSpoSpr() {
      // ファイル読み込み後
      const regexpTxtFile = /\.(txt)/
      const regexpImgFile = /\.(png)/

      const spo_spr_file = await this.downloadSpoSpr()

      // Zipファイル読み込み
      JSZip.loadAsync(spo_spr_file)
        .then((result) => {
          let promisesTxtRead = []
          let promisesImgRead = []
          // テキストファイルのみを抽出
          result.file(regexpTxtFile).forEach((file_list_txt) => {
            // テキストファイルを読み込む
            let filename = file_list_txt.name.match(/([^/]*)\./)[1]
            promisesTxtRead.push(
              file_list_txt.async("string").then((result) => {
                // key : Document, E, QE, QA, Q, A
                // list : 各種テキストの中身
                return { key: filename, list: result }
              })
            )
          })
          if (promisesTxtRead.length <= 0) {
            // テキストファイルが存在しない
            // ファイル形式エラー
            this.showModalOkOnly(
              this.$t("messages.error.notSelectedPresentation")
            )
            this.isLoading = false
            throw new Error("ファイル形式エラー")
          }
          Promise.all(promisesTxtRead).then((txt_promise_result) => {
            txt_promise_result.forEach((data) => {
              // テキストの中身のファイルを読み込む
              data.list.split(/[\r\n|\n]/).forEach((file) => {
                // 画像パスを読み込む
                result.file(regexpImgFile).forEach((image_data) => {
                  // テキストの中身と画像パスが一致する画像情報をリストに追加
                  if (image_data.name === "Document/" + file) {
                    promisesImgRead.push(
                      image_data.async("blob").then(async (image_blob) => {
                        // 受信した画像情報をBase64に変換
                        let blob = new Blob([image_blob], { type: "image/png" })
                        let img_b64
                        await new Promise((resolve) => {
                          let reader = new FileReader()
                          reader.onload = () => {
                            resolve(reader.result)
                          }
                          reader.readAsDataURL(blob)
                        }).then((ret) => {
                          img_b64 = ret
                        })

                        return { file_name: file, file_image: img_b64 }
                      })
                    )
                  }
                })
              })
            })
            // 画像の読み込みが終了したら実行
            Promise.all(promisesImgRead).then((result) => {
              this.imageList = result

              // プレビューダイアログの表示
              this.showPreviewStdbQuestion = true
            })
          })
        })
        .catch((error) => {
          throw error
        })
    },

    wait: function (time) {
      return new Promise((resolve) => {
        setTimeout(resolve, time)
      })
    },

    /**
     * アップロード一覧の存在チェックを行う（true: 存在しない, false: 存在する）
     */
    async notExistCheckForUploadList() {
      if (this.homeworkFileHistoryItems.length > 0) {
        let filteredHomeworkFileHistoryItems =
          this.homeworkFileHistoryItems.filter(
            (hisItem) => hisItem.file_status_flg !== 0
          )

        if (filteredHomeworkFileHistoryItems.length > 0) {
          // 再利用または削除予定のアップロード一覧が1つでも存在する場合存在チェックを行う

          let deletedList = []

          // STDBの要素のうち既に存在しない配信ファイルを使用している宿題がないかをチェック
          if (filteredHomeworkFileHistoryItems.length > 0) {
            const result = await homeworkApi.getHomeworkStreamFileHistoryList(
              this.loginUserInfo.accountId, // アカウントID
              this.loginUserInfo.schoolId, // 学校ID
              homeworkTypeCode.stdbLayout, // 宿題種別
              this.loginUserInfo.lmsApiToken // APIトークン
            )
            let haishinList = result.data.data.haishinfile_history_list
            const deletedItems = filteredHomeworkFileHistoryItems.filter(
              (item) => {
                return haishinList.every((elem) => {
                  return elem.haishin_file_no != item.haishin_file_no
                })
              }
            )
            deletedList = deletedList.concat(deletedItems)
          }
          if (deletedList.length > 0) {
            let filteredHomeworkDetailItems = this.homeworkDetailItems.filter(
              (item) => {
                return (
                  item.questionType != homeworkTypeCode.textbook &&
                  item.questionType != homeworkTypeCode.pdf
                )
              }
            )
            let deleteTasks = filteredHomeworkDetailItems.filter((item) => {
              return deletedList.some((elem) => {
                return (
                  elem.sub_info[0].file_path == item.subQuestionInfo.filePath
                )
              })
            })

            if (deleteTasks.length > 0) {
              let taskNames = ""
              deleteTasks.forEach((item) => {
                taskNames += "・" + item.taskName + "\n"
              })
              // エラーメッセージ表示
              this.showModalOkOnly(
                this.$t("messages.error.notExistUploadFileForPublishOrSave", {
                  taskName: taskNames,
                })
              )
              return true
            }
          }
        }
      }
      return false
    },
    /**
     * ファイルをドラッグアンドドロップした時の処理
     */
    dragAndDropSelectedFile(e) {
      e.preventDefault()
      if (!this.$el.querySelector(".file-select").contains(e.target)) {
        e.dataTransfer.dropEffect = "none"
        return
      }
      e.stopPropagation()
      e.dataTransfer.dropEffect = "copy"
    },
    onPreviewOpen() {
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.button-file-select {
  display: inline;

  .btn-sm {
    padding: 0rem 0.5rem;
    border-radius: 2rem;
  }
}

.explanation {
  margin-top: 1em;
  background: url(../assets/images/arrow_blue.png) center center/auto 100%
    no-repeat;
  padding: 1.3em 0 1.3em 60%;
  @media (max-width: 1280px) {
    background-size: 15%;
  }
}

.capacity-bar {
  position: relative;
  justify-content: space-between;
  display: flex;
  align-items: center;
}
</style>
