var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "question" },
    [
      _c("div", { staticClass: "d-flex" }, [
        _c("div", { staticClass: "flex-grow-1 px-4 py-3 bd-highlight" }, [
          _c("div", { staticClass: "mb-4" }, [_c("TitleQuestion")], 1),
          _c("div", { staticClass: "d-flex align-item-center mt-3 mb-4" }, [
            _c(
              "div",
              { staticClass: "me-3 group-text-area" },
              [
                _c("FormSelectBox", {
                  ref: "groupSelectBox",
                  attrs: {
                    id: "selectedGroupId",
                    "label-text": _vm.$t("labels.group"),
                    options: _vm.groupSelect,
                    "initial-value": "0"
                  },
                  on: { input: _vm.onChangeGroup }
                })
              ],
              1
            ),
            _c(
              "div",
              [
                _c("FormSelectBox", {
                  ref: "bookSelectBox",
                  attrs: {
                    id: "selectedBookId",
                    "label-text": _vm.$t("labels.textbook"),
                    options: _vm.bookSelect,
                    "initial-value": "0"
                  },
                  on: { input: _vm.onChangeBook }
                })
              ],
              1
            )
          ]),
          _vm.showCanNotChangeTextbookFlg
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("messages.error.canNotChangeSelectedTextbook")
                    ) +
                    " "
                )
              ])
            : _vm._e(),
          _vm.showCanNotGetTextbook
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("messages.error.canNotGetTextbook")) + " "
                )
              ])
            : _vm._e(),
          _vm.showCanNotGetStudent
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("messages.error.canNotGetStudent")) + " "
                )
              ])
            : _vm._e(),
          _vm.isSelectedDropDownList && !_vm.showCanNotChangeTextbookFlg
            ? _c("div", [
                _vm.filterList
                  ? _c("div", [
                      _c("div", [
                        _c("label", { staticClass: "form-label" }, [
                          _vm._v("設定項目")
                        ]),
                        _vm.filterList
                          ? _c(
                              "ul",
                              { staticClass: "d-flex mb-4" },
                              _vm._l(_vm.filterList, function(item) {
                                return _c(
                                  "SettingFilterTab",
                                  _vm._b(
                                    {
                                      key: item.id,
                                      staticClass: "setting-filter",
                                      on: { input: _vm.onChangedTab },
                                      model: {
                                        value: _vm.currentId,
                                        callback: function($$v) {
                                          _vm.currentId = $$v
                                        },
                                        expression: "currentId"
                                      }
                                    },
                                    "SettingFilterTab",
                                    item,
                                    false
                                  )
                                )
                              }),
                              1
                            )
                          : _vm._e()
                      ]),
                      _c(
                        "div",
                        { staticClass: "ques-wrap justify-content-center row" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "col-4 align-self-stretch tree-wrap"
                            },
                            [
                              _c(
                                "TreeQuestion",
                                _vm._g(
                                  {
                                    key: _vm.currentId,
                                    ref: "treeQuestion",
                                    staticClass: "tree",
                                    attrs: {
                                      list: _vm.questionLists[_vm.currentId],
                                      "display-all-check-flg": true,
                                      "grayout-hidden-mode": true
                                    }
                                  },
                                  {
                                    "clicked-question-title":
                                      _vm.onClickedQuestionTitle,
                                    "change-preview-tabs":
                                      _vm.changePreviewAreaTabs,
                                    "change-display-message":
                                      _vm.onChangeMessageDisplayFlag,
                                    "clicked-select-all": _vm.onClickSelectAll,
                                    "on-dirty": _vm.onDirty
                                  }
                                )
                              )
                            ],
                            1
                          ),
                          _c("PreviewContainer", {
                            ref: "previewContainer",
                            attrs: {
                              image: _vm.image,
                              "setting-list": _vm.settingList,
                              "message-display-flg": _vm.messageDisplayFlg
                            }
                          }),
                          _c("div", { staticClass: "row mb-1" }, [
                            _c("div", { staticClass: "col" }, [
                              _c(
                                "label",
                                {
                                  staticClass: "text-end w-100",
                                  staticStyle: { "font-size": "85%" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "labels.questionManagementMessage"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ])
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row gx-5 justify-content-center mt-4"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-auto" },
                                [
                                  _c("ButtonBorderCircleMedium", {
                                    attrs: {
                                      "label-name": "キャンセル",
                                      "label-color": "#ff7f27",
                                      "border-color": "#ff7f27"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.onCancel.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "col-auto" },
                                [
                                  _c("ButtonBorderCircleMedium", {
                                    attrs: {
                                      "label-name": "登録",
                                      color: "layout-theme-light",
                                      "label-color": "#ff7f27",
                                      "border-color": "#ff7f27",
                                      disabled: !_vm.filterList
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.onRegisterQuestion.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  : _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "messages.error.notChangeSettingsSelectedMaterial"
                            )
                          ) +
                          " "
                      )
                    ])
              ])
            : _vm._e()
        ])
      ]),
      _vm.isLoading ? _c("LoadingScreen") : _vm._e(),
      _vm.sessionDisconnect
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.$t("messages.error.overLimitSessionToken") },
            on: {
              "close-confirm-modal-ok-only": function($event) {
                return _vm.onSessionDisconnect()
              }
            }
          })
        : _vm._e(),
      _vm.showChangedGroups
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.$t("messages.error.changedGroup") },
            on: {
              "close-confirm-modal-ok-only": function($event) {
                return _vm.onOkCloseShowChangedGroups()
              }
            }
          })
        : _vm._e(),
      _vm.displayRegisterWithCaution
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.$t("messages.success.registerWithCaution") },
            on: {
              "close-confirm-modal-ok-only": function() {
                _vm.displayRegisterWithCaution = false
              }
            }
          })
        : _vm._e(),
      _vm.displayConfirmBookChange
        ? _c(
            "ModalConfirm",
            _vm._g(
              {
                attrs: { message: _vm.$t("messages.confirm.notSavedChanges") }
              },
              {
                "ok-confirm-modal": _vm.onChangeBookConfirmOk,
                "cancel-confirm-modal": _vm.onChangeBookConfirmCancel,
                "close-confirm-modal": function() {
                  _vm.displayConfirmBookChange = false
                }
              }
            )
          )
        : _vm._e(),
      _vm.displayConfirmGroupChange
        ? _c(
            "ModalConfirm",
            _vm._g(
              {
                attrs: { message: _vm.$t("messages.confirm.notSavedChanges") }
              },
              {
                "ok-confirm-modal": _vm.onChangeGroupConfirmOk,
                "cancel-confirm-modal": _vm.onChangeGroupConfirmCancel,
                "close-confirm-modal": function() {
                  _vm.displayConfirmGroupChange = false
                }
              }
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }