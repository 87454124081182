var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "setting-preview-image" }, [
    _vm.image
      ? _c("div", { staticClass: "image" }, [
          _c("img", {
            staticClass: "container-fluid",
            attrs: { src: _vm.image }
          })
        ])
      : _vm.image == "" && !_vm.stdbFlg
      ? _c(
          "div",
          {
            staticClass:
              "no_image d-flex align-items-center justify-content-center"
          },
          [_vm._v(" 画像がありません。 ")]
        )
      : _c("div", { staticClass: "no_image d-flex" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }