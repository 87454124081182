<template>
  <button
    type="button"
    :class="['btn', 'btn-' + color, 'btn-sm']"
    :style="buttonStyle"
  >
    {{ labelName }}
  </button>
</template>

<script>
export default {
  name: "ButtonMini",
  props: {
    labelName: {
      type: String,
      default: function () {
        return ""
      },
    },
    color: {
      type: String,
      default: function () {
        return ""
      },
    },
    labelColor: {
      type: String,
      default: function () {
        return ""
      },
    },
    borderColor: {
      type: String,
      default: function () {
        return ""
      },
    },
  },
  computed: {
    buttonStyle: function () {
      return {
        color: this.labelColor,
        border: `solid 1px ${this.borderColor}`,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-sm {
  padding: 0rem 0.5rem;
  border-radius: 2rem;
}
</style>
