import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

import JaMessages from './locales/ja/translation'
import EnMessages from './locales/en/translation'

export default new VueI18n({
  locale: 'ja',
  // ロケール設定用の翻訳定義がない場合は、日本語を使用する。
  fallbackLocale: 'ja',
  messages: {
    ja: JaMessages.ja,
    en: EnMessages.en,
  },
});