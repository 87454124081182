<template>
  <div class="title-homework">
    <ul class="list-group list-group-horizontal title-area">
      <li class="list-group-item border-0 pt-0 ps-0 pe-2 pb-0">
        <IconHomeworkMono
          width="30px"
          height="30px"
        />
      </li>
      <li class="list-group-item border-0 px-0 pb-0 title-text">
        <h5>{{ $t("titles.homework") }}</h5>
      </li>
      <li class="list-group-item border-0 ps-3 pe-1 pb-0 title-help">
        <ButtonOpenHelp :url="helpLink" />
      </li>
      <li
        v-if="showRequiredMessage"
        class="list-group-item border-0 title-text"
      >
        <label
          class="required-label"
        >
          {{ $t("labels.required") }}
        </label>
      </li>
    </ul>
    <div
      v-if="showButtons"
      class="btn-create-homework"
    >
        <ButtonBorderCircleMedium
          :label-name="$t('buttons.createHomework')"
          color="layout-theme-light"
          :label-color="colorLayoutTheme"
          :border-color="colorLayoutTheme"
          @click.native="createHomework"
        />
    </div>
    <div
      v-if="showButtons"
      class="sub-functions-area"
    >
      <div
        v-if="showButtons"
        ref="sub-functions"
        class="sub-functions"
      >
        <div
          class="button-leader"
          @click="toggleSubFunction"
          v-text="'･･･'"
        ></div>
        <div
          class="sub-menu-list"
          :hidden="!showSubMenu"
        >
          <div
            class="sub-menu-item"
            @click="onClickExportHomework"
            v-text="$t('buttons.exportHomework')"
          ></div>
          <div
            class="sub-menu-item"
            @click="onClickSetEval"
            v-text="$t('buttons.settingEval')"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconHomeworkMono from "@/components/atoms/icons/IconHomeworkMono.vue"
import ButtonOpenHelp from "@/components/atoms/buttons/ButtonOpenHelp.vue"
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"

export default {
  name: "TitleHomework",
  components: {
    IconHomeworkMono,
    ButtonOpenHelp,
    ButtonBorderCircleMedium,
  },
  props: {
    // ヘルプページへのリンク
    helpLink: {
      type: String,
      default: function () {
        return ""
      },
    },
    showRequiredMessage: {
      type: Boolean,
      default: function(){
        return false
      }
    },
    showButtons: {
      type: Boolean,
      default: function() {
        return false
      }
    }
  },
  data: () => ({
    colorLayoutTheme: "#ff7f27",
    showSubMenu: false,
    contentsArea: undefined,
  }),
  mounted() {
    window.document.addEventListener('click', this.closeSubMenu)
    // コンテンツエリアのスクロールイベントを登録
    this.contentsArea = window.document.getElementsByClassName('contentsArea')[0]
    this.contentsArea.addEventListener('scroll', this.onContentsAreaScroll)
  },
  beforeDestroy() {
    window.document.removeEventListener('click', this.closeSubMenu)
    this.contentsArea.removeEventListener('scroll', this.onContentsAreaScroll)
  },
  methods: {
    createHomework() {
      this.$emit('create-homework')
    },
    /**
     * サブメニュー表示切り替え
     */
    toggleSubFunction($event) {
      $event.stopPropagation()
      this.showSubMenu = !this.showSubMenu
    },
    onClickExportHomework() {
      this.showSubMenu = false
      this.$emit('export-homework')
    },
    onClickSetEval() {
      this.showSubMenu = false
      this.$emit('set-eval')
    },
    closeSubMenu() {
      this.showSubMenu = false
    },
    /**
     * コンテンツエリアスクロール時
     */
     onContentsAreaScroll() {
      // サブメニューボタンをスクロールに追従させる
      const scrollLeft = this.contentsArea.scrollLeft
      const subFunctionsElm = this.$refs['sub-functions']
      if (subFunctionsElm) {
        subFunctionsElm.style.right = `${scrollLeft * -1}px`
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.title-homework {
  position: relative;
  display: flex;
  font-size: 10px;
  height: 2.8rem;
  align-items: center;

  .list-group {
    height: 2.8rem;
    align-items: center;

    li {
      padding: 0;
    }
  }

  .title-area {
    flex-grow: 1;
    .title-text {
      h5 {
        margin: 0;
      }
    }
  }
  .btn-create-homework {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 2;
    position: absolute;
    left: calc(50% - 10em);
  }

  .sub-functions-area {
    display: flex;
    position: relative;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    .sub-functions {
      position: absolute;
      font-size: var(--bs-body-font-size);
      .button-leader {
        cursor: pointer;
        width: 30px;
        height: 30px;
        background-color: var(--bs-table-header);
        color: var(--bs-layout-theme);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: bold;
      }

      .sub-menu-list {
        position: absolute;
        width: 220px;
        height: auto;
        top: 38px;
        right: 0px;
        z-index: 20;

        .sub-menu-item {
          cursor: pointer;
          font-weight: 900;
          padding: 10px 20px;
          background-color: #FFF;
          border-left: solid var(--bs-layout-theme) 2px;
          border-right: solid var(--bs-layout-theme) 2px;
          border-bottom: solid var(--bs-layout-theme) 1px;

          &:first-child {
            border-top: solid var(--bs-layout-theme) 2px;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
          }

          &:last-child {
            border-bottom: solid var(--bs-layout-theme) 2px;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
          }

          &:hover {
            background-color: var(--bs-table-row);
          }
        }
      }
    }
  }
}
.required-label {
  padding: inherit;
  font-size: 140%;
}
.required-label:before {
  content: "*";
  color: red;
}
</style>
