/**
 * 問題管理関連 API
 */

import apiInstance from "./axiosBase"

export default {
  /**
   * Create 系 API
   */

  /**
   * Read 系 API
   */

  /**
   * 問題表示設定を取得する
   * @param {String} apiToken LMS API トークン
   * @param {Number} schoolId 学校 ID
   * @param {Number} groupId グループID
   * @param {String} bookId 書籍ID
   * @param {String} accountId アカウントID
   * @returns 問題表示設定
   */
  async getSettingQuestionInfo(apiToken, schoolId, groupId, bookId, accountId) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: "/question/SlctQuesSet",
        data: {
          token: apiToken,
          school_id: schoolId,
          grp_id: groupId,
          textbook_id: bookId,
          account_id: accountId,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 引数の教科、教材に紐づく問題一覧を取得する
   * @param {String} curriculum 教科
   * @param {String} bookId 教材 ID
   * @returns 問題一覧
   */
  async getTextbookConfigJson(curriculum, bookId) {
    try {
      const fileName = "le_ques_config.json"
      return await apiInstance.axiosSviewerBase({
        method: "get",
        url: `/books/textbook/${curriculum}/${bookId}/000/learningjson/${fileName}`,
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 引数の教科、教材に紐づくコンテンツを取得する
   * @param {String} curriculum 教科
   * @param {String} bookId 教材 ID
   * @returns コンテンツ一覧
   */
  async getContentsListJson(curriculum, bookId) {
    try {
      const fileName = "le_contents_list.json"
      return await apiInstance.axiosSviewerBase({
        method: "get",
        url: `/books/textbook/${curriculum}/${bookId}/000/learningjson/${fileName}`,
      })
    } catch (error) {
      return error
    }
  },


  /**
   * エスビューワ側のJSONを取得する
   * @param {String} url ドメインなしのURL(/books/はのぞく)
   * @returns JSONファイル内容
   */
  async getSviewerJson(url) {
    try {
      return await apiInstance.axiosSviewerBase({
        method: "get",
        url: `/books/${url}`,
      })
    } catch (error) {
      return error
    }
  },

  /**
   * 教材の画像を取得する
   * @param {String} imgPath 
   * @returns 画像
   */
  async getQuestionImage(imgPath) {
    try {
      return await apiInstance.axiosSviewerBase({
        method: "get",
        url: `/books/${imgPath}`,
        responseType: "blob",
      })
    } catch (error) {
      return error
    }
  },

  /**
   * コンテンツの画像を取得する
   * @param {String} curriculum 教科
   * @param {String} textbookId 書籍ID
   * @param {String} imgPath 画像パス
   * @returns 画像
   */
  async getContentImage(curriculum, textbookId, imgPath) {
    try {
      return await apiInstance.axiosSviewerBase({
        method: "get",
        url: `/books/textbook/${curriculum}/${textbookId}/000/contents/img/${imgPath}`,
        responseType: "blob",
      })
    } catch (error) {
      return error
    }
  },

  /**
   * Update 系 API
   */

  /**
   * クラス、教材、問題に対してヒント、答、解説、コンテンツの選択有無の一括登録を行う。
   * @param {String} apiToken アクセストークン
   * @param {String} accountId アカウントID
   * @param {String} schoolId 学校ID
   * @param {String} groupId グループID
   * @param {String} bookId 書籍ID
   * @param {Array} questionSetList 問題設定_親問題リスト
   * @param {Array} questionSetContentsList 問題設定_コンテンツリスト
   * @returns 処理結果
   */
  async updateQuestionControl(apiToken, accountId, schoolId, groupId, bookId, questionSetList, questionSetContentsList) {
    try {
      return await apiInstance.axiosLmsBase({
        method: "post",
        url: `/question/InsQuesSet`,
        data: {
          token: apiToken,
          account_id: accountId,
          school_id: schoolId,
          grp_id: groupId,
          textbook_id: bookId,
          ques_set_list: questionSetList,
          ques_set_contents_list: questionSetContentsList,
        },
      })
    } catch (error) {
      return error
    }
  },
  /**
   * Delete 系 API
   */
}
