<template>
  <div class="table-homework-stream-data-list">
    <div class="row">
      <div class="col-8">
        <label
          class="form-label mt-3 mb-1"
          :class="{ 'required-label': isRequire }"
        >{{ $t("labels.streamData") }}</label>
      </div>
      <div
        v-if="limit === 0"
        class="col-4 text-end"
      >
        <label class="form-label mt-3 mb-1 mx-3"> {{ streamDataList.length }}件 </label>
      </div>
      <div
        v-else
        class="col-4 text-end"
      >
        <label class="form-label mt-3 mb-1 mx-3"> {{ streamDataList.length }}/{{ limit }}件 </label>
      </div>
    </div>

    <!--
      行数は最大表示数 + ヘッダ１行分とする。
      １行の高さはCSS部分で設定しているものに対して少し余裕を持たせたものとしている。
      ※丁度に設定すると何故か最大表示数と要素数が一致した時でもスクロールバーが活性化されてしまう為。
    -->
    <div
      class="table-wrap"
      :style="{ 'max-height': `${42 * (displayCount + 1)}px` }"
    >
      <table>
        <thead>
          <tr class="table-header">
            <th
              v-for="(header, index) in headers"
              :key="index"
            >
              <div>{{ header }}</div>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(item, index) in dataList"
            :key="index"
            class="text-center px-1"
          >
            <template v-if="item">
              <!-- 行番号 -->
              <!-- <th>{{ index + 1 }}</th> -->
              <!-- TODO: 状況に応じてスタンプを表示する -->
              <td>
                <IconCaution
                  v-if="showCaution(item) || judgeAdditionalOptionCaution(item)"
                  class="caution"
                  width="20"
                  height="20"
                  @click.native="onclickCaution(item)"
                />
              </td>

              <td
                v-if="item.questionType === 1"
                class="text-start px-1"
              >
                <div class="row">
                  <div class="col-4">
                    <div class="show-three-point">
                      {{ getBookName(item.subQuestionInfo.teachingMaterials) }}
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="show-three-point">
                      {{ item.taskName }}
                    </div>
                  </div>
                </div>
              </td>
              <td
                v-else
                class="text-start px-1"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="show-three-point">
                      {{ item.taskName }}
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <ButtonPreview @click.native="onClickPreview(item)" />
              </td>
              <td>
                <ButtonDelete
                  :disabled="isPublished"
                  @click.native="onClickDelete(item, index)"
                />
              </td>
              <!-- 入れ替えボタン -->
              <template v-if="isShowButtonUpDown">
                <td>
                  <ButtonUp
                    v-if="index !== 0"
                    :disabled="isPublished"
                    @click.native="onClickUp(index)"
                  />
                </td>
                <td>
                  <ButtonDown
                    v-if="index !== streamDataList.length - 1"
                    :disabled="isPublished"
                    @click.native="onClickDown(index)"
                  />
                </td>
              </template>
            </template>
            <template v-else>
              <!-- colspanの値はitemがnullでない場合の表示数と合わせる -->
              <td :colspan="isShowButtonUpDown ? 6 : 4" />
            </template>
          </tr>
        </tbody>
      </table>
    </div>

    <ModalPreviewTextbookQuestion
      v-if="currentPreview === previewKind.textbook"
      :item="questionInfo"
      :curriculum="curriculum"
      :stream-group="Number(streamGroup)"
      :login-user-info="loginUserInfo"
      :student-items="studentItems"
      :book-items="bookItems"
      :task-name="taskName"
      :book-name="bookName"
      @close="currentPreview = previewKind.none"
    />
    <ModalProgressPreviewStdbPdfQuestion
      v-if="
        currentPreview === previewKind.stdbLayout ||
          currentPreview === previewKind.pdf
      "
      :item="questionInfo"
      @close="currentPreview = previewKind.none"
    />
    <ModalProgressPreviewSingleStdbQuestion
      v-if="currentPreview === previewKind.stdb"
      :item="questionInfo"
      @close="currentPreview = previewKind.none"
    />
    <ModalConfirm
      v-if="showConfirmDelete"
      :message="
        $t('messages.confirm.deleteHomeworkReplaceWords', {
          homeworkName: deleteHomeworkName,
        })
      "
      v-on="{
        'ok-confirm-modal': okConfirmModal,
        'close-confirm-modal': closeConfirmModal,
      }"
    />
    <ModalConfirmOkOnly
      v-if="showConfirmCaution"
      :message="cautionMessage"
      @close-confirm-modal-ok-only="showConfirmCaution = !showConfirmCaution"
    />
  </div>
</template>

<script>
import mixin from "@/mixins/mixin"
import { mapGetters } from "vuex"
import ButtonPreview from "@/components/atoms/buttons/ButtonPreview.vue"
import ButtonDelete from "@/components/atoms/buttons/ButtonDelete.vue"
import ModalPreviewTextbookQuestion from "@/components/organisms/modal/ModalPreviewTextbookQuestion.vue"
import ModalProgressPreviewStdbPdfQuestion from "@/components/organisms/modal/ModalProgressPreviewStdbPdfQuestion.vue"
import ModalProgressPreviewSingleStdbQuestion from "@/components/organisms/modal/ModalProgressPreviewSingleStdbQuestion.vue"
import ButtonUp from "@/components/atoms/buttons/ButtonUp.vue"
import ModalConfirm from "@/components/organisms/modal/ModalConfirm.vue"
import ButtonDown from "@/components/atoms/buttons/ButtonDown.vue"
import IconCaution from "@/components/atoms/icons/IconCaution.vue"
import { homeworkTypeCode, streamFileInfoMode } from "@/constant/homework"
import ModalConfirmOkOnly from "./modal/ModalConfirmOkOnly.vue"
import { db } from "@/dbs/indexedDb"

// プレビューの種類
const previewKind = {
  // プレビューしていない
  none: 0,
  // 書籍
  textbook: 1,
  // STDB(１問ずつ)
  stdb: 2,
  // STDB(レイアウト通り)
  stdbLayout: 3,
  // PDF
  pdf: 4,
}

export default {
  name: "TableHomeworkStreamDataList",
  components: {
    ButtonPreview,
    ButtonDelete,
    ModalPreviewTextbookQuestion,
    ModalProgressPreviewStdbPdfQuestion,
    ModalProgressPreviewSingleStdbQuestion,
    ModalConfirm,
    ButtonUp,
    ButtonDown,
    IconCaution,
    ModalConfirmOkOnly,
  },
  mixins: [mixin],
  props: {
    questionType: {
      type: Number,
      require: true,
      default: function () {
        return 0
      },
    },
    streamDataList: {
      type: Array,
      default: function () {
        return []
      },
    },
    displayCount: {
      type: Number,
      default: function () {
        return 0
      },
    },
    isRequire: {
      type: Boolean,
      default: function () {
        return false
      },
    },
    isPublished: { type: Boolean },
    isShowButtonUpDown: {
      type: Boolean,
      default: function () {
        return true
      },
    },
    streamGroupMember: {
      type: Array,
      default: function () {
        return []
      },
    },
    studentItems: {
      type: Array,
      default: function () {
        return []
      },
    },
    bookItems: {
      type: Array,
      default: function () {
        return []
      },
    },
    streamGroup: {
      type: String,
      default: function () {
        return "0"
      },
    },
    copyHomeworkKey: {
      type: Number,
      default: function () {
        return 0
      },
    },
    limit: {
      type: Number,
      default: function () {
        return 0
      },
    },
    hasAdditionalOptionIdStudentList: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },

  data: () => ({
    headers: [
      // 行番号
      // '',
      "",
      "課題名",
      "プレビュー",
      "削除",
      "",
      "",
    ],
    items: [],
    previewKind: previewKind, // templateからアクセスする為のもの
    // 現在プレビューしている問題の種類
    currentPreview: previewKind.none,
    taskName: "",
    bookName: "",
    showConfirmDelete: false,
    showConfirmCaution: false,
    deleteItem: {},
    deleteItemIndex: -1,
    deleteHomeworkName: "",
    cautionMessage: "",
    questionInfo: {},
    curriculum: "",
  }),
  computed: {
    ...mapGetters("homework", ["headerInfo", "homeworkDetailItems"]),
    dataList: {
      get: function () {
        const length = Math.max(this.streamDataList.length, this.displayCount)
        const list = []
        for (let i = 0; i < length; i++) {
          const item =
            i < this.streamDataList.length
              ? JSON.parse(JSON.stringify(this.streamDataList[i]))
              : null
          if (item) {
            this.$set(item, "index", i)
          }
          list.push(item)
        }
        return list
      },
      set: function (value) {
        this.$emit("changed-stream-data-list", value)
      },
    },
  },
  mounted() {
    if (!this.isShowButtonUpDown) {
      this.headers = ["", "課題名", "プレビュー", "削除"]
    }
  },
  watch: {
    hasAdditionalOptionIdStudentList: function () {
      if(this.dataList){
        this.dataList.forEach((data) => {
          if(data){
            this.showCaution(data);
            this.judgeAdditionalOptionCaution(data);
          }
        });
      }
    },
  },
  methods: {
    /**
     * 問題の所属する書籍を所有していない生徒がいる場合Trueを返す処理
     */
    showCaution: function (item) {
      if (item.questionType === homeworkTypeCode.textbook) {
        // 問題の書籍情報を取得
        const bookId = item.subQuestionInfo.teachingMaterials
        // 生徒ごとに書籍の所持状態を確認 所持：False, 未所持：true
        return this.streamGroupMember.some((memberInfo) => {
          return !memberInfo.bookItems.some(
            (bookItem) => bookItem.id === bookId
          )
        })
      }
      return false
    },
    judgeAdditionalOptionCaution (item) {
      const hasAdditionalOptionIdStudentList = this.hasAdditionalOptionIdStudentList[item.subQuestionInfo.teachingMaterials]
      if (hasAdditionalOptionIdStudentList) {
        console.log(JSON.parse(JSON.stringify(hasAdditionalOptionIdStudentList)))
        return this.streamGroupMember.some((item) => {
          return !hasAdditionalOptionIdStudentList.find(v => item.accountId === v)
        })
      }
      return false
    },
    getBookName(bookId) {
      if (this.bookItems && this.bookItems.length > 0) {
        const bookInfo = this.bookItems.find(
          (bookInfo) => bookInfo.bookId === bookId
        )
        return !bookInfo ? "" : bookInfo.bookTitle
      } else if (
        this.headerInfo.bookItems &&
        this.headerInfo.bookItems.length > 0
      ) {
        const bookInfo = this.headerInfo.bookItems.find(
          (bookInfo) => bookInfo.bookId === bookId
        )
        return !bookInfo ? "" : bookInfo.bookTitle
      } else {
        return ""
      }
    },
    onclickCaution: function (item) {
      let streamGroup = ""
      const bookId = item.subQuestionInfo.teachingMaterials
      this.streamGroupMember.forEach((member) => {
        if (!member.bookItems.some((bookItem) => bookItem.id === bookId)) {
          streamGroup += "・" + member.accountName + "\n"
        }
      })
      if(this.hasAdditionalOptionIdStudentList[bookId]){
        this.streamGroupMember.forEach((member) => {
          if (!this.hasAdditionalOptionIdStudentList[bookId].find(v => member.accountId === v)) {
            streamGroup += "・" + member.accountName + "\n"
          }
        })
      }
      this.cautionMessage = this.$t("messages.error.notExistQuestionStream", {
        streamGroup: streamGroup,
      })
      this.showConfirmCaution = true
    },
    onClickPreview: async function (item) {
      const existBookItem = this.checkExistBookItem(item)

      if (item.questionType !== homeworkTypeCode.textbook) {
        if (item.haishinFileNo === 0 || this.headerInfo.editMode === "copy") {
          if (item.haishinFileNo !== 0 && this.headerInfo.editMode === "copy") {
            this.questionInfo = {
              taskName: item.taskName,
              fileList: [],
              mode: 1,
              key: this.copyHomeworkKey,
              keyNo: item.homeworkEdaNo,
              fileNmSeq: item.fileNmSeq,
              page: "create",
            }
          } else {
            // 新規の場合はIndexedDBから取得
            let fileList
            const idbQuestionImage = await db.idbQuestionImage.get(
              item.subQuestionInfo.filePath
            )
            if (idbQuestionImage === undefined) {
              fileList = []
            } else {
              fileList = idbQuestionImage.uploadFileList
            }
            if (item.questionType === homeworkTypeCode.stdb) {
              // STDB１問ずつの場合は、対応した枝番の画像だけを引っ張ってくる
              fileList = fileList.filter(
                (file) => Number(file.file_name.slice(1, 4)) === item.fileNmSeq
              )
              this.questionInfo = {
                taskName: item.taskName,
                fileList: fileList,
                page: "create",
                isPublished: this.isPublished,
              }
            } else {
              // STDBレイアウト通り、PDFの場合は紐づく画像すべてを引っ張ってくる
              this.questionInfo = {
                taskName: item.taskName,
                fileList: fileList,
                page: "create",
                isPublished: this.isPublished,
              }
            }
          }
        } else {
          this.questionInfo = {
            taskName: item.taskName,
            fileList: [],
            mode:
              this.headerInfo.homeworkKey === 0
                ? streamFileInfoMode.uploadList
                : streamFileInfoMode.questionList,
            key: this.headerInfo.homeworkKey,
            keyNo:
              this.headerInfo.homeworkKey !== 0
                ? item.homeworkEdaNo
                : item.haishinFileNo,
            fileNmSeq: item.fileNmSeq,
            page: "create",
            isPublished: this.isPublished,
          }
        }
      }

      switch (item.questionType) {
        case homeworkTypeCode.textbook:
          // 書籍
          if (!existBookItem) {
            this.showConfirmCaution = true
            this.cautionMessage = this.$t("messages.error.canNotGetPreviewFile")
            return
          }
          this.currentPreview = previewKind.textbook
          this.bookName = item.mainQuestionInfo.fileName
          this.taskName = item.taskName
          this.questionInfo = {
            bookId: item.subQuestionInfo.teachingMaterials,
            chapterId: item.mainQuestionInfo.chapterId,
            quesParentId: item.mainQuestionInfo.quesParentId,
          }
          this.curriculum = this.nameCurriculums.shortName.find(
            (s) => Number(s.code) == this.headerInfo.curriculum
          ).name
          break
        case homeworkTypeCode.pdf:
          // PDF
          this.currentPreview = previewKind.pdf
          break
        case homeworkTypeCode.stdbLayout:
          // STDB(レイアウト通り)
          this.currentPreview = previewKind.stdbLayout
          break
        case homeworkTypeCode.stdb:
          // STDB(1問ずつ)
          this.currentPreview = previewKind.stdb
          break
      }
    },
    onClickDelete: function (item, index) {
      this.deleteItem = item
      this.deleteItemIndex = index
      this.deleteHomeworkName = item.taskName

      if (this.isPublished) {
        return
      } // 配信中もしくは配信終了しているものは削除不可

      // 削除処理を実行する
      this.showConfirmDelete = true
    },
    onClickUp: function (index) {
      if (this.isPublished) {
        return
      }
      if (index === 0) {
        return
      }
      this.sort(index, index - 1)
    },
    onClickDown: function (index) {
      if (this.isPublished) {
        return
      }
      if (index === this.dataList.length - 1) {
        return
      }
      this.sort(index, index + 1)
    },
    sort: function (indexA, indexB) {
      this.$emit("on-sort", indexA, indexB)
    },
    okConfirmModal: function () {
      // 親コンポーネントの宿題削除処理を実行
      this.$emit("delete", this.deleteItem)
      this.showConfirmDelete = false
    },
    closeConfirmModal: function () {
      this.showConfirmDelete = false
    },
    checkExistBookItem(questionInfo) {
      const paramBookId = questionInfo.subQuestionInfo.teachingMaterials
      const existBookItem = this.streamGroupMember.some((member) => {
        return member.bookItems.some((book) => book.id === paramBookId)
      })
      return existBookItem
    },
  },
}
</script>

<style lang="scss" scoped>
.caution {
  cursor: pointer;
}

.table-wrap {
  overflow-y: scroll;

  table {
    table-layout: fixed;
    width: 100%;
    border: solid 1px #b3b3b3;

    thead {
      position: sticky;
      top: -1px;
      // 暫定対応なので別の箇所でz-indexを設定する時のレスポンス対応時は考慮が必要
      z-index: 1;
      tr {
        height: 40px;
        background: var(--bs-table-header);
        color: var(--bs-layout-theme);
        // 警告アイコン
        th:nth-child(1) {
          width: 3%;
          @media (max-width: 991px) {
            width: 7%;
            text-align: center;
          }
        }

        // 課題名
        th:nth-child(2) {
          text-align: center;
        }
        // プレビュー
        th:nth-child(3) {
          text-align: center;
          width: 10%;
          @media (max-width: 991px) {
            width: 12%;
            text-align: center;
          }
        }
        // 削除
        th:nth-child(4) {
          text-align: center;
          width: 10%;
          @media (max-width: 991px) {
            width: 12%;
            text-align: center;
          }
        }
        // 入れ替えボタン
        th:nth-child(5),
        th:nth-child(6) {
          text-align: center;
          width: 5%;
          @media (max-width: 991px) {
            width: 7%;
            text-align: center;
          }
        }
      }
    }

    tbody {
      tr {
        height: 40px;
        // 警告アイコン
        td:nth-child(1) {
          width: 3%;
          @media (max-width: 991px) {
            width: 7%;
            text-align: center;
          }
        }
        // プレビュー
        td:nth-child(3) {
          width: 10%;
          // アイコンクリック範囲設定
          ::v-deep .button-preview {
            display: contents;
            width: 25px;
            height: 25px;
          }
        }
        // 削除
        td:nth-child(4) {
          width: 10%;
          // アイコンクリック範囲設定
          ::v-deep .button-delete {
            display: contents;
            width: 25px;
            height: 25px;
          }
        }
        // 入れ替えボタン
        td:nth-child(5),
        td:nth-child(6) {
          width: 8px;
          // アイコンクリック範囲設定
          ::v-deep .button-preview {
            display: contents;
            width: 8px;
            height: 8px;
          }
        }
      }
      tr:nth-child(odd) {
        background: var(--bs-table-row);
      }
    }
  }
}
.table-header {
  font-size: 1rem;
  @media (max-width: 991px) {
    font-size: 0.8rem;
  }
}
.required-label:before {
  content: "*";
  color: red;
}
.show-three-point {
  max-width: fit-content;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
