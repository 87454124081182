import { render, staticRenderFns } from "./ForbiddenContent.vue?vue&type=template&id=63e607de&scoped=true&"
import script from "./ForbiddenContent.vue?vue&type=script&lang=js&"
export * from "./ForbiddenContent.vue?vue&type=script&lang=js&"
import style0 from "./ForbiddenContent.vue?vue&type=style&index=0&id=63e607de&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63e607de",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/git/lms-teachers-site/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63e607de')) {
      api.createRecord('63e607de', component.options)
    } else {
      api.reload('63e607de', component.options)
    }
    module.hot.accept("./ForbiddenContent.vue?vue&type=template&id=63e607de&scoped=true&", function () {
      api.rerender('63e607de', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organisms/ForbiddenContent.vue"
export default component.exports