var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "homework", staticStyle: { height: "unset" } },
    [
      _c(
        "div",
        { staticClass: "mt-3 mx-4 mb-2" },
        [
          _c("TitleHomework", {
            attrs: {
              "help-link": _vm.$t("url.helps.homeworkList"),
              "show-buttons": true
            },
            on: {
              "create-homework": _vm.createHomework,
              "export-homework": _vm.onClickExportHomework,
              "set-eval": function($event) {
                _vm.showSettingEval = true
              }
            }
          })
        ],
        1
      ),
      _c("div", { staticClass: "container-scroller" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c(
            "div",
            { staticClass: "top-contents" },
            [
              _vm.homeworkItems.length > 0
                ? _c("HomeworkFilterCondition", {
                    attrs: { "homework-list": _vm.homeworkItems },
                    on: { "change-filter": _vm.change_filter_handler }
                  })
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            {
              ref: "tableHomeworkTop",
              staticClass: "row justify-content-center"
            },
            [
              _c("div", { staticClass: "col" }, [
                _vm.homeworkItems.length > 0
                  ? _c(
                      "div",
                      [
                        _vm.filteredHomeworkItems.length > 0
                          ? _c("TableHomework", {
                              attrs: {
                                "homework-list": _vm.filteredHomeworkItems
                              },
                              on: { "delete-homework": _vm.deleteHomework }
                            })
                          : _vm._e(),
                        _vm.filteredHomeworkItems.length == 0
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-center align-items-center pt-5",
                                staticStyle: { "font-size": "1.2em" }
                              },
                              [
                                _vm._v(
                                  " 検索条件に該当する宿題が見つかりませんでした。 "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ])
            ]
          )
        ])
      ]),
      _vm.showModal == "terms"
        ? _c("ModalTerms", {
            attrs: { "is-agree-required": true },
            on: {
              "agree-terms": function($event) {
                return _vm.agreeTerms()
              },
              "disagree-terms": function($event) {
                return _vm.$router.push({ name: "Login" })
              }
            }
          })
        : _vm._e(),
      _vm.showModal == "notice"
        ? _c("ModalNotice", {
            attrs: {
              "notice-title": _vm.noticeTitle,
              "notice-style": _vm.noticeStyle,
              "notice-text": _vm.noticeText
            },
            on: {
              "close-notice": function($event) {
                return _vm.closeNotice()
              }
            }
          })
        : _vm._e(),
      _vm.showModal == "updateInfo"
        ? _c("ModalUpdateInfo", {
            attrs: { "update-info-text": _vm.updateInfoText },
            on: {
              "close-update-info": function($event) {
                return _vm.closeUpdateInfo()
              }
            }
          })
        : _vm._e(),
      _vm.showComfirm
        ? _c("ModalConfirmOkOnly", {
            attrs: {
              message: _vm.comfirmMessage,
              "font-size": _vm.confirmFontSize
            },
            on: {
              "close-confirm-modal-ok-only": function($event) {
                _vm.showComfirm = false
              }
            }
          })
        : _vm._e(),
      _vm.sessionDisconnect
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.$t("messages.error.overLimitSessionToken") },
            on: {
              "close-confirm-modal-ok-only": function($event) {
                return _vm.onSessionDisconnect()
              }
            }
          })
        : _vm._e(),
      _vm.showServerErrorExportHomework
        ? _c("ModalConfirmOkOnly", {
            attrs: {
              message: _vm.$t("messages.error.serverErrorExportHomework"),
              "font-size": _vm.confirmFontSize
            },
            on: {
              "close-confirm-modal-ok-only":
                _vm.onClickExportHomeworkErrorModalOkButton
            }
          })
        : _vm._e(),
      _vm.showExportHomeworkConfirm
        ? _c("ModalConfirmWithTitle", {
            attrs: {
              message: _vm.$t("labels.exportHomeworkDialog.message"),
              "ok-button-id": "buttons.output",
              "z-index": 99
            },
            on: {
              "ok-confirm-modal": _vm.exportHomework,
              "cancel-confirm-modal": function($event) {
                _vm.showExportHomeworkConfirm = false
              }
            }
          })
        : _vm._e(),
      _vm.showSettingEval
        ? _c(
            "ModalSettingEval",
            _vm._g(
              { attrs: { "initial-data": _vm.evalSet, "is-common": true } },
              {
                set: _vm.settingEvalSetHandler,
                cancel: _vm.settingEvalCloseHandler
              }
            )
          )
        : _vm._e(),
      _vm.isLoading ? _c("LoadingScreen") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }