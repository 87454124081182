var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tree-check-box" }, [
    _c(
      "ul",
      { class: { tree_space: _vm.list[0] && !_vm.isFolder(_vm.list[0]) } },
      _vm._l(_vm.list, function(item, index) {
        return _c(
          "li",
          { key: index, staticClass: "tree" },
          [
            _c(
              "div",
              {
                staticClass: "label",
                class: {
                  hidden:
                    !_vm.isFolder(item) &&
                    _vm.isGrayOut(item) &&
                    _vm.grayoutHiddenMode,
                  active: item === _vm.selectedItem
                }
              },
              [
                _vm.isFolder(item)
                  ? _c(
                      "span",
                      {
                        class: {
                          hidden_icon:
                            _vm.isGrayOut(item) && _vm.grayoutHiddenMode
                        },
                        on: {
                          click: function($event) {
                            return _vm.toggle(item)
                          }
                        }
                      },
                      [
                        !item.isOpen
                          ? _c("IconCaretRight", {
                              attrs: {
                                width: "15px",
                                height: "15px",
                                "color-style": "#1E90FF"
                              }
                            })
                          : _vm._e(),
                        item.isOpen
                          ? _c("IconCaretDown", {
                              attrs: {
                                width: "15px",
                                height: "15px",
                                "color-style": "#ff7f27"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: item.checkFlg,
                      expression: "item.checkFlg"
                    }
                  ],
                  ref: "checkboxes" + index,
                  refInFor: true,
                  attrs: {
                    id: item.id,
                    type: "checkbox",
                    disabled: _vm.isGrayOut(item)
                  },
                  domProps: {
                    value: item.checkFlg,
                    checked: Array.isArray(item.checkFlg)
                      ? _vm._i(item.checkFlg, item.checkFlg) > -1
                      : item.checkFlg
                  },
                  on: {
                    change: [
                      function($event) {
                        var $$a = item.checkFlg,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = item.checkFlg,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(item, "checkFlg", $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                item,
                                "checkFlg",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(item, "checkFlg", $$c)
                        }
                      },
                      function($event) {
                        _vm.onChangedFlg(item)
                        _vm.onChangeMessageDisplayFlag(item)
                      }
                    ]
                  }
                }),
                _c(
                  "span",
                  {
                    class: {
                      grayout: _vm.isGrayOut(item),
                      select_preview: !_vm.isFolder(item) || item.img
                    },
                    on: {
                      click: function($event) {
                        if ($event.target !== $event.currentTarget) {
                          return null
                        }
                        return _vm.onClickQuestionTitle(item)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(item.title) + " ")]
                )
              ]
            ),
            item.isOpen
              ? _c(
                  "TreeCheckBox",
                  _vm._g(
                    {
                      attrs: {
                        list: item.nodes,
                        "parent-id": item.id,
                        "grayout-hidden-mode": _vm.grayoutHiddenMode,
                        "selected-item": _vm.selectedItem
                      }
                    },
                    {
                      "changed-child-flg": _vm.onChangedChildFlg,
                      "clicked-question-title": _vm.emitQuestionImage,
                      "change-preview-tabs": _vm.emitChangePreviewTabs,
                      "clicked-content-title": _vm.onClickContentTitle,
                      "change-display-message": _vm.onChangeMessageDisplayFlag
                    }
                  )
                )
              : _vm._e()
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }