<template>
  <div class="form-text-field">
    <label
      :for="id"
      class="form-label "
      :class="{ 'required-label': isRequire}"
    >{{ labelText }}</label>
    <input
      :id="id"
      ref="input"
      type="text"
      :value="value"
      class="form-control form-control-sm"
      :aria-describedby="help"
      :disabled="disabled"
      :placeholder="placeholder"
      v-on="{
        input: onInput,
        blur: onBlur,
        compositionstart: startComposition,
        compositionend: endComposition,
      }"
    >
    <div :id="help">
      {{ helpMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: "FormTextField",
  props: {
    labelText: {
      type: String,
      default: function () {
        return ""
      },
    },
    id: {
      type: String,
      default: function () {
        return ""
      },
    },
    help: {
      type: String,
      default: function () {
        return ""
      },
    },
    helpMessage: {
      type: String,
      default: function () {
        return ""
      },
    },
    initialValue: {
      type: String,
      default: function () {
        return ""
      },
    },
    isRequire: {
      type: Boolean,
      default: function (){
        return false
      }
    },
    placeholder: {
      type: String,
      default: function (){
        return ""
      }
    },
    maxLength: { type: Number, default: null },
    disabled: { type: Boolean, default: false },
  },

  data: function () {
    return {
      value: this.initialValue,
      prevValue: "",
      isComposing: false,
    }
  },

  mounted: function () {
    // 初期値を呼び出し元に返す
    this.$emit("input", this.value)
  },

  methods: {
    onInput($event) {
      if (this.isComposing) {
        return
      } // 全角入力の変換確定前。文字列のカットは確定後に行う

      $event.target.value = this.sliceText($event.target.value)
      this.$emit("input", $event.target.value)
    },
    sliceText(text) {
      if (this.maxLength !== null && text.length > this.maxLength) {
        text = text.substring(0, this.maxLength)
      }
      return text
    },
    startComposition() {
      this.isComposing = true
    },
    endComposition() {
      this.isComposing = false

      // inputイベントの後に発火するのでここでカットする
      this.$refs.input.value = this.sliceText(this.$refs.input.value)
      this.$emit("input", this.$refs.input.value)
    },
    onBlur(){
      this.$emit("on-blur", this.$refs.input.value)
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep label {
  display: inline;
}
.required-label:before { 
   content:"*";
   color:red;
}
</style>
