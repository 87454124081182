var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "icon-close" }, [
    _c(
      "svg",
      {
        staticStyle: { "enable-background": "new 0 0 125.5 125.5" },
        attrs: {
          id: "close",
          version: "1.1",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:xlink": "http://www.w3.org/1999/xlink",
          x: "0px",
          y: "0px",
          width: _vm.width,
          height: _vm.height,
          viewBox: "0 0 125.5 125.5",
          "xml:space": "preserve"
        }
      },
      [
        _c("polygon", {
          staticClass: "st0",
          style: _vm.closeStyle,
          attrs: {
            points:
              "62.7,70.8 15.2,118.3 7.2,110.2 54.7,62.7 7.2,15.2 15.2,7.2 62.7,54.7 110.2,7.2 118.3,15.2 \n    70.8,62.7 118.3,110.2 110.2,118.3 "
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }