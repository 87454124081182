// db.js
import Dexie from 'dexie';
import { apiResponseCode } from "../constant/network"
import { sessionConfig } from "../constant/config"

export const db = new Dexie('managementSite');
db.version(4).stores({
  selectedStreamGroupMember: 'id, groupMemberItems, isStreamMySelf', // Primary key and indexed props
  uploadHomeworkDetailItems: 'id, ansDispFlg, checkFlg, endPubDate, endUseDate, explainDispFlg, externalFilePath, fileStatusFlg, haishinFileNo, homeworkEdaNo, mainQuestionInfo, questionCount, questionType, sendMemo, subQuestionInfo, taskName', // Primary key and indexed props
  idbQuestionImage: 'id, uploadFileList', // Primary key and indexed props
  responseCache: 'id, cache',
});
db.version(3).stores({
  selectedStreamGroupMember: 'id, groupMemberItems, isStreamMySelf', // Primary key and indexed props
  uploadHomeworkDetailItems: 'id, ansDispFlg, checkFlg, endPubDate, endUseDate, explainDispFlg, externalFilePath, fileStatusFlg, haishinFileNo, homeworkEdaNo, mainQuestionInfo, questionCount, questionType, sendMemo, subQuestionInfo, taskName', // Primary key and indexed props
  idbQuestionImage: 'id, uploadFileList', // Primary key and indexed props
  displayPreviousValues: 'id, previous',
});
db.version(2).stores({
  selectedStreamGroupMember: 'id, groupMemberItems, isStreamMySelf', // Primary key and indexed props
  uploadHomeworkDetailItems: 'id, ansDispFlg, checkFlg, endPubDate, endUseDate, explainDispFlg, externalFilePath, fileStatusFlg, haishinFileNo, homeworkEdaNo, mainQuestionInfo, questionCount, questionType, sendMemo, subQuestionInfo, taskName', // Primary key and indexed props
  idbQuestionImage: 'id, uploadFileList', // Primary key and indexed props
});
db.version(1).stores({
  selectedStreamGroupMember: 'id, groupMemberItems, isStreamMySelf', // Primary key and indexed props
});

/**
 * DB操作メソッド群
 */
export const funcs = {
  // 画面表示値
  displayPreviousValues: {
    get: async () => {
      let _displayPreviousValues = await db.displayPreviousValues.get(0)
      if (!_displayPreviousValues || !_displayPreviousValues.previous) {
        return {}
      }
      return JSON.parse(_displayPreviousValues.previous)
    },
    put: async (previousObj) => {
      await db.displayPreviousValues.put({
        id: 0,
        previous: JSON.stringify(previousObj),
      })
    }
  },
  responseCache: {
    get: async (key) => {
      const responseCache = await db.responseCache.get(key);
      if (responseCache) {
        let data = JSON.parse(responseCache.cache);
        // キャッシュがあって、設定期間内に保存されたデータの場合
        if (Date.now() - data.timestamp < sessionConfig.responseCacheSpan) {
          // console.log(`キャッシュ有り：${key}`)
          return data;
        }
        else {
          // 期限の切れたレスポンスキャッシュを削除
          // console.log(`キャッシュ期限切れ：${key}`)
          db.responseCache.delete(key);
        }
      }
      // console.log(`キャッシュ無し：${key}`)
      return null;
    },
    set: async (key, cache) => {
      // レスポンスが正常系の場合、キャッシュに保存する
      let isSuccess = false

      // レスポンスステータスチェック
      let checkStatus = (d) => {
        return d.status === apiResponseCode.ok || d.status === apiResponseCode.noContent
      }

      if (Array.isArray(cache)) {
        // 配列
        isSuccess = cache.every((value) => checkStatus(value))
      } else {
        // オブジェクト
        isSuccess = checkStatus(cache)
      }
      if (isSuccess) {
        // console.log(`responseCache.set: ${key}`)
        await db.responseCache.put({
          id: key,
          cache: JSON.stringify({
            data: cache,
            timestamp: Date.now(),
          }),
        })
        }

    },
    clear: async (id) => {
      // console.log(`responseCache.clear: ${id}`)
      await db.responseCache.delete(id);
    },
    clearMatch: async (match) => {
      // console.log(`responseCache.clearMatch: ${match}`)
      let items = (await db.responseCache.toArray()).filter(item => item.id.includes(match));
      for (let item of items) {
        await db.responseCache.delete(item.id);
      }
    },
    clearAll: async () => {
      // console.log(`responseCache.clearAll`)
      await db.responseCache.clear();
    }
  }
}