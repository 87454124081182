var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "error-background" }, [
    _c(
      "div",
      { staticClass: "error d-flex justify-content-center align-items-center" },
      [_c("InternalServerErrorContent")],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }