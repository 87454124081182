<template>
  <div class="card answer-status">
    <div class="card-body">
      <ul
        v-for="(item, index) in list"
        :key="index"
      >
        <p class="text-start m-0">
          {{ item.homeworkName }}
        </p>
        <li
          v-for="(childItem, childIndex) in item.childrenQuestion"
          :key="childIndex"
        >
          <p>{{ childIndex + 1 }}問目</p>
          <div
            v-if="childItem === 0"
          >
            <IconDash width="15px" />
          </div>
          <div
            v-if="childItem === 1"
          >
            <IconCircle
              width="15px"
              :color-style="correctColor"
            />
          </div>
          <div
            v-if="childItem === 2"
          >
            <IconClose
              width="15px"
              :color-style="incorrectColor"
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import IconCircle from "@/components/atoms/icons/IconCircle.vue"
import IconClose from "@/components/atoms/icons/IconClose.vue"
import IconDash from "@/components/atoms/icons/IconDash.vue"

export default {
  name: "StudentAnswerStatus",
  components: {
    IconCircle,
    IconClose,
    IconDash,
  },
  props: {
    list: {
      type: Array,
      default: function () {
        return []
      },
    },
  },
  data: () => ({
    // 正解の色
    correctColor: "#f00",
    // 不正解の色
    incorrectColor: "#0070c0",
  })
}
</script>

<style lang="scss" scoped>
ul {
  padding: 0;
  list-style: none;
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    position: relative;
    margin: 20px 0 0 0;
    p {
      position: absolute;
      left: -12%;
      margin: 0 auto;
      @media (min-width:768px) {
        left: -3%;
      }
      @media (min-width:1080px){
        left: 14%;
      }
      @media (min-width:1440px){
        left: 15%;
      }
    }
  }
}

.answer-status {
  height: 90%;
  overflow-y: scroll;
  overflow-x: scroll;
  padding: 0;
  white-space: nowrap;
}
</style>
