<template>
  <li
    v-if="label"
    :class="[active, 'tab']"
    @click="changeTab"
  >
    {{ label }}
  </li>
</template>

<script>
export default {
  name: "SettingFilterTab",
  props: {
    id: { type: Number, required: true },
    label: {
      type: String,
      default: function () {
        return ""
      },
    },
    value: {
      type: Number,
      default: function () {
        return 0
      },
    },
  },
  computed: {
    active() {
      return this.value === this.id ? "active" : false
    },
  },
  methods: {
    changeTab() {
      this.$emit("input", this.id)
    },
  },
}
</script>

<style lang="scss" scoped>
li {
  list-style: none;
}
.tab {
  width: 8em;
  font-size: 12px;
  text-align: center;
  background: #ededed;
  border: solid 1px #e1e1e1;
  border-right: none;
  cursor: pointer;
  padding: 0.5em 1em;
}
.tab:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.tab:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.active {
  background-color: #ff7f27;
  color: #fff;
}
</style>
