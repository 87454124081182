import Vue from "vue"
import VueRouter from "vue-router"
import store from "../store"
import LeftMenuLayout from "@/layouts/LeftMenuLayout.vue"
import { title, meta } from "@/constant/meta"
import i18n from "../i18n"
// 各画面定義
import Login from "../views/Login.vue"
import FailedSocialLogin from "../views/errors/FailedLogin.vue"
import Homework from "../views/Homework.vue"
import HomeworkRegister from "../views/HomeworkRegister.vue"
import HomeworkAddTextbookQuestion from "../views/HomeworkAddTextbookQuestion.vue"
import HomeworkAddStdbQuestion from "../views/HomeworkAddStdbQuestion.vue"
import HomeworkAddPdfQuestion from "../views/HomeworkAddPdfQuestion.vue"
import HomeworkProgress from "../views/HomeworkProgress.vue"
import Question from "../views/Question.vue"
// エラー画面定義
import PageNotFound404Url from "../views/errors/PageNotFound.vue"
import BadRequest400Url from "../views/errors/BadRequest.vue"
import Forbidden403 from "../views/errors/Forbidden.vue"
import InternalServerError500Url from "../views/errors/InternalServerError.vue"
import ServiceUnavailable503Url from "../views/errors/ServiceUnavailable.vue"

Vue.use(VueRouter)

const routes = [
  {
    // デフォルトは、宿題管理 - 宿題一覧 を表示する
    path: "/",
    redirect: {
      name: "Homework",
    },
  },
  // AWS 用ルーティング定義
  {
    path: "/index.html",
    redirect: {
      name: "Homework",
    },
  },

  // ログイン画面
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: title.login,
      description: meta.description.login,
      keywords: meta.keywords.login,
      robots: meta.robots.login,
    },
  },
  // ログイン失敗時画面
  {
    path: "/failed_login",
    name: "FailedSocialLogin",
    component: FailedSocialLogin,
    meta: {
      title: title.socialLoginError,
      description: meta.description.socialLoginError,
      keywords: meta.keywords.socialLoginError,
      robots: meta.robots.socialLoginError,
    },
  },
  // Appleソーシャルログイン
  {
    path: "/social-login-apple",
    name: "SocialLoginApple",
    beforeEnter: () => {
      const param = `identity_provider=SignInWithApple&redirect_uri=${
        process.env.VUE_APP_ACCOUNT_API_BASE_URL
      }/cognito/social/login&response_type=CODE&client_id=${
        process.env.VUE_APP_COGNITO_CLIENT_ID
      }&scope=email openid aws.cognito.signin.user.admin&state=Apple_${i18n.t(
        "labels.socialLogin.serviceId.sviewer"
      )}_${i18n.t("labels.socialLogin.appId.sviewer.lms")}`
      window.location.href = `https://${process.env.VUE_APP_COGNITO_DOMAIN}.auth.ap-northeast-1.amazoncognito.com/oauth2/authorize?${param}`
    },
  },
  // Googleソーシャルログイン
  {
    path: "/social-login-google",
    name: "SocialLoginGoogle",
    beforeEnter: () => {
      const param = `identity_provider=Google&redirect_uri=${
        process.env.VUE_APP_ACCOUNT_API_BASE_URL
      }/cognito/social/login&response_type=CODE&client_id=${
        process.env.VUE_APP_COGNITO_CLIENT_ID
      }&scope=email openid aws.cognito.signin.user.admin&state=Google_${i18n.t(
        "labels.socialLogin.serviceId.sviewer"
      )}_${i18n.t("labels.socialLogin.appId.sviewer.lms")}`
      window.location.href = `https://${process.env.VUE_APP_COGNITO_DOMAIN}.auth.ap-northeast-1.amazoncognito.com/oauth2/authorize?${param}`
    },
  },
  // Microsoftソーシャルログイン
  {
    path: "/social-login-microsoft",
    name: "SocialLoginMicrosoft",
    beforeEnter: () => {
      const param = `identity_provider=Azure&redirect_uri=${
        process.env.VUE_APP_ACCOUNT_API_BASE_URL
      }/cognito/social/login&response_type=CODE&client_id=${
        process.env.VUE_APP_COGNITO_CLIENT_ID
      }&scope=email openid aws.cognito.signin.user.admin&state=Microsoft_${i18n.t(
        "labels.socialLogin.serviceId.sviewer"
      )}_${i18n.t("labels.socialLogin.appId.sviewer.lms")}`
      window.location.href = `https://${process.env.VUE_APP_COGNITO_DOMAIN}.auth.ap-northeast-1.amazoncognito.com/oauth2/authorize?${param}`
    },
  },
  {
    path: "/succeeded_login",
    name: "SucceededSocialLogin",
    redirect: (to) => {
      return {
        name: "Login",
        query: null,
        params: {
          status: to.query.status,
          accountid: to.query.accountid,
          token: to.query.token,
        },
      }
    },
  },

  {
    path: "/management",
    component: LeftMenuLayout,
    children: [
      {
        // 宿題管理 - 宿題一覧
        path: "homework",
        name: "Homework",
        component: Homework,
        props: true,
        meta: {
          title: title.homework,
          description: meta.description.homework,
          keywords: meta.keywords.homework,
          robots: meta.robots.homework,
        },
      },
      {
        // 宿題管理 - 宿題作成
        // 「mode」は操作モード（ブランク（空文字）：新規作成、edit：編集、copy：複製）
        path: "homework-register/:mode?",
        name: "HomeworkRegister",
        component: HomeworkRegister,
        props: true,
        meta: {
          title: title.homeworkRegister,
          description: meta.description.homeworkRegister,
          keywords: meta.keywords.homeworkRegister,
          robots: meta.robots.homeworkRegister,
        },
      },
      {
        // 宿題管理 - 教材の問題を追加
        path: "homework-add-textbook-question",
        name: "HomeworkAddTextbookQuestion",
        component: HomeworkAddTextbookQuestion,
        meta: {
          title: title.homeworkAddTextbookQuestion,
          description: meta.description.homeworkAddTextbookQuestion,
          keywords: meta.keywords.homeworkAddTextbookQuestion,
          robots: meta.robots.homeworkAddTextbookQuestion,
        },
      },
      {
        // 宿題管理 - 教材の問題(STDB)を追加
        path: "homework-add-stdb-question",
        name: "HomeworkAddStdbQuestion",
        component: HomeworkAddStdbQuestion,
        meta: {
          title: title.homeworkAddStdbQuestion,
          description: meta.description.homeworkAddStdbQuestion,
          keywords: meta.keywords.homeworkAddStdbQuestion,
          robots: meta.robots.homeworkAddStdbQuestion,
        },
      },
      {
        // 宿題管理 - 教材の問題(PDF)を追加
        path: "homework-add-pdf-question",
        name: "HomeworkAddPdfQuestion",
        component: HomeworkAddPdfQuestion,
        meta: {
          title: title.homeworkAddPdfQuestion,
          description: meta.description.homeworkAddPdfQuestion,
          keywords: meta.keywords.homeworkAddPdfQuestion,
          robots: meta.robots.homeworkAddPdfQuestion,
        },
      },
      {
        // 宿題管理 - 宿題進捗
        path: "homework-progress",
        name: "HomeworkProgress",
        component: HomeworkProgress,
        props: true,
        meta: {
          title: title.homeworkProgress,
          description: meta.description.homeworkProgress,
          keywords: meta.keywords.homeworkProgress,
          robots: meta.robots.homeworkProgress,
        },
      },
      {
        path: "question",
        name: "Question",
        component: Question,
        meta: {
          title: title.question,
          description: meta.description.question,
          keywords: meta.keywords.question,
          robots: meta.robots.question,
        },
      },
    ],
  },

  // 共通エラー
  {
    path: "/error/:status",
    name: "APIError",
  },
  // 404 PAGE NOT FOUND
  {
    path: "/404_url",
    name: "PageNotFound404Url",
    component: PageNotFound404Url,
    meta: {
      title: title.errorPages.notFound,
      description: meta.description.errorPages.notFound,
      keywords: meta.keywords.errorPages.notFound,
      robots: meta.robots.errorPages.notFound,
    },
  },
  // 400 BAD REQUEST
  {
    path: "/400_url",
    name: "BadRequest400Url",
    component: BadRequest400Url,
    meta: {
      title: title.errorPages.badRequest,
      description: meta.description.errorPages.badRequest,
      keywords: meta.keywords.errorPages.badRequest,
      robots: meta.robots.errorPages.badRequest,
    },
  },
  // 403 FORBIDDEN
  {
    path: "/403_forbidden",
    name: "Forbidden403",
    component: Forbidden403,
    meta: {
      title: title.errorPages.forbidden,
      description: meta.description.errorPages.forbidden,
      keywords: meta.keywords.errorPages.forbidden,
      robots: meta.robots.errorPages.forbidden,
    },
  },
  // 500 INTERNAL SERVER ERROR
  {
    path: "/500_url",
    name: "InternalServerError500Url",
    component: InternalServerError500Url,
    meta: {
      title: title.errorPages.internalServerError,
      description: meta.description.errorPages.internalServerError,
      keywords: meta.keywords.errorPages.internalServerError,
      robots: meta.robots.errorPages.internalServerError,
    },
  },
  // 503 SERVICE UNAVAILABLE
  {
    path: "/503_url",
    name: "ServiceUnavailable503Url",
    component: ServiceUnavailable503Url,
    meta: {
      title: title.errorPages.serviceUnavailable,
      description: meta.description.errorPages.serviceUnavailable,
      keywords: meta.keywords.errorPages.serviceUnavailable,
      robots: meta.robots.errorPages.serviceUnavailable,
    },
  },
  // どれにもマッチしない場合は、「404 PAGE NOT FOUND」とする
  // ※このルーティングは最後に定義する必要がある
  {
    path: "/*",
    name: "PageNotFound",
    redirect: { name: "PageNotFound404Url" },
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

/**
 * グローバルナビゲーションガード
 * 基本、処理を追加しない事
 * （非同期で動作したり、next を実行しても後続の処理が実行されたりするため）
 */
router.beforeEach((to, from, next) => {
  if (to.name === "APIError") {
    switch (to.params.status) {
      case 400:
        // 400 BAD REQUEST
        router.push({ name: "BadRequest400Url" })
        break
      case 403:
        // 403 FORBIDDEN
        router.push({ name: "Forbidden403" })
        break
      case 404:
        // 404 PAGE NOT FOUND
        router.push({ name: "PageNotFound404Url" })
        break
      case 500:
        // 500 INTERNAL SERVER ERROR
        router.push({ name: "InternalServerError500Url" })
        break
      case 503:
        // 503 SERVICE UNAVAILABLE
        router.push({ name: "ServiceUnavailable503Url" })
        break
      case 504:
        // 504 TIMEOUT ERROR
        router.push({ name: "InternalServerError500Url" })
        break
    }
    return false
  }

  const matchToName = [
    "Login",
    "FailedSocialLogin",
    "SocialLoginApple",
    "SocialLoginGoogle",
    "SocialLoginMicrosoft",
  ]
  const errorPageNames = [
    "BadRequest400Url",
    "Forbidden403",
    "PageNotFound404Url",
    "InternalServerError500Url",
    "ServiceUnavailable503Url",
  ]

  // エラーページはそのまま遷移させる
  if (errorPageNames.includes(to.name)) {
    next()
    // ログイン画面以外に遷移する場合、セッショントークンがなければログイン画面にリダイレクトする
  } else if (
    !matchToName.includes(to.name) &&
    !store.getters.loginUserInfo.sessionToken
  ) {
    next({ name: "Login" })
  } else {
    next()
  }
})

export default router
