<template>
  <div
    class="eval-status"
    :class="{ 'eval-none': evalCode === 0 }"
    :style="statusStyle"
    @click="onChangeEval"
  >
    {{ evalNameList[evalCode] }}
  </div>
</template>

<script>
export default {
  name: "EvalStatus",
  props: {
    evalCode: {
      type: Number,
      default: function () {
        return 0
      },
    },
  },

  data: () => ({
    evalNameList: ['なし', 'A', 'B', 'C', 'D', 'E'],
    evalColorList: ['#000000', '#d15443', '#4878bd', '#bc8920', '#55935e', '#8f5cb4'],
  }),

  computed: {
    statusStyle: function () {
      return {
        color: this.evalColorList[this.evalCode],
      }
    },
  },

  methods: {
    // 評価変更時処理
    onChangeEval() {
      this.$emit("on-change-eval", this.evalCode)
    },
  },
}
</script>

<style lang="scss" scoped>
.eval-status {
  font-family: 'Quicksand', var(--bs-body-font-family);
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;

  &.eval-none {
    font-family: var(--bs-body-font-family);
  }
}
</style>
