<template>
  <div class="button-delete">
    <button
      type="button"
      class="btn"
      :disabled="disabled"
    >
      <IconDelete
        width="25px"
        height="25px"
      />
    </button>
  </div>
</template>

<script>
import IconDelete from "@/components/atoms/icons/IconDelete.vue"

export default {
  name: "ButtonDelete",
  components: {
    IconDelete,
  },

  props: {
    disabled: { type: Boolean, default: false },
  },

  data: () => ({}),
}
</script>

<style lang="scss" scoped>
.btn:disabled {
  opacity: 0.3 !important;
}
</style>
