var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "table-header-label",
      style: {
        cursor: _vm.sortKey ? "pointer" : "default"
      },
      on: { click: _vm.onClick }
    },
    [
      _c("div", { domProps: { textContent: _vm._s(_vm.labelText) } }),
      _vm.sortKey && _vm.isSelected
        ? _c("div", {
            staticClass: "sort-mark",
            style: {
              "font-size": _vm.sortMarkSizeRate * 100 + "%"
            },
            domProps: { textContent: _vm._s(_vm.sortOrder === 1 ? "▲" : "▼") }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }