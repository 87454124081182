<template>
  <div class="internal-server-error">
    <div class="text-center">
      <h1>エラーが発生しました。</h1>
      <h3 class="text-muted">
        500 Error
      </h3>
    </div>
    <div class="content">
      <p>アクセスしたファイルを表示できません。</p>
      <p>時間をおいて再度お試しください。</p>
      <p>それでも改善しない場合は数研出版までお問い合わせください。</p>
    </div>
    <div class="row">
      <div class="col-6 text-center">
        <router-link :to="{ name: 'Login' }">
          <ButtonForward
            :label-name="$t('buttons.returnToLogin')"
            color="secondary"
          />
        </router-link>
      </div>
      <div class="col-6 text-center">
        <a
          :href="inquiryUrl"
          target="_blank"
        >
          <ButtonForward
            :label-name="$t('buttons.toInquiry')"
            color="secondary"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonForward from "@/components/atoms/buttons/ButtonForward.vue"

export default {
  name: "InternalServerErrorContent",
  components: {
    ButtonForward,
  },
  data: () => ({
    inquiryUrl: "https://www.chart.co.jp/inquiry/inquiry.html",
  }),
}
</script>

<style lang="scss" scoped>
.internal-server-error {
  padding: 2.5em;
  min-width: 600px;
  border-style: double;
}
.content {
  padding: 1.5em;
}
.footer {
  justify-content: center;
}
</style>
