<template>
  <div class="button-copy">
    <button
      type="button"
      class="btn"
    >
      <IconCopy
        width="25px"
        height="25px"
      />
    </button>
  </div>
</template>

<script>
import IconCopy from "@/components/atoms/icons/IconCopy.vue"

export default {
  name: "ButtonCopy",
  components: {
    IconCopy,
  },
}
</script>