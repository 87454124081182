var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-parameters-homework-register" },
    [
      _c("div", { staticClass: "mb-1 group-type-radio" }, [
        _c("label", { staticClass: "form-label is-required" }, [
          _vm._v(_vm._s(_vm.$t("labels.groupType")))
        ]),
        _c("span", {
          staticClass: "col-auto group-type-exp",
          domProps: { innerHTML: _vm._s(_vm.$t("labels.groupTypeExp")) }
        }),
        _c("div", { staticClass: "col-auto  mt-1 mb-2" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.groupType,
                expression: "groupType"
              }
            ],
            attrs: {
              type: "radio",
              id: "groupChoice1",
              value: "individual",
              disabled:
                _vm.isPublished ||
                (_vm.selectParams.headerInfo.isMyHomework == undefined
                  ? false
                  : !_vm.selectParams.headerInfo.isMyHomework)
            },
            domProps: { checked: _vm._q(_vm.groupType, "individual") },
            on: {
              change: [
                function($event) {
                  _vm.groupType = "individual"
                },
                _vm.onSelectGroupType
              ]
            }
          }),
          _c("label", { staticClass: "ps-1", attrs: { for: "groupChoice1" } }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/icon_individual.svg"),
                width: "15px"
              }
            }),
            _vm._v(_vm._s(_vm.$t("labels.individualGroup")) + " ")
          ])
        ]),
        _c("div", { staticClass: "col-auto mb-2" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.groupType,
                expression: "groupType"
              }
            ],
            attrs: {
              type: "radio",
              id: "groupChoice2",
              value: "share",
              disabled:
                _vm.isPublished ||
                (_vm.selectParams.headerInfo.isMyHomework == undefined
                  ? false
                  : !_vm.selectParams.headerInfo.isMyHomework)
            },
            domProps: { checked: _vm._q(_vm.groupType, "share") },
            on: {
              change: [
                function($event) {
                  _vm.groupType = "share"
                },
                _vm.onSelectGroupType
              ]
            }
          }),
          _c("label", { staticClass: "ps-1", attrs: { for: "groupChoice2" } }, [
            _c("img", {
              attrs: {
                src: require("@/assets/images/icon_shared.svg"),
                width: "15px"
              }
            }),
            _vm._v(_vm._s(_vm.$t("labels.shareGroup")) + " ")
          ])
        ])
      ]),
      _c("div", { staticClass: "row mb-1" }, [
        _c("div", { staticClass: "col-auto" }, [
          _c("div", { staticClass: "condition-item-value" }, [
            _c(
              "label",
              {
                staticClass: "form-label is-required",
                attrs: { for: "selectedGroup" }
              },
              [_vm._v(_vm._s(_vm.$t("labels.group")))]
            ),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedGroup,
                    expression: "selectedGroup"
                  }
                ],
                staticClass: "form-select form-select-sm",
                attrs: { disabled: _vm.isPublished },
                on: {
                  change: [
                    function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedGroup = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.onSelectGroup
                  ]
                }
              },
              _vm._l(_vm.groupList, function(group, key) {
                return _c("option", {
                  key: key,
                  staticClass: "h6",
                  domProps: {
                    value: group.value,
                    textContent: _vm._s(group.label)
                  }
                })
              }),
              0
            )
          ])
        ])
      ]),
      _vm.groupType == "share"
        ? _c("div", { staticClass: "row mb-1 editor-radio" }, [
            _c("div", { staticClass: "-md-6 col-lg-5" }, [
              _c("label", { staticClass: "form-label is-required" }, [
                _vm._v("宿題の編集・返却が出来る人")
              ]),
              _c("div", { staticClass: "row mt-1" }, [
                _c("div", { staticClass: "col-auto required_transparent" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.editor,
                        expression: "editor"
                      }
                    ],
                    attrs: {
                      type: "radio",
                      id: "editorChoice1",
                      value: "anyone",
                      name: "editor",
                      disabled:
                        _vm.selectParams.headerInfo.isMyHomework == undefined
                          ? false
                          : !_vm.selectParams.headerInfo.isMyHomework
                    },
                    domProps: { checked: _vm._q(_vm.editor, "anyone") },
                    on: {
                      change: [
                        function($event) {
                          _vm.editor = "anyone"
                        },
                        _vm.onSelectEditor
                      ]
                    }
                  }),
                  _c(
                    "label",
                    { staticClass: "ps-1", attrs: { for: "editorChoice1" } },
                    [_vm._v("だれでも")]
                  )
                ]),
                _c("div", { staticClass: "col-auto" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.editor,
                        expression: "editor"
                      }
                    ],
                    attrs: {
                      type: "radio",
                      id: "editorChoice2",
                      value: "onlyme",
                      name: "editor",
                      disabled:
                        _vm.selectParams.headerInfo.isMyHomework == undefined
                          ? false
                          : !_vm.selectParams.headerInfo.isMyHomework
                    },
                    domProps: { checked: _vm._q(_vm.editor, "onlyme") },
                    on: {
                      change: [
                        function($event) {
                          _vm.editor = "onlyme"
                        },
                        _vm.onSelectEditor
                      ]
                    }
                  }),
                  _c(
                    "label",
                    { staticClass: "ps-1", attrs: { for: "editorChoice2" } },
                    [_vm._v("自分のみ")]
                  )
                ])
              ])
            ])
          ])
        : _vm._e(),
      _c("div", { staticClass: "row mb-1" }, [
        _c(
          "div",
          { staticClass: "-md-6 col-lg-5" },
          [
            _c("FormTextField", {
              attrs: {
                id: "homeworkName",
                "label-text": _vm.$t("labels.homeworkName"),
                value: _vm.homeworkName,
                "initial-value": _vm.initialHomeworkName,
                "max-length": 100,
                "is-require": true
              },
              on: {
                input: function($event) {
                  _vm.homeworkName = $event
                }
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-auto col-category" },
          [
            _c("FormSelectBox", {
              ref: "categorySelectBox",
              attrs: {
                id: "category",
                "label-text": _vm.$t("labels.category"),
                value: _vm.selectedCategory,
                options: _vm.generateCategoryItems
              },
              on: { input: _vm.onSelectCategory }
            }),
            _c(
              "a",
              {
                staticClass: "category-edit-link",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.onClickCategoryEdit($event)
                  }
                }
              },
              [_vm._v(" 編集 ")]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row mb-1" }, [
        _c(
          "div",
          { staticClass: "col-auto" },
          [
            _c("FormSelectBox", {
              ref: "curriculumSelectBox",
              attrs: {
                id: "curriculum",
                "label-text": _vm.$t("labels.curriculum"),
                value: _vm.selectedCurriculum,
                options: _vm.generateCurriculumItems,
                "initial-value": _vm.initialCurriculum,
                disabled: _vm.isPublished,
                "is-require": true
              },
              on: { input: _vm.onSelectCurriculum }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-auto" },
          [
            _c("FormSelectBox", {
              ref: "subjectSelectBox",
              attrs: {
                id: "subject",
                "label-text": _vm.$t("labels.subject"),
                value: _vm.selectedSubject,
                options: _vm.generateSubjectItems,
                "initial-value": _vm.initialSubject,
                disabled: _vm.isPublished
              },
              on: {
                input: function($event) {
                  _vm.selectedSubject = $event
                }
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-auto" },
          [
            _c("FormDateField", {
              attrs: {
                "label-text": _vm.$t("labels.startDate"),
                value: _vm.startDate,
                "initial-value": _vm.initialStartDate,
                disabled: _vm.isPublished,
                "date-range": _vm.startDateRange,
                "is-require": true
              },
              on: { input: _vm.onSelectedStartDate }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-auto" },
          [
            _c("FormDateField", {
              attrs: {
                "label-text": _vm.$t("labels.deadlineDate"),
                value: _vm.deadlineDate,
                "initial-value": _vm.initialDeadlineDate,
                "date-range": _vm.deadlineDateRange,
                "is-require": true
              },
              on: { input: _vm.onSelectedDeadlineDate }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "row mb-1" }, [
        _c("div", { staticClass: "col-12" }, [
          _vm.showCanNotGetTextbook
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("messages.error.canNotGetTextbook")) + " "
                )
              ])
            : _vm._e(),
          _vm.showCanNotGetStudent
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("messages.error.canNotGetStudent")) + " "
                )
              ])
            : _vm._e()
        ])
      ]),
      _c("div", { staticClass: "row mb-1" }, [
        _c(
          "div",
          { staticClass: "col" },
          [
            _c(
              "SelectGroupMembers",
              _vm._g(
                {
                  ref: "groupMembers",
                  attrs: {
                    options: _vm.generateGroupMemberItems,
                    "initial-members": _vm.initialStreamGroupMembers,
                    "is-add-only": _vm.isPublished,
                    "is-require": true
                  },
                  on: { input: _vm.onSelectedGroupMembers }
                },
                {
                  "change-stream-my-self": _vm.onCheckedStreamMySelf
                }
              )
            )
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.selectedGroupMembers.length > 100,
                expression: "selectedGroupMembers.length > 100"
              }
            ],
            staticClass: "mt-2 mb-2 text-danger"
          },
          [_vm._v(_vm._s(_vm.$t("labels.recommendNumberOfDeliveryMessage")))]
        )
      ]),
      _vm.showConfirmDeleteBySelectGroup
        ? _c(
            "ModalConfirm",
            _vm._g(
              { attrs: { message: _vm.confirmMessage } },
              {
                "ok-confirm-modal": _vm.onOkShowConfirmDeleteBySelectGroup,
                "close-confirm-modal": _vm.onCloseConfirmModalGroup
              }
            )
          )
        : _vm._e(),
      _vm.showConfirmDeleteBySelectCurriculum
        ? _c(
            "ModalConfirm",
            _vm._g(
              { attrs: { message: _vm.confirmMessage } },
              {
                "ok-confirm-modal": _vm.onOkShowConfirmDeleteBySelectCurriculum,
                "close-confirm-modal": _vm.onCloseConfirmModalCurriculum
              }
            )
          )
        : _vm._e(),
      _vm.isInvalidDateRange
        ? _c("ModalConfirmOkOnly", {
            attrs: {
              message: _vm.$t("messages.error.validateDateRange", {
                maxDeadlineDate:
                  _vm.paramReleaseEndPeriodItems[0].items[0].value -
                  _vm.paramDeadlinePeriodItems[0].items[0].value
              })
            },
            on: {
              "close-confirm-modal-ok-only": function($event) {
                return _vm.onClickInvalidDateRange()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }