<template>
  <div class="modal-select-question-type">
    <transition
      name="modal"
      appear
    >
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container-outer">
            <ButtonClose
              class="modal-default-button"
              @click.native="$emit('close')"
            />
            <div
              class="modal-container p-4"
              style="overflow: auto; max-height: 80vh"
            >
              <div class="row">
                <div class="col">
                  <p>追加するデータの種類を選択</p>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-auto mt-4">
                  <ButtonBorderCircleMedium
                    :label-name="$t('buttons.questionOfTextbook')"
                    color="layout-theme-light"
                    :width="buttonWidth"
                    :label-color="colorLayoutTheme"
                    :border-color="colorLayoutTheme"
                    @click.native="onClickAddTextbookQuestion"
                  />
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-auto mt-4">
                  <ButtonBorderCircleMedium
                    :label-name="$t('buttons.stdbPrint')"
                    color="layout-theme-light"
                    :width="buttonWidth"
                    :label-color="colorLayoutTheme"
                    :border-color="colorLayoutTheme"
                    @click.native="onClickAddStdbQuestion"
                  />
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-auto mt-4">
                  <ButtonBorderCircleMedium
                    :label-name="$t('buttons.pdf')"
                    color="layout-theme-light"
                    :width="buttonWidth"
                    :label-color="colorLayoutTheme"
                    :border-color="colorLayoutTheme"
                    @click.native="onClickAddPdfQuestion"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/**
 * 宿題管理 - 宿題種別毎の課題追加選択モーダル
 */
import ButtonClose from "@/components/atoms/buttons/ButtonClose.vue"
import ButtonBorderCircleMedium from "@/components/atoms/buttons/ButtonBorderCircleMedium.vue"

export default {
  name: "ModalSelectQuestionType",
  components: {
    ButtonClose,
    ButtonBorderCircleMedium,
  },
  props: {
    isPublished: { type: Boolean },
  },
  data: () => ({
    colorLayoutTheme: "#ff7f27",
    buttonWidth: "270px"
  }),

  methods: {
    /**
     * 「教材の問題」ボタン押下
     */
    onClickAddTextbookQuestion: function () {
      this.$emit("pre-transition") // 遷移前に飛ばすイベント
      this.$emit("close")
      this.$router.push({
        name: "HomeworkAddTextbookQuestion",
      })
    },
    onClickAddPdfQuestion: function () {
      this.$emit("pre-transition") // 遷移前に飛ばすイベント
      this.$emit("close")
      this.$router.push({
        name: "HomeworkAddPdfQuestion",
      })
    },
    onClickAddStdbQuestion: function () {
      this.$emit("pre-transition") // 遷移前に飛ばすイベント
      this.$emit("close")
      this.$router.push({
        name: "HomeworkAddStdbQuestion",
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;

    .modal-container-outer {
      width: 460px;
      margin-left: auto;
      margin-right: auto;

      .modal-default-button {
        width: 51px;
        margin-left: auto;
      }

      .modal-container {
        width: 380px;
        height: 380px;
        margin: 0px auto;
        padding: 20px 30px;
        background-color: #fff;

        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        transition: all 0.3s ease;
        text-align: center;
      }
    }
  }
}

// トランジション（アニメーション）設定
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: opacity 0.8s;
}
</style>
