/**
 * 設定値 API
 */
import apiInstance from './axiosBase'

export default {
  /**
   * Create 系 API
   */

  /**
   * Read 系 API
   */

  /**
   * コード値・名称変換設定値を取得する
   * @param {Object} accessParameter アクセスパラメータ情報
   * @returns 設定値情報
   */
  async getNameConversionConfig(accessParameter) {
    try {
      const fileName = 'NameConversionList.json'
      return await apiInstance.axiosAwsS3Base({
        method: 'get',
        url: `/lms-config/${fileName}`,
        params: {
          Expires: accessParameter.expires,
          Signature: accessParameter.signature,
          'Key-Pair-Id': accessParameter.keyPairId,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * パラメータ設定を取得する
   * @returns パラメータ設定情報
   */
  async getParameterConfig(accessParameter) {
    try {
      const fileName = 'ParameterList.json'
      return await apiInstance.axiosAwsS3Base({
        method: 'get',
        url: `/lms-config/${fileName}`,
        params: {
          Expires: accessParameter.expires,
          Signature: accessParameter.signature,
          'Key-Pair-Id': accessParameter.keyPairId,
        },
      })
    } catch (error) {
      return error
    }
  },

  /**
   * Update 系 API
   */

  /**
   * Delete 系 API
   */
}