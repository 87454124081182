<template>
  <div class="table-homework">
    <table>
      <thead>
        <tr>
          <th
            v-for="(header, index) in headers"
            :key="index"
          >
            <TableHeaderLabel
              :label-text="header.label"
              :sort-key="header.sortKey"
              :is-selected="header.sortKey === selectedHeaderKey"
              @on-selected="onClickLabel"
            />
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(item, index) in convertedStatusNameItems"
          :key="index"
          class="text-center"
        >
          <!-- 行番号 -->
          <!-- <th>{{ index + 1 }}</th> -->
          <td>
            <BadgeStatus
              :label-text="item.statusDisplayName"
              :color="item.statusColor"
            />
          </td>
          <td>
            <img v-if="item.groupType=='share'" src="@/assets/images/icon_shared.svg"  width="15px">
            <img v-else src="@/assets/images/icon_individual.svg" width="15px">
            {{ item.groupName }}
          </td>
          <td>{{ item.category }}</td>
          <td style="text-align: left">
            {{ item.homeworkName }}
          </td>
          <!-- 進捗ボタン -->
          <td>
            <!-- ステータスが「配信中」「配信終了」の場合のみ表示する -->
            <template
              v-if="
                item.status === streamStatus.streaming ||
                  item.status === streamStatus.endOfStream
              "
            >
              <div v-if="isConfirmed(item)">
                <ButtonProgress @click.native="onClickProgress(item)" />
              </div>
              <div v-else>
                <ButtonProgressUnconfirm
                  @click.native="onClickProgress(item)"
                />
              </div>
            </template>
          </td>
          <!-- 編集ボタン -->
          <td>
            <ButtonEdit @click.native="onClickEdit(item)" :editingPermission="item.editingPermission"/>
          </td>
          <td>{{ item.curriculumName }}</td>
          <td>
            {{ item.subjectName }}
          </td>
          <td>{{ item.startDate }}</td>
          <td>{{ item.deadlineDate }}</td>
          <td>{{ item.HandInCount }}</td>
          <!-- 複製ボタン -->
          <td><ButtonCopy @click.native="onClickCopy(item)" :disable="!item.copyable" /></td>
          <!-- 出力ボタン -->
          <td>
            <!-- ステータスが「配信中」「配信終了」の場合のみ表示する -->
            <template
              v-if="
                item.status === streamStatus.streaming ||
                  item.status === streamStatus.endOfStream
              "
            >
              <ButtonExport @click.native="onClickExport(item)" />
            </template>
          </td>
          <!-- 削除ボタン -->
          <td>
            <ButtonDelete
              :editingPermission="item.editingPermission"
              @click.native="
                onClickDelete(
                  item.homeworkKey,
                  item.schoolId,
                  item.homeworkName,
                  item.editingPermission
                )
              "
            />
          </td>
          <td>{{ item.releaseEndDate }}</td>
        </tr>
      </tbody>
    </table>

    <LoadingScreen v-if="isLoading" />

    <ModalConfirmOkOnly
      v-if="showConfirm"
      :message="confirmMessage"
      @close-confirm-modal-ok-only="showConfirm = false"
    />
    <ModalConfirmOkOnly
      v-if="showNoEditPermission"
      :message="$t('messages.error.noEditingPermission')"
      @close-confirm-modal-ok-only="showNoEditPermission = false"
    />
    <ModalConfirmOkOnly
      v-if="showNoCopyPermission"
      :message="$t('messages.error.otherTeacherHomeworkCopyNotAllowedMessage')"
      @close-confirm-modal-ok-only="showNoCopyPermission = false"
    />
    <ModalConfirm
      v-if="showComfirmDelete"
      :message="
        $t('messages.confirm.deleteHomeworkReplaceWords', {
          homeworkName: deleteHomeworkName,
        })
      "
      v-on="{
        'ok-confirm-modal': okConfirmModal,
        'close-confirm-modal': closeConfirmModal,
      }"
    />
  </div>
</template>

<script>
/**
 * 宿題管理 - 宿題一覧表 organisms コンポーネント
 */
import { mapGetters } from "vuex"
import mixin from "../../mixins/mixin"
import ButtonProgress from "@/components/atoms/buttons/ButtonProgress.vue"
import ButtonProgressUnconfirm from "@/components/atoms/buttons/ButtonProgressUnconfirm.vue"
import ButtonEdit from "@/components/atoms/buttons/ButtonEdit.vue"
import ButtonCopy from "@/components/atoms/buttons/ButtonCopy.vue"
import ButtonExport from "@/components/atoms/buttons/ButtonExport.vue"
import ButtonDelete from "@/components/atoms/buttons/ButtonDelete.vue"
import BadgeStatus from "@/components/atoms/BadgeStatus.vue"
import ModalConfirm from "@/components/organisms/modal/ModalConfirm.vue"
import ModalConfirmOkOnly from "@/components/organisms/modal/ModalConfirmOkOnly.vue"
import TableHeaderLabel from "@/components/atoms/TableHeaderLabel.vue"
import LoadingScreen from "@/components/atoms/LoadingScreen.vue"
import convertValue from "../../repositories/convert-value"
import homeworkRepository from "../../repositories/homework"
import { streamStatus } from "../../constant/homework"
import accountRepository from "@/repositories/account"
import { sukenServiceId } from "@/constant/network"
import { funcs } from '@/dbs/indexedDb'
import { groupType } from "@/constant/homework"

// ソート用のキー
// ここでしか使わないのでここに定義
const sortKeys = {
  status: "status",
  group: "group",
  category: "category",
  homeworkName: "homeworkName",
  curriculumId: "curriculumId",
  subjectId: "subjectId",
  startDate: "startDate",
  deadlineDate: "deadlineDate",
  releaseEndDate: "releaseEndDate",
}

export default {
  name: "TableHomework",
  components: {
    ButtonProgress,
    ButtonProgressUnconfirm,
    ButtonEdit,
    ButtonCopy,
    ButtonExport,
    ButtonDelete,
    BadgeStatus,
    ModalConfirm,
    ModalConfirmOkOnly,
    TableHeaderLabel,
    LoadingScreen,
  },

  mixins: [mixin],
  props: {
    homeworkList: {
      type: Array,
      default: function () {
        return []
      },
    },
  },

  data: function () {
    return {
      headers: [
        // 行番号
        // '',
        { label: "状態", sortKey: sortKeys.status },
        { label: "グループ", sortKey: sortKeys.group },
        { label: "カテゴリー", sortKey: sortKeys.category },
        { label: "宿題名", sortKey: sortKeys.homeworkName },
        { label: "進捗" },
        { label: "編集" },
        { label: "教科", sortKey: sortKeys.curriculumId },
        { label: "科目", sortKey: sortKeys.subjectId },
        { label: "開始日", sortKey: sortKeys.startDate },
        { label: "締切日", sortKey: sortKeys.deadlineDate },
        { label: "提出数" },
        { label: "複製" },
        { label: "出力" },
        { label: "削除" },
        { label: "公開終了日", sortKey: sortKeys.releaseEndDate },
      ],
      items: this.homeworkList,
      showComfirmDelete: false,
      deleteHomeworkName: "",
      deleteHomeworkItem: {
        homeworkKey: 0,
        schoolId: 0,
      },
      selectedHeaderKey: "startDate",
      sortOrder: -1,
      showConfirm: false,
      confirmMessage: "",
      isLoading: false,
      showNoEditPermission: false,
      showNoCopyPermission: false,

      // templateで定数を使用するための定義
      streamStatus: streamStatus,
    }
  },

  computed: {
    ...mapGetters("homework", ["checkedStudentList", "transferStudentList"]),
    ...mapGetters("parameterSettings", ["createHomeworkMaximumLimitItems"]),
    convertedStatusNameItems: function () {
      // 配信ステータスから表示名に変換した一覧を生成
      const items = this.homeworkList.map((x) => {
        // 配信ステータス
        const foundStatus = this.nameStreamStatusItems.find(
          (s) => Number(s.code) === x.status
        )
        // 配信先グループ
        let foundGroupId;
        if(x.groupType == "share"){
          foundGroupId= this.loginUserInfo.shareGroupIds.find(
            (g) => g.groupId === x.groupId
          )
        }else{
          foundGroupId= this.loginUserInfo.groupIds.find(
            (g) => g.groupId === x.groupId
          )
        }
        // 教科
        const foundCurriculum = this.nameCurriculums.items.find(
          (c) => Number(c.code) === x.curriculum
        )
        const foundCurriculumKey = this.nameCurriculums.keyItems.find(
          (ck) => Number(ck.code) === x.curriculum
        )
        const foundCurriculumShortName = this.nameCurriculums.shortName.find(
          (cs) => Number(cs.code) === x.curriculum
        )
        // 科目
        let foundSubject = {}
        if (foundCurriculumKey) {
          const foundSubjectItems = this.nameSubjects.find(
            (s) => s.curriculumKey === foundCurriculumKey.name
          )
          if (foundSubjectItems) {
            foundSubject = foundSubjectItems.subjectItems.find(
              (s) => Number(s.code) === x.subject
            )
          }
        }

        
        // 共有グループ用の課題において宿題編集の権限があるかどうかの判定
        let editPermission = true;
        if(x.editableHomework == "0"){
          editPermission = false
        }

        return {
          homeworkKey: x.homeworkKey,
          schoolId: x.schoolId,
          status: x.status,
          statusDisplayName: x.expired
            ? this.$t("labels.overDeadLine")
            : foundStatus.name,
          statusColor: x.statusColor,
          // 配信ステータス ID から名称に変換
          groupId: foundGroupId ? foundGroupId : -1,
          groupName: foundGroupId ? foundGroupId.groupName : "",
          category: x.category ? x.category : "",
          homeworkName: x.homeworkName,
          curriculumId: foundCurriculum ? foundCurriculum.code : -1,
          // 教科 ID から名称に変換
          curriculumName: foundCurriculum ? foundCurriculum.name : "",
          subjectId: foundSubject ? foundSubject.code : -1,
          // 科目 ID から名称に変換
          subjectName: foundSubject ? foundSubject.name : "",
          startDate: x.startDate
            ? convertValue.toDateStringSeparator(x.startDate)
            : "",
          deadlineDate: x.deadlineDate
            ? convertValue.toDateStringSeparator(x.deadlineDate)
            : "",
          handedStudentCount: x.handedStudentCount,
          HandInCount: x.HandInCount,
          releaseEndDate: x.releaseEndDate
            ? convertValue.toDateStringSeparator(x.releaseEndDate)
            : "",
          curriculumShortName: foundCurriculumShortName
            ? foundCurriculumShortName.name
            : "",
          groupType: x.groupType,
          editor: x.editor,
          editingPermission: editPermission,
          ownHomework: x.ownHomework,
          hasOriginal: x.hasOriginal,
          copyable: x.copyable,
        }
      })

      let sortKey = this.selectedHeaderKey
      let sortOrder = this.sortOrder
      if (sortKey === "") {
        // 表示初期は開始日の降順
        sortKey = "startDate"
        sortOrder = -1
      }
      items.sort(function (a, b) {
        const compareDate = function (dateAString, dateBString) {
          const dateA = new Date(dateAString)
          const dateB = new Date(dateBString)
          return dateA >= dateB
        }
        let isUsingPositiveValue = true
        switch (sortKey) {
          case sortKeys.status:
            isUsingPositiveValue = a.status >= b.status
            break
          case sortKeys.group:
            isUsingPositiveValue = a.groupName >= b.groupName
            break
          case sortKeys.category:
            isUsingPositiveValue = a.category >= b.category
            break
          case sortKeys.homeworkName:
            isUsingPositiveValue = a.homeworkName >= b.homeworkName
            break
          case sortKeys.curriculumId:
            isUsingPositiveValue = a.curriculumId >= b.curriculumId
            break
          case sortKeys.subjectId:
            if (a.curriculumId == b.curriculumId) {
              isUsingPositiveValue = a.subjectId >= b.subjectId
            } else {
              isUsingPositiveValue = a.curriculumId >= b.curriculumId
            }
            break
          case sortKeys.startDate:
            isUsingPositiveValue = compareDate(a.startDate, b.startDate)
            break
          case sortKeys.deadlineDate:
            isUsingPositiveValue = compareDate(a.deadlineDate, b.deadlineDate)
            break
          case sortKeys.releaseEndDate:
            isUsingPositiveValue = compareDate(
              a.releaseEndDate,
              b.releaseEndDate
            )
            break
        }
        return isUsingPositiveValue ? sortOrder : -sortOrder
      })

      return items
    },
  },

  methods: {

    /**
     * 削除アイコン押下時処理
     */
    onClickDelete(homeworkKey, schoolId, homeworkName, editingPermission) {
      if(editingPermission){
        this.deleteHomeworkName = homeworkName
        this.deleteHomeworkItem.homeworkKey = homeworkKey
        this.deleteHomeworkItem.schoolId = schoolId
        this.showComfirmDelete = true
      }else{
        // 編集権限がない場合はモーダルを表示
        this.showNoEditPermission = true;
      }
    },
    /**
     * 編集アイコン押下時処理
     */
    async onClickEdit(item) {
      if(item.editingPermission){
        const existGroup = await this.checkExistGroups(item)
        if (!existGroup) {
          return
        }
        this.$router.push({
          name: "HomeworkRegister",
          params: {
            mode: "edit",
            editParams: {
              homeworkKey: item.homeworkKey,
              schoolId: item.schoolId,
              groupName: item.groupName,
              groupType: item.groupType,
              editor: item.editor,
            },
            homeworkStatus: item.status,
          },
        })
      }else{
        // 編集権限がない場合はモーダルを表示
        this.showNoEditPermission = true;
      }
    },
    /**
     * 複製アイコン押下時処理
     */
    async onClickCopy(item) {
      if (!item.copyable) {
        // 編集権限がない場合はモーダルを表示
        this.showNoCopyPermission = true;
        return
      }
      const existGroup = await this.checkExistGroups(item)
      if (!existGroup) {
        return
      }
      if (
        homeworkRepository.isLimitHomeworkCount(
          this.homeworkList,
          this.createHomeworkMaximumLimitItems
        )
      ) {
        this.showConfirmDialog(this.$t("messages.error.overLimitHomeworkCount"))
        return
      }
      this.$router.push({
        name: "HomeworkRegister",
        params: {
          mode: "copy",
          copyParams: {
            schoolId: item.schoolId,
            homeworkKey: 0,
            copyHomeworkKey: item.homeworkKey,
            homeworkName: item.homeworkName,
            groupName: item.groupName,
            curriculumName: item.curriculumName,
            subjectName: item.subjectName,
            startDate: item.startDate,
            deadlineDate: item.deadlineDate,
            groupType: item.groupType,
            editor: item.editor,
          },
        },
      })
    },
    /**
     * 進捗確認済みか？
     */
    isConfirmed(item) {
      const checkedList = this.checkedStudentList[item.homeworkKey] || []
      const transferList = this.transferStudentList[item.homeworkKey] || []
      let handedStudentCount = item.handedStudentCount - transferList.length
      // 確認済みの数より提出済みの数の方が多ければ「未確認の生徒がいる」と見做す
      // ※「再提出が無い」と言う前提の実装
      //   万が一再提出が実装されるのであれば、見直す必要がある（「フロントエンドで状態を保持する」と言う仕様が破綻するレベルの問題だが）
      return checkedList.length >= handedStudentCount
    },
    /**
     * 進捗アイコン押下時処理
     */
    async onClickProgress(item) {
      const existGroup = await this.checkExistGroups(item)
      if (!existGroup) {
        return
      }
      this.$router.push({
        name: "HomeworkProgress",
        params: {
          progressParams: {
            schoolId: item.schoolId,
            groupName: item.groupName,
            homeworkKey: item.homeworkKey,
            homeworkName: item.homeworkName,
            startDate: item.startDate,
            deadlineDate: item.deadlineDate,
            groupId: item.groupId.groupId,
            curriculum: item.curriculumShortName,
            curriculumName: item.curriculumName,
            subjectName: item.subjectName,
            groupType: item.groupType,
            editor: item.editor,
          },
        },
      })
    },
    /**
     * 出力アイコン押下時処理
     */
    async onClickExport(homeworkItem) {
      this.isLoading = true;

      // 教材パッケージ一覧のレスポンスキャッシュをクリア
      await funcs.responseCache.clearMatch("/accounts/products/books");

      // グループ更新
      this.$parent.onRefleshGroup()

      // 宿題進捗情報を取得
      let promise = null;
      try {
        promise = await homeworkRepository.getHomeworkProgressList(
          this.loginUserInfo.accountId,
          this.loginUserInfo.schoolId,
          homeworkItem.homeworkKey,
          homeworkItem.deadlineDate,
          this.loginUserInfo.lmsApiToken,
          this.paramApiSettingItems.find(v => v.itemName === 'MSHW0403').items
        );
      } catch (error) {
        this.$router.push({ name: "APIError", params: { status: error.status } });
        return;
      }

      // ヘッダー情報
      // 親問題単位で扱えるように加工する
      const headerItems = [];
      // 子問題別レート情報
      const childQuestionRateItems = [];
      // 進捗詳細情報
      const homeworkProgressItems = promise.progressItems;
      promise.headerItems.forEach((header, index) => {
        const headerIndex = Number(header.split("\n")[0]) - 1;
        if (!headerItems[headerIndex]) {
          headerItems[headerIndex] = [];
          childQuestionRateItems[headerIndex] = [];
        }
        headerItems[headerIndex].push(header);
        childQuestionRateItems[headerIndex].push(promise.childQuestionRateItems[index]);
      });

      // 生徒情報を取得
      promise = null;
      try {
        promise = await homeworkRepository.getStudentsInfo(
          this.loginUserInfo.accountId,
          homeworkItem.schoolId,
          homeworkItem.homeworkKey,
          this.loginUserInfo.lmsApiToken,
          this.loginUserInfo.sessionToken,
          this.nameCurriculums,
          this.paramApiSettingItems,
        );
      } catch (error) {
        console.log(error);
        this.$router.push({ name: "APIError", params: { status: error.status } });
        return;
      }

      // 生徒情報をリストに反映
      homeworkRepository.setStudentsInfo(
        headerItems,
        homeworkProgressItems,
        promise.groupMemberItems,
        childQuestionRateItems,
        promise.homeworkDetails
      );

      // 表示用進捗詳細情報リストを取得
      const viewProgressItems = homeworkRepository.getViewProgressItems(
        homeworkProgressItems,
        this.nameHomeworkStatusItems,
        headerItems,
        homeworkItem.homeworkKey,
        this.$t("labels.expired")
      );

      // Excelファイルエクスポート
      homeworkRepository.outputProgressInfo(
        homeworkItem,
        headerItems,
        childQuestionRateItems,
        viewProgressItems
      );

      this.isLoading = false;
    },
    
    /**
     * モーダルＯＫボタン押下時処理
     */
    okConfirmModal() {
      // 削除処理を実行
      this.deleteHomework()
    },
    /**
     * モーダル閉じるボタン押下時処理
     */
    closeConfirmModal() {
      this.showComfirmDelete = false
    },
    /**
     * テーブルのラベル押下時処理
     */
    onClickLabel: function (key, order) {
      this.selectedHeaderKey = key
      this.sortOrder = order
    },
    /**
     * 確認ダイアログ表示処理
     */
    showConfirmDialog: function (message) {
      this.showConfirm = true
      this.confirmMessage = message
    },
    /**
     * グループが削除されている宿題のボタンアイコン押下時に宿題を削除する処理
     */
    async checkExistGroups(paramItem) {
      const statusCheck = paramItem.status === 2 || paramItem.status === 3
      if (!statusCheck) {
        return !statusCheck
      }

      let groupTypeNum = groupType.individual
      if(paramItem.groupType == "share"){
        groupTypeNum = groupType.share
      }
      const groupIdPromise = await accountRepository.getGroupIdsOfSukenAccount(
        sukenServiceId.lms,
        groupTypeNum,
        this.loginUserInfo.accountId,
        this.loginUserInfo.sessionToken
      )
      let existGroup;
      if(groupTypeNum == groupType.share){
        existGroup = groupIdPromise.data.sharedGroupList.some(
          (item) => item.groupId === paramItem.groupId.groupId
        )
      }else{
        existGroup = groupIdPromise.data.groupList.some(
          (item) => item.groupId === paramItem.groupId.groupId
        )
      }
      if (!existGroup) {
        this.showConfirmDialog(
          this.$t("messages.error.notExistGroupForHomework")
        )
        this.deleteHomeworkItem.homeworkKey = paramItem.homeworkKey
        this.deleteHomeworkItem.schoolId = paramItem.schoolId
        this.deleteHomework(paramItem.schoolId, paramItem.homeworkKey)
      }
      return existGroup
    },
    /**
     * 宿題削除処理
     */
    deleteHomework() {
      this.$emit(
        "delete-homework",
        this.deleteHomeworkItem.schoolId,
        this.deleteHomeworkItem.homeworkKey
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.table-homework {
  position: relative;
}

table {
  white-space: nowrap;

  thead {
    tr {
      height: 40px;
      color: var(--bs-layout-theme);
      border-left: 1px solid #b3b3b3;
      border-right: 1px solid #b3b3b3;

      th {
        padding: 0 0.5rem;
        top: 0px;
        border-top: solid 1px #b3b3b3;
        position: -webkit-sticky;
        position: sticky;
        z-index: 1;
        background: var(--bs-table-header);
        margin: 0;

        &:nth-child(3) {
          max-width: 140px;
        }

        &:nth-child(4) {
          max-width: 300px;
        }

        &::before {
          position: absolute;
          content: "";
          display: block;
          border-top: 1px solid #b3b3b3;
          top: -1px;
          left: -1px;
          right: -1px;
          bottom: -1px;
          pointer-events: none;
        }
      }
    }
  }

  tbody {
    tr {
      height: 50px;
      border-left: 1px solid #b3b3b3;
      border-right: 1px solid #b3b3b3;

      &:last-child {
        border-bottom: 1px solid #b3b3b3;
      }

      td {
        padding: 0.3em 0.5rem 0.3em !important;

        &:nth-child(3) {
          max-width: 140px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        &:nth-child(4) {
          max-width: 300px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      &:nth-child(odd) {
        background: var(--bs-table-row);
      }
    }
  }
}
</style>
