<template>
  <span class="icon-apple-login-logo">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 384.4 56"
    >
      <defs />
      <g
        id="レイヤー_2"
        data-name="レイヤー 2"
      >
        <g id="appple">
          <rect
            class="cls-1"
            x="1.5"
            y="1.5"
            width="381.4"
            height="53"
            rx="26.5"
          />
          <circle
            class="cls-2"
            cx="340.99"
            cy="28"
            r="11.77"
          />
          <path
            class="cls-3"
            d="M338.68,33.64a.84.84,0,0,1-.55-.19.57.57,0,0,1,0-.92L343.56,28l-5.43-4.53a.57.57,0,0,1,0-.92.9.9,0,0,1,1.1,0l5.85,4.87a.73.73,0,0,1,0,1.16l-5.85,4.87A.84.84,0,0,1,338.68,33.64Zm5.29-5.3h0Z"
          />
          <g id="apple_icon">
            <path
              id="_"
              data-name=""
              d="M46,17.72a5.44,5.44,0,0,0,3.77-2,6.31,6.31,0,0,0,1.47-3.89,3,3,0,0,0,0-.55,6.2,6.2,0,0,0-4.09,2.12,6,6,0,0,0-1.53,3.78,2.12,2.12,0,0,0,.06.49A1.82,1.82,0,0,0,46,17.72ZM41.55,39.14C43.27,39.14,44,38,46.17,38s2.66,1.12,4.57,1.12,3.14-1.74,4.32-3.44a15.91,15.91,0,0,0,1.92-4,6.1,6.1,0,0,1-3.72-5.63,6.3,6.3,0,0,1,3-5.31A6.56,6.56,0,0,0,50.74,18c-2.1,0-3.82,1.27-4.9,1.27s-2.71-1.2-4.53-1.2c-3.47,0-7,2.87-7,8.28a17.14,17.14,0,0,0,2.92,9.23C38.61,37.55,39.82,39.14,41.55,39.14Z"
            />
          </g>
          <path
            d="M108.33,31.27h-6.85L100,35.55H97.72L104,19.2h1.88l6.26,16.35H109.9Zm-6.19-1.76h5.55l-2.78-7.65Z"
          />
          <path
            d="M124.25,29.61A7.25,7.25,0,0,1,123,34.08a4.07,4.07,0,0,1-3.44,1.69,4.48,4.48,0,0,1-3.48-1.4v5.85H114V23.4h1.9l.1,1.34a4.31,4.31,0,0,1,3.53-1.57A4.14,4.14,0,0,1,123,24.82a7.36,7.36,0,0,1,1.28,4.6Zm-2.08-.24a5.3,5.3,0,0,0-.88-3.24,2.8,2.8,0,0,0-2.4-1.2,3.05,3.05,0,0,0-2.83,1.68v5.8a3.06,3.06,0,0,0,2.85,1.67,2.82,2.82,0,0,0,2.38-1.19A5.84,5.84,0,0,0,122.17,29.37Z"
          />
          <path
            d="M137.15,29.61a7.25,7.25,0,0,1-1.27,4.47,4.05,4.05,0,0,1-3.43,1.69,4.49,4.49,0,0,1-3.49-1.4v5.85h-2.07V23.4h1.9l.1,1.34a4.29,4.29,0,0,1,3.52-1.57,4.15,4.15,0,0,1,3.47,1.65,7.36,7.36,0,0,1,1.27,4.6Zm-2.08-.24a5.37,5.37,0,0,0-.87-3.24,2.83,2.83,0,0,0-2.41-1.2A3.06,3.06,0,0,0,129,26.61v5.8a3.08,3.08,0,0,0,2.86,1.67,2.81,2.81,0,0,0,2.37-1.19A5.84,5.84,0,0,0,135.07,29.37Z"
          />
          <path d="M142.05,35.55H140V18.3h2.08Z" />
          <path
            d="M150.42,35.77a5.34,5.34,0,0,1-4-1.62,6,6,0,0,1-1.55-4.34v-.38a7.17,7.17,0,0,1,.7-3.23A5.39,5.39,0,0,1,147.47,24a4.87,4.87,0,0,1,2.69-.8,4.55,4.55,0,0,1,3.68,1.56,6.73,6.73,0,0,1,1.31,4.47v.87h-8.23A4.33,4.33,0,0,0,148,33a3.3,3.3,0,0,0,2.56,1.11,3.61,3.61,0,0,0,1.86-.45,4.73,4.73,0,0,0,1.34-1.19l1.27,1A5.1,5.1,0,0,1,150.42,35.77Zm-.26-10.89a2.75,2.75,0,0,0-2.11.91A4.43,4.43,0,0,0,147,28.36h6.09V28.2a4,4,0,0,0-.86-2.45A2.6,2.6,0,0,0,150.16,24.88Z"
          />
          <path
            d="M177.45,21.45h-5.62a11.17,11.17,0,0,0-4,3.21,7.48,7.48,0,0,0-1.95,4.85,5.05,5.05,0,0,0,1,3.24,6,6,0,0,0,2.76,1.89,14.31,14.31,0,0,0,4.58.6l1.36,0-.11,1.66h-.06c-1.38,0-2.53,0-3.45-.07a11.66,11.66,0,0,1-2.88-.55,7.67,7.67,0,0,1-2.68-1.46,6.14,6.14,0,0,1-1.71-2.25,7.08,7.08,0,0,1-.57-2.9,8.43,8.43,0,0,1,1.38-4.48,12.58,12.58,0,0,1,3.63-3.75H157.9v-1.5h19.55Zm-2.78,4.79-1.3.58a26.87,26.87,0,0,0-1.62-3.15l1.21-.55A25,25,0,0,1,174.67,26.24Zm3.26-.69-1.29.53A22.93,22.93,0,0,0,175,23l1.19-.58A32.26,32.26,0,0,1,177.93,25.55Z"
          />
          <path
            d="M186.76,30.16h-1.95V24.47h-4.45V22.85h4.45V18.11h1.95v4.74h9.31V17.78h2v5.07h4.23v1.62H198v2.72q0,9.42-13,9.84l-.37-1.67q6.28-.22,8.85-2.4a7.13,7.13,0,0,0,2.56-5.72V24.47h-9.31Z"
          />
          <path
            d="M217,37.14h-2V26a43.62,43.62,0,0,1-10.51,3.92l-.6-1.54a37.48,37.48,0,0,0,11.5-4.71,23.51,23.51,0,0,0,6.32-5.85l1.85.72A24.31,24.31,0,0,1,217,24.65Z"
          />
          <path
            d="M247.45,25.9a19.89,19.89,0,0,1-4.9,5.69,21.87,21.87,0,0,1-6.26,3.57,33.34,33.34,0,0,1-7.65,1.65l-.39-1.73a30.38,30.38,0,0,0,6.56-1.35,19.85,19.85,0,0,0,4.91-2.34,26.25,26.25,0,0,0,3.4-2.73,18.41,18.41,0,0,0,2.79-3.73Zm-9.79-2.85-1.27,1.47a59.49,59.49,0,0,0-8-4.17l1-1.4A47.33,47.33,0,0,1,237.66,23.05Z"
          />
          <path
            d="M263,37.14h-2V26a43.62,43.62,0,0,1-10.51,3.92l-.6-1.54a37.48,37.48,0,0,0,11.5-4.71,23.51,23.51,0,0,0,6.32-5.85l1.85.72A24.31,24.31,0,0,1,263,24.65Z"
          />
          <path
            d="M293.45,25.9a19.89,19.89,0,0,1-4.9,5.69,21.87,21.87,0,0,1-6.26,3.57,33.34,33.34,0,0,1-7.65,1.65l-.39-1.73a30.38,30.38,0,0,0,6.56-1.35,19.85,19.85,0,0,0,4.91-2.34,26.25,26.25,0,0,0,3.4-2.73,18.41,18.41,0,0,0,2.79-3.73Zm-9.79-2.85-1.27,1.47a59.49,59.49,0,0,0-8-4.17l1-1.4A47.33,47.33,0,0,1,283.66,23.05Z"
          />
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: "IconAppleLoginLogo",
};
</script>
<style lang="scss" scoped>
.cls-1,
.cls-3 {
  fill: #fff;
}
.cls-1 {
  stroke: #2f7bb9;
  stroke-miterlimit: 10;
  stroke-width: 3px;
}
.cls-2 {
  fill: #2f7bb9;
}
</style>