var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-homework-stream-data-list" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-8" }, [
          _c(
            "label",
            {
              staticClass: "form-label mt-3 mb-1",
              class: { "required-label": _vm.isRequire }
            },
            [_vm._v(_vm._s(_vm.$t("labels.streamData")))]
          )
        ]),
        _vm.limit === 0
          ? _c("div", { staticClass: "col-4 text-end" }, [
              _c("label", { staticClass: "form-label mt-3 mb-1 mx-3" }, [
                _vm._v(" " + _vm._s(_vm.streamDataList.length) + "件 ")
              ])
            ])
          : _c("div", { staticClass: "col-4 text-end" }, [
              _c("label", { staticClass: "form-label mt-3 mb-1 mx-3" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.streamDataList.length) +
                    "/" +
                    _vm._s(_vm.limit) +
                    "件 "
                )
              ])
            ])
      ]),
      _c(
        "div",
        {
          staticClass: "table-wrap",
          style: { "max-height": 42 * (_vm.displayCount + 1) + "px" }
        },
        [
          _c("table", [
            _c("thead", [
              _c(
                "tr",
                { staticClass: "table-header" },
                _vm._l(_vm.headers, function(header, index) {
                  return _c("th", { key: index }, [
                    _c("div", [_vm._v(_vm._s(header))])
                  ])
                }),
                0
              )
            ]),
            _c(
              "tbody",
              _vm._l(_vm.dataList, function(item, index) {
                return _c(
                  "tr",
                  { key: index, staticClass: "text-center px-1" },
                  [
                    item
                      ? [
                          _c(
                            "td",
                            [
                              _vm.showCaution(item) ||
                              _vm.judgeAdditionalOptionCaution(item)
                                ? _c("IconCaution", {
                                    staticClass: "caution",
                                    attrs: { width: "20", height: "20" },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.onclickCaution(item)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          item.questionType === 1
                            ? _c("td", { staticClass: "text-start px-1" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-4" }, [
                                    _c(
                                      "div",
                                      { staticClass: "show-three-point" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getBookName(
                                                item.subQuestionInfo
                                                  .teachingMaterials
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]),
                                  _c("div", { staticClass: "col-8" }, [
                                    _c(
                                      "div",
                                      { staticClass: "show-three-point" },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.taskName) + " "
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ])
                            : _c("td", { staticClass: "text-start px-1" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c(
                                      "div",
                                      { staticClass: "show-three-point" },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.taskName) + " "
                                        )
                                      ]
                                    )
                                  ])
                                ])
                              ]),
                          _c(
                            "td",
                            [
                              _c("ButtonPreview", {
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.onClickPreview(item)
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "td",
                            [
                              _c("ButtonDelete", {
                                attrs: { disabled: _vm.isPublished },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.onClickDelete(item, index)
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _vm.isShowButtonUpDown
                            ? [
                                _c(
                                  "td",
                                  [
                                    index !== 0
                                      ? _c("ButtonUp", {
                                          attrs: { disabled: _vm.isPublished },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.onClickUp(index)
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c(
                                  "td",
                                  [
                                    index !== _vm.streamDataList.length - 1
                                      ? _c("ButtonDown", {
                                          attrs: { disabled: _vm.isPublished },
                                          nativeOn: {
                                            click: function($event) {
                                              return _vm.onClickDown(index)
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            : _vm._e()
                        ]
                      : [
                          _c("td", {
                            attrs: { colspan: _vm.isShowButtonUpDown ? 6 : 4 }
                          })
                        ]
                  ],
                  2
                )
              }),
              0
            )
          ])
        ]
      ),
      _vm.currentPreview === _vm.previewKind.textbook
        ? _c("ModalPreviewTextbookQuestion", {
            attrs: {
              item: _vm.questionInfo,
              curriculum: _vm.curriculum,
              "stream-group": Number(_vm.streamGroup),
              "login-user-info": _vm.loginUserInfo,
              "student-items": _vm.studentItems,
              "book-items": _vm.bookItems,
              "task-name": _vm.taskName,
              "book-name": _vm.bookName
            },
            on: {
              close: function($event) {
                _vm.currentPreview = _vm.previewKind.none
              }
            }
          })
        : _vm._e(),
      _vm.currentPreview === _vm.previewKind.stdbLayout ||
      _vm.currentPreview === _vm.previewKind.pdf
        ? _c("ModalProgressPreviewStdbPdfQuestion", {
            attrs: { item: _vm.questionInfo },
            on: {
              close: function($event) {
                _vm.currentPreview = _vm.previewKind.none
              }
            }
          })
        : _vm._e(),
      _vm.currentPreview === _vm.previewKind.stdb
        ? _c("ModalProgressPreviewSingleStdbQuestion", {
            attrs: { item: _vm.questionInfo },
            on: {
              close: function($event) {
                _vm.currentPreview = _vm.previewKind.none
              }
            }
          })
        : _vm._e(),
      _vm.showConfirmDelete
        ? _c(
            "ModalConfirm",
            _vm._g(
              {
                attrs: {
                  message: _vm.$t(
                    "messages.confirm.deleteHomeworkReplaceWords",
                    {
                      homeworkName: _vm.deleteHomeworkName
                    }
                  )
                }
              },
              {
                "ok-confirm-modal": _vm.okConfirmModal,
                "close-confirm-modal": _vm.closeConfirmModal
              }
            )
          )
        : _vm._e(),
      _vm.showConfirmCaution
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.cautionMessage },
            on: {
              "close-confirm-modal-ok-only": function($event) {
                _vm.showConfirmCaution = !_vm.showConfirmCaution
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }