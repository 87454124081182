import i18n from '../../../i18n'

// 先生用メニュー設定
const teacherMenuItems = [
  { name: 'Homework', displayName: i18n.t('sideMenu.homework'), fontColor: 'text-dark', iconName: "IconHomeworkMono", isBottomMenu: false, showLinkIcon: false },
  { name: 'Question', displayName: i18n.t('sideMenu.question'), fontColor: 'text-dark', iconName: "IconQuestion", isBottomMenu: false, showLinkIcon: false },
  { name: 'Group', displayName: i18n.t('sideMenu.group'), fontColor: 'text-dark', iconName: "IconGroupManage", isBottomMenu: true, openUrl: i18n.t('url.groupManagement'), showLinkIcon: true },
  { name: 'Help', displayName: i18n.t('sideMenu.help'), fontColor: 'text-dark', iconName: "IconHelp", isBottomMenu: true, openUrl: i18n.t('url.helps.top'), showLinkIcon: true },
  { name: 'Terms', displayName: i18n.t('sideMenu.terms'), fontColor: 'text-dark', iconName: "IconQuestion", isBottomMenu: true, showLinkIcon: false },
]

// 管理者用メニュー設定
const adminMenuItems = [
  { name: 'CommentControl', displayName: i18n.t('sideMenu.commentControl'), fontColor: 'text-dark', iconName: "IconComment", isBottomMenu: false, showLinkIcon: false },
  { name: 'SharedGroup', displayName: i18n.t('sideMenu.sharedGroup'), fontColor: 'text-dark', iconName: "IconGroupManage", isBottomMenu: true, openUrl: i18n.t('url.sharedGroupManagement'), showLinkIcon: true },
  { name: 'Help', displayName: i18n.t('sideMenu.help'), fontColor: 'text-dark', iconName: "IconHelp", isBottomMenu: true, openUrl: i18n.t('url.helps.comment'), showLinkIcon: true },
  { name: 'Terms', displayName: i18n.t('sideMenu.terms'), fontColor: 'text-dark', iconName: "IconQuestion", isBottomMenu: true, showLinkIcon: false },
]
export const menu = {
  namespaced: true,
  state: {
    activeName: '',
    menuItems: [],
    adminMenuItems: [],
  },

  getters: {
    activeName: (state) => {
      return state.activeName
    },
    allMenuItems: (state, _getters, rootState) => {
      const loginUserInfo = rootState.loginUserInfo
      if (loginUserInfo.isAdmin) {
        return state.adminMenuItems
      } else {
        return state.menuItems
      }
    },
  },

  mutations: {
    setActiveName(state, payload) {
      state.activeName = payload.activeName
    },
    setMenuItems(state, payload) {
      state.menuItems = payload.menuItems
    },
    setAdminMenuItems(state, payload) {
      state.adminMenuItems = payload.menuItems
    },
  },

  actions: {
    updateMenuItem({ commit, rootState }, selectName) {
      commit('setActiveName', { activeName: selectName })

      const loginUserInfo = rootState.loginUserInfo
      const menuItems = loginUserInfo.isAdmin ? adminMenuItems : teacherMenuItems
      const newItems = menuItems.map(item => {
        let activeFontColor = item.fontColor
        if (item.name === selectName) {
          activeFontColor = 'text-white'
        } else {
          activeFontColor = 'text-dark'
        }

        let iconColorCode = '#fff'
        if (activeFontColor === 'text-dark') {
          iconColorCode = '#343a40'
        }

        const newItem = {
          ...item,
          fontColor: activeFontColor,
          iconColor: iconColorCode,
          // openUrl,
        }
        return newItem
      })
      if (loginUserInfo.isAdmin) {
        commit('setAdminMenuItems', { menuItems: newItems })
      } else {
        commit('setMenuItems', { menuItems: newItems })
      }
    }
  },
}