/**
 * セッション関連 API
 */
import apiInstance from './axiosBase'
import { funcs } from '@/dbs/indexedDb'

export default {
  /**
  * Create 系 API
  */

  /**
  * Read 系 API
  */

  /**
   * システム日時を取得する
   * @param {String} accountId ID
   * @param {String} apiToken API トークン
   * @returns システム日時
   */
  async getSystemDateTime(accountId, apiToken) {
    // 有効期限内のレスポンスキャッシュが存在する場合、レスポンスとして返却する
    let key = "systemDateTime";
    let responseCache = await funcs.responseCache.get(key);
    if (responseCache !== null) {
      return responseCache.data;
    }

    try {
      // 存在しない学校 ID をセットして、結果が0件になるようにする
      const noSuchSchoolId = 0
      let responseData = await apiInstance.axiosLmsBase({
        method: 'post',
        url: `/homework/search`,
        data: {
          account_id: accountId,
          school_id: noSuchSchoolId,
          token: apiToken,
        },
      })
      // レスポンスをキャッシュとして保存する
      await funcs.responseCache.set(key, responseData);
      return responseData;
    } catch (error) {
      return error
    }
  },

  /**
  * Update 系 API
  */

  /**
  * Delete 系 API
  */

}