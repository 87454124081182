<template>
  <div class="form-select-box">
    <fieldset>
      <label
        :for="id"
        class="form-label"
        :class="{ 'required-label': isRequire }"
      >{{ labelText }}</label>
      <span
        v-if="isGroupSelect"
        style="font-size: 85%"
      >&nbsp;&nbsp;&nbsp;&nbsp;グループの作成・編集は<a
        href="https://account.chart.co.jp/group/list"
        target="_blank"
      >こちら</a></span>
      <select
        ref="selectBox"
        :value="selected"
        :disabled="disabled"
        class="form-select form-select-sm"
        @change="$emit('input', $event.target.value)"
      >
        <template v-for="(option, index) in options">
          <option
            :key="index"
            :value="option.value"
            class="h6"
          >
            {{ option.label }}
          </option>
        </template>
      </select>
    </fieldset>
  </div>
</template>

<script>
export default {
  name: "FormSelectBox",
  props: {
    labelText: {
      type: String,
      default: function () {
        return ""
      },
    },
    id: {
      type: String,
      default: function () {
        return ""
      },
    },
    options: {
      type: Array,
      require: true,
      default: function () {
        return []
      },
    },
    initialValue: {
      type: String,
      default: function () {
        return ""
      },
    },
    isRequire: {
      type: Boolean,
      default: function () {
        return false
      },
    },
    disabled: { type: Boolean, default: false },
  },

  data: function () {
    return {
      selected: this.initialValue,
    }
  },

  computed: {
    isGroupSelect() {
      return this.labelText === this.$t("labels.group")
    },
  },

  mounted: function () {
    // 初期値を呼び出し元に返す
    this.$emit("input", this.selected)
  },

  methods: {
    /**
     * 値を設定
     */
    setValue(value) {
      this.selected = value
      this.$refs.selectBox.value = value
    },
  },
}
</script>

<style scoped>
::v-deep label {
  display: inline;
}
::v-deep .select-box {
  border: solid 1px#b3b3b3;
  padding: 0.2em;
  border-radius: 5px;
}
.required-label:before {
  content: "*";
  color: red;
}
</style>
