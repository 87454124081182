import { extend } from 'vee-validate'

// ログインIDの許容文字判定
extend('possibleLoginId', value => {
    return value.match(/^[A-Za-z0-9+\-_.@]*$/) !== null
})

// ログインパスワードの許容文字判定
extend('possibleLoginPassword', value => {
    return value.match(/^[A-Za-z0-9+\-=@_]*$/) !== null
})
