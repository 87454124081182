var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-progress-preview-stdb-pdf-question" },
    [
      _c("transition", { attrs: { name: "modal", appear: "" } }, [
        _c("div", { staticClass: "modal-mask" }, [
          _c("div", { staticClass: "modal-wrapper" }, [
            _c(
              "div",
              { staticClass: "modal-container-outer" },
              [
                _c("ButtonClose", {
                  staticClass: "modal-default-button",
                  nativeOn: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                }),
                _c("div", { staticClass: "modal-container px-2 py-4" }, [
                  _c("div", { staticClass: "row mb-4 task-name" }, [
                    _vm.item.page == "progress" &&
                    _vm.selectedHomeworkItemIndex !== 0
                      ? _c("div", { staticClass: "col-2" }, [
                          _c("div", { staticClass: "show-three-point" }, [
                            _vm._v(
                              " No." +
                                _vm._s(_vm.selectedHomeworkItemIndex) +
                                " "
                            )
                          ])
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "col-8" }, [
                      _c("div", { staticClass: "show-three-point" }, [
                        _vm._v(" " + _vm._s(_vm.item.taskName) + " ")
                      ])
                    ]),
                    _vm.filePaths.length
                      ? _c("div", { staticClass: "col-2" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.currentIndex +
                                  1 +
                                  " / " +
                                  _vm.filePaths.length
                              ) +
                              " "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "card w-90",
                      staticStyle: { "margin-right": "5%", "margin-left": "5%" }
                    },
                    [
                      _c("div", { staticClass: "card-header" }, [
                        _vm._v(" " + _vm._s(_vm.$t("labels.preview")) + " ")
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "py-3 card-body preview-window window-body"
                        },
                        [
                          _c("div", { staticClass: "row mb-2 preview-image" }, [
                            _c(
                              "div",
                              { staticClass: "col-1 page-transition-button" },
                              [
                                _vm.hasPrevPage
                                  ? _c("i", {
                                      staticClass: "bi bi-chevron-left",
                                      on: {
                                        click: function($event) {
                                          _vm.currentIndex--
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ]
                            ),
                            _c("div", { staticClass: "col-10" }, [
                              _c("div", { staticClass: "card" }, [
                                _vm.currentPath
                                  ? _c("img", {
                                      attrs: { src: _vm.currentPath }
                                    })
                                  : _vm._e()
                              ])
                            ]),
                            _c(
                              "div",
                              { staticClass: "col-1 page-transition-button" },
                              [
                                _vm.hasNextPage
                                  ? _c("i", {
                                      staticClass: "bi bi-chevron-right",
                                      on: {
                                        click: function($event) {
                                          _vm.currentIndex++
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ]
                            )
                          ]),
                          _vm.isLoading ? _c("LoadingScreen") : _vm._e()
                        ],
                        1
                      )
                    ]
                  )
                ])
              ],
              1
            )
          ])
        ])
      ]),
      _vm.showConfirmCaution
        ? _c("ModalConfirmOkOnly", {
            attrs: { message: _vm.cautionMessage },
            on: { "close-confirm-modal-ok-only": _vm.closeModal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }