import i18n from '../../../i18n'

export const menu = {
  namespaced: true,
  state: {
    activeName: '',
    menuItems: [
      { name: 'Homework', displayName: i18n.t('sideMenu.homework'), fontColor: 'text-dark', iconName: "IconHomeworkMono", isBottomMenu: false, showLinkIcon: false },
      { name: 'Question', displayName: i18n.t('sideMenu.question'), fontColor: 'text-dark', iconName: "IconQuestion", isBottomMenu: false, showLinkIcon: false },
      { name: 'Group', displayName: i18n.t('sideMenu.group'), fontColor: 'text-dark', iconName: "IconGroupManage", isBottomMenu: true, openUrl: i18n.t('url.groupManagement'), showLinkIcon: true },
      { name: 'Help', displayName: i18n.t('sideMenu.help'), fontColor: 'text-dark', iconName: "IconHelp", isBottomMenu: true, openUrl: i18n.t('url.helps.top'), showLinkIcon: true },
      { name: 'Terms', displayName: i18n.t('sideMenu.terms'), fontColor: 'text-dark', iconName: "IconQuestion", isBottomMenu: true, showLinkIcon: false },
    ],
  },

  getters: {
    activeName: (state) => {
      return state.activeName
    },
    allMenuItems: (state) => {
      return state.menuItems
    },
  },

  mutations: {
    setActiveName(state, payload) {
      state.activeName = payload.activeName
    },
    setMenuItems(state, payload) {
      state.menuItems = payload.menuItems
    },
  },

  actions: {
    updateMenuItem({ commit, state }, selectName) {
      commit('setActiveName', { activeName: selectName })

      const newItems = state.menuItems.map(item => {
        let activeFontColor = item.fontColor
        if (item.name === selectName) {
          activeFontColor = 'text-white'
        } else {
          activeFontColor = 'text-dark'
        }

        let iconColorCode = '#fff'
        if (activeFontColor === 'text-dark') {
          iconColorCode = '#343a40'
        }

        let newItem = {
          name: item.name,
          displayName: item.displayName,
          fontColor: activeFontColor,
          iconName: item.iconName,
          iconColor: iconColorCode,
          isBottomMenu: item.isBottomMenu,
          openUrl: item.openUrl,
          showLinkIcon: item.showLinkIcon
        }
        return newItem
      })
      commit('setMenuItems', { menuItems: newItems })
    }
  },
}